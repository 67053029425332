import React, { useCallback, useState } from 'react';

import ForgotPasswordForm from '../components/forms/ForgotPasswordForm';
import LoginForm from '../components/forms/LoginForm';
import UserAccountWizardPageWrapper from '../components/UserAccountWizardPageWrapper';

enum Forms {
  LOGIN = 'login',
  FORGOT_PASSWORD = 'forgotPassword'
}

const LoginPage = () => {
  const [form, setForm] = useState<Forms>(Forms.LOGIN);

  const goToForgotPassword = useCallback(() => {
    setForm(Forms.FORGOT_PASSWORD);
  }, []);

  const goToLogin = useCallback(() => {
    setForm(Forms.LOGIN);
  }, []);

  return (
    <UserAccountWizardPageWrapper titleId="pages.login.title">
      {form === Forms.LOGIN && <LoginForm goToForgotPassword={goToForgotPassword} />}
      {form === Forms.FORGOT_PASSWORD && <ForgotPasswordForm goBack={goToLogin} />}
    </UserAccountWizardPageWrapper>
  );
};

export default LoginPage;
