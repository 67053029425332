import { Action } from 'redux';

import { Notification, NotificationType } from '../types/State';

export enum NotificationAction {
  ADD_NOTIFICATION = 'notification/ADD_NOTIFICATION',
  CLEAR_NOTIFICATIONS = 'notification/CLEAR_NOTIFICATIONS',
  DISMISS_NOTIFICATION = 'notification/DISMISS_NOTIFICATION'
}

interface AddNotificationAction extends Action {
  payload: {
    notification: Notification;
  };
  type: NotificationAction.ADD_NOTIFICATION;
}

export interface ClearNotificationsAction extends Action {
  type: NotificationAction.CLEAR_NOTIFICATIONS;
}

interface DismissNotificationAction extends Action {
  payload: {
    id: string;
  };
  type: NotificationAction.DISMISS_NOTIFICATION;
}

export type NotificationActionTypes =
  | AddNotificationAction
  | ClearNotificationsAction
  | DismissNotificationAction;

export const addNotification = (notification: Notification) => ({
  payload: { notification },
  type: NotificationAction.ADD_NOTIFICATION
});

export const clearNotifications = () => ({
  type: NotificationAction.CLEAR_NOTIFICATIONS
});

export const dismissNotification = (id: string) => ({
  payload: { id },
  type: NotificationAction.DISMISS_NOTIFICATION
});

const alertUniversal = (tKey: string, type: NotificationType) =>
  addNotification({ bodyTranslationKey: tKey, type });

export const alertError = (tKey: string) => alertUniversal(tKey, NotificationType.ERROR);
export const alertSuccess = (tKey: string) => alertUniversal(tKey, NotificationType.SUCCESS);
export const alertWarning = (tKey: string) => alertUniversal(tKey, NotificationType.WARNING);
