import { produce } from 'immer';

import { SearchAction, SearchActionTypes } from '../actions/searchActions';
import * as searchUtils from '../services/searchUtils';
import { State } from '../types';

const defaultState: State.Search = {
  conversationParams: searchUtils.defaultConversationSearchParams(),
  eventParams: searchUtils.defaultPostSearchParams(),
  forumParams: searchUtils.defaultPostSearchParams(),
  jobBoardParams: searchUtils.defaultPostSearchParams(),
  memberParams: searchUtils.defaultMemberSearchParams(),
  newsItemParams: searchUtils.defaultPostSearchParams()
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const searchReducer = (state = defaultState, action: SearchActionTypes): State.Search => {
  switch (action.type) {
    case SearchAction.SET_CONVERSATION_SEARCH_PARAMS:
      return produce(state, draft => {
        draft.conversationParams = action.payload.params;
      });
    case SearchAction.SET_EVENT_SEARCH_PARAMS:
      return produce(state, draft => {
        draft.eventParams = action.payload.params;
      });
    case SearchAction.SET_FORUM_SEARCH_PARAMS:
      return produce(state, draft => {
        draft.forumParams = action.payload.params;
      });
    case SearchAction.SET_JOB_BOARD_SEARCH_PARAMS:
      return produce(state, draft => {
        draft.jobBoardParams = action.payload.params;
      });
    case SearchAction.SET_MEMBER_SEARCH_PARAMS:
      return produce(state, draft => {
        draft.memberParams = action.payload.params;
      });
    case SearchAction.SET_NEWS_ITEM_SEARCH_PARAMS:
      return produce(state, draft => {
        draft.newsItemParams = action.payload.params;
      });
    default:
      return state;
  }
};
