/* eslint-disable @typescript-eslint/naming-convention */
const connectSrc = [
  "'self'",
  'data:',
  `${import.meta.env.VITE_UPLOAD_CDN_URL}`,
  'https://api.mapbox.com',
  'https://*.google-analytics.com',
  'https://sentry.io',
  `${import.meta.env.VITE_API_HOST}`,
  `${import.meta.env.VITE_ACTIONCABLE_HOST}`
];

const imgSrc = [
  "'self'",
  'blob:',
  'data:',
  `${import.meta.env.VITE_UPLOAD_CDN_URL}`,
  'https://*.google-analytics.com'
];

const scriptSrc = ["'self'", 'data:', 'https://*.google-analytics.com', 'https://js.stripe.com'];

if (import.meta.env.MODE === 'development') {
  connectSrc.push(
    `http${import.meta.env.VITE_ENABLE_HTTPS && 's'}://${import.meta.env.VITE_HOST}:${
      import.meta.env.VITE_PORT
    }`
  );
  connectSrc.push(`wss://${import.meta.env.VITE_HOST}:${import.meta.env.VITE_PORT}`);
  connectSrc.push(`ws://${import.meta.env.VITE_HOST}:${import.meta.env.VITE_PORT}`);
  connectSrc.push('via.placeholder.com');
  imgSrc.push('via.placeholder.com');
  scriptSrc.push("'unsafe-inline'");
}

const contentSecurityPolicy: { [key: string]: string | string[] } = {
  'base-uri': "'self'",
  'connect-src': connectSrc,
  'default-src': ['https://js.stripe.com'],
  'font-src': ["'self'"],
  'form-action': ["'none'"],
  'img-src': imgSrc,
  'manifest-src': ["'self'"],
  'object-src': "'none'",
  'script-src': scriptSrc,
  'style-src': [
    "'self'",
    "'unsafe-inline'" // Seems like this has to be enabled for Ionic to work
  ]
};

const directives: string[] = [];
for (const [key, value] of Object.entries(contentSecurityPolicy)) {
  directives.push(`${key} ${typeof value === 'string' ? value : value.join(' ')}`);
}
/* eslint-enable @typescript-eslint/naming-convention */

export default directives.join('; ');
