// all this does is wrap a primary color IonText around paragraphs so we don't forget
import { IonText } from '@ionic/react';
import React from 'react';

const Paragraph = (props: React.ComponentPropsWithoutRef<typeof IonText>) => (
  <p>
    <IonText color="primary" {...props} />
  </p>
);

export default Paragraph;
