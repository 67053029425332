import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import useThunkDispatch from '../hooks/useThunkDispatch';
import { getPostFollow } from '../selectors';
import { dispatchAndAlert } from '../services/dispatchUtils';
import { createFollow, deleteFollow } from '../thunks/apiThunks';
import { JSONApi, State } from '../types';

const useFollowHandler = (
  post: JSONApi.PostResource,
  group: JSONApi.GroupResource,
  onSuccess?: () => void
) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const dispatch = useThunkDispatch();

  const follow = getPostFollow(apiData, post);

  return useCallback(() => {
    if (follow) {
      dispatchAndAlert(
        dispatch,
        deleteFollow(group.attributes.slug, follow),
        'forms.follow.delete.success',
        'forms.follow.delete.error',
        onSuccess
      );
    } else {
      dispatchAndAlert(
        dispatch,
        createFollow(group.attributes.slug, post),
        'forms.follow.create.success',
        'forms.follow.create.error',
        onSuccess
      );
    }
  }, [dispatch, follow, group.attributes.slug, onSuccess, post]);
};

export default useFollowHandler;
