import { useCallback, useState } from 'react';

import useThunkDispatch from '../hooks/useThunkDispatch';
import { deleteAttachment } from '../thunks/apiThunks';
import { JSONApi } from '../types';

export type AttachmentsFieldManager = {
  addAttachment: (attachment: JSONApi.AttachmentResource) => void;
  attachmentDidUpload: () => void;
  attachmentWillUpload: () => void;
  attachments: JSONApi.AttachmentResource[];
  isUploading: boolean;
  removeAttachment: (attachment: JSONApi.AttachmentResource) => void;
  reset: () => void;
};

const useAttachmentsFieldManager = (initialAttachments?: JSONApi.AttachmentResource[]) => {
  const dispatch = useThunkDispatch();
  const [attachments, setAttachments] = useState<JSONApi.AttachmentResource[]>(
    initialAttachments ?? []
  );
  const [isUploading, setIsUploading] = useState(false);

  const addAttachment = useCallback(
    (attachment: JSONApi.AttachmentResource) => {
      if (!attachments.includes(attachment)) {
        const newAttachments = [...attachments, attachment];
        setAttachments(newAttachments);
      }
    },
    [attachments]
  );

  const attachmentDidUpload = useCallback(() => {
    setIsUploading(false);
  }, []);

  const attachmentWillUpload = useCallback(() => {
    setIsUploading(true);
  }, []);

  const reset = useCallback(() => {
    setAttachments([]);
  }, []);

  const removeAttachment = useCallback(
    (toRemove: JSONApi.AttachmentResource) => {
      const newAttachments = attachments.filter(attachment => attachment.id !== toRemove.id);
      setAttachments(newAttachments);
      dispatch(deleteAttachment(toRemove));
    },
    [attachments, dispatch]
  );

  const attachmentsFieldManager: AttachmentsFieldManager = {
    addAttachment,
    attachmentDidUpload,
    attachmentWillUpload,
    attachments,
    isUploading,
    removeAttachment,
    reset
  };

  return attachmentsFieldManager;
};

export default useAttachmentsFieldManager;
