import { IonContent } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ChangePasswordForm from '../components/forms/ChangePasswordForm';
import Page from '../components/layout/Page';
import PageHeader from '../components/layout/PageHeader';
import PageTitle from '../components/layout/PageTitle';
import { JSONApi } from '../types';

type Props = {
  user: JSONApi.UserResource;
};

const ChangePasswordPage = ({ user }: Props) => (
  <Page className="centered tight">
    <PageHeader defaultBackHref="/me-and-my-data" />
    <PageTitle id="pages.changePassword.title" />
    <IonContent className="canvas ion-padding">
      <div>
        <h1 className="ion-no-margin ion-padding">
          <FormattedMessage id="pages.changePassword.title" />
        </h1>
        <ChangePasswordForm user={user} />
      </div>
    </IonContent>
  </Page>
);

export default ChangePasswordPage;
