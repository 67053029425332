import { IonGrid, IonRow } from '@ionic/react';
import { attachOutline, imagesOutline, peopleOutline } from 'ionicons/icons';
import React from 'react';

import AdminPage from '../components/layout/AdminPage';
import SelectSectionTile from '../components/SelectSectionTile';

import './SectionSelector.scss';

const AdminHomePage = () => (
  <AdminPage h2Key="pages.admin.home.title">
    {() => (
      <IonGrid className="section-selector">
        <IonRow>
          <SelectSectionTile
            icon={peopleOutline}
            routerLink="/admin/groups"
            tKey="pages.admin.groups.link"
          />
          <SelectSectionTile
            icon={imagesOutline}
            routerLink="/admin/images"
            tKey="pages.admin.images.link"
          />
          <SelectSectionTile
            icon={attachOutline}
            routerLink="/admin/attachments"
            tKey="pages.admin.attachments.link"
          />
        </IonRow>
      </IonGrid>
    )}
  </AdminPage>
);

export default AdminHomePage;
