const forms = {
  block: {
    content: {
      error: 'Failed to hide this content. Please reload and try again, or contact support.',
      success: 'You have hidden this content'
    },
    delete: {
      error: 'Failed to delete that block. Please reload and try again, or contact support.',
      success: 'You are no longer blocking this content.'
    },
    member: {
      error: 'Failed to block this member. Please reload and try again, or contact support.',
      success: 'You have blocked this member'
    }
  },
  comment: {
    create: {
      success: 'Your comment has been posted.'
    },
    delete: {
      error: 'Failed to delete that comment. Please reload and try again, or contact support.',
      success: 'That comment is now deleted.'
    },
    submitButton: 'Add comment',
    update: {
      success: 'Your comment is updated.'
    }
  },
  discussion: {
    create: {
      success: 'Your discussion has been posted.'
    },
    update: {
      success: 'Your discussion has been updated.'
    }
  },
  editMember: {
    cantLeaveGroup:
      'You are the owner of this group, so you cannot leave it unless you specify a different owner.',
    leaveGroup: 'Leave Group',
    update: {
      error:
        'Failed to update your membership details. Please reload and try again, or contact support.',
      success: 'Your membership details have been updated.'
    }
  },
  emailAddress: {
    delete: {
      failure:
        'Failed to remove that email address. Please reload and try again, or contact support.',
      success: 'You have removed that email address from your account.'
    },
    promote: {
      failure:
        'Failed to promote that email address to primary. Please reload and try again, or ' +
        'contact support.',
      success: 'That email address is now your primary email address.'
    }
  },
  emailConfirmation: {
    create: {
      failure:
        "We couldn't send a confirmation email to that address. Are you sure you typed it " +
        'correctly?',
      success: 'We just sent you a confirmation email. Please check your inbox.'
    }
  },
  event: {
    create: {
      success: 'Your event has been posted.'
    },
    update: {
      success: 'Your event has been updated.'
    }
  },
  facet: {
    keyAndCount: '{key} ({count})'
  },
  flag: {
    create: {
      success: 'You successfully flagged this content for moderator review.'
    }
  },
  follow: {
    create: {
      error: 'Failed to follow this post. Please reload and try again, or contact support.',
      success: 'You are now following this post.'
    },
    delete: {
      error: 'Failed to unfollow this post. Please reload and try again, or contact support.',
      success: 'You are no longer following this post.'
    }
  },
  forgotPassword: {
    create: {
      failed:
        'There is no account with that email address in our system. ' +
        'Are you sure you typed it correctly?',
      success: 'We just sent you a password recovery email. Please check your inbox.'
    },
    h2: 'Forgot your password?',
    imageAlt: 'Forgot password form banner image'
  },
  group: {
    approve: {
      error: 'Failed to approve the group. Please reload and try again, or contact support.',
      success: 'You just approved the group.'
    },
    reject: {
      error: 'Failed to reject the group. Please reload and try again, or contact support.',
      success: 'You just rejected the group.'
    },
    update: {
      error: 'Failed to update the group. Please reload and try again, or contact support.',
      success: 'You successfully updated the group.'
    }
  },
  invites: {
    addAnother: 'Add another',
    addManyAtOnce: 'add many at once',
    create: {
      success: 'Your invites were sent successfully.'
    }
  },
  jobApplication: {
    create: {
      success: 'Your job application has been submitted.'
    }
  },
  jobPost: {
    create: {
      success: 'Your job has been posted.'
    },
    update: {
      success: 'Your job post has been updated.'
    }
  },
  leaveGroup: {
    close: {
      error: 'Failed to freeze this member profile. Reload and try again, or contact support.',
      success: 'You successfully froze this member profile.'
    },
    closeButton: 'Freeze Membership',
    delete: {
      error: 'Failed to delete this member profile. Reload and try again, or contact support.',
      success: 'You successfully deleted this member profile.'
    },
    deleteButton: 'Permanently Delete Membership',
    open: {
      error: 'Failed to unfreeze this member profile. Reload and try again, or contact support.',
      success: 'You successfully unfroze this member profile.'
    },
    openButton: 'Unfreeze Membership'
  },
  link: {
    create: {
      error: 'Failed to add that link. Reload and try again, or contact support.',
      success: 'You just added a link.'
    },
    delete: {
      error: 'Failed to remove that link. Reload and try again, or contact support.',
      success: 'You just removed that link.'
    }
  },
  login: {
    submitButton: 'Login',
    success: 'Welcome back! You are now logged in.'
  },
  logout: {
    submitButton: 'Logout',
    success: 'You are now logged out.'
  },
  moderateComment: {
    reject: {
      error:
        'Failed to remove this comment from the group. Reload and try again, or contact support.',
      success: 'You have removed this comment from the group.'
    }
  },
  moderateFlag: {
    dismiss: {
      error: 'Failed to dismiss that flag. Reload and try again, or contact support.',
      success: 'You have dismissed that flag from the group and will no longer see it in the queue.'
    }
  },
  moderateInvite: {
    revoke: {
      error: 'Failed to revoke that invite. Reload and try again, or contact support.',
      success:
        `You have revoked that invite. The recipient can still try to join the group, but ` +
        `will no longer be approved for access automatically.`
    }
  },
  moderateMember: {
    approve: {
      error: "Failed to approve this member's access. Reload and try again, or contact support.",
      success: 'You have approved this member for access to the group.'
    },
    changeRole: {
      error: "Failed to change this member's role. Reload and try again, or contact support.",
      success: "You have changed this member's role."
    },
    reject: {
      error:
        'Failed to remove this member from the group. Reload and try again, or contact support.',
      success: 'You have removed this member from the group.'
    }
  },
  moderatePost: {
    approve: {
      error: 'Failed to approve this post. Reload and try again, or contact support.',
      success: 'You have approved this post.'
    },
    reject: {
      error: 'Failed to reject this post. Reload and try again, or contact support.',
      success: 'You have removed this post from the group.'
    }
  },
  newEmailAddress: {
    create: {
      success: 'You have added a new email address to your account.'
    }
  },
  newMessage: {
    create: {
      success: 'Your message has been sent.'
    }
  },
  newSubscriptionLocationFilter: {
    create: {
      attributeName: 'location',
      error: 'Failed to add your location filter. Reload and try again, or contact support.',
      searchTitle: 'Choose a location',
      success: 'Your location filter has been created.',
      title: 'Add a location filter'
    }
  },
  newsItem: {
    create: {
      success: 'Your news item has been posted.'
    },
    update: {
      success: 'Your news item has been updated.'
    }
  },
  note: {
    create: {
      success: 'Your note has been added to this conversation.'
    },
    submitButton: 'Send'
  },
  password: {
    update: {
      success: 'Your password has been updated.'
    }
  },
  payment: {
    cancelPlan: {
      error: 'Failed to cancel your plan. Please reload and try again, or contact support.',
      success: 'Successfully canceled your plan'
    },
    overCapBy: '{count, plural, one {# member over cap} other {# members over cap}}',
    perMonth: '/mo',
    perYear: '/yr',
    reactivatePlan: {
      error: 'Failed to reactivate your plan. Please reload and try again, or contact support.',
      success: 'Successfully reactivated your plan'
    },
    selected: 'Selected',
    title: 'Pick a new plan'
  },
  pinPost: {
    create: {
      error: 'Failed to pin that post. Please reload and try again, or contact support.',
      success: 'Pinned post'
    },
    delete: {
      error: 'Failed to unpin that post. Please reload and try again, or contact support.',
      success: 'Unpinned post'
    }
  },
  post: {
    delete: {
      error: 'Failed to delete this post. Please reload and try again, or contact support.',
      success: 'That post is now permanently removed.'
    }
  },
  rsvp: {
    create: {
      error: 'Failed to RSVP to this event. Please reload and try again, or contact support.',
      success: "You are now RSVP'd to this event."
    },
    delete: {
      error:
        'Failed to undo your RSVP to this event. Please reload and try again, or contact support.',
      success: "You are no longer RSVP'd to this event."
    }
  },
  rule: {
    create: {
      success: 'You have added a new rule for the group.'
    }
  },
  save: {
    create: {
      error: 'Failed to save. Please reload and try again, or contact support.',
      success: 'Saved successfully.'
    },
    delete: {
      error: 'Failed to unsave. Please reload and try again, or contact support.',
      success: 'Save removed.'
    },
    save: 'Save',
    unsave: 'Unsave'
  },
  search: {
    sort: {
      label: 'Sort by'
    },
    sortOptions: {
      auto: 'Auto',
      firstName: 'First Name',
      joinDate: 'Date Joined',
      lastName: 'Last Name',
      location: 'Location',
      postDate: 'Date Posted',
      startTime: 'Start Time',
      title: 'Title'
    },
    update: {
      failed: 'Whoops, something went wrong!'
    }
  },
  subscription: {
    update: {
      error: "Oops. We couldn't change your subscription at this time. Please try again.",
      success: 'Your subscription has been updated.'
    }
  },
  subscriptionLocationFilter: {
    delete: {
      error: "Oops. We couldn't delete your location filter. Please try again.",
      success: 'Your location filter has been deleted.'
    }
  },
  trait: {
    create: {
      success: 'Successfully added a category to the group.'
    },
    delete: {
      error: 'Failed to remove that category. Please reload and try again, or contact support.',
      success: 'Successfully removed that category from the group.'
    },
    update: {
      error: 'Failed to update that category. Please reload and try again, or contact support.',
      success: 'Successfully updated that category.'
    }
  },
  traitOption: {
    create: {
      success: 'Successfully added an option to the category.'
    },
    delete: {
      error: 'Failed to remove that option. Please reload and try again, or contact support.',
      success: 'Successfully removed that option from the category.'
    }
  },
  user: {
    delete: {
      closeButton: 'Delete Account',
      error: 'Failed to delete your user account. Please reload and try again, or contact support.',
      success: 'Successfully deleted your user account.'
    },
    update: {
      success: 'Your profile has been updated.'
    }
  },
  verificationSettings: {
    changeOkToShareInfo: {
      error: "Sorry, that didn't work. Try reloading the page and doing it again.",
      success: 'Your data sharing option has been successfully updated.'
    }
  },
  verificationWaiting: {
    submitButton: 'Add email',
    success:
      "You've successfully added your new email address. Check your inbox for a confirmation email."
  },
  welcome: {
    create: {
      completed:
        'Your welcome has been sent. Click the button below to see the conversation thread.',
      explainer:
        'Clicking the button below will send {name} an email "welcome" ' +
        'to this group from you. Sending a welcome also starts a ' +
        'conversation thread, so you can follow up there to add a ' +
        'personal message.',
      submitButton: 'Send welcome',
      success: 'Your welcome has been sent.',
      title: 'Welcome {name}',
      visit: 'Visit conversation'
    }
  }
};

export default forms;
