export function cancelablePromise<T>(promise: Promise<T>) {
  let hasCanceled = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(
      val => {
        if (hasCanceled) {
          return reject({ isCanceled: true });
        }
        return resolve(val);
      },
      error => {
        if (hasCanceled) {
          return reject({ isCanceled: true });
        }
        return reject(error);
      }
    );
  });

  return {
    cancel() {
      hasCanceled = true;
    },
    promise: wrappedPromise
  };
}
