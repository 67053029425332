import { DateTime } from 'luxon';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Currency from '../../components/Currency';
import ChangePreviewModal from '../../components/forms/ChangePreviewModal';
import LocalLoadingIndicator from '../../components/LocalLoadingIndicator';
import Nbsp from '../../components/Nbsp';
import PlanMemberCapLabel from '../../components/PlanMemberCapLabel';
import { Opener } from '../../hooks/useOpener';
import { JSONApi } from '../../types';

type Props = {
  group: JSONApi.GroupResource;
  handleAccept: () => void;
  handleCancel: () => void;
  opener: Opener;
  preview: JSONApi.PlanChangePreviewResource | undefined;
};

const PlanChangePreviewModal = ({ group, handleAccept, handleCancel, opener, preview }: Props) => {
  let acceptKey = 'modals.planChangePreview.selectPlan';
  if (group.attributes.planActive) {
    acceptKey = 'modals.planChangePreview.changePlan';
  }

  return (
    <ChangePreviewModal
      acceptButtonTextKey={acceptKey}
      group={group}
      handleAccept={handleAccept}
      handleCancel={handleCancel}
      opener={opener}
      titleKey="modals.planChangePreview.title"
    >
      {preview ? (
        <>
          <PlanMemberCapLabel cap={preview.attributes.newMemberCap} />
          {preview.attributes.newPlanIsFree ? (
            <p>
              <FormattedMessage id="models.planChangePreview.attributes.newPlanIsFree.explanation" />
            </p>
          ) : (
            <p>
              <FormattedMessage id="models.planChangePreview.attributes.immediateCostInCents.labelPrefix" />
              <strong>
                <Currency valueInCents={preview.attributes.immediateCostInCents} />
              </strong>
              <FormattedMessage id="models.planChangePreview.attributes.immediateCostInCents.labelSuffix" />
              <Nbsp />
              <FormattedMessage id="models.planChangePreview.attributes.newPlanCostPerPeriodInCents.labelPrefix" />
              <strong>
                <Currency valueInCents={preview.attributes.newPlanCostPerPeriodInCents} />
              </strong>
              <Nbsp />
              <FormattedMessage id="dictionary.on" />
              <Nbsp />
              <strong>
                {preview.attributes.nextPaymentDate.toLocaleString(DateTime.DATE_FULL)}
              </strong>
              <FormattedMessage id="punctuation.period" />
              <Nbsp />
              <FormattedMessage
                id="models.planChangePreview.attributes.newPeriod.label"
                values={{ period: preview.attributes.newPeriod }}
              />
            </p>
          )}
          <p>
            <FormattedMessage id="models.plan.finePrint" />
          </p>
        </>
      ) : (
        <LocalLoadingIndicator />
      )}
    </ChangePreviewModal>
  );
};

export default PlanChangePreviewModal;
