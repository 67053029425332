import {
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonList,
  IonRow
} from '@ionic/react';
import { documentAttach } from 'ionicons/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import AdminAutoModerationResult from '../components/AdminAutoModerationResult';
import ImgOriented from '../components/ImgOriented';
import InfiniteScroll from '../components/InfiniteScroll';
import AdminPage from '../components/layout/AdminPage';
import LocalLoadingIndicator from '../components/LocalLoadingIndicator';
import useApiData from '../hooks/useApiData';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { getAllAttachments, getAttachmentAutoModerationResult } from '../selectors';
import { dispatchAndAlert } from '../services/dispatchUtils';
import {
  adminApproveAttachment,
  adminLoadAttachments,
  adminLoadMoreAttachments
} from '../thunks/apiThunks';
import { JSONApi, Models, State } from '../types';

import './AdminImagesPage.scss';
import './Img.scss';

const AdminAttachmentsPage = () => {
  const apiData = useSelector((root: State.Root) => root.api);
  const dispatch = useThunkDispatch();
  const [attachments, setAttachments] = useState<JSONApi.Resource<Models.Attachment>[]>([]);

  const findAttachments = useCallback(
    () => getAllAttachments(apiData).filter(attachment => !attachment.attributes.adminApproved),
    [apiData]
  );

  const { loading, responseData } = useApiData<Models.Attachment>(
    adminLoadAttachments(),
    findAttachments,
    'AdminAttachmentsPage'
  );

  const generateHandleApproveClick = useCallback(
    (attachment: JSONApi.AttachmentResource) => () => {
      dispatchAndAlert(
        dispatch,
        adminApproveAttachment(attachment),
        'pages.admin.attachments.approve.success',
        'pages.admin.attachments.approve.failure'
      );
    },
    [dispatch]
  );

  useEffect(() => {
    setAttachments(responseData);
  }, [responseData, setAttachments]);

  if (loading) {
    return <LocalLoadingIndicator />;
  }

  return (
    <AdminPage h2Key="pages.admin.attachments.title">
      {contentRef => (
        <>
          <IonList>
            {attachments.map(attachment => {
              const moderationResult = getAttachmentAutoModerationResult(apiData, attachment);
              return (
                <IonItem key={attachment.id}>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        {attachment.attributes.isImage ? (
                          <ImgOriented
                            alt={attachment.id}
                            className="image-attachment ion-padding"
                            src={attachment.attributes.url}
                          />
                        ) : (
                          <IonButton
                            className="flat"
                            color="secondary"
                            download={attachment.attributes.filename}
                            href={attachment.attributes.url}
                            key={attachment.id}
                            rel="noopener noreferrer"
                            shape="round"
                            size="small"
                            target="_blank"
                          >
                            <IonIcon icon={documentAttach} slot="start" />
                            <span className="truncate">{attachment.attributes.filename}</span>
                          </IonButton>
                        )}
                      </IonCol>
                      {attachment.attributes.isImage && (
                        <AdminAutoModerationResult autoModerationResult={moderationResult} />
                      )}
                    </IonRow>
                  </IonGrid>
                  <IonButtons slot="end">
                    <IonButton
                      color="success"
                      expand="full"
                      fill="solid"
                      onClick={generateHandleApproveClick(attachment)}
                    >
                      <FormattedMessage id="dictionary.approve" />
                    </IonButton>
                  </IonButtons>
                </IonItem>
              );
            })}
          </IonList>
          <InfiniteScroll<Models.Attachment>
            contentRef={contentRef}
            loadMoreActionCreator={adminLoadMoreAttachments}
          />
        </>
      )}
    </AdminPage>
  );
};

export default AdminAttachmentsPage;
