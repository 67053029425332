import React from 'react';

import FormModal from '../components/FormModal';
import EventForm from '../components/forms/EventForm';
import { Opener } from '../hooks/useOpener';
import { JSONApi } from '../types';

type Props = {
  event?: JSONApi.EventResource;
  group: JSONApi.GroupResource;
  onSuccess?: () => void;
  opener: Opener;
  titleKey: string;
};

const EventFormModal = ({ event, group, onSuccess, opener, titleKey }: Props) => (
  <FormModal keyboardClose={false} opener={opener} titleKey={titleKey}>
    {modalContentRef => (
      <EventForm
        autofocus={opener.isPresented}
        contentForwardRef={modalContentRef}
        event={event}
        group={group}
        onSuccess={onSuccess}
        opener={opener}
      />
    )}
  </FormModal>
);

export default EventFormModal;
