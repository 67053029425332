import { IonContent, IonItem, IonRouterLink } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import GroupAvatar from '../components/GroupAvatar';
import Heading from '../components/Heading';
import Page from '../components/layout/Page';
import Nbsp from '../components/Nbsp';
import Paragraph from '../components/Paragraph';
import useCurrentMember from '../hooks/useCurrentMember';
import { JSONApi, ModelAttributes } from '../types';

type Props = {
  group: JSONApi.GroupResource;
};

const RejectedGroupPage = ({ group }: Props) => {
  const currentMember = useCurrentMember();

  const bannerStyle: React.CSSProperties = {
    backgroundColor: group.attributes.primaryColor
  };
  if (group.attributes.bgImageUrl) {
    bannerStyle.backgroundImage = `url(${group.attributes.bgImageUrl})`;
  }

  return (
    <Page>
      <IonContent className="canvas group-home-page">
        <div className="banner" style={bannerStyle} />
        <div className="group-name ion-padding">
          <GroupAvatar group={group} slot="start" />
          <Heading level={1}>{group.attributes.name}</Heading>
        </div>
        <IonItem color="transparent" lines="none">
          <Heading level={2}>
            <FormattedMessage id="pages.rejectedGroup.h2" />
          </Heading>
        </IonItem>
        <IonItem color="transparent" lines="none">
          <Paragraph>
            <FormattedMessage id="pages.rejectedGroup.body" />
          </Paragraph>
        </IonItem>
        <IonItem color="transparent" lines="none">
          <IonRouterLink routerLink="/search">
            <FormattedMessage id="dictionary.findAnotherGroup" />
          </IonRouterLink>
          <Nbsp />
          <FormattedMessage id="dictionary.toJoin" />
        </IonItem>

        {currentMember && currentMember.attributes.role === ModelAttributes.MemberRole.OWNER && (
          <IonItem color="transparent" lines="none">
            <Paragraph>
              <FormattedMessage id="pages.rejectedGroup.ownerSteps" />
            </Paragraph>
          </IonItem>
        )}
      </IonContent>
    </Page>
  );
};

export default RejectedGroupPage;
