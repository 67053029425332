import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';

import { alertError } from '../actions/notificationActions';
import LandingPage from '../components/LandingPage';
import UserAccountWizardPage from '../components/UserAccountWizardPage';
import useCurrentUser from '../hooks/useCurrentUser';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { getGroup, getUserMemberForGroup } from '../selectors';
import { JSONApi, ModelAttributes, SessionState, State } from '../types';

type Props = {
  allowUnapproved?: boolean;
  children: (group: JSONApi.GroupResource, member: JSONApi.MemberResource) => React.ReactElement;
  redirect: string;
};

export const RequireGroupAndMember = ({ allowUnapproved = false, children, redirect }: Props) => {
  const dispatch = useThunkDispatch();

  const apiData = useSelector((root: State.Root) => root.api);
  const currentUser = useCurrentUser();
  const userState = useSelector((root: State.Root) => root.session.currentUser.state);

  const { slug } = useParams<{ slug: string }>();
  const group = getGroup(apiData, slug);
  const member = currentUser && group && getUserMemberForGroup(apiData, currentUser, group);

  if (userState === SessionState.LOADING) {
    return null;
  }

  if (userState === SessionState.FAILED || userState === SessionState.NONE) {
    if (group) {
      return <UserAccountWizardPage group={group} />;
    }
    return <LandingPage />;
  }

  if (
    !group ||
    !member ||
    (!allowUnapproved && member.attributes.status !== ModelAttributes.MemberStatus.APPROVED)
  ) {
    dispatch(alertError('errors.member.approvedMemberRequired'));
    return <Redirect to={redirect} />;
  }

  return children(group, member);
};

export default RequireGroupAndMember;
