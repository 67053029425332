import { Action } from 'redux';

import { AjaxError } from '../services/ajaxRequest';
import { ApiEndpoint, JSONApi } from '../types';

export enum ApiAction {
  APPEND_NEW_RECORD_INTO_HAS_MANY_RELATIONSHIP = 'api/APPEND_NEW_RECORD_INTO_HAS_MANY_RELATIONSHIP',
  CLEAR_CACHED_RESPONSES_CONTAINING_RESOURCE = 'api/CLEAR_CACHED_RESPONSES_CONTAINING_RESOURCE',
  CLEAR_CACHED_RESPONSES_FOR_ENDPOINT = 'api/CLEAR_CACHED_RESPONSES_FOR_ENDPOINT',
  DELETE_RESOURCE = 'api/DELETE_RESOURCE',
  ERROR = 'api/ERROR',
  SUCCESS = 'api/SUCCESS'
}

export interface ApiAppendNewRecordIntoHasManyRelationshipAction extends Action {
  payload: {
    belongsToRelationshipName: string;
    hasManyRelationshipName: string;
    resource: JSONApi.BaseResource;
  };
  type: ApiAction.APPEND_NEW_RECORD_INTO_HAS_MANY_RELATIONSHIP;
}

export interface ApiClearCachedResponsesContainingResourceAction extends Action {
  payload: {
    id: string;
    type: string;
  };
  type: ApiAction.CLEAR_CACHED_RESPONSES_CONTAINING_RESOURCE;
}

export interface ApiClearCachedResponsesForEndpointAction extends Action {
  payload: {
    endpoint: ApiEndpoint;
  };
  type: ApiAction.CLEAR_CACHED_RESPONSES_FOR_ENDPOINT;
}

export interface ApiDeleteResourceAction extends Action {
  payload: {
    id: string;
    type: string;
  };
  type: ApiAction.DELETE_RESOURCE;
}

export interface ApiErrorAction extends Action {
  payload: {
    endpoint: ApiEndpoint;
    error: AjaxError;
  };
  type: ApiAction.ERROR;
}

export interface ApiSuccessAction extends Action {
  payload: {
    cacheKey: string;
    endpoint: ApiEndpoint;
    response: JSONApi.Response;
    status: number;
  };
  type: ApiAction.SUCCESS;
}

export type ApiActionTypes =
  | ApiAppendNewRecordIntoHasManyRelationshipAction
  | ApiClearCachedResponsesContainingResourceAction
  | ApiClearCachedResponsesForEndpointAction
  | ApiDeleteResourceAction
  | ApiErrorAction
  | ApiSuccessAction;

export const apiClearCachedResponsesContainingResource = (id: string, type: string) => ({
  payload: { id, type },
  type: ApiAction.CLEAR_CACHED_RESPONSES_CONTAINING_RESOURCE
});

export const apiClearCachedResponsesForEndpoint = (endpoint: ApiEndpoint) => ({
  payload: { endpoint },
  type: ApiAction.CLEAR_CACHED_RESPONSES_FOR_ENDPOINT
});

export const apiAppendNewRecordIntoHasManyRelationship = (
  resource: JSONApi.BaseResource,
  belongsToRelationshipName: string,
  hasManyRelationshipName: string
) => ({
  payload: { belongsToRelationshipName, hasManyRelationshipName, resource },
  type: ApiAction.APPEND_NEW_RECORD_INTO_HAS_MANY_RELATIONSHIP
});

export const apiDeleteResource = (id: string, type: string) => ({
  payload: { id, type },
  type: ApiAction.DELETE_RESOURCE
});

export const apiError = (error: string, endpoint: ApiEndpoint) => ({
  payload: { endpoint, error },
  type: ApiAction.ERROR
});

export const apiSuccess = (
  response: JSONApi.Response,
  endpoint: ApiEndpoint,
  cacheKey: string | undefined,
  status: number
) => ({
  payload: { cacheKey, endpoint, response, status },
  type: ApiAction.SUCCESS
});
