import { IonContent, IonFab, IonFabButton, IonItem } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Page from '../components/layout/Page';
import PageHeader from '../components/layout/PageHeader';
import MembersList from '../components/MembersList';
import useRsvpHandler from '../hooks/useRsvpHandler';
import { getEventAttendees, getPostViewerRsvp } from '../selectors';
import { JSONApi, State } from '../types';

type Props = {
  event: JSONApi.EventResource;
  group: JSONApi.GroupResource;
};

const EventAttendeesPage = ({ event, group }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);

  const attendees = getEventAttendees(apiData, event);
  const rsvp = getPostViewerRsvp(apiData, event);

  const handleClickRsvp = useRsvpHandler(event, group);

  return (
    <Page className="centered wide">
      <PageHeader defaultBackHref={`/g/${group.attributes.slug}/events/${event.attributes.slug}`} />
      <IonFab horizontal="end" id="event-attendees-fab" vertical="bottom">
        <IonFabButton
          activated={!!rsvp}
          color={rsvp ? 'tertiary' : 'secondary'}
          onClick={handleClickRsvp}
        >
          <FormattedMessage id="dictionary.rsvp" />
        </IonFabButton>
      </IonFab>
      <IonContent className="canvas" fullscreen>
        <IonItem color="transparent" lines="none">
          <h1>
            <FormattedMessage
              id="pages.event.attendees.title"
              values={{ title: event.attributes.title }}
            />
          </h1>
        </IonItem>
        <MembersList group={group} members={attendees} />
        {attendees.length === 0 && (
          <div className="ion-padding">
            <FormattedMessage id="pages.event.attendees.noResults" />
          </div>
        )}
      </IonContent>
    </Page>
  );
};

export default EventAttendeesPage;
