import { IonCard } from '@ionic/react';
import { FormApi } from 'final-form';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import Form from '../components/forms/Form';
import FormContainer from '../components/forms/FormContainer';
import FormError from '../components/forms/FormError';
import FormInput from '../components/forms/FormInput';
import FormItem from '../components/forms/FormItem';
import FormSubmit from '../components/forms/FormSubmit';
import FormTextarea from '../components/forms/FormTextarea';
import PersonalizeGroupForm from '../components/forms/PersonalizeGroupForm';
import SubmitError from '../components/forms/SubmitError';
import ModeratorPage from '../components/layout/ModeratorPage';
import LocalLoadingIndicator from '../components/LocalLoadingIndicator';
import { maxTextInputLength, maxTitleLength } from '../globals';
import useCurrentUser from '../hooks/useCurrentUser';
import useMountedTracking from '../hooks/useMountedTracking';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { handleFormSubmission } from '../services/formUtils';
import { updateGroup } from '../thunks/apiThunks';
import { JSONApi } from '../types';

type FormValues = {
  description: string;
  name: string;
};

type Props = {
  group: JSONApi.GroupResource;
};

const ManageGroupCustomizationPage = ({ group }: Props) => {
  const currentUser = useCurrentUser();
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const initialValues: FormValues = {
    description: group.attributes.description,
    name: group.attributes.name
  };

  const handleSubmit = useCallback(
    async (values: FormValues, form: FormApi<FormValues>) =>
      handleFormSubmission({
        action: updateGroup(values, group.id),
        dispatch,
        errorTranslationLocation: 'group',
        form,
        intl,
        isMounted,
        successTKey: 'forms.group.update.success',
        values
      }),
    [dispatch, group.id, intl, isMounted]
  );

  const validationSchema = Yup.object().shape({
    description: Yup.string()
      .nullable()
      .max(
        maxTextInputLength,
        intl.formatMessage(
          { id: 'errors.group.description.tooLong' },
          { count: maxTextInputLength }
        )
      ),
    name: Yup.string()
      .required(intl.formatMessage({ id: 'errors.group.name.blank' }))
      .max(
        maxTitleLength,
        intl.formatMessage({ id: 'errors.group.name.tooLong' }, { count: maxTitleLength })
      )
  });

  if (!currentUser) {
    return <LocalLoadingIndicator />;
  }

  return (
    <ModeratorPage group={group} h2Key="pages.manageGroup.customize.title">
      {() => (
        <>
          <IonCard>
            <FormContainer<FormValues>
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ form, handleSubmit }) => (
                <Form<FormValues> onFormSubmit={handleSubmit} submit={form.submit}>
                  <SubmitError />
                  <FormError name="name" />
                  <FormItem id="models.group.attributes.name.label" position="stacked">
                    <FormInput
                      color="primary"
                      name="name"
                      placeholder={intl.formatMessage({
                        id: 'models.group.attributes.name.placeholder'
                      })}
                      type="text"
                    />
                  </FormItem>
                  <FormError name="description" />
                  <FormItem id="models.group.attributes.description.label" position="stacked">
                    <FormTextarea
                      color="primary"
                      name="description"
                      placeholder={intl.formatMessage({
                        id: 'models.group.attributes.description.placeholder'
                      })}
                    />
                  </FormItem>
                  <div className="ion-padding">
                    <FormSubmit>
                      <FormattedMessage id="dictionary.save" />
                    </FormSubmit>
                  </div>
                </Form>
              )}
            </FormContainer>
          </IonCard>
          <PersonalizeGroupForm group={group} />
        </>
      )}
    </ModeratorPage>
  );
};

export default ManageGroupCustomizationPage;
