import { IonIcon, IonItem } from '@ionic/react';
import { checkmarkCircle, radioButtonOff } from 'ionicons/icons';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import GroupAvatar from '../components/GroupAvatar';
import { JSONApi } from '../types';

const GroupMenuItem = ({
  active,
  group,
  onClick,
  routerLink,
  ...itemProps
}: Pick<React.ComponentPropsWithoutRef<typeof IonItem>, 'disabled' | 'lines'> & {
  active: boolean;
  group: JSONApi.GroupResource;
  onClick: () => Promise<boolean> | undefined;
  routerLink: string;
}) => {
  const history = useHistory();

  // More stupidity because of Ionic silliness. What happens here is that the menu
  // is closed in the onClick, but if we immediately change the URL then we will get
  // an error about trying to change the menu while it is in the middle of animating.
  // The error itself isn't a problem, but Ionic is triggering the debugger in the handler
  // for it, which _does_ cause a problem for anyone having the dev tools open. So, we
  // just redirect the page after the menu is closed. This is super annoying but seems
  // ok for the limited case of switching groups.
  const handleClick = useCallback(() => {
    const promise = onClick();
    if (promise) {
      promise.then(() => {
        history.push(routerLink);
      });
    } else {
      history.push(routerLink);
    }
  }, [history, onClick, routerLink]);

  return (
    <IonItem
      {...itemProps}
      button
      className="group-item"
      color="light"
      detail={false}
      key={group.id}
      onClick={handleClick}
      role="button"
    >
      {active ? (
        <IonIcon icon={checkmarkCircle} slot="start" />
      ) : (
        <IonIcon icon={radioButtonOff} slot="start" />
      )}
      <GroupAvatar group={group} slot="start" />
      {group.attributes.name}
    </IonItem>
  );
};

export default GroupMenuItem;
