import { ModalOptions, useIonModal } from '@ionic/react';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import { HookOverlayOptions } from '@ionic/react/dist/types/hooks/HookOverlayOptions';
import React, { useCallback, useEffect } from 'react';

import useMountedTracking from '../hooks/useMountedTracking';
import { Opener } from '../hooks/useOpener';

type ModalBodyProps = {
  children: React.ReactNode;
};

// eslint-disable-next-line react/jsx-no-useless-fragment
const ModalBody = ({ children }: ModalBodyProps) => <>{children}</>;

const Modal = ({
  children,
  onDidDismiss,
  onDidPresent,
  opener,
  ...props
}: {
  children: React.ReactNode;
  opener: Opener;
} & ((HookOverlayOptions & Omit<ModalOptions, 'component' | 'componentProps'>) | undefined)) => {
  const isMounted = useMountedTracking();

  const handleDidDismiss = useCallback(
    (event: CustomEvent<OverlayEventDetail>) => {
      if (onDidDismiss) {
        onDidDismiss(event);
      }
      opener.close();
      opener.handleDidDismiss();
    },
    [onDidDismiss, opener]
  );

  const handleDidPresent = useCallback(
    (event: CustomEvent<OverlayEventDetail>) => {
      if (onDidPresent) {
        onDidPresent(event);
      }
      opener.handleDidPresent();
    },
    [onDidPresent, opener]
  );

  const [present, dismiss] = useIonModal(ModalBody, { children });

  useEffect(() => {
    if (opener.isOpen) {
      if (!opener.isPresented && isMounted.current) {
        present({
          ...props,
          onDidDismiss: handleDidDismiss,
          onDidPresent: handleDidPresent
        });
      }
    } else if (opener.isPresented && isMounted.current) {
      dismiss();
    }
  }, [
    dismiss,
    handleDidDismiss,
    handleDidPresent,
    isMounted,
    opener.isOpen,
    opener.isPresented,
    present,
    props
  ]);

  return null;
};

export default Modal;
