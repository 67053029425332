import avatar0 from '../assets/images/avatars/avatar0.png';
import avatar1 from '../assets/images/avatars/avatar1.png';
import avatar10 from '../assets/images/avatars/avatar10.png';
import avatar11 from '../assets/images/avatars/avatar11.png';
import avatar12 from '../assets/images/avatars/avatar12.png';
import avatar13 from '../assets/images/avatars/avatar13.png';
import avatar14 from '../assets/images/avatars/avatar14.png';
import avatar15 from '../assets/images/avatars/avatar15.png';
import avatar16 from '../assets/images/avatars/avatar16.png';
import avatar17 from '../assets/images/avatars/avatar17.png';
import avatar18 from '../assets/images/avatars/avatar18.png';
import avatar19 from '../assets/images/avatars/avatar19.png';
import avatar2 from '../assets/images/avatars/avatar2.png';
import avatar20 from '../assets/images/avatars/avatar20.png';
import avatar21 from '../assets/images/avatars/avatar21.png';
import avatar22 from '../assets/images/avatars/avatar22.png';
import avatar23 from '../assets/images/avatars/avatar23.png';
import avatar24 from '../assets/images/avatars/avatar24.png';
import avatar25 from '../assets/images/avatars/avatar25.png';
import avatar26 from '../assets/images/avatars/avatar26.png';
import avatar27 from '../assets/images/avatars/avatar27.png';
import avatar28 from '../assets/images/avatars/avatar28.png';
import avatar29 from '../assets/images/avatars/avatar29.png';
import avatar3 from '../assets/images/avatars/avatar3.png';
import avatar30 from '../assets/images/avatars/avatar30.png';
import avatar31 from '../assets/images/avatars/avatar31.png';
import avatar32 from '../assets/images/avatars/avatar32.png';
import avatar33 from '../assets/images/avatars/avatar33.png';
import avatar34 from '../assets/images/avatars/avatar34.png';
import avatar35 from '../assets/images/avatars/avatar35.png';
import avatar36 from '../assets/images/avatars/avatar36.png';
import avatar37 from '../assets/images/avatars/avatar37.png';
import avatar38 from '../assets/images/avatars/avatar38.png';
import avatar39 from '../assets/images/avatars/avatar39.png';
import avatar4 from '../assets/images/avatars/avatar4.png';
import avatar40 from '../assets/images/avatars/avatar40.png';
import avatar41 from '../assets/images/avatars/avatar41.png';
import avatar42 from '../assets/images/avatars/avatar42.png';
import avatar43 from '../assets/images/avatars/avatar43.png';
import avatar44 from '../assets/images/avatars/avatar44.png';
import avatar45 from '../assets/images/avatars/avatar45.png';
import avatar46 from '../assets/images/avatars/avatar46.png';
import avatar47 from '../assets/images/avatars/avatar47.png';
import avatar5 from '../assets/images/avatars/avatar5.png';
import avatar6 from '../assets/images/avatars/avatar6.png';
import avatar7 from '../assets/images/avatars/avatar7.png';
import avatar8 from '../assets/images/avatars/avatar8.png';
import avatar9 from '../assets/images/avatars/avatar9.png';

const avatars = [
  avatar0,
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9,
  avatar10,
  avatar11,
  avatar12,
  avatar13,
  avatar14,
  avatar15,
  avatar15,
  avatar16,
  avatar17,
  avatar18,
  avatar19,
  avatar20,
  avatar21,
  avatar22,
  avatar23,
  avatar24,
  avatar25,
  avatar26,
  avatar27,
  avatar28,
  avatar29,
  avatar30,
  avatar31,
  avatar32,
  avatar33,
  avatar34,
  avatar35,
  avatar36,
  avatar37,
  avatar38,
  avatar39,
  avatar40,
  avatar41,
  avatar42,
  avatar43,
  avatar44,
  avatar45,
  avatar46,
  avatar47
];

export const randomAvatarSrc = (s: string) => {
  // generate a predictable "random" number from a string by summing the character code
  // of each character in the string and use it to pick a predictable avatar
  let randomNumber = 0;
  for (let i = 0; i < s.length; i++) {
    randomNumber += s.charCodeAt(i);
  }
  const avatarId = randomNumber % 48;
  return avatars[avatarId];
};
