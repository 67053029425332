import { IonIcon } from '@ionic/react';
import { closeCircle } from 'ionicons/icons';
import React, { useCallback } from 'react';

import Img from '../../components/Img';
import OnClickLink from '../../components/OnClickLink';
import { RelationshipsFieldManager } from '../../hooks/useRelationshipsFieldManager';
import { JSONApi, Models } from '../../types';

import './ImageThumbnail.scss';

type Props = {
  fieldManager: RelationshipsFieldManager<Models.Image>;
  image: JSONApi.ImageResource;
};

export const ImageThumbnail = ({ fieldManager, image }: Props) => {
  const handleRemoveClick = useCallback(() => {
    fieldManager.remove(image);
  }, [fieldManager, image]);

  return (
    <div className="image-thumbnail">
      <OnClickLink className="remove" onClick={handleRemoveClick}>
        <IonIcon color="tertiary" icon={closeCircle} />
      </OnClickLink>
      <div className="thumbnail">
        <Img alt={image.attributes.filename} src={image.attributes.url} />
      </div>
    </div>
  );
};

export default ImageThumbnail;
