import { IonIcon, IonItem, IonText } from '@ionic/react';
import { checkmark } from 'ionicons/icons';
import React, { useCallback } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import Currency from '../../components/Currency';
import Nbsp from '../../components/Nbsp';
import { forceArray } from '../../services/arrayUtils';
import { JSONApi, ModelAttributes } from '../../types';

type ItemSubcomponentProps = {
  active: boolean;
  cap: number;
  free: boolean;
  memberCount: number;
  onClick: () => void;
  overCap: boolean;
  priceInCents: number;
  term: 'Month' | 'Year';
};

const ItemSubcomponent = (props: ItemSubcomponentProps) => {
  const { active, cap, free, memberCount, onClick, overCap, priceInCents, term } = props;
  const disabled = active || overCap;
  const itemProps: React.ComponentProps<typeof IonItem> = {
    color: active ? 'dark' : 'light',
    disabled: overCap
  };
  if (!active && !disabled) {
    itemProps.button = true;
    itemProps.className = 'ion-activatable';
    itemProps.onClick = onClick;
    itemProps.role = 'button';
  }
  return (
    <IonItem {...itemProps}>
      <strong>
        <FormattedNumber value={cap} />
      </strong>
      <Nbsp />
      <FormattedMessage id="models.member.label" />
      {overCap && (
        <IonText color="danger" slot="end">
          <strong>
            <FormattedMessage id="forms.payment.overCapBy" values={{ count: memberCount - cap }} />
          </strong>
        </IonText>
      )}
      {active && <IonIcon icon={checkmark} slot="end" />}
      {!disabled && (
        <IonText slot="end">
          {free ? (
            <strong>
              <FormattedMessage id="dictionary.free" />
            </strong>
          ) : (
            <>
              <strong>
                <Currency valueInCents={priceInCents} />
              </strong>
              <FormattedMessage id={`forms.payment.per${term}`} />
            </>
          )}
        </IonText>
      )}
    </IonItem>
  );
};

type Props = {
  group: JSONApi.GroupResource;
  onTierSelected: (tier: JSONApi.PriceTierResource, period: ModelAttributes.PlanPeriod) => void;
  priceTier: JSONApi.PriceTierResource;
  term: ModelAttributes.PlanPeriod;
};

const PriceTierSelector = ({ group, onTierSelected, priceTier, term }: Props) => {
  const selectMonthlyPlan = useCallback(() => {
    onTierSelected(priceTier, ModelAttributes.PlanPeriod.MONTHLY);
  }, [onTierSelected, priceTier]);

  const selectYearlyPlan = useCallback(() => {
    onTierSelected(priceTier, ModelAttributes.PlanPeriod.YEARLY);
  }, [onTierSelected, priceTier]);

  const groupTierData = group.attributes.planActive && group.relationships?.priceTier?.data;
  const groupCurrentTier = groupTierData && forceArray(groupTierData)[0];
  const isActiveTier = groupCurrentTier && groupCurrentTier.id === priceTier.id;
  const groupIsMonthly = group.attributes.planPeriod === ModelAttributes.PlanPeriod.MONTHLY;
  const groupIsYearly = group.attributes.planPeriod === ModelAttributes.PlanPeriod.YEARLY;
  const monthlyActive = !!isActiveTier && !!groupIsMonthly;
  const yearlyActive = !!isActiveTier && !!groupIsYearly;

  const { memberCap, monthlyPriceInCents, yearlyPriceInCents } = priceTier.attributes;
  const overCap = group.attributes.memberCount > memberCap;

  return (
    <>
      {term === ModelAttributes.PlanPeriod.MONTHLY && (
        <ItemSubcomponent
          active={monthlyActive}
          cap={memberCap}
          free={priceTier.attributes.free}
          memberCount={group.attributes.memberCount}
          onClick={selectMonthlyPlan}
          overCap={overCap}
          priceInCents={monthlyPriceInCents}
          term="Month"
        />
      )}
      {term === ModelAttributes.PlanPeriod.YEARLY && (
        <ItemSubcomponent
          active={yearlyActive}
          cap={memberCap}
          free={priceTier.attributes.free}
          memberCount={group.attributes.memberCount}
          onClick={selectYearlyPlan}
          overCap={overCap}
          priceInCents={yearlyPriceInCents}
          term="Year"
        />
      )}
    </>
  );
};

export default PriceTierSelector;
