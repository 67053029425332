import { Search } from '../types';

const blanks = {
  industry: null,
  lat: null,
  lng: null,
  location: null,
  query: null,
  radius: null,
  traitOptionIds: '',
  upcoming: null
};

const postBlanks = { companyName: null, eventType: null, jobType: null, memberSlug: null };

export const defaultConversationSearchParams = (): Search.ConversationParams => ({ query: null });

export const defaultPostSearchParams = (): Search.PostParams => ({ ...blanks, ...postBlanks });

export const defaultMemberSearchParams = (): Search.MemberParams => ({ ...blanks, country: null });
