import { IonText } from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { alertError, alertSuccess } from '../actions/notificationActions';
import confirmEmailImage from '../assets/images/design/confirm-email.png';
import BasicLink from '../components/BasicLink';
import CheckInboxPage from '../components/CheckInboxPage';
import Heading from '../components/Heading';
import Img from '../components/Img';
import Paragraph from '../components/Paragraph';
import UserAccountWizardBackButton from '../components/UserAccountWizardBackButton';
import useCurrentUser from '../hooks/useCurrentUser';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { getUserEmailAddresses } from '../selectors';
import { createEmailConfirmation } from '../thunks/apiThunks';
import { JSONApi, State } from '../types';

type LocationState = { email?: string } | undefined;

type Props = {
  group?: JSONApi.GroupResource;
};

const CheckForEmailConfirmationPage = ({ group }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const currentUser = useCurrentUser();
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const intl = useIntl();

  const locationState = history.location.state as LocationState;
  const [confirmationEmail, setConfirmationEmail] = useState(locationState?.email);

  useEffect(() => {
    if (!confirmationEmail && currentUser) {
      const emails = getUserEmailAddresses(apiData, currentUser);
      const primaryEmail = emails.filter(email => email.attributes.primary)[0];
      setConfirmationEmail(primaryEmail.attributes.email);
    }
  }, [apiData, confirmationEmail, currentUser]);

  const handleResendConfirmationEmail = useCallback(() => {
    if (confirmationEmail) {
      dispatch(createEmailConfirmation(group, confirmationEmail))
        .then(() => {
          dispatch(alertSuccess('forms.emailConfirmation.create.success'));
        })
        .catch(() => {
          dispatch(alertError('forms.emailConfirmation.create.failure'));
        });
    }
  }, [dispatch, confirmationEmail, group]);

  return (
    <CheckInboxPage titleId="dictionary.checkYourInbox">
      <UserAccountWizardBackButton routerLink={group ? `/g/${group.attributes.slug}/start` : '/'} />
      <div>
        <Img
          alt={intl.formatMessage({ id: 'pages.checkForEmailConfirmation.imageAlt' })}
          src={confirmEmailImage}
        />
        <div className="ion-text-center">
          <Heading level={2}>
            <FormattedMessage id="dictionary.checkYourInbox" />
          </Heading>
          <Paragraph>
            <FormattedMessage id="pages.checkForEmailConfirmation.pPrefix" />
            <strong>{confirmationEmail}</strong>
            <FormattedMessage id="pages.checkForEmailConfirmation.pSuffix" />
          </Paragraph>
        </div>
      </div>
      <IonText className="ion-padding-bottom ion-padding-top ion-text-center" color="primary">
        <BasicLink
          onClick={handleResendConfirmationEmail}
          tKey="pages.checkForEmailConfirmation.button"
        />
      </IonText>
    </CheckInboxPage>
  );
};

export default CheckForEmailConfirmationPage;
