import { IonCard, IonContent } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Page from '../components/layout/Page';
import PageHeader, { PageHeaderProps } from '../components/layout/PageHeader';
import PageTitle from '../components/layout/PageTitle';

import '../components/forms/CustomForm.scss';
import './SetupPage.scss';

const SetupPage = ({
  children,
  titleKey,
  titleValues,
  ...pageHeaderProps
}: React.PropsWithChildren<PageHeaderProps>) => (
  <Page className="setup-page">
    <PageHeader {...pageHeaderProps} />
    <IonContent className="canvas ion-padding">
      {titleKey && (
        <>
          <PageTitle id={titleKey} values={titleValues} />
          <h1 className="ion-text-center ion-padding-bottom">
            <FormattedMessage id={titleKey} values={titleValues} />
          </h1>
        </>
      )}
      <IonCard className="custom-form-wrapper setup-card">{children}</IonCard>
    </IonContent>
  </Page>
);

export default SetupPage;
