export const imageToDataURL = (image: HTMLImageElement): string | undefined => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (ctx) {
    canvas.width = image.width;
    canvas.height = image.height;
    ctx.drawImage(image, 0, 0);
    return canvas.toDataURL();
  }
};

export const orientedImageToDataURL = (
  orientedImage: Event | HTMLCanvasElement | HTMLImageElement
): string | undefined => {
  if (orientedImage instanceof HTMLCanvasElement) {
    return orientedImage.toDataURL();
  }
  if (orientedImage instanceof HTMLImageElement) {
    return imageToDataURL(orientedImage);
  }
};
