import React from 'react';
import { useSelector } from 'react-redux';

import PostCard from '../components/PostCard';
import { getPostImage } from '../selectors';
import { JSONApi, State } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  newsItem: JSONApi.NewsItemResource;
};

const NewsItemCard = ({ group, newsItem }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const image = getPostImage(apiData, newsItem);

  return (
    <PostCard
      body={newsItem.attributes.body}
      group={group}
      href={`/g/${group.attributes.slug}/news/${newsItem.attributes.slug}`}
      image={image?.attributes?.url ?? newsItem.attributes.ogImage}
      post={newsItem}
    />
  );
};

export default NewsItemCard;
