import { IonButton, IonButtons, IonItem } from '@ionic/react';
import { DateTime } from 'luxon';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormSubmit from '../../components/forms/FormSubmit';
import FormTextarea from '../../components/forms/FormTextarea';
import RequiredFieldMarker from '../../components/forms/RequiredFieldMarker';
import SubmitError from '../../components/forms/SubmitError';
import Heading from '../../components/Heading';
import Paragraph from '../../components/Paragraph';
import { WizardSteps } from '../../components/VerificationWizard';
import { maxTextInputLength } from '../../globals';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { getGroupRules } from '../../selectors';
import { handleFormSubmission } from '../../services/formUtils';
import { updateMember } from '../../thunks/apiThunks';
import { JSONApi, Models, State } from '../../types';

type FormValues = {
  verificationAnswer: string;
};

type Props = {
  group: JSONApi.GroupResource;
  member: JSONApi.MemberResource;
  setWizardStep: (step: WizardSteps) => void;
};

const VerificationQuestionForm = ({ group, member, setWizardStep }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const initialValues: FormValues = {
    verificationAnswer: member.attributes.verificationAnswer ?? ''
  };

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      // When they answer the question, submit their answer and if it's ok then
      // move on to the rules page.
      let nextStep: WizardSteps = WizardSteps.RULES;
      const updateData: Models.UpdateMember = { verificationAnswer: values.verificationAnswer };
      // go ahead and "accept" the rules if there aren't any, so we can skip that page
      const rules = getGroupRules(apiData, group);
      if (rules.length === 0) {
        nextStep = WizardSteps.EDIT_PROFILE;
        updateData.acceptedRulesAt = DateTime.local();
      }
      return handleFormSubmission({
        action: updateMember(group.attributes.slug, updateData, member.id),
        dispatch,
        errorTranslationLocation: 'member',
        intl,
        isMounted,
        onSuccess: () => {
          setWizardStep(nextStep);
        },
        values
      });
    },
    [apiData, dispatch, group, intl, isMounted, member.id, setWizardStep]
  );

  const validationSchema = Yup.object().shape({
    verificationAnswer: Yup.string()
      .required(intl.formatMessage({ id: 'errors.member.verificationAnswer.blank' }))
      .max(
        maxTextInputLength,
        intl.formatMessage(
          { id: 'errors.member.verificationAnswer.tooLong' },
          { count: maxTextInputLength }
        )
      )
  });

  return (
    <FormContainer<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ form, handleSubmit }) => (
        <Form<FormValues>
          className="verification-question-form"
          onFormSubmit={handleSubmit}
          submit={form.submit}
        >
          <Heading level={2}>
            <FormattedMessage id="pages.verificationQuestion.h2" />
          </Heading>
          <Paragraph>
            {group.attributes.memberVerificationQuestion}
            <RequiredFieldMarker />
          </Paragraph>
          <SubmitError />
          <FormError name="verificationAnswer" />
          <FormTextarea
            aria-label={intl.formatMessage({
              id: 'models.member.attributes.verificationAnswer.label'
            })}
            autofocus
            name="verificationAnswer"
            placeholder={intl.formatMessage({
              id: 'models.member.attributes.verificationAnswer.placeholder'
            })}
          />
          <IonItem color="transparent" lines="none">
            <IonButtons slot="end">
              <IonButton
                color="primary"
                fill="outline"
                routerDirection="back"
                routerLink={`/g/${group.attributes.slug}/register/setup-account`}
                size="default"
              >
                <FormattedMessage id="dictionary.previous" />
              </IonButton>
              <FormSubmit allowPristineSubmit color="primary" fill="solid" size="default">
                <FormattedMessage id="dictionary.next" />
              </FormSubmit>
            </IonButtons>
          </IonItem>
        </Form>
      )}
    </FormContainer>
  );
};

export default VerificationQuestionForm;
