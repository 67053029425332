import {
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonGrid,
  IonIcon,
  IonItem,
  IonRow
} from '@ionic/react';
import { add, closeOutline } from 'ionicons/icons';
import { DateTime } from 'luxon';
import React, { createRef, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import DiscussionFormModal from '../components/DiscussionFormModal';
import EventFormModal from '../components/EventFormModal';
import GroupAboutHeader from '../components/GroupAboutHeader';
import Heading from '../components/Heading';
import Img from '../components/Img';
import JobPostFormModal from '../components/JobPostFormModal';
import Page from '../components/layout/Page';
import PageHeader from '../components/layout/PageHeader';
import NewsItemFormModal from '../components/NewsItemFormModal';
import PinnedPost from '../components/PinnedPost';
import PostFeed from '../components/PostFeed';
import SearchResults from '../components/SearchResults';
import { useGroupPostsChannel } from '../hooks/useGroupPostsChannel';
import useNewsPostingPermission from '../hooks/useNewsPostingPermission';
import useOpener from '../hooks/useOpener';
import { loadMorePostSearchResults, loadPostSearch } from '../thunks/apiThunks';
import { ApiEndpoint, JSONApi, Models, Search } from '../types';

import './Img.scss';

type PostingButtonProps = {
  ariaLabel: string;
  iconClass: 'custom-briefcase' | 'custom-calendar' | 'custom-feed' | 'custom-news';
  isOpen: boolean;
  toggle: () => void;
};

const PostingButton = ({ ariaLabel, iconClass, isOpen, toggle }: PostingButtonProps) => (
  <IonButton
    aria-label={ariaLabel}
    color="primary"
    disabled={isOpen}
    fill="solid"
    onClick={toggle}
    shape="round"
    size="small"
  >
    <IonIcon className={iconClass} slot="icon-only" />
  </IonButton>
);

type Props = {
  currentUser: JSONApi.UserResource;
  group: JSONApi.GroupResource;
};

const GroupHomePage = ({ currentUser, group }: Props) => {
  const allowNewsPosting = useNewsPostingPermission(currentUser, group);
  const contentRef = createRef<HTMLIonContentElement>();
  const discussionModalOpener = useOpener();
  const eventModalOpener = useOpener();
  const intl = useIntl();
  const jobPostModalOpener = useOpener();
  const newsItemModalOpener = useOpener();
  const [resultsUpdatedAt, setResultsUpdatedAt] = useState(DateTime.local());
  const [showPostingOptions, setShowPostingOptions] = useState(false);
  // annoyingly this really needs to be "memoized" in the state so the object doesn't change
  const [searchParams] = useState<Search.PostParams>({
    companyName: null,
    eventType: null,
    industry: null,
    jobType: null,
    lat: null,
    lng: null,
    location: null,
    memberSlug: null,
    query: null,
    radius: null,
    upcoming: null
  });

  useGroupPostsChannel({ group });

  const bannerStyle: React.CSSProperties = {
    backgroundColor: group.attributes.primaryColor
  };
  if (group.attributes.bgImageUrl) {
    bannerStyle.backgroundImage = `url(${group.attributes.bgImageUrl})`;
  } else {
    bannerStyle.maxHeight = '2rem';
  }

  const handleContentAdded = useCallback(() => {
    setTimeout(() => {
      setResultsUpdatedAt(DateTime.local());
    }, 1000);
  }, []);

  const handleToggleShowPostingOptions = useCallback(() => {
    setShowPostingOptions(!showPostingOptions);
  }, [showPostingOptions]);

  return (
    <Page className="centered group-home-page wide">
      <PageHeader group={group} titleKey="pages.groupHome.title" />
      <IonFab horizontal="end" id="group-home-fab" vertical="bottom">
        <IonFabButton aria-label={intl.formatMessage({ id: 'dictionary.post' })} color="secondary">
          <IonIcon icon={add} />
        </IonFabButton>
        <IonFabList side="top">
          {/* FAB buttons are displayed in reverse order from the way they are laid out here */}
          {group.attributes.careersEnabled && (
            <IonFabButton
              aria-label={intl.formatMessage({ id: 'modals.newJobPost.title' })}
              color="primary"
              disabled={jobPostModalOpener.isOpen}
              onClick={jobPostModalOpener.toggle}
              size="small"
            >
              <IonIcon className="custom-briefcase" />
            </IonFabButton>
          )}
          <JobPostFormModal
            group={group}
            onSuccess={handleContentAdded}
            opener={jobPostModalOpener}
            titleKey="modals.newJobPost.title"
          />
          {group.attributes.newsEnabled && allowNewsPosting && (
            <IonFabButton
              aria-label={intl.formatMessage({ id: 'modals.newNewsItem.title' })}
              color="primary"
              disabled={newsItemModalOpener.isOpen}
              onClick={newsItemModalOpener.toggle}
              size="small"
            >
              <IonIcon className="custom-news" />
            </IonFabButton>
          )}
          <NewsItemFormModal
            group={group}
            onSuccess={handleContentAdded}
            opener={newsItemModalOpener}
            titleKey="modals.newNewsItem.title"
          />
          {group.attributes.eventsEnabled && (
            <IonFabButton
              aria-label={intl.formatMessage({ id: 'modals.newEvent.title' })}
              color="primary"
              disabled={eventModalOpener.isOpen}
              onClick={eventModalOpener.toggle}
              size="small"
            >
              <IonIcon className="custom-calendar" />
            </IonFabButton>
          )}
          <EventFormModal
            group={group}
            onSuccess={handleContentAdded}
            opener={eventModalOpener}
            titleKey="modals.newEvent.title"
          />
          {group.attributes.forumEnabled && (
            <IonFabButton
              aria-label={intl.formatMessage({ id: 'modals.newDiscussion.title' })}
              color="primary"
              disabled={discussionModalOpener.isOpen}
              onClick={discussionModalOpener.toggle}
              size="small"
            >
              <IonIcon className="custom-feed" />
            </IonFabButton>
          )}
          <DiscussionFormModal
            group={group}
            onSuccess={handleContentAdded}
            opener={discussionModalOpener}
            titleKey="modals.newDiscussion.title"
          />
        </IonFabList>
      </IonFab>
      <IonContent className="canvas group-home-page" ref={contentRef} scrollEvents>
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol className="ion-no-padding mobile-only" size="12" sizeLg="4">
              <GroupAboutHeader group={group} />
            </IonCol>
            <IonCol size="12" sizeLg="8">
              <IonItem color="transparent" lines="none">
                <Heading level={2}>
                  <FormattedMessage id="pages.groupHome.h2" />
                </Heading>
                <IonButtons slot="end">
                  <IonButton
                    aria-label={intl.formatMessage({ id: 'dictionary.post' })}
                    color="secondary"
                    fill="solid"
                    onClick={handleToggleShowPostingOptions}
                    shape="round"
                    size="small"
                  >
                    <IonIcon icon={showPostingOptions ? closeOutline : add} slot="icon-only" />
                  </IonButton>
                </IonButtons>
              </IonItem>
              {showPostingOptions && (
                <IonItem color="transparent" lines="none">
                  <IonButtons slot="end">
                    {group.attributes.forumEnabled && (
                      <PostingButton
                        ariaLabel={intl.formatMessage({ id: 'modals.newDiscussion.title' })}
                        iconClass="custom-feed"
                        isOpen={discussionModalOpener.isOpen}
                        toggle={discussionModalOpener.toggle}
                      />
                    )}
                    {group.attributes.eventsEnabled && (
                      <PostingButton
                        ariaLabel={intl.formatMessage({ id: 'modals.newEvent.title' })}
                        iconClass="custom-calendar"
                        isOpen={eventModalOpener.isOpen}
                        toggle={eventModalOpener.toggle}
                      />
                    )}
                    {group.attributes.newsEnabled && allowNewsPosting && (
                      <PostingButton
                        ariaLabel={intl.formatMessage({ id: 'modals.newNewsItem.title' })}
                        iconClass="custom-news"
                        isOpen={newsItemModalOpener.isOpen}
                        toggle={newsItemModalOpener.toggle}
                      />
                    )}
                    {group.attributes.careersEnabled && (
                      <PostingButton
                        ariaLabel={intl.formatMessage({ id: 'modals.newJobPost.title' })}
                        iconClass="custom-briefcase"
                        isOpen={jobPostModalOpener.isOpen}
                        toggle={jobPostModalOpener.toggle}
                      />
                    )}
                  </IonButtons>
                </IonItem>
              )}
              <PinnedPost group={group} />
              <SearchResults<Models.PostSearch, Models.Post, Search.PostParams>
                contentRef={contentRef}
                group={group}
                loadDataActionCreator={loadPostSearch}
                loadMoreActionCreator={loadMorePostSearchResults}
                resultsUpdatedAt={resultsUpdatedAt}
                searchEndpoint={ApiEndpoint.POST_SEARCH}
                searchParams={searchParams}
                showCount={false}
              >
                {results => <PostFeed group={group} posts={results} />}
              </SearchResults>
            </IonCol>
            <IonCol className="desktop-only" size="12" sizeLg="4">
              <IonCard className="ion-no-padding">
                {group.attributes.bgImageUrl && (
                  <span className="image-wrapper">
                    <Img
                      alt={intl.formatMessage({ id: 'models.group.attributes.bgImage.alt' })}
                      src={group.attributes.bgImageUrl}
                    />
                  </span>
                )}
                <GroupAboutHeader group={group} showRules />
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </Page>
  );
};

export default GroupHomePage;
