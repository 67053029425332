import axe from '@axe-core/react';
import { SplashScreen } from '@capacitor/splash-screen';
import '@formatjs/intl-pluralrules/locale-data/en';
// This installs a polyfill for pluralization that is required on older
// android releases (at least)
import '@formatjs/intl-pluralrules/polyfill';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { isPlatform, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';

import ActionCableProvider from './components/ActionCableProvider';
import App from './components/App';
import ErrorBoundary from './components/ErrorBoundary';
import KeyboardStatusProvider from './components/KeyboardStatusProvider';
import enTranslations from './i18n/en';
import { disableReactDevTools } from './services/disableReactDevTools';
import { initialize as initializeGA } from './services/googleAnalytics';
import * as serviceWorker from './serviceWorker';
import store from './store';

import './index.scss';

setupIonicReact();

if (import.meta.env.MODE === 'development' && import.meta.env.VITE_RUN_AXE) {
  const timeout = 1000;
  axe(React, ReactDOM, timeout);
}

if (import.meta.env.MODE === 'production') {
  disableReactDevTools();
}

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
  release: import.meta.env.VITE_RELEASE_SHA
});

if ((isPlatform('mobile') || isPlatform('mobileweb')) && !isPlatform('tablet')) {
  if (isPlatform('cordova')) {
    try {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT).catch(() => {
        // Also don't care about it getting rejected.
      });
    } catch {
      // Android sometimes raises an exception here
    }
  } else if (isPlatform('mobileweb')) {
    if (screen?.orientation?.lock) {
      try {
        screen.orientation.lock('portrait').catch(() => {
          // Also don't care about it getting rejected.
        });
      } catch {
        // Some browsers implement the function but raise an exception when calling it.
      }
    }
  }
}

initializeGA();

SplashScreen.hide();

ReactDOM.render(
  <IntlProvider locale="en" messages={enTranslations}>
    <IonReactRouter>
      <Provider store={store}>
        <ErrorBoundary>
          <ActionCableProvider>
            <KeyboardStatusProvider>
              <App />
            </KeyboardStatusProvider>
          </ActionCableProvider>
        </ErrorBoundary>
      </Provider>
    </IonReactRouter>
  </IntlProvider>,
  document.getElementById('root')
);

// Call the element loader after the app has been rendered the first time
defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
