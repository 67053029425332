import { IonContent } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import GroupNotificationsForm from '../components/GroupNotificationsForm';
import Page from '../components/layout/Page';
import PageHeader from '../components/layout/PageHeader';
import PageTitle from '../components/layout/PageTitle';
import { JSONApi } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  member: JSONApi.MemberResource;
};

const GroupNotificationsPage = ({ group, member }: Props) => (
  <Page className="centered tight">
    <PageHeader defaultBackHref="/me-and-my-data/notifications" />
    <PageTitle
      group={group}
      id="pages.groupNotifications.title"
      values={{ name: group.attributes.name }}
    />
    <IonContent className="canvas ion-padding">
      <div>
        <h1 className="ion-no-margin ion-padding">
          <FormattedMessage
            id="pages.groupNotifications.title"
            values={{ name: group.attributes.name }}
          />
        </h1>
        <GroupNotificationsForm group={group} member={member} />
      </div>
    </IonContent>
  </Page>
);

export default GroupNotificationsPage;
