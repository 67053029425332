import { IonItem } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import { alertError, alertSuccess } from '../../actions/notificationActions';
import BasicLink from '../../components/BasicLink';
import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormInput from '../../components/forms/FormInput';
import FormSubmit from '../../components/forms/FormSubmit';
import SubmitError from '../../components/forms/SubmitError';
import UserAccountWizardBackButton from '../../components/UserAccountWizardBackButton';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { getRelatedResourcesFromIncluded } from '../../selectors';
import { forceArray } from '../../services/arrayUtils';
import { handleFormSubmission } from '../../services/formUtils';
import { createEmailConfirmation } from '../../thunks/apiThunks';
import { login } from '../../thunks/sessionThunks';
import { JSONApi, Models, State } from '../../types';

type FormValues = {
  password: string;
};

type Props = {
  email: string;
  handleLoginSuccess: (user: JSONApi.UserResource, newApiData: State.Api) => void;
  openForgotPassword: () => void;
  setCurrentEmail: (email: string | undefined) => void;
};

const EnterPasswordForm = (props: Props) => {
  const { email, handleLoginSuccess, openForgotPassword, setCurrentEmail } = props;
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const initialValues: FormValues = {
    password: ''
  };

  const goBackToEmailStep = useCallback(() => {
    setCurrentEmail(undefined);
  }, [setCurrentEmail]);

  const handleSubmit = useCallback(
    async (values: FormValues) =>
      handleFormSubmission({
        action: login({ email, password: values.password }),
        dispatch,
        errorTranslationLocation: 'login',
        intl,
        isMounted,
        onSuccess: response => {
          // The handleLoginSuccess callback will try to look into the state and
          // find the memberships for the logged-in user, so we need to wait until
          // the data is loaded into the state before calling it.
          response.dataLoadedIntoStatePromise.then((newState: State.Root) => {
            const users = getRelatedResourcesFromIncluded<Models.Session, Models.User>(
              forceArray(response.data)[0],
              response.included,
              'user'
            );
            const user = forceArray(users)[0];
            handleLoginSuccess(user, newState.api);
          });
        },
        values
      }),
    [dispatch, email, handleLoginSuccess, intl, isMounted]
  );

  const handleLoginByEmail = useCallback(() => {
    dispatch(createEmailConfirmation(null, email))
      .then(() => {
        dispatch(alertSuccess('pages.login.passwordlessLoginSuccess'));
      })
      .catch(() => {
        dispatch(alertError('errors.login.passwordlessLoginCreation.failed'));
      });
  }, [dispatch, email]);

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(intl.formatMessage({ id: 'errors.login.password.blank' }))
  });

  return (
    <>
      <UserAccountWizardBackButton onClick={goBackToEmailStep} />
      <FormContainer<FormValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ form, handleSubmit }) => (
          <Form<FormValues>
            className="enter-password-form"
            onFormSubmit={handleSubmit}
            submit={form.submit}
          >
            <IonItem className="compact" color="transparent" lines="full">
              <FormInput
                aria-label={intl.formatMessage({
                  id: 'models.login.attributes.password.label'
                })}
                autofocus
                color="primary"
                name="password"
                placeholder={intl.formatMessage({
                  id: 'models.login.attributes.password.placeholder'
                })}
                type="password"
              />
            </IonItem>
            <SubmitError />
            <FormError allowBlank name="password" />
            <p>
              <BasicLink onClick={openForgotPassword} tKey="dictionary.forgotPassword" />
            </p>
            <p>
              <BasicLink onClick={handleLoginByEmail} tKey="dictionary.loginByEmail" />
            </p>
            <FormSubmit color="primary" size="default">
              <FormattedMessage id="dictionary.next" />
            </FormSubmit>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default EnterPasswordForm;
