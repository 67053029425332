import { IonCard, IonCardContent, IonItem, IonLabel, IonText } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import MemberAvatar from '../components/MemberAvatar';
import Nbsp from '../components/Nbsp';
import Paragraph from '../components/Paragraph';
import RelativeTimestamp from '../components/RelativeTimestamp';
import { getCommentPost, getMentionCreator, getMentionedInResource } from '../selectors';
import { getPostPath } from '../services/postUtils';
import { JSONApi, State } from '../types';

import './MentionCard.scss';

type Props = {
  group: JSONApi.GroupResource;
  mention: JSONApi.MentionResource;
};

const MentionCard = ({ group, mention }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);

  const creator = getMentionCreator(apiData, mention);
  const content = getMentionedInResource(apiData, mention);

  let bodyText = '';
  switch (content.type) {
    case 'comment':
      bodyText = (content as JSONApi.CommentResource).attributes.content;
      break;
    case 'discussion':
      bodyText = (content as JSONApi.DiscussionResource).attributes.description;
      break;
    case 'event':
      bodyText = (content as JSONApi.EventResource).attributes.description;
      break;
    case 'jobPost':
      bodyText = (content as JSONApi.JobPostResource).attributes.description;
      break;
    case 'newsItem':
      bodyText = (content as JSONApi.NewsItemResource).attributes.body;
      break;
    default:
      break;
  }

  let link: string;
  switch (content.type) {
    case 'comment':
      link = getPostPath(getCommentPost(apiData, content as JSONApi.CommentResource), group);
      break;
    default:
      link = getPostPath(content as JSONApi.PostResource, group);
      break;
  }

  return (
    <IonCard className="mention-card" routerLink={link}>
      <IonCardContent>
        <IonItem className="compact" color="transparent" lines="none">
          <Paragraph color="medium">
            <FormattedMessage
              id={`models.mention.type.${content.type}.title`}
              values={{ name: creator.attributes.firstName }}
            />
          </Paragraph>
        </IonItem>
        <div className="body-content">
          <MemberAvatar member={creator} />
          <div>
            <IonLabel color="primary">
              <FormattedMessage
                id="models.member.attributes.slug.handle"
                values={{ slug: creator.attributes.slug }}
              />
            </IonLabel>
            <Nbsp />
            <IonText color="medium">
              <RelativeTimestamp date={mention.attributes.createdAt.valueOf()} />
            </IonText>
            <Paragraph>{bodyText}</Paragraph>
          </div>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default MentionCard;
