import { IonItem, IonLabel, IonList } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { JSONApi } from '../types';

import './SettingsList.scss';

const MyGroupsList = ({
  groups,
  id
}: Pick<React.ComponentPropsWithoutRef<typeof FormattedMessage>, 'id'> & {
  groups: JSONApi.GroupResource[];
}) => {
  if (groups.length === 0) {
    return null;
  }

  return (
    <>
      <IonLabel className="settings-list-label" color="primary">
        <FormattedMessage id={id} />
      </IonLabel>
      <IonList className="ion-no-padding settings-list">
        {groups.map((group, index) => (
          <IonItem
            button
            color="light"
            key={group.id}
            lines={index === groups.length - 1 ? 'none' : 'inset'}
            role="button"
            routerLink={`/g/${group.attributes.slug}`}
          >
            {group.attributes.name}
          </IonItem>
        ))}
      </IonList>
    </>
  );
};

export default MyGroupsList;
