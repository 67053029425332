import {
  QueryHandler,
  SearchableSelectOptionData
} from '../components/forms/SearchableSelectModal';

export const generateTextQueryHandler: (options: SearchableSelectOptionData[]) => QueryHandler =
  options => async (query: string) => {
    const rgx = new RegExp(query, 'i');
    if (options) {
      return Promise.resolve(options.filter(option => !!rgx.exec(option.label)));
    }
    return Promise.resolve([]);
  };
