import React from 'react';

import LinksForm from '../components/forms/LinksForm';
import ModeratorPage from '../components/layout/ModeratorPage';
import { JSONApi } from '../types';

import '../components/forms/CustomForm.scss';

type Props = {
  group: JSONApi.GroupResource;
};

const ManageGroupLinksPage = ({ group }: Props) => (
  <ModeratorPage group={group} h2Key="pages.manageGroup.links.title">
    {() => (
      <div className="custom-form-wrapper">
        <LinksForm group={group} owner={group} />
      </div>
    )}
  </ModeratorPage>
);

export default ManageGroupLinksPage;
