import { IonPage } from '@ionic/react';
import React from 'react';

import PlanAlerts from '../../components/PlanAlerts';
import useCurrentGroup from '../../hooks/useCurrentGroup';

import './Page.scss';

// this imports the base page css so you don't forget to do it and adds group-wide alerts
const Page = ({ children, className }: React.PropsWithChildren<{ className?: string }>) => {
  const group = useCurrentGroup();

  return (
    <IonPage className={`page-layout ${className ?? ''}`}>
      <PlanAlerts group={group} />
      {children}
    </IonPage>
  );
};

export default Page;
