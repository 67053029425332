import { IonBadge, IonItem, IonLabel } from '@ionic/react';
import { fileTray, pin } from 'ionicons/icons';
import { DateTime } from 'luxon';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import EventFormModal from '../components/EventFormModal';
import ExternalLink from '../components/ExternalLink';
import FormatText from '../components/FormatText';
import Nbsp from '../components/Nbsp';
import Paragraph from '../components/Paragraph';
import PostImages from '../components/PostImages';
import PostPage, { PostPageProps } from '../components/PostPage';
import SearchChip from '../components/SearchChip';
import useOpener from '../hooks/useOpener';
import { forceArray } from '../services/arrayUtils';
import { locationSearchUrl, toUrl } from '../services/urlUtils';
import { JSONApi, Models } from '../types';

type Props = {
  event: JSONApi.EventResource;
  group: JSONApi.GroupResource;
};

const EventPage = ({ event, group }: Props) => {
  const { description, link } = event.attributes;

  const intl = useIntl();
  const modalOpener = useOpener();

  const url = toUrl(link);
  const rsvpsCount = forceArray(event.relationships.rsvps.data).length;

  const postPageProps: PostPageProps<Models.Event> = {
    backHref: `/g/${group.attributes.slug}/events`,
    group,
    post: event
  };

  if (event.attributes.permissions.edit) {
    postPageProps.onEditClick = modalOpener.toggle;
  }

  const locationLabel = event.attributes.online
    ? intl.formatMessage({ id: 'models.event.attributes.online.name' })
    : event.attributes.location;

  const locationLink = locationSearchUrl(event.attributes, 'events', group);

  const eventTypeLink = `/g/${group.attributes.slug}/events?eventType=${encodeURIComponent(
    event.attributes.eventType
  )}`;

  return (
    <PostPage<Models.Event> {...postPageProps}>
      <EventFormModal
        event={event}
        group={group}
        opener={modalOpener}
        titleKey="modals.editEvent.title"
      />
      <IonItem color="transparent" lines="none">
        <h2>
          <IonLabel>
            {event.attributes.startTime.toLocaleString(DateTime.DATETIME_SHORT)}
            <>
              <Nbsp />
              <FormattedMessage id="dictionary.dash" />
              <Nbsp />
              {event.attributes.endTime.toLocaleString(DateTime.DATETIME_SHORT)}
            </>
          </IonLabel>
        </h2>
      </IonItem>
      {description && (
        <div className="ion-padding">
          <Paragraph className="formatted-text">
            <FormatText autolink mentionable>
              {description}
            </FormatText>
          </Paragraph>
        </div>
      )}
      {url && (
        <IonItem color="transparent" lines="none">
          <ExternalLink href={url.toString()}>{url.host}</ExternalLink>
        </IonItem>
      )}
      <IonItem>
        <IonLabel>
          <FormattedMessage id="models.event.attributes.eventType.label" />
        </IonLabel>
        <SearchChip icon={fileTray} label={event.attributes.eventType} link={eventTypeLink} />
      </IonItem>
      <IonItem>
        <IonLabel>
          <FormattedMessage id="models.event.attributes.location.label" />
        </IonLabel>
        <SearchChip icon={pin} label={locationLabel} link={locationLink} />
      </IonItem>
      {event.attributes.enableRsvps && (
        <IonItem
          button
          role="button"
          routerLink={`/g/${group.attributes.slug}/events/${event.attributes.slug}/rsvps`}
        >
          <IonLabel>
            <FormattedMessage id="dictionary.whosGoing" />
          </IonLabel>
          <IonBadge color="tertiary">{rsvpsCount}</IonBadge>
        </IonItem>
      )}
      <PostImages post={event} />
    </PostPage>
  );
};

export default EventPage;
