import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import ImgOriented from '../components/ImgOriented';
import Lightbox from '../components/Lightbox';
import useOpener from '../hooks/useOpener';
import { getPostImages } from '../selectors';
import { JSONApi, Models, State } from '../types';

import './Img.scss';
import './PostImages.scss';

type Props = {
  post: JSONApi.Resource<Models.PostWithLink>;
};

const PostImages = ({ post }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const intl = useIntl();
  const lightboxOpener = useOpener();
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);

  const generateHandleCollageImageClick = useCallback(
    (index: number) => () => {
      setSelectedImageIndex(index);
      lightboxOpener.open();
    },
    [lightboxOpener]
  );

  const generateHandleCollageImageKeyPress = useCallback(
    (index: number) => (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        generateHandleCollageImageClick(index)();
      }
    },
    [generateHandleCollageImageClick]
  );

  const generateHandleLightboxMoveRequest = useCallback(
    (newIndex: number) => () => {
      setSelectedImageIndex(newIndex);
    },
    []
  );

  const alt = intl.formatMessage({ id: `models.${post.type}.relationships.images.alt` });
  const images = getPostImages(apiData, post);
  const numImages = images.length;
  const maxNumImages = 5;

  if (numImages > 1) {
    const nextIndex = (selectedImageIndex + 1) % numImages;
    const prevIndex = (selectedImageIndex + numImages - 1) % numImages;
    return (
      <>
        <div className="post-images-wrapper">
          <div className={`post-images has-${Math.min(maxNumImages, numImages)}-images`}>
            {images.slice(0, maxNumImages).map((image, index) => (
              <div
                className={`image-collage-item item-${index}`}
                key={image.id}
                onClick={generateHandleCollageImageClick(index)}
                onKeyPress={generateHandleCollageImageKeyPress(index)}
                role="button"
                style={{ backgroundImage: `url("${image.attributes.url}")` }}
                tabIndex={0}
              />
            ))}
            {numImages > maxNumImages && (
              <div className="additional-images-indicator">
                <FormattedMessage id="dictionary.plus" />
                {numImages - maxNumImages}
              </div>
            )}
          </div>
        </div>
        <Lightbox
          mainSrc={images[selectedImageIndex].attributes.url}
          nextSrc={images[nextIndex].attributes.url}
          onMoveNextRequest={generateHandleLightboxMoveRequest(nextIndex)}
          onMovePrevRequest={generateHandleLightboxMoveRequest(prevIndex)}
          opener={lightboxOpener}
          prevSrc={images[prevIndex].attributes.url}
        />
      </>
    );
  }
  if (numImages === 1) {
    return (
      <span className="image-wrapper">
        <ImgOriented alt={alt} src={images[0].attributes.url} />
      </span>
    );
  }

  if (post.attributes.ogImage) {
    return (
      <span className="image-wrapper">
        <ImgOriented alt={alt} src={post.attributes.ogImage} />
      </span>
    );
  }

  return null;
};

export default PostImages;
