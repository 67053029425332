import { IonCard, IonCardContent, IonCardHeader, IonCol, IonIcon, IonText } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  icon?: string;
  iconClassName?: string;
  routerLink: string;
  tKey: string;
};

const SelectSectionTile = ({ icon, iconClassName, routerLink, tKey }: Props) => (
  <IonCol sizeLg="3" sizeMd="3" sizeSm="4" sizeXl="2" sizeXs="6">
    <div className="ion-padding">
      <IonCard button className="ion-text-center" role="button" routerLink={routerLink}>
        <IonCardHeader>
          <IonIcon className={iconClassName} color="primary" icon={icon} />
        </IonCardHeader>
        <IonCardContent>
          <IonText color="primary">
            <FormattedMessage id={tKey} />
          </IonText>
        </IonCardContent>
      </IonCard>
    </div>
  </IonCol>
);

export default SelectSectionTile;
