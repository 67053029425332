import { DateTime } from 'luxon';

export function forceArray<T>(input: T | T[] | null): T[] {
  if (!input) {
    return [];
  }

  if (!(input instanceof Array)) {
    return Array.of(input);
  }

  return input;
}

export function randomItem<T>(input: T[]): T {
  return input[Math.floor(Math.random() * input.length)];
}

type ThingWithCreatedAt = {
  attributes: {
    createdAt: DateTime;
  };
};

type ThingWithPosition = {
  attributes: {
    position: number;
  };
};

export function sortByCreatedAt<T extends ThingWithCreatedAt>(list: T[]) {
  return list.sort((a, b) => {
    if (!a.attributes.createdAt || !b.attributes.createdAt) {
      return 0;
    }
    return a.attributes.createdAt > b.attributes.createdAt ? -1 : 1;
  });
}

export function sortByPosition<T extends ThingWithPosition>(list: T[]) {
  // eslint-disable-next-line no-confusing-arrow
  return list.sort((a, b) => (a.attributes.position > b.attributes.position ? 1 : -1));
}

export function uniq<T>(array: T[]): T[] {
  return array.filter((item, pos) => array.indexOf(item) === pos);
}
