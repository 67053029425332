import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import useCurrentUser from '../hooks/useCurrentUser';
import { getMemberGroup, getUserMembers } from '../selectors';
import { State } from '../types';

type Props = {
  children: React.ReactElement;
};

const RedirectIfCurrentUser = ({ children }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const currentUser = useCurrentUser();

  if (currentUser) {
    const members = getUserMembers(apiData, currentUser);
    if (members.length === 1) {
      const group = getMemberGroup(apiData, members[0]);
      return <Redirect to={`/g/${group.attributes.slug}`} />;
    }
    if (members.length > 1) {
      const slug = currentUser.attributes.lastVisitedGroupSlug;
      if (slug) {
        return <Redirect to={`/g/${slug}`} />;
      }
    }
    // if you have a complete user but no memberships
    // or several memberships but no last group visisted
    // go to the start page
    return <Redirect to="/start" />;
  }

  return children;
};

export default RedirectIfCurrentUser;
