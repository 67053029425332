import { IonLabel, IonList, IonListHeader } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import GroupMenuItem from '../components/GroupMenuItem';
import { Opener } from '../hooks/useOpener';
import { JSONApi } from '../types';

type Props = {
  creating?: boolean;
  currentGroupSlug: string | null;
  groups: JSONApi.GroupResource[];
  headerTKey: string;
  menuOpener: Opener;
  onClick: () => Promise<boolean> | undefined;
};

const GroupMenuList = ({
  creating = false,
  currentGroupSlug,
  groups,
  headerTKey,
  menuOpener,
  onClick
}: Props) => {
  const isCurrentGroup = useCallback(
    (group: JSONApi.GroupResource) => group.attributes.slug === currentGroupSlug,
    [currentGroupSlug]
  );

  return (
    <IonList className="custom-list">
      <IonListHeader>
        <IonLabel>
          <FormattedMessage id={headerTKey} />
        </IonLabel>
      </IonListHeader>
      {groups.map((group, idx) => (
        <GroupMenuItem
          active={isCurrentGroup(group)}
          disabled={!menuOpener.isPresented}
          group={group}
          key={group.id}
          lines={idx === groups.length - 1 ? 'none' : 'inset'}
          onClick={onClick}
          routerLink={creating ? `/create/${group.attributes.slug}` : `/g/${group.attributes.slug}`}
        />
      ))}
    </IonList>
  );
};

export default GroupMenuList;
