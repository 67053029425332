import { IonButton, IonButtons, IonIcon, IonItem } from '@ionic/react';
import { FormApi } from 'final-form';
import { send } from 'ionicons/icons';
import { MentionsRef } from 'rc-mentions/lib/Mentions';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormMentionsTextarea from '../../components/forms/FormMentionsTextarea';
import FormSubmit from '../../components/forms/FormSubmit';
import SubmitError from '../../components/forms/SubmitError';
import MentionButton from '../../components/MentionButton';
import { maxTextInputLength } from '../../globals';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { handleFormSubmission } from '../../services/formUtils';
import { updateComment } from '../../thunks/apiThunks';
import { JSONApi, Models } from '../../types';

import './EditCommentForm.scss';

type FormValues = Models.UpdateComment;

type Props = {
  comment: JSONApi.CommentResource;
  group: JSONApi.GroupResource;
  mentionsRef?: React.RefObject<MentionsRef>;
  onCancel?: () => void;
  onSuccess?: () => void;
};

const EditCommentForm = ({ comment, group, mentionsRef, onCancel, onSuccess }: Props) => {
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const initialValues: FormValues = {
    content: comment.attributes.content
  };

  const generateSetContentValue = useCallback(
    (form: FormApi<FormValues>) => (content: string) => {
      form.change('content', content);
    },
    []
  );

  const handleSubmit = useCallback(
    async (values: FormValues, form: FormApi<FormValues>) =>
      handleFormSubmission({
        action: updateComment(group.attributes.slug, values, comment.id),
        dispatch,
        errorTranslationLocation: 'comment',
        form,
        intl,
        isMounted,
        onSuccess,
        successTKey: 'forms.comment.update.success',
        values
      }),
    [comment.id, dispatch, group.attributes.slug, intl, isMounted, onSuccess]
  );

  const validationSchema = Yup.object().shape({
    content: Yup.string()
      .required(intl.formatMessage({ id: 'errors.comment.content.blank' }))
      .max(
        maxTextInputLength,
        intl.formatMessage({ id: 'errors.comment.content.tooLong' }, { count: maxTextInputLength })
      )
  });

  return (
    <FormContainer<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ form, handleSubmit }) => (
        <Form<FormValues>
          className="edit-comment-form"
          onFormSubmit={handleSubmit}
          submit={form.submit}
        >
          <SubmitError />
          <FormMentionsTextarea
            aria-label={intl.formatMessage({
              id: 'models.comment.attributes.content.label'
            })}
            forwardRef={mentionsRef}
            name="content"
            placeholder={intl.formatMessage({
              id: 'models.comment.attributes.content.placeholder'
            })}
            placement="top"
            rows={2}
          />
          <IonItem className="compact" color="transparent" lines="none">
            {onCancel && (
              <IonButtons slot="start">
                <IonButton onClick={onCancel}>
                  <FormattedMessage id="dictionary.cancel" />
                </IonButton>
              </IonButtons>
            )}
            <IonButtons slot="end">
              {mentionsRef && (
                <MentionButton
                  fieldRef={mentionsRef}
                  setFieldValue={generateSetContentValue(form)}
                />
              )}
              <FormSubmit
                aria-label={intl.formatMessage({ id: 'forms.comment.submitButton' })}
                className="comment-submit"
                color="primary"
                fill="clear"
                size="small"
              >
                <IonIcon icon={send} slot="icon-only" />
              </FormSubmit>
            </IonButtons>
          </IonItem>
        </Form>
      )}
    </FormContainer>
  );
};

export default EditCommentForm;
