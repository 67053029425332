import { FormApi } from 'final-form';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormInput from '../../components/forms/FormInput';
import FormItem from '../../components/forms/FormItem';
import FormSubmit from '../../components/forms/FormSubmit';
import FormTip from '../../components/forms/FormTip';
import SubmitError from '../../components/forms/SubmitError';
import { maxTitleLength } from '../../globals';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { AjaxResponse } from '../../services/ajaxRequest';
import { handleFormSubmission } from '../../services/formUtils';
import { createTrait } from '../../thunks/apiThunks';
import { JSONApi, Models } from '../../types';

type FormValues = Models.TraitFormData;

type Props = {
  autofocus: boolean;
  group: JSONApi.GroupResource;
  onSuccess?: (response: AjaxResponse<Models.Trait>) => void;
};

const NewGroupTraitForm = ({ autofocus, group, onSuccess }: Props) => {
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const initialValues: FormValues = {
    required: false,
    title: ''
  };

  const handleSubmit = useCallback(
    async (values: FormValues, form: FormApi<FormValues>) =>
      handleFormSubmission({
        action: createTrait(group.attributes.slug, values),
        dispatch,
        errorTranslationLocation: 'trait',
        form,
        intl,
        isMounted,
        onSuccess,
        successTKey: 'forms.trait.create.success',
        values
      }),
    [dispatch, group.attributes.slug, intl, isMounted, onSuccess]
  );

  const validationSchema = Yup.object().shape({
    required: Yup.boolean(),
    title: Yup.string()
      .required(intl.formatMessage({ id: 'errors.trait.title.blank' }))
      .max(
        maxTitleLength,
        intl.formatMessage({ id: 'errors.trait.title.tooLong' }, { count: maxTitleLength })
      )
  });

  return (
    <FormContainer<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ form, handleSubmit }) => (
        <Form<FormValues> onFormSubmit={handleSubmit} submit={form.submit}>
          <SubmitError />
          <FormError name="title" />
          <FormItem id="models.trait.attributes.title.label" required>
            <FormInput
              autofocus={autofocus}
              name="title"
              placeholder={intl.formatMessage({ id: 'models.trait.attributes.title.placeholder' })}
            />
          </FormItem>
          <div className="ion-padding-end ion-padding-start">
            <FormTip id="models.trait.attributes.title.tip" />
          </div>
          <div className="ion-padding">
            <FormSubmit>
              <FormattedMessage id="dictionary.add" />
            </FormSubmit>
          </div>
        </Form>
      )}
    </FormContainer>
  );
};

export default NewGroupTraitForm;
