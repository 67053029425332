import { IonButton, IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';
import React, { useCallback } from 'react';

import Input from '../../components/forms/Input';
import SearchableSelectModal from '../../components/forms/SearchableSelectModal';
import useOpener from '../../hooks/useOpener';

const SearchableSelect = ({
  attributeNameTKey,
  defaultOptions,
  onSelectOption,
  queryHandler,
  selectedOption,
  titleTKey,
  ...inputProps
}: Pick<
  React.ComponentPropsWithoutRef<typeof SearchableSelectModal>,
  | 'attributeNameTKey'
  | 'defaultOptions'
  | 'onSelectOption'
  | 'queryHandler'
  | 'selectedOption'
  | 'titleTKey'
> &
  React.ComponentPropsWithoutRef<typeof Input>) => {
  const { value } = inputProps;

  const modalOpener = useOpener();

  const handleClearClick = useCallback(() => {
    onSelectOption(undefined);
  }, [onSelectOption]);

  const handleIonFocus = useCallback(() => {
    modalOpener.open();
  }, [modalOpener]);

  return (
    <>
      <Input {...inputProps} className="ion-searchable-select" onIonFocus={handleIonFocus} />
      <IonButton
        className={value ? 'close-ion-searchable-select' : 'hide'}
        color="dark"
        fill="clear"
        onClick={handleClearClick}
        size="small"
        slot="end"
        type="button"
      >
        <IonIcon icon={close} slot="icon-only" />
      </IonButton>
      <SearchableSelectModal
        attributeNameTKey={attributeNameTKey}
        defaultOptions={defaultOptions}
        onSelectOption={onSelectOption}
        opener={modalOpener}
        queryHandler={queryHandler}
        selectedOption={selectedOption}
        titleTKey={titleTKey}
      />
    </>
  );
};

export default SearchableSelect;
