import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import PageTitle from '../../components/layout/PageTitle';
import { JSONApi } from '../../types';

import './PageHeader.scss';

export type PageHeaderProps = {
  buttonsRight?: React.ReactElement;
  defaultBackHref?: string;
  group?: JSONApi.GroupResource;
  titleKey?: string;
  titleValues?: { [key: string]: string };
};

const PageHeader = ({
  buttonsRight,
  defaultBackHref,
  group,
  titleKey,
  titleValues
}: PageHeaderProps) => {
  const backButtonProps: { defaultHref?: string } = {};
  if (defaultBackHref) {
    backButtonProps.defaultHref = defaultBackHref;
  }
  return (
    <IonHeader className="page-header">
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton {...backButtonProps} text="" />
        </IonButtons>
        {titleKey && (
          <>
            <PageTitle group={group} id={titleKey} values={titleValues} />
            <IonTitle>
              <h1>
                <FormattedMessage id={titleKey} values={titleValues} />
              </h1>
            </IonTitle>
          </>
        )}
        <IonButtons slot="end">
          {buttonsRight}
          <IonMenuButton menu="main-menu">
            <IonIcon className="custom-menu-right" />
          </IonMenuButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default PageHeader;
