import { useEffect, useRef } from 'react';

type Autofocusable = HTMLIonInputElement | HTMLIonSearchbarElement | HTMLIonTextareaElement;

const useAutofocus = (activate: boolean, inputRef: React.RefObject<Autofocusable>) => {
  const everGotFocus = useRef<boolean>(false);
  const interval = useRef<NodeJS.Timeout | undefined>();
  const timeout = useRef<NodeJS.Timeout | undefined>();

  const stopInterval = () => {
    if (interval.current) {
      window.clearInterval(interval.current);
      interval.current = undefined;
    }
  };

  const stopTimeout = () => {
    if (timeout.current) {
      window.clearTimeout(timeout.current);
      timeout.current = undefined;
    }
  };

  // If we are supposed to activate, we haven't already gotten focus, and there is a proper
  // input element, set focus on the element. That probably won't work, so also schedule an
  // interval to fire repeatedly attempting to give it focus.
  useEffect(() => {
    if (!!activate && !!inputRef.current && !!inputRef.current.setFocus && !everGotFocus.current) {
      inputRef.current.setFocus();

      if (!interval.current) {
        // sometimes the input will mysteriously become null in here, so we have to
        // cache what it was when we scheduled the interval.
        const cachedInput = inputRef.current;
        interval.current = setInterval(() => {
          cachedInput.setFocus();
        }, 50);

        // This is just a stopgap to keep it from spinning forever. After five seconds of trying
        // to set focus, just give up.
        timeout.current = setTimeout(() => {
          stopInterval();
        }, 5000);
      }
    }
  }, [activate, inputRef]);

  // If we are meant to activate and there is an input, attach an event listener to it so that
  // we can track if it ever gets focus. Once it actually gets focus, we can stop our ridiculous
  // attempts at forcing it to do so by clearing the interval.
  useEffect(() => {
    if (activate && inputRef.current) {
      inputRef.current.addEventListener('ionFocus', () => {
        // TODO: figure out how to not set the interval over and over again
        everGotFocus.current = true;
        stopInterval();
        stopTimeout();
      });
    }
  }, [activate, inputRef]);

  // If for some reason activate becomes false, clear the interval that we may have kicked
  // off when it was set to true.
  useEffect(() => {
    if (!activate) {
      stopInterval();
      stopTimeout();
    }
  }, [activate]);

  // On unmount, clear the intervals.
  useEffect(
    () => () => {
      everGotFocus.current = false;
      stopInterval();
      stopTimeout();
    },
    []
  );
};

export default useAutofocus;
