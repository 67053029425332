import React from 'react';
import { FormattedMessage } from 'react-intl';

import OnClickLink from '../components/OnClickLink';

type Props = {
  onClick: () => void;
  slot?: string;
  tKey: string;
};

const BasicLink = ({ onClick, slot, tKey }: Props) => (
  <OnClickLink onClick={onClick} slot={slot}>
    <FormattedMessage id={tKey} />
  </OnClickLink>
);

export default BasicLink;
