import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import ModerateItemFlags from '../components/ModerateItemFlags';
import { findById, getCommentCreator } from '../selectors';
import { forceArray } from '../services/arrayUtils';
import { getPostPath } from '../services/postUtils';
import { JSONApi, Models, State } from '../types';

type Props = {
  comment: JSONApi.CommentResource;
  flaggedItem: JSONApi.FlaggedItemResource;
  group: JSONApi.GroupResource;
};

const ModerateCommentFlags = ({ comment, flaggedItem, group }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const intl = useIntl();

  const postData =
    comment?.relationships?.post?.data && forceArray(comment.relationships.post.data)[0];
  const post = postData && findById<Models.Post>(apiData, postData.type, postData.id);
  const author = getCommentCreator(apiData, comment);

  const renderFlaggedContent = useCallback(
    () => comment.attributes.content,
    [comment.attributes.content]
  );

  if (comment && post) {
    return (
      <ModerateItemFlags
        flaggedItem={flaggedItem}
        flaggedItemAuthor={author}
        group={group}
        rejectErrorKey="forms.moderateComment.reject.error"
        rejectSuccessKey="forms.moderateComment.reject.success"
        renderFlaggedContent={renderFlaggedContent}
        typeBadgeLabel={intl.formatMessage({ id: 'models.comment.badge' })}
        viewFlaggedLink={getPostPath(post, group)}
      />
    );
  }

  return null;
};

export default ModerateCommentFlags;
