import { IonBadge } from '@ionic/react';
import React from 'react';

const TabBadge = ({
  count,
  ...props
}: React.ComponentPropsWithoutRef<typeof IonBadge> & {
  count: number | undefined;
}) => {
  if (!count || count === 0) {
    return null;
  }
  return <IonBadge {...props}>{count > 99 ? '99+' : count}</IonBadge>;
};

export default TabBadge;
