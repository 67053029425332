import { ActionSheetButton, IonActionSheet } from '@ionic/react';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import BlockFormModal from '../components/BlockFormModal';
import FlagFormModal from '../components/FlagFormModal';
import useOpener, { Opener } from '../hooks/useOpener';
import { JSONApi } from '../types';

type Props = {
  actionSheetOpener: Opener;
  block: boolean;
  flag: boolean;
  group: JSONApi.GroupResource;
  onDelete?: () => void;
  onEdit?: () => void;
  record: JSONApi.BaseResource;
};

const ChatBubbleActionSheet = ({
  actionSheetOpener,
  block,
  flag,
  group,
  onDelete,
  onEdit,
  record
}: Props) => {
  const intl = useIntl();
  const blockModalOpener = useOpener();
  const flagModalOpener = useOpener();

  const actionSheetButtons: ActionSheetButton[] = [];
  if (onEdit) {
    actionSheetButtons.push({
      handler: onEdit,
      text: intl.formatMessage({ id: 'dictionary.edit' })
    });
  }
  if (onDelete) {
    actionSheetButtons.push({
      handler: onDelete,
      text: intl.formatMessage({ id: 'dictionary.delete' })
    });
  }
  if (flag) {
    actionSheetButtons.push({
      handler: flagModalOpener.open,
      text: intl.formatMessage({ id: 'modals.newFlag.title' })
    });
  }
  if (block) {
    actionSheetButtons.push({
      handler: blockModalOpener.open,
      text: intl.formatMessage({ id: 'modals.newBlock.title.content' })
    });
  }
  actionSheetButtons.push({
    handler: actionSheetOpener.close,
    role: 'cancel',
    text: intl.formatMessage({ id: 'dictionary.close' })
  });

  const handleDidDismiss = useCallback(() => {
    actionSheetOpener.close();
    actionSheetOpener.handleDidDismiss();
  }, [actionSheetOpener]);

  return (
    <>
      {(block || onDelete || onEdit || flag) && (
        <IonActionSheet
          buttons={actionSheetButtons}
          isOpen={actionSheetOpener.isOpen}
          onDidDismiss={handleDidDismiss}
          onDidPresent={actionSheetOpener.handleDidPresent}
        />
      )}
      <BlockFormModal group={group} opener={blockModalOpener} record={record} />
      <FlagFormModal group={group} opener={flagModalOpener} record={record} />
    </>
  );
};

export default ChatBubbleActionSheet;
