import { ActionSheetButton, IonActionSheet, IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { mailOutline } from 'ionicons/icons';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import BlockFormModal from '../components/BlockFormModal';
import FlagFormModal from '../components/FlagFormModal';
import FormModal from '../components/FormModal';
import NewMessageForm from '../components/forms/NewMessageForm';
import WelcomeForm from '../components/forms/WelcomeForm';
import useOpener, { Opener } from '../hooks/useOpener';
import useSaveHandler from '../hooks/useSaveHandler';
import { getMemberWelcome, getSaveForResource } from '../selectors';
import { JSONApi, State } from '../types';

type Props = {
  actionSheetOpener: Opener;
  group: JSONApi.GroupResource;
  member: JSONApi.MemberResource;
};

const MemberActions = ({ actionSheetOpener, group, member }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const blockModalOpener = useOpener();
  const history = useHistory();
  const intl = useIntl();
  const flagModalOpener = useOpener();
  const messageModalOpener = useOpener();
  const welcomeModalOpener = useOpener();

  const save = getSaveForResource(apiData, member);
  const welcome = getMemberWelcome(apiData, member);

  const handleDidDismiss = useCallback(() => {
    actionSheetOpener.close();
    actionSheetOpener.handleDidDismiss();
  }, [actionSheetOpener]);

  const goToConversation = useCallback(
    (welcome: JSONApi.ConversationResource) => () => {
      history.push(`/g/${group.attributes.slug}/inbox/conversations/${welcome.attributes.slug}`);
    },
    [group.attributes.slug, history]
  );

  const handleClickBlock = useCallback(() => {
    actionSheetOpener.close();
    blockModalOpener.open();
  }, [actionSheetOpener, blockModalOpener]);

  const handleClickEdit = useCallback(() => {
    history.push(`/me-and-my-data/memberships/${group.attributes.slug}`);
  }, [group.attributes.slug, history]);

  const handleClickFlag = useCallback(() => {
    actionSheetOpener.close();
    flagModalOpener.open();
  }, [actionSheetOpener, flagModalOpener]);

  const handleClickSendMessage = useCallback(() => {
    actionSheetOpener.close();
    messageModalOpener.open();
  }, [actionSheetOpener, messageModalOpener]);

  const handleClickWelcome = useCallback(() => {
    actionSheetOpener.close();
    welcomeModalOpener.open();
  }, [actionSheetOpener, welcomeModalOpener]);

  const handleClickSave = useSaveHandler(member, group, () => close());

  const actionSheetButtons: ActionSheetButton[] = [];

  if (member.attributes.permissions.message) {
    actionSheetButtons.push({
      handler: handleClickSendMessage,
      text: intl.formatMessage({ id: 'dictionary.sendMessage' })
    });
  }

  if (welcome) {
    const handler = goToConversation(welcome);
    actionSheetButtons.push({
      handler,
      text: intl.formatMessage({ id: 'dictionary.viewWelcome' })
    });
  }

  if (member.attributes.permissions.welcome) {
    actionSheetButtons.push({
      handler: handleClickWelcome,
      text: intl.formatMessage({ id: 'dictionary.sendWelcome' })
    });
  }

  actionSheetButtons.push({
    handler: handleClickSave,
    text: intl.formatMessage({ id: save ? 'forms.save.unsave' : 'forms.save.save' })
  });

  if (member.attributes.permissions.flag) {
    actionSheetButtons.push({
      handler: handleClickFlag,
      text: intl.formatMessage({ id: 'modals.newFlag.title' })
    });
  }

  if (member.attributes.permissions.block) {
    actionSheetButtons.push({
      handler: handleClickBlock,
      text: intl.formatMessage({ id: 'modals.newBlock.title.member' })
    });
  }

  if (member.attributes.permissions.edit) {
    actionSheetButtons.push({
      handler: handleClickEdit,
      text: intl.formatMessage({ id: 'dictionary.edit' })
    });
  }

  actionSheetButtons.push({
    handler: actionSheetOpener.close,
    role: 'cancel',
    text: intl.formatMessage({ id: 'dictionary.close' })
  });

  return (
    <>
      <IonActionSheet
        buttons={actionSheetButtons}
        isOpen={actionSheetOpener.isOpen}
        onDidDismiss={handleDidDismiss}
        onDidPresent={actionSheetOpener.handleDidPresent}
      />
      {member.attributes.permissions.message && (
        <IonFab className="member-actions-fab" horizontal="end" slot="fixed" vertical="bottom">
          <IonFabButton
            aria-label={intl.formatMessage({ id: 'dictionary.sendMessage' })}
            color="secondary"
            disabled={messageModalOpener.isOpen}
            onClick={messageModalOpener.toggle}
          >
            <IonIcon icon={mailOutline} />
          </IonFabButton>
        </IonFab>
      )}
      <FormModal
        keyboardClose={false}
        opener={messageModalOpener}
        titleKey="modals.newMessage.title"
        titleValues={{ name: member.attributes.firstName }}
      >
        {() => <NewMessageForm group={group} opener={messageModalOpener} recipient={member} />}
      </FormModal>
      <FormModal
        opener={welcomeModalOpener}
        titleKey="forms.welcome.create.title"
        titleValues={{ name: member.attributes.firstName }}
      >
        {() => <WelcomeForm closeModal={welcomeModalOpener.close} group={group} member={member} />}
      </FormModal>
      <BlockFormModal group={group} opener={blockModalOpener} record={member} />
      <FlagFormModal group={group} opener={flagModalOpener} record={member} />
    </>
  );
};

export default MemberActions;
