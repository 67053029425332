import React from 'react';
import { FormattedMessage } from 'react-intl';

import './RequiredFieldMarker.scss';

const RequiredFieldMarker = () => (
  <span className="required-field-marker">
    <FormattedMessage id="dictionary.requiredFieldMarker" />
  </span>
);

export default RequiredFieldMarker;
