import { IonLoading } from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';

import { State } from '../types';

import './GlobalLoadingIndicator.scss';

const GlobalLoadingIndicator = () => {
  const globalLoadingStatus = useSelector((root: State.Root) => root.loading.globalLoading);
  return <IonLoading isOpen={globalLoadingStatus} spinner={null} />;
};

export default GlobalLoadingIndicator;
