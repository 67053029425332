import { IonChip, IonCol, IonGrid, IonIcon, IonLabel, IonProgressBar, IonRow } from '@ionic/react';
import loadImage from 'blueimp-load-image';
import { cloudUpload } from 'ionicons/icons';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import ErrorMessage from '../../components/forms/ErrorMessage';
import FileInput from '../../components/forms/FileInput';
import ImagesFieldItems from '../../components/forms/ImagesFieldItems';
import { maxFileUploadSizeMb } from '../../globals';
import useMountedTracking from '../../hooks/useMountedTracking';
import { RelationshipsFieldManager } from '../../hooks/useRelationshipsFieldManager';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { AjaxError } from '../../services/ajaxRequest';
import { forceArray } from '../../services/arrayUtils';
import { orientedImageToDataURL } from '../../services/imageUtils';
import { translateApiError } from '../../services/translateApiError';
import { createImage } from '../../thunks/apiThunks';
import { JSONApi, Models } from '../../types';

type Props = {
  fieldManager: RelationshipsFieldManager<Models.Image>;
};

const ImagesField = ({ fieldManager }: Props) => {
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();
  const [fileError, setFileError] = useState<string | undefined>();

  const handleCreate = useCallback(
    (imageData: string) => {
      dispatch(createImage(imageData))
        .then((response: JSONApi.Response<Models.Image>) => {
          if (isMounted.current) {
            const responseImage = forceArray(response.data)[0];
            fieldManager.didUpload();
            fieldManager.add(responseImage);
          }
        })
        .catch((error: AjaxError) => {
          fieldManager.didUpload();
          const errorMessage = translateApiError(intl, 'imageUpload', error.errors()[0]);
          setFileError(errorMessage);
        });
    },
    [dispatch, fieldManager, intl, isMounted]
  );

  const handleFail = useCallback(() => {
    fieldManager.didUpload();
    setFileError(intl.formatMessage({ id: 'errors.imageUpload.file' }));
  }, [fieldManager, intl]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target;
      const newFile = input?.files?.[0];
      if (newFile) {
        if (newFile.size / 1000000.0 > maxFileUploadSizeMb) {
          setFileError(
            intl.formatMessage(
              { id: 'errors.imageUpload.file.fileSizeIsLessThanOrEqualTo' },
              { maxMb: `${maxFileUploadSizeMb.toString()}MB` }
            )
          );
        } else {
          setFileError(undefined);
          fieldManager.willUpload(newFile);
          loadImage(
            newFile,
            orientedImg => {
              const dataURL = orientedImageToDataURL(orientedImg);
              if (dataURL) {
                handleCreate(dataURL);
              } else if (isMounted.current) {
                handleFail();
              }
            },
            { canvas: true, contain: true, maxHeight: 1280, maxWidth: 1280, orientation: true }
          );
        }
      }
    },
    [fieldManager, handleCreate, handleFail, intl, isMounted]
  );

  return (
    <IonGrid>
      <ImagesFieldItems fieldManager={fieldManager} />
      {fileError && (
        <IonRow>
          <IonCol>
            <ErrorMessage>{fileError}</ErrorMessage>
          </IonCol>
        </IonRow>
      )}
      {fieldManager.isUploading && fieldManager.uploadingFile && (
        <IonRow>
          <IonCol>
            <IonIcon icon={cloudUpload} />
            <IonLabel>{fieldManager.uploadingFile.name}</IonLabel>
            <IonProgressBar type="indeterminate" />
          </IonCol>
        </IonRow>
      )}
      <FileInput
        accept="image/*"
        className="image-field"
        disabled={fieldManager.isUploading}
        multiple
        name="postImage"
        onChange={handleChange}
      >
        <IonRow>
          <IonCol>
            <IonChip color="secondary">
              <IonIcon icon={cloudUpload} />
              <IonLabel>
                <FormattedMessage id="dictionary.addImage" />
              </IonLabel>
            </IonChip>
          </IonCol>
        </IonRow>
      </FileInput>
    </IonGrid>
  );
};

export default ImagesField;
