import { IonButton, IonButtons, IonItem, IonLabel } from '@ionic/react';
import { FormApi } from 'final-form';
import React, { useCallback } from 'react';
import { FormSpy } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { apiClearCachedResponsesForEndpoint } from '../../actions/apiActions';
import DeletePostModal from '../../components/DeletePostModal';
import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormInput from '../../components/forms/FormInput';
import FormMentionsTextarea from '../../components/forms/FormMentionsTextarea';
import FormSubmit from '../../components/forms/FormSubmit';
import ImagesField from '../../components/forms/ImagesField';
import RequiredFieldMarker from '../../components/forms/RequiredFieldMarker';
import SubmitError from '../../components/forms/SubmitError';
import { maxTextInputLength, maxTitleLength } from '../../globals';
import useDismissModalAndGoTo from '../../hooks/useDismissModalAndGoTo';
import useMountedTracking from '../../hooks/useMountedTracking';
import useOpener, { Opener } from '../../hooks/useOpener';
import useRelationshipsFieldManager from '../../hooks/useRelationshipsFieldManager';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { getPostImages } from '../../selectors';
import { forceArray } from '../../services/arrayUtils';
import { handleFormSubmission } from '../../services/formUtils';
import { createNewsItem, deleteImage, updateNewsItem } from '../../thunks/apiThunks';
import { ApiEndpoint, JSONApi, Models, State } from '../../types';

import './CustomForm.scss';

type FormValues = Models.NewsItemFormData;

type Props = {
  autofocus: boolean;
  contentForwardRef: React.RefObject<HTMLIonContentElement>;
  group: JSONApi.GroupResource;
  newsItem?: JSONApi.NewsItemResource;
  onSuccess?: () => void;
  opener: Opener;
};

const NewsItemForm = ({
  autofocus,
  contentForwardRef,
  group,
  newsItem,
  onSuccess,
  opener
}: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const deleteModalOpener = useOpener();
  const dismissModalAndGoTo = useDismissModalAndGoTo(opener);
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  let images: JSONApi.ImageResource[] = [];
  if (newsItem) {
    images = getPostImages(apiData, newsItem);
  }
  const imagesFieldManager = useRelationshipsFieldManager(images, deleteImage);

  const initialValues: FormValues = {
    body: newsItem ? newsItem.attributes.body : '',
    link: newsItem ? newsItem.attributes.link : '',
    title: newsItem ? newsItem.attributes.title : ''
  };

  const handleSubmit = useCallback(
    async (values: FormValues, form: FormApi<FormValues>) => {
      const action = newsItem
        ? updateNewsItem(
            group.attributes.slug,
            { ...newsItem.attributes, ...values, slug: newsItem.attributes.slug },
            imagesFieldManager.relationships
          )
        : createNewsItem(group.attributes.slug, values, imagesFieldManager.relationships);
      return handleFormSubmission<FormValues, Models.NewsItem>({
        action,
        contentForwardRef,
        dispatch,
        errorTranslationLocation: 'newsItem',
        form,
        intl,
        isMounted,
        onSuccess: response => {
          dispatch(apiClearCachedResponsesForEndpoint(ApiEndpoint.NEWS_ITEM_SEARCH));
          const newNewsItem = forceArray(response.data)[0];
          if (onSuccess) {
            onSuccess();
          }
          dismissModalAndGoTo(`/g/${group.attributes.slug}/news/${newNewsItem.attributes.slug}`);
        },
        successTKey: `forms.newsItem.${newsItem ? 'update' : 'create'}.success`,
        values
      });
    },
    [
      contentForwardRef,
      dismissModalAndGoTo,
      dispatch,
      group.attributes.slug,
      imagesFieldManager.relationships,
      intl,
      isMounted,
      newsItem,
      onSuccess
    ]
  );

  const validationSchema = Yup.object().shape({
    body: Yup.string()
      .nullable()
      .max(
        maxTextInputLength,
        intl.formatMessage({ id: 'errors.newsItem.body.tooLong' }, { count: maxTextInputLength })
      ),
    link: Yup.string()
      .nullable()
      .max(
        maxTextInputLength,
        intl.formatMessage({ id: 'errors.newsItem.link.tooLong' }, { count: maxTextInputLength })
      ),
    title: Yup.string()
      .required(intl.formatMessage({ id: 'errors.newsItem.title.blank' }))
      .max(
        maxTitleLength,
        intl.formatMessage({ id: 'errors.newsItem.title.tooLong' }, { count: maxTitleLength })
      )
  });

  return (
    <FormContainer<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ form, handleSubmit }) => (
        <div className="custom-form-wrapper">
          <Form<FormValues>
            className="news-item-form"
            onFormSubmit={handleSubmit}
            submit={form.submit}
          >
            <SubmitError />
            <IonLabel>
              <FormattedMessage id="models.newsItem.attributes.title.label" />
              <RequiredFieldMarker />
            </IonLabel>
            <FormError name="title" />
            <FormInput
              autocapitalize="on"
              autocorrect="on"
              autofocus={autofocus}
              name="title"
              placeholder={intl.formatMessage({
                id: 'models.newsItem.attributes.title.placeholder'
              })}
            />
            <IonLabel>
              <FormattedMessage id="models.newsItem.attributes.body.label" />
            </IonLabel>
            <FormError name="body" />
            <FormMentionsTextarea
              name="body"
              placeholder={intl.formatMessage({
                id: 'models.newsItem.attributes.body.placeholder'
              })}
              rows={6}
            />
            <IonLabel>
              <FormattedMessage id="models.newsItem.attributes.link.label" />
            </IonLabel>
            <FormError name="link" />
            <FormInput
              name="link"
              placeholder={intl.formatMessage({
                id: 'models.newsItem.attributes.link.placeholder'
              })}
            />
            <FormSpy<FormValues> subscription={{ values: true }}>
              {() => <ImagesField fieldManager={imagesFieldManager} />}
            </FormSpy>
            <IonItem color="transparent" lines="none">
              <IonButtons slot="end">
                <FormSubmit allowPristineSubmit fill="solid">
                  <FormattedMessage id={newsItem ? 'dictionary.update' : 'dictionary.post'} />
                </FormSubmit>
                {newsItem && (
                  <>
                    <IonButton color="danger" fill="clear" onClick={deleteModalOpener.toggle}>
                      <FormattedMessage id="dictionary.delete" />
                    </IonButton>
                    <DeletePostModal group={group} opener={deleteModalOpener} post={newsItem} />
                  </>
                )}
              </IonButtons>
            </IonItem>
          </Form>
        </div>
      )}
    </FormContainer>
  );
};

export default NewsItemForm;
