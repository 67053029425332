import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { apiClearCachedResponsesForEndpoint } from '../actions/apiActions';
import SetupGroupNamesForm from '../components/forms/SetupGroupNamesForm';
import SetupGroupPersonalizationForm from '../components/forms/SetupGroupPersonalizationForm';
import SetupGroupVerificationSettingsForm from '../components/forms/SetupGroupVerificationSettingsForm';
import LocalLoadingIndicator from '../components/LocalLoadingIndicator';
import SetupGroupAccount from '../components/SetupGroupAccount';
import SetupGroupFeatures from '../components/SetupGroupFeatures';
import SetupGroupFreePlan from '../components/SetupGroupFreePlan';
import SetupGroupPayment from '../components/SetupGroupPayment';
import SetupGroupRules from '../components/SetupGroupRules';
import SetupGroupSharing from '../components/SetupGroupSharing';
import SetupPage from '../components/SetupPage';
import UserAccountWizard from '../components/UserAccountWizard';
import UserAccountWizardPageWrapper from '../components/UserAccountWizardPageWrapper';
import useActionCableChannel from '../hooks/useActionCableChannel';
import useCurrentUser from '../hooks/useCurrentUser';
import useMountedTracking from '../hooks/useMountedTracking';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { findResource, getUserMemberForGroup } from '../selectors';
import { forceArray } from '../services/arrayUtils';
import { planManagementEnabled } from '../services/platformUtils';
import { loadGroup } from '../thunks/apiThunks';
import { ApiEndpoint, JSONApi, Models, State } from '../types';

export enum WizardSteps {
  NAMES = 'names',
  PERSONALIZE = 'personalize',
  VERIFICATION = 'verification',
  RULES = 'rules',
  FEATURES = 'features',
  SETUP_ACCOUNT = 'setup-account',
  PAYMENT = 'payment',
  SHARE = 'share'
}

/* eslint-disable complexity */
const CreateGroupPage = () => {
  const apiData = useSelector((root: State.Root) => root.api);
  const currentUser = useCurrentUser();
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const isMounted = useMountedTracking();
  const { slug, step } = useParams<{ slug?: string; step?: string }>();
  const [group, setGroup] = useState<JSONApi.GroupResource | null | undefined>(undefined);

  useActionCableChannel({
    channelName: { channel: 'GroupChannel', id: group?.attributes?.slug },
    onReceived: () => {
      if (group) {
        dispatch(apiClearCachedResponsesForEndpoint(ApiEndpoint.LOAD_GROUP));
        dispatch(loadGroup(group.attributes.slug));
      }
    }
  });

  useEffect(() => {
    if (slug) {
      const groupFromRedux = findResource<Models.Group>(apiData, 'group', 'slug', slug, null);
      if (groupFromRedux) {
        setGroup(groupFromRedux);
      } else {
        dispatch(loadGroup(slug))
          .then(response => {
            response.dataLoadedIntoStatePromise.then(() => {
              if (isMounted.current) {
                setGroup(forceArray(response.data)[0]);
              }
            });
          })
          .catch(() => {
            setGroup(null);
          });
      }
    } else {
      setGroup(null);
    }
  }, [apiData, dispatch, isMounted, slug]);

  // if the step is defined in the params, use it
  // otherwise, if there is a group present, push forward to personalization
  // use the first step as a backup if neither of those conditions is met
  // in the future, the group should probably track the steps that have been completed
  let wizardStep: WizardSteps = WizardSteps.NAMES;
  const steps = Object.values(WizardSteps) as string[];
  if (step && steps.includes(step)) {
    wizardStep = step as WizardSteps;
  } else {
    if (group) {
      wizardStep = WizardSteps.PERSONALIZE;
    }
  }

  useEffect(() => {
    if (group && currentUser) {
      const member = getUserMemberForGroup(apiData, currentUser, group);
      if (!member || !member.attributes.moderator) {
        history.push(`/g/${group.attributes.slug}`);
      }
    }
  }, [apiData, currentUser, group, history, wizardStep]);

  const handleLoginSuccess = useCallback(() => {
    history.push('/create');
  }, [history]);

  if (group === undefined || currentUser === undefined) {
    return <LocalLoadingIndicator />;
  }

  if (currentUser === null) {
    return (
      <UserAccountWizardPageWrapper titleId="pages.createGroup.creating.title">
        <UserAccountWizard group={group} handleLoginSuccess={handleLoginSuccess} />
      </UserAccountWizardPageWrapper>
    );
  }

  return (
    <SetupPage
      defaultBackHref="/"
      titleKey={group ? 'pages.createGroup.customizing.title' : 'pages.createGroup.creating.title'}
    >
      <>
        {(!group || wizardStep === WizardSteps.NAMES) && <SetupGroupNamesForm group={group} />}
        {group && wizardStep === WizardSteps.PERSONALIZE && (
          <SetupGroupPersonalizationForm group={group} />
        )}
        {group && wizardStep === WizardSteps.VERIFICATION && (
          <SetupGroupVerificationSettingsForm group={group} />
        )}
        {group && wizardStep === WizardSteps.RULES && <SetupGroupRules group={group} />}
        {group && wizardStep === WizardSteps.FEATURES && (
          <SetupGroupFeatures currentUser={currentUser} group={group} />
        )}
        {group && wizardStep === WizardSteps.SETUP_ACCOUNT && (
          <SetupGroupAccount currentUser={currentUser} group={group} />
        )}
        {group && wizardStep === WizardSteps.PAYMENT && (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {planManagementEnabled() ? (
              <SetupGroupPayment group={group} />
            ) : (
              <SetupGroupFreePlan group={group} />
            )}
          </>
        )}
        {group && wizardStep === WizardSteps.SHARE && <SetupGroupSharing group={group} />}
      </>
    </SetupPage>
  );
};
/* eslint-enable complexity */

export default CreateGroupPage;
