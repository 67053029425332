import { IonItem } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { alertError, alertSuccess } from '../actions/notificationActions';
import SearchableSelectModal, {
  SearchableSelectOptionData
} from '../components/forms/SearchableSelectModal';
import useMountedTracking from '../hooks/useMountedTracking';
import useOpener from '../hooks/useOpener';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { cityQueryHandler } from '../services/placeSearch';
import { createSubscriptionLocationFilter } from '../thunks/apiThunks';
import { JSONApi } from '../types';

type Props = {
  group: JSONApi.GroupResource;
};

const AddSubscriptionLocationFilter = ({ group }: Props) => {
  const isMounted = useMountedTracking();
  const dispatch = useThunkDispatch();
  const modalOpener = useOpener();

  const handleSelectOption = useCallback(
    (option: SearchableSelectOptionData | undefined) => {
      if (option) {
        dispatch(createSubscriptionLocationFilter(group.attributes.slug, option.value))
          .then(() => {
            if (isMounted.current) {
              dispatch(alertSuccess('forms.newSubscriptionLocationFilter.create.success'));
              modalOpener.close();
            }
          })
          .catch(() => {
            dispatch(alertError('forms.newSubscriptionLocationFilter.create.error'));
          });
      }
    },
    [dispatch, group.attributes.slug, isMounted, modalOpener]
  );

  return (
    <>
      <IonItem
        button
        disabled={modalOpener.isOpen || modalOpener.isPresented}
        lines="none"
        onClick={modalOpener.open}
        role="button"
      >
        <FormattedMessage id="forms.newSubscriptionLocationFilter.create.title" />
      </IonItem>
      <SearchableSelectModal
        attributeNameTKey="forms.newSubscriptionLocationFilter.create.attributeName"
        onSelectOption={handleSelectOption}
        opener={modalOpener}
        queryHandler={cityQueryHandler}
        selectedOption={undefined}
        titleTKey="forms.newSubscriptionLocationFilter.create.searchTitle"
      />
    </>
  );
};

export default AddSubscriptionLocationFilter;
