import { IonBadge, IonItem, IonLabel, IonList, IonText } from '@ionic/react';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';

import AdminManageGroup from '../components/AdminManageGroup';
import Img from '../components/Img';
import AdminPage from '../components/layout/AdminPage';
import LocalLoadingIndicator from '../components/LocalLoadingIndicator';
import MemberAvatar from '../components/MemberAvatar';
import Nbsp from '../components/Nbsp';
import Paragraph from '../components/Paragraph';
import SearchResults from '../components/SearchResults';
import useApiData from '../hooks/useApiData';
import { getGroup } from '../selectors';
import { loadGroup, loadMemberSearch, loadMoreMemberSearchResults } from '../thunks/apiThunks';
import { ApiEndpoint, ModelAttributes, Models, Search, State } from '../types';

import './SettingsList.scss';

const AdminGroupPage = () => {
  const apiData = useSelector((root: State.Root) => root.api);
  const intl = useIntl();
  const { slug } = useParams<{ slug: string }>();

  const [memberSearchParams] = useState<Search.MemberParams>({
    country: null,
    industry: null,
    lat: null,
    lng: null,
    location: null,
    query: '',
    radius: null,
    role: ModelAttributes.MemberRole.OWNER,
    status: ModelAttributes.MemberStatus.APPROVED,
    traitOptionIds: ''
  });

  const findGroup = useCallback(() => getGroup(apiData, slug), [apiData, slug]);
  const { loading, responseData } = useApiData<Models.Group>(
    loadGroup(slug),
    findGroup,
    'AdminGroupPage'
  );
  const group = responseData[0];

  if (loading) {
    return <LocalLoadingIndicator />;
  }

  if (!group) {
    return <Redirect to="/admin/groups" />;
  }

  return (
    <AdminPage h2Key="pages.admin.group.title">
      {contentRef => (
        <div className="ion-padding">
          <IonList className="ion-no-padding settings-list">
            <AdminManageGroup group={group} lines="none" link={false} />
            <IonItem color="light" lines="none">
              <Paragraph>{group.attributes.description}</Paragraph>
            </IonItem>
            {group.attributes.bgImageUrl && (
              <IonItem color="light" lines="none">
                <span className="image-wrapper">
                  <Img
                    alt={intl.formatMessage({ id: 'models.group.attributes.bgImage.alt' })}
                    src={group.attributes.bgImageUrl}
                  />
                </span>
              </IonItem>
            )}
            <IonItem color="light" lines="none">
              {group.attributes.memberVerificationRequired ? (
                <IonBadge color="warning">
                  <FormattedMessage id="dictionary.private" />
                </IonBadge>
              ) : (
                <IonBadge color="success">
                  <FormattedMessage id="dictionary.public" />
                </IonBadge>
              )}
              <Nbsp />
              <IonBadge color="primary">
                <FormattedMessage
                  id="models.group.nMembers"
                  values={{ count: group.attributes.memberCount }}
                />
              </IonBadge>
              {group.attributes.planFree && (
                <>
                  <Nbsp />
                  <IonBadge color="danger">
                    <FormattedMessage id="models.group.attributes.planFree.label" />
                  </IonBadge>
                </>
              )}
              {group.attributes.planActive && (
                <>
                  <Nbsp />
                  <IonBadge color="success">
                    <FormattedMessage id="models.group.attributes.planActive.label" />
                  </IonBadge>
                </>
              )}
            </IonItem>
          </IonList>
          <SearchResults<Models.MemberSearch, Models.Member, Search.MemberParams>
            contentRef={contentRef}
            group={group}
            loadDataActionCreator={loadMemberSearch}
            loadMoreActionCreator={loadMoreMemberSearchResults}
            searchEndpoint={ApiEndpoint.MEMBER_SEARCH}
            searchParams={memberSearchParams}
            showCount={false}
          >
            {members => (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {members.length > 0 && (
                  <>
                    <IonLabel className="settings-list-label">
                      <FormattedMessage id="dictionary.owners" />
                    </IonLabel>
                    <IonList className="ion-no-padding settings-list">
                      {members.map(member => (
                        <IonItem
                          color="light"
                          key={member.id}
                          lines="none"
                          routerLink={`/g/${slug}/members/${member.attributes.slug}`}
                        >
                          <MemberAvatar member={member} slot="start" />
                          <IonLabel>{member.attributes.fullName}</IonLabel>
                          {member.attributes.email && (
                            <IonText color="primary">{member.attributes.email}</IonText>
                          )}
                        </IonItem>
                      ))}
                    </IonList>
                  </>
                )}
              </>
            )}
          </SearchResults>
        </div>
      )}
    </AdminPage>
  );
};

export default AdminGroupPage;
