import {
  IonAvatar,
  IonCard,
  IonCardContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonSkeletonText,
  IonText
} from '@ionic/react';
import { mailUnread } from 'ionicons/icons';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import MemberAvatar from '../components/MemberAvatar';
import RelativeTimestamp from '../components/RelativeTimestamp';
import useConversation from '../hooks/useConversation';
import useCurrentMember from '../hooks/useCurrentMember';
import { JSONApi } from '../types';

import './ConversationCard.scss';

type Props = {
  conversation: JSONApi.ConversationResource;
  group: JSONApi.GroupResource;
};

const ConversationCard = ({ conversation, group }: Props) => {
  const { coverLetter, threadType } = conversation.attributes;
  const { counterparty, notes } = useConversation(conversation);
  const currentMember = useCurrentMember();

  const href = `/g/${group.attributes.slug}/inbox/conversations/${conversation.attributes.slug}`;

  const mostRecentContent = notes.length > 0 ? notes[0].attributes.note : coverLetter;

  return (
    <IonCard button className="conversation-card ion-activatable" role="button" routerLink={href}>
      <IonCardContent>
        <IonItem className="compact" color="transparent" lines="none">
          <IonLabel color="dark">
            {counterparty ? (
              <FormattedMessage
                id={`models.conversation.attributes.threadType.${threadType}.title`}
                values={{ counterparty: counterparty.attributes.fullName }}
              />
            ) : (
              <IonSkeletonText />
            )}
          </IonLabel>
          {conversation.attributes.unread && (
            <IonIcon color="danger" icon={mailUnread} size="small" slot="end" />
          )}
        </IonItem>
        {mostRecentContent && (
          <div className="snippet">
            <IonText color="medium">{mostRecentContent}</IonText>
          </div>
        )}
        <IonItem className="compact" color="transparent" lines="none">
          <div slot="start">
            {counterparty ? (
              <MemberAvatar member={counterparty} />
            ) : (
              <IonAvatar>
                <IonSkeletonText animated />
              </IonAvatar>
            )}
            {currentMember ? (
              <MemberAvatar member={currentMember} />
            ) : (
              <IonAvatar>
                <IonSkeletonText animated />
              </IonAvatar>
            )}
          </div>
          <IonText className="smallcaps" color="primary" slot="end">
            <RelativeTimestamp date={conversation.attributes.mostRecentActivityAt.valueOf()} />
          </IonText>
        </IonItem>
      </IonCardContent>
    </IonCard>
  );
};

export default ConversationCard;
