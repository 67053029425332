import { IonList } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import AccountTopItem from '../../components/forms/AccountTopItem';
import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormInput from '../../components/forms/FormInput';
import FormItem from '../../components/forms/FormItem';
import FormSearchableSelect from '../../components/forms/FormSearchableSelect';
import FormSubmit from '../../components/forms/FormSubmit';
import { SearchableSelectOptionData } from '../../components/forms/SearchableSelectModal';
import SubmitError from '../../components/forms/SubmitError';
import { maxTextInputLength, stringArrayDelimiter } from '../../globals';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { handleFormSubmission } from '../../services/formUtils';
import { cityQueryHandler } from '../../services/placeSearch';
import { generateTextQueryHandler } from '../../services/textSearch';
import { updateUser } from '../../thunks/apiThunks';
import { JSONApi, ModelAttributes, Models } from '../../types';

type FormValues = Models.UserFormData;

type Props = {
  currentUser: JSONApi.UserResource;
};

const AccountForm = ({ currentUser }: Props) => {
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const industries = intl
    .formatMessage({ id: 'models.user.attributes.industry.options' })
    .split(stringArrayDelimiter) as ModelAttributes.Industry[];
  const industryOptions: SearchableSelectOptionData[] = industries.map(industry => ({
    id: industry,
    label: industry,
    value: industry
  }));
  industryOptions.unshift({
    id: 'n/a',
    label: intl.formatMessage({ id: 'dictionary.notApplicable' }),
    value: ''
  });

  const initialValues: FormValues = {
    firstName: currentUser.attributes.firstName,
    hometown: currentUser.attributes.hometown,
    industry: currentUser.attributes.industry,
    lastName: currentUser.attributes.lastName,
    location: currentUser.attributes.location
  };

  const handleSubmit = useCallback(
    async (values: FormValues) =>
      handleFormSubmission({
        action: updateUser(values, currentUser.id),
        dispatch,
        errorTranslationLocation: 'user',
        intl,
        isMounted,
        successTKey: 'forms.user.update.success',
        values
      }),
    [currentUser.id, dispatch, intl, isMounted]
  );

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: 'errors.user.firstName.blank' }))
      .max(
        maxTextInputLength,
        intl.formatMessage({ id: 'errors.user.firstName.tooLong' }, { count: maxTextInputLength })
      ),
    hometown: Yup.string()
      .nullable()
      .max(
        maxTextInputLength,
        intl.formatMessage({ id: 'errors.user.hometown.tooLong' }, { count: maxTextInputLength })
      ),
    lastName: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: 'errors.user.lastName.blank' }))
      .max(
        maxTextInputLength,
        intl.formatMessage({ id: 'errors.user.lastName.tooLong' }, { count: maxTextInputLength })
      ),
    location: Yup.string()
      .nullable()
      .max(
        maxTextInputLength,
        intl.formatMessage({ id: 'errors.user.location.tooLong' }, { count: maxTextInputLength })
      )
  });

  return (
    <FormContainer<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ form, handleSubmit }) => (
        <Form<FormValues> className="account-form" onFormSubmit={handleSubmit} submit={form.submit}>
          <IonList className="ion-margin-bottom ion-no-padding settings-list">
            <AccountTopItem currentUser={currentUser} />
            <SubmitError />
            <FormError name="firstName" />
            <FormItem required>
              <FormInput
                aria-label={intl.formatMessage({
                  id: 'models.user.attributes.firstName.label'
                })}
                autocapitalize="on"
                autofocus
                name="firstName"
                placeholder={intl.formatMessage({
                  id: 'models.user.attributes.firstName.placeholder'
                })}
                type="text"
              />
            </FormItem>
            <FormError name="lastName" />
            <FormItem required>
              <FormInput
                aria-label={intl.formatMessage({
                  id: 'models.user.attributes.lastName.label'
                })}
                autocapitalize="on"
                name="lastName"
                placeholder={intl.formatMessage({
                  id: 'models.user.attributes.lastName.label'
                })}
                type="text"
              />
            </FormItem>
            <FormError name="location" />
            <FormItem>
              <FormSearchableSelect
                aria-label={intl.formatMessage({
                  id: 'models.user.attributes.location.label'
                })}
                attributeNameTKey="models.user.attributes.location.label"
                name="location"
                placeholder={intl.formatMessage({
                  id: 'models.user.attributes.location.placeholder'
                })}
                queryHandler={cityQueryHandler}
                titleTKey="models.user.attributes.location.label"
                type="text"
              />
            </FormItem>
            <FormError name="hometown" />
            <FormItem>
              <FormSearchableSelect
                aria-label={intl.formatMessage({
                  id: 'models.user.attributes.hometown.label'
                })}
                attributeNameTKey="models.user.attributes.hometown.label"
                name="hometown"
                placeholder={intl.formatMessage({
                  id: 'models.user.attributes.hometown.placeholder'
                })}
                queryHandler={cityQueryHandler}
                titleTKey="models.user.attributes.hometown.label"
                type="text"
              />
            </FormItem>
            <FormItem lines="none">
              <FormSearchableSelect
                aria-label={intl.formatMessage({
                  id: 'models.user.attributes.industry.label'
                })}
                defaultOptions={industryOptions}
                name="industry"
                placeholder={intl.formatMessage({
                  id: 'models.user.attributes.industry.placeholder'
                })}
                queryHandler={generateTextQueryHandler(industryOptions)}
                titleTKey="models.user.attributes.industry.label"
                type="text"
              />
            </FormItem>
          </IonList>
          <FormSubmit className="ion-margin-bottom">
            <FormattedMessage id="dictionary.save" />
          </FormSubmit>
        </Form>
      )}
    </FormContainer>
  );
};

export default AccountForm;
