import { IonAvatar } from '@ionic/react';
import React from 'react';

import Img from '../components/Img';
import { maybeSlot } from '../services/componentUtils';
import { randomAvatarSrc } from '../services/randomAvatarSrc';

type Props = {
  alt: string;
  id: string;
  slot?: string;
};

const AvatarRandom = ({ alt, id, slot }: Props) => (
  <IonAvatar className="random" {...maybeSlot(slot)}>
    <Img alt={alt} src={randomAvatarSrc(id)} />
  </IonAvatar>
);

export default AvatarRandom;
