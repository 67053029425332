import equal from 'deep-equal';
import { produce } from 'immer';
import { DateTime } from 'luxon';
import { IntlShape } from 'react-intl';

import { translateApiError } from '../services/translateApiError';
import { isArray, isBlob, isDateTime, isFile, isObject, isString } from '../services/typeDetection';
import { JSONApi, State } from '../types';

const updateOrInsertResource = (state: State.Api, newResource: JSONApi.BaseResource) => {
  const newResourceCopy = { ...newResource };

  let newState = state;
  let existingResource: JSONApi.BaseResource | undefined;

  if (state.resources[newResourceCopy.type] && newResourceCopy.id) {
    existingResource = state.resources[newResourceCopy.type][newResourceCopy.id];
  }

  if (existingResource) {
    type SimpleRelationships = {
      [type: string]: JSONApi.Relationship;
    };
    const applyRelationships: SimpleRelationships = {};
    const newResourceRelationships: JSONApi.Relationships = newResourceCopy.relationships || {};

    // If the existing resource contained relationships that are not in the new resource then copy
    // them over to the new one.
    for (const relationship in existingResource.relationships) {
      if (!newResourceRelationships[relationship]) {
        applyRelationships[relationship] = existingResource.relationships[relationship];
      }
    }

    // Sometimes the new resource will have a subset of the attributes of the old resource. To
    // handle that, merge the two together with the new resource's values having precedence.
    newResourceCopy.attributes = { ...existingResource.attributes, ...newResourceCopy.attributes };

    if (!Object.prototype.hasOwnProperty.call(newResourceCopy, 'relationships')) {
      Object.assign(newResourceCopy, { applyRelationships });
    } else {
      Object.assign(newResourceCopy.relationships, applyRelationships);
    }
  }

  if (!equal(existingResource, newResourceCopy) && newResourceCopy.id) {
    newState = produce(newState, draft => {
      if (!draft.resources[newResourceCopy.type]) {
        draft.resources[newResourceCopy.type] = {};
      }
      draft.resources[newResourceCopy.type][newResourceCopy.id] = newResourceCopy;
    });
  }

  return newState;
};

export const updateOrInsertResourcesIntoState = (
  state: State.Api,
  resources: JSONApi.BaseResource[]
) => resources.reduce(updateOrInsertResource, state);

export type GenericJSON = {
  [key: string]: GenericJSONValue;
};

export type GenericJSONValue =
  | GenericJSON
  | GenericJSON[]
  | DateTime
  | string
  | number
  | null
  | undefined;

export const convertTimestamps = (data: GenericJSON) => {
  const result: GenericJSON = {};
  Object.keys(data).forEach(key => {
    const node = data[key];
    if (isDateTime(node)) {
      result[key] = node;
    } else if (isArray(node)) {
      result[key] = node.map(nodeItem => convertTimestamps(nodeItem));
    } else if (isObject(node)) {
      result[key] = convertTimestamps(node);
    } else if (isString(node)) {
      const stringNode = node;
      // DateTime will be perfectly happy with any four-digit string of digits, which it will
      // interpret as a year. We only want to match strings that look at least like an ISO 8601
      // date.
      if (/^\d\d\d\d-\d\d-\d\d/.test(stringNode)) {
        const dt = DateTime.fromISO(stringNode);
        if (dt.isValid) {
          result[key] = dt;
        } else {
          result[key] = node;
        }
      } else {
        result[key] = node;
      }
    } else {
      result[key] = node;
    }
  });
  return result;
};

export const appendToFormData = (data: GenericJSON, formData: FormData, prefix = '') => {
  Object.keys(data).forEach(key => {
    const node = data[key];
    const property = prefix ? `${prefix}[${key}]` : key;
    if (isArray(node)) {
      node.forEach(value => {
        const key = `${prefix}[]`;
        appendToFormData(value, formData, key);
      });
    } else if (isDateTime(node)) {
      formData.append(property, node.toISO() as string);
    } else if (isObject(node) && !isFile(node) && !isBlob(node)) {
      const newPrefix = prefix ? `${prefix}[${key}]` : key;
      appendToFormData(node as GenericJSON, formData, newPrefix);
    } else if (isBlob(node) || isString(node)) {
      formData.append(property, node as Blob | string);
    } else {
      formData.append(property, JSON.stringify(node));
    }
  });
};

export const getErrorMessages = (intl: IntlShape, errors: JSONApi.Error[], errorLocation: string) =>
  errors
    .map(error => {
      if (error.meta) {
        return translateApiError(intl, errorLocation, error);
      }
      return undefined;
    })
    .filter(error => !!error) as string[];
