import { IonLabel, IonList, IonListHeader } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const BlockedItemList = ({ children, titleKey }: React.PropsWithChildren<{ titleKey: string }>) => (
  <IonList className="custom-list">
    <IonListHeader>
      <IonLabel>
        <FormattedMessage id={titleKey} />
      </IonLabel>
    </IonListHeader>
    {children}
  </IonList>
);

export default BlockedItemList;
