import React, { useState } from 'react';

import { setConversationSearchParams } from '../actions/searchActions';
import SearchQuery from '../components/SearchQuery';
import useSearchParamsFromUrl from '../hooks/useSearchParamsFromUrl';
import { defaultConversationSearchParams } from '../services/searchUtils';
import { Search } from '../types';

import '../components/forms/CustomForm.scss';
import './Searchbox.scss';

type Props = {
  isOpen: boolean;
};

const InboxSearchForm = ({ isOpen }: Props) => {
  const [searchParams, setSearchParams] = useState<Search.ConversationParams>(
    defaultConversationSearchParams()
  );

  const searchFields: (keyof Search.Params)[] = ['query'];

  useSearchParamsFromUrl<Search.ConversationParams>(
    searchFields,
    setSearchParams,
    searchParams,
    setConversationSearchParams
  );

  if (isOpen) {
    return (
      <div className="subheader">
        <div className="custom-form-wrapper ion-padding searchbox">
          <SearchQuery<Search.ConversationParams>
            autofocus={false}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </div>
      </div>
    );
  }
  return null;
};

export default InboxSearchForm;
