/*
 * this file is an overwrite of the native form element. it:
 * 1) disables native validations by default, b/c we want our form library to handle validation
 * 2) submits the form when the user performs an `Enter` keypress by default
 * 3) requires that you include an `onSubmit` prop, which is normally optional
 */
import React, { useCallback } from 'react';
import { AnyObject } from 'react-final-form';

export default function Form<FormValues>({
  onFormSubmit,
  onSubmit,
  submit,
  submitOnEnter = true,
  ...formProps
}: React.HTMLProps<HTMLFormElement> & {
  // React Final Form's handleSubmit function returns a promise, so supplying that directly
  // to the native form's onSubmit prop (which expects a void returning function) is considered
  // bad practice and violates the linter. our solution is to use a separate onFormSubmit prop
  // and execute both onFormSubmit and onSubmit within a void returning callback.
  onFormSubmit: (
    event?: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>> | undefined
  ) => Promise<AnyObject | undefined> | undefined;
  onSubmit?: React.FormEventHandler<HTMLFormElement> | undefined;
  submit: () => Promise<FormValues | undefined> | undefined;
  submitOnEnter?: boolean;
}) {
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (submitOnEnter && event.key === 'Enter') {
        submit();
        // the form may be on a page with other forms and we want to stop the propagation of
        // the enter keydown right here
        event.stopPropagation();
        // the native form behavior might try to submit the form if there's only one field
        // in the form and we want to prevent that since we handle form submission manually
        event.preventDefault();
      }
    },
    [submit, submitOnEnter]
  );

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      if (onSubmit) {
        onSubmit(event);
      }
      onFormSubmit(event);
    },
    [onFormSubmit, onSubmit]
  );

  return (
    <div onKeyDown={handleKeyDown} role="presentation">
      {/* eslint-disable-next-line react/forbid-elements */}
      <form noValidate {...formProps} onSubmit={handleSubmit} />
    </div>
  );
}
