import mbxGeocoder, {
  GeocodeFeature,
  GeocodeQueryType
} from '@mapbox/mapbox-sdk/services/geocoding';

import {
  QueryHandler,
  SearchableSelectOptionData
} from '../components/forms/SearchableSelectModal';
import stateAbbreviations from '../i18n/stateAbbreviations';

export type PlaceOptionMeta = {
  geoloc: {
    lat: number;
    lng: number;
  };
};

const getPlacesInstance = () => {
  const key = import.meta.env.VITE_MAPBOX_KEY;
  if (key) {
    return mbxGeocoder({ accessToken: key });
  }
  return null;
};

const convertGeocodeFeatureToLocationString = (feature: GeocodeFeature) => {
  let suggestionValue = '';
  if (feature.address) {
    suggestionValue += `${feature.address} `;
  }
  suggestionValue += feature.text;
  if (!feature.address) {
    const neighborhoodFeature = feature.context.find(
      parentFeature => parentFeature.id.split('.')[0] === 'neighborhood'
    );
    if (neighborhoodFeature) {
      suggestionValue += `, ${neighborhoodFeature.text}`;
    }
  }
  const placeFeature = feature.context.find(
    parentFeature => parentFeature.id.split('.')[0] === 'place'
  );
  if (placeFeature) {
    suggestionValue += `, ${placeFeature.text}`;
  }
  const regionFeature = feature.context.find(
    parentFeature => parentFeature.id.split('.')[0] === 'region'
  );
  const countryFeature = feature.context.find(
    parentFeature => parentFeature.id.split('.')[0] === 'country'
  );
  if (regionFeature) {
    if (countryFeature?.text === 'United States' && regionFeature.text in stateAbbreviations) {
      suggestionValue += `, ${stateAbbreviations[regionFeature.text]}`;
    } else {
      suggestionValue += `, ${regionFeature.text}`;
    }
  }
  if (countryFeature && countryFeature.text !== 'United States') {
    suggestionValue += `, ${countryFeature.text}`;
  }
  return suggestionValue;
};

const convertGeocodeFeaturesToOptions = (features: GeocodeFeature[]) => {
  const seenLocationStrings: string[] = [];
  const result = features.map(feature => {
    const locationString = convertGeocodeFeatureToLocationString(feature);
    if (!seenLocationStrings.includes(locationString)) {
      seenLocationStrings.push(locationString);
      const option: SearchableSelectOptionData = {
        id: feature.id,
        label: locationString,
        meta: {
          geoloc: {
            lat: feature.center[1],
            lng: feature.center[0]
          }
        },
        value: locationString
      };
      return option;
    }
    return undefined;
  });
  return result.filter(item => !!item) as SearchableSelectOptionData[];
};

type CreateQueryHandler = (types: GeocodeQueryType[]) => QueryHandler;

const createQueryHandler: CreateQueryHandler =
  (types: GeocodeQueryType[]) => async (query: string) => {
    const placesInstance = getPlacesInstance();
    if (placesInstance && query.length > 0) {
      return placesInstance
        .forwardGeocode({
          limit: 10,
          mode: 'mapbox.places',
          query,
          types
        })
        .send()
        .then(response => convertGeocodeFeaturesToOptions(response.body.features))
        .catch(error => {
          throw error;
        });
    }
    const options: SearchableSelectOptionData[] = [];
    return Promise.resolve(options);
  };

export const cityQueryHandler = createQueryHandler(['locality', 'neighborhood', 'place']);
export const placeQueryHandler = createQueryHandler([
  'address',
  'district',
  'locality',
  'neighborhood',
  'place',
  'region'
]);
