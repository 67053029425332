import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getTraitOptionSelections } from '../selectors';
import { JSONApi, State } from '../types';

const useTraitOptionSelections = (member: JSONApi.MemberResource) => {
  const apiData = useSelector((root: State.Root) => root.api);

  const [traitOptionSelections, setTraitOptionSelections] = useState<
    JSONApi.TraitOptionSelectionResource[]
  >([]);

  useEffect(() => {
    setTraitOptionSelections(getTraitOptionSelections(apiData, member));
  }, [apiData, member]);

  return traitOptionSelections;
};

export default useTraitOptionSelections;
