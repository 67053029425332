import { IonButton, IonButtons, IonItem, IonProgressBar } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import { alertSuccess } from '../actions/notificationActions';
import { WizardSteps } from '../components/CreateGroupPage';
import InvitesForm from '../components/forms/InvitesForm';
import MembersCanSendInvitesField from '../components/forms/MembersCanSendInvitesField';
import Heading from '../components/Heading';
import Paragraph from '../components/Paragraph';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { JSONApi } from '../types';

type Props = {
  group: JSONApi.GroupResource;
};

const SetupGroupSharing = ({ group }: Props) => {
  const dispatch = useThunkDispatch();
  const history = useHistory();

  const handleBackClick = useCallback(() => {
    history.push({ pathname: `/create/${group.attributes.slug}/${WizardSteps.PAYMENT}` });
  }, [group.attributes.slug, history]);

  const handleNextClick = useCallback(() => {
    history.push(`/g/${group.attributes.slug}`);
    dispatch(alertSuccess('pages.groupWizard.success'));
  }, [dispatch, group.attributes.slug, history]);

  return (
    <div className="setup-group-sharing-form">
      <IonProgressBar buffer={0.9} color="success" value={0} />
      <Heading level={2}>
        <FormattedMessage id="pages.groupWizard.share.title" />
      </Heading>
      <Paragraph>
        <FormattedMessage id="pages.groupWizard.share.description" />
      </Paragraph>
      <MembersCanSendInvitesField group={group} />
      <hr />
      <Heading level={3}>
        <FormattedMessage id="pages.groupWizard.share.h3Email" />
      </Heading>
      <InvitesForm group={group} />
      <hr />
      <IonItem color="transparent" lines="none">
        <IonButtons slot="end">
          <IonButton color="primary" fill="outline" onClick={handleBackClick} size="default">
            <FormattedMessage id="dictionary.previous" />
          </IonButton>
          <IonButton color="primary" fill="solid" onClick={handleNextClick} size="default">
            <FormattedMessage id="dictionary.next" />
          </IonButton>
        </IonButtons>
      </IonItem>
    </div>
  );
};

export default SetupGroupSharing;

/*
 * Original code from Chris for creating auto-approve links:

  <IonText color="primary">
    <h1 className="ion-padding">
      <FormattedMessage id="forms.groupCreate.share.title" />
    </h1>
  </IonText>
  <IonText color="primary">
    <h3 className="ion-padding">
      <FormattedMessage id="forms.groupCreate.share.emails" />
    </h3>
  </IonText>
  <FormikIonTextarea<FormValues>
    color="primary"
    name="description"
    placeholder="Enter comma-separated emails"
  />
  <IonText color="primary">
    <h3 className="ion-padding">
      <FormattedMessage id="forms.groupCreate.share.code" />
    </h3>
  </IonText>
  <IonRadioGroup>
    <IonItem>
      <IonLabel>
        <FormattedMessage id="forms.groupCreate.share.day" />
      </IonLabel>
      <IonRadio checked slot="start" value="1" />
    </IonItem>
    <IonItem>
      <IonLabel>
        <FormattedMessage id="forms.groupCreate.share.week" />
      </IonLabel>
      <IonRadio slot="start" value="7" />
    </IonItem>
    <IonItem>
      <IonLabel>
        <FormattedMessage id="forms.groupCreate.share.month" />
      </IonLabel>
      <IonRadio slot="start" value="30" />
    </IonItem>
    <IonItem>
      <IonLabel>
        <FormattedMessage id="forms.groupCreate.share.never" />
      </IonLabel>
      <IonRadio slot="start" value="never" />
    </IonItem>
  </IonRadioGroup>
  <div className="shareCode">{shareCode}</div>

  and the copy:
  groupCreate: {
    share: {
      code: 'Or, generate a special link to share',
      day: 'Expires in 24 hours',
      emails: 'Enter email addresses below and we will take care of the rest.',
      month: 'Expires in 30 days',
      never: 'Never expires',
      week: 'Expires in 7 days'
    }
  },
*/
