import { useSelector } from 'react-redux';

import { getGroupTraits } from '../selectors';
import { JSONApi, State } from '../types';

const useGroupTraits = (group: JSONApi.GroupResource) => {
  const apiData = useSelector((root: State.Root) => root.api);
  return getGroupTraits(apiData, group);
};

export default useGroupTraits;
