import { IonButton, IonIcon } from '@ionic/react';
import { at } from 'ionicons/icons';
import { MentionsRef } from 'rc-mentions/lib/Mentions';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

type Props = {
  fieldRef: React.RefObject<MentionsRef>;
  setFieldValue: (newValue: string) => void;
};

const MentionButton = ({ fieldRef, setFieldValue }: Props) => {
  const intl = useIntl();

  const handleClickMentions = useCallback(() => {
    if (fieldRef.current?.textarea) {
      const input = fieldRef.current.textarea;

      // make sure the field has focus
      input.focus();

      const start = input.selectionStart;
      let prefix = input.value.slice(0, start);
      // don't do anything if you are already positioned after an @ character
      if (!prefix.endsWith('@')) {
        const end = input.selectionEnd;
        let suffix = input.value.slice(end);
        if (prefix.length > 0 && !prefix.endsWith(' ')) {
          prefix = `${prefix} `;
        }
        if (suffix.length > 0 && !suffix.startsWith(' ')) {
          suffix = ` ${suffix}`;
        }
        const newValue = `${prefix}@${suffix}`;
        setFieldValue(newValue);
        const newCaretPosition = prefix.length + 1;
        // for some annoying reason this needs to happen asynchronously
        setTimeout(() => {
          input.setSelectionRange(newCaretPosition, newCaretPosition);
          if (fieldRef.current) {
            /*
             * At this moment, we need to tell the Mentions component to search for
             * and display autocomplete options. It seems the only way to do that is
             * to call the `onKeyUp` function on its ref.
             * Unfortunately, that function requires an argument of type
             * React.KeyboardEvent<HTMLTextAreaElement>. It seems like there should
             * be a better way to construct one of those than what we're doing below,
             * but there does not appear to be any documentation on how to do it. The
             * faked out data here roughly corresponds to hitting the @ key, but
             * it doesn't appear that the data here matters at all.
             */
            // const fakeEvent: React.KeyboardEvent<HTMLTextAreaElement> = {
            //   altKey: false,
            //   bubbles: false,
            //   cancelable: false,
            //   charCode: 0,
            //   code: '',
            //   ctrlKey: false,
            //   currentTarget: input,
            //   defaultPrevented: false,
            //   detail: 0,
            //   eventPhase: 3,
            //   getModifierState: () => false,
            //   isDefaultPrevented: () => false,
            //   isPropagationStopped: () => false,
            //   isTrusted: true,
            //   key: '@',
            //   keyCode: 50,
            //   locale: 'en-US',
            //   location: 0,
            //   metaKey: false,
            //   nativeEvent: new KeyboardEvent('keyUp', { key: 'At' }),
            //   persist: () => {
            //     // no-op
            //   },
            //   preventDefault: () => {
            //     // no-op
            //   },
            //   repeat: false,
            //   shiftKey: true,
            //   stopPropagation: () => {
            //     // no-op
            //   },
            //   target: input,
            //   timeStamp: 0,
            //   type: '',
            //   view: {
            //     document: document,
            //     styleMedia: {
            //       matchMedium: () => true,
            //       type: 'example'
            //     }
            //   },
            //   which: 50
            // };
            // fieldRef.current.onKeyUp(fakeEvent);
          }
        }, 0);
      }
    }
  }, [fieldRef, setFieldValue]);

  return (
    <IonButton
      aria-label={intl.formatMessage({ id: 'dictionary.mentionSomeone' })}
      color="primary"
      fill="clear"
      onClick={handleClickMentions}
      size="small"
    >
      <IonIcon icon={at} slot="icon-only" />
    </IonButton>
  );
};

export default MentionButton;
