import { IonButton } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import FormModal, { FormModalProps } from '../components/FormModal';
import Paragraph from '../components/Paragraph';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { dispatchAndAlert } from '../services/dispatchUtils';
import { Actions, Models } from '../types';

const ConfirmDeleteModal = ({
  action,
  buttonKey = 'dictionary.doIt',
  descriptionKey,
  errorAlertKey,
  h2Key = 'dictionary.areYouSure',
  onSuccess,
  successAlertKey,
  ...formModalProps
}: Omit<FormModalProps, 'children' | 'keyboardStatus'> & {
  action: Actions.ApiThunkAction<Models.Base>;
  buttonKey?: string;
  descriptionKey: string;
  errorAlertKey: string;
  h2Key?: string;
  onSuccess?: () => void;
  successAlertKey: string;
}) => {
  const dispatch = useThunkDispatch();

  const handleClick = useCallback(() => {
    dispatchAndAlert(dispatch, action, successAlertKey, errorAlertKey, onSuccess);
  }, [action, dispatch, errorAlertKey, onSuccess, successAlertKey]);

  return (
    <FormModal {...formModalProps}>
      {() => (
        <>
          <h2>
            <FormattedMessage id={h2Key} />
          </h2>
          <Paragraph>
            <FormattedMessage id={descriptionKey} />
          </Paragraph>
          <IonButton color="danger" expand="block" onClick={handleClick}>
            <FormattedMessage id={buttonKey} />
          </IonButton>
        </>
      )}
    </FormModal>
  );
};

export default ConfirmDeleteModal;
