import React from 'react';
import { FormattedMessage } from 'react-intl';

import FormModal from '../components/FormModal';
import InvitesForm from '../components/forms/InvitesForm';
import { Opener } from '../hooks/useOpener';
import { JSONApi } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  opener: Opener;
};

const InvitesModal = ({ group, opener }: Props) => (
  <FormModal keyboardClose={false} opener={opener} titleKey="modals.createInvites.title">
    {modalContentRef => (
      <>
        <h2>
          <FormattedMessage
            id="modals.createInvites.h2"
            values={{ group: group.attributes.name }}
          />
        </h2>
        <InvitesForm
          autofocus={opener.isPresented}
          contentForwardRef={modalContentRef}
          group={group}
          onSuccess={opener.close}
        />
      </>
    )}
  </FormModal>
);

export default InvitesModal;
