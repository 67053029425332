import { IonButton, IonButtons, IonItem, IonLabel } from '@ionic/react';
import { FormApi } from 'final-form';
import React, { useCallback } from 'react';
import { FormSpy } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { apiClearCachedResponsesForEndpoint } from '../../actions/apiActions';
import DeletePostModal from '../../components/DeletePostModal';
import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormInput from '../../components/forms/FormInput';
import FormMentionsTextarea from '../../components/forms/FormMentionsTextarea';
import FormSubmit from '../../components/forms/FormSubmit';
import ImagesField from '../../components/forms/ImagesField';
import RequiredFieldMarker from '../../components/forms/RequiredFieldMarker';
import SubmitError from '../../components/forms/SubmitError';
import { maxTextInputLength, maxTitleLength } from '../../globals';
import useDismissModalAndGoTo from '../../hooks/useDismissModalAndGoTo';
import useMountedTracking from '../../hooks/useMountedTracking';
import useOpener, { Opener } from '../../hooks/useOpener';
import useRelationshipsFieldManager from '../../hooks/useRelationshipsFieldManager';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { getPostImages } from '../../selectors';
import { forceArray } from '../../services/arrayUtils';
import { handleFormSubmission } from '../../services/formUtils';
import { createDiscussion, deleteImage, updateDiscussion } from '../../thunks/apiThunks';
import { ApiEndpoint, JSONApi, Models, State } from '../../types';

import './CustomForm.scss';

type FormValues = Models.DiscussionFormData;

type Props = {
  autofocus: boolean;
  contentForwardRef: React.RefObject<HTMLIonContentElement>;
  discussion?: JSONApi.DiscussionResource;
  group: JSONApi.GroupResource;
  onSuccess?: () => void;
  opener: Opener;
};

const DiscussionForm = ({
  autofocus,
  contentForwardRef,
  discussion,
  group,
  onSuccess,
  opener
}: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const deleteModalOpener = useOpener();
  const dismissModalAndGoTo = useDismissModalAndGoTo(opener);
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  let images: JSONApi.ImageResource[] = [];
  if (discussion) {
    images = getPostImages(apiData, discussion);
  }
  const imagesFieldManager = useRelationshipsFieldManager(images, deleteImage);

  const initialValues: FormValues = {
    description: discussion ? discussion.attributes.description : '',
    link: discussion ? discussion.attributes.link : '',
    title: discussion ? discussion.attributes.title : ''
  };

  const handleSubmit = useCallback(
    async (values: FormValues, form: FormApi<FormValues>) => {
      const action = discussion
        ? updateDiscussion(
            group.attributes.slug,
            { ...discussion.attributes, ...values },
            imagesFieldManager.relationships
          )
        : createDiscussion(group.attributes.slug, values, imagesFieldManager.relationships);
      return handleFormSubmission<FormValues, Models.Discussion>({
        action,
        contentForwardRef,
        dispatch,
        errorTranslationLocation: 'discussion',
        form,
        intl,
        isMounted,
        onSuccess: (response: JSONApi.Response<Models.Discussion>) => {
          dispatch(apiClearCachedResponsesForEndpoint(ApiEndpoint.DISCUSSION_SEARCH));
          const newDiscussion = forceArray(response.data)[0];
          if (onSuccess) {
            onSuccess();
          }
          dismissModalAndGoTo(`/g/${group.attributes.slug}/forum/${newDiscussion.attributes.slug}`);
        },
        successTKey: `forms.discussion.${discussion ? 'update' : 'create'}.success`,
        values
      });
    },
    [
      contentForwardRef,
      discussion,
      dismissModalAndGoTo,
      dispatch,
      group.attributes.slug,
      imagesFieldManager.relationships,
      intl,
      isMounted,
      onSuccess
    ]
  );

  const validationSchema = Yup.object().shape({
    description: Yup.string()
      .nullable()
      .max(
        maxTextInputLength,
        intl.formatMessage(
          { id: 'errors.discussion.description.tooLong' },
          { count: maxTextInputLength }
        )
      ),
    link: Yup.string()
      .nullable()
      .max(
        maxTextInputLength,
        intl.formatMessage({ id: 'errors.discussion.link.tooLong' }, { count: maxTextInputLength })
      ),
    title: Yup.string()
      .required(intl.formatMessage({ id: 'errors.discussion.title.blank' }))
      .max(
        maxTitleLength,
        intl.formatMessage({ id: 'errors.discussion.title.tooLong' }, { count: maxTitleLength })
      )
  });

  return (
    <FormContainer<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ form, handleSubmit }) => (
        <div className="custom-form-wrapper discussion-form">
          <Form<FormValues> onFormSubmit={handleSubmit} submit={form.submit}>
            <SubmitError />
            <IonLabel>
              <FormattedMessage id="models.discussion.attributes.title.label" />
              <RequiredFieldMarker />
            </IonLabel>
            <FormError name="title" />
            <FormInput
              autocapitalize="on"
              autocorrect="on"
              autofocus={autofocus}
              name="title"
              placeholder={intl.formatMessage({
                id: 'models.discussion.attributes.title.placeholder'
              })}
            />
            <IonLabel>
              <FormattedMessage id="models.discussion.attributes.description.label" />
            </IonLabel>
            <FormError name="description" />
            <FormMentionsTextarea
              name="description"
              placeholder={intl.formatMessage({
                id: 'models.discussion.attributes.description.placeholder'
              })}
              rows={6}
            />
            <IonLabel>
              <FormattedMessage id="models.discussion.attributes.link.label" />
            </IonLabel>
            <FormError name="link" />
            <FormInput
              name="link"
              placeholder={intl.formatMessage({
                id: 'models.discussion.attributes.link.placeholder'
              })}
            />
            <FormSpy<FormValues> subscription={{ values: true }}>
              {() => <ImagesField fieldManager={imagesFieldManager} />}
            </FormSpy>
            <IonItem color="transparent" lines="none">
              <IonButtons slot="end">
                <FormSubmit allowPristineSubmit fill="solid">
                  <FormattedMessage id={discussion ? 'dictionary.update' : 'dictionary.post'} />
                </FormSubmit>
                {discussion && (
                  <>
                    <IonButton color="danger" fill="clear" onClick={deleteModalOpener.toggle}>
                      <FormattedMessage id="dictionary.delete" />
                    </IonButton>
                    <DeletePostModal group={group} opener={deleteModalOpener} post={discussion} />
                  </>
                )}
              </IonButtons>
            </IonItem>
          </Form>
        </div>
      )}
    </FormContainer>
  );
};

export default DiscussionForm;
