import { IonButton, IonButtons, IonIcon, IonItem } from '@ionic/react';
import { FormApi } from 'final-form';
import { send } from 'ionicons/icons';
import React, { useCallback } from 'react';
import { FormSpy } from 'react-final-form';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import AddAttachment from '../../components/forms/AddAttachment';
import AttachmentFieldItems from '../../components/forms/AttachmentFieldItems';
import ErrorMessage from '../../components/forms/ErrorMessage';
import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormSubmit from '../../components/forms/FormSubmit';
import FormTextarea from '../../components/forms/FormTextarea';
import { maxTextInputLength } from '../../globals';
import useAttachmentsFieldManager from '../../hooks/useAttachmentsFieldManager';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { handleFormSubmission } from '../../services/formUtils';
import { createNote } from '../../thunks/apiThunks';
import { JSONApi, Models } from '../../types';

import './AddToThreadForm.scss';

type FormValues = Models.CreateNote;

type Props = {
  conversation: JSONApi.ConversationResource;
  counterparty: JSONApi.MemberResource | null | undefined;
  group: JSONApi.GroupResource;
  onFocus: () => void;
};

const NoteForm = ({ conversation, counterparty, group, onFocus }: Props) => {
  const attachmentsFieldManager = useAttachmentsFieldManager();
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const placeholder = counterparty
    ? intl.formatMessage(
        { id: 'models.note.attributes.note.placeholder.withCounterparty' },
        { name: counterparty.attributes.firstName }
      )
    : intl.formatMessage({ id: 'models.note.attributes.note.placeholder.withoutCounterparty' });

  const initialValues: FormValues = {
    note: ''
  };

  const handleSubmit = useCallback(
    async (values: FormValues, form: FormApi<FormValues>) =>
      handleFormSubmission({
        action: createNote(
          group.attributes.slug,
          conversation,
          values,
          attachmentsFieldManager.attachments
        ),
        dispatch,
        errorTranslationLocation: 'note',
        form,
        intl,
        isMounted,
        onSuccess: () => {
          onFocus();
          attachmentsFieldManager.reset();
        },
        successTKey: 'forms.note.create.success',
        values
      }),
    [
      attachmentsFieldManager,
      conversation,
      dispatch,
      group.attributes.slug,
      intl,
      isMounted,
      onFocus
    ]
  );

  const validationSchema = Yup.object().shape({
    note: Yup.string()
      .required(intl.formatMessage({ id: 'errors.note.note.blank' }))
      .max(
        maxTextInputLength,
        intl.formatMessage({ id: 'errors.note.note.tooLong' }, { count: maxTextInputLength })
      )
  });

  return (
    <FormContainer<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ form, handleSubmit }) => (
        <Form<FormValues>
          className="add-to-thread-form note-form"
          onFormSubmit={handleSubmit}
          submit={form.submit}
        >
          <FormSpy subscription={{ submitError: true }}>
            {({ submitError }) => {
              if (submitError) {
                return (
                  <IonItem className="compact" color="transparent" lines="none">
                    <ErrorMessage>{submitError}</ErrorMessage>
                  </IonItem>
                );
              }
              return null;
            }}
          </FormSpy>
          <AttachmentFieldItems attachmentsFieldManager={attachmentsFieldManager} />
          <div className="input-wrapper">
            <IonItem className="compact" color="transparent" lines="none">
              <FormTextarea
                aria-label={intl.formatMessage({ id: 'models.note.attributes.note.label' })}
                className="ion-no-margin"
                name="note"
                onFocus={onFocus}
                placeholder={placeholder}
                rows={1}
              />
              <IonButtons className="ion-no-margin" slot="end">
                <IonButton
                  aria-label={intl.formatMessage({ id: 'models.attachment.add' })}
                  color="primary"
                  fill="clear"
                  size="small"
                >
                  <AddAttachment attachmentsFieldManager={attachmentsFieldManager} />
                </IonButton>
                <FormSubmit
                  aria-label={intl.formatMessage({ id: 'forms.note.submitButton' })}
                  color="primary"
                  disabled={attachmentsFieldManager.isUploading}
                  fill="clear"
                  size="small"
                  slot="end"
                >
                  <IonIcon icon={send} slot="icon-only" />
                </FormSubmit>
              </IonButtons>
            </IonItem>
          </div>
        </Form>
      )}
    </FormContainer>
  );
};

export default NoteForm;
