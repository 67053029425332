import React, { useState } from 'react';

import EnterEmailForm from '../components/forms/EnterEmailForm';
import EnterPasswordForm from '../components/forms/EnterPasswordForm';
import ForgotPasswordForm from '../components/forms/ForgotPasswordForm';
import useOpener from '../hooks/useOpener';
import { JSONApi, State } from '../types';

type Props = {
  group: JSONApi.GroupResource | null | undefined;
  handleLoginSuccess: (user: JSONApi.UserResource, newApiData: State.Api) => void;
};

const UserAccountWizard = ({ group, handleLoginSuccess }: Props) => {
  const [currentEmail, setCurrentEmail] = useState<string | undefined>(undefined);
  const forgotPasswordOpener = useOpener();

  if (currentEmail) {
    if (forgotPasswordOpener.isOpen) {
      return (
        <ForgotPasswordForm
          email={currentEmail}
          goBack={forgotPasswordOpener.close}
          group={group}
        />
      );
    }
    return (
      <EnterPasswordForm
        email={currentEmail}
        handleLoginSuccess={handleLoginSuccess}
        openForgotPassword={forgotPasswordOpener.open}
        setCurrentEmail={setCurrentEmail}
      />
    );
  }
  return <EnterEmailForm group={group} setCurrentEmail={setCurrentEmail} />;
};

export default UserAccountWizard;
