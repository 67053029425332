import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { DateTime } from 'luxon';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import NewsItemCard from '../components/NewsItemCard';
import NewsItemFormModal from '../components/NewsItemFormModal';
import NewsItemSearchForm from '../components/NewsItemSearchForm';
import NoPostResults from '../components/NoPostResults';
import PostsPage from '../components/PostsPage';
import SearchResults from '../components/SearchResults';
import useNewsPostingPermission from '../hooks/useNewsPostingPermission';
import useOpener from '../hooks/useOpener';
import usePageVisitTracker from '../hooks/usePageVisitTracker';
import { loadMoreNewsItemSearchResults, loadNewsItemSearch } from '../thunks/apiThunks';
import { ApiEndpoint, JSONApi, Models, Search, State } from '../types';

type Props = {
  currentUser: JSONApi.UserResource;
  group: JSONApi.GroupResource;
};

const NewsPage = ({ currentUser, group }: Props) => {
  const newsItemSearchParams = useSelector((root: State.Root) => root.search.newsItemParams);
  const allowCreation = useNewsPostingPermission(currentUser, group);
  const modalOpener = useOpener();
  const [newsUpdatedAt, setNewsUpdatedAt] = useState(DateTime.local());
  usePageVisitTracker(group, Models.Readable.NEWS_ITEM);

  const anySearchesApplied = Object.values(newsItemSearchParams).filter(val => !!val).length > 0;

  const renderSearchForm = useCallback(
    (isOpen: boolean) => (
      <div className="subheader">
        <NewsItemSearchForm isOpen={isOpen} />
      </div>
    ),
    []
  );

  const handleNewsItemAdded = useCallback(() => {
    setTimeout(() => {
      setNewsUpdatedAt(DateTime.local());
    }, 1000);
  }, []);

  return (
    <PostsPage
      group={group}
      onCreateClick={allowCreation ? modalOpener.toggle : undefined}
      renderSearchForm={renderSearchForm}
      titleKey="pages.news.title"
    >
      {pageContentRef => (
        <>
          <NewsItemFormModal
            group={group}
            onSuccess={handleNewsItemAdded}
            opener={modalOpener}
            titleKey="modals.newNewsItem.title"
          />
          <SearchResults<Models.PostSearch, Models.NewsItem, Search.PostParams>
            contentRef={pageContentRef}
            group={group}
            loadDataActionCreator={loadNewsItemSearch}
            loadMoreActionCreator={loadMoreNewsItemSearchResults}
            resultsUpdatedAt={newsUpdatedAt}
            searchEndpoint={ApiEndpoint.NEWS_ITEM_SEARCH}
            searchParams={newsItemSearchParams}
            showCount={anySearchesApplied}
          >
            {results => (
              <>
                {results.length === 0 && (
                  <NoPostResults
                    onClick={allowCreation ? modalOpener.toggle : undefined}
                    tKey="pages.news.noResults"
                  />
                )}
                {results.length > 0 && (
                  <IonGrid className="cards">
                    <IonRow>
                      {results.map(newsItem => (
                        <IonCol key={newsItem.id} size="12" sizeLg="4" sizeXl="4">
                          <NewsItemCard group={group} newsItem={newsItem} />
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonGrid>
                )}
              </>
            )}
          </SearchResults>
        </>
      )}
    </PostsPage>
  );
};

export default NewsPage;
