import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import useThunkDispatch from '../hooks/useThunkDispatch';
import { getPostViewerRsvp } from '../selectors';
import { dispatchAndAlert } from '../services/dispatchUtils';
import { createRsvp, deleteRsvp } from '../thunks/apiThunks';
import { JSONApi, State } from '../types';

const useRsvpHandler = (
  event: JSONApi.EventResource,
  group: JSONApi.GroupResource,
  onSuccess?: () => void
) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const dispatch = useThunkDispatch();

  const rsvp = getPostViewerRsvp(apiData, event);

  return useCallback(() => {
    if (rsvp) {
      dispatchAndAlert(
        dispatch,
        deleteRsvp(group.attributes.slug, rsvp),
        'forms.rsvp.delete.success',
        'forms.rsvp.delete.error',
        onSuccess
      );
    } else {
      dispatchAndAlert(
        dispatch,
        createRsvp(group.attributes.slug, event),
        'forms.rsvp.create.success',
        'forms.rsvp.create.error',
        onSuccess
      );
    }
  }, [dispatch, event, group.attributes.slug, onSuccess, rsvp]);
};

export default useRsvpHandler;
