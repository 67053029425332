import { IonLabel } from '@ionic/react';
import { FormApi } from 'final-form';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import EditClosedMemberProfilePage from '../../components/EditClosedMemberProfilePage';
import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormInput from '../../components/forms/FormInput';
import FormTextarea from '../../components/forms/FormTextarea';
import LinksForm from '../../components/forms/LinksForm';
import SubmitError from '../../components/forms/SubmitError';
import TraitOptionSelectionField from '../../components/forms/TraitOptionSelectionField';
import { maxTextInputLength, maxTitleLength } from '../../globals';
import useGroupTraits from '../../hooks/useGroupTraits';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import useTraitOptionSelections from '../../hooks/useTraitOptionSelections';
import { handleFormSubmission } from '../../services/formUtils';
import {
  convertApiTraitDataToFormSelections,
  convertFormSelectionsToApiTraitData,
  traitOptionSelectionsYupSchema
} from '../../services/traitUtils';
import { updateMember } from '../../thunks/apiThunks';
import { JSONApi, ModelAttributes, Models } from '../../types';

import './CustomForm.scss';

type FormValues = Models.MemberFormData;

const EditMemberProfileForm = ({
  children,
  group,
  member,
  onSuccess
}: React.PropsWithChildren<{
  group: JSONApi.GroupResource;
  member: JSONApi.MemberResource;
  onSuccess?: () => void;
}>) => {
  const dispatch = useThunkDispatch();
  const groupTraits = useGroupTraits(group);
  const intl = useIntl();
  const isMounted = useMountedTracking();
  const traitOptionSelections = useTraitOptionSelections(member);

  const initialValues: FormValues = {
    bio: member.attributes.bio,
    headline: member.attributes.headline,
    traitOptionSelections: convertApiTraitDataToFormSelections(groupTraits, traitOptionSelections)
  };

  const handleSubmit = useCallback(
    async (values: FormValues, form: FormApi<FormValues>) => {
      const { included, relationships } = convertFormSelectionsToApiTraitData(
        values,
        traitOptionSelections
      );
      const { traitOptionSelections: selections, ...apiValues } = values;
      return handleFormSubmission({
        action: updateMember(group.attributes.slug, apiValues, member.id, relationships, included),
        dispatch,
        errorTranslationLocation: 'editMember',
        form,
        intl,
        isMounted,
        onSuccess,
        successTKey: 'forms.editMember.update.success',
        values
      });
    },
    [dispatch, group.attributes.slug, intl, isMounted, member.id, onSuccess, traitOptionSelections]
  );

  const validationSchema = Yup.object().shape({
    bio: Yup.string()
      .nullable()
      .max(
        maxTextInputLength,
        intl.formatMessage({ id: 'errors.member.bio.tooLong' }, { count: maxTextInputLength })
      ),
    headline: Yup.string()
      .nullable()
      .max(
        maxTitleLength,
        intl.formatMessage({ id: 'errors.member.headline.tooLong' }, { count: maxTitleLength })
      ),
    traitOptionSelections: traitOptionSelectionsYupSchema(
      groupTraits,
      intl.formatMessage({
        id: 'errors.member.relationships.traitOptionSelections.blank'
      })
    )
  });

  if (member.attributes.status === ModelAttributes.MemberStatus.SELF_CLOSED) {
    return <EditClosedMemberProfilePage group={group} member={member} />;
  }

  return (
    <FormContainer<FormValues>
      initialValues={initialValues}
      // the LinksForm reinitializes this form when you add a new link, but chances are you've
      // made some edits and have dirty values that you haven't saved when you add a link
      // this lets you keep them around
      keepDirtyOnReinitialize
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ form, handleSubmit }) => (
        <Form<FormValues>
          className="member-profile-form"
          onFormSubmit={handleSubmit}
          submit={form.submit}
        >
          <SubmitError />
          <div className="ion-padding-end ion-padding-start">
            <IonLabel color="primary">
              <FormattedMessage id="models.member.attributes.headline.label" />
            </IonLabel>
            <FormError name="headline" />
            <FormInput
              autocapitalize="on"
              autocorrect="on"
              autofocus
              name="headline"
              placeholder={intl.formatMessage({
                id: 'models.member.attributes.headline.placeholder'
              })}
              type="text"
            />
            <IonLabel color="primary">
              <FormattedMessage id="models.member.attributes.bio.label" />
            </IonLabel>
            <FormError name="bio" />
            <FormTextarea
              name="bio"
              placeholder={intl.formatMessage({ id: 'models.member.attributes.bio.placeholder' })}
              rows={3}
            />
            <TraitOptionSelectionField groupTraits={groupTraits} />
          </div>
          <LinksForm group={group} owner={member} />
          <div className="ion-padding">{children}</div>
        </Form>
      )}
    </FormContainer>
  );
};

export default EditMemberProfileForm;
