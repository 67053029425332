import { IonText } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import ChatBubble from '../components/ChatBubble';
import ChatBubbleActionSheet from '../components/ChatBubbleActionSheet';
import SimpleChatBubble from '../components/SimpleChatBubble';
import useOpener from '../hooks/useOpener';
import { getNoteAttachments, getNoteCreator, getRecordBlock } from '../selectors';
import { JSONApi, State } from '../types';

type Props = {
  counterparty: JSONApi.MemberResource | null | undefined;
  group: JSONApi.GroupResource;
  note: JSONApi.NoteResource;
};

const NoteBubble = ({ counterparty, group, note }: Props) => {
  const actionSheetOpener = useOpener();
  const apiData = useSelector((root: State.Root) => root.api);

  const attachments = getNoteAttachments(apiData, note);
  const block = getRecordBlock(apiData, note);
  const creator = getNoteCreator(apiData, note);

  if (block) {
    return (
      <SimpleChatBubble color="danger">
        <FormattedMessage id="models.note.attributes.status.blockedMessage" />
      </SimpleChatBubble>
    );
  }

  const isActionable = note.attributes.permissions.block;

  return (
    <>
      {isActionable && (
        <ChatBubbleActionSheet
          actionSheetOpener={actionSheetOpener}
          block={note.attributes.permissions.block}
          flag={false}
          group={group}
          record={note}
        />
      )}
      <ChatBubble
        actionSheetOpener={actionSheetOpener}
        attachments={attachments}
        color={creator === counterparty ? 'light' : 'dark'}
        group={group}
        isActionable={isActionable}
        member={getNoteCreator(apiData, note)}
        timestamp={note.attributes.createdAt}
      >
        <IonText className="formatted-text">{note.attributes.note}</IonText>
      </ChatBubble>
    </>
  );
};

export default NoteBubble;
