import React, { useEffect } from 'react';
import { Redirect } from 'react-router';

import { alertSuccess } from '../actions/notificationActions';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { logout } from '../thunks/sessionThunks';

const Logout = () => {
  const dispatch = useThunkDispatch();

  useEffect(() => {
    // todo: logout should return a promise so we can conditionally dispatch the alert
    dispatch(logout());
    dispatch(alertSuccess('forms.logout.success'));
  }, [dispatch]);

  return <Redirect to="/" />;
};

export default Logout;
