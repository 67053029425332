import { loadStripe, Stripe } from '@stripe/stripe-js';

import { forceArray } from '../services/arrayUtils';
import { JSONApi, Models } from '../types';

type ThingThatCanPush = {
  push: (path: string) => void;
};

export const handleCheckoutSessionResponse = (
  response: JSONApi.Response<Models.CheckoutSession>,
  history: ThingThatCanPush,
  successRedirect: string
) => {
  const checkoutSession = forceArray(response.data)[0];

  const stripeKey = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;

  if (stripeKey && checkoutSession) {
    if (checkoutSession.attributes.sessionId) {
      loadStripe(stripeKey).then((stripe: Stripe | null) => {
        if (stripe && checkoutSession.attributes.sessionId) {
          stripe.redirectToCheckout({ sessionId: checkoutSession.attributes.sessionId });
        }
      });
    } else if (checkoutSession.attributes.skipCheckout) {
      history.push(successRedirect);
    }
  }
};
