import { isDiscussion, isEvent, isJobPost, isNewsItem } from '../services/typeDetection';
import { apiPath } from '../services/urlUtils';
import { JSONApi, ModelAttributes } from '../types';

export const getPostBody = (post: JSONApi.PostResource) => {
  if (isDiscussion(post)) {
    return post.attributes.description;
  }
  if (isEvent(post)) {
    return post.attributes.description;
  }
  if (isJobPost(post)) {
    return post.attributes.description;
  }
  if (isNewsItem(post)) {
    return post.attributes.body;
  }
  throw new Error(`Post type is invalid ${post.type} ${post.attributes.slug}`);
};

export const getPostIcon = (post: JSONApi.PostResource) => {
  switch (post.type) {
    case ModelAttributes.PostType.DISCUSSION:
      return 'custom-feed';
    case ModelAttributes.PostType.EVENT:
      return 'custom-calendar';
    case ModelAttributes.PostType.JOB_POST:
      return 'custom-briefcase';
    case ModelAttributes.PostType.NEWS_ITEM:
      return 'custom-news';
    default:
      // this basically won't happen since we handle all the possible cases
      throw new Error(`Post type is invalid ${post.type} ${post.attributes.slug}`);
  }
};

export const getPostApiPath = (groupSlug: string, id: string, type: ModelAttributes.PostType) => {
  switch (type) {
    case ModelAttributes.PostType.DISCUSSION:
      return apiPath(groupSlug, `discussions/${id}`);
    case ModelAttributes.PostType.EVENT:
      return apiPath(groupSlug, `events/${id}`);
    case ModelAttributes.PostType.JOB_POST:
      return apiPath(groupSlug, `jobs/${id}`);
    case ModelAttributes.PostType.NEWS_ITEM:
      return apiPath(groupSlug, `news_items/${id}`);
    default:
      // this basically won't happen since we handle all the possible cases
      throw new Error(`Post type is invalid`);
  }
};

export const getPostPath = (post: JSONApi.PostResource, group: JSONApi.GroupResource) => {
  if (post) {
    switch (post.type) {
      case ModelAttributes.PostType.DISCUSSION:
        return `/g/${group.attributes.slug}/forum/${post.attributes.slug}`;
      case ModelAttributes.PostType.EVENT:
        return `/g/${group.attributes.slug}/events/${post.attributes.slug}`;
      case ModelAttributes.PostType.JOB_POST:
        return `/g/${group.attributes.slug}/job-board/${post.attributes.slug}`;
      case ModelAttributes.PostType.NEWS_ITEM:
        return `/g/${group.attributes.slug}/news/${post.attributes.slug}`;
      default:
        // this basically won't happen since we handle all the possible cases
        throw new Error(`Post type is invalid ${post.type} ${post.attributes.slug}`);
    }
  }
  return `/g/${group.attributes.slug}`;
};

export const isAuthorModerator = (author: JSONApi.MemberResource) =>
  [ModelAttributes.MemberRole.MODERATOR, ModelAttributes.MemberRole.OWNER].includes(
    author.attributes.role
  );
