/*
 * this component overwrites IonToggle with some default behavior we always need:
 * 1) ensures the enter key toggles the value rather than submits the form
 * 2) set default values for certain props that make sense in most cases for toggles
 */

import { IonToggle } from '@ionic/react';
import React from 'react';

import useStopEnterKeyPropagation from '../../hooks/useStopEnterKeyPropagation';

const Toggle = ({
  onKeyDown,
  ...props
}: React.ComponentProps<typeof IonToggle> & {
  onKeyDown?: React.KeyboardEventHandler<HTMLIonToggleElement>;
}) => {
  const handleKeyDown = useStopEnterKeyPropagation<HTMLIonToggleElement>(onKeyDown);

  // eslint-disable-next-line react/forbid-elements
  return <IonToggle color="primary" {...props} onKeyDown={handleKeyDown} />;
};

export default Toggle;
