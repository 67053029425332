import { IonLabel, IonSelect, IonSelectOption, SelectChangeEventDetail } from '@ionic/react';
import equal from 'deep-equal';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import useGroupTraits from '../hooks/useGroupTraits';
import { getTraitOptions } from '../selectors';
import { JSONApi, Search, State } from '../types';

type ExtendedSearchParams<T extends Search.Params> = T & {
  traitOptionIds: string;
};

type Props<T extends Search.Params> = {
  facetValues?: Search.FacetValue[] | undefined;
  group: JSONApi.GroupResource;
  searchParams: ExtendedSearchParams<T>;
  setSearchParams: (value: ExtendedSearchParams<T>) => void;
};

function SearchTraits<T extends Search.Params>({
  facetValues,
  group,
  searchParams,
  setSearchParams
}: Props<T>) {
  const apiData = useSelector((root: State.Root) => root.api);
  const groupTraits = useGroupTraits(group);
  const allSelectedOptions = searchParams.traitOptionIds.split(',');

  const generateTraitOptionSelectionsChangeHandler = useCallback(
    (trait: JSONApi.TraitResource) => (e: CustomEvent<SelectChangeEventDetail>) => {
      // Keep the present selections for options in any trait that's not this one, then
      // inject the selections we just made. They are in arbitrary order so we need to
      // sort them lexically or the selections can flop around infinitely.
      const optionIdsForThisTrait = getTraitOptions(apiData, trait).map(item => item.id);
      const otherTraitOptionIds = allSelectedOptions.filter(
        id => !optionIdsForThisTrait.includes(id)
      );
      const traitOptionIds = otherTraitOptionIds
        .concat(e.detail.value)
        // eslint-disable-next-line no-confusing-arrow
        .sort((a, b) => (a > b ? 1 : -1))
        .filter(x => x.length > 0)
        .join(',');
      const newParams = { ...searchParams, traitOptionIds };
      if (!equal(searchParams, newParams)) {
        setSearchParams(newParams);
      }
    },
    [allSelectedOptions, apiData, searchParams, setSearchParams]
  );

  /* eslint-disable react/jsx-no-useless-fragment */
  return (
    <>
      {groupTraits.map(trait => {
        const options = getTraitOptions(apiData, trait);
        const selectedIds = options
          .map(item => item.id)
          .filter(item => allSelectedOptions.includes(item));
        const handleIonChange = generateTraitOptionSelectionsChangeHandler(trait);
        return (
          <div key={trait.id}>
            <IonLabel>{trait.attributes.title}</IonLabel>
            <IonSelect multiple onIonChange={handleIonChange} value={selectedIds}>
              {options.map(option => {
                const facet = facetValues?.find(item => item.key === option.id);
                return (
                  <IonSelectOption key={option.id} value={option.id}>
                    {option.attributes.title}
                    {facet && ` (${facet.count.toString()})`}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </div>
        );
      })}
    </>
  );
  /* eslint-enable react/jsx-no-useless-fragment */
}

export default SearchTraits;
