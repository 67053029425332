import { IonButton, IonButtons, IonCol, IonGrid, IonItem, IonList, IonRow } from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import AdminAutoModerationResult from '../components/AdminAutoModerationResult';
import ImgOriented from '../components/ImgOriented';
import InfiniteScroll from '../components/InfiniteScroll';
import AdminPage from '../components/layout/AdminPage';
import LocalLoadingIndicator from '../components/LocalLoadingIndicator';
import useApiData from '../hooks/useApiData';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { getAllImages, getImageAutoModerationResult } from '../selectors';
import { dispatchAndAlert } from '../services/dispatchUtils';
import { adminApproveImage, adminLoadImages, adminLoadMoreImages } from '../thunks/apiThunks';
import { JSONApi, Models, State } from '../types';

import './AdminImagesPage.scss';
import './Img.scss';

const AdminImagesPage = () => {
  const apiData = useSelector((root: State.Root) => root.api);
  const dispatch = useThunkDispatch();
  const [images, setImages] = useState<JSONApi.Resource<Models.Image>[]>([]);

  const findImages = useCallback(
    () => getAllImages(apiData).filter(image => !image.attributes.adminApproved),
    [apiData]
  );

  const { loading, responseData } = useApiData<Models.Image>(
    adminLoadImages(),
    findImages,
    'AdminImagesPage'
  );

  const generateHandleApproveClick = useCallback(
    (image: JSONApi.ImageResource) => () => {
      dispatchAndAlert(
        dispatch,
        adminApproveImage(image),
        'pages.admin.images.approve.success',
        'pages.admin.images.approve.failure'
      );
    },
    [dispatch]
  );

  useEffect(() => {
    setImages(responseData);
  }, [responseData, setImages]);

  if (loading) {
    return <LocalLoadingIndicator />;
  }

  return (
    <AdminPage h2Key="pages.admin.images.title">
      {contentRef => (
        <>
          <IonList>
            {images.map(image => {
              const moderationResult = getImageAutoModerationResult(apiData, image);
              return (
                <IonItem key={image.id}>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <ImgOriented
                          alt={image.id}
                          className="image-attachment ion-padding"
                          src={image.attributes.url}
                        />
                      </IonCol>
                      <IonCol>
                        <AdminAutoModerationResult autoModerationResult={moderationResult} />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  <IonButtons slot="end">
                    <IonButton
                      color="success"
                      expand="full"
                      fill="solid"
                      onClick={generateHandleApproveClick(image)}
                    >
                      <FormattedMessage id="dictionary.approve" />
                    </IonButton>
                  </IonButtons>
                </IonItem>
              );
            })}
          </IonList>
          <InfiniteScroll<Models.Image>
            contentRef={contentRef}
            loadMoreActionCreator={adminLoadMoreImages}
          />
        </>
      )}
    </AdminPage>
  );
};

export default AdminImagesPage;
