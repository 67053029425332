// an IonChip, that acts as a button!
import { IonChip } from '@ionic/react';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

const ChipButton = ({
  onClick,
  routerLink,
  ...ionChipProps
}: Omit<React.ComponentPropsWithoutRef<typeof IonChip>, 'onClick'> & {
  onClick?: () => void;
  routerLink?: string;
}) => {
  const history = useHistory();

  const handleClick = useCallback(() => {
    if (routerLink) {
      history.push(routerLink);
    }
    if (onClick) {
      onClick();
    }
  }, [history, onClick, routerLink]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLIonChipElement>) => {
      if (event.key === 'Enter') {
        handleClick();
      }
    },
    [handleClick]
  );

  return (
    <IonChip
      {...ionChipProps}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    />
  );
};

export default ChipButton;
