import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonToolbar
} from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Route } from 'react-router';

import logo from '../assets/images/logos/logo-coral-horizontal.png';
import InvitesModal from '../components/InvitesModal';
import { TabData } from '../components/routing/TabDataProvider';
import TabBadge from '../components/TabBadge';
import useCanSendInvites from '../hooks/useCanSendInvites';
import useOpener from '../hooks/useOpener';
import { JSONApi } from '../types';

type Props = {
  currentGroup: JSONApi.GroupResource;
  tabs: TabData[];
};

const GroupDesktopMenu = ({ currentGroup, tabs }: Props) => {
  const canSendInvites = useCanSendInvites();
  const intl = useIntl();
  const modalOpener = useOpener();

  type T = TabData & { className: string };
  const renderMenuItem = useCallback(
    ({ className, icon, id, path, tKey, unreadCount }: T) => (
      <IonItem
        button
        className={className}
        color="night"
        detail={false}
        key={id}
        lines="full"
        role="button"
        routerLink={path}
      >
        <IonIcon className={icon} slot="start" />
        <IonLabel>
          <FormattedMessage id={tKey} />
        </IonLabel>
        <TabBadge color="danger" count={unreadCount} slot="end" />
      </IonItem>
    ),
    []
  );

  return (
    <IonMenu
      className="group-desktop-menu"
      contentId="mainMenuContent"
      disabled={false}
      menuId="group-desktop-menu"
      side="start"
      type="overlay"
    >
      <IonHeader className="hero">
        <IonToolbar className="ion-padding" color="night">
          <IonItem className="compact" color="night" lines="none">
            <h2>{currentGroup.attributes.name}</h2>
          </IonItem>
          {canSendInvites && (
            <div>
              <IonButton
                color="tertiary"
                fill="outline"
                onClick={modalOpener.open}
                shape="round"
                size="small"
              >
                <IonIcon className="custom-invite" slot="start" />
                <FormattedMessage id="dictionary.inviteMembers" />
              </IonButton>
            </div>
          )}
          <InvitesModal group={currentGroup} opener={modalOpener} />
        </IonToolbar>
      </IonHeader>
      <IonContent color="night">
        <IonList className="ion-no-padding">
          {tabs.map(tab => (
            <Route exact key={tab.id} path={tab.path}>
              {match => {
                const className = match.match ? 'selected' : '';
                return renderMenuItem({ ...tab, className });
              }}
            </Route>
          ))}
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar className="ion-padding" color="night">
          {/* eslint-disable-next-line react/forbid-elements */}
          <img alt={intl.formatMessage({ id: 'dictionary.logo' })} className="logo" src={logo} />
        </IonToolbar>
      </IonFooter>
    </IonMenu>
  );
};

export default GroupDesktopMenu;
