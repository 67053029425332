import { IonBadge, IonButton, IonButtons, IonIcon, IonItem, IonLabel } from '@ionic/react';
import { pin, pinOutline } from 'ionicons/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { alertError, alertSuccess } from '../actions/notificationActions';
import Nbsp from '../components/Nbsp';
import useMountedTracking from '../hooks/useMountedTracking';
import useOpener from '../hooks/useOpener';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { getGroup, getPinnedPost } from '../selectors';
import { AjaxError } from '../services/ajaxRequest';
import { handleSubmissionError } from '../services/formUtils';
import { getPostBody, getPostPath } from '../services/postUtils';
import { truncateString } from '../services/stringUtils';
import { approvePost, rejectPost, updateGroup } from '../thunks/apiThunks';
import { Actions, JSONApi, ModelAttributes, Models, State } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  post: JSONApi.PostResource;
};

const ModeratePost = ({ group, post }: Props) => {
  const actionsOpener = useOpener();
  const apiData = useSelector((root: State.Root) => root.api);
  const dispatch = useThunkDispatch();
  const isMounted = useMountedTracking();
  const [isPinned, setIsPinned] = useState(false);

  const { status } = post.attributes;
  const title = post.attributes.title || truncateString(getPostBody(post), 50);

  const canBeApproved = [
    ModelAttributes.PostStatus.PENDING,
    ModelAttributes.PostStatus.REJECTED
  ].includes(status);

  const canBeRejected = [
    ModelAttributes.PostStatus.APPROVED,
    ModelAttributes.PostStatus.PENDING,
    ModelAttributes.PostStatus.PUBLISHED
  ].includes(status);

  // default status color handles these situations:
  // Models.PostStatus.PENDING
  let statusColor: 'danger' | 'success' | 'warning' = 'warning';
  if (ModelAttributes.PostStatus.REJECTED === status) {
    statusColor = 'danger';
  }
  if (
    [ModelAttributes.PostStatus.APPROVED, ModelAttributes.PostStatus.PUBLISHED].includes(status)
  ) {
    statusColor = 'success';
  }

  useEffect(() => {
    // annoying but the group in memory in this component will always be the "old" version
    // that doesn't have the updated pinned post id, so we pull the group out of api data
    const updatedGroup = getGroup(apiData, group.attributes.slug);
    const pinnedPost = updatedGroup && getPinnedPost(apiData, updatedGroup);
    setIsPinned(pinnedPost?.id === post.id);
  }, [apiData, group.attributes.slug, post.id]);

  const handleUpdate = useCallback(
    (thunk: Actions.ApiThunkAction<Models.Post>, alertKey: 'approve' | 'reject') => {
      dispatch(thunk)
        .then(() => {
          dispatch(alertSuccess(`forms.moderatePost.${alertKey}.success`));
        })
        .catch((error: AjaxError) => {
          handleSubmissionError({
            dispatch,
            error,
            handleInvalid: () => {
              dispatch(alertError(`forms.moderatePost.${alertKey}.error`));
            },
            isMounted
          });
        });
    },
    [dispatch, isMounted]
  );

  const handleClickApprove = useCallback(() => {
    handleUpdate(approvePost(group.attributes.slug, post), 'approve');
  }, [group.attributes.slug, handleUpdate, post]);

  const handleClickTogglePin = useCallback(() => {
    if (isPinned) {
      dispatch(updateGroup({ pinnedPostId: null }, group.id))
        .then(() => {
          dispatch(alertSuccess('forms.pinPost.delete.success'));
        })
        .catch(() => {
          dispatch(alertError('forms.pinPost.delete.error'));
        });
    } else {
      dispatch(updateGroup({ pinnedPostId: post.id }, group.id))
        .then(() => {
          dispatch(alertSuccess('forms.pinPost.create.success'));
        })
        .catch(() => {
          dispatch(alertError('forms.pinPost.create.error'));
        });
    }
  }, [dispatch, group.id, isPinned, post.id]);

  const handleClickReject = useCallback(() => {
    handleUpdate(rejectPost(group.attributes.slug, post), 'reject');
  }, [group.attributes.slug, handleUpdate, post]);

  return (
    <>
      <IonItem
        color={actionsOpener.isOpen ? 'light' : undefined}
        lines={actionsOpener.isOpen ? 'none' : 'inset'}
      >
        <IonButtons slot="start">
          <IonButton onClick={handleClickTogglePin}>
            <IonIcon icon={isPinned ? pin : pinOutline} slot="icon-only" />
          </IonButton>
        </IonButtons>
        <IonItem
          button
          className="ion-activatable"
          color="transparent"
          lines="none"
          onClick={actionsOpener.toggle}
          role="button"
        >
          <IonLabel>{title}</IonLabel>
          <div slot="end">
            <IonBadge color={statusColor}>
              <FormattedMessage id={`models.post.attributes.status.${status}Badge`} />
            </IonBadge>
            <Nbsp />
            <IonBadge color="dark">
              <FormattedMessage id={`models.${post.type}.badge`} />
            </IonBadge>
          </div>
        </IonItem>
      </IonItem>
      {actionsOpener.isOpen && (
        <>
          <IonItem color="light" lines="none">
            <IonLabel>
              <FormattedMessage id="dictionary.postedAt" />
              <Nbsp />
              {post.attributes.createdAt.toLocaleString({
                day: 'numeric',
                month: 'short',
                year: 'numeric'
              })}
            </IonLabel>
          </IonItem>
          <IonItem color="light" lines="none">
            <IonButtons>
              <IonButton
                color="secondary"
                fill="solid"
                routerLink={getPostPath(post, group)}
                slot="start"
              >
                <FormattedMessage id="dictionary.viewPost" />
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              {canBeApproved && (
                <IonButton color="success" fill="solid" onClick={handleClickApprove}>
                  <FormattedMessage id="dictionary.approve" />
                </IonButton>
              )}
              {canBeRejected && (
                <IonButton color="danger" fill="solid" onClick={handleClickReject}>
                  <FormattedMessage id="dictionary.reject" />
                </IonButton>
              )}
            </IonButtons>
          </IonItem>
        </>
      )}
    </>
  );
};

export default ModeratePost;
