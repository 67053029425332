import { IonInputCustomEvent } from '@ionic/core';
import { InputChangeEventDetail } from '@ionic/react';
import React from 'react';
import { useField } from 'react-final-form';

import Input from '../../components/forms/Input';
import useMergeOnChange from '../../hooks/useMergeOnChange';

const FormInput = ({
  name,
  onIonChange,
  ...props
}: React.ComponentPropsWithoutRef<typeof Input> & {
  name: string;
  onIonChange?: ((event: IonInputCustomEvent<InputChangeEventDetail>) => void) | undefined;
}) => {
  const field = useField(name, { parse: (value: string) => value, subscription: { value: true } });
  // the checked and multiple props from react-final-form do not pertain to basic inputs
  // the type prop from react-final-form technically does apply, but the props passed to this component
  // will already have it in a stronger typed form that the Input component needs
  // and we need to handle onChange manually during the onIonChange handler
  const { checked, multiple, onChange, type, ...fieldInputProps } = field.input;
  const handleIonChange = useMergeOnChange<IonInputCustomEvent<InputChangeEventDetail>>({
    onChange,
    onIonChange
  });

  return <Input {...fieldInputProps} {...props} onIonChange={handleIonChange} />;
};

export default FormInput;
