import React, { useCallback, useState } from 'react';
import { useField } from 'react-final-form';

import SearchableSelect from '../../components/forms/SearchableSelect';
import { SearchableSelectOptionData } from '../../components/forms/SearchableSelectModal';

const FormSearchableSelect = ({
  name,
  ...props
}: Omit<
  React.ComponentProps<typeof SearchableSelect>,
  'clear' | 'onSelectOption' | 'selectedOption'
> & {
  name: string;
}) => {
  const [selectedOption, setSelectedOption] = useState<SearchableSelectOptionData | undefined>();

  const field = useField(name, { subscription: { value: true } });
  // the checked prop from react-final-form does not pertain to searchable selects
  // the type prop from react-final-form technically does apply, but the props passed to this component
  // will already have it in a stronger typed form that the SearchableSelect component needs
  // and we need to handle onChange manually during the onIonChange handler
  const { checked, onChange, type, ...fieldSearchableSelectProps } = field.input;

  const handleSelectOption = useCallback(
    (option: SearchableSelectOptionData | undefined) => {
      setSelectedOption(option);
      const newValue = option ? option.value : null;
      if (fieldSearchableSelectProps.value !== newValue) {
        onChange(newValue);
      }
    },
    [fieldSearchableSelectProps.value, onChange]
  );

  return (
    <SearchableSelect
      {...fieldSearchableSelectProps}
      {...props}
      onSelectOption={handleSelectOption}
      selectedOption={selectedOption}
    />
  );
};

export default FormSearchableSelect;
