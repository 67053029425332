import { IonButton, IonButtons, IonIcon, IonItem } from '@ionic/react';
import { close } from 'ionicons/icons';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { dismissNotification } from '../actions/notificationActions';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { State } from '../types';

import './PermanentAlert.scss';

const PermanentAlert = ({
  buttonProps,
  color,
  id,
  ...props
}: React.ComponentPropsWithoutRef<typeof FormattedMessage> & {
  buttonProps?: React.ComponentPropsWithoutRef<typeof IonButton>;
  color?: string;
  id: string;
}) => {
  const dismissed = useSelector((root: State.Root) => root.notifications.dismissedNotifications);
  const dispatch = useThunkDispatch();

  const handleDismiss = useCallback(() => {
    if (id) {
      dispatch(dismissNotification(id.toString()));
    }
  }, [dispatch, id]);

  const renderDismissButton = useCallback(
    () => (
      <IonButton color={color} fill="solid" onClick={handleDismiss}>
        <IonIcon icon={close} size="large" />
      </IonButton>
    ),
    [color, handleDismiss]
  );

  if (id && dismissed.includes(id.toString())) {
    return null;
  }

  return (
    <IonItem className="permanent-alert" color={color} lines="none">
      <p>
        <FormattedMessage id={id} {...props} />
        {buttonProps && (
          <IonButtons className="ion-margin-top mobile-only">
            <IonButton fill="solid" {...buttonProps} />
            {renderDismissButton()}
          </IonButtons>
        )}
      </p>
      <IonButtons className="desktop-only" slot="end">
        {buttonProps && <IonButton fill="solid" {...buttonProps} />}
        {renderDismissButton()}
      </IonButtons>
    </IonItem>
  );
};

export default PermanentAlert;
