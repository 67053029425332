import React from 'react';
import { useField } from 'react-final-form';

import ErrorMessage from '../../components/forms/ErrorMessage';

type Props = {
  allowBlank?: boolean;
  className?: string;
  name: string;
};

const FormError = ({ allowBlank = false, className, name }: Props) => {
  const { input, meta } = useField(name, {
    subscription: {
      dirtySinceLastSubmit: true,
      error: true,
      submitError: true,
      touched: true,
      value: allowBlank
    }
  });

  // we only care about the submit error if you haven't modified the field since submitting
  const message =
    (meta.error as string) ?? (!meta.dirtySinceLastSubmit && (meta.submitError as string));

  // if blanks are allowed we won't show an error until there is a value present
  if (message && meta.touched && (!allowBlank || input.value)) {
    return <ErrorMessage className={className}>{message}</ErrorMessage>;
  }

  return null;
};

export default FormError;
