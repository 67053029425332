import { IonButton, IonCard, IonCardContent, IonContent, IonFooter, IonIcon } from '@ionic/react';
import { ellipsisHorizontal } from 'ionicons/icons';
import React, { createRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { apiClearCachedResponsesContainingResource } from '../actions/apiActions';
import CommentsSection from '../components/CommentsSection';
import CommentForm from '../components/forms/CommentForm';
import Heading from '../components/Heading';
import Page from '../components/layout/Page';
import PageHeader from '../components/layout/PageHeader';
import PageTitle from '../components/layout/PageTitle';
import PostActionSheet from '../components/PostActionSheet';
import PostAuthor from '../components/PostAuthor';
import useActionCableChannel from '../hooks/useActionCableChannel';
import useOpener from '../hooks/useOpener';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { getPostAuthor } from '../selectors';
import { loadPost } from '../thunks/apiThunks';
import { JSONApi, ModelAttributes, Models, State } from '../types';

import './PostPage.scss';

/* eslint-disable @typescript-eslint/no-type-alias */
export type PostPageProps<T extends Models.Post> = React.PropsWithChildren<{
  backHref: string;
  fab?: React.ReactElement;
  group: JSONApi.GroupResource;
  onEditClick?: () => void;
  post: JSONApi.Resource<T>;
}>;
/* eslint-enable @typescript-eslint/no-type-alias */

function PostPage<T extends Models.Post>(props: PostPageProps<T>) {
  const { backHref, children, fab, group, onEditClick, post } = props;

  const actionSheetOpener = useOpener();
  const apiData = useSelector((root: State.Root) => root.api);
  const contentRef = createRef<HTMLIonContentElement>();
  const dispatch = useThunkDispatch();
  const intl = useIntl();

  useActionCableChannel({
    channelName: { channel: 'PostUpdatesChannel', groupId: group.id, postId: post.id },
    onReceived: () => {
      dispatch(apiClearCachedResponsesContainingResource(post.id, post.type));
      dispatch(loadPost(group.attributes.slug, post.id, post.type as ModelAttributes.PostType));
    }
  });

  const poster = getPostAuthor(apiData, post);

  return (
    <Page className="centered post-page tight">
      <PageHeader defaultBackHref={backHref} />
      <PageTitle group={group} value={post.attributes.title} />
      <IonContent className="canvas ion-padding" fullscreen ref={contentRef}>
        {fab}
        <IonCard>
          <IonCardContent className="ion-no-padding">
            <IonButton
              aria-label={intl.formatMessage({ id: 'dictionary.options' })}
              className="post-actions ion-float-right"
              color="primary"
              fill="clear"
              onClick={actionSheetOpener.toggle}
            >
              <IonIcon icon={ellipsisHorizontal} />
            </IonButton>
            <div className="ion-padding">
              {poster && <PostAuthor group={group} post={post} timestamp />}
              {post.attributes.title.length > 0 && (
                <Heading level={1}>{post.attributes.title}</Heading>
              )}
            </div>
            {children}
            <CommentsSection group={group} post={post} />
          </IonCardContent>
        </IonCard>
        <PostActionSheet
          close={actionSheetOpener.close}
          group={group}
          isOpen={actionSheetOpener.isOpen}
          onEditClick={onEditClick}
          post={post}
        />
      </IonContent>
      <IonFooter className="add-to-thread">
        <CommentForm contentRef={contentRef} group={group} post={post} />
      </IonFooter>
    </Page>
  );
}

export default PostPage;
