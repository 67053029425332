import { IonButtons, IonItem, IonProgressBar } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

import { WizardSteps } from '../../components/CreateGroupPage';
import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormInput from '../../components/forms/FormInput';
import FormSubmit from '../../components/forms/FormSubmit';
import FormTextarea from '../../components/forms/FormTextarea';
import SubmitError from '../../components/forms/SubmitError';
import Heading from '../../components/Heading';
import {
  maxGroupSlugLength,
  maxTextInputLength,
  maxTitleLength,
  minGroupSlugLength
} from '../../globals';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { forceArray } from '../../services/arrayUtils';
import { handleFormSubmission } from '../../services/formUtils';
import { createGroup, updateGroup } from '../../thunks/apiThunks';
import { JSONApi } from '../../types';

import './SetupGroupNamesForm.scss';

type FormValues = {
  description: string;
  name: string;
  slug: string;
};

type Props = {
  group: JSONApi.GroupResource | null | undefined;
};

const SetupGroupNamesForm = ({ group }: Props) => {
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const initialValues: FormValues = {
    description: group?.attributes?.description ?? '',
    name: group?.attributes?.name ?? '',
    slug: group?.attributes?.slug ?? ''
  };

  const handleSubmit = useCallback(
    async (values: FormValues) =>
      handleFormSubmission({
        action: group ? updateGroup(values, group.id) : createGroup(values),
        dispatch,
        errorTranslationLocation: 'group',
        intl,
        isMounted,
        onSuccess: response => {
          const newGroup = forceArray(response.data)[0];
          history.push(`/create/${newGroup.attributes.slug}/${WizardSteps.PERSONALIZE}`);
        },
        values
      }),
    [dispatch, group, history, intl, isMounted]
  );

  const validationSchema = Yup.object().shape({
    description: Yup.string()
      .nullable()
      .max(
        maxTextInputLength,
        intl.formatMessage(
          { id: 'errors.group.description.tooLong' },
          { count: maxTextInputLength }
        )
      ),
    name: Yup.string()
      .required(intl.formatMessage({ id: 'errors.group.name.blank' }))
      .max(
        maxTitleLength,
        intl.formatMessage({ id: 'errors.group.name.tooLong' }, { count: maxTitleLength })
      ),
    slug: Yup.string()
      .required(intl.formatMessage({ id: 'errors.group.slug.blank' }))
      .matches(
        new RegExp(/^[\w|\d|\-|_]*$/),
        intl.formatMessage({ id: 'errors.group.slug.invalid' })
      )
      .max(
        maxGroupSlugLength,
        intl.formatMessage({ id: 'errors.group.slug.tooLong' }, { count: maxGroupSlugLength })
      )
      .min(
        minGroupSlugLength,
        intl.formatMessage({ id: 'errors.group.slug.tooShort' }, { count: minGroupSlugLength })
      )
  });

  return (
    <FormContainer<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ form, handleSubmit }) => (
        <Form<FormValues>
          className="setup-group-names-form"
          onFormSubmit={handleSubmit}
          submit={form.submit}
        >
          <IonProgressBar buffer={0.1} color="success" value={0} />
          <Heading level={2}>
            <FormattedMessage id="pages.groupWizard.names.title" />
          </Heading>
          <SubmitError />
          <FormError name="name" />
          <FormInput
            aria-label={intl.formatMessage({ id: 'models.group.attributes.name.label' })}
            autofocus
            name="name"
            placeholder={intl.formatMessage({
              id: 'models.group.attributes.name.placeholder'
            })}
            type="text"
          />
          <FormError name="description" />
          <FormTextarea
            aria-label={intl.formatMessage({
              id: 'models.group.attributes.description.label'
            })}
            name="description"
            placeholder={intl.formatMessage({
              id: 'models.group.attributes.description.placeholder'
            })}
          />
          <Heading level={2}>
            <FormattedMessage id="models.group.attributes.slug.label" />
          </Heading>
          <FormError name="slug" />
          <div className="slug-input">
            <div className="prefix">
              <FormattedMessage id="models.group.attributes.slug.prefix" />
            </div>
            <FormInput
              aria-label={intl.formatMessage({ id: 'models.group.attributes.slug.label' })}
              name="slug"
              placeholder={intl.formatMessage({
                id: 'models.group.attributes.slug.placeholder'
              })}
              type="text"
            />
          </div>
          <IonItem color="transparent" lines="none">
            <IonButtons slot="end">
              <FormSubmit allowPristineSubmit color="primary" fill="solid" size="default">
                <FormattedMessage id="dictionary.next" />
              </FormSubmit>
            </IonButtons>
          </IonItem>
        </Form>
      )}
    </FormContainer>
  );
};

export default SetupGroupNamesForm;
