import { HTML, ModelAttributes } from '.';

export enum SortOptions {
  AUTO = 'auto',
  FIRST_NAME = 'firstName',
  JOIN_DATE = 'joinDate',
  LAST_NAME = 'lastName',
  LOCATION = 'location',
  CREATED_DATE = 'createdDate:desc',
  POST_DATE = 'postDate',
  START_TIME = 'startTime',
  TITLE = 'title'
}

export interface Params extends HTML.UrlParams {
  query: string | null;
  sort?: SortOptions;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ConversationParams extends Params {}

export interface GroupParams extends Params {
  status?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ImageParams extends Params {}

export interface InviteParams extends Params {
  status?: string;
}

export interface MemberParams extends Params {
  country: string | null;
  industry: ModelAttributes.Industry | null;
  lat: number | null;
  lng: number | null;
  location: string | null;
  radius: string | null;
  reactionContentId?: string | null;
  reactionContentType?: ModelAttributes.ReactionContentType | null;
  reactionEmotion?: ModelAttributes.ReactionEmotion | null;
  role?: string | null;
  status?: string;
  traitOptionIds: string;
}

export interface PostParams extends Params {
  companyName: string | null;
  eventType: ModelAttributes.EventType | null;
  industry: ModelAttributes.Industry | null;
  jobType: ModelAttributes.JobType | null;
  lat: number | null;
  lng: number | null;
  location: string | null;
  memberSlug: string | null;
  radius: string | null;
  status?: string;
  upcoming: 'true' | 'false' | null;
}

export enum Searchable {
  DISCUSSION = 'Discussion',
  EVENT = 'Event',
  JOB_POST = 'JobPost',
  MEMBER = 'Member',
  NEWS_ITEM = 'NewsItem'
}

export interface FacetValue {
  count: number;
  key: string;
}

export interface FacetCollection {
  [key: string]: FacetValue[] | undefined;
}
