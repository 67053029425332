import { IonItem } from '@ionic/react';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import { alertError, alertSuccess } from '../../actions/notificationActions';
import BasicLink from '../../components/BasicLink';
import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormInput from '../../components/forms/FormInput';
import FormSubmit from '../../components/forms/FormSubmit';
import SubmitError from '../../components/forms/SubmitError';
import Paragraph from '../../components/Paragraph';
import UserAccountWizardBackButton from '../../components/UserAccountWizardBackButton';
import { maxEmailLength } from '../../globals';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { handleFormSubmission } from '../../services/formUtils';
import { createEmailConfirmation } from '../../thunks/apiThunks';
import { login } from '../../thunks/sessionThunks';

type FormValues = {
  email: string;
  password: string;
};

type Props = {
  goToForgotPassword: () => void;
};

const LoginForm = ({ goToForgotPassword }: Props) => {
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const [usingPasswordless, setUsingPasswordless] = useState<boolean>(false);

  const initialValues: FormValues = {
    email: '',
    password: ''
  };

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      if (usingPasswordless) {
        try {
          await dispatch(createEmailConfirmation(null, values.email));
          dispatch(alertSuccess('pages.login.passwordlessLoginSuccess'));
        } catch {
          dispatch(alertError('errors.login.passwordlessLoginCreation.failed'));
        }
      } else {
        // The LoginForm is always rendered inside of components that redirect appropriately if there
        // is a user, so we really don't need a success handler here.
        return handleFormSubmission({
          action: login(values),
          dispatch,
          errorTranslationLocation: 'login',
          intl,
          isMounted,
          values
        });
      }
    },
    [dispatch, intl, isMounted, usingPasswordless]
  );

  const handleToggleUsePasswordless = useCallback(() => {
    setUsingPasswordless(!usingPasswordless);
  }, [usingPasswordless]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'errors.login.email.invalid' }))
      .required(intl.formatMessage({ id: 'errors.login.email.blank' }))
      .max(
        maxEmailLength,
        intl.formatMessage({ id: 'errors.emailAddress.email.tooLong' }, { count: maxEmailLength })
      ),
    password: usingPasswordless
      ? Yup.string().nullable()
      : Yup.string().required(intl.formatMessage({ id: 'errors.login.password.blank' }))
  });

  return (
    <>
      <UserAccountWizardBackButton routerLink="/" />
      <FormContainer<FormValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ form, handleSubmit }) => (
          <Form<FormValues> className="login-form" onFormSubmit={handleSubmit} submit={form.submit}>
            <IonItem className="compact" color="transparent" lines="full">
              <FormInput
                aria-label={intl.formatMessage({
                  id: 'models.login.attributes.email.label'
                })}
                autofocus
                color="primary"
                name="email"
                placeholder={intl.formatMessage({
                  id: 'models.login.attributes.email.placeholder'
                })}
                type="email"
              />
            </IonItem>
            <FormError allowBlank name="email" />
            {!usingPasswordless && (
              <>
                <IonItem className="compact" color="transparent" lines="full">
                  <FormInput
                    aria-label={intl.formatMessage({
                      id: 'models.login.attributes.password.label'
                    })}
                    color="primary"
                    name="password"
                    placeholder={intl.formatMessage({
                      id: 'models.login.attributes.password.placeholder'
                    })}
                    type="password"
                  />
                </IonItem>
                <FormError allowBlank name="password" />
              </>
            )}
            <SubmitError />
            <IonItem className="compact" lines="none">
              <Paragraph>
                <FormattedMessage
                  id={
                    usingPasswordless
                      ? 'models.login.tip.passwordless'
                      : 'models.login.tip.password'
                  }
                />
              </Paragraph>
            </IonItem>
            <IonItem className="compact" lines="none">
              <Paragraph>
                <BasicLink
                  onClick={handleToggleUsePasswordless}
                  tKey={
                    usingPasswordless ? 'pages.login.usePassword' : 'pages.login.usePasswordless'
                  }
                />
              </Paragraph>
            </IonItem>
            <IonItem className="compact" lines="none">
              <Paragraph>
                <BasicLink onClick={goToForgotPassword} tKey="dictionary.forgotPassword" />
              </Paragraph>
            </IonItem>
            <FormSubmit color="primary">
              <FormattedMessage
                id={usingPasswordless ? 'pages.login.sendEmail' : 'dictionary.logIn'}
              />
            </FormSubmit>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default LoginForm;
