import { IonContent, IonItem, IonList } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import AccountTopItem from '../components/forms/AccountTopItem';
import Page from '../components/layout/Page';
import PageHeader from '../components/layout/PageHeader';
import PageTitle from '../components/layout/PageTitle';
import { JSONApi } from '../types';

import './SettingsList.scss';

type Props = {
  currentUser: JSONApi.UserResource;
};

const MeAndMyDataPage = ({ currentUser }: Props) => {
  const [groupSlug, setGroupSlug] = useState<string>();

  useEffect(() => {
    if (currentUser.attributes.lastVisitedGroupSlug) {
      setGroupSlug(currentUser.attributes.lastVisitedGroupSlug);
    }
  }, [currentUser.attributes.lastVisitedGroupSlug]);

  return (
    <Page className="centered me-and-my-data-page tight">
      <PageHeader defaultBackHref={groupSlug ? `/g/${groupSlug}` : '/'} />
      <PageTitle id="pages.meAndMyData.title" />
      <IonContent className="canvas ion-padding">
        <div>
          <h1 className="ion-no-margin ion-padding">
            <FormattedMessage id="pages.meAndMyData.title" />
          </h1>
          <IonList className="ion-no-padding settings-list">
            <AccountTopItem currentUser={currentUser} />
            <IonItem button role="button" routerLink="/me-and-my-data/account">
              <FormattedMessage id="pages.account.title" />
            </IonItem>
            <IonItem button role="button" routerLink="/me-and-my-data/notifications">
              <FormattedMessage id="pages.notifications.title" />
            </IonItem>
            <IonItem button role="button" routerLink="/me-and-my-data/emails">
              <FormattedMessage id="dictionary.changeEmail" />
            </IonItem>
            <IonItem button role="button" routerLink="/me-and-my-data/password">
              <FormattedMessage id="dictionary.changePassword" />
            </IonItem>
            <IonItem button lines="none" role="button" routerLink="/logout">
              <FormattedMessage id="forms.logout.submitButton" />
            </IonItem>
          </IonList>
        </div>
      </IonContent>
    </Page>
  );
};

export default MeAndMyDataPage;
