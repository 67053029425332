import { IonItem } from '@ionic/react';
import React, { useState } from 'react';

import { setEventSearchParams } from '../actions/searchActions';
import SearchEventType from '../components/SearchEventType';
import SearchLocation from '../components/SearchLocation';
import SearchQuery from '../components/SearchQuery';
import SearchRadius from '../components/SearchRadius';
import SearchSort from '../components/SearchSort';
import SearchUpcoming from '../components/SearchUpcoming';
import useSearchParamsFromUrl from '../hooks/useSearchParamsFromUrl';
import { defaultPostSearchParams } from '../services/searchUtils';
import { JSONApi, Search } from '../types';

import '../components/forms/CustomForm.scss';
import './Searchbox.scss';

export type EventSearchFacets = {
  eventType: Search.FacetValue[] | undefined;
};

type Props = {
  facets: EventSearchFacets;
  group: JSONApi.GroupResource;
  isOpen: boolean;
};

const EventSearchForm = ({ facets, group, isOpen }: Props) => {
  const [searchParams, setSearchParams] = useState<Search.PostParams>(defaultPostSearchParams());

  let searchFields: (keyof Search.PostParams)[] = ['eventType', 'query', 'sort', 'upcoming'];
  if (group.attributes.locationFeaturesEnabled) {
    searchFields = searchFields.concat(['lat', 'lng', 'location', 'radius']);
  }

  const sortOptions = [
    Search.SortOptions.START_TIME,
    Search.SortOptions.AUTO,
    Search.SortOptions.POST_DATE,
    Search.SortOptions.TITLE
  ];

  useSearchParamsFromUrl<Search.PostParams>(
    searchFields,
    setSearchParams,
    searchParams,
    setEventSearchParams
  );

  if (isOpen) {
    return (
      <div className="custom-form-wrapper searchbox">
        <IonItem>
          <SearchQuery<Search.PostParams>
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </IonItem>
        <div className="ion-padding">
          <SearchEventType<Search.PostParams>
            facetValues={facets.eventType}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
          {group.attributes.locationFeaturesEnabled && (
            <>
              <SearchLocation<Search.PostParams>
                searchParams={searchParams}
                setSearchParams={setSearchParams}
              />
              <SearchRadius<Search.PostParams>
                searchParams={searchParams}
                setSearchParams={setSearchParams}
              />
            </>
          )}
          <SearchUpcoming<Search.PostParams>
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
          <SearchSort<Search.PostParams>
            defaultOption={Search.SortOptions.START_TIME}
            options={sortOptions}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </div>
      </div>
    );
  }
  return null;
};

export default EventSearchForm;
