import { FormApi } from 'final-form';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormItem from '../../components/forms/FormItem';
import FormSubmit from '../../components/forms/FormSubmit';
import FormTextarea from '../../components/forms/FormTextarea';
import SubmitError from '../../components/forms/SubmitError';
import { maxParagraphLength, minRuleLength } from '../../globals';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { AjaxResponse } from '../../services/ajaxRequest';
import { handleFormSubmission } from '../../services/formUtils';
import { createRule } from '../../thunks/apiThunks';
import { JSONApi, Models } from '../../types';

type FormValues = Models.RuleFormData;

type Props = {
  autofocus: boolean;
  group: JSONApi.GroupResource;
  onSuccess?: (response: AjaxResponse<Models.Rule>) => void;
};

const GroupRuleForm = ({ autofocus, group, onSuccess }: Props) => {
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const initialValues: FormValues = { rule: '' };

  const handleSubmit = useCallback(
    async (values: FormValues, form: FormApi<FormValues>) =>
      handleFormSubmission({
        action: createRule(group.attributes.slug, values),
        dispatch,
        errorTranslationLocation: 'rule',
        form,
        intl,
        isMounted,
        onSuccess,
        successTKey: 'forms.rule.create.success',
        values
      }),
    [dispatch, group.attributes.slug, intl, isMounted, onSuccess]
  );

  const validationSchema = Yup.object().shape({
    rule: Yup.string()
      .required(intl.formatMessage({ id: 'errors.rule.rule.blank' }))
      .min(
        minRuleLength,
        intl.formatMessage({ id: 'errors.rule.rule.tooShort' }, { count: minRuleLength })
      )
      .max(
        maxParagraphLength,
        intl.formatMessage({ id: 'errors.rule.rule.tooLong' }, { count: maxParagraphLength })
      )
  });

  return (
    <FormContainer<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ form, handleSubmit }) => (
        <Form<FormValues>
          className="group-rule-form"
          onFormSubmit={handleSubmit}
          submit={form.submit}
        >
          <SubmitError />
          <FormError name="rule" />
          <FormItem id="models.rule.attributes.rule.label" required>
            <FormTextarea
              autoGrow={false}
              autofocus={autofocus}
              name="rule"
              placeholder={intl.formatMessage({ id: 'models.rule.attributes.rule.placeholder' })}
              rows={3}
            />
          </FormItem>
          <div className="ion-padding">
            <FormSubmit>
              <FormattedMessage id="dictionary.add" />
            </FormSubmit>
          </div>
        </Form>
      )}
    </FormContainer>
  );
};

export default GroupRuleForm;
