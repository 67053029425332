import { IonSpinner } from '@ionic/react';
import React from 'react';

const LocalLoadingIndicator = () => (
  <div className="ion-padding ion-text-center">
    <IonSpinner name="lines" />
  </div>
);

export default LocalLoadingIndicator;
