import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { alertError } from '../../actions/notificationActions';
import EditAvatar from '../../components/forms/EditAvatar';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { forceArray } from '../../services/arrayUtils';
import { randomAvatarSrc } from '../../services/randomAvatarSrc';
import { updateUser } from '../../thunks/apiThunks';
import { JSONApi, Models } from '../../types';

type Props = {
  user: JSONApi.UserResource;
};

const EditUserAvatar = ({ user }: Props) => {
  const intl = useIntl();
  const isMounted = useMountedTracking();
  const [isUpdating, setIsUpdating] = useState(false);
  const [src, setSrc] = useState(user.attributes.remoteImageUrl);
  const dispatch = useThunkDispatch();

  const alt = intl.formatMessage(
    { id: 'models.member.attributes.image.alt' },
    { name: user.attributes.firstName }
  );

  const srcWithBackup = src || randomAvatarSrc(user.attributes.slug);

  const handleFail = useCallback(() => {
    setIsUpdating(false);
    dispatch(alertError('errors.user.image.default'));
  }, [dispatch]);

  const updateAvatar = useCallback(
    (imageDataUrl: string) => {
      dispatch(updateUser({ image: imageDataUrl }, user.id))
        .then((response: JSONApi.Response<Models.User>) => {
          if (isMounted.current) {
            const newAvatarImage = forceArray(response.data)[0].attributes.remoteImageUrl;
            setSrc(newAvatarImage);
            setIsUpdating(false);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            handleFail();
          }
        });
    },
    [dispatch, isMounted, handleFail, user.id]
  );

  return (
    <EditAvatar
      alt={alt}
      isUpdating={isUpdating}
      onFail={handleFail}
      setIsUpdating={setIsUpdating}
      src={srcWithBackup}
      updateAvatar={updateAvatar}
    />
  );
};

export default EditUserAvatar;
