import { ModelAttributes } from '../types';

const eventTypes: ModelAttributes.EventType[] = [
  'Appearance or Signing',
  'Attraction',
  'Camp, Trip, or Retreat',
  'Career/Job Fair',
  'Class, Training, or Workshop',
  'Concert or Performance',
  'Conference',
  'Convention',
  'Dinner or Gala',
  'Festival or Fair',
  'Game or Competition',
  'Meeting or Networking Event',
  'Other',
  'Party or Social Gathering',
  'Race or Endurance Event',
  'Rally',
  'Screening',
  'Seminar or Talk',
  'Tour',
  'Tournament',
  'Tradeshow, Consumer Show, or Expo',
  'Virtual Event',
  'Volunteer',
  'Webinar'
];

export default eventTypes;
