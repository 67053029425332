import useCurrentGroup from './useCurrentGroup';
import useCurrentMember from './useCurrentMember';

const useCanSendInvites = () => {
  const currentGroup = useCurrentGroup();
  const currentMember = useCurrentMember();

  return (
    (!!currentGroup && currentGroup.attributes.membersCanSendInvites) ||
    (!!currentMember && currentMember.attributes.moderator)
  );
};

export default useCanSendInvites;
