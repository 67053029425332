import { IonContent } from '@ionic/react';
import React from 'react';

import Page from '../components/layout/Page';
import PageTitle from '../components/layout/PageTitle';

import './CheckInboxPage.scss';

const CheckInboxPage = ({
  children,
  titleId
}: React.PropsWithChildren<{
  titleId: string;
}>) => (
  <Page className="centered check-inbox-page tight">
    <IonContent className="canvas">
      <PageTitle id={titleId} />
      <div className="grid ion-padding">{children}</div>
    </IonContent>
  </Page>
);

export default CheckInboxPage;
