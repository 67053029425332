import React, { useCallback } from 'react';

import useStopEnterKeyPropagation from '../hooks/useStopEnterKeyPropagation';

// you should use this component instead of an <a> tag with an onClick prop
// you can still use <a> tags with an href prop just fine
const OnClickLink = ({
  children,
  onClick,
  ...aProps
}: React.PropsWithChildren<
  Omit<
    React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
    'href' | 'onClick' | 'onKeyDown' | 'role' | 'tabIndex'
  > & {
    onClick: () => void;
  }
>) => {
  const clickLink = useCallback(
    (event: React.KeyboardEvent<HTMLAnchorElement>) => {
      if (event.key === 'Enter') {
        onClick();
      }
    },
    [onClick]
  );

  // this is included to prevent keydown behavior from bubbling up to, and submitting,
  // a form that might wrap this link
  const handleKeyDown = useStopEnterKeyPropagation<HTMLAnchorElement>(clickLink);

  return (
    <a {...aProps} onClick={onClick} onKeyDown={handleKeyDown} role="link" tabIndex={0}>
      {children}
    </a>
  );
};

export default OnClickLink;
