import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  cap: number;
};

const PlanMemberCapLabel = ({ cap }: Props) => (
  <p>
    <FormattedMessage id="models.plan.attributes.memberCap.labelPrefix" />
    <strong>
      <FormattedMessage id="models.plan.attributes.memberCap.label" values={{ count: cap }} />
    </strong>
    <FormattedMessage id="models.plan.attributes.memberCap.labelSuffix" />
  </p>
);

export default PlanMemberCapLabel;
