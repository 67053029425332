import { InputChangeEventDetail, IonDatetimeCustomEvent, IonInputCustomEvent } from '@ionic/core';
import { DatetimeChangeEventDetail } from '@ionic/react';
import React from 'react';
import { useField } from 'react-final-form';

import Datetime from '../../components/forms/Datetime';
import useMergeOnChange from '../../hooks/useMergeOnChange';

const FormDatetime = ({
  name,
  onIonChange,
  ...props
}: React.ComponentPropsWithoutRef<typeof Datetime> & {
  name: string;
  onIonChange?: ((event: IonInputCustomEvent<InputChangeEventDetail>) => void) | undefined;
}) => {
  const field = useField(name, { subscription: { value: true } });
  // the checked, multiple, and type props from react-final-form do not pertain to datetimes
  // and we need to handle onChange manually during the onIonChange handler
  const { checked, multiple, onChange, type, ...fieldDatetimeProps } = field.input;
  const handleIonChange = useMergeOnChange<IonDatetimeCustomEvent<DatetimeChangeEventDetail>>({
    onChange,
    onIonChange
  });

  return <Datetime {...fieldDatetimeProps} {...props} onIonChange={handleIonChange} />;
};

export default FormDatetime;
