import { IonCheckbox, IonLabel, IonToggle } from '@ionic/react';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import Paragraph from './Paragraph';
import { alertError, alertSuccess } from '../actions/notificationActions';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { updateMember } from '../thunks/apiThunks';
import { JSONApi, Models } from '../types';

type Props = {
  attributeName: keyof Pick<Models.Member, 'wantsModeratorEmails' | 'wantsModeratorSupportEmails'>;
  group: JSONApi.GroupResource;
  member: JSONApi.MemberResource;
  tErrorKey: string;
  tSuccessKey: string;
  textType?: 'label' | 'paragraph';
  uiType?: 'checkbox' | 'toggle';
};

const ToggleMemberFieldCheckbox = (props: Props) => {
  const {
    attributeName,
    group,
    member,
    tErrorKey,
    tSuccessKey,
    textType = 'label',
    uiType = 'checkbox'
  } = props;

  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const [checked, setChecked] = useState(member.attributes[attributeName] ?? false);

  const handleToggleModeratorEmail = useCallback(
    (event: React.MouseEvent<HTMLIonCheckboxElement | HTMLIonToggleElement>) => {
      const checkbox = event.target as HTMLIonCheckboxElement & typeof event.target;
      setChecked(checkbox.checked);
      dispatch(
        updateMember(group.attributes.slug, { [attributeName]: checkbox.checked }, member.id)
      )
        .then(() => {
          dispatch(alertSuccess(tSuccessKey));
        })
        .catch(() => {
          dispatch(alertError(tErrorKey));
        });
    },
    [attributeName, dispatch, group.attributes.slug, member.id, tErrorKey, tSuccessKey]
  );

  const uiProps = {
    checked,
    onClick: handleToggleModeratorEmail,
    slot: 'start'
  };

  const text = intl.formatMessage({ id: `models.member.attributes.${attributeName}.description` });

  return (
    <>
      {uiType === 'checkbox' && <IonCheckbox {...uiProps} />}
      {uiType === 'toggle' && <IonToggle {...uiProps} />}
      {textType === 'label' && <IonLabel>{text}</IonLabel>}
      {textType === 'paragraph' && <Paragraph>{text}</Paragraph>}
    </>
  );
};

export default ToggleMemberFieldCheckbox;
