import { IonButton } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import { alertError, alertSuccess } from '../actions/notificationActions';
import BasicLink from '../components/BasicLink';
import FormModal from '../components/FormModal';
import useMountedTracking from '../hooks/useMountedTracking';
import useOpener from '../hooks/useOpener';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { AjaxError } from '../services/ajaxRequest';
import { handleSubmissionError } from '../services/formUtils';
import { closeMember, deleteMember } from '../thunks/apiThunks';
import { Actions, JSONApi, Models } from '../types';

const LeaveGroupModal = ({
  group,
  member,
  ...formModalProps
}: Omit<React.ComponentPropsWithoutRef<typeof FormModal>, 'children' | 'titleKey'> & {
  group: JSONApi.GroupResource;
  member: JSONApi.MemberResource;
}) => {
  const dispatch = useThunkDispatch();
  const explanationOpener = useOpener();
  const history = useHistory();
  const isMounted = useMountedTracking();

  const handleLeaveGroupDecision = useCallback(
    (action: Actions.ApiThunkAction<Models.Member>, type: 'close' | 'delete') => {
      dispatch(action)
        .then(() => {
          if (isMounted.current) {
            history.push('/me-and-my-data/account');
            dispatch(alertSuccess(`forms.leaveGroup.${type}.success`));
          }
        })
        .catch((error: AjaxError) => {
          handleSubmissionError({
            dispatch,
            error,
            handleInvalid: () => {
              dispatch(alertError(`forms.leaveGroup.${type}.error`));
            },
            isMounted
          });
        });
    },
    [dispatch, history, isMounted]
  );

  const handleCloseClick = useCallback(() => {
    handleLeaveGroupDecision(closeMember(group.attributes.slug, member), 'close');
  }, [group.attributes.slug, handleLeaveGroupDecision, member]);

  const handleDeleteClick = useCallback(() => {
    handleLeaveGroupDecision(deleteMember(group.attributes.slug, member), 'delete');
  }, [group.attributes.slug, handleLeaveGroupDecision, member]);

  return (
    <FormModal {...formModalProps} titleKey="modals.leaveGroup.title">
      {() => (
        <>
          <h2>
            <FormattedMessage id="modals.leaveGroup.h2" values={{ group: group.attributes.name }} />
          </h2>
          <p>
            <BasicLink onClick={explanationOpener.toggle} tKey="modals.leaveGroup.exposeLink" />
          </p>
          {explanationOpener.isOpen && (
            <>
              <div>
                <strong>
                  <FormattedMessage id="modals.leaveGroup.freeze.explanation.header" />
                </strong>
                {/* eslint-disable-next-line react/forbid-elements */}
                <ul>
                  <li>
                    <FormattedMessage id="modals.leaveGroup.freeze.explanation.bullet1" />
                  </li>
                  <li>
                    <FormattedMessage id="modals.leaveGroup.freeze.explanation.bullet2" />
                  </li>
                  <li>
                    <FormattedMessage id="modals.leaveGroup.freeze.explanation.bullet3" />
                  </li>
                  <li>
                    <FormattedMessage id="modals.leaveGroup.freeze.explanation.bullet4" />
                  </li>
                </ul>
              </div>
              <div>
                <strong>
                  <FormattedMessage id="modals.leaveGroup.delete.explanation.header" />
                </strong>
                {/* eslint-disable-next-line react/forbid-elements */}
                <ul>
                  <li>
                    <FormattedMessage id="modals.leaveGroup.delete.explanation.bullet1" />
                  </li>
                  <li>
                    <FormattedMessage id="modals.leaveGroup.delete.explanation.bullet2" />
                  </li>
                  <li>
                    <FormattedMessage id="modals.leaveGroup.delete.explanation.bullet3" />
                  </li>
                  <li>
                    <FormattedMessage id="modals.leaveGroup.delete.explanation.bullet4" />
                  </li>
                </ul>
              </div>
            </>
          )}
          <IonButton color="danger" expand="block" onClick={handleCloseClick}>
            <FormattedMessage id="forms.leaveGroup.closeButton" />
          </IonButton>
          <IonButton color="danger" expand="block" fill="outline" onClick={handleDeleteClick}>
            <FormattedMessage id="forms.leaveGroup.deleteButton" />
          </IonButton>
        </>
      )}
    </FormModal>
  );
};

export default LeaveGroupModal;
