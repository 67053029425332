import { IonIcon, IonRouterLink } from '@ionic/react';
import { star } from 'ionicons/icons';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import MemberAvatar from '../components/MemberAvatar';
import Nbsp from '../components/Nbsp';
import RelativeTimestamp from '../components/RelativeTimestamp';
import { getPostAuthor } from '../selectors';
import { isAuthorModerator } from '../services/postUtils';
import { JSONApi, State } from '../types';

import './CustomItem.scss';

type Props = {
  description?: boolean;
  group: JSONApi.GroupResource;
  link?: boolean;
  post: JSONApi.PostResource;
  timestamp?: boolean;
};

function PostAuthor({ description = false, group, link = true, post, timestamp = false }: Props) {
  const apiData = useSelector((root: State.Root) => root.api);

  const author = getPostAuthor(apiData, post);

  if (!author) {
    return null;
  }

  const path = `/g/${group.attributes.slug}/members/${author.attributes.slug}`;

  return (
    <div className="custom-item">
      {link ? (
        <IonRouterLink routerLink={path}>
          <MemberAvatar member={author} />
        </IonRouterLink>
      ) : (
        <MemberAvatar member={author} />
      )}
      <div className="item-content">
        <div className="item-content-1">
          <strong>
            {link ? (
              <IonRouterLink routerLink={path}>{author.attributes.fullName}</IonRouterLink>
            ) : (
              author.attributes.fullName
            )}
          </strong>
          {description && (
            <>
              <Nbsp />
              <FormattedMessage id={`models.${post.type}.description`} />
            </>
          )}
        </div>
        <div className="item-content-2">
          {isAuthorModerator(author) && (
            <>
              <IonIcon icon={star} />
              <Nbsp />
              <FormattedMessage id="dictionary.moderator" />
              <Nbsp />
              {timestamp && (
                <>
                  <FormattedMessage id="dictionary.dot" />
                  <Nbsp />
                </>
              )}
            </>
          )}
          {timestamp && <RelativeTimestamp date={post.attributes.createdAt.valueOf()} />}
        </div>
      </div>
    </div>
  );
}

export default PostAuthor;
