const errors = {
  attachmentUpload: {
    file: {
      fileSizeIsLessThanOrEqualTo: 'That file is too large. Attachments must be less than {count}.'
    }
  },
  comment: {
    content: {
      blank: 'Comment is required',
      tooLong:
        'A comment cannot be more than {count, plural, one {# character} other {# characters}}'
    }
  },
  comments: {
    load: {
      failed:
        'Failed to load comments for that post. Please reload and try again, or contact support.'
    }
  },
  discussion: {
    description: {
      blank: 'Body text is required',
      tooLong:
        'Body text cannot be more than {count, plural, one {# character} other {# characters}}'
    },
    link: {
      invalidAddress: 'Link must be a valid URL',
      tooLong: 'A link cannot be more than {count, plural, one {# character} other {# characters}}'
    },
    title: {
      blank: 'A title is required',
      tooLong: 'A title cannot be more than {count, plural, one {# character} other {# characters}}'
    }
  },
  emailAddress: {
    email: {
      blank: 'Email is required',
      invalid: 'Email format is invalid',
      taken: 'That email is already being used',
      tooLong:
        'An email cannot be more than {count, plural, one {# character} other {# characters}}'
    }
  },
  emailConfirmation: {
    authentication: {
      blank:
        'The link you used for validating your email does not include a ' +
        'confirmation token. Please make sure your email client is not ' +
        'removing the confirmation token from the link before trying again.'
    }
  },
  event: {
    description: {
      tooLong:
        'An event description cannot be more than {count, plural, one {# character} other ' +
        '{# characters}}'
    },
    endTime: {
      beforeStartTime: 'Events cannot end before they start',
      blank: 'End time is required'
    },
    eventType: {
      inclusion: 'A category is required'
    },
    link: {
      invalidAddress: 'Link must be a valid URL'
    },
    location: {
      blank: 'A location is required',
      tooLong:
        'A location cannot be more than {count, plural, one {# character} other {# characters}}'
    },
    locationName: {
      tooLong:
        'A location name cannot be more than {count, plural, one {# character} other ' +
        '{# characters}}'
    },
    startTime: {
      blank: 'Start time is required'
    },
    title: {
      blank: 'A title is required',
      tooLong: 'A title cannot be more than {count, plural, one {# character} other {# characters}}'
    }
  },
  flag: {
    category: {
      blank: 'Flag category is required'
    },
    flagger: {
      taken: 'You have already flagged this content'
    }
  },
  genericFailure: "Sorry, that didn't work. Try reloading the page.",
  group: {
    description: {
      tooLong:
        'A description cannot be more than {count, plural, one {# character} other {# characters}}'
    },
    htmlNewsSource: {
      tooLong:
        'A source link cannot be more than {count, plural, one {# character} other {# characters}}'
    },
    htmlNewsSourceSelector: {
      tooLong:
        'A selector cannot be more than {count, plural, one {# character} other {# characters}}'
    },
    memberVerificationQuestion: {
      tooLong:
        'Your question cannot be more than {count, plural, one {# character} other {# characters}}'
    },
    name: {
      blank: 'Name is required',
      tooLong:
        'A group name cannot be more than {count, plural, one {# character} other {# characters}}'
    },
    navIcon: {
      default: "That image couldn't be uploaded, please try again"
    },
    requiredForPage: 'You need to select a group in order to view that page',
    rssNewsSource: {
      tooLong:
        'A source link cannot be more than {count, plural, one {# character} other {# characters}}'
    },
    slug: {
      blank: 'Group ID is required',
      invalid: 'Group ID cannot include special characters',
      taken: 'Someone is already using that ID. Try another!',
      tooLong:
        'Your group ID cannot be more than {count, plural, one {# character} other {# characters}}',
      tooShort:
        'Your group ID must be at least {count, plural, one {# character} other {# characters}}'
    },
    traits: {
      traitOptions: {
        title: {
          blank: 'Option title is required'
        }
      }
    },
    twitterHandleNewsSource: {
      tooLong:
        'A twitter handle cannot be more than {count, plural, one {# character} other {# characters}}'
    }
  },
  imageUpload: {
    default: 'Your upload failed. Please reload and try again, or contact support.',
    file: {
      fail: "We couldn't process that image. Please reload and try again, or contact support.",
      fileSizeIsLessThanOrEqualTo: 'That image is too large. Uploads must be less than {count}.'
    }
  },
  invite: {
    inviteeEmail: {
      badFormat: 'Email format is invalid',
      blank: 'Email is required',
      taken: 'You already sent an invitation to that email',
      tooLong:
        'An email cannot be more than {count, plural, one {# character} other {# characters}}'
    },
    inviterId: {
      taken: 'You already invited this address'
    },
    note: {
      blank: 'A note is required',
      tooLong: 'A note cannot be more than {count, plural, one {# character} other {# characters}}',
      tooShort: 'A note must be at least {count, plural, one {# character} other {# characters}}'
    }
  },
  inviteLookup: {
    inviteCode: {
      blank: 'You must enter an invite code to search for an invite',
      notFound: `We couldn't find an invite matching that code. Are you sure you typed it correctly?`
    }
  },
  jobApplication: {
    coverLetter: {
      blank: 'You must include a cover letter to apply',
      tooLong:
        'A cover letter cannot be more than {count, plural, one {# character} other ' +
        '{# characters}}',
      tooShort:
        'A cover letter must be at least {count, plural, one {# character} other {# characters}}'
    }
  },
  jobPost: {
    companyName: {
      blank: 'You must list the company hiring this position',
      tooLong:
        'A company name cannot be more than {count, plural, one {# character} other {# characters}}'
    },
    companyUrl: {
      tooLong:
        'A company URL cannot be more than {count, plural, one {# character} other {# characters}}'
    },
    description: {
      blank: 'A description is required',
      tooLong:
        'A description cannot be more than {count, plural, one {# character} other {# characters}}'
    },
    location: {
      tooLong:
        'A location cannot be more than {count, plural, one {# character} other {# characters}}'
    },
    title: {
      blank: 'A title is required',
      tooLong: 'A title cannot be more than {count, plural, one {# character} other {# characters}}'
    }
  },
  link: {
    label: {
      tooLong:
        'A link label cannot be more than {count, plural, one {# character} other {# characters}}'
    },
    uri: {
      blank: 'Link is required',
      invalid: 'Link format is invalid',
      tooLong: 'A link cannot be more than {count, plural, one {# character} other {# characters}}'
    }
  },
  login: {
    base: {
      failure: "That email and password combination didn't work. Please try again."
    },
    email: {
      blank: 'Email is required',
      invalid: 'Email format is invalid',
      taken: 'That email address is already being used'
    },
    emailAddresses: {
      invalid: 'Email format is invalid'
    },
    login: {
      incorrect: 'Login failed. Are you sure you typed your password correctly?'
    },
    password: {
      blank: 'Password is required'
    },
    passwordlessLoginCreation: {
      failed: 'Failed to create your login email. Please reload and try again, or contact support.'
    },
    username: {
      blank: 'Email is required'
    }
  },
  member: {
    approvedMemberRequired: 'You must have an approved membership to view that page.',
    bio: {
      tooLong:
        'Your bio cannot be more than {count, plural, one {# character} other {# characters}}'
    },
    headline: {
      tooLong:
        'Your headline cannot be more than {count, plural, one {# character} other {# characters}}'
    },
    relationships: {
      traitOptionSelections: {
        blank: 'You must choose at least one option for each required field'
      }
    },
    verificationAnswer: {
      blank: 'An answer is required',
      tooLong:
        'Your answer cannot be more than {count, plural, one {# character} other {# characters}}'
    }
  },
  message: {
    thread: {
      notes: {
        note: {
          blank: 'A note is required',
          tooLong:
            'A note cannot be more than {count, plural, one {# character} other {# characters}}'
        }
      }
    }
  },
  networkError: `It looks like you may be offline. If this error continues, please contact support.`,
  newsItem: {
    body: {
      tooLong:
        'Body text cannot be more than {count, plural, one {# character} other {# characters}}'
    },
    link: {
      invalidAddress: 'Link must be a valid URL',
      tooLong: 'A link cannot be more than {count, plural, one {# character} other {# characters}}'
    },
    title: {
      blank: 'A title is required',
      tooLong:
        'The title cannot be more than {count, plural, one {# character} other {# characters}}'
    }
  },
  note: {
    note: {
      blank: 'A note is required',
      tooLong: 'A note cannot be more than {count, plural, one {# character} other {# characters}}'
    }
  },
  pageNotFound: "The page you requested doesn't exist.",
  password: {
    base: {
      failure:
        'The reset password token you used is invalid or expired. Click the back arrow to try ' +
        'again.'
    },
    currentPassword: {
      blank: 'Your current password is required.',
      incorrect: "You've entered the wrong password."
    },
    password: {
      blank: 'A new password is required.',
      notPwned:
        'That password has appeared in a list of compromised passwords. Try something stronger.',
      tooShort:
        'Your password must be at least {count, plural, one {# character} other {# characters}}'
    },
    passwordConfirmation: {
      blank: 'Confirmation of your new password is required.',
      doesNotMatch: 'Password and confirmation must match.'
    }
  },
  plan: {
    failedToLoadInvoices:
      'Failed to load your billing history. Please reload and try again, or contact support.'
  },
  rule: {
    rule: {
      blank: 'A rule is required',
      tooLong: 'A rule cannot be more than {count, plural, one {# character} other {# characters}}',
      tooShort: 'A rule must be at least {count, plural, one {# character} other {# characters}}'
    }
  },
  serverError:
    `Sorry, something has gone wrong. We've been notified of the problem and are working to fix ` +
    `it.`,
  session: {
    expired: 'Your login has expired. Please log in again.'
  },
  subscriptionLocationFilter: {
    location: {
      blank: 'A location is required'
    }
  },
  trait: {
    title: {
      blank: 'A category name is required',
      taken: 'You already have a category with that name',
      tooLong:
        `A category's name cannot be more than {count, plural, one {# character} other ` +
        `{# characters}}`
    }
  },
  traitOption: {
    title: {
      blank: 'An option name is required',
      taken: "You've already added an option with that name",
      tooLong:
        `An option's name cannot be more than {count, plural, one {# character} other ` +
        `{# characters}}`
    }
  },
  unhandled: 'Something went wrong here. Please try reloading the page.',
  user: {
    acceptedTerms: {
      accepted: 'You must accept the terms and conditions to continue'
    },
    acceptedTransactionalEmail: {
      accepted: 'You must accept account emails to continue'
    },
    firstName: {
      blank: 'First name is required',
      tooLong:
        `Your first name cannot be more than {count, plural, one {# character} other ` +
        `{# characters}}`
    },
    hometown: {
      tooLong:
        'Your hometown cannot be more than {count, plural, one {# character} other {# characters}}'
    },
    image: {
      default: "That image couldn't be uploaded, please try again."
    },
    lastName: {
      blank: 'Last name is required',
      tooLong:
        `Your last name cannot be more than {count, plural, one {# character} other ` +
        `{# characters}}`
    },
    location: {
      tooLong:
        'Your location cannot be more than {count, plural, one {# character} other {# characters}}'
    },
    loginRequired: 'Whoops, you must be logged in to view that page.'
  },
  verification: {
    email: {
      invalid: 'Email format is invalid'
    }
  }
};

export default errors;
