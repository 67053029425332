import { produce } from 'immer';

import { LoadingAction, LoadingActionTypes } from '../actions/loadingActions';
import { State } from '../types';

const defaultState: State.Loading = { globalLoading: false, names: [] };

// eslint-disable-next-line @typescript-eslint/default-param-last
export const loadingReducer = (state = defaultState, action: LoadingActionTypes): State.Loading => {
  switch (action.type) {
    case LoadingAction.INCREMENT_GLOBAL:
      return produce(state, draft => {
        draft.names.push(action.payload.name);
        draft.globalLoading = true;
      });
    case LoadingAction.DECREMENT_GLOBAL: {
      const newList = ([] as string[]).concat(state.names);
      newList.splice(newList.indexOf(action.payload.name), 1);
      return produce(state, draft => {
        draft.names = newList;
        draft.globalLoading = newList.length > 0;
      });
    }
    default:
      return state;
  }
};
