import { useCallback, useEffect, useRef, useState } from 'react';

import useMountedTracking from '../hooks/useMountedTracking';
import useThunkDispatch from '../hooks/useThunkDispatch';
import useViewStatus, { ViewStatus } from '../hooks/useViewStatus';
import { forceArray } from '../services/arrayUtils';
import { Actions, JSONApi, Models } from '../types';

enum DispatchStatus {
  READY = 'ready',
  DISPATCHING = 'dispatching',
  DISPATCHED = 'dispatched'
}

const useApiData = <T extends Models.Base>(
  loadAction: Actions.ApiThunkAction<T> | undefined,
  selectData: () => JSONApi.Resource<T>[] | JSONApi.Resource<T> | null | undefined,
  identifier: string
) => {
  const dispatch = useThunkDispatch();
  const isMounted = useMountedTracking();
  const viewStatus = useViewStatus(identifier);
  const dispatchStatus = useRef<DispatchStatus>(DispatchStatus.READY);
  const [loading, setLoading] = useState(!!loadAction);
  const [responseData, setResponseData] = useState<JSONApi.Resource<T>[]>([]);

  const changeToDispatched = useCallback(() => {
    if (isMounted.current) {
      setLoading(false);
      if (viewStatus.everBeenOpen && viewStatus.status === ViewStatus.CLOSED) {
        dispatchStatus.current = DispatchStatus.READY;
      } else {
        dispatchStatus.current = DispatchStatus.DISPATCHED;
      }
    }
  }, [isMounted, viewStatus.everBeenOpen, viewStatus.status]);

  useEffect(() => {
    const record = selectData();
    if (record) {
      setResponseData(forceArray(record));
    }
  }, [selectData]);

  useEffect(() => {
    if (
      loadAction &&
      dispatchStatus.current === DispatchStatus.READY &&
      (!viewStatus.everBeenOpen || viewStatus.status === ViewStatus.OPEN)
    ) {
      dispatchStatus.current = DispatchStatus.DISPATCHING;
      dispatch(loadAction)
        .then(response => {
          response.dataLoadedIntoStatePromise
            .then(() => {
              changeToDispatched();
            })
            .catch(() => {
              changeToDispatched();
            });
        })
        .catch(() => {
          changeToDispatched();
        });
    }
  }, [
    changeToDispatched,
    dispatch,
    dispatchStatus,
    isMounted,
    loadAction,
    responseData,
    selectData,
    viewStatus
  ]);

  return { loading, responseData };
};

export default useApiData;
