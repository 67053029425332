import { ModelAttributes } from '../types';

export const getRandomHtmlId = () => `_${Math.random().toString(36).substr(2, 10)}`;

export const getMemberRoleColor = (role: ModelAttributes.MemberRole): 'dark' | 'medium' => {
  switch (role) {
    case ModelAttributes.MemberRole.MODERATOR:
      return 'dark';
    case ModelAttributes.MemberRole.OWNER:
      return 'dark';
    default:
      return 'medium';
  }
};

export const getMemberStatusColor = (
  status: ModelAttributes.MemberStatus
): 'danger' | 'success' | 'warning' => {
  switch (status) {
    case ModelAttributes.MemberStatus.ADMINISTRATOR_REJECTED:
      return 'danger';
    case ModelAttributes.MemberStatus.APPROVED:
      return 'success';
    case ModelAttributes.MemberStatus.AWAITING_MODERATION:
      return 'warning';
    case ModelAttributes.MemberStatus.DELETED:
      return 'danger';
    case ModelAttributes.MemberStatus.INCOMPLETE:
      return 'warning';
    case ModelAttributes.MemberStatus.MODERATOR_REJECTED:
      return 'danger';
    case ModelAttributes.MemberStatus.SELF_CLOSED:
      return 'danger';
    default:
      return 'warning';
  }
};
