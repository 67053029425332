import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import useThunkDispatch from '../hooks/useThunkDispatch';
import { getSaveForResource } from '../selectors';
import { dispatchAndAlert } from '../services/dispatchUtils';
import { createSave, deleteSave } from '../thunks/apiThunks';
import { JSONApi, State } from '../types';

const useSaveHandler = (
  resource: JSONApi.BaseResource,
  group: JSONApi.GroupResource,
  onSuccess?: () => void
) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const dispatch = useThunkDispatch();

  const save = getSaveForResource(apiData, resource);

  return useCallback(() => {
    if (save) {
      dispatchAndAlert(
        dispatch,
        deleteSave(group.attributes.slug, save),
        'forms.save.delete.success',
        'forms.save.delete.error',
        onSuccess
      );
    } else {
      dispatchAndAlert(
        dispatch,
        createSave(group.attributes.slug, resource),
        'forms.save.create.success',
        'forms.save.create.error',
        onSuccess
      );
    }
  }, [dispatch, group.attributes.slug, onSuccess, resource, save]);
};

export default useSaveHandler;
