import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import ConfirmDeleteModal from '../components/ConfirmDeleteModal';
import { Opener } from '../hooks/useOpener';
import { deletePost } from '../thunks/apiThunks';
import { JSONApi } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  opener: Opener;
  post: JSONApi.PostResource;
};

const DeletePostModal = ({ group, opener, post }: Props) => {
  const history = useHistory();

  const onSuccess = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <ConfirmDeleteModal
      action={deletePost(group.attributes.slug, post)}
      descriptionKey="modals.deletePost.explanation"
      errorAlertKey="forms.post.delete.error"
      onSuccess={onSuccess}
      opener={opener}
      successAlertKey="forms.post.delete.success"
      titleKey="dictionary.deletePost"
    />
  );
};

export default DeletePostModal;
