import { IonButton, IonButtons, IonItem, IonProgressBar } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import { WizardSteps } from '../components/CreateGroupPage';
import GroupRulesForm from '../components/forms/GroupRulesForm';
import Heading from '../components/Heading';
import Paragraph from '../components/Paragraph';
import { JSONApi } from '../types';

type Props = {
  group: JSONApi.GroupResource;
};

const SetupGroupRules = ({ group }: Props) => {
  const history = useHistory();

  const handleBackClick = useCallback(() => {
    history.push({ pathname: `/create/${group.attributes.slug}/${WizardSteps.VERIFICATION}` });
  }, [group.attributes.slug, history]);

  const handleNextClick = useCallback(() => {
    history.push({ pathname: `/create/${group.attributes.slug}/${WizardSteps.FEATURES}` });
  }, [group.attributes.slug, history]);

  return (
    <div className="setup-group-rules-form">
      <IonProgressBar buffer={0.4} color="success" value={0} />
      <Heading level={2}>
        <FormattedMessage id="pages.groupWizard.rules.title" />
      </Heading>
      <Paragraph>
        <FormattedMessage id="pages.groupWizard.rules.description" />
      </Paragraph>
      <GroupRulesForm group={group} />
      <IonItem color="transparent" lines="none">
        <IonButtons slot="end">
          <IonButton color="primary" fill="outline" onClick={handleBackClick} size="default">
            <FormattedMessage id="dictionary.previous" />
          </IonButton>
          <IonButton color="primary" fill="solid" onClick={handleNextClick} size="default">
            <FormattedMessage id="dictionary.next" />
          </IonButton>
        </IonButtons>
      </IonItem>
    </div>
  );
};

export default SetupGroupRules;
