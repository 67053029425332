import { IonButtons, IonItem, IonLabel, IonList, IonText } from '@ionic/react';
import { FormApi } from 'final-form';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import * as Yup from 'yup';

import choosePasswordImage from '../assets/images/design/choose-password.png';
import Form from '../components/forms/Form';
import FormContainer from '../components/forms/FormContainer';
import FormError from '../components/forms/FormError';
import FormInput from '../components/forms/FormInput';
import FormSubmit from '../components/forms/FormSubmit';
import RequiredFieldMarker from '../components/forms/RequiredFieldMarker';
import SubmitError from '../components/forms/SubmitError';
import Heading from '../components/Heading';
import Img from '../components/Img';
import LocalLoadingIndicator from '../components/LocalLoadingIndicator';
import SetupPage from '../components/SetupPage';
import { minPasswordLength } from '../globals';
import useMountedTracking from '../hooks/useMountedTracking';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { getRelatedResourcesFromIncluded } from '../selectors';
import { AjaxError } from '../services/ajaxRequest';
import { forceArray } from '../services/arrayUtils';
import { handleFormSubmission, handleSubmissionError } from '../services/formUtils';
import { getErrorMessages } from '../services/jsonApiUtils';
import { updateUser } from '../thunks/apiThunks';
import { login } from '../thunks/sessionThunks';
import { JSONApi, Models } from '../types';

interface FormValues {
  password: string;
  passwordConfirmation: string;
}
type Props = {
  group?: JSONApi.GroupResource;
};

const ResetPasswordPage = ({ group }: Props) => {
  const dispatch = useThunkDispatch();
  const [errors, setErrors] = useState<string[] | undefined>(undefined);
  const history = useHistory();
  const intl = useIntl();
  const isMounted = useMountedTracking();
  const { token } = useParams<{ token: string }>();
  const [user, setUser] = useState<JSONApi.UserResource | null | undefined>(undefined);

  const initialValues: FormValues = {
    password: '',
    passwordConfirmation: ''
  };

  useEffect(() => {
    dispatch(login({ passwordResetToken: token }))
      .then(response => {
        if (isMounted.current) {
          const users = getRelatedResourcesFromIncluded<Models.Session, Models.User>(
            forceArray(response.data)[0],
            response.included,
            'user'
          );
          setUser(forceArray(users)[0]);
        }
      })
      .catch((error: AjaxError) => {
        handleSubmissionError({
          dispatch,
          error,
          handleInvalid: errors => {
            const messages = getErrorMessages(intl, errors, 'resetPassword');
            setErrors(messages);
            setUser(null);
          },
          isMounted
        });
      });
  }, [dispatch, intl, isMounted, token]);

  const handleSubmit = useCallback(
    async (values: FormValues, form: FormApi<FormValues>) => {
      if (user) {
        return handleFormSubmission({
          action: updateUser({ ...values, passwordResetToken: token }, user.id),
          dispatch,
          errorTranslationLocation: 'password',
          form,
          intl,
          isMounted,
          onSuccess: () => {
            // currently you can only reset your password during the enter group or create group process
            if (group) {
              history.push(`/g/${group.attributes.slug}`);
            } else {
              history.push('/');
            }
          },
          successTKey: 'forms.password.update.success',
          values
        });
      }
    },
    [dispatch, group, history, intl, isMounted, token, user]
  );

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(intl.formatMessage({ id: 'errors.password.password.blank' }))
      .min(
        minPasswordLength,
        intl.formatMessage(
          { id: 'errors.password.password.tooShort' },
          { count: minPasswordLength }
        )
      ),
    passwordConfirmation: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: 'errors.password.passwordConfirmation.blank' }))
      .oneOf(
        [Yup.ref('password'), ''],
        intl.formatMessage({ id: 'errors.password.passwordConfirmation.doesNotMatch' })
      )
  });

  return (
    <SetupPage titleKey="pages.resetPassword.title">
      {user === undefined && <LocalLoadingIndicator />}
      {errors && errors.length > 0 && (
        <IonList>
          {errors.map(error => (
            <IonItem key={error} lines="none">
              <IonText color="danger">{error}</IonText>
            </IonItem>
          ))}
        </IonList>
      )}
      {user && (
        <>
          <Img
            alt={intl.formatMessage({ id: 'pages.resetPassword.imageAlt' })}
            src={choosePasswordImage}
          />
          <Heading className="ion-padding" level={2}>
            <FormattedMessage id="pages.resetPassword.h2" />
          </Heading>
          <FormContainer<FormValues>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ form, handleSubmit }) => (
              <Form<FormValues>
                className="reset-password-form"
                onFormSubmit={handleSubmit}
                submit={form.submit}
              >
                <SubmitError />
                <IonLabel color="primary">
                  <FormattedMessage id="models.user.attributes.password.label" />
                  <RequiredFieldMarker />
                </IonLabel>
                <FormError allowBlank name="password" />
                <FormInput autocapitalize="off" autofocus name="password" type="password" />
                <IonLabel color="primary">
                  <FormattedMessage id="models.user.attributes.passwordConfirmation.label" />
                  <RequiredFieldMarker />
                </IonLabel>
                <FormError allowBlank name="passwordConfirmation" />
                <FormInput autocapitalize="off" name="passwordConfirmation" type="password" />
                <IonItem color="transparent" lines="none">
                  <IonButtons slot="end">
                    <FormSubmit color="primary" fill="solid" size="default">
                      <FormattedMessage id="dictionary.save" />
                    </FormSubmit>
                  </IonButtons>
                </IonItem>
              </Form>
            )}
          </FormContainer>
        </>
      )}
    </SetupPage>
  );
};

export default ResetPasswordPage;
