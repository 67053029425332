import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import useActionCableChannel from '../hooks/useActionCableChannel';
import useCurrentGroup from '../hooks/useCurrentGroup';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { clearRequestCacheOfResourceOnUpdate } from '../services/broadcastUtils';
import { loadUser } from '../thunks/apiThunks';
import { JSONApi, Models, State } from '../types';

// This subscribes to globally-applicable broadcast updates and uses them to update the state
// when data is received.
const BroadcastUpdateManager = () => {
  const currentGroup = useCurrentGroup();
  const currentUserId = useSelector((root: State.Root) => root.session.currentUser.id);
  const dispatch = useThunkDispatch();

  const handleMemberUpdate = useCallback(
    (update: JSONApi.Response<Models.BroadcastUpdate>) => {
      clearRequestCacheOfResourceOnUpdate(update, dispatch);
      if (currentUserId) {
        dispatch(loadUser(currentUserId));
      }
    },
    [currentUserId, dispatch]
  );

  useActionCableChannel({
    channelName: { channel: 'GroupPostsChannel', groupId: currentGroup?.id },
    onReceived: (update: JSONApi.Response<Models.BroadcastUpdate>) => {
      clearRequestCacheOfResourceOnUpdate(update, dispatch);
    }
  });

  useActionCableChannel({
    channelName: { channel: 'MemberNotesChannel', groupId: currentGroup?.id },
    onReceived: (update: JSONApi.Response<Models.BroadcastUpdate>) => {
      clearRequestCacheOfResourceOnUpdate(update, dispatch);
    }
  });

  useActionCableChannel({
    channelName: { channel: 'UserMembersChannel' },
    onReceived: handleMemberUpdate
  });

  return null;
};

export default BroadcastUpdateManager;
