import React, { useState } from 'react';

import { setForumSearchParams } from '../actions/searchActions';
import SearchQuery from '../components/SearchQuery';
import useSearchParamsFromUrl from '../hooks/useSearchParamsFromUrl';
import { defaultPostSearchParams } from '../services/searchUtils';
import { Search } from '../types';

import '../components/forms/CustomForm.scss';
import './Searchbox.scss';

type Props = {
  isOpen: boolean;
};

const ForumSearchForm = ({ isOpen }: Props) => {
  const [searchParams, setSearchParams] = useState<Search.PostParams>(defaultPostSearchParams());

  const searchFields: (keyof Search.Params)[] = ['query'];

  useSearchParamsFromUrl<Search.PostParams>(
    searchFields,
    setSearchParams,
    searchParams,
    setForumSearchParams
  );

  if (isOpen) {
    return (
      <div className="custom-form-wrapper ion-padding searchbox">
        <SearchQuery<Search.PostParams>
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      </div>
    );
  }
  return null;
};

export default ForumSearchForm;
