import { ActionSheetButton, IonActionSheet } from '@ionic/react';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import BlockFormModal from '../components/BlockFormModal';
import FlagFormModal from '../components/FlagFormModal';
import useFollowHandler from '../hooks/useFollowHandler';
import useOpener from '../hooks/useOpener';
import useRsvpHandler from '../hooks/useRsvpHandler';
import useSaveHandler from '../hooks/useSaveHandler';
import { getPostFollow, getPostViewerRsvp, getSaveForResource } from '../selectors';
import { JSONApi, ModelAttributes, State } from '../types';

type Props = {
  close: () => void;
  group: JSONApi.GroupResource;
  isOpen: boolean;
  onEditClick?: () => void;
  post: JSONApi.PostResource;
};

const PostActionSheet = (props: Props) => {
  const { close, group, isOpen, onEditClick, post } = props;

  const apiData = useSelector((root: State.Root) => root.api);
  const blockModalOpener = useOpener();
  const intl = useIntl();
  const flagModalOpener = useOpener();

  const follow = getPostFollow(apiData, post);
  // technically only events can have RSVPs, but that just means you'll get null for non-events
  const rsvp = getPostViewerRsvp(apiData, post);
  const save = getSaveForResource(apiData, post);

  const handleEditClick = useCallback(() => {
    if (onEditClick) {
      onEditClick();
      close();
    }
  }, [close, onEditClick]);

  const handleClickFollow = useFollowHandler(post, group, () => close());

  const handleClickRsvp = useRsvpHandler(post as JSONApi.EventResource, group, () => close());

  const handleClickSave = useSaveHandler(post, group, () => close());

  const handleClickBlock = useCallback(() => {
    blockModalOpener.open();
    close();
  }, [blockModalOpener, close]);

  const handleClickFlag = useCallback(() => {
    flagModalOpener.open();
    close();
  }, [close, flagModalOpener]);

  const actionSheetButtons: ActionSheetButton[] = [];

  if (onEditClick) {
    actionSheetButtons.push({
      handler: handleEditClick,
      text: intl.formatMessage({ id: 'pages.post.edit' })
    });
  }

  if (
    post.type === ModelAttributes.PostType.EVENT &&
    (post as JSONApi.EventResource).attributes.enableRsvps
  ) {
    actionSheetButtons.push({
      handler: handleClickRsvp,
      text: intl.formatMessage({ id: rsvp ? 'pages.event.removeRsvp' : 'pages.event.rsvp' })
    });
  }

  actionSheetButtons.push({
    handler: handleClickFollow,
    text: intl.formatMessage({ id: follow ? 'pages.post.unfollow' : 'pages.post.follow' })
  });

  actionSheetButtons.push({
    handler: handleClickSave,
    text: intl.formatMessage({ id: save ? 'forms.save.unsave' : 'forms.save.save' })
  });

  if (post.attributes.permissions.flag) {
    actionSheetButtons.push({
      handler: handleClickFlag,
      text: intl.formatMessage({ id: 'modals.newFlag.title' })
    });
  }

  if (post.attributes.permissions.block) {
    actionSheetButtons.push({
      handler: handleClickBlock,
      text: intl.formatMessage({ id: 'modals.newBlock.title.content' })
    });
  }

  actionSheetButtons.push({
    handler: close,
    role: 'cancel',
    text: intl.formatMessage({ id: 'dictionary.close' })
  });

  return (
    <>
      <IonActionSheet buttons={actionSheetButtons} isOpen={isOpen} onDidDismiss={close} />
      <BlockFormModal group={group} opener={blockModalOpener} record={post} />
      <FlagFormModal group={group} opener={flagModalOpener} record={post} />
    </>
  );
};

export default PostActionSheet;
