import { IonButton, IonContent, IonFooter, IonIcon, IonList, IonText } from '@ionic/react';
import { ellipsisHorizontal } from 'ionicons/icons';
import React, { createRef } from 'react';
import { useIntl } from 'react-intl';

import { apiClearCachedResponsesContainingResource } from '../actions/apiActions';
import ChatBubble from '../components/ChatBubble';
import ConversationActions from '../components/ConversationActions';
import ConversationMetaNote from '../components/ConversationMetaNote';
import NoteForm from '../components/forms/NoteForm';
import Page from '../components/layout/Page';
import PageHeader from '../components/layout/PageHeader';
import PageTitle from '../components/layout/PageTitle';
import NoteBubble from '../components/NoteBubble';
import useActionCableChannel from '../hooks/useActionCableChannel';
import useConversation from '../hooks/useConversation';
import useOpener from '../hooks/useOpener';
import usePageVisitTracker from '../hooks/usePageVisitTracker';
import useScrollToBottom from '../hooks/useScrollToBottom';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { loadConversation } from '../thunks/apiThunks';
import { JSONApi, Models } from '../types';

import './ConversationPage.scss';

type Props = {
  conversation: JSONApi.ConversationResource;
  group: JSONApi.GroupResource;
};

const ConversationPage = ({ conversation, group }: Props) => {
  const { attachments, counterparty, creator, notes } = useConversation(conversation);
  const actionSheetOpener = useOpener();
  const contentRef = createRef<HTMLIonContentElement>();
  const intl = useIntl();
  const scrollToBottom = useScrollToBottom(contentRef);
  const dispatch = useThunkDispatch();

  usePageVisitTracker(group, Models.Readable.CONVERSATION);

  useActionCableChannel({
    channelName: {
      channel: 'ConversationNotesChannel',
      conversationId: conversation.id,
      groupId: group.id
    },
    onReceived: () => {
      dispatch(apiClearCachedResponsesContainingResource(conversation.id, conversation.type));
      dispatch(loadConversation(group.attributes.slug, conversation.attributes.slug));
    }
  });

  const actions = conversation.attributes.permissions.block ? (
    <IonButton
      aria-label={intl.formatMessage({ id: 'dictionary.options' })}
      onClick={actionSheetOpener.toggle}
    >
      <IonIcon icon={ellipsisHorizontal} />
    </IonButton>
  ) : undefined;

  return (
    <Page className="centered conversation-page wide">
      <PageHeader buttonsRight={actions} defaultBackHref={`/g/${group.attributes.slug}/inbox`} />
      <PageTitle
        id="pages.conversation.title"
        values={{ counterparty: counterparty?.attributes.fullName }}
      />
      <IonContent className="canvas" ref={contentRef}>
        <ConversationActions
          actionSheetOpener={actionSheetOpener}
          conversation={conversation}
          group={group}
        />
        <ConversationMetaNote conversation={conversation} group={group} />
        <IonList className="bubble-canvas">
          {conversation.attributes.coverLetter && (
            <div className="bubble-canvas">
              <ChatBubble
                attachments={attachments}
                color={creator === counterparty ? 'light' : 'dark'}
                group={group}
                member={creator}
                timestamp={conversation.attributes.deliveredAt}
              >
                <IonText className="formatted-text">{conversation.attributes.coverLetter}</IonText>
              </ChatBubble>
            </div>
          )}
          {notes.map(note => (
            <div className="bubble-canvas" key={note.id}>
              <NoteBubble counterparty={counterparty} group={group} note={note} />
            </div>
          ))}
        </IonList>
      </IonContent>
      <IonFooter className="add-to-thread ion-padding-end ion-padding-start">
        <NoteForm
          conversation={conversation}
          counterparty={counterparty}
          group={group}
          onFocus={scrollToBottom}
        />
      </IonFooter>
    </Page>
  );
};

export default ConversationPage;
