import { IonBadge, IonCard, IonCardContent } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import GroupAvatar from '../components/GroupAvatar';
import Heading from '../components/Heading';
import Paragraph from '../components/Paragraph';
import { truncateString } from '../services/stringUtils';
import { JSONApi } from '../types';

import './GriddedCard.scss';

const GroupCard = ({
  forwardRef,
  group,
  ...ionCardProps
}: React.ComponentPropsWithoutRef<typeof IonCard> & {
  forwardRef?: React.RefObject<HTMLIonCardElement>;
  group: JSONApi.GroupResource;
}) => {
  const history = useHistory();
  const link = `/g/${group.attributes.slug}`;

  const handleClick = useCallback(() => {
    history.push(link);
  }, [history, link]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLIonCardElement>) => {
      if (event.key === 'Enter') {
        history.push(link);
      }
    },
    [history, link]
  );

  // adding the button and routerLink props to this components will mess with the arrow key
  // handling that manages focus across these components by adding a native `<button>` into
  // the shadow dom. to avoid that, we have to handle the routing manually using handlers.
  return (
    <IonCard
      {...ionCardProps}
      className="ion-activatable gridded-card group-card"
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      ref={forwardRef}
      role="button"
      tabIndex={0}
    >
      <IonCardContent>
        <GroupAvatar group={group} slot="start" />
        <div className="details">
          <Heading level={2}>{group.attributes.name}</Heading>
          {group.attributes.description && (
            <Paragraph>{truncateString(group.attributes.description, 120)}</Paragraph>
          )}
          <div>
            {group.attributes.memberVerificationRequired ? (
              <IonBadge color="warning" slot="end">
                <FormattedMessage id="dictionary.private" />
              </IonBadge>
            ) : (
              <IonBadge color="success" slot="end">
                <FormattedMessage id="dictionary.public" />
              </IonBadge>
            )}
            <IonBadge color="primary" slot="end">
              <FormattedMessage
                id="models.group.nMembers"
                values={{ count: group.attributes.memberCount }}
              />
            </IonBadge>
          </div>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default GroupCard;
