import { produce } from 'immer';

import { NotificationAction, NotificationActionTypes } from '../actions/notificationActions';
import { State } from '../types';

const defaultState: State.NotificationList = {
  dismissedNotifications: [],
  notifications: []
};

export const notificationsReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = defaultState,
  action: NotificationActionTypes
): State.NotificationList => {
  switch (action.type) {
    case NotificationAction.ADD_NOTIFICATION:
      return produce(state, draft => {
        draft.notifications = draft.notifications.concat(action.payload.notification);
      });
    case NotificationAction.CLEAR_NOTIFICATIONS:
      return produce(state, draft => {
        draft.notifications = [];
      });
    case NotificationAction.DISMISS_NOTIFICATION:
      return produce(state, draft => {
        draft.dismissedNotifications = draft.dismissedNotifications.concat(action.payload.id);
      });
    default:
      return state;
  }
};
