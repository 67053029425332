enum ApiEndpoint {
  ADMIN_APPROVE_ATTACHMENT = 'ADMIN_APPROVE_ATTACHMENT',
  ADMIN_APPROVE_IMAGE = 'ADMIN_APPROVE_IMAGE',
  ADMIN_LOAD_ATTACHMENTS = 'ADMIN_LOAD_ATTACHMENTS',
  ADMIN_LOAD_IMAGES = 'ADMIN_LOAD_IMAGES',
  APPROVE_DISCUSSION = 'APPROVE_DISCUSSION',
  APPROVE_EVENT = 'APPROVE_EVENT',
  APPROVE_GROUP = 'APPROVE_GROUP',
  APPROVE_JOB_POST = 'APPROVE_JOB_POST',
  APPROVE_MEMBER = 'APPROVE_MEMBER',
  APPROVE_NEWS_ITEM = 'APPROVE_NEWS_ITEM',
  CANCEL_PLAN = 'CANCEL_PLAN',
  CLOSE_MEMBER = 'CLOSE_MEMBER',
  CONVERSATION_SEARCH = 'CONVERSATION_SEARCH',
  CREATE_ATTACHMENT = 'CREATE_ATTACHMENT',
  CREATE_BLOCK = 'CREATE_BLOCK',
  CREATE_COMMENT = 'CREATE_COMMENT',
  CREATE_DISCUSSION = 'CREATE_DISCUSSION',
  CREATE_EMAIL_CONFIRMATION = 'CREATE_EMAIL_CONFIRMATION',
  CREATE_EMAIL_ADDRESS = 'CREATE_EMAIL_ADDRESS',
  CREATE_EVENT = 'CREATE_EVENT',
  CREATE_FLAG = 'CREATE_FLAG',
  CREATE_FOLLOW = 'CREATE_FOLLOW',
  CREATE_FREE_PLAN_CHECKOUT_SESSION = 'CREATE_FREE_PLAN_CHECKOUT_SESSION',
  CREATE_GROUP = 'CREATE_GROUP',
  CREATE_IMAGE = 'CREATE_IMAGE',
  CREATE_INITIAL_CHECKOUT_SESSION = 'CREATE_INITIAL_CHECKOUT_SESSION',
  CREATE_INVITES = 'CREATE_INVITES',
  CREATE_JOB_APPLICATION = 'CREATE_JOB_APPLICATION',
  CREATE_JOB_POST = 'CREATE_JOB_POST',
  CREATE_LINK = 'CREATE_LINK',
  CREATE_MEMBER = 'CREATE_MEMBER',
  CREATE_MESSAGE = 'CREATE_MESSAGE',
  CREATE_NEWS_ITEM = 'CREATE_NEWS_ITEM',
  CREATE_NEWSLETTER_LOCATION_FILTER = 'CREATE_NEWSLETTER_LOCATION_FILTER',
  CREATE_NOTE = 'CREATE_NOTE',
  CREATE_PASSWORD_RESET = 'CREATE_PASSWORD_RESET',
  CREATE_PAYMENT_INFO_UPDATE_CHECKOUT_SESSION = 'CREATE_PAYMENT_INFO_UPDATE_CHECKOUT_SESSION',
  CREATE_PLAN_CHANGE = 'CREATE_PLAN_CHANGE',
  CREATE_PLAN_CHANGE_PREVIEW = 'CREATE_PLAN_CHANGE_PREVIEW',
  CREATE_PUSH_NOTIFICATION_TOKEN = 'CREATE_PUSH_NOTIFICATION_TOKEN',
  CREATE_REACTION = 'CREATE_REACTION',
  CREATE_RSVP = 'CREATE_RSVP',
  CREATE_RULE = 'CREATE_RULE',
  CREATE_SAVE = 'CREATE_SAVE',
  CREATE_TRAIT = 'CREATE_TRAIT',
  CREATE_TRAIT_OPTION = 'CREATE_TRAIT_OPTION',
  CREATE_USER = 'CREATE_USER',
  CREATE_WELCOME = 'CREATE_WELCOME',
  DELETE_ATTACHMENT = 'DELETE_ATTACHMENT',
  DELETE_BLOCK = 'DELETE_BLOCK',
  DELETE_COMMENT = 'DELETE_COMMENT',
  DELETE_EMAIL_ADDRESS = 'DELETE_EMAIL_ADDRESS',
  DELETE_FOLLOW = 'DELETE_FOLLOW',
  DELETE_IMAGE = 'DELETE_IMAGE',
  DELETE_LINK = 'DELETE_LINK',
  DELETE_MEMBER = 'DELETE_MEMBER',
  DELETE_NEWSLETTER_LOCATION_FILTER = 'DELETE_NEWSLETTER_LOCATION_FILTER',
  DELETE_POST = 'DELETE_POST',
  DELETE_REACTION = 'DELETE_REACTION',
  DELETE_RSVP = 'DELETE_RSVP',
  DELETE_RULE = 'DELETE_RULE',
  DELETE_SAVE = 'DELETE_SAVE',
  DELETE_TRAIT = 'DELETE_TRAIT',
  DELETE_TRAIT_OPTION = 'DELETE_TRAIT_OPTION',
  DELETE_USER = 'DELETE_USER',
  DISCUSSION_SEARCH = 'DISCUSSION_SEARCH',
  EMAIL_LOOKUP = 'EMAIL_LOOKUP',
  EVENT_SEARCH = 'EVENT_SEARCH',
  EXTEND_SESSION = 'EXTEND_SESSION',
  GROUP_SEARCH = 'GROUP_SEARCH',
  IGNORE_FLAGGED_ITEM = 'IGNORE_FLAGGED_ITEM',
  IMAGE_SEARCH = 'IMAGE_SEARCH',
  INVITE_LOOKUP = 'INVITE_LOOKUP',
  INVITE_SEARCH = 'INVITE_SEARCH',
  JOB_POST_SEARCH = 'JOB_POST_SEARCH',
  JOIN = 'JOIN',
  LOAD_BLOCKS = 'LOAD_BLOCKS',
  LOAD_COMMENT = 'LOAD_COMMENT',
  LOAD_COMMENTS = 'LOAD_COMMENTS',
  LOAD_CONVERSATION = 'LOAD_CONVERSATION',
  LOAD_CONVERSATIONS = 'LOAD_CONVERSATIONS',
  LOAD_DISCUSSION = 'LOAD_DISCUSSION',
  LOAD_EVENT = 'LOAD_EVENT',
  LOAD_FLAGGED_ITEMS = 'LOAD_FLAGGED_ITEMS',
  LOAD_FLAGS = 'LOAD_FLAGS',
  LOAD_GROUP = 'LOAD_GROUP',
  LOAD_INVITES = 'LOAD_INVITES',
  LOAD_INVOICES = 'LOAD_INVOICES',
  LOAD_JOB_POST = 'LOAD_JOB_POST',
  LOAD_MEMBER = 'LOAD_MEMBER',
  LOAD_MENTIONS = 'LOAD_MENTIONS',
  LOAD_NEWS_ITEM = 'LOAD_NEWS_ITEM',
  LOAD_NEWSLETTER_LOCATION_FILTERS = 'LOAD_NEWSLETTER_LOCATION_FILTERS',
  LOAD_NEWSLETTER_SUBSCRIPTIONS = 'LOAD_NEWSLETTER_SUBSCRIPTIONS',
  LOAD_POST = 'LOAD_POST',
  LOAD_PRICE_TIERS = 'LOAD_PRICE_TIERS',
  LOAD_RULES = 'LOAD_RULES',
  LOAD_SAVES = 'LOAD_SAVES',
  LOAD_SESSION = 'LOAD_SESSION',
  LOAD_UNREAD_COUNTS = 'LOAD_UNREAD_COUNTS',
  LOAD_USER = 'LOAD_USER',
  LOAD_WHITELISTED_DOMAINS = 'LOAD_WHITELISTED_DOMAINS',
  LOGIN = 'LOGIN',
  MEMBER_SEARCH = 'MEMBER_SEARCH',
  NEWS_ITEM_SEARCH = 'NEWS_ITEM_SEARCH',
  OPEN_MEMBER = 'OPEN_MEMBER',
  POST_SEARCH = 'POST_SEARCH',
  PROMOTE_EMAIL_ADDRESS = 'PROMOTE_EMAIL_ADDRESS',
  REACTIVATE_PLAN = 'REACTIVATE_PLAN',
  REJECT_DISCUSSION = 'REJECT_DISCUSSION',
  REJECT_EVENT = 'REJECT_EVENT',
  REJECT_FLAGGED_ITEM = 'REJECT_FLAGGED_ITEM',
  REJECT_GROUP = 'REJECT_GROUP',
  REJECT_JOB_POST = 'REJECT_JOB_POST',
  REJECT_MEMBER = 'REJECT_MEMBER',
  REJECT_NEWS_ITEM = 'REJECT_NEWS_ITEM',
  REVOKE_INVITE = 'REVOKE_INVITE',
  UPDATE_COMMENT = 'UPDATE_COMMENT',
  UPDATE_DISCUSSION = 'UPDATE_DISCUSSION',
  UPDATE_EVENT = 'UPDATE_EVENT',
  UPDATE_FLAG = 'UPDATE_FLAG',
  UPDATE_GROUP = 'UPDATE_GROUP',
  UPDATE_JOB_POST = 'UPDATE_JOB_POST',
  UPDATE_MEMBER = 'UPDATE_MEMBER',
  UPDATE_NEWS_ITEM = 'UPDATE_NEWS_ITEM',
  UPDATE_NEWSLETTER_SUBSCRIPTION = 'UPDATE_NEWSLETTER_SUBSCRIPTION',
  UPDATE_POST = 'UPDATE_POST',
  UPDATE_TRAIT = 'UPDATE_TRAIT',
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_UNREAD_COUNT = 'UPDATE_UNREAD_COUNT'
}

export default ApiEndpoint;
