import { DateTime } from 'luxon';

import {
  BroadcastUpdateAction,
  CommentStatus,
  ConversationType,
  EventType,
  FlagCategory,
  FlagStatus,
  GroupStatus,
  Industry,
  InviteStatus,
  JobType,
  MemberRole,
  MemberStatus,
  PlanPeriod,
  PlanStatus,
  PostStatus,
  ReactionEmotion,
  SubscriptionFrequency
} from '../types/ModelAttributes';
import { FacetCollection } from '../types/Search';

export interface Base {
  id?: string;
}

export interface Blockable extends Base {
  permissions: {
    block: boolean;
  };
}

export interface UserGeneratedContent extends Blockable {
  permissions: {
    block: boolean;
    flag: boolean;
  };
}

export enum Readable {
  CONVERSATION = 'Conversation',
  DISCUSSION = 'Discussion',
  EVENT = 'Event',
  IMAGE = 'Image',
  JOB_POST = 'JobPost',
  MEMBER = 'Member',
  NEWS_ITEM = 'NewsItem'
}

// file is not returned from the api, but is used in form submission
export interface Attachment extends Base {
  adminApproved: boolean;
  createdAt: DateTime;
  file?: File;
  filename: string;
  isImage: string;
  url: string;
}

export type CreateAttachment = Required<Pick<Attachment, 'file'>>;

// blockedId and blockedType are not returned from the api but are used in form submission
// note that this is technically a jsonapi violation and we should be using related resources
export interface Block extends Base {
  blockedId?: string;
  blockedType?: string;
  createdAt: DateTime;
}

export type CreateBlock = Required<Pick<Block, 'blockedId' | 'blockedType'>>;

export interface BroadcastUpdate extends Base {
  action: BroadcastUpdateAction;
}

export interface ReactionCounts extends Record<ReactionEmotion | 'total', number> {
  Confused: number;
  Dislike: number;
  Haha: number;
  Like: number;
  total: number;
}

export interface Comment extends UserGeneratedContent {
  content: string;
  createdAt: DateTime;
  permissions: {
    block: boolean;
    edit: boolean;
    flag: boolean;
  };
  reactionCounts: ReactionCounts;
  slug: string;
  status: CommentStatus;
}

export type CreateComment = Pick<Comment, 'content'>;

export type UpdateComment = {
  content?: string;
  status?: CommentStatus;
};

// recipientId is not returned from the api but is used in form submission
// note that this is technically a jsonapi violation and we should be using related resources
export interface Conversation extends Blockable {
  coverLetter?: string;
  createdAt: DateTime;
  deliveredAt: DateTime;
  draft: boolean;
  mostRecentActivityAt: DateTime;
  permissions: {
    block: boolean;
  };
  recipientId?: string;
  slug: string;
  state?: string;
  threadType: ConversationType;
  unread: boolean;
}

export type CreateConversation = Required<Pick<Conversation, 'recipientId'>>;

export interface Flag extends Base {
  category: FlagCategory;
  createdAt: DateTime;
  report: string;
  status: FlagStatus;
}

export type CreateFlag = Pick<Flag, 'category' | 'report'> & {
  flaggedId: string;
  flaggedType: string;
};

export type UpdateFlag = {
  status: FlagStatus;
};

export interface FlaggedItem extends Base {
  mostRecentFlagAt: DateTime;
  status: FlagStatus;
}

// postId is not returned from the api but is used in form submission
// note that this is technically a jsonapi violation and we should be using related resources
export interface Follow extends Base {
  postId?: string;
}

export type FollowFormData = Required<Pick<Follow, 'postId'>>;

export interface Save extends Base {
  createdAt: DateTime;
}

export type CreateSave = Base;

export interface Group extends Base {
  bgImageUrl?: string;
  careersEnabled: boolean;
  description: string;
  eventsEnabled: boolean;
  forumEnabled: boolean;
  galleryEnabled: boolean;
  hasInvites: boolean;
  htmlNewsSource: string;
  htmlNewsSourceSelector: string;
  isAtMaxCapacity: boolean;
  location: string;
  locationFeaturesEnabled: boolean;
  memberCap: number;
  memberCount: number;
  memberVerificationQuestion: string;
  memberVerificationRequired: boolean;
  membersCanSendInvites: boolean;
  name: string;
  navIconUrl?: string;
  newsEnabled: boolean;
  planActive: boolean;
  planCancellationEffective?: DateTime;
  planCancellationRequested: boolean;
  planCostPerPeriodInCents: number;
  planCurrentPeriodEnd: DateTime;
  planFree: boolean;
  planPeriod: PlanPeriod;
  planStatus: PlanStatus;
  primaryColor?: string;
  primaryContrastColor?: string;
  readOnly: boolean;
  rssNewsSource: string;
  secondaryColor?: string;
  secondaryContrastColor?: string;
  slug: string;
  status: GroupStatus;
  twitterHandleNewsSource: string;
  usersCanCreateNews: boolean;
}

export type CreateGroup = Pick<Group, 'description' | 'name' | 'slug'>;

export type UpdateGroup = {
  bgImage?: string; // base64 encoded file
  careersEnabled?: boolean;
  description?: string;
  eventsEnabled?: boolean;
  forumEnabled?: boolean;
  htmlNewsSource?: string;
  htmlNewsSourceSelector?: string;
  locationFeaturesEnabled?: boolean;
  memberVerificationQuestion?: string;
  memberVerificationRequired?: boolean;
  membersCanSendInvites?: boolean;
  name?: string;
  navIcon?: string; // base64 encoded file
  newsEnabled?: boolean;
  pinnedPostId?: string | null;
  primaryColor?: string;
  rssNewsSource?: string;
  slug?: string;
  twitterHandleNewsSource?: string;
  usersCanCreateNews?: boolean;
};

export interface PushNotificationToken extends Base {
  platform: string;
  token: string;
}

export interface Rule extends Base {
  position: number;
  rule: string;
}

export type UpdateRule = Pick<Rule, 'rule' | 'id'>;

export type RuleFormData = Pick<Rule, 'rule'>;

export interface Trait extends Base {
  position: number;
  required: boolean;
  title: string;
}

export type TraitFormData = Pick<Trait, 'required' | 'title'>;

export interface TraitOption extends Base {
  position: number;
  title: string;
}

export type TraitOptionFormData = Pick<TraitOption, 'title'>;

export type TraitOptionSelection = Base;

export type UpdateTraitOptionSelection = {
  id: string;
  traitId: string;
  traitOptionId: string;
};

export type TraitOptionSelectionFormData = {
  traitId: string;
  traitOptionIds: string[];
};

export interface CheckoutSession extends Base {
  sessionId?: string;
  skipCheckout: boolean;
}

export interface PlanChangePreview extends Base {
  balanceRemainingInCents: number;
  currentMemberCap: number;
  immediateCostInCents: number;
  newMemberCap: number;
  newPeriod: PlanPeriod;
  newPlanCostPerPeriodInCents: number;
  newPlanIsFree: boolean;
  nextPaymentDate: DateTime;
}

export interface WhitelistedDomain extends Base {
  domain: string;
}

// file is not returned from the api, but is used in form submission
export interface Image extends Base {
  adminApproved: boolean;
  createdAt: DateTime;
  file?: string;
  filename: string;
  url: string;
}

export type CreateImage = Required<Pick<Image, 'file'>>;

export interface AutoModerationResult extends Base {
  createdAt: DateTime;
  minorLikelihood: number;
  noNudityLikelihood: number;
  offensiveLikelihood: number;
  partialNudityLikelihood: number;
  problematicLikelihood: number;
  rawNudityLikelihood: number;
  scamLikelihood: number;
  weaponLikelihood: number;
}

export interface Invite extends Base {
  createdAt: DateTime;
  inviteCode?: string;
  inviteeEmail: string;
  note: string;
  status: InviteStatus;
}

export type InviteLookup = Base;

export type CreateInvite = Pick<Invite, 'inviteeEmail' | 'note'>;
export type InviteFormData = Pick<Invite, 'inviteeEmail'>;

export type CreateJobApplication = {
  coverLetter: string;
};

export interface Link extends Base {
  label: string;
  metaDescription: string;
  metaImage: string;
  metaTitle: string;
  position: number;
  uri: string;
}

export type LinkFormData = Pick<Link, 'label' | 'uri'>;

export interface Member extends UserGeneratedContent {
  acceptedRulesAt: DateTime | null;
  bio: string;
  createdAt: DateTime;
  email?: string;
  firstName: string;
  fullName: string;
  headline: string;
  image: string | null;
  industry: Industry | null;
  lastName: string;
  latitude: number | null;
  location: string | null;
  longitude: number | null;
  moderator: boolean;
  okToShareInfo?: boolean;
  owner: boolean;
  permissions: {
    block: boolean;
    edit: boolean;
    flag: boolean;
    message: boolean;
    welcome: boolean;
  };
  profileImage200Url: string | null;
  profileImage80Url: string | null;
  role: MemberRole;
  slug: string;
  status: MemberStatus;
  verificationAnswer?: string;
  wantsModeratorEmails?: boolean;
  wantsModeratorSupportEmails?: boolean;
}

export type UpdateMember = Partial<
  Pick<
    Member,
    | 'acceptedRulesAt'
    | 'bio'
    | 'headline'
    | 'okToShareInfo'
    | 'role'
    | 'status'
    | 'verificationAnswer'
    | 'wantsModeratorEmails'
    | 'wantsModeratorSupportEmails'
  >
>;

export type MemberFormData = Pick<Member, 'bio' | 'headline'> & {
  traitOptionSelections: TraitOptionSelectionFormData[];
};

export interface SubscriptionLocationFilter extends Base {
  latitude: number;
  location: string;
  longitude: number;
}

export type CreateSubscriptionLocationFilter = Pick<SubscriptionLocationFilter, 'location'>;

export interface Subscription extends Base {
  emailAcceptedInvites: boolean;
  emailDiscussions: boolean;
  emailEvents: boolean;
  emailFrequency: SubscriptionFrequency;
  emailJobPosts: boolean;
  emailMentions: boolean;
  emailNewsItems: boolean;
  lastEmailDelivery: DateTime | null;
  pushAcceptedInvites: boolean;
  pushDiscussions: boolean;
  pushEvents: boolean;
  pushJobPosts: boolean;
  pushMentions: boolean;
  pushNewsItems: boolean;
}

export type UpdateSubscription = {
  emailAcceptedInvites?: boolean;
  emailDiscussions?: boolean;
  emailEvents?: boolean;
  emailFrequency?: SubscriptionFrequency;
  emailJobPosts?: boolean;
  emailMentions?: boolean;
  emailNewsItems?: boolean;
};

export interface Mention extends Base {
  createdAt: DateTime;
}

export interface Note extends Blockable {
  createdAt: DateTime;
  note: string;
  permissions: {
    block: boolean;
  };
}

// create note will never actually have an id, but the type needs to include it so that it properly extends Base
export type CreateNote = Pick<Note, 'note' | 'id'>;

export interface PasswordReset extends Base {
  email: string;
}

export interface Post extends UserGeneratedContent {
  createdAt: DateTime;
  numComments: number;
  permissions: {
    block: boolean;
    edit: boolean;
    flag: boolean;
  };
  reactionCounts: ReactionCounts;
  slug: string;
  status: PostStatus;
  title: string;
}

export interface PostWithLink extends Post {
  link: string;
  ogDescription?: string;
  ogImage?: string;
  ogTitle?: string;
}

export type UpdatePost = Pick<Post, 'status'>;

export interface Discussion extends PostWithLink {
  description: string;
}

export type DiscussionFormData = Pick<Discussion, 'description' | 'link' | 'title'>;

export interface Event extends PostWithLink {
  country: string;
  description: string;
  enableRsvps: boolean;
  endTime: DateTime | string; // datetime when returned from api, string when submitted
  eventType: EventType | null;
  latitude: number;
  location: string;
  locationName: string;
  longitude: number;
  online: boolean;
  startTime: DateTime | string; // datetime when returned from api, string when submitted
  timeZone: string;
}

export type ReturnedEvent = Event & {
  endTime: DateTime;
  eventType: EventType;
  startTime: DateTime;
};

export type EventFormData = Pick<
  Event,
  | 'description'
  | 'enableRsvps'
  | 'endTime'
  | 'eventType'
  | 'link'
  | 'location'
  | 'locationName'
  | 'online'
  | 'startTime'
  | 'timeZone'
  | 'title'
> & {
  endTime: string;
  startTime: string;
};

export interface JobPost extends PostWithLink {
  companyName: string;
  companyUrl: string;
  description: string;
  industry: Industry | null;
  jobType: JobType | null | undefined;
  latitude: number | null;
  location: string | null;
  longitude: number | null;
  permissions: {
    apply: boolean;
    block: boolean;
    edit: boolean;
    flag: boolean;
  };
}

export type JobPostFormData = Pick<
  JobPost,
  'companyName' | 'companyUrl' | 'description' | 'industry' | 'jobType' | 'location' | 'title'
>;

export interface NewsItem extends PostWithLink {
  body: string;
  postFormat: 'auto' | 'basic' | 'link';
  sourceId: string;
  sourceType: 'html' | 'rss' | 'twitter';
  sourceUrl: string;
}

export type NewsItemFormData = Pick<NewsItem, 'body' | 'link' | 'title'>;

// eventId is not returned from the api but is used in form submission
// note that this is technically a jsonapi violation and we should be using related resources
export interface Rsvp extends Base {
  eventId?: string;
}

export type RsvpFormData = Required<Pick<Rsvp, 'eventId'>>;

export interface Search extends Base {
  currentPage: number;
  facets: FacetCollection;
  hitCount: number;
  perPage: number;
}

export interface GroupSearch extends Search {
  query?: string;
}

export interface ConversationSearch extends Search {
  query?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ImageSearch extends Search {}

export interface InviteSearch extends Search {
  query?: string;
}

export interface MemberSearch extends Search {
  lat?: number;
  lng?: number;
  location?: string;
  query?: string;
  radius?: string;
}

export interface PostSearch extends Search {
  query?: string;
}

export interface Reaction extends Base {
  createdAt: DateTime;
  emotion: ReactionEmotion;
}

export type CreateReaction = Pick<Reaction, 'emotion'>;

export interface Session extends Base {
  email?: string;
  password?: string;
  passwordResetToken?: string;
}

export interface UnreadCounts extends Base {
  conversationCount?: number;
  discussionCount?: number;
  eventCount?: number;
  imageCount?: number;
  jobPostCount?: number;
  memberCount?: number;
  newsItemCount?: number;
}

export interface User extends Base {
  acceptedTerms?: boolean;
  acceptedTransactionalEmail?: boolean;
  admin: boolean;
  confirmed: boolean;
  firstName: string;
  fullName: string;
  hasAppInstalled: boolean;
  hasPassword: boolean;
  hometown: string;
  image: string; // base 64 encoded file
  industry: Industry | null;
  lastName: string;
  lastVisitedGroupSlug: string | null;
  location: string;
  remoteImageThumbUrl: string;
  remoteImageUrl: string;
  slug: string;
  timezone: string;
  userComplete: boolean;
  username: string;
}

export type CreateUser = Pick<User, 'username'>;

export type UpdateUser = Partial<
  Pick<
    User,
    | 'acceptedTerms'
    | 'acceptedTransactionalEmail'
    | 'firstName'
    | 'hometown'
    | 'image'
    | 'industry'
    | 'lastName'
    | 'lastVisitedGroupSlug'
    | 'location'
    | 'timezone'
  >
> & {
  password?: string;
  passwordResetToken?: string;
};

export type UserFormData = Pick<
  User,
  'firstName' | 'hometown' | 'industry' | 'lastName' | 'location'
>;

export interface EmailLookup extends Base {
  confirmed: boolean;
  email: string;
  found: boolean;
}

export type CreateEmailLookup = Pick<EmailLookup, 'email'>;

export interface EmailAddress extends Base {
  bounced: boolean;
  confirmed: boolean;
  email: string;
  primary: boolean;
}

export type CreateEmailAddress = Pick<EmailAddress, 'email'>;

export interface EmailConfirmation extends Base {
  email: string;
}

export interface PriceTier extends Base {
  free: boolean;
  memberCap: number;
  monthlyPriceInCents: number;
  yearlyPriceInCents: number;
}

export interface Invoice extends Base {
  createdAt: DateTime;
  hostedInvoiceUrl: string;
  invoicePdf: string;
  total: number;
}
