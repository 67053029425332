import { IonButton, IonContent, IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { addOutline, search } from 'ionicons/icons';
import React, { createRef } from 'react';
import { useIntl } from 'react-intl';

import Page from '../components/layout/Page';
import PageHeader from '../components/layout/PageHeader';
import { useGroupPostsChannel } from '../hooks/useGroupPostsChannel';
import useOpener from '../hooks/useOpener';
import { JSONApi } from '../types';

import './Cards.scss';

type Props = {
  children: (contentRef: React.RefObject<HTMLIonContentElement>) => React.ReactElement;
  group: JSONApi.GroupResource;
  onCreateClick?: () => void;
  renderSearchForm: (isOpen: boolean) => JSX.Element;
  titleKey: string;
};

function PostsPage(props: Props) {
  const { children, group, onCreateClick, renderSearchForm, titleKey } = props;
  const intl = useIntl();
  const contentRef = createRef<HTMLIonContentElement>();
  const searchFormOpener = useOpener();

  useGroupPostsChannel({ group });

  const actionButtons = (
    <IonButton
      aria-label={intl.formatMessage({ id: 'dictionary.search' })}
      onClick={searchFormOpener.toggle}
    >
      <IonIcon icon={search} slot="icon-only" />
    </IonButton>
  );

  return (
    <Page className="centered posts-page wide">
      <PageHeader buttonsRight={actionButtons} group={group} titleKey={titleKey} />
      {onCreateClick && (
        <IonFab className="posts-page-fab" horizontal="end" slot="fixed" vertical="bottom">
          <IonFabButton
            aria-label={intl.formatMessage({ id: 'dictionary.post' })}
            color="secondary"
            onClick={onCreateClick}
          >
            <IonIcon icon={addOutline} />
          </IonFabButton>
        </IonFab>
      )}
      <IonContent className="canvas" ref={contentRef} scrollEvents>
        {renderSearchForm(searchFormOpener.isOpen)}
        {children(contentRef)}
      </IonContent>
    </Page>
  );
}

export default PostsPage;
