import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import { alertError, alertSuccess } from '../actions/notificationActions';
import CurrentPlan from '../components/CurrentPlan';
import FormModal from '../components/FormModal';
import GroupPlanForm from '../components/forms/GroupPlanForm';
import Heading from '../components/Heading';
import InvoiceList from '../components/InvoiceList';
import ModeratorPage from '../components/layout/ModeratorPage';
import useOpener from '../hooks/useOpener';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { forceArray } from '../services/arrayUtils';
import { handleCheckoutSessionResponse } from '../services/checkoutUtils';
import { planManagementEnabled } from '../services/platformUtils';
import {
  cancelPlan,
  createPaymentInfoUpdateCheckoutSession,
  loadInvoices,
  reactivatePlan
} from '../thunks/apiThunks';
import { JSONApi } from '../types';

import './SettingsList.scss';

type Props = {
  group: JSONApi.GroupResource;
};

const ManageGroupPlanPage = ({ group }: Props) => {
  const billingHistoryOpener = useOpener();
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const [invoices, setInvoices] = useState<JSONApi.InvoiceResource[]>();

  const handleClickBillingHistory = useCallback(() => {
    dispatch(loadInvoices(group.attributes.slug))
      .then(response => {
        setInvoices(forceArray(response.data));
        billingHistoryOpener.open();
      })
      .catch(() => {
        dispatch(alertError('errors.plan.failedToLoadInvoices'));
      });
  }, [billingHistoryOpener, dispatch, group.attributes.slug]);

  const handleClickUpdatePayment = useCallback(() => {
    const cancelRedirect = `/g/${group.attributes.slug}/manage-group/plan/?cancel=true`;
    const successRedirect = `/g/${group.attributes.slug}/manage-group/plan?success=true`;

    dispatch(
      createPaymentInfoUpdateCheckoutSession(group.attributes.slug, successRedirect, cancelRedirect)
    ).then(response => {
      handleCheckoutSessionResponse(response, history, successRedirect);
    });
  }, [dispatch, group, history]);

  const handleClickCancelPlan = useCallback(() => {
    dispatch(cancelPlan(group.attributes.slug))
      .then(() => dispatch(alertSuccess('forms.payment.cancelPlan.success')))
      .catch(() => dispatch(alertError('forms.payment.cancelPlan.error')));
  }, [dispatch, group.attributes.slug]);

  const handleClickReactivatePlan = useCallback(() => {
    dispatch(reactivatePlan(group.attributes.slug))
      .then(() => dispatch(alertSuccess('forms.payment.reactivatePlan.success')))
      .catch(() => dispatch(alertError('forms.payment.reactivatePlan.error')));
  }, [dispatch, group.attributes.slug]);

  return (
    <ModeratorPage group={group} h2Key="pages.manageGroup.plan.title">
      {() => (
        <>
          <div className="ion-padding">
            {group.attributes.planActive && (
              <CurrentPlan
                group={group}
                onClickBillingHistory={handleClickBillingHistory}
                onClickCancel={handleClickCancelPlan}
                onClickReactivate={handleClickReactivatePlan}
                onClickUpdatePayment={handleClickUpdatePayment}
              />
            )}
            {!group.attributes.planCancellationRequested && (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {planManagementEnabled() ? (
                  <GroupPlanForm
                    group={group}
                    paymentCancelRedirect={`/g/${group.attributes.slug}/manage-group/plan/?cancel=true`}
                    paymentSuccessRedirect={`/g/${group.attributes.slug}/manage-group/plan?success=true`}
                  />
                ) : (
                  <Heading level={2}>
                    <FormattedMessage id="pages.manageGroup.plan.planManagementDisabled" />
                  </Heading>
                )}
              </>
            )}
          </div>
          <FormModal
            keyboardClose
            opener={billingHistoryOpener}
            titleKey="modals.billingHistory.title"
          >
            {() => <InvoiceList invoices={invoices} />}
          </FormModal>
        </>
      )}
    </ModeratorPage>
  );
};

export default ManageGroupPlanPage;
