import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';

import ModeratorPage from '../components/layout/ModeratorPage';
import ModerateFlaggedItem from '../components/ModerateFlaggedItem';
import { getFlaggedItem } from '../selectors';
import { JSONApi, State } from '../types';

import './SettingsList.scss';

type Props = {
  group: JSONApi.GroupResource;
};

const ManageGroupFlagPage = ({ group }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const { id } = useParams<{ id: string }>();

  const flaggedItem = getFlaggedItem(apiData, group, id);

  if (!flaggedItem) {
    return <Redirect to={`/g/${group.attributes.slug}/manage-group/flags`} />;
  }

  return (
    <ModeratorPage
      contentClass="canvas ion-padding"
      group={group}
      h2Key="pages.manageGroup.flag.title"
    >
      {() => <ModerateFlaggedItem flaggedItem={flaggedItem} group={group} />}
    </ModeratorPage>
  );
};

export default ManageGroupFlagPage;
