import React, { useCallback } from 'react';
import { useParams } from 'react-router';

import RequireRecord from '../components/RequireRecord';
import { findBySlug } from '../selectors';
import { loadMember } from '../thunks/apiThunks';
import { JSONApi, Models, State } from '../types';

type Props = {
  children: (member: JSONApi.MemberResource) => React.ReactElement;
  group: JSONApi.GroupResource;
};

const RequireMember = ({ children, group }: Props) => {
  const { memberSlug } = useParams<{ memberSlug: string }>();

  const recordFinder = useCallback(
    (apiData: State.Api) => findBySlug<Models.Member>(apiData, 'member', memberSlug, group),
    [group, memberSlug]
  );

  return (
    <RequireRecord<Models.Member>
      identifier="RequireMember"
      loadAction={loadMember(group.attributes.slug, memberSlug)}
      recordFinder={recordFinder}
      redirect={`/g/${group.attributes.slug}/members`}
      warningKey="models.member.notFound"
    >
      {children}
    </RequireRecord>
  );
};

export default RequireMember;
