import React from 'react';

import ConfirmDeleteModal from '../components/ConfirmDeleteModal';
import { Opener } from '../hooks/useOpener';
import { deleteComment } from '../thunks/apiThunks';
import { JSONApi } from '../types';

type Props = {
  comment: JSONApi.CommentResource;
  group: JSONApi.GroupResource;
  opener: Opener;
};

const DeleteCommentModal = ({ comment, group, opener }: Props) => (
  <ConfirmDeleteModal
    action={deleteComment(group.attributes.slug, comment)}
    descriptionKey="modals.deleteComment.explanation"
    errorAlertKey="forms.comment.delete.error"
    opener={opener}
    successAlertKey="forms.comment.delete.success"
    titleKey="dictionary.deleteComment"
  />
);

export default DeleteCommentModal;
