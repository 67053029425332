import { IonList, IonSegment, IonSegmentButton, SegmentChangeEventDetail } from '@ionic/react';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ModeratorPage from '../components/layout/ModeratorPage';
import ModeratePost from '../components/ModeratePost';
import SearchQuery from '../components/SearchQuery';
import SearchResults from '../components/SearchResults';
import { loadMorePostSearchResults, loadPostSearch } from '../thunks/apiThunks';
import { ApiEndpoint, JSONApi, ModelAttributes, Models, Search } from '../types';

import '../components/forms/CustomForm.scss';

type Props = {
  group: JSONApi.GroupResource;
};

enum StatusFilters {
  ALL = 'all',
  APPROVED = 'approved',
  PENDING = 'pending',
  REJECTED = 'rejected'
}

const ManageGroupPostsPage = ({ group }: Props) => {
  const [statusFilter, setStatusFilter] = useState<StatusFilters>(StatusFilters.ALL);
  const [searchParams, setSearchParams] = useState<Search.PostParams>({
    companyName: null,
    eventType: null,
    industry: null,
    jobType: null,
    lat: null,
    lng: null,
    location: null,
    memberSlug: null,
    query: '',
    radius: null,
    status: Object.values(ModelAttributes.PostStatus).join(','),
    upcoming: null
  });

  const handlePostFilterChange = useCallback(
    (event: CustomEvent<SegmentChangeEventDetail>) => {
      const changeDetail = event.detail;
      if (changeDetail) {
        const newStatusFilter = changeDetail.value as StatusFilters;
        setStatusFilter(newStatusFilter);
        let statusParam: string | undefined = undefined;
        switch (newStatusFilter) {
          case StatusFilters.ALL:
            statusParam = Object.values(ModelAttributes.PostStatus).join(',');
            break;
          case StatusFilters.APPROVED:
            statusParam = [
              ModelAttributes.PostStatus.APPROVED,
              ModelAttributes.PostStatus.PUBLISHED
            ].join(',');
            break;
          case StatusFilters.PENDING:
            statusParam = ModelAttributes.PostStatus.PENDING;
            break;
          case StatusFilters.REJECTED:
            statusParam = ModelAttributes.PostStatus.REJECTED;
            break;
          default:
            break;
        }
        setSearchParams({ ...searchParams, status: statusParam });
      }
    },
    [searchParams]
  );

  return (
    <ModeratorPage group={group} h2Key="pages.manageGroup.posts.title">
      {contentRef => (
        <>
          <div className="custom-form-wrapper ion-padding">
            <SearchQuery<Search.PostParams>
              autofocus={false}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </div>
          <IonSegment onIonChange={handlePostFilterChange} value={statusFilter}>
            <IonSegmentButton value={StatusFilters.ALL}>
              <FormattedMessage id="dictionary.all" />
            </IonSegmentButton>
            <IonSegmentButton value={StatusFilters.PENDING}>
              <FormattedMessage id="dictionary.needsReview" />
            </IonSegmentButton>
            <IonSegmentButton value={StatusFilters.APPROVED}>
              <FormattedMessage id="dictionary.approved" />
            </IonSegmentButton>
            <IonSegmentButton value={StatusFilters.REJECTED}>
              <FormattedMessage id="dictionary.rejected" />
            </IonSegmentButton>
          </IonSegment>
          <SearchResults<Models.PostSearch, Models.Post, Search.PostParams>
            contentRef={contentRef}
            group={group}
            loadDataActionCreator={loadPostSearch}
            loadMoreActionCreator={loadMorePostSearchResults}
            searchEndpoint={ApiEndpoint.POST_SEARCH}
            searchParams={searchParams}
            showCount={false}
          >
            {results => (
              <IonList>
                {results.map(post => (
                  <ModeratePost group={group} key={post.id} post={post} />
                ))}
              </IonList>
            )}
          </SearchResults>
        </>
      )}
    </ModeratorPage>
  );
};

export default ManageGroupPostsPage;
