import React from 'react';

import SetupPasswordForm from '../components/forms/SetupPasswordForm';
import UserAccountWizardPageWrapper from '../components/UserAccountWizardPageWrapper';
import { JSONApi } from '../types';

type Props = {
  currentUser: JSONApi.UserResource;
  group?: JSONApi.GroupResource;
};

const SetupPasswordPage = ({ currentUser, group }: Props) => {
  const nextHref = group ? `/g/${group.attributes.slug}/register/setup-account` : '/start';

  return (
    <UserAccountWizardPageWrapper titleId="pages.setupPassword.title">
      <SetupPasswordForm currentUser={currentUser} nextHref={nextHref} />
    </UserAccountWizardPageWrapper>
  );
};

export default SetupPasswordPage;
