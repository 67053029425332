import React from 'react';
import { Redirect } from 'react-router';

import useCurrentUser from '../hooks/useCurrentUser';
import { JSONApi } from '../types';

type Props = {
  children: (user: JSONApi.UserResource) => React.ReactElement;
};

const RequireAdmin = ({ children }: Props) => {
  const user = useCurrentUser();

  if (user === undefined) {
    return null;
  }

  if (user?.attributes?.admin) {
    return children(user);
  }

  return <Redirect to="/" />;
};

export default RequireAdmin;
