import { IonButton, IonCard, IonCardContent, IonIcon, IonRouterLink } from '@ionic/react';
import { ellipsisHorizontal } from 'ionicons/icons';
import { DateTime } from 'luxon';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Nbsp from './Nbsp';
import CommentsSection from '../components/CommentsSection';
import ExternalLink from '../components/ExternalLink';
import FormatText from '../components/FormatText';
import Paragraph from '../components/Paragraph';
import PostActionSheet from '../components/PostActionSheet';
import PostAuthor from '../components/PostAuthor';
import PostImages from '../components/PostImages';
import useOpener from '../hooks/useOpener';
import { getPostBody, getPostPath } from '../services/postUtils';
import { isDiscussion, isEvent, isNewsItem } from '../services/typeDetection';
import { toUrl } from '../services/urlUtils';
import { JSONApi } from '../types';

import './FeedItem.scss';

type Props = {
  group: JSONApi.GroupResource;
  post: JSONApi.PostResource;
};

const FeedItem = ({ group, post }: Props) => {
  const actionSheetOpener = useOpener();
  const intl = useIntl();

  const postPath = getPostPath(post, group);
  const url =
    isDiscussion(post) || isEvent(post) || isNewsItem(post)
      ? toUrl(post.attributes.link)
      : undefined;

  return (
    <IonCard className="feed-item">
      <IonCardContent>
        <IonButton
          aria-label={intl.formatMessage({ id: 'dictionary.options' })}
          className="post-actions ion-float-right"
          color="primary"
          fill="clear"
          onClick={actionSheetOpener.toggle}
          size="small"
        >
          <IonIcon icon={ellipsisHorizontal} />
        </IonButton>
        <PostActionSheet
          close={actionSheetOpener.close}
          group={group}
          isOpen={actionSheetOpener.isOpen}
          post={post}
        />
        <PostAuthor description group={group} post={post} timestamp />
        {post.attributes.title.length > 0 && (
          <Paragraph className="post-title">
            <IonRouterLink routerLink={postPath}>
              {post.attributes.title}
              {isEvent(post) && (
                <>
                  <FormattedMessage id="dictionary.comma" />
                  <Nbsp />
                  {post.attributes.startTime.toLocaleString(DateTime.DATETIME_SHORT)}
                  <Nbsp />
                  <FormattedMessage id="dictionary.dash" />
                  <Nbsp />
                  {post.attributes.endTime.toLocaleString(DateTime.DATETIME_SHORT)}
                </>
              )}
            </IonRouterLink>
          </Paragraph>
        )}
        <Paragraph className="formatted-text">
          <FormatText autolink mentionable>
            {getPostBody(post)}
          </FormatText>
        </Paragraph>
        {url && (
          <Paragraph>
            <ExternalLink href={url.toString()}>{url.host}</ExternalLink>
          </Paragraph>
        )}
      </IonCardContent>
      {(isDiscussion(post) || isEvent(post) || isNewsItem(post)) && <PostImages post={post} />}
      <CommentsSection group={group} post={post} showForm />
    </IonCard>
  );
};

export default FeedItem;
