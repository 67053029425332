import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonNote,
  IonSkeletonText
} from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Paragraph from '../components/Paragraph';
import PostAuthor from '../components/PostAuthor';
import RelativeTimestamp from '../components/RelativeTimestamp';
import useImageOrienter from '../hooks/useImageOrienter';
import { getPostIcon } from '../services/postUtils';
import { truncateString } from '../services/stringUtils';
import { JSONApi } from '../types';

import './PostCard.scss';

type HeaderProps = {
  title: string;
};

const Header = ({ title }: HeaderProps) => {
  if (title.length === 0) {
    return null;
  }

  return (
    <IonCardHeader>
      <IonCardTitle color="primary">{title}</IonCardTitle>
    </IonCardHeader>
  );
};

type PostCardImageProps = {
  image: string;
};

const PostCardImage = ({ image }: PostCardImageProps) => {
  const imageOrienter = useImageOrienter(image);

  if (imageOrienter.isProcessing) {
    return <IonSkeletonText animated />;
  }

  return <div className="img-wrapper" style={{ backgroundImage: `url("${imageOrienter.src}")` }} />;
};

const PostCard = ({
  body,
  children,
  group,
  href,
  image,
  post
}: React.PropsWithChildren<{
  body: string;
  group: JSONApi.GroupResource;
  href: string;
  image?: string;
  post: JSONApi.PostResource;
}>) => (
  <IonCard
    button
    className="ion-activatable ion-no-padding post-card"
    role="button"
    routerLink={href}
  >
    {image ? (
      <>
        <PostCardImage image={image} />
        <Header title={post.attributes.title} />
        <IonCardContent>
          {children}
          <PostAuthor group={group} link={false} post={post} />
        </IonCardContent>
      </>
    ) : (
      <>
        <Header title={post.attributes.title} />
        <IonCardContent>
          <PostAuthor group={group} link={false} post={post} />
          <div className="ion-padding-bottom ion-padding-top">
            <Paragraph className="formatted-text">{truncateString(body, 120)}</Paragraph>
          </div>
          {children}
        </IonCardContent>
      </>
    )}
    <div className="footer ion-padding-bottom ion-padding-end ion-padding-start">
      <IonIcon className={getPostIcon(post)} color="primary" />
      <IonNote className="smallcaps" color="primary">
        <FormattedMessage
          id="models.post.attributes.numComments.label"
          values={{ num: post.attributes.numComments }}
        />
      </IonNote>
      <IonNote className="smallcaps">
        <RelativeTimestamp date={post.attributes.createdAt.valueOf()} />
      </IonNote>
    </div>
  </IonCard>
);

export default PostCard;
