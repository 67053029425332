import { IonButton, IonButtons, IonItem, IonLabel, IonRouterLink } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import GroupAvatar from '../components/GroupAvatar';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { dispatchAndAlert } from '../services/dispatchUtils';
import { approveGroup, rejectGroup } from '../thunks/apiThunks';
import { JSONApi, ModelAttributes } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  lines: 'full' | 'none';
  link?: boolean;
};

const AdminManageGroup = ({ group, lines, link = true }: Props) => {
  const dispatch = useThunkDispatch();

  const handleApproveGroup = useCallback(() => {
    dispatchAndAlert(
      dispatch,
      approveGroup(group),
      'forms.group.approve.success',
      'forms.group.approve.error'
    );
  }, [dispatch, group]);

  const handleRejectGroup = useCallback(() => {
    dispatchAndAlert(
      dispatch,
      rejectGroup(group),
      'forms.group.reject.success',
      'forms.group.reject.error'
    );
  }, [dispatch, group]);

  return (
    <IonItem color="light" key={group.id} lines={lines}>
      <GroupAvatar group={group} slot="start" />
      <IonLabel>
        {link ? (
          <IonRouterLink routerLink={`/admin/groups/${group.attributes.slug}`}>
            {group.attributes.name}
          </IonRouterLink>
        ) : (
          group.attributes.name
        )}
      </IonLabel>
      <IonButtons slot="end">
        {group.attributes.status !== ModelAttributes.GroupStatus.APPROVED && (
          <IonButton color="primary" onClick={handleApproveGroup}>
            <FormattedMessage id="dictionary.approve" />
          </IonButton>
        )}
        {group.attributes.status !== ModelAttributes.GroupStatus.REJECTED && (
          <IonButton color="danger" onClick={handleRejectGroup}>
            <FormattedMessage id="dictionary.reject" />
          </IonButton>
        )}
      </IonButtons>
    </IonItem>
  );
};

export default AdminManageGroup;
