import { useSelector } from 'react-redux';

import useCurrentGroup from '../hooks/useCurrentGroup';
import useCurrentUser from '../hooks/useCurrentUser';
import { getUserMemberForGroup } from '../selectors';
import { SessionState, State } from '../types';

const useCurrentMember = () => {
  const apiData = useSelector((root: State.Root) => root.api);

  const currentGroup = useCurrentGroup();
  const groupState = useSelector((root: State.Root) => root.session.currentGroup.state);

  const currentUser = useCurrentUser();
  const userState = useSelector((root: State.Root) => root.session.currentUser.state);

  if (currentGroup && currentUser) {
    return getUserMemberForGroup(apiData, currentUser, currentGroup);
  }

  if (groupState === SessionState.LOADING || userState === SessionState.LOADING) {
    return undefined;
  }

  return null;
};

export default useCurrentMember;
