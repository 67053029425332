/* eslint-disable max-lines */
import { stringArrayDelimiter } from '../globals';
import eventTypes from '../i18n/eventTypes';
import flagCategories from '../i18n/flagCategories';
import industries from '../i18n/industries';
import jobPostTypes from '../i18n/jobPostTypes';
import subscriptionFrequencies from '../i18n/subscriptionFrequencies';

const models = {
  attachment: {
    add: 'Add an attachment'
  },
  autoModerationResult: {
    minorLikelihood: 'Minor: {value}%',
    offensiveLikelihood: 'Offensive: {value}%',
    partialNudityLikelihood: 'Partial nudity: {value}%',
    problematicLikelihood: 'Problematic: {value}%',
    rawNudityLikelihood: 'Partial nudity: {value}%',
    scamLikelihood: 'Scam: {value}%',
    weaponLikelihood: 'Weapon: {value}%'
  },
  comment: {
    attributes: {
      content: {
        label: 'New Comment',
        placeholder: 'Add a comment'
      },
      status: {
        blockedMessage: 'Comment blocked',
        deletedMessage: 'Comment deleted',
        rejectedMessage: 'Comment removed by moderator'
      }
    },
    attribution: '{name} commented',
    badge: 'Comment',
    label: 'Comments'
  },
  conversation: {
    attributes: {
      threadType: {
        jobApplication: {
          title: 'Job Application with {counterparty}'
        },
        message: {
          title: 'Conversation with {counterparty}'
        },
        welcome: {
          title: 'Welcome Message with {counterparty}'
        }
      }
    },
    label: 'Conversations',
    notFound: 'That conversation could not be found. Please try searching for it.'
  },
  discussion: {
    attributes: {
      description: {
        label: 'Body',
        placeholder: 'The main content for your post goes here'
      },
      link: {
        label: 'Link',
        placeholder: 'Add a link to reference content on the web'
      },
      title: {
        label: 'Title',
        placeholder: 'This will be the headline for your post'
      }
    },
    badge: 'Forum',
    description: 'posted a discussion',
    name: {
      one: 'discussion',
      other: 'discussions'
    },
    notFound: 'That discussion could not be found. Please try searching for it.',
    relationships: {
      images: {
        alt: 'Image for discussion post',
        label: 'Images'
      }
    }
  },
  emailAddress: {
    attributes: {
      email: {
        label: 'Email address',
        placeholder: 'Add a new email address'
      },
      primary: {
        details:
          `This is your primary email address. You'll need to add a different email address ` +
          `and promote it to primary in order to remove this one.`
      }
    }
  },
  event: {
    attributes: {
      dateHeader: {
        label: 'Date & Time'
      },
      description: {
        label: 'Body',
        placeholder: 'Describe the event in detail'
      },
      detailsHeader: {
        label: 'Event details'
      },
      enableEndTime: {
        label: 'Has end time'
      },
      enableRsvps: {
        label: 'Turn on RSVPs'
      },
      endDate: {
        label: 'End date',
        placeholder: 'Select a date'
      },
      endTime: {
        label: 'End time',
        placeholder: 'Select a time'
      },
      ends: {
        label: 'Ends'
      },
      eventType: {
        label: 'Category',
        options: eventTypes.join(stringArrayDelimiter),
        placeholder: 'What type of event is this?'
      },
      link: {
        label: 'Link',
        placeholder: 'Add a link to reference content on the web'
      },
      location: {
        label: 'Location',
        placeholder: 'The city or address where the event will be held'
      },
      locationHeader: {
        label: 'Location'
      },
      locationName: {
        label: 'Venue name',
        placeholder: 'The name of the place where the event will be held'
      },
      moreOptionsHeader: {
        label: 'More Options'
      },
      online: {
        label: 'This event is online only',
        name: 'Online'
      },
      startDate: {
        label: 'Start date',
        placeholder: 'Select a date'
      },
      startTime: {
        label: 'Start time',
        placeholder: 'Select a time'
      },
      starts: {
        label: 'Starts'
      },
      timeZone: {
        label: 'Time zone',
        placeholder: 'Select the local time zone'
      },
      title: {
        label: 'Title',
        placeholder: 'This will be the headline for your event'
      }
    },
    badge: 'Event',
    description: 'added to the calendar',
    name: {
      one: 'event',
      other: 'events'
    },
    notFound: 'That event could not be found. Please try searching for it.',
    relationships: {
      images: {
        alt: 'Image for event post'
      }
    }
  },
  flag: {
    attributes: {
      categories: {
        options: flagCategories.join(stringArrayDelimiter)
      },
      report: {
        description: 'Report from member',
        label: 'Report',
        none: '(No report provided)',
        placeholder: 'Write a report of the issue for the moderators.'
      }
    }
  },
  flaggedItem: {
    attributes: {
      flagCount: '{count, plural, one {# Flag} other {# Flags}}',
      flagger: 'Flagged by',
      mostRecentFlagAt: 'Most recent: {date}'
    }
  },
  group: {
    attributes: {
      avatar: {},
      bgImage: {
        alt: 'Background image'
      },
      careersEnabled: {
        label:
          'Enable a "jobs" section so members can post to a group job board and hire one another'
      },
      description: {
        label: 'Group description',
        placeholder: `Describe your group's purpose`
      },
      eventsEnabled: {
        label: 'Enable an "events" section so members can add events to a group calendar'
      },
      forumEnabled: {
        label: 'Enable a "discussion" section so members can have group conversations'
      },
      galleryEnabled: {
        label: 'Enable a "photo gallery" section that displays all the images from posts'
      },
      htmlNewsSource: {
        explanation:
          `If provided, we will regularly scrape this link for news articles; ` +
          `however, the setup is more complicated than the RSS setup. After ` +
          `providing a web address for the news page in this field, we will need ` +
          `you to add a "css selector" we can use to parse the page. If you don't ` +
          `understand what this means, email us and we'll help set it up.`,
        label: 'Web address',
        placeholder: 'Link to a news page'
      },
      htmlNewsSourceSelector: {
        explanation:
          'Add a comma separated list of CSS selectors we can use to identify news ' +
          'links on this page. If you need help, email customer support.',
        label: 'CSS Selectors for finding articles',
        placeholder: 'Enter CSS selectors'
      },
      isAtMaxCapacity: {
        explanation:
          `Your group has reached the maximum number of members. You are no longer able to ` +
          `approve new members. You can create space by removing some members or upgrading ` +
          `your account.`
      },
      locationFeaturesEnabled: {
        explanation:
          `Indicating "yes" will enable city-based search features, which won't be ` +
          `relevant if you all live in one place. If your members live in more than ` +
          `one city, but you still want to turn off these search features, just indicate ` +
          `"no" here.`,
        label: 'Do your group members live in more than one city or town?'
      },
      memberCount: {
        label: 'Number of members'
      },
      memberVerificationQuestion: {
        label: 'Member verification question',
        placeholder:
          `If you want members to answer a question to verify their eligibility ` +
          `for this group, enter it here.`,
        tip:
          `Tip: Consider asking for information that would assist you in approving ` +
          `them (like membership ID number, class year, reason for joining, etc.)`
      },
      memberVerificationRequired: {
        label: 'Require verification for members to join'
      },
      membersCanSendInvites: {
        explanation:
          `An invite grants the recipient automatic access to the group. If you allow ` +
          `members to invite others, you will be giving them the ability to grant ` +
          `such access.`,
        label: 'Should members be able to send invites to other members?'
      },
      name: {
        label: 'Group name',
        placeholder: 'Please name your group'
      },
      navIcon: {
        alt: "{name}'s Avatar Image"
      },
      newsEnabled: {
        additionalSettings: 'Additional settings for news',
        label: 'Enable a "news" section for group leaders to share news with members'
      },
      newsSource: {
        explanation: 'Connect a news source to your group using one of these methods.',
        label: '(Optional) Connect a news source'
      },
      planActive: {
        label: 'Active'
      },
      planFree: {
        label: 'Free'
      },
      primaryColor: {
        colorLabel: `Select {color} as the primary color`,
        label: 'Primary color',
        placeholder: '# HEX'
      },
      rssNewsSource: {
        explanation:
          'If provided, we will regularly scan this link for new articles ' +
          'and add them to the News section.',
        label: 'RSS address',
        placeholder: 'Link to an RSS feed'
      },
      secondaryColor: {
        label: 'Secondary color',
        placeholder: '# HEX'
      },
      slug: {
        label: 'Group URL',
        placeholder: 'Add a unique id...',
        prefix: 'grouplicate.com/g/'
      },
      twitterHandleNewsSource: {
        explanation:
          'If provided, we will regularly scan this Twitter handle and add its tweets ' +
          'to the News section.',
        label: 'Twitter handle',
        placeholder: 'Add a twitter handle'
      },
      usersCanCreateNews: {
        label: 'Allow group members to post news directly'
      },
      verificationQuestion: {
        description: 'Response to verification question'
      }
    },
    nMembers: '{count, plural, one {# Member} other {# Members}}',
    notFound: 'That group could not be found. Please try searching for it.',
    relationships: {
      links: {
        add: 'Add a link to the group',
        label: 'Group Links',
        title: 'Resources & Links'
      },
      pinnedPost: {
        title: 'Pinned'
      },
      rules: {
        add: 'Add a rule for your group',
        label: 'Group Rules',
        tip:
          `You may want to list rules about appropriate behavior within the group, like ` +
          `acceptable language, whether soliciting for business or jobs is ok, and what ` +
          `the intended purpose(s) of the group are.`,
        title: 'Our rules'
      },
      traits: {
        add: 'Add a new information category',
        label: 'Categories',
        tip: 'Click on a category to edit and add options to it.'
      }
    }
  },
  image: {
    attributes: {
      alt: 'User posted image'
    }
  },
  invite: {
    attributes: {
      bulkInvitesList: {
        count: '{num, plural, one {# email} other {# emails}}',
        label: 'Emails in bulk',
        tip:
          'Copy and paste email addresses from an address book. Please separate multiple emails ' +
          'using commas!'
      },
      inviteeEmail: {
        label: 'Email',
        placeholder: 'email@example.com'
      },
      note: {
        label: 'Message',
        placeholder: 'Write a message to be included with this invite.'
      },
      status: {
        acceptedBadge: 'Accepted',
        revokedBadge: 'Revoked',
        sentBadge: 'Sent'
      }
    }
  },
  inviteLookup: {
    attributes: {
      inviteCode: {
        label: 'Invite Code',
        placeholder: 'Enter an invite code',
        tip:
          `If you've received an invite code, enter it here and we'll look up the group associated ` +
          `with the invite.`
      }
    }
  },
  jobApplication: {
    attributes: {
      coverLetter: {
        label: 'Cover Letter',
        placeholder:
          'Describe your experience and qualifications for the position in a cover letter.'
      }
    },
    relationships: {
      attachments: {
        add: 'Attach a resume or other files'
      }
    },
    suggestion:
      `To make sure you are properly considered, link to an online CV or resume in your ` +
      `cover letter, or make sure to include one as an attachment.`
  },
  jobPost: {
    attributes: {
      companyName: {
        label: 'Company Name',
        placeholder: 'Enter the name of the company hiring the position'
      },
      companyUrl: {
        label: 'Company URL',
        placeholder: `Link to the company's home page`
      },
      description: {
        label: 'Job Description',
        note:
          `If you'd prefer to attach a file with a job description, click or tap the paperclip ` +
          `icon below.`,
        placeholder: 'Write or link to a job description'
      },
      industry: {
        label: 'Industry',
        options: industries.join(stringArrayDelimiter),
        placeholder: 'The industry most associated with this position'
      },
      jobType: {
        label: 'Job Type',
        options: jobPostTypes.join(stringArrayDelimiter),
        placeholder: 'What type of job is this?'
      },
      location: {
        label: 'Job Location',
        note: 'Leave this blank for remote or virtual positions',
        placeholder: 'The city where the position is based'
      },
      title: {
        label: 'Job Title',
        placeholder: 'Enter the title of the position'
      }
    },
    badge: 'Job Board',
    description: 'posted to the job board',
    name: {
      one: 'job post',
      other: 'job posts'
    },
    notFound: 'That job post could not be found. Please try searching for it.',
    relationships: {
      attachments: {
        add: 'Attach a job description or other files'
      },
      images: {
        alt: 'Image for job post'
      }
    },
    seeAttachedJobDescription: 'Please see attached job description'
  },
  link: {
    attributes: {
      label: {
        label: 'Label',
        placeholder: 'Enter a label for this link'
      },
      uri: {
        label: 'Link',
        placeholder: 'https://www.example.com'
      }
    }
  },
  login: {
    attributes: {
      email: {
        label: 'Your email address',
        placeholder: 'Enter your email address'
      },
      password: {
        label: 'Your password',
        placeholder: 'Enter your password'
      }
    },
    tip: {
      password:
        `Enter your email and password and click the button below to log in. If you'd ` +
        `prefer, you can click on the link below to receive a login link via email.`,
      passwordless: 'Enter your email and click the button below to receive a login email.'
    }
  },
  member: {
    attributes: {
      bio: {
        label: 'About Me',
        placeholder: `Add more about yourself. You can come back to this later if you aren't ready to fill it out now.`
      },
      createdAt: {
        label: 'Joined'
      },
      defaultSubscriptionSettings: {
        label: 'Give me the default notification settings.',
        subheader:
          'By selecting the default notification settings, you give Groulicate permission to ' +
          'send you emails and notifications based on a standardized set of notification settings. ' +
          'You can always adjust these later based on your preference.'
      },
      headline: {
        label: 'My Headline',
        placeholder: `Add an optional short headline to your profile.`
      },
      image: {
        alt: "{name}'s Avatar Image"
      },
      moderatorEmailSettings: {
        description: 'As a moderator you receive administrative-oriented emails',
        label: 'Moderator communications'
      },
      okToShareInfo: {
        label: "Do you want your group's leaders to be able to see your email address?",
        subheader:
          'This means that they can use your email for things outside of Grouplicate like for newsletters ' +
          'or to email you directly. Grouplicate has no control over their use of your email once it ' +
          'leaves our system.'
      },
      role: {
        label: 'Role',
        memberBadge: 'Member',
        moderatorBadge: 'Moderator',
        ownerBadge: 'Owner'
      },
      slug: {
        handle: '@{slug}'
      },
      status: {
        administratorRejectedBadge: 'Rejected',
        approvedBadge: 'Approved',
        awaitingModerationBadge: 'In Review',
        deletedBadge: 'Deleted',
        incompleteBadge: 'Incomplete',
        moderatorRejectedBadge: 'Rejected',
        selfClosedBadge: 'Frozen'
      },
      verificationAnswer: {
        label: 'Your answer for the group verification question',
        placeholder: 'Write your answer here...'
      },
      wantsModeratorEmails: {
        description: 'Daily activity summary'
      },
      wantsModeratorSupportEmails: {
        description: 'Group management support'
      }
    },
    badge: 'Member',
    info: `{name}'s Basic Info`,
    label: 'Members',
    name: {
      one: 'member',
      other: 'members'
    },
    notFound: 'That member could not be found. Please try searching for them.',
    relationships: {
      links: {
        add: 'Add a link to a profile from another website',
        label: 'Links To You',
        title: 'Find {name} on the web'
      },
      posts: {
        none: '{name} has not added any posts yet.',
        title: `{name}'s Posts`
      }
    }
  },
  mention: {
    type: {
      comment: {
        title: '{name} mentioned you in a comment'
      },
      discussion: {
        title: '{name} mentioned you in a discussion'
      },
      event: {
        title: '{name} mentioned you in an event'
      },
      jobPost: {
        title: '{name} mentioned you in a job post'
      },
      // this assumes that automated news posts will never mention anyone, which seems reasonable?
      newsItem: {
        title: '{name} mentioned you in a news post'
      }
    }
  },
  message: {
    attributes: {
      note: {
        label: 'New Message',
        placeholder: 'Your message to {name}'
      }
    }
  },
  newsItem: {
    attributes: {
      body: {
        label: 'Body',
        placeholder: 'The main content for your post goes here'
      },
      link: {
        label: 'Link',
        placeholder: 'Add a link to reference an article on the web'
      },
      title: {
        label: 'Title',
        placeholder: 'This will be the headline for your post'
      }
    },
    badge: 'News',
    description: 'shared some news',
    name: {
      one: 'news item',
      other: 'news items'
    },
    notFound: 'That news item could not be found. Please try searching for it.',
    relationships: {
      images: {
        alt: 'Image for news post',
        label: 'Image'
      }
    }
  },
  note: {
    attributes: {
      note: {
        label: 'New Note',
        placeholder: {
          withCounterparty: 'Write a note to {name}',
          withoutCounterparty: 'Write a note'
        }
      },
      status: {
        blockedMessage: 'Note blocked'
      }
    },
    attribution: 'From: {name}',
    label: 'Notes'
  },
  passwordReset: {
    attributes: {
      email: {
        label: 'Your email',
        placeholder: 'Enter your email address',
        tip:
          "Enter your email address and we'll send you a message with " +
          'instructions for resetting your password.'
      }
    }
  },
  plan: {
    attributes: {
      memberCap: {
        label: '{count, plural, one {# member} other {# members}}',
        labelPrefix: 'Your group may have up to ',
        labelSuffix: ' at any time.',
        more: 'More members?'
      },
      planCostPerPeriodInCents: {
        labelPrefix: 'Your next {period} charge will be '
      },
      planPeriod: {
        tip: '(save 17%)'
      }
    },
    cancel: {
      p1:
        'Your plan will be scheduled for cancellation, and will remain open until the end of your current ' +
        'billing period on {effectiveDate}. You will be able to reactivate the subscription at any point prior ' +
        'to that date.',
      p2:
        'Once the cancellation becomes effective, the group will become read-only. ' +
        'Current members will be able to log in and see existing content but will not be able to post new content, and ' +
        'new members will not be allowed to join the group.',
      p3: 'Any future payments will be canceled, and you will not be charged again.'
    },
    finePrint:
      'You can cancel or change your plan at any time. When you do, your account may have a prorated ' +
      'balance for any remaining time under an existing, paid plan. You may use this balance toward another ' +
      'plan, but you cannot request to receive it as a refund.',
    reactivate: {
      p1:
        'Your group is currently scheduled for cancellation on {effectiveDate}. Reactivating it will not result in any ' +
        'new charges until the end of the billing period on that date. At that time, your card will be charged for the next period.',
      p2: 'Once the group is reactivated you will be able to change your plan and make other changes to your billing setup.'
    },
    warnings: {
      cancellationEffectiveSoon: 'Your plan will become read-only on {effectiveDate}.',
      readOnly:
        'Your group is in read-only mode. This means that you cannot create new content or messages ' +
        'until the group managers choose a paid plan.',
      readOnlyForOwner:
        'Your group is in read-only mode. Members will not be able to create content or message one another ' +
        'unless you select a paid plan.',
      selectAPlan: 'Select a plan',
      upgradeYourPlan: 'Upgrade your plan'
    }
  },
  planChangePreview: {
    attributes: {
      immediateCostInCents: {
        labelPrefix: 'You will be charged ',
        labelSuffix: ' today.'
      },
      newPeriod: {
        label: 'Future charges will occur {period}.'
      },
      newPlanCostPerPeriodInCents: {
        labelPrefix: 'Your next charge will be '
      },
      newPlanIsFree: {
        explanation: 'Your selected plan is free. You will never be charged under this plan.'
      }
    }
  },
  post: {
    attributes: {
      numComments: {
        label: '{num, plural, one {# comment} other {# comments}}'
      },
      status: {
        approvedBadge: 'Approved',
        pendingBadge: 'Pending Review',
        publishedBadge: 'Published',
        rejectedBadge: 'Rejected'
      }
    },
    label: 'Posts',
    relationships: {
      comments: {
        more: '{num, plural, one {# more comment} other {# more comments}}'
      }
    }
  },
  priceTier: {
    attributes: {
      memberCap: 'Member Cap',
      monthlyPriceInCents: 'Monthly Rate',
      yearlyPriceInCents: 'Yearly Rate'
    }
  },
  registration: {
    attributes: {
      email: {
        label: 'Email',
        placeholder: 'email@example.com',
        tip:
          `Let's get started! Log in or sign up by entering your email address. We will send a link ` +
          `to that address you can use to log in or continue signing up.`
      },
      password: {
        label: 'Your new password',
        placeholder: 'Create a password',
        tip: 'Passwords must be at least {minLength} characters long'
      }
    }
  },
  rule: {
    attributes: {
      rule: {
        label: 'Group Rule',
        placeholder: 'Enter a rule'
      }
    }
  },
  search: {
    attributes: {
      companyName: 'Company',
      country: 'Country',
      industry: 'Industry',
      location: 'Location',
      query: 'Keyword',
      radius: 'Radius',
      radiusOption: '{miles} miles',
      upcoming: 'Upcoming Only'
    },
    noResults: 'There were no results for that search.'
  },
  subscription: {
    attributes: {
      emailAcceptedInvites: {
        label: `Someone accepts your invite`
      },
      emailDiscussions: {
        label: 'Discussions'
      },
      emailEvents: {
        label: 'Events'
      },
      emailFrequency: {
        label: 'Email newsletter frequency',
        options: subscriptionFrequencies.join(stringArrayDelimiter)
      },
      emailJobPosts: {
        label: 'Job posts'
      },
      emailMentions: {
        label: 'Someone mentions you'
      },
      emailNewsItems: {
        label: 'News'
      },
      emailTypes: {
        header: 'Include in email newsletters'
      },
      pushAcceptedInvites: {
        label: `Someone accepts your invite`
      },
      pushDiscussions: {
        label: 'Discussions'
      },
      pushEvents: {
        label: 'Events'
      },
      pushJobPosts: {
        label: 'Job posts'
      },
      pushMentions: {
        label: 'Someone mentions you'
      },
      pushNewsItems: {
        label: 'News'
      },
      pushTypes: {
        header: 'Send mobile app notifications for'
      }
    }
  },
  subscriptionLocationFilter: {
    attributes: {
      location: {
        label: 'Location',
        placeholder: 'Filter your subscription content by a location'
      }
    }
  },
  trait: {
    add: 'Add a category',
    attributes: {
      required: {
        label: 'Require that members pick an option for this category'
      },
      title: {
        label: 'Category',
        placeholder: 'Enter a category name',
        tip:
          `Make categories concise. For example, a category for a sports team might be ` +
          `"Position" and one for school might be "Student Group"`
      }
    },
    explanation:
      `What characteristics make your members important to one another and to the ` +
      `group as a whole? We'll feature these traits on profiles and make them easy ` +
      `to search.`,
    relationships: {
      traitOptions: {
        add: 'Add an option',
        count: '{count, plural, one {# Option} other {# Options}}',
        label: 'Options for members to select',
        none:
          `Warning: this category does not have any options yet. Members will not be asked to ` +
          `complete it until you add some options.`,
        tip: 'Members must pick from these options. Keep them simple.'
      }
    },
    remove: 'Remove this category',
    title: 'Category'
  },
  traitOption: {
    add: 'Add Another Option',
    attributes: {
      title: {
        label: 'Option name',
        placeholder: 'Enter an option',
        tip:
          `Keep options simple. For example, options for a "Position" category might be ` +
          `"Quarterback" or "Kicker" and options for a "Student Group" category might be ` +
          `"Chess Club" or "Debate Team."`
      }
    }
  },
  user: {
    attributes: {
      acceptedTerms: {
        label: 'I have read and accept the Grouplicate Terms of Service and Privacy Policy.'
      },
      acceptedTransactionalEmail: {
        label:
          'Grouplicate can email me for standard reasons related to my account like ' +
          'password recovery, email confirmations, and other similar notices.'
      },
      currentPassword: {
        label: 'Current Password',
        placeholder: 'Your current password'
      },
      email: {
        label: 'Email'
      },
      firstName: {
        label: 'First name',
        placeholder: 'Your first name'
      },
      hometown: {
        label: 'Hometown',
        placeholder: 'Your hometown'
      },
      image: {
        label: 'Profile Photo'
      },
      industry: {
        label: 'Industry',
        options: industries.join(stringArrayDelimiter),
        placeholder: 'Your professional industry'
      },
      lastName: {
        label: 'Last name',
        placeholder: 'Your last name'
      },
      location: {
        label: 'Location',
        placeholder: 'The city where you live'
      },
      newPassword: {
        label: 'New Password'
      },
      password: {
        label: 'Password',
        placeholder: 'Your password'
      },
      passwordConfirmation: {
        label: 'Password Confirmation',
        placeholder: 'Confirm your new password'
      }
    }
  }
};

export default models;
/* eslint-enable max-lines */
