import { IonButton, IonContent, isPlatform } from '@ionic/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import deviceShowcase1 from '../assets/images/design/device-showcase-1.png';
import deviceShowcase2 from '../assets/images/design/device-showcase-2.png';
import logoNavyHorizontal from '../assets/images/logos/logo-navy-horizontal.png';
import Img from '../components/Img';
import Page from '../components/layout/Page';
import PageTitle from '../components/layout/PageTitle';
import RedirectIfCurrentUser from '../components/RedirectIfCurrentUser';
import { SessionState, State } from '../types';

import './LandingPage.scss';

const LandingPage = () => {
  const intl = useIntl();
  const userState = useSelector((root: State.Root) => root.session.currentUser.state);

  if (userState === SessionState.LOADING) {
    return null;
  }

  return (
    <RedirectIfCurrentUser>
      <Page className="landing-page">
        <PageTitle id="pages.landing.headline" />
        <IonContent className="ion-no-padding">
          <div className="content-wrapper">
            <div className="banner">
              <div className="logo-wrapper">
                <Img
                  alt={intl.formatMessage({ id: 'dictionary.logo' })}
                  className="logo"
                  src={logoNavyHorizontal}
                />
              </div>
              <div className="banner-image-wrapper">
                <Img
                  alt={intl.formatMessage({ id: 'pages.landing.deviceShowcase.alt' })}
                  className="banner-image"
                  src={
                    isPlatform('desktop') || isPlatform('tablet')
                      ? deviceShowcase1
                      : deviceShowcase2
                  }
                />
              </div>
            </div>
            <div className="cta">
              <div>
                <h1 className="ion-text-center">
                  <FormattedMessage id="pages.landing.headline" />
                </h1>
                <p className="ion-text-center">
                  <FormattedMessage id="pages.landing.subheadline" />
                </p>
                <div className="buttons">
                  <div className="button-wrapper">
                    <IonButton color="primary" expand="block" fill="solid" routerLink="/join">
                      <FormattedMessage id="dictionary.join" />
                    </IonButton>
                  </div>
                  <div className="button-wrapper">
                    <IonButton color="primary" expand="block" fill="solid" routerLink="/login">
                      <FormattedMessage id="dictionary.login" />
                    </IonButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </IonContent>
      </Page>
    </RedirectIfCurrentUser>
  );
};

export default LandingPage;
