import React from 'react';

import ExternalLink from '../components/ExternalLink';
import FormatText from '../components/FormatText';
import NewsItemFormModal from '../components/NewsItemFormModal';
import Paragraph from '../components/Paragraph';
import PostImages from '../components/PostImages';
import PostPage, { PostPageProps } from '../components/PostPage';
import useOpener from '../hooks/useOpener';
import { toUrl } from '../services/urlUtils';
import { JSONApi, Models } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  newsItem: JSONApi.NewsItemResource;
};

const NewsItemPage = ({ group, newsItem }: Props) => {
  const { body, link } = newsItem.attributes;

  const modalOpener = useOpener();

  const url = toUrl(link);

  const postPageProps: PostPageProps<Models.NewsItem> = {
    backHref: `/g/${group.attributes.slug}/news`,
    group,
    post: newsItem
  };

  if (newsItem.attributes.permissions.edit) {
    postPageProps.onEditClick = modalOpener.toggle;
  }

  return (
    <PostPage<Models.NewsItem> {...postPageProps}>
      <NewsItemFormModal
        group={group}
        newsItem={newsItem}
        opener={modalOpener}
        titleKey="modals.editNewsItem.title"
      />
      {body && (
        <div className="ion-padding-bottom ion-padding-end ion-padding-start">
          <Paragraph className="formatted-text" color="primary">
            <FormatText autolink mentionable>
              {body}
            </FormatText>
          </Paragraph>
          {url && (
            <Paragraph>
              <ExternalLink href={url.toString()}>{url.host}</ExternalLink>
            </Paragraph>
          )}
        </div>
      )}
      <PostImages post={newsItem} />
    </PostPage>
  );
};

export default NewsItemPage;
