import { useSelector } from 'react-redux';

import useCurrentMember from '../hooks/useCurrentMember';
import {
  getConversationAttachments,
  getConversationCreator,
  getConversationJobPost,
  getConversationNotes,
  getConversationRecipient
} from '../selectors';
import { JSONApi, State } from '../types';

const useConversation = (conversation: JSONApi.ConversationResource) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const attachments = getConversationAttachments(apiData, conversation);
  const creator = getConversationCreator(apiData, conversation);
  const jobPost = getConversationJobPost(apiData, conversation);
  const recipient = getConversationRecipient(apiData, conversation);
  const notes = getConversationNotes(apiData, conversation);
  const currentMember = useCurrentMember();

  // it's weird to do it this way but it means that the counterparty gets typed correctly
  // and it will track the loading state of the current member.
  let counterparty = currentMember;
  if (currentMember) {
    counterparty = currentMember.id === creator.id ? recipient : creator;
  }

  return {
    attachments,
    counterparty,
    creator,
    jobPost,
    notes,
    recipient
  };
};

export default useConversation;
