import { DateTime } from 'luxon';

import { GenericJSON, GenericJSONValue } from '../services/jsonApiUtils';
import { JSONApi, ModelAttributes } from '../types';

export const isNull = (value: GenericJSONValue): value is null => value === null;

export const isUndefined = (value: GenericJSONValue): value is undefined => value === undefined;

export const isArray = (value: GenericJSONValue): value is GenericJSON[] => value instanceof Array;

export const isDateTime = (value: GenericJSONValue): value is DateTime => value instanceof DateTime;

export const isNumber = (value: GenericJSONValue): value is number =>
  typeof value === 'number' || value instanceof Number;

export const isObject = (value: GenericJSONValue): value is GenericJSON =>
  !isNull(value) && typeof value === 'object';

export const isString = (value: GenericJSONValue): value is string =>
  typeof value === 'string' || value instanceof String;

export const isBlob = (value: GenericJSONValue): value is GenericJSON =>
  !isArray(value) &&
  !isDateTime(value) &&
  !isNull(value) &&
  !isNumber(value) &&
  !isString(value) &&
  !isUndefined(value) &&
  typeof value.size === 'number' &&
  typeof value.type === 'string' &&
  typeof value.slice === 'function';

export const isFile = (value: GenericJSONValue): value is GenericJSON =>
  isBlob(value) &&
  typeof value.name === 'string' &&
  (typeof value.lastModifiedDate === 'object' || typeof value.lastModified === 'number');

// custom types

export const isComment = (resource: JSONApi.BaseResource): resource is JSONApi.CommentResource =>
  resource.type === 'comment';

export const isDiscussion = (post: JSONApi.PostResource): post is JSONApi.DiscussionResource =>
  post.type === ModelAttributes.PostType.DISCUSSION;

export const isEvent = (post: JSONApi.PostResource): post is JSONApi.EventResource =>
  post.type === ModelAttributes.PostType.EVENT;

export const isGroup = (resource: JSONApi.BaseResource): resource is JSONApi.GroupResource =>
  resource.type === 'group';

export const isJobPost = (post: JSONApi.PostResource): post is JSONApi.JobPostResource =>
  post.type === ModelAttributes.PostType.JOB_POST;

export const isMember = (resource: JSONApi.BaseResource): resource is JSONApi.MemberResource =>
  resource.type === 'member';

export const isNewsItem = (post: JSONApi.PostResource): post is JSONApi.NewsItemResource =>
  post.type === ModelAttributes.PostType.NEWS_ITEM;

export const isPost = (resource: JSONApi.BaseResource): resource is JSONApi.PostResource =>
  (Object.values(ModelAttributes.PostType) as string[]).includes(resource.type);
