/*
 * this component overwrites IonInput with some default behavior we always need:
 * 1) extend the autofocus behavior with our autofocus hook
 * 2) set default values for certain props that make sense in most cases for inputs
 */

import { IonInput } from '@ionic/react';
import React, { createRef } from 'react';

import useAutofocus from '../../hooks/useAutofocus';

const Input = (
  ionInputProps: Omit<
    React.ComponentProps<typeof IonInput>,
    // omit native input props for which ionic has a different prop
    'autoCapitalize' | 'autoCorrect' | 'spellCheck'
  >
) => {
  const ref = createRef<HTMLIonInputElement>();
  const { autofocus } = ionInputProps;
  useAutofocus(!!autofocus, ref);

  return (
    // eslint-disable-next-line react/forbid-elements
    <IonInput
      // defaults
      autocapitalize="off"
      autocomplete="off"
      autocorrect="off"
      color="primary"
      spellcheck={false}
      // overwrite defaults with client-supplied props
      {...ionInputProps}
      ref={ref}
    />
  );
};

export default Input;
