import { InputChangeEventDetail } from '@ionic/react';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import Input from '../../components/forms/Input';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { forceArray } from '../../services/arrayUtils';
import { updateGroup } from '../../thunks/apiThunks';
import { JSONApi, Models } from '../../types';

type Props = {
  attribute: 'primaryColor' | 'secondaryColor';
  getColor: string;
  group: JSONApi.GroupResource;
  setColor: (color: string) => void;
};

const GroupColorField = ({ attribute, getColor, group, setColor }: Props) => {
  const dispatch = useThunkDispatch();
  const intl = useIntl();

  const handleIonChange = useCallback(
    (event: CustomEvent<InputChangeEventDetail>) => {
      const target = event.target as HTMLIonInputElement & typeof event.target;
      if (target?.value) {
        const rgx = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);
        const isMatch = !!rgx.exec(target.value as string);
        if (isMatch) {
          dispatch(updateGroup({ [attribute]: target.value }, group.id)).then(
            (response: JSONApi.Response<Models.Group>) => {
              const updatedGroup = forceArray(response.data)[0];
              if (updatedGroup?.attributes?.[attribute]) {
                setColor(updatedGroup.attributes[attribute] as string);
              }
            }
          );
        }
      }
    },
    [attribute, dispatch, group.id, setColor]
  );

  return (
    <Input
      aria-label={intl.formatMessage({ id: `models.group.attributes.${attribute}.label` })}
      maxlength={7}
      name="color"
      onIonChange={handleIonChange}
      pattern="^#[A-Fa-f0-9]{6}$"
      placeholder={intl.formatMessage({ id: `models.group.attributes.${attribute}.placeholder` })}
      type="text"
      value={getColor}
    />
  );
};

export default GroupColorField;
