import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import GroupLandingPage from '../../components/GroupLandingPage';
import RequireCurrentUser from '../../components/RequireCurrentUser';
import RequireNoCurrentMember from '../../components/RequireNoCurrentMember';
import ResetPasswordPage from '../../components/ResetPasswordPage';
import GroupTabsContainer from '../../components/routing/GroupTabsContainer';
import GuardAgainstIncompleteUser from '../../components/routing/GuardAgainstIncompleteUser';
import SetupAccountPage from '../../components/SetupAccountPage';
import UserAccountWizardPage from '../../components/UserAccountWizardPage';
import VerificationPage from '../../components/VerificationPage';
import useCurrentMember from '../../hooks/useCurrentMember';
import useCurrentUser from '../../hooks/useCurrentUser';
import { JSONApi, ModelAttributes } from '../../types';

type RootProps = {
  currentGroup: JSONApi.GroupResource;
};

const Root = ({ currentGroup }: RootProps) => {
  const currentMember = useCurrentMember();
  const currentUser = useCurrentUser();

  if (currentUser) {
    if (currentMember) {
      if (currentMember.attributes.status === ModelAttributes.MemberStatus.APPROVED) {
        return <GroupTabsContainer currentGroup={currentGroup} currentUser={currentUser} />;
      }
      if (currentUser.attributes.userComplete) {
        return <Redirect to={`/g/${currentGroup.attributes.slug}/register/verification`} />;
      }
      return <Redirect to={`/g/${currentGroup.attributes.slug}/register/setup-account`} />;
    }
    // If there is a user but no member then we need to allow this to fall through so we wind
    // up at the group landing page.
  }
  // no current user or no current member with both fall through to here
  return <Redirect to={`/g/${currentGroup.attributes.slug}/start`} />;
};

type Props = {
  currentGroup: JSONApi.GroupResource;
};

const GroupRoutes = ({ currentGroup }: Props) => (
  <GuardAgainstIncompleteUser group={currentGroup}>
    <Switch>
      <Route path="/g/:slug/start">
        {() => (
          <RequireNoCurrentMember redirect={`/g/${currentGroup.attributes.slug}`}>
            <GroupLandingPage group={currentGroup} />
          </RequireNoCurrentMember>
        )}
      </Route>
      <Route path="/g/:slug/join">
        {() => (
          <RequireNoCurrentMember redirect={`/g/${currentGroup.attributes.slug}`}>
            <UserAccountWizardPage group={currentGroup} />
          </RequireNoCurrentMember>
        )}
      </Route>
      <Route path="/g/:slug/register/setup-account">
        {() => (
          <RequireCurrentUser group={currentGroup}>
            {user => <SetupAccountPage currentUser={user} group={currentGroup} />}
          </RequireCurrentUser>
        )}
      </Route>
      <Route path="/g/:slug/register/verification">
        {() => (
          <RequireCurrentUser group={currentGroup}>
            {user => <VerificationPage group={currentGroup} user={user} />}
          </RequireCurrentUser>
        )}
      </Route>
      <Route path="/g/:slug/reset-password/:token">
        {() => <ResetPasswordPage group={currentGroup} />}
      </Route>
      <Route path="/">{() => <Root currentGroup={currentGroup} />}</Route>
    </Switch>
  </GuardAgainstIncompleteUser>
);

export default GroupRoutes;
