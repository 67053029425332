import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonLabel,
  IonRouterLink,
  IonSearchbar,
  IonText,
  SearchbarChangeEventDetail
} from '@ionic/react';
import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import GroupCard from '../components/GroupCard';
import Page from '../components/layout/Page';
import PageHeader from '../components/layout/PageHeader';
import PageTitle from '../components/layout/PageTitle';
import LocalLoadingIndicator from '../components/LocalLoadingIndicator';
import LoginButton from '../components/LoginButton';
import MyGroupsList from '../components/MyGroupsList';
import Nbsp from '../components/Nbsp';
import useAutofocus from '../hooks/useAutofocus';
import useCurrentUserGroups from '../hooks/useCurrentUserGroups';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { getGroupSearchResults } from '../selectors';
import { forceArray } from '../services/arrayUtils';
import { loadGroupSearch } from '../thunks/apiThunks';
import { JSONApi, State } from '../types';

import './SettingsList.scss';

const GroupSearchPage = () => {
  const apiData = useSelector((root: State.Root) => root.api);
  const contentRef = createRef<HTMLIonContentElement>();
  const currentUserGroups = useCurrentUserGroups();
  const dispatch = useThunkDispatch();
  const focusedIndex = useRef<number>(-1);
  const searchbarRef = createRef<HTMLIonSearchbarElement>();
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [search, setSearch] = useState<JSONApi.GroupSearchResource | null>(null);
  const [resultRefs, setResultRefs] = useState<React.RefObject<HTMLIonCardElement>[]>([]);
  const [results, setResults] = useState<JSONApi.GroupResource[]>([]);

  useEffect(() => {
    setResultRefs(results?.map(() => createRef<HTMLIonCardElement>()) ?? []);
  }, [results]);

  useAutofocus(true, searchbarRef);

  const generateHandleFocus = useCallback(
    (index: number) => () => {
      focusedIndex.current = index;
    },
    []
  );

  const handleBlur = useCallback(() => {
    focusedIndex.current = -1;
  }, []);

  const handleIonChange = useCallback((event: CustomEvent<SearchbarChangeEventDetail>) => {
    setQuery(event.detail.value ?? '');
  }, []);

  useEffect(() => {
    if (query) {
      setLoading(true);
      dispatch(loadGroupSearch({ query }))
        .then(result => {
          result.dataLoadedIntoStatePromise
            .then(() => {
              setLoading(false);
              setSearch(forceArray(result.data)[0]);
            })
            .catch(() => {
              setLoading(false);
              setSearch(forceArray(result.data)[0]);
            });
        })
        .catch(() => {
          setLoading(false);
          setSearch(null);
        });
    } else {
      setSearch(null);
    }
  }, [dispatch, query]);

  useEffect(() => {
    if (search) {
      setResults(getGroupSearchResults(apiData, search));
    } else {
      setResults([]);
    }
  }, [apiData, search]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        // prevent the default behavior of scrolling the content area
        event.preventDefault();

        // if the content ref is not currently focused, you have to focus it first
        // before focusing an option
        if (focusedIndex.current < 0 && contentRef.current) {
          contentRef.current.focus();
        }
        let nextIndex = 0;
        if (event.key === 'ArrowDown') {
          nextIndex = (focusedIndex.current + 1) % resultRefs.length;
        }
        if (event.key === 'ArrowUp') {
          if (focusedIndex.current > 0) {
            nextIndex = focusedIndex.current - 1;
          } else {
            nextIndex = resultRefs.length - 1;
          }
        }
        const refToFocus = resultRefs[nextIndex];
        if (refToFocus?.current) {
          refToFocus.current.focus();
        }
      }
    },
    [contentRef, resultRefs]
  );

  return (
    <Page className="centered group-search-page tight">
      <PageHeader buttonsRight={<LoginButton />} />
      <IonContent className="canvas ion-padding" ref={contentRef}>
        <PageTitle id="pages.groupSearch.title" />
        <h1 className="ion-text-center ion-padding-bottom">
          <FormattedMessage id="pages.groupSearch.title" />
        </h1>
        <div onKeyDown={handleKeyDown} role="tab" tabIndex={0}>
          <IonCard>
            <IonCardHeader>
              <h2 className="ion-no-margin">
                <IonText color="primary">
                  <FormattedMessage id="pages.groupSearch.find" />
                </IonText>
              </h2>
            </IonCardHeader>
            <IonCardContent>
              <IonSearchbar color="light" onIonChange={handleIonChange} ref={searchbarRef} />
              <div className="ion-text-center">
                <IonRouterLink routerLink="/create">
                  <IonLabel>
                    <FormattedMessage id="pages.groupSearch.orCreateOne" />
                  </IonLabel>
                </IonRouterLink>
              </div>
            </IonCardContent>
          </IonCard>
          {!loading && results.length === 0 && query && (
            <div className="ion-padding">
              <IonText color="primary">
                <FormattedMessage id="pages.groupSearch.noResults" />
                <Nbsp />
                <strong>{query}</strong>
              </IonText>
            </div>
          )}
          {loading && <LocalLoadingIndicator />}
          {results.map((group, index) => (
            <GroupCard
              forwardRef={resultRefs[index]}
              group={group}
              key={group.id}
              onBlur={handleBlur}
              onFocus={generateHandleFocus(index)}
            />
          ))}
        </div>
        <div>
          {currentUserGroups.any && (
            <IonCard>
              <IonCardHeader>
                <h2 className="ion-no-margin">
                  <IonText color="primary">
                    <FormattedMessage id="dictionary.myGroups" />
                  </IonText>
                </h2>
              </IonCardHeader>
              <IonCardContent>
                <MyGroupsList groups={currentUserGroups.joined} id="dictionary.groupsIveJoined" />
                <MyGroupsList groups={currentUserGroups.joining} id="dictionary.groupsImJoining" />
                <MyGroupsList
                  groups={currentUserGroups.creating}
                  id="dictionary.groupsImCreating"
                />
              </IonCardContent>
            </IonCard>
          )}
        </div>
      </IonContent>
    </Page>
  );
};

export default GroupSearchPage;
