const pages = {
  account: {
    deleteExplanation:
      'This button permanently deletes all of your data and group memberships. Account deletion ' +
      'cannot be undone.',
    h2: 'Edit Your Group Memberships',
    h3: 'Delete Your Account',
    title: 'Edit Your Account and Profiles'
  },
  admin: {
    attachments: {
      approve: {
        failure: 'Failed to approve the attachment.',
        success: 'Attachment approved'
      },
      link: 'Attachments',
      title: 'Attachments'
    },
    group: {
      title: 'Manage Group'
    },
    groups: {
      link: 'Groups',
      title: 'Groups'
    },
    headerTitle: 'Admin',
    home: {
      title: 'Select a section'
    },
    images: {
      approve: {
        failure: 'Failed to approve the image.',
        success: 'Image approved'
      },
      link: 'Images',
      title: 'Images'
    }
  },
  blocks: {
    none: `You haven't blocked any members or hidden any content yet.`,
    title: 'Blocked and Hidden Content',
    titleShort: 'Blocked Content'
  },
  changeEmail: {
    title: 'Change your email'
  },
  changePassword: {
    title: 'Change your password'
  },
  checkForEmailConfirmation: {
    button: 'Send the confirmation email again',
    imageAlt: 'Check for email confirmation page banner image',
    pPrefix: '...and tap the button in the email we sent to ',
    pSuffix: ' to log in.',
    warning:
      'You have already started to create an account and need to confirm your email address ' +
      'before you can move forward.'
  },
  checkForPasswordRecovery: {
    button: 'Send the password recovery email again',
    imageAlt: 'Check for password recovery page banner image',
    pPrefix: '...and tap the button in the email we sent to ',
    pSuffix: ' to reset your password.'
  },
  confirmEmail: {
    title: 'Confirming Your Email Address'
  },
  conversation: {
    title: 'Conversation with {counterparty}'
  },
  createGroup: {
    creating: {
      title: `Okay, let's get started!`
    },
    customizing: {
      title: 'Customize your group'
    },
    groupCreationDisabled: {
      body: "You can’t start a group in the app, you can only join one. We know it's a pain. Once your group is ready you can sign into it here.",
      title: 'Trying to start a group?'
    }
  },
  editClosedMemberProfile: {
    explanation:
      'From here, you can either restore your Member Profile to regain access to the group, or ' +
      'permanently delete it.',
    title: 'This Member Profile is Frozen'
  },
  editMemberProfile: {
    title: 'Edit Your {group} Member Profile'
  },
  enterInviteCode: {
    title: 'Enter an Invite Code'
  },
  event: {
    attendees: {
      noResults: 'No one has responded just yet. RSVP using the button above.',
      title: "Who's going to {title}"
    },
    removeRsvp: 'Undo your RSVP',
    rsvp: 'RSVP to this event'
  },
  events: {
    noResults: 'There are no events on the calendar yet. Use the button below to add one.',
    title: 'Events'
  },
  forum: {
    noResults: 'There are no posts in the forum yet. Use the button below to add one.',
    title: 'Forum'
  },
  gallery: {
    noImages: 'There are no images in the gallery right now.',
    title: 'Gallery'
  },
  groupAbout: {
    title: 'About'
  },
  groupHome: {
    h2: 'The Latest',
    memberCount: '{count, plural, one {# Member} other {# Members}}',
    title: 'Home'
  },
  groupLanding: {
    title: 'Welcome to {group}'
  },
  groupNotifications: {
    locationFiltersExplanation:
      'By adding location filters, your newsletters and notifications will include only content ' +
      'that either has no location or is located near one of the locations you select.',
    locationFiltersHeader: 'Target notifications by location',
    title: 'Notification Settings for {name}'
  },
  groupSearch: {
    find: 'Find a group to join',
    noResults: 'There are no group search results for',
    orCreateOne: 'Or Create One',
    title: 'Search Groups'
  },
  groupWizard: {
    account: {
      description: 'Almost done! Create a personal profile to finish creating your group.',
      title: 'Your Account'
    },
    features: {
      description: 'Which group features do you want to enable? You can always change this later.',
      title: 'Features'
    },
    freePlan: {
      description:
        `Your group will be automatically set up on our free plan which allows up to 15 individual members. ` +
        `We have plans available for larger groups, but you cannot upgrade your plan in the app. Sorry, we ` +
        `know it's a pain.`,
      error: 'Hmm, something went wrong. Please try again, or contact support.',
      title: `You're on the free plan`
    },
    names: {
      title: 'The Basics'
    },
    payment: {
      description:
        `Don't short change your privacy! Grouplicate charges a small monthly fee so we ` +
        `can keep your group and your data safe and secure. Our rates are below.`,
      title: 'Pick a plan'
    },
    personalization: {
      description:
        'Time to get creative. Add a background image, avatar, and color for your group.',
      title: 'Personalize'
    },
    rules: {
      description: `Please list any "rules of the road" for your new group`,
      title: 'Add Group Rules'
    },
    share: {
      description:
        "Send invitations to get members onboard. When you're done, click on the Next button below.",
      h3Email: 'Send email invites',
      title: 'Invite your group members'
    },
    success:
      'You have finished setting up your group! Create a post to welcome the first members to arrive.',
    traits: {
      title: 'Group-Specific Information'
    },
    verification: {
      description: 'Do you want to verify members before they join this group?',
      title: 'Verification Settings'
    }
  },
  inbox: {
    noResults: `You haven't sent or received any messages yet.`,
    title: 'Inbox'
  },
  jobBoard: {
    noResults: 'There are no jobs on the board yet. Use the button below to add one.',
    title: 'Job Board'
  },
  landing: {
    deviceShowcase: {
      alt: 'Product image'
    },
    headline: 'Better Group Networking',
    subheadline:
      `Grouplicate is the safe alternative to social media for groups with a shared ` +
      `purpose and passion.`,
    toJoin: 'to join'
  },
  login: {
    imageAlt: 'Login page banner image',
    passwordlessLoginSuccess: 'Check your email for a login link',
    sendEmail: 'Send email',
    title: 'Login',
    usePassword: 'Login using a password',
    usePasswordless: 'Get a login link emailed to you'
  },
  manageGroup: {
    customize: {
      link: 'Customize',
      title: 'Customize Group'
    },
    features: {
      link: 'Features',
      title: 'Manage Features'
    },
    flag: {
      title: 'Review This Flag'
    },
    flags: {
      link: 'Flags',
      title: 'Review Flagged Content'
    },
    groupRules: {
      link: 'Group Rules',
      title: 'Manage Group Rules'
    },
    invites: {
      link: 'Invites',
      title: 'Manage Invites'
    },
    links: {
      link: 'Links',
      title: 'Add Group Links'
    },
    members: {
      link: 'Members',
      title: 'Manage Members'
    },
    plan: {
      billingHistory: 'Billing history',
      cancel: 'Unsubscribe',
      currentPlan: 'Current plan',
      freePlan: 'This plan is free.',
      link: 'Subscription',
      noActivePlan: 'Select a plan to get started',
      planCancellationEffective:
        'Your subscription has been canceled. At the end of your current billing cycle ' +
        'on {effectiveDate} the group will become read-only. Prior to {effectiveDate} you will be able to reactivate ' +
        'your plan to maintain service.',
      planManagementDisabled: `You can’t manage plans in the app. We know it's a pain. `,
      reactivate: 'Reactivate',
      title: 'Manage Subscription',
      updatePaymentInfo: 'Update payment info'
    },
    posts: {
      link: 'Posts',
      title: 'Manage Posted Content'
    },
    security: {
      link: 'Security Settings',
      title: 'Manage Security Settings'
    },
    selectSection: {
      title: 'Select a section'
    },
    tools: {
      title: 'Manage Group'
    },
    traits: {
      link: 'Group-Specific Information',
      title: 'Manage Group-Specific Information'
    }
  },
  meAndMyData: {
    title: 'Me and My Data'
  },
  memberProfile: {
    title: '{name}'
  },
  members: {
    title: 'Members'
  },
  mentions: {
    none: `You haven't been mentioned yet.`,
    title: 'Mentions'
  },
  moreSections: {
    title: 'Sections'
  },
  news: {
    noResults: 'There is no news posted to the feed yet. Please check back later.',
    title: 'News'
  },
  notifications: {
    noGroups:
      `You haven't joined a group yet. You'll need to join one first before you ` +
      `have any notification settings to manage.`,
    note:
      `You can control the settings for each group you join individually. Pick one of ` +
      `your groups below to modify your email and notification settings for that group.`,
    title: 'Notification Settings'
  },
  post: {
    edit: 'Edit',
    follow: 'Turn on notifications',
    unfollow: 'Turn off notifications'
  },
  rejectedGroup: {
    body: 'The group was found to be in voilation of the Grouplicate Terms of Service.',
    h2: 'This group has been disabled',
    ownerSteps:
      'If you are the owner of the group, you can contact support for further information ' +
      'or to begin the process of having the group reinstated.'
  },
  resetPassword: {
    h2: 'Reset your password',
    imageAlt: 'Reset password page banner image',
    title: 'Account'
  },
  saves: {
    membersFilter: 'Members ({count})',
    membersNoSaves: "You haven't saved any members yet",
    noSaves: "You haven't saved anything yet",
    postsFilter: 'Posts ({count})',
    postsNoSaves: "You haven't saved any posts yet",
    title: 'Saved Items'
  },
  setupAccount: {
    h2: 'Fill out your profile',
    title: 'Account'
  },
  setupPassword: {
    h2: 'Choose a strong password, at least {min} characters long',
    imageAlt: 'Setup password page banner image',
    title: 'Create a password',
    warning:
      'You have already started to create an account and need to add a password before ' +
      'you can move forward.'
  },
  start: {
    closeButton: 'Logout',
    createGroup: {
      button: 'Create a Group',
      header: 'Get your group on Grouplicate'
    },
    findInvite: {
      header: 'Did you receive an invite code?',
      link: 'Enter your code'
    },
    inviteCount: `You've received {count, plural, one {# group invite} other {# group invites}} so far. `,
    inviteLabel: `{group} ({count, plural, one {# member} other {# members}})`,
    justBrowsing: `I'm just browsing`,
    subtitle: 'Accept invites, create a group, or search for others to join.',
    title: `Let's get started!`
  },
  titleSuffixWithGroup: ' | Grouplicate for {group}',
  titleSuffixWithoutGroup: ' | Grouplicate',
  userAccountWizard: {
    title: 'Create or sign into your account'
  },
  verification: {
    title: 'Verify your membership'
  },
  verificationProfileSettings: {
    h2: 'Complete your Profile'
  },
  verificationQuestion: {
    alreadyAMember: 'Already a member?',
    h2: 'Verification question'
  },
  verificationRules: {
    h2: 'Group Rules',
    p: 'To join {group}, you must agree to abide by the following rules.'
  },
  verificationSettings: {
    customize: 'Customize them',
    h2: 'Notification settings'
  },
  verificationWaiting: {
    h2: 'Your request to join has been submitted',
    maxCapacityMessage:
      `This group currently has the maximum number of allowed members. The group moderator has ` +
      `been notified of your request to join and given options to create space for your membership. ` +
      `There's nothing more for you to do at the moment. You'll be notified if there's any change ` +
      `to your membership status. If you'd like to search for other groups you can join, use the ` +
      `button below.`,
    messageWithWhitelisting:
      'At this point you can either find other groups to join using the button below, try to ' +
      'get your account approved automatically using the whitelisting process below, or just ' +
      'wait for a moderator to review your profile for access.',
    messageWithoutWhitelisting:
      `There's nothing more for you to do at the moment. You'll be notified once a moderator has ` +
      `reviewed your profile. If you'd like to search for other groups you can join, use the button ` +
      `below.`,
    whitelistingExplanationIntro:
      '{groupName} requires that a group moderator review your application before giving you access ' +
      "to the site. This process shouldn't take long, but you can also be automatically approved using " +
      'an alternative email address:',
    whitelistingExplanationWithDomains:
      'If you have an email address under any of the following domains, then entering it here and then ' +
      'confirming your ownership will get you automatic access:',
    whitelistingExplanationWithEmails:
      'If you already have a relationship with the group where you might have used a different email ' +
      'address that you still have access to, try adding it here.',
    whitelistingTitle: 'Have your account verified faster'
  }
};

export default pages;
