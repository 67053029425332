import React from 'react';
import { FormattedNumber } from 'react-intl';

type Props = {
  valueInCents: number;
};

const Currency = ({ valueInCents }: Props) => {
  const decimalValue = valueInCents / 100.0;

  const props: React.ComponentProps<typeof FormattedNumber> = {
    currency: 'USD',
    style: 'currency',
    value: decimalValue
  };

  if (decimalValue === Math.round(decimalValue)) {
    props.maximumFractionDigits = 0;
    props.minimumFractionDigits = 0;
  }

  return <FormattedNumber {...props} />;
};

export default Currency;
