import { IonItem, IonLabel } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import RequiredFieldMarker from '../../components/forms/RequiredFieldMarker';

const FormItem = (
  props: Pick<React.ComponentProps<typeof FormattedMessage>, 'id' | 'values'> &
    Pick<React.ComponentProps<typeof IonItem>, 'color' | 'lines'> &
    Pick<React.ComponentProps<typeof IonLabel>, 'position'> &
    React.PropsWithChildren<{ required?: boolean }>
) => {
  const { children, id, position = 'stacked', required = false, values, ...ionItemProps } = props;

  return (
    <IonItem lines="inset" {...ionItemProps}>
      {id && (
        <IonLabel className="strong" position={position}>
          <FormattedMessage id={id} values={values} />
          {required && <RequiredFieldMarker />}
        </IonLabel>
      )}
      {children}
    </IonItem>
  );
};

export default FormItem;
