import { IonRouterLink, IonText } from '@ionic/react';
import LinkifyIt from 'linkify-it';
import React from 'react';
import { useSelector } from 'react-redux';
import tlds from 'tlds';

import ExternalLink from '../components/ExternalLink';
import useCurrentGroup from '../hooks/useCurrentGroup';
import { findBySlug } from '../selectors';
import { forceArray } from '../services/arrayUtils';
import { State } from '../types';

type Props = {
  autolink?: boolean;
  children: string;
  color?: string;
  mentionable?: boolean;
};

const FormatText = ({ autolink = false, children, color, mentionable = false }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const currentGroup = useCurrentGroup();

  type Output = (string | JSX.Element)[];

  let parsedText: Output = forceArray(children);

  const parseForMentions = (text: string) => {
    const elements: Output = [];
    if (!currentGroup) {
      elements.push(text);
      return elements;
    }
    const mentions = text.match(/@(\w|-)+/g);
    if (!mentions) {
      elements.push(text);
    } else {
      let scanIndex = 0;
      mentions.forEach(mention => {
        const memberSlug = mention.slice(1);
        const member = findBySlug(apiData, 'member', memberSlug, currentGroup);
        if (member) {
          // only search forward from the scanIndex to avoid matching previous mentions
          const index = scanIndex + text.slice(scanIndex).search(mention);
          // realistically these should _always_ be found, but might as well check
          if (index >= 0) {
            if (index > scanIndex) {
              elements.push(text.slice(scanIndex, index));
            }
            elements.push(
              <IonRouterLink
                key={`${mention}-${scanIndex.toString()}`}
                routerLink={`/g/${currentGroup.attributes.slug}/members/${memberSlug}`}
              >
                {mention}
              </IonRouterLink>
            );
            scanIndex = index + mention.length;
          }
        }
      });
      if (text.length > scanIndex) {
        elements.push(text.slice(scanIndex));
      }
    }
    return elements;
  };

  const parseForLinks = (text: string) => {
    const elements: Output = [];
    const linkify = new LinkifyIt();
    linkify.tlds(tlds);
    const links = linkify.match(text);
    if (!links) {
      elements.push(text);
      return elements;
    }
    let scanIndex = 0;
    // autolink the text
    links.forEach(link => {
      if (link.index > scanIndex) {
        elements.push(text.slice(scanIndex, link.index));
      }
      elements.push(
        <ExternalLink href={link.url} key={`${link.text}-${scanIndex.toString()}`}>
          {link.text}
        </ExternalLink>
      );
      scanIndex = link.lastIndex;
    });
    if (text.length > scanIndex) {
      elements.push(text.slice(scanIndex));
    }
    return elements;
  };

  if (autolink) {
    const elements: Output = [];
    parsedText.forEach(element => {
      if (typeof element === 'string') {
        parseForLinks(element).forEach(newElement => elements.push(newElement));
      } else {
        elements.push(element);
      }
    });
    parsedText = elements;
  }

  if (mentionable) {
    const elements: Output = [];
    parsedText.forEach(element => {
      if (typeof element === 'string') {
        parseForMentions(element).forEach(newElement => elements.push(newElement));
      } else {
        elements.push(element);
      }
    });
    parsedText = elements;
  }

  return <IonText color={color}>{parsedText}</IonText>;
};

export default FormatText;
