import { IonButton, IonButtons, IonItem } from '@ionic/react';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

const BlockedItem = ({
  children,
  isLast,
  onClick
}: PropsWithChildren<{
  isLast: boolean;
  onClick: () => void;
}>) => (
  <IonItem lines={isLast ? 'none' : 'inset'}>
    {children}
    <IonButtons slot="end">
      <IonButton color="danger" onClick={onClick}>
        <FormattedMessage id="dictionary.unblock" />
      </IonButton>
    </IonButtons>
  </IonItem>
);

export default BlockedItem;
