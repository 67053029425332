import { IonCard, IonCardContent, IonCardHeader } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import Form from '../../components/forms/Form';
import FormCondition from '../../components/forms/FormCondition';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormInput from '../../components/forms/FormInput';
import FormTip from '../../components/forms/FormTip';
import FormToggleItem from '../../components/forms/FormToggleItem';
import SubmitError from '../../components/forms/SubmitError';
import Heading from '../../components/Heading';
import Paragraph from '../../components/Paragraph';
import { maxTextInputLength } from '../../globals';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { handleFormSubmission } from '../../services/formUtils';
import { updateGroup } from '../../thunks/apiThunks';
import { JSONApi } from '../../types';

type FormValues = {
  careersEnabled: boolean;
  eventsEnabled: boolean;
  forumEnabled: boolean;
  galleryEnabled: boolean;
  htmlNewsSource: string;
  htmlNewsSourceSelector: string;
  locationFeaturesEnabled: boolean;
  newsEnabled: boolean;
  rssNewsSource: string;
  twitterHandleNewsSource: string;
  usersCanCreateNews: boolean;
};

const GroupFeaturesForm = ({
  children,
  group,
  onSuccess,
  showNews = true
}: React.PropsWithChildren<{
  group: JSONApi.GroupResource;
  onSuccess: () => void;
  showNews?: boolean;
}>) => {
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const initialValues: FormValues = {
    careersEnabled: group.attributes.careersEnabled,
    eventsEnabled: group.attributes.eventsEnabled,
    forumEnabled: group.attributes.forumEnabled,
    galleryEnabled: group.attributes.galleryEnabled,
    htmlNewsSource: group.attributes.htmlNewsSource,
    htmlNewsSourceSelector: group.attributes.htmlNewsSourceSelector,
    locationFeaturesEnabled: group.attributes.locationFeaturesEnabled,
    newsEnabled: group.attributes.newsEnabled,
    rssNewsSource: group.attributes.rssNewsSource,
    twitterHandleNewsSource: group.attributes.twitterHandleNewsSource,
    usersCanCreateNews: group.attributes.usersCanCreateNews
  };

  const handleSubmit = useCallback(
    async (values: FormValues) =>
      handleFormSubmission({
        action: updateGroup(values, group.id),
        dispatch,
        errorTranslationLocation: 'group',
        intl,
        isMounted,
        onSuccess,
        values
      }),
    [dispatch, group, intl, isMounted, onSuccess]
  );

  const validationSchema = Yup.object().shape({
    careersEnabled: Yup.boolean(),
    eventsEnabled: Yup.boolean(),
    forumEnabled: Yup.boolean(),
    galleryEnabled: Yup.boolean(),
    htmlNewsSource: Yup.string()
      .nullable()
      .max(
        maxTextInputLength,
        intl.formatMessage(
          { id: 'errors.group.htmlNewsSource.tooLong' },
          { count: maxTextInputLength }
        )
      ),
    htmlNewsSourceSelector: Yup.string()
      .nullable()
      .max(
        maxTextInputLength,
        intl.formatMessage(
          { id: 'errors.group.htmlNewsSourceSelector.tooLong' },
          { count: maxTextInputLength }
        )
      ),
    locationFeaturesEnabled: Yup.boolean(),
    newsEnabled: Yup.boolean(),
    rssNewsSource: Yup.string()
      .nullable()
      .max(
        maxTextInputLength,
        intl.formatMessage(
          { id: 'errors.group.rssNewsSource.tooLong' },
          { count: maxTextInputLength }
        )
      ),
    twitterHandleNewsSource: Yup.string()
      .nullable()
      .max(
        maxTextInputLength,
        intl.formatMessage(
          { id: 'errors.group.twitterHandleNewsSource.tooLong' },
          { count: maxTextInputLength }
        )
      ),
    usersCanCreateNews: Yup.boolean()
  });

  return (
    <FormContainer<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ form, handleSubmit }) => (
        <Form<FormValues> onFormSubmit={handleSubmit} submit={form.submit}>
          <SubmitError />
          <FormError name="locationFeaturesEnabled" />
          <FormToggleItem<FormValues, 'locationFeaturesEnabled'>
            change={form.change}
            id="models.group.attributes.locationFeaturesEnabled.label"
            name="locationFeaturesEnabled"
          />
          <FormTip id="models.group.attributes.locationFeaturesEnabled.explanation" />
          <FormError name="forumEnabled" />
          <FormToggleItem<FormValues, 'forumEnabled'>
            change={form.change}
            id="models.group.attributes.forumEnabled.label"
            name="forumEnabled"
          />
          <FormError name="eventsEnabled" />
          <FormToggleItem<FormValues, 'eventsEnabled'>
            change={form.change}
            id="models.group.attributes.eventsEnabled.label"
            name="eventsEnabled"
          />
          <FormError name="careersEnabled" />
          <FormToggleItem<FormValues, 'careersEnabled'>
            change={form.change}
            id="models.group.attributes.careersEnabled.label"
            name="careersEnabled"
          />
          <FormToggleItem<FormValues, 'galleryEnabled'>
            change={form.change}
            id="models.group.attributes.galleryEnabled.label"
            name="galleryEnabled"
          />
          {showNews && (
            <>
              <FormError name="newsEnabled" />
              <FormToggleItem<FormValues, 'newsEnabled'>
                change={form.change}
                id="models.group.attributes.newsEnabled.label"
                name="newsEnabled"
              />
              <FormCondition name="newsEnabled">
                <IonCard>
                  <IonCardHeader>
                    <Heading level={3}>
                      <FormattedMessage id="models.group.attributes.newsEnabled.additionalSettings" />
                    </Heading>
                  </IonCardHeader>
                  <IonCardContent>
                    <FormError name="usersCanCreateNews" />
                    <FormToggleItem<FormValues, 'usersCanCreateNews'>
                      change={form.change}
                      id="models.group.attributes.usersCanCreateNews.label"
                      name="usersCanCreateNews"
                    />
                    <Heading className="ion-padding-bottom ion-padding-top" level={4}>
                      <FormattedMessage id="models.group.attributes.newsSource.label" />
                    </Heading>
                    <Paragraph color="primary">
                      <FormattedMessage id="models.group.attributes.newsSource.explanation" />
                    </Paragraph>
                    <FormError name="rssNewsSource" />
                    <FormInput
                      aria-label={intl.formatMessage({
                        id: 'models.group.attributes.rssNewsSource.label'
                      })}
                      name="rssNewsSource"
                      placeholder={intl.formatMessage({
                        id: 'models.group.attributes.rssNewsSource.placeholder'
                      })}
                      type="text"
                    />
                    <FormTip id="models.group.attributes.rssNewsSource.explanation" />
                    <FormError name="twitterHandleNewsSource" />
                    <FormInput
                      aria-label={intl.formatMessage({
                        id: 'models.group.attributes.twitterHandleNewsSource.label'
                      })}
                      name="twitterHandleNewsSource"
                      placeholder={intl.formatMessage({
                        id: 'models.group.attributes.twitterHandleNewsSource.placeholder'
                      })}
                      type="text"
                    />
                    <FormTip id="models.group.attributes.twitterHandleNewsSource.explanation" />
                    <FormError name="htmlNewsSource" />
                    <FormInput
                      aria-label={intl.formatMessage({
                        id: 'models.group.attributes.htmlNewsSource.label'
                      })}
                      name="htmlNewsSource"
                      placeholder={intl.formatMessage({
                        id: 'models.group.attributes.htmlNewsSource.placeholder'
                      })}
                      type="text"
                    />
                    <FormTip id="models.group.attributes.htmlNewsSource.explanation" />
                    <FormCondition name="htmlNewsSource">
                      <FormError name="htmlNewsSourceSelector" />
                      <FormInput
                        aria-label={intl.formatMessage({
                          id: 'models.group.attributes.htmlNewsSourceSelector.label'
                        })}
                        name="htmlNewsSourceSelector"
                        placeholder={intl.formatMessage({
                          id: 'models.group.attributes.htmlNewsSourceSelector.placeholder'
                        })}
                        type="text"
                      />
                      <FormTip id="models.group.attributes.htmlNewsSourceSelector.explanation" />
                    </FormCondition>
                  </IonCardContent>
                </IonCard>
              </FormCondition>
            </>
          )}
          {children}
        </Form>
      )}
    </FormContainer>
  );
};

export default GroupFeaturesForm;
