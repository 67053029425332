import { IonLabel, IonList } from '@ionic/react';
import { FormApi } from 'final-form';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormInput from '../../components/forms/FormInput';
import FormItem from '../../components/forms/FormItem';
import FormSubmit from '../../components/forms/FormSubmit';
import SubmitError from '../../components/forms/SubmitError';
import { minPasswordLength } from '../../globals';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { handleFormSubmission } from '../../services/formUtils';
import { updateUser } from '../../thunks/apiThunks';
import { JSONApi } from '../../types';

type FormValues = {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
};

type Props = {
  user: JSONApi.UserResource;
};

const ChangePasswordForm = ({ user }: Props) => {
  const history = useHistory();
  const intl = useIntl();
  const isMounted = useMountedTracking();
  const dispatch = useThunkDispatch();

  const initialValues: FormValues = {
    currentPassword: '',
    password: '',
    passwordConfirmation: ''
  };

  const handleSubmit = useCallback(
    async (values: FormValues, form: FormApi<FormValues>) =>
      handleFormSubmission({
        action: updateUser(values, user.id),
        dispatch,
        errorTranslationLocation: 'password',
        form,
        intl,
        isMounted,
        onSuccess: () => {
          history.goBack();
        },
        successTKey: 'forms.password.update.success',
        values
      }),
    [dispatch, history, intl, isMounted, user.id]
  );

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(
      intl.formatMessage({ id: 'errors.password.currentPassword.blank' })
    ),
    password: Yup.string()
      .required(intl.formatMessage({ id: 'errors.password.password.blank' }))
      .min(
        minPasswordLength,
        intl.formatMessage(
          { id: 'errors.password.password.tooShort' },
          { count: minPasswordLength }
        )
      ),
    passwordConfirmation: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: 'errors.password.passwordConfirmation.blank' }))
      .oneOf(
        [Yup.ref('password'), ''],
        intl.formatMessage({ id: 'errors.password.passwordConfirmation.doesNotMatch' })
      )
  });

  return (
    <FormContainer<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ form, handleSubmit }) => (
        <Form<FormValues>
          className="change-password-form"
          onFormSubmit={handleSubmit}
          submit={form.submit}
        >
          <IonLabel className="settings-list-label">
            <FormattedMessage id="models.user.attributes.currentPassword.label" />
          </IonLabel>
          <IonList className="ion-margin-bottom ion-no-padding settings-list">
            <SubmitError />
            <FormError allowBlank name="currentPassword" />
            <FormItem lines="none" required>
              <FormInput
                aria-label={intl.formatMessage({
                  id: 'models.user.attributes.currentPassword.label'
                })}
                autocapitalize="off"
                autofocus
                name="currentPassword"
                placeholder={intl.formatMessage({
                  id: 'models.user.attributes.currentPassword.placeholder'
                })}
                type="password"
              />
            </FormItem>
          </IonList>
          <IonLabel className="settings-list-label">
            <FormattedMessage id="models.user.attributes.newPassword.label" />
          </IonLabel>
          <IonList className="ion-margin-bottom ion-no-padding settings-list">
            <FormError allowBlank name="password" />
            <FormItem required>
              <FormInput
                aria-label={intl.formatMessage({
                  id: 'models.user.attributes.password.label'
                })}
                autocapitalize="off"
                name="password"
                placeholder={intl.formatMessage({
                  id: 'models.user.attributes.password.placeholder'
                })}
                type="password"
              />
            </FormItem>
            <FormError allowBlank name="passwordConfirmation" />
            <FormItem lines="none" required>
              <FormInput
                aria-label={intl.formatMessage({
                  id: 'models.user.attributes.passwordConfirmation.label'
                })}
                autocapitalize="off"
                name="passwordConfirmation"
                placeholder={intl.formatMessage({
                  id: 'models.user.attributes.passwordConfirmation.placeholder'
                })}
                type="password"
              />
            </FormItem>
          </IonList>
          <FormSubmit>
            <FormattedMessage id="dictionary.save" />
          </FormSubmit>
        </Form>
      )}
    </FormContainer>
  );
};

export default ChangePasswordForm;
