const dictionary = {
  aboutUs: 'About us',
  accept: 'Accept',
  accepted: 'Accepted',
  add: 'Add',
  addAnother: 'Add another',
  addAttachment: 'Include an attachment',
  addImage: 'Add an Image',
  all: 'All',
  allClear: 'All Clear!',
  and: 'and',
  apply: 'Apply',
  approve: 'Approve',
  approved: 'Approved',
  areYouSure: 'Are you sure?',
  at: 'at',
  back: 'Go back',
  backgroundImage: 'Background image',
  both: 'Both',
  bounced: 'Bounced',
  cancel: 'Cancel',
  changeEmail: 'Change Email',
  changePassword: 'Change Password',
  checkYourInbox: 'Check Your Inbox',
  clear: 'Clear',
  close: 'Close',
  closeParen: ')',
  comma: ',',
  confirmed: 'Confirmed',
  contactUs: 'Contact us',
  copyright: '©',
  dash: '-',
  delete: 'Delete',
  deleteComment: 'Delete Comment',
  deletePost: 'Delete Post',
  discussions: 'Discussions',
  dislike: 'Dislike',
  dislikes: 'Dislikes',
  doIt: 'Do It',
  done: 'Done',
  dot: '·',
  edit: 'Edit',
  email: 'Email',
  events: 'Events',
  findAGroup: 'Find a group',
  findAnotherGroup: 'Find another group',
  findMoreGroups: 'Find More Groups',
  finish: 'Finish',
  flag: 'Flag',
  flags: 'Flags',
  forgotPassword: 'Forgot your password?',
  free: 'Free',
  grouplicate: 'Grouplicate',
  groupsImCreating: "Groups I'm Creating",
  groupsImJoining: "Groups I'm Joining",
  groupsIveJoined: "Groups I've Joined",
  help: 'Help',
  hide: 'Hide',
  hideComments: 'Hide comments',
  ignore: 'Ignore',
  industry: 'Industry',
  inviteMembers: 'Invite Members',
  invitedOn: 'Invited on',
  join: 'Join',
  joined: 'Joined',
  justNow: 'just now',
  legal: 'Legal',
  like: 'Like',
  likes: 'Likes',
  link: 'Link',
  links: 'Links',
  loading: 'Please wait...',
  location: 'Location',
  logIn: 'Log In',
  login: 'Login',
  loginByEmail: 'Get a login link emailed to you',
  logo: 'Logo',
  logout: 'Logout',
  lookup: 'Look Up',
  makePrimary: 'Make Primary',
  me: 'Me',
  mentionSomeone: 'Mention someone',
  message: 'Message',
  moderator: 'Moderator',
  monthly: 'Monthly',
  more: 'More',
  moreSections: 'More Sections',
  myGroups: 'My Groups',
  needsReview: 'Needs Review',
  news: 'News',
  next: 'Next',
  none: 'None',
  notApplicable: 'Does not apply',
  ok: 'OK',
  on: 'on',
  options: 'Options',
  or: 'or',
  owners: 'Owners',
  plus: '+',
  post: 'Post',
  postNow: 'Post now',
  postedAt: 'Posted at',
  previous: 'Previous step',
  primary: 'Primary',
  private: 'Private',
  public: 'Public',
  published: 'Published',
  push: 'Push',
  randomAvatar: 'Random Avatar',
  reactivate: 'Reactivate',
  reject: 'Reject',
  rejected: 'Rejected',
  remote: 'Remote',
  remove: 'Remove',
  reorder: 'Reorder',
  requiredFieldMarker: '*',
  resendConfirmation: 'Resend Confirmation',
  reset: 'Reset',
  revoke: 'Revoke',
  revoked: 'Revoked',
  rsvp: 'RSVP',
  save: 'Save',
  search: 'Search',
  send: 'Send',
  sendMessage: 'Send a Message',
  sendWelcome: 'Send a Welcome',
  sent: 'Sent',
  show: 'Show',
  showComments: 'Show comments',
  signUpLogin: 'Sign Up/Login',
  skip: 'Skip',
  startAGroup: 'Start a Group',
  submit: 'Submit',
  terms: 'terms & conditions',
  to: 'To',
  toJoin: 'to join',
  unblock: 'Unblock',
  unconfirmed: 'Unconfirmed',
  update: 'Update',
  updateImage: 'Update Your Image',
  updatePaymentInformation: 'Update Payment Information',
  upgradeNow: 'Upgrade Now',
  view: 'View',
  viewContent: 'View Content',
  viewPost: 'View Post',
  viewProfile: 'View Profile',
  viewWelcome: 'See your Welcome',
  viewYourProfile: 'view your profile',
  whosGoing: "Who's going",
  yearly: 'Yearly',
  you: 'You'
};

export default dictionary;
