import React from 'react';

import FormModal from '../components/FormModal';
import DiscussionForm from '../components/forms/DiscussionForm';
import { Opener } from '../hooks/useOpener';
import { JSONApi } from '../types';

type Props = {
  discussion?: JSONApi.DiscussionResource;
  group: JSONApi.GroupResource;
  onSuccess?: () => void;
  opener: Opener;
  titleKey: string;
};

const DiscussionFormModal = ({ discussion, group, onSuccess, opener, titleKey }: Props) => (
  <FormModal keyboardClose={false} opener={opener} titleKey={titleKey}>
    {modalContentRef => (
      <DiscussionForm
        autofocus={opener.isPresented}
        contentForwardRef={modalContentRef}
        discussion={discussion}
        group={group}
        onSuccess={onSuccess}
        opener={opener}
      />
    )}
  </FormModal>
);

export default DiscussionFormModal;
