import { IonButton, IonButtons, IonItem, IonText } from '@ionic/react';
import { FormApi } from 'final-form';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormInput from '../../components/forms/FormInput';
import FormSubmit from '../../components/forms/FormSubmit';
import SubmitError from '../../components/forms/SubmitError';
import Heading from '../../components/Heading';
import Paragraph from '../../components/Paragraph';
import { maxEmailLength } from '../../globals';
import useApiData from '../../hooks/useApiData';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { getGroupWhitelistedDomains } from '../../selectors';
import { handleFormSubmission } from '../../services/formUtils';
import { createEmailAddress, loadWhitelistedDomains } from '../../thunks/apiThunks';
import { JSONApi, Models, State } from '../../types';

type Props = {
  group: JSONApi.GroupResource;
};

type FormValues = {
  email: string;
};

const VerificationWaitingForm = ({ group }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const findWhitelistedDomains = useCallback(
    () => getGroupWhitelistedDomains(apiData, group),
    [apiData, group]
  );
  const { responseData: domains } = useApiData<Models.WhitelistedDomain>(
    loadWhitelistedDomains(group.attributes.slug),
    findWhitelistedDomains,
    'VerificationWaitingForm'
  );
  const intl = useIntl();
  const dispatch = useThunkDispatch();
  const isMounted = useMountedTracking();
  const [hasWhitelisting, setHasWhitelisting] = useState(false);

  const initialValues: FormValues = {
    email: ''
  };

  useEffect(() => {
    setHasWhitelisting(group.attributes.hasInvites || domains.length > 0);
  }, [domains, group.attributes.hasInvites]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'errors.login.email.invalid' }))
      .required(intl.formatMessage({ id: 'errors.login.email.blank' }))
      .max(
        maxEmailLength,
        intl.formatMessage({ id: 'errors.emailAddress.email.tooLong' }, { count: maxEmailLength })
      )
  });

  const handleSubmit = useCallback(
    async (values: FormValues, form: FormApi<FormValues>) =>
      handleFormSubmission({
        action: createEmailAddress(group.attributes.slug, { email: values.email }),
        dispatch,
        errorTranslationLocation: 'login',
        form,
        intl,
        isMounted,
        successTKey: 'forms.verificationWaiting.success',
        values
      }),
    [dispatch, group.attributes.slug, intl, isMounted]
  );

  return (
    <div className="verification-waiting-form">
      <Heading level={2}>
        <FormattedMessage id="pages.verificationWaiting.h2" />
      </Heading>
      <Paragraph>
        {group.attributes.isAtMaxCapacity && (
          <FormattedMessage id="pages.verificationWaiting.maxCapacityMessage" />
        )}
        {!group.attributes.isAtMaxCapacity && hasWhitelisting && (
          <FormattedMessage id="pages.verificationWaiting.messageWithWhitelisting" />
        )}
        {!group.attributes.isAtMaxCapacity && !hasWhitelisting && (
          <FormattedMessage id="pages.verificationWaiting.messageWithoutWhitelisting" />
        )}
      </Paragraph>
      <IonButton color="primary" expand="block" routerLink="/search">
        <FormattedMessage id="dictionary.findMoreGroups" />
      </IonButton>
      {!group.attributes.isAtMaxCapacity && hasWhitelisting && (
        <>
          <IonText color="primary">
            <h2>
              <FormattedMessage id="pages.verificationWaiting.whitelistingTitle" />
            </h2>
            <div>
              <p>
                <FormattedMessage
                  id="pages.verificationWaiting.whitelistingExplanationIntro"
                  values={{ groupName: group.attributes.name }}
                />
              </p>
              {/* eslint-disable-next-line react/forbid-elements */}
              <ul>
                {group.attributes.hasInvites && (
                  <li>
                    <FormattedMessage id="pages.verificationWaiting.whitelistingExplanationWithEmails" />
                  </li>
                )}
                {domains.length > 0 && (
                  <li>
                    <FormattedMessage id="pages.verificationWaiting.whitelistingExplanationWithDomains" />
                    {/* eslint-disable-next-line react/forbid-elements */}
                    <ul>
                      {domains.map(domain => (
                        <li key={domain.id}>{domain.attributes.domain}</li>
                      ))}
                    </ul>
                  </li>
                )}
              </ul>
            </div>
          </IonText>
          <FormContainer<FormValues>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ form, handleSubmit }) => (
              <Form<FormValues> onFormSubmit={handleSubmit} submit={form.submit}>
                <SubmitError />
                <FormError allowBlank name="email" />
                <FormInput
                  aria-label={intl.formatMessage({
                    id: 'models.registration.attributes.email.label'
                  })}
                  autofocus
                  name="email"
                  placeholder={intl.formatMessage({
                    id: 'models.registration.attributes.email.placeholder'
                  })}
                  type="email"
                />
                <IonItem color="transparent" lines="none">
                  <IonButtons slot="end">
                    <FormSubmit color="primary" fill="solid" size="default">
                      <FormattedMessage id="forms.verificationWaiting.submitButton" />
                    </FormSubmit>
                  </IonButtons>
                </IonItem>
              </Form>
            )}
          </FormContainer>
        </>
      )}
    </div>
  );
};

export default VerificationWaitingForm;
