import { IonButton } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import useCurrentUser from '../hooks/useCurrentUser';

const LoginButton = () => {
  const currentUser = useCurrentUser();

  if (currentUser || currentUser === undefined) {
    return null;
  }

  return (
    <IonButton color="secondary" routerLink="/login" size="small">
      <FormattedMessage id="dictionary.login" />
    </IonButton>
  );
};

export default LoginButton;
