import { Capacitor } from '@capacitor/core';
import { IonApp, IonPage } from '@ionic/react';
import * as Sentry from '@sentry/browser';
import '@stripe/stripe-js';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { decrementGlobalLoading, incrementGlobalLoading } from '../actions/loadingActions';
import { setAuthToken } from '../actions/sessionActions';
import AppCore from '../components/AppCore';
import AppUrlListener from '../components/AppUrlListener';
import BroadcastUpdateManager from '../components/BroadcastUpdateManager';
import MainMenu from '../components/MainMenu';
import PushNotificationSetup from '../components/PushNotificationSetup';
import useCurrentUser from '../hooks/useCurrentUser';
import useGaTracking from '../hooks/useGaTracking';
import useThunkDispatch from '../hooks/useThunkDispatch';
import contentSecurityPolicy from '../services/cspUtils';
import { storage } from '../services/storage';
import { extendSession, setUserId } from '../thunks/sessionThunks';
import { State } from '../types';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
// import '@ionic/react/css/flex-utils.css';
// import '@ionic/react/css/display.css';

/* Theme variables */
import '../theme/fonts.scss';
import '../theme/variables.css';
import '../theme/icons.scss';
import '../theme/overrides.scss';

const App = () => {
  const currentUser = useCurrentUser();
  const dispatch = useThunkDispatch();
  const location = useLocation();
  const userId = useSelector((root: State.Root) => root.session.currentUser.id);
  const [lastSessionExtension, setLastSessionExtension] = useState<DateTime>(DateTime.utc());

  useGaTracking();

  useEffect(() => {
    dispatch(incrementGlobalLoading('bootup'));
    const promises = [storage.get('authToken'), storage.get('userId')];
    Promise.all(promises)
      .then(res => {
        const [authToken, userId] = res;
        if (authToken) {
          dispatch(setAuthToken(authToken));
        }
        if (userId) {
          dispatch(setUserId(userId));
        } else {
          dispatch(setUserId(null));
        }
        dispatch(decrementGlobalLoading('bootup'));
      })
      .catch(error => {
        Sentry.captureException(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userId) {
      const fiveMinutesAgo = DateTime.utc().minus({ minutes: 5 });
      if (!lastSessionExtension || lastSessionExtension < fiveMinutesAgo) {
        const now = DateTime.utc();
        dispatch(extendSession()).catch(() => {
          // ok to swallow this
        });
        setLastSessionExtension(now);
      }
    }
  }, [dispatch, lastSessionExtension, location, setLastSessionExtension, userId]);

  const platform = Capacitor.getPlatform();

  return (
    <IonApp>
      <Helmet>
        <meta content="noindex, nofollow" name="robots" />
        <meta content={contentSecurityPolicy} name="Content-Security-Policy" />
      </Helmet>
      <AppUrlListener />
      <PushNotificationSetup />
      <BroadcastUpdateManager />
      <div className={`App platform-${platform}`}>
        {currentUser && <MainMenu currentUser={currentUser} />}
        <IonPage id="mainMenuContent">
          <AppCore />
        </IonPage>
      </div>
    </IonApp>
  );
};

export default App;
