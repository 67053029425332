import { IonItem, IonProgressBar } from '@ionic/react';
import React from 'react';

import AttachmentThumbnail from '../../components/forms/AttachmentThumbnail';
import { AttachmentsFieldManager } from '../../hooks/useAttachmentsFieldManager';

import './AttachmentFieldItems.scss';

type Props = {
  attachmentsFieldManager: AttachmentsFieldManager;
};

const AttachmentFieldItems = ({ attachmentsFieldManager }: Props) => (
  <>
    {attachmentsFieldManager.attachments.length > 0 && (
      <div className="attachment-thumbnails">
        {attachmentsFieldManager.attachments.map(attachment => (
          <AttachmentThumbnail
            attachment={attachment}
            attachmentsFieldManager={attachmentsFieldManager}
            key={attachment.id}
          />
        ))}
      </div>
    )}
    {attachmentsFieldManager.isUploading && (
      <IonItem className="compact" color="transparent" lines="none">
        <IonProgressBar type="indeterminate" />
      </IonItem>
    )}
  </>
);

export default AttachmentFieldItems;
