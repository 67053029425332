import { IonIcon, IonItem, IonLabel, IonList, IonReorder, IonReorderGroup } from '@ionic/react';
import { checkmark, close, repeat } from 'ionicons/icons';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { alertError, alertSuccess } from '../../actions/notificationActions';
import FormModal from '../../components/FormModal';
import FormTip from '../../components/forms/FormTip';
import GroupRuleForm from '../../components/forms/GroupRuleForm';
import OnClickLink from '../../components/OnClickLink';
import useOpener from '../../hooks/useOpener';
import useReorderer from '../../hooks/useReorderer';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { getGroupRules } from '../../selectors';
import { AjaxResponse } from '../../services/ajaxRequest';
import { forceArray } from '../../services/arrayUtils';
import { dispatchAndAlert } from '../../services/dispatchUtils';
import { deleteRule, updateGroup } from '../../thunks/apiThunks';
import { JSONApi, Models, State } from '../../types';

import '../SettingsList.scss';

type Props = {
  group: JSONApi.GroupResource;
};

const GroupRulesForm = ({ group }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const modalOpener = useOpener();

  const onReorder = useCallback(
    (resources: JSONApi.RuleResource[]) => {
      const relationships = {
        rules: { data: resources.map(({ id, type }) => ({ id, type })) }
      };
      const included = resources.map(({ attributes, id, type }) => ({
        attributes,
        id,
        relationships: {},
        type
      }));
      dispatchAndAlert(
        dispatch,
        updateGroup({}, group.id, relationships, included),
        'forms.group.update.success',
        'forms.group.update.error'
      );
    },
    [dispatch, group.id]
  );

  const reorderer = useReorderer<Models.Rule>({
    initialResources: getGroupRules(apiData, group),
    onReorder
  });

  const generateRemoveRuleClickHandler = useCallback(
    (rule: JSONApi.RuleResource) => () => {
      dispatch(deleteRule(group.attributes.slug, rule))
        .then(() => {
          dispatch(alertSuccess('forms.rule.delete.success'));
          reorderer.setResources(reorderer.resources.filter(resource => resource.id !== rule.id));
        })
        .catch(() => {
          dispatch(alertError('forms.rule.delete.error'));
        });
    },
    [dispatch, group.attributes.slug, reorderer]
  );

  const handleRuleFormSuccess = useCallback(
    (response: AjaxResponse<Models.Rule>) => {
      modalOpener.close();
      reorderer.setResources([...reorderer.resources, forceArray(response.data)[0]]);
    },
    [modalOpener, reorderer]
  );

  return (
    <div className="group-rules-form">
      <IonItem className="compact" color="transparent" lines="none">
        <IonLabel className="ion-no-margin settings-list-label" color="primary">
          <FormattedMessage id="models.group.relationships.rules.label" />
        </IonLabel>
        <OnClickLink
          aria-label={intl.formatMessage({ id: 'dictionary.reorder' })}
          className="settings-list-link"
          onClick={reorderer.handleToggleReorder}
          slot="end"
        >
          {reorderer.isReordering ? <IonIcon icon={checkmark} /> : <IonIcon icon={repeat} />}
        </OnClickLink>
      </IonItem>
      <IonList className="ion-no-padding rules-list settings-list">
        <IonReorderGroup
          disabled={!reorderer.isReordering}
          onIonItemReorder={reorderer.handleIonItemReorder}
        >
          {reorderer.resources.map(rule => (
            <IonItem color="light" key={rule.id}>
              <p>{rule.attributes.rule}</p>
              {!reorderer.isReordering && (
                <OnClickLink
                  aria-label={intl.formatMessage({ id: 'dictionary.remove' })}
                  onClick={generateRemoveRuleClickHandler(rule)}
                  slot="end"
                >
                  <IonIcon icon={close} />
                </OnClickLink>
              )}
              <IonReorder slot="end" />
            </IonItem>
          ))}
        </IonReorderGroup>
        <IonItem
          button
          color="light"
          disabled={modalOpener.isOpen || modalOpener.isPresented}
          lines="none"
          onClick={modalOpener.open}
          role="button"
        >
          <strong>
            <FormattedMessage id="models.group.relationships.rules.add" />
          </strong>
        </IonItem>
      </IonList>
      <FormTip id="models.group.relationships.rules.tip" />
      <FormModal keyboardClose={false} opener={modalOpener} titleKey="modals.addRule.title">
        {() => (
          <GroupRuleForm
            autofocus={modalOpener.isPresented}
            group={group}
            onSuccess={handleRuleFormSuccess}
          />
        )}
      </FormModal>
    </div>
  );
};

export default GroupRulesForm;
