import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { addNotification } from '../actions/notificationActions';
import { clearSession, setAuthToken } from '../actions/sessionActions';
import LocalLoadingIndicator from '../components/LocalLoadingIndicator';
import UserAccountWizardPageWrapper from '../components/UserAccountWizardPageWrapper';
import useMountedTracking from '../hooks/useMountedTracking';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { getMemberGroup, getRelatedResourcesFromIncluded } from '../selectors';
import { AjaxError } from '../services/ajaxRequest';
import { forceArray } from '../services/arrayUtils';
import { handleSubmissionError } from '../services/formUtils';
import { getErrorMessages } from '../services/jsonApiUtils';
import { collectSearchParamsFromUrl } from '../services/urlUtils';
import { loadSession } from '../thunks/sessionThunks';
import { JSONApi, Models, State } from '../types';

type Props = {
  group?: JSONApi.GroupResource;
};

const ConfirmEmailPage = ({ group }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const dispatch = useThunkDispatch();
  const [alreadyLoggedIn, setAlreadyLoggedIn] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const isMounted = useMountedTracking();
  const location = useLocation();

  useEffect(() => {
    const token = collectSearchParamsFromUrl<{ token: string }>(location, ['token']).token;
    if (alreadyLoggedIn) {
      return;
    }
    setAlreadyLoggedIn(true);

    dispatch(clearSession());
    dispatch(setAuthToken(token));

    dispatch(loadSession())
      .then(response => {
        response.dataLoadedIntoStatePromise.then(() => {
          if (isMounted.current) {
            // When we get back this response, it will include the session, its user, and
            // the memberships for that user. The apiData in the state may already include the
            // user and membership, and the state may have been properly updated with the data
            // that came back in the response, but the local apiData variable in this component
            // will still contain the old values. This is usually fine because a component would
            // rerender as the data is updated, but in this case we are going to update the history
            // based on it. For that reason, we have to use ONLY data from the response to make
            // our decision.
            const users = getRelatedResourcesFromIncluded<Models.Session, Models.User>(
              forceArray(response.data)[0],
              response.included,
              'user'
            );
            const user = forceArray(users)[0];

            const members = getRelatedResourcesFromIncluded<Models.Session, Models.Member>(
              forceArray(response.data)[0],
              response.included,
              'user.members'
            );

            if (user) {
              if (group) {
                history.push(`/g/${group.attributes.slug}`);
              } else {
                if (members.length > 0) {
                  const lastVisitedSlug = user.attributes.lastVisitedGroupSlug;
                  if (lastVisitedSlug) {
                    history.push(`/g/${lastVisitedSlug}`);
                  } else if (members.length === 1) {
                    const member = members[0];
                    const firstMemberGroup = getMemberGroup(apiData, member);
                    history.push(`/g/${firstMemberGroup.attributes.slug}`);
                  } else {
                    history.push('/search');
                  }
                } else {
                  history.push('/start');
                }
              }
            } else {
              // !!TODO!! handle no user
              // but this shouldn't ever happen since we just successfully logged them in
            }
          }
        });
      })
      .catch((error: AjaxError) => {
        handleSubmissionError({
          dispatch,
          error,
          handleInvalid: errors => {
            const messages = getErrorMessages(intl, errors, 'emailConfirmation');
            if (group) {
              history.push(`/g/${group.attributes.slug}/start`);
            } else {
              history.push(`/create`);
            }
            dispatch(
              addNotification({ body: messages.join('. '), type: State.NotificationType.ERROR })
            );
          },
          isMounted
        });
      });
  }, [alreadyLoggedIn, apiData, dispatch, group, history, intl, isMounted, location]);

  // all this page ever does is redirect somewhere else, so just render a spinner while you wait
  return (
    <UserAccountWizardPageWrapper titleId="pages.confirmEmail.title">
      <LocalLoadingIndicator />
    </UserAccountWizardPageWrapper>
  );
};

export default ConfirmEmailPage;
