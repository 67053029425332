import { IonItem, IonLabel, IonList, IonText } from '@ionic/react';
import React, { useCallback } from 'react';

import { JSONApi } from '../types';

import './SettingsList.scss';

type Props = {
  group: JSONApi.GroupResource;
  options: JSONApi.TraitOptionResource[];
  trait: JSONApi.TraitResource;
};

const TraitList = ({ group, options, trait }: Props) => {
  const generateSearchLink = useCallback(
    (item: JSONApi.TraitOptionResource) =>
      `/g/${group.attributes.slug}/members?traitOptionIds=${encodeURIComponent(item.id)}`,
    [group]
  );

  return (
    <>
      <IonLabel className="settings-list-label" color="primary">
        {trait.attributes.title}
      </IonLabel>
      <IonList className="ion-no-padding settings-list">
        {options.map((option, index) => (
          <IonItem
            key={option.id}
            lines={index === options.length - 1 ? 'none' : 'full'}
            routerLink={generateSearchLink(option)}
          >
            <IonText color="primary">{option.attributes.title}</IonText>
          </IonItem>
        ))}
      </IonList>
    </>
  );
};

export default TraitList;
