import { IonButton, IonCard, IonCol, IonGrid, IonLabel, IonRow } from '@ionic/react';
import { DateTime } from 'luxon';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import Currency from '../components/Currency';
import CancelPlanPreviewModal from '../components/forms/CancelPlanPreviewModal';
import ReactivatePlanPreviewModal from '../components/forms/ReactivatePlanPreviewModal';
import Nbsp from '../components/Nbsp';
import Paragraph from '../components/Paragraph';
import PlanMemberCapLabel from '../components/PlanMemberCapLabel';
import useOpener from '../hooks/useOpener';
import { isPlanAwaitingCancellation } from '../services/planUtils';
import { JSONApi } from '../types';

import './CurrentPlan.scss';
import './SettingsList.scss';

type ButtonRowSubcomponentProps = {
  color?: string;
  id: string;
  onClick: () => void;
};

const ButtonRowSubcomponent = ({ color = 'primary', id, onClick }: ButtonRowSubcomponentProps) => (
  <IonRow>
    <IonCol className="ion-no-padding">
      <IonButton
        className="ion-float-right"
        color={color}
        onClick={onClick}
        shape="round"
        size="small"
      >
        <FormattedMessage id={id} />
      </IonButton>
    </IonCol>
  </IonRow>
);

type Props = {
  group: JSONApi.GroupResource;
  onClickBillingHistory: () => void;
  onClickCancel: () => void;
  onClickReactivate: () => void;
  onClickUpdatePayment: () => void;
};

const CurrentPlan = ({
  group,
  onClickBillingHistory,
  onClickCancel,
  onClickReactivate,
  onClickUpdatePayment
}: Props) => {
  const cancelOpener = useOpener();
  const reactivateOpener = useOpener();

  const handleShowCancelPreview = useCallback(() => {
    cancelOpener.open();
  }, [cancelOpener]);

  const handleCancelAccept = useCallback(() => {
    cancelOpener.close();
    onClickCancel();
  }, [onClickCancel, cancelOpener]);

  const handleCancelCancel = useCallback(() => {
    cancelOpener.close();
  }, [cancelOpener]);

  const handleShowReactivatePreview = useCallback(() => {
    reactivateOpener.open();
  }, [reactivateOpener]);

  const handleReactivateAccept = useCallback(() => {
    reactivateOpener.close();
    onClickReactivate();
  }, [onClickReactivate, reactivateOpener]);

  const handleReactivateCancel = useCallback(() => {
    reactivateOpener.close();
  }, [reactivateOpener]);

  const renderGroupStatus = useCallback(() => {
    if (isPlanAwaitingCancellation(group)) {
      return (
        <FormattedMessage
          id="pages.manageGroup.plan.planCancellationEffective"
          values={{
            effectiveDate: group.attributes.planCurrentPeriodEnd.toLocaleString(DateTime.DATE_FULL)
          }}
        />
      );
    }

    if (!group.attributes.planActive) {
      return <FormattedMessage id="pages.manageGroup.plan.noActivePlan" />;
    }

    if (group.attributes.planFree) {
      return <FormattedMessage id="pages.manageGroup.plan.freePlan" />;
    }

    return (
      <>
        <FormattedMessage
          id="models.plan.attributes.planCostPerPeriodInCents.labelPrefix"
          values={{ period: group.attributes.planPeriod }}
        />
        <strong>
          <Currency valueInCents={group.attributes.planCostPerPeriodInCents} />
        </strong>
        <Nbsp />
        <FormattedMessage id="dictionary.on" />
        <Nbsp />
        <strong>{group.attributes.planCurrentPeriodEnd.toLocaleString(DateTime.DATE_FULL)}</strong>
        <FormattedMessage id="punctuation.period" />
      </>
    );
  }, [group]);

  return (
    <>
      <IonLabel className="settings-list-label">
        <FormattedMessage id="pages.manageGroup.plan.currentPlan" />
      </IonLabel>
      <IonCard className="current-plan ion-no-margin" color="light">
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol className="current-plan-description ion-padding" sizeLg="6" sizeXs="12">
              <PlanMemberCapLabel cap={group.attributes.memberCap} />
              <Paragraph>{renderGroupStatus()}</Paragraph>
            </IonCol>
            <IonCol className="ion-padding" sizeLg="6" sizeXs="12">
              <IonGrid>
                <ButtonRowSubcomponent
                  id="pages.manageGroup.plan.billingHistory"
                  onClick={onClickBillingHistory}
                />
                {isPlanAwaitingCancellation(group) && (
                  <ButtonRowSubcomponent
                    color="success"
                    id="pages.manageGroup.plan.reactivate"
                    onClick={handleShowReactivatePreview}
                  />
                )}
                {group.attributes.planActive && !isPlanAwaitingCancellation(group) && (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {!group.attributes.planFree && (
                      <>
                        <ButtonRowSubcomponent
                          id="pages.manageGroup.plan.updatePaymentInfo"
                          onClick={onClickUpdatePayment}
                        />
                        <ButtonRowSubcomponent
                          color="danger"
                          id="pages.manageGroup.plan.cancel"
                          onClick={handleShowCancelPreview}
                        />
                      </>
                    )}
                  </>
                )}
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCard>
      <CancelPlanPreviewModal
        group={group}
        handleAccept={handleCancelAccept}
        handleCancel={handleCancelCancel}
        opener={cancelOpener}
      />
      <ReactivatePlanPreviewModal
        group={group}
        handleAccept={handleReactivateAccept}
        handleCancel={handleReactivateCancel}
        opener={reactivateOpener}
      />
    </>
  );
};

export default CurrentPlan;
