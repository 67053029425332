import React from 'react';
import { useSelector } from 'react-redux';

import RedirectIfCurrentUser from '../components/RedirectIfCurrentUser';
import { SessionState, State } from '../types';

type Props = {
  children: React.ReactElement;
};

const RequireNoCurrentUser = ({ children }: Props) => {
  const userState = useSelector((root: State.Root) => root.session.currentUser.state);

  if (userState === SessionState.LOADING) {
    return null;
  }

  return <RedirectIfCurrentUser>{children}</RedirectIfCurrentUser>;
};

export default RequireNoCurrentUser;
