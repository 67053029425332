import React from 'react';
import { Redirect } from 'react-router';

import useCurrentMember from '../hooks/useCurrentMember';
import { JSONApi } from '../types';

type Props = {
  children: (member: JSONApi.MemberResource) => React.ReactElement;
  group: JSONApi.GroupResource;
};

const RequireOwner = ({ children, group }: Props) => {
  const member = useCurrentMember();

  if (member === undefined) {
    return null;
  }

  if (member?.attributes?.owner) {
    return children(member);
  }

  return <Redirect to={`/g/${group.attributes.slug}`} />;
};

export default RequireOwner;
