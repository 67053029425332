import { IonItem } from '@ionic/react';
import React, { useState } from 'react';

import { setMemberSearchParams } from '../actions/searchActions';
import SearchCountry from '../components/SearchCountry';
import SearchIndustry from '../components/SearchIndustry';
import SearchLocation from '../components/SearchLocation';
import SearchQuery from '../components/SearchQuery';
import SearchRadius from '../components/SearchRadius';
import SearchSort from '../components/SearchSort';
import SearchTraits from '../components/SearchTraits';
import useSearchParamsFromUrl from '../hooks/useSearchParamsFromUrl';
import { defaultMemberSearchParams } from '../services/searchUtils';
import { JSONApi, Search } from '../types';

import '../components/forms/CustomForm.scss';
import './Searchbox.scss';

type Props = {
  countryFacet: Search.FacetValue[] | undefined;
  group: JSONApi.GroupResource;
  industryFacet: Search.FacetValue[] | undefined;
  isOpen: boolean;
  traitOptionIdsFacet: Search.FacetValue[] | undefined;
};

const MemberSearchForm = ({
  countryFacet,
  group,
  industryFacet,
  isOpen,
  traitOptionIdsFacet
}: Props) => {
  const [searchParams, setSearchParams] = useState<Search.MemberParams>(
    defaultMemberSearchParams()
  );

  let searchFields: (keyof Search.MemberParams)[] = ['query', 'sort', 'traitOptionIds'];

  const sortOptions = [
    Search.SortOptions.AUTO,
    Search.SortOptions.JOIN_DATE,
    Search.SortOptions.FIRST_NAME,
    Search.SortOptions.LAST_NAME
  ];

  if (group.attributes.locationFeaturesEnabled) {
    sortOptions.push(Search.SortOptions.LOCATION);
    searchFields = searchFields.concat(['country', 'location', 'lat', 'lng', 'radius']);
  }
  if (group.attributes.careersEnabled) {
    searchFields.push('industry');
  }

  useSearchParamsFromUrl<Search.MemberParams>(
    searchFields,
    setSearchParams,
    searchParams,
    setMemberSearchParams
  );

  if (isOpen) {
    return (
      <div className="subheader">
        <div className="custom-form-wrapper searchbox">
          <IonItem>
            <SearchQuery<Search.MemberParams>
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </IonItem>
          <div className="ion-padding">
            <SearchTraits<Search.MemberParams>
              facetValues={traitOptionIdsFacet}
              group={group}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
            {group.attributes.locationFeaturesEnabled && (
              <>
                <SearchCountry<Search.MemberParams>
                  facetValues={countryFacet}
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                />
                <SearchLocation<Search.MemberParams>
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                />
                <SearchRadius<Search.MemberParams>
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                />
              </>
            )}
            {group.attributes.careersEnabled && (
              <SearchIndustry<Search.MemberParams>
                facetValues={industryFacet}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
              />
            )}
            <SearchSort<Search.MemberParams>
              defaultOption={Search.SortOptions.AUTO}
              options={sortOptions}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default MemberSearchForm;
