import { Keyboard } from '@capacitor/keyboard';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { close } from 'ionicons/icons';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { KeyboardContext, KeyboardStatus } from '../components/KeyboardStatusProvider';
import Modal from '../components/Modal';
import { Opener } from '../hooks/useOpener';
import useStopEnterKeyPropagation from '../hooks/useStopEnterKeyPropagation';

type FormModalBodyProps = {
  children: (contentForwardRef: React.RefObject<HTMLIonContentElement>) => React.ReactElement;
  keyboardStatus: KeyboardStatus;
  opener: Opener;
  titleKey: string;
  titleValues?: { [key: string]: string };
};

const FormModalBody = ({
  children,
  keyboardStatus,
  opener,
  titleKey,
  titleValues
}: FormModalBodyProps) => {
  const intl = useIntl();
  const contentForwardRef = createRef<HTMLIonContentElement>();
  const [closeClicked, setCloseClicked] = useState(false);

  const handleCloseClick = useCallback(() => {
    setCloseClicked(true);
    if (keyboardStatus === KeyboardStatus.OPEN || keyboardStatus === KeyboardStatus.OPENING) {
      Keyboard.hide();
    }
  }, [keyboardStatus]);

  // prevent enter keypresses on a modal from propagating outside the modal
  const handleEnterKeyDown = useStopEnterKeyPropagation<HTMLElement>();

  useEffect(() => {
    if (keyboardStatus === KeyboardStatus.CLOSED && closeClicked) {
      setCloseClicked(false);
      opener.close();
    }
  }, [closeClicked, keyboardStatus, opener]);

  return (
    <>
      <IonHeader onKeyDown={handleEnterKeyDown}>
        <IonToolbar>
          <IonTitle color="primary">
            <FormattedMessage id={titleKey} values={titleValues} />
          </IonTitle>
          <IonButtons slot="end">
            <IonButton
              aria-label={intl.formatMessage({ id: 'dictionary.close' })}
              onClick={handleCloseClick}
            >
              <IonIcon icon={close} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding" onKeyDown={handleEnterKeyDown} ref={contentForwardRef}>
        {children(contentForwardRef)}
      </IonContent>
    </>
  );
};

export interface FormModalProps
  extends Omit<React.ComponentPropsWithoutRef<typeof Modal>, 'children' | 'isOpen'> {
  children: (contentForwardRef: React.RefObject<HTMLIonContentElement>) => React.ReactElement;
  opener: Opener;
  titleKey: string;
  titleValues?: { [key: string]: string };
}

const FormModal = ({
  children,
  opener,
  titleKey,
  titleValues = {},
  ...modalProps
}: FormModalProps) => (
  <Modal opener={opener} {...modalProps}>
    <KeyboardContext.Consumer>
      {keyboardStatus => (
        <FormModalBody
          keyboardStatus={keyboardStatus}
          opener={opener}
          titleKey={titleKey}
          titleValues={titleValues}
        >
          {children}
        </FormModalBody>
      )}
    </KeyboardContext.Consumer>
  </Modal>
);

export default FormModal;
