/*
 * this component overwrites IonTextarea with some default behavior we always need:
 * 1) extend the autofocus behavior with our autofocus hook
 * 2) ensures the enter key adds a return character rather than submits the form
 * 3) set default values for certain props that make sense in most cases for textareas
 */

import { IonTextarea } from '@ionic/react';
import React, { createRef } from 'react';

import useAutofocus from '../../hooks/useAutofocus';
import useStopEnterKeyPropagation from '../../hooks/useStopEnterKeyPropagation';

// omit native textarea props for which ionic has a different prop
const Textarea = ({
  forwardRef,
  onKeyDown,
  ...ionTextareaProps
}: Omit<React.ComponentProps<typeof IonTextarea>, 'autoCapitalize' | 'spellCheck'> & {
  forwardRef?: React.RefObject<HTMLIonTextareaElement>;
}) => {
  const ref = forwardRef ?? createRef<HTMLIonTextareaElement>();
  useAutofocus(!!ionTextareaProps.autofocus, ref);

  const handleKeyDown = useStopEnterKeyPropagation<HTMLIonTextareaElement>(onKeyDown);

  return (
    // eslint-disable-next-line react/forbid-elements
    <IonTextarea
      // defaults
      autoCorrect="on"
      autoGrow
      autocapitalize="on"
      color="primary"
      spellcheck
      // overwrite defaults with client-supplied props
      {...ionTextareaProps}
      onKeyDown={handleKeyDown}
      ref={ref}
    />
  );
};

export default Textarea;
