import { IonButton, IonButtons, IonIcon, IonItem } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React from 'react';
import { useIntl } from 'react-intl';

const UserAccountWizardBackButton = (props: React.ComponentPropsWithoutRef<typeof IonButton>) => {
  const intl = useIntl();

  return (
    <IonItem className="compact" color="transparent" lines="none">
      <IonButtons slot="start">
        <IonButton
          aria-label={intl.formatMessage({ id: 'dictionary.back' })}
          color="primary"
          fill="clear"
          {...props}
        >
          <IonIcon icon={arrowBack} slot="icon-only" />
        </IonButton>
      </IonButtons>
    </IonItem>
  );
};

export default UserAccountWizardBackButton;
