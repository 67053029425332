import { IonToggleCustomEvent } from '@ionic/core';
import { ToggleChangeEventDetail } from '@ionic/react';
import React, { useCallback } from 'react';
import { useField } from 'react-final-form';

import ToggleItem from '../../components/forms/ToggleItem';
import useMergeOnChange from '../../hooks/useMergeOnChange';

function FormToggleItem<FormValues, F extends keyof FormValues>({
  change,
  name,
  onIonChange,
  ...props
}: React.ComponentProps<typeof ToggleItem> & {
  // we must handle the change manually because the events don't match up correctly
  change: (name: F, value?: FormValues[F]) => void;
  // name is required for form fields
  name: F;
  onIonChange?: ((event: IonToggleCustomEvent<ToggleChangeEventDetail<any>>) => void) | undefined;
}) {
  const field = useField(name, { subscription: { value: true } });
  // the multiple and type props from react-final-form do not pertain to toggles
  // and we need to handle onChange manually during the onIonChange handler
  const { multiple, onChange: _onChange, type, ...fieldToggleItemProps } = field.input;

  const onChange = useCallback(
    (event: Event) => {
      const target = event.target as HTMLIonToggleElement & typeof event.target;
      // the onIonChange event seems to be thrown twice when you click on a toggle. you
      // only want to trigger the form state changing behavior when the value and checked
      // state are out of sync, otherwise you may get unexpected flickering behavior.
      if (!!fieldToggleItemProps.value !== target.checked) {
        change(name, target.checked as unknown as FormValues[F]);
      }
    },
    [change, fieldToggleItemProps.value, name]
  );
  const handleIonChange = useMergeOnChange<IonToggleCustomEvent<ToggleChangeEventDetail>>({
    onChange,
    onIonChange
  });

  return (
    <ToggleItem
      {...fieldToggleItemProps}
      {...props}
      checked={!!fieldToggleItemProps.value}
      onIonChange={handleIonChange}
    />
  );
}

export default FormToggleItem;
