import { IonCard, IonCol, IonContent, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import GroupAboutHeader from '../components/GroupAboutHeader';
import Img from '../components/Img';
import Page from '../components/layout/Page';
import PageHeader from '../components/layout/PageHeader';
import LinksList from '../components/LinksList';
import { getOwnerLinks } from '../selectors';
import { JSONApi, State } from '../types';

import './Img.scss';
import './SettingsList.scss';

type Props = {
  group: JSONApi.GroupResource;
};

const GroupAboutPage = ({ group }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const intl = useIntl();
  const links = getOwnerLinks(apiData, group);

  return (
    <Page className="centered wide">
      <PageHeader
        defaultBackHref={`/g/${group.attributes.slug}`}
        group={group}
        titleKey="pages.groupAbout.title"
      />
      <IonContent className="canvas ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol size="12" sizeLg="8">
              <IonCard className="ion-no-margin">
                <GroupAboutHeader group={group} link={false} showDescription showRules />
              </IonCard>
              <LinksList links={links} tKey="models.group.relationships.links.title" />
            </IonCol>
            <IonCol size="12" sizeLg="4">
              {group.attributes.bgImageUrl && (
                <IonCard className="ion-no-margin">
                  <span className="image-wrapper">
                    <Img
                      alt={intl.formatMessage({ id: 'models.group.attributes.bgImage.alt' })}
                      src={group.attributes.bgImageUrl}
                    />
                  </span>
                </IonCard>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </Page>
  );
};

export default GroupAboutPage;
