import { IonIcon, IonText } from '@ionic/react';
import { closeCircle } from 'ionicons/icons';
import React, { useCallback } from 'react';

import Img from '../../components/Img';
import OnClickLink from '../../components/OnClickLink';
import { AttachmentsFieldManager } from '../../hooks/useAttachmentsFieldManager';
import { JSONApi } from '../../types';

import './AttachmentThumbnail.scss';

type Props = {
  attachment: JSONApi.AttachmentResource;
  attachmentsFieldManager: AttachmentsFieldManager;
};

export const AttachmentThumbnail = ({ attachment, attachmentsFieldManager }: Props) => {
  const handleRemoveClick = useCallback(() => {
    attachmentsFieldManager.removeAttachment(attachment);
  }, [attachment, attachmentsFieldManager]);

  return (
    <div className="attachment-thumbnail">
      <OnClickLink className="remove" onClick={handleRemoveClick}>
        <IonIcon color="tertiary" icon={closeCircle} />
      </OnClickLink>
      <div className="thumbnail">
        {attachment.attributes.isImage ? (
          <Img alt={attachment.attributes.filename} src={attachment.attributes.url} />
        ) : (
          <div className="ion-padding-end ion-padding-start text-thumb">
            <span className="truncate">
              <IonText color="primary">{attachment.attributes.filename}</IonText>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default AttachmentThumbnail;
