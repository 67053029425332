import { IonItem } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

import Form from '../components/forms/Form';
import FormContainer from '../components/forms/FormContainer';
import FormError from '../components/forms/FormError';
import FormInput from '../components/forms/FormInput';
import FormSubmit from '../components/forms/FormSubmit';
import SubmitError from '../components/forms/SubmitError';
import Paragraph from '../components/Paragraph';
import UserAccountWizardBackButton from '../components/UserAccountWizardBackButton';
import UserAccountWizardPageWrapper from '../components/UserAccountWizardPageWrapper';
import useMountedTracking from '../hooks/useMountedTracking';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { getInviteLookupGroup, getInviteLookupInvite } from '../selectors';
import { forceArray } from '../services/arrayUtils';
import { handleFormSubmission } from '../services/formUtils';
import { acceptInviteForGroup } from '../services/thunkUtils';
import { loadInviteLookup } from '../thunks/apiThunks';
import { JSONApi, State } from '../types';

type FormValues = {
  inviteCode: string;
};

type Props = {
  currentUser: JSONApi.UserResource;
};

const EnterInviteCodePage = ({ currentUser }: Props) => {
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const initialValues: FormValues = {
    inviteCode: ''
  };

  const handleSubmit = useCallback(
    async (values: FormValues) =>
      handleFormSubmission({
        action: loadInviteLookup(values.inviteCode),
        dispatch,
        errorTranslationLocation: 'inviteLookup',
        intl,
        isMounted,
        onSuccess: (response: JSONApi.Response, newState: State.Root) => {
          const inviteLookup = forceArray(response.data)[0];
          const group = getInviteLookupGroup(newState.api, inviteLookup);
          const invite = getInviteLookupInvite(newState.api, inviteLookup);
          acceptInviteForGroup({
            apiData: newState.api,
            dispatch,
            group,
            history,
            invite,
            inviteCode: values.inviteCode,
            user: currentUser
          });
        },
        values
      }),
    [currentUser, dispatch, history, intl, isMounted]
  );

  const validationSchema = Yup.object().shape({
    inviteCode: Yup.string().required(
      intl.formatMessage({ id: 'errors.inviteLookup.inviteCode.blank' })
    )
  });

  return (
    <UserAccountWizardPageWrapper titleId="pages.enterInviteCode.title">
      <UserAccountWizardBackButton routerLink="/start" />
      <FormContainer<FormValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ form, handleSubmit }) => (
          <Form<FormValues> onFormSubmit={handleSubmit} submit={form.submit}>
            <IonItem color="transparent" lines="full">
              <FormInput
                aria-label={intl.formatMessage({
                  id: 'models.inviteLookup.attributes.inviteCode.label'
                })}
                color="primary"
                name="inviteCode"
                placeholder={intl.formatMessage({
                  id: 'models.inviteLookup.attributes.inviteCode.placeholder'
                })}
              />
            </IonItem>
            <SubmitError />
            <FormError name="inviteCode" />
            <IonItem lines="none">
              <Paragraph color="medium">
                <FormattedMessage id="models.inviteLookup.attributes.inviteCode.tip" />
              </Paragraph>
            </IonItem>
            <FormSubmit color="primary">
              <FormattedMessage id="dictionary.lookup" />
            </FormSubmit>
          </Form>
        )}
      </FormContainer>
    </UserAccountWizardPageWrapper>
  );
};

export default EnterInviteCodePage;
