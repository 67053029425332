import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import TimeAgo from 'react-timeago';

import useMountedTracking from '../hooks/useMountedTracking';

// requires a date in number format
const RelativeTimestamp = ({
  date,
  ...otherProps
}: Omit<React.ComponentPropsWithoutRef<typeof TimeAgo>, 'date'> & {
  date: number;
}) => {
  const oneMinute = 60000;
  const [delta, setDelta] = useState<number>(0);
  const isMounted = useMountedTracking();

  const updateDelta = useCallback(() => {
    if (isMounted.current) {
      const passedValueAsDate = new Date(date);
      const currentTime = new Date();
      setDelta(currentTime.getTime() - passedValueAsDate.getTime());
    }
  }, [date, isMounted]);

  useEffect(() => {
    updateDelta();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (delta < 60000) {
      const timeout = setTimeout(() => {
        if (isMounted.current) {
          updateDelta();
        }
      }, oneMinute);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [delta, isMounted, updateDelta]);

  if (delta > 0 && delta < oneMinute) {
    return <FormattedMessage id="dictionary.justNow" />;
  }
  return <TimeAgo date={date} {...otherProps} />;
};

export default RelativeTimestamp;
