import { IonSearchbar, SearchbarChangeEventDetail } from '@ionic/react';
import React, { createRef, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Search } from '../types';

type ExtendedSearchParams<T extends Search.Params> = T & { query: string | null };

type Props<T extends Search.Params> = {
  autofocus?: boolean;
  searchParams: ExtendedSearchParams<T>;
  setSearchParams: (value: ExtendedSearchParams<T>) => void;
};

function SearchQuery<T extends Search.Params>({
  autofocus = true,
  searchParams,
  setSearchParams
}: Props<T>) {
  const intl = useIntl();
  const inputRef = createRef<HTMLIonSearchbarElement>();

  const handleIonChange = useCallback(
    (event: CustomEvent<SearchbarChangeEventDetail>) => {
      const target = event.target as HTMLInputElement | null;
      if (target) {
        setSearchParams({ ...searchParams, query: target.value });
      }
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    setTimeout(() => {
      // For some reason under test the element is an HTMLUnknownElement
      if (autofocus && inputRef.current && inputRef.current.setFocus) {
        inputRef.current.setFocus();
      }
    }, 200);
  }, [autofocus, inputRef]);

  return (
    <IonSearchbar
      aria-label={intl.formatMessage({ id: 'models.search.attributes.query' })}
      debounce={0}
      onIonChange={handleIonChange}
      ref={inputRef}
      type="text"
      value={searchParams.query}
    />
  );
}

export default SearchQuery;
