import { IntlShape } from 'react-intl';

import { JSONApi } from '../types';

type TranslationValues = {
  count?: string;
};

export function translateApiError(intl: IntlShape, base: string, error: JSONApi.Error | undefined) {
  let id = `errors.${base}`;
  const values: TranslationValues = {};

  if (error?.meta) {
    id = id.concat(`.${error.meta.location}`);
    id = id.concat(`.${error.meta.error}`);
  } else {
    id = id.concat('.default');
  }

  if (error?.meta?.count) {
    values.count = error.meta.count;
  }

  return intl.formatMessage({ id }, values);
}
