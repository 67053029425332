import { IonContent } from '@ionic/react';
import React from 'react';

import Page from '../components/layout/Page';
import PageTitle from '../components/layout/PageTitle';

import './UserAccountWizardPageWrapper.scss';

const UserAccountWizardPageWrapper = ({
  children,
  titleId
}: React.PropsWithChildren<{
  titleId: string;
}>) => (
  <Page className="centered user-account-wizard-page tight">
    <IonContent className="canvas">
      <PageTitle id={titleId} />
      <div className="grid ion-padding">{children}</div>
    </IonContent>
  </Page>
);

export default UserAccountWizardPageWrapper;
