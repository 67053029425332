import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import CheckForEmailConfirmationPage from '../../components/CheckForEmailConfirmationPage';
import ConfirmEmailPage from '../../components/ConfirmEmailPage';
import Logout from '../../components/Logout';
import RequireCurrentUser from '../../components/RequireCurrentUser';
import NoGroupRoutes from '../../components/routing/NoGroupRoutes';
import SetupPasswordPage from '../../components/SetupPasswordPage';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { setGroupSlug } from '../../thunks/sessionThunks';

const NoGroupRouter = () => {
  const dispatch = useThunkDispatch();

  useEffect(() => {
    dispatch(setGroupSlug(null));
  }, [dispatch]);

  return (
    <Switch>
      <Route
        component={CheckForEmailConfirmationPage}
        exact
        path="/register/check-for-email-confirmation"
      />
      {/* this route is deprecated. prefer the register/confirm-email route */}
      <Redirect from="/confirm-email" to="register/confirm-email" />
      <Route component={ConfirmEmailPage} exact path="/register/confirm-email" />
      <Route exact path="/register/setup-password">
        {() => (
          <RequireCurrentUser>
            {user => <SetupPasswordPage currentUser={user} />}
          </RequireCurrentUser>
        )}
      </Route>
      <Route component={Logout} exact path="/logout" />
      <Route component={NoGroupRoutes} path="/" />
    </Switch>
  );
};

export default NoGroupRouter;
