import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import BasicLink from '../../components/BasicLink';
import FormError from '../../components/forms/FormError';
import FormInput from '../../components/forms/FormInput';

type Props = {
  autofocus: boolean;
  index: number;
  remove: (index: number) => void;
  showRemoveLink: boolean;
};

const InviteeEmailField = ({ autofocus, index, remove, showRemoveLink }: Props) => {
  const intl = useIntl();

  const handleRemoveClick = useCallback(() => {
    remove(index);
  }, [index, remove]);

  return (
    <>
      <FormError name={`invites.${index.toString()}.inviteeEmail`} />
      <div className="field-array-row">
        <FormInput
          aria-label={intl.formatMessage({
            id: 'models.invite.attributes.inviteeEmail.label'
          })}
          autofocus={autofocus && index === 0}
          color="primary"
          name={`invites.${index.toString()}.inviteeEmail`}
          placeholder={intl.formatMessage({
            id: 'models.invite.attributes.inviteeEmail.placeholder'
          })}
          type="email"
        />
        {showRemoveLink && <BasicLink onClick={handleRemoveClick} tKey="dictionary.remove" />}
      </div>
    </>
  );
};

export default InviteeEmailField;
