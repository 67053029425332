import { IonButton, IonButtons, IonIcon, IonItem, IonLabel } from '@ionic/react';
import { close } from 'ionicons/icons';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { alertError, alertSuccess } from '../actions/notificationActions';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { deleteSubscriptionLocationFilter } from '../thunks/apiThunks';
import { JSONApi } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  subscriptionLocationFilter: JSONApi.SubscriptionLocationFilterResource;
};

const SubscriptionLocationFilterItem = ({ group, subscriptionLocationFilter }: Props) => {
  const dispatch = useThunkDispatch();
  const intl = useIntl();

  const handleClick = useCallback(() => {
    dispatch(deleteSubscriptionLocationFilter(group.attributes.slug, subscriptionLocationFilter))
      .then(() => {
        dispatch(alertSuccess('forms.subscriptionLocationFilter.delete.success'));
      })
      .catch(() => {
        dispatch(alertError('forms.subscriptionLocationFilter.delete.error'));
      });
  }, [dispatch, group.attributes.slug, subscriptionLocationFilter]);

  return (
    <IonItem>
      <IonLabel>{subscriptionLocationFilter.attributes.location}</IonLabel>
      <IonButtons className="ion-no-margin" slot="end">
        <IonButton
          aria-label={intl.formatMessage({ id: 'dictionary.remove' })}
          fill="clear"
          onClick={handleClick}
          shape="round"
          slot="end"
        >
          <IonIcon icon={close} slot="icon-only" />
        </IonButton>
      </IonButtons>
    </IonItem>
  );
};

export default SubscriptionLocationFilterItem;
