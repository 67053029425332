import { ModelAttributes } from '../types';

const flagCategories: ModelAttributes.FlagCategory[] = [
  'Nudity',
  'Harassment',
  'Hate Speech',
  'Violence',
  'Terrorism',
  'Spam',
  'Other'
];

export default flagCategories;
