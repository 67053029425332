import { IonImg, IonSkeletonText } from '@ionic/react';
import React, { KeyboardEvent, useCallback } from 'react';

import Lightbox from '../components/Lightbox';
import useOpener from '../hooks/useOpener';

const Img = ({
  alt,
  isProcessing = false,
  ...imgProps
}: React.ComponentProps<typeof IonImg> & {
  alt: string;
  isProcessing?: boolean;
  // enforce existence of src
  src: string;
}) => {
  const lightboxOpener = useOpener();

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLIonImgElement>) => {
      if (event.key === 'Enter') {
        lightboxOpener.open();
      }
    },
    [lightboxOpener]
  );

  if (isProcessing) {
    return <IonSkeletonText animated />;
  }

  return (
    <>
      {/* eslint-disable-next-line react/forbid-elements */}
      <IonImg
        alt={alt}
        onClick={lightboxOpener.open}
        onKeyDown={handleKeyDown}
        role="link"
        tabIndex={0}
        {...imgProps}
      />
      <Lightbox mainSrc={imgProps.src} opener={lightboxOpener} />
    </>
  );
};

export default Img;
