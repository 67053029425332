import { DateTime } from 'luxon';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ChangePreviewModal from '../../components/forms/ChangePreviewModal';
import { Opener } from '../../hooks/useOpener';
import { JSONApi } from '../../types';

type Props = {
  group: JSONApi.GroupResource;
  handleAccept: () => void;
  handleCancel: () => void;
  opener: Opener;
};

const ReactivatePlanPreviewModal = ({ group, handleAccept, handleCancel, opener }: Props) => (
  <ChangePreviewModal
    acceptButtonTextKey="modals.planReactivate.reactivate"
    group={group}
    handleAccept={handleAccept}
    handleCancel={handleCancel}
    opener={opener}
    titleKey="modals.planReactivate.title"
  >
    <p>
      <FormattedMessage
        id="models.plan.reactivate.p1"
        values={{
          effectiveDate: group.attributes.planCurrentPeriodEnd.toLocaleString(DateTime.DATE_FULL)
        }}
      />
    </p>
    <p>
      <FormattedMessage id="models.plan.reactivate.p2" />
    </p>
  </ChangePreviewModal>
);

export default ReactivatePlanPreviewModal;
