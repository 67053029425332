import { IonButtons, IonIcon, IonItem } from '@ionic/react';
import { FormApi } from 'final-form';
import { addCircle } from 'ionicons/icons';
import React, { useCallback } from 'react';
import { FormSpy } from 'react-final-form';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import ErrorMessage from '../../components/forms/ErrorMessage';
import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormInput from '../../components/forms/FormInput';
import FormItem from '../../components/forms/FormItem';
import FormSubmit from '../../components/forms/FormSubmit';
import { maxEmailLength } from '../../globals';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { handleFormSubmission } from '../../services/formUtils';
import { createEmailAddress } from '../../thunks/apiThunks';

type FormValues = {
  email: string;
};

const NewEmailAddressForm = () => {
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const initialValues = {
    email: ''
  };

  const handleSubmit = useCallback(
    async (values: FormValues, form: FormApi<FormValues>) =>
      handleFormSubmission({
        action: createEmailAddress(undefined, values),
        dispatch,
        errorTranslationLocation: 'emailAddress',
        form,
        intl,
        isMounted,
        successTKey: 'forms.newEmailAddress.create.success',
        values
      }),
    [dispatch, intl, isMounted]
  );

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'errors.emailAddress.email.invalid' }))
      .required(intl.formatMessage({ id: 'errors.emailAddress.email.blank' }))
      .max(
        maxEmailLength,
        intl.formatMessage({ id: 'errors.emailAddress.email.tooLong' }, { count: maxEmailLength })
      )
  });

  return (
    <FormContainer<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ form, handleSubmit }) => (
        <Form<FormValues>
          className="email-address-form"
          onFormSubmit={handleSubmit}
          submit={form.submit}
        >
          <FormSpy subscription={{ submitError: true }}>
            {({ submitError }) => {
              if (submitError) {
                return (
                  <IonItem lines="none">
                    <ErrorMessage>{submitError}</ErrorMessage>
                  </IonItem>
                );
              }
              return null;
            }}
          </FormSpy>
          <FormError allowBlank name="email" />
          <FormItem lines="none">
            <FormInput
              aria-label={intl.formatMessage({ id: 'models.emailAddress.attributes.email.label' })}
              autocapitalize="off"
              name="email"
              placeholder={intl.formatMessage({
                id: 'models.emailAddress.attributes.email.placeholder'
              })}
              type="email"
            />
            <IonButtons slot="end">
              <FormSubmit
                aria-label={intl.formatMessage({ id: 'dictionary.submit' })}
                className="create-email-address"
                color="primary"
                shape="round"
                size="small"
                type="submit"
              >
                <IonIcon icon={addCircle} slot="icon-only" />
              </FormSubmit>
            </IonButtons>
          </FormItem>
        </Form>
      )}
    </FormContainer>
  );
};

export default NewEmailAddressForm;
