import {
  IonBadge,
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote
} from '@ionic/react';
import { chevronForward } from 'ionicons/icons';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import DeleteAccountModal from '../components/DeleteAccountModal';
import AccountForm from '../components/forms/AccountForm';
import Page from '../components/layout/Page';
import PageHeader from '../components/layout/PageHeader';
import PageTitle from '../components/layout/PageTitle';
import useOpener from '../hooks/useOpener';
import { getMemberGroup, getUserMembers } from '../selectors';
import { getMemberStatusColor } from '../services/htmlUtils';
import { JSONApi, State } from '../types';

import './SettingsList.scss';

type Props = {
  currentUser: JSONApi.UserResource;
};

const AccountPage = ({ currentUser }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const deleteAccountModalOpener = useOpener();

  const members = getUserMembers(apiData, currentUser).sort((a, b) => {
    const groupA = getMemberGroup(apiData, a);
    const groupB = getMemberGroup(apiData, b);
    return groupA.attributes.name.toLowerCase() > groupB.attributes.name.toLowerCase() ? 1 : -1;
  });

  return (
    <Page className="account-page centered tight">
      <PageHeader defaultBackHref="/me-and-my-data" />
      <PageTitle id="pages.account.title" />
      <IonContent className="canvas ion-padding">
        <div>
          <h1 className="ion-no-margin ion-padding">
            <FormattedMessage id="pages.account.title" />
          </h1>
          <AccountForm currentUser={currentUser} />
          {members.length > 0 && (
            <IonList className="ion-margin-bottom ion-no-padding settings-list">
              <IonListHeader>
                <h2>
                  <FormattedMessage id="pages.account.h2" />
                </h2>
              </IonListHeader>
              {members.map((member, index) => {
                const group = getMemberGroup(apiData, member);
                const isLast = index === members.length - 1;
                return (
                  <IonItem
                    button
                    detail={false}
                    key={member.id}
                    lines={isLast ? 'none' : 'inset'}
                    role="button"
                    routerLink={`/me-and-my-data/memberships/${group.attributes.slug}`}
                  >
                    <IonLabel>{group.attributes.name}</IonLabel>
                    <IonBadge color={getMemberStatusColor(member.attributes.status)}>
                      <FormattedMessage
                        id={`models.member.attributes.status.${member.attributes.status}Badge`}
                      />
                    </IonBadge>
                    <IonIcon icon={chevronForward} slot="end" />
                  </IonItem>
                );
              })}
            </IonList>
          )}
          <IonList className="ion-margin-bottom ion-no-padding settings-list">
            <IonListHeader>
              <h2>
                <FormattedMessage id="pages.account.h3" />
              </h2>
            </IonListHeader>
            <div className="ion-padding-end ion-padding-start">
              <IonButton color="danger" expand="block" onClick={deleteAccountModalOpener.open}>
                <FormattedMessage id="modals.deleteAccount.title" />
              </IonButton>
              <IonNote color="danger">
                <FormattedMessage id="pages.account.deleteExplanation" />
              </IonNote>
              <DeleteAccountModal currentUser={currentUser} opener={deleteAccountModalOpener} />
            </div>
          </IonList>
        </div>
      </IonContent>
    </Page>
  );
};

export default AccountPage;
