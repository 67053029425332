import React from 'react';

import Img from '../components/Img';
import useImageOrienter from '../hooks/useImageOrienter';

const ImgOriented = ({
  alt,
  src,
  ...otherImgProps
}: Omit<React.ComponentPropsWithoutRef<typeof Img>, 'isProcessing'> & {
  src: string;
}) => {
  const imageOrienter = useImageOrienter(src);

  return <Img alt={alt} {...otherImgProps} {...imageOrienter} />;
};

export default ImgOriented;
