import { useCallback } from 'react';

import useActionCableChannel from '../hooks/useActionCableChannel';
import { useApiDatRef } from '../hooks/useApiDataRef';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { getPost } from '../selectors';
import { forceArray } from '../services/arrayUtils';
import { loadPost } from '../thunks/apiThunks';
import { JSONApi, ModelAttributes, Models } from '../types';

type UseGroupPostsChannelArgs = {
  group: JSONApi.GroupResource;
};

export const useGroupPostsChannel = ({ group }: UseGroupPostsChannelArgs) => {
  const apiDataRef = useApiDatRef();
  const dispatch = useThunkDispatch();

  const handleBroadcastUpdateReceived = useCallback(
    (update: JSONApi.Response<Models.BroadcastUpdate>) => {
      // note that the cached requests will be cleared by the BroadcastUpdateManager
      const data = forceArray(forceArray(update.data)[0].relationships.containingResource.data)[0];
      // we only want to reload the post if it's already in redux and thus possibly displaying on screen
      const post = getPost(apiDataRef.current, data.type, data.id);
      if (post) {
        dispatch(loadPost(group.attributes.slug, post.id, post.type as ModelAttributes.PostType));
      }
    },
    [apiDataRef, dispatch, group.attributes.slug]
  );

  useActionCableChannel({
    channelName: { channel: 'GroupPostsChannel', groupId: group.id },
    onReceived: handleBroadcastUpdateReceived
  });
};
