import { IonIcon, IonLabel } from '@ionic/react';
import React from 'react';

import ChipButton from '../components/ChipButton';

type Props = {
  icon?: string;
  label: string | React.ReactElement;
  link: string;
};

const SearchChip = ({ icon, label, link }: Props) => (
  <ChipButton color="secondary" routerLink={link}>
    {icon && <IonIcon icon={icon} />}
    <IonLabel>{label}</IonLabel>
  </ChipButton>
);

export default SearchChip;
