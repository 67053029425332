import ReactGA from 'react-ga';

export const initialize = () => {
  if (import.meta.env.VITE_GOOGLE_ANALYTICS_TRACKING_ID && window.PerformanceObserver) {
    ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS_TRACKING_ID, {
      debug: false
    });

    const navigationObserverCallback = (list: PerformanceObserverEntryList) => {
      list.getEntries().forEach(entry => {
        if (entry.entryType === 'navigation') {
          const timing = entry as PerformanceNavigationTiming;
          ReactGA.timing({
            category: 'Load Performance',
            value: timing.responseStart - timing.requestStart,
            variable: 'Server Latency'
          });
          ReactGA.timing({
            category: 'Load Performance',
            value: timing.responseEnd - timing.responseStart,
            variable: 'Download Time'
          });
          ReactGA.timing({
            category: 'Load Performance',
            value: timing.responseEnd - timing.requestStart,
            variable: 'Total Load Time'
          });
        }
      });
    };

    const paintObserverCallback = (list: PerformanceObserverEntryList) => {
      list.getEntries().forEach(entry => {
        if (entry.entryType === 'paint') {
          ReactGA.timing({
            category: 'Paint',
            value: entry.startTime,
            variable: entry.name
          });
        }
      });
    };

    const navigationObserver = new PerformanceObserver(navigationObserverCallback);
    try {
      navigationObserver.observe({ entryTypes: ['navigation'] });
    } catch {
      // This means that the browser doesn't support this. Older Android releases
      // don't seem to support either.
    }

    const paintObserver = new PerformanceObserver(paintObserverCallback);
    try {
      paintObserver.observe({ entryTypes: ['paint'] });
    } catch {
      // This means that the browser doesn't support this. Older Android releases
      // don't seem to support either.
    }
  }
};
