import { useSelector } from 'react-redux';

import { findById } from '../selectors';
import { Models, SessionState, State } from '../types';

const useCurrentUser = () => {
  const currentUserData = useSelector((root: State.Root) => root.session.currentUser);
  const apiData = useSelector((root: State.Root) => root.api);

  if (currentUserData.state === SessionState.LOADING) {
    return undefined;
  }

  // Second condition here shouldn't happen but makes TS happy
  if (currentUserData.state !== SessionState.SUCCESS || !currentUserData.id) {
    return null;
  }

  return findById<Models.User>(apiData, 'user', currentUserData.id);
};

export default useCurrentUser;
