import { IonIcon } from '@ionic/react';
import { attach } from 'ionicons/icons';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { addNotification } from '../../actions/notificationActions';
import FileInput from '../../components/forms/FileInput';
import { maxFileUploadSizeMb } from '../../globals';
import { AttachmentsFieldManager } from '../../hooks/useAttachmentsFieldManager';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { AjaxError } from '../../services/ajaxRequest';
import { forceArray } from '../../services/arrayUtils';
import { translateApiError } from '../../services/translateApiError';
import { createAttachment } from '../../thunks/apiThunks';
import { JSONApi, Models, State } from '../../types';

type Props = {
  attachmentsFieldManager: AttachmentsFieldManager;
};

const AddAttachment = ({ attachmentsFieldManager }: Props) => {
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target;
      const newFile = input?.files?.[0];
      if (newFile) {
        if (newFile.size / 1000000.0 > maxFileUploadSizeMb) {
          const errorMessage = intl.formatMessage(
            { id: 'errors.attachmentUpload.file.fileSizeIsLessThanOrEqualTo' },
            { count: `${maxFileUploadSizeMb.toString()}MB` }
          );
          dispatch(addNotification({ body: errorMessage, type: State.NotificationType.ERROR }));
        } else {
          attachmentsFieldManager.attachmentWillUpload();
          dispatch(createAttachment(newFile))
            .then((response: JSONApi.Response<Models.Attachment>) => {
              if (isMounted.current) {
                attachmentsFieldManager.addAttachment(forceArray(response.data)[0]);
                attachmentsFieldManager.attachmentDidUpload();
              }
            })
            .catch((error: AjaxError) => {
              attachmentsFieldManager.attachmentDidUpload();
              const errorMessage = translateApiError(intl, 'attachmentUpload', error.errors()[0]);
              dispatch(addNotification({ body: errorMessage, type: State.NotificationType.ERROR }));
            });
        }
      }
    },
    [attachmentsFieldManager, dispatch, intl, isMounted]
  );

  return (
    <FileInput name="file" onChange={handleChange}>
      <IonIcon icon={attach} slot="icon-only" />
    </FileInput>
  );
};

export default AddAttachment;
