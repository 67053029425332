import { IonSpinner, ScrollDetail } from '@ionic/react';
import React, { createRef, useCallback, useEffect, useState } from 'react';

import useMountedTracking from '../hooks/useMountedTracking';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { AjaxResponse } from '../services/ajaxRequest';
import { forceArray } from '../services/arrayUtils';
import { Actions, JSONApi, Models, State } from '../types';

import './InfiniteScroll.scss';

type Props<T extends Models.Base> = {
  contentRef: React.RefObject<HTMLIonContentElement>;
  loadMoreActionCreator: () => (
    dispatch: Actions.ThunkDispatch,
    getState: () => State.Root
  ) => Promise<AjaxResponse<T> | undefined>;
  onDataLoad?: (resources: JSONApi.Resource<T>[]) => void;
};

function InfiniteScroll<T extends Models.Base>({
  contentRef,
  loadMoreActionCreator,
  onDataLoad
}: Props<T>) {
  const dispatch = useThunkDispatch();
  const isMounted = useMountedTracking();
  const infiniteRef = createRef<HTMLIonItemElement>();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleIonScroll = useCallback(
    (event: Event) => {
      if (isDisabled || isLoading) {
        return;
      }

      contentRef.current?.getScrollElement().then(scrollEl => {
        const thresholdPixels = 100;

        let infiniteHeight = 0;
        if (infiniteRef.current) {
          infiniteHeight = infiniteRef.current.offsetHeight;
        }

        const detailEvent = event as CustomEvent<ScrollDetail>;
        const scrollTop = detailEvent.detail.scrollTop;
        const scrollHeight = scrollEl.scrollHeight;
        const height = scrollEl.offsetHeight;

        const distanceFromInfinite =
          scrollHeight - infiniteHeight - scrollTop - thresholdPixels - height;

        if (distanceFromInfinite < 0) {
          setIsLoading(true);
          contentRef.current?.removeEventListener('ionScroll', handleIonScroll);
          dispatch(loadMoreActionCreator())
            .then(responseBody => {
              if (responseBody) {
                if (onDataLoad) {
                  onDataLoad(forceArray(responseBody.data));
                }
                contentRef.current?.addEventListener('ionScroll', handleIonScroll);
              } else if (isMounted.current) {
                setIsDisabled(true);
              }
              responseBody?.dataLoadedIntoStatePromise
                .then(() => {
                  if (isMounted.current) {
                    setIsLoading(false);
                  }
                })
                .catch(() => {
                  if (isMounted.current) {
                    setIsLoading(false);
                  }
                });
            })
            .catch(() => {
              if (isMounted.current) {
                setIsLoading(false);
                setIsDisabled(true);
              }
            });
        }
      });
    },
    [
      contentRef,
      dispatch,
      infiniteRef,
      isDisabled,
      isLoading,
      isMounted,
      loadMoreActionCreator,
      onDataLoad
    ]
  );

  useEffect(() => {
    const currentRef = contentRef.current;
    currentRef?.addEventListener('ionScroll', handleIonScroll);

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('ionScroll', handleIonScroll);
      }
    };
  }, [contentRef, handleIonScroll]);

  if (isLoading && !isDisabled) {
    return (
      <div className="infinite-scroll">
        <div className="infinite-scroll-spinner">
          <IonSpinner name="lines" />
        </div>
      </div>
    );
  }

  return <span />;
}

export default InfiniteScroll;
