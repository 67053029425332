import { IonIcon, IonProgressBar } from '@ionic/react';
import loadImage from 'blueimp-load-image';
import { camera } from 'ionicons/icons';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import FileInput from '../../components/forms/FileInput';
import Img from '../../components/Img';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { orientedImageToDataURL } from '../../services/imageUtils';
import { updateGroup } from '../../thunks/apiThunks';
import { JSONApi } from '../../types';

type Props = {
  group: JSONApi.GroupResource;
};

const EditGroupBackgroundImage = ({ group }: Props) => {
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();
  const [isUpdating, setIsUpdating] = useState(false);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target;
      const newAvatarImage = input?.files?.[0];
      if (newAvatarImage) {
        setIsUpdating(true);
        loadImage(
          newAvatarImage,
          orientedImg => {
            const dataURL = orientedImageToDataURL(orientedImg);
            if (dataURL) {
              dispatch(updateGroup({ bgImage: dataURL }, group.id))
                .then(() => {
                  if (isMounted.current) {
                    setIsUpdating(false);
                  }
                })
                .catch(() => {
                  if (isMounted.current) {
                    setIsUpdating(false);
                  }
                });
            } else if (isMounted.current) {
              setIsUpdating(false);
            }
          },
          { canvas: true, contain: true, maxHeight: 1280, maxWidth: 1280, orientation: true }
        );
      }
    },
    [dispatch, group.id, isMounted]
  );

  const handleKeyUp = useCallback((event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      const target = event.target as HTMLIonAvatarElement & typeof event.target;
      target.click();
    }
  }, []);

  return (
    <>
      <div className="content">
        {isUpdating && <IonProgressBar type="indeterminate" />}
        {group.attributes.bgImageUrl && !isUpdating && (
          <Img
            alt={intl.formatMessage({ id: 'models.group.attributes.bgImage.alt' })}
            src={group.attributes.bgImageUrl}
          />
        )}
      </div>
      <FileInput disabled={isUpdating} name="avatarImage" onChange={handleChange}>
        <div
          className="camera-button ion-activatable"
          color="secondary"
          onKeyUp={handleKeyUp}
          role="button"
          tabIndex={0}
        >
          <IonIcon icon={camera} slot="icon-only" />
          <FormattedMessage id="dictionary.backgroundImage" />
        </div>
      </FileInput>
    </>
  );
};

export default EditGroupBackgroundImage;
