import { IonItem } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

import { alertError } from '../../actions/notificationActions';
import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormInput from '../../components/forms/FormInput';
import FormSubmit from '../../components/forms/FormSubmit';
import SubmitError from '../../components/forms/SubmitError';
import Paragraph from '../../components/Paragraph';
import UserAccountWizardBackButton from '../../components/UserAccountWizardBackButton';
import { maxEmailLength } from '../../globals';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { resetPassword } from '../../thunks/apiThunks';
import { JSONApi } from '../../types';

type Props = {
  email?: string;
  goBack: () => void;
  group?: JSONApi.GroupResource | null | undefined;
};

type FormValues = {
  email: string;
};

const ForgotPasswordForm = ({ email, goBack, group }: Props) => {
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const handleSubmit = useCallback(
    async (values: FormValues) =>
      dispatch(resetPassword(group, values.email))
        .then(() => {
          if (isMounted.current) {
            history.push({
              pathname: `/check-for-password-recovery`,
              state: { email: values.email, group }
            });
          }
        })
        .catch(() => {
          if (isMounted.current) {
            dispatch(alertError('forms.forgotPassword.create.failed'));
          }
        }),
    [dispatch, group, history, isMounted]
  );

  const initialValues: FormValues = {
    email: email ?? ''
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'errors.login.email.invalid' }))
      .required(intl.formatMessage({ id: 'errors.login.email.blank' }))
      .max(
        maxEmailLength,
        intl.formatMessage({ id: 'errors.emailAddress.email.tooLong' }, { count: maxEmailLength })
      )
  });

  return (
    <>
      <UserAccountWizardBackButton onClick={goBack} />
      <FormContainer<FormValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ form, handleSubmit }) => (
          <Form<FormValues>
            className="forgot-password-form"
            onFormSubmit={handleSubmit}
            submit={form.submit}
          >
            <IonItem className="compact" color="transparent" lines="full">
              <FormInput
                aria-label={intl.formatMessage({
                  id: 'models.passwordReset.attributes.email.label'
                })}
                autofocus
                color="primary"
                name="email"
                placeholder={intl.formatMessage({
                  id: 'models.passwordReset.attributes.email.placeholder'
                })}
                type="email"
              />
            </IonItem>
            <SubmitError />
            <FormError allowBlank name="email" />
            <IonItem className="compact" lines="none">
              <Paragraph>
                <FormattedMessage id="models.passwordReset.attributes.email.tip" />
              </Paragraph>
            </IonItem>
            <FormSubmit color="primary">
              <FormattedMessage id="dictionary.reset" />
            </FormSubmit>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default ForgotPasswordForm;
