import { DatetimeChangeEventDetail } from '@ionic/react';
import React, { useCallback } from 'react';

import { EventFormValues } from '../../components/forms/EventForm';
import FormDatetime from '../../components/forms/FormDatetime';
import FormError from '../../components/forms/FormError';
import { addTime, getDuration, systemLocalTime } from '../../services/dateUtils';

type F = 'startTime' | 'endTime';

type Props = {
  change: (name: F, value?: EventFormValues[F]) => void;
  format: 'date' | 'time';
  name: F;
  values: EventFormValues;
};

const EventTimeField = ({ change, format, name, values }: Props) => {
  const thisYear = new Date().getFullYear();
  const lastYear = thisYear - 1;
  const twoYearsFromNow = thisYear + 2;

  /*
   * Changing the start time of an event should not change it's duration, thus, the end time
   * automatically adjusts by the same amount as the start time. Changing the end time does
   * change the event duration, and thus doesn't impact the start time at all.
   * This approach is consistent with how Facebook's events form and Google Calendar work.
   */
  const setTimeFields = useCallback(
    (newTime: string) => {
      if (name === 'startTime') {
        const duration = getDuration(values.startTime, values.endTime);
        const endTime = systemLocalTime(addTime(newTime, duration)) as string;
        change('startTime', newTime);
        change('endTime', endTime);
      } else {
        change('endTime', newTime);
      }
    },
    [change, name, values.endTime, values.startTime]
  );

  const handleDatetimeChange = useCallback(
    (event: CustomEvent<DatetimeChangeEventDetail>) => {
      const input = event.target as HTMLIonDatetimeElement & typeof event.target;
      // we know that we won't use this component with a "multiple" datetime field
      // so recast the value as string only rather than string[]
      const newTime = input.value as string | null | undefined;
      if (newTime) {
        setTimeFields(newTime);
      }
    },
    [setTimeFields]
  );

  const datetimeProps: React.ComponentProps<typeof FormDatetime> = {
    name,
    onIonChange: handleDatetimeChange
  };
  if (format === 'date') {
    datetimeProps.max = twoYearsFromNow.toString();
    datetimeProps.min = lastYear.toString();
  }
  if (format === 'time') {
    datetimeProps.minuteValues = [0, 15, 30, 45];
  }
  return (
    <>
      <FormError name={name} />
      <FormDatetime {...datetimeProps} />
    </>
  );
};

export default EventTimeField;
