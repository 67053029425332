import { forceArray, sortByCreatedAt, sortByPosition } from '../services/arrayUtils';
import { JSONApi, ModelAttributes, Models, State } from '../types';

export const findResources = <ModelType extends Models.Base>(
  state: State.Api,
  type: string,
  key: string | undefined,
  value: string[] | string | undefined,
  group: JSONApi.GroupResource | null
): JSONApi.Resource<ModelType>[] => {
  if (state.resources[type]) {
    const resourceTypeCollection = state.resources[
      type
    ] as JSONApi.ResourceTypeCollection<ModelType>;
    if (!resourceTypeCollection) {
      return [];
    }
    const resources = Object.values(resourceTypeCollection);
    type ThingWithKeys = {
      [key: string]: unknown;
    };
    return resources.filter(resource => {
      const typedAttributes = resource.attributes as ThingWithKeys & typeof resource.attributes;
      const keyMatch =
        !key || (value && forceArray(value).includes(typedAttributes[key] as string));
      const groupData =
        resource.relationships?.group && forceArray(resource.relationships.group.data)[0];
      const groupMatch = !group || (groupData && groupData.id === group.id);
      return keyMatch && groupMatch;
    });
  }
  return [];
};

export const findResource = <ModelType extends Models.Base>(
  state: State.Api,
  type: string,
  key: string,
  value: string,
  group: JSONApi.GroupResource | null
): JSONApi.Resource<ModelType> | null =>
  findResources<ModelType>(state, type, key, value, group)[0] || null;

export const findById = <ModelType extends Models.Base>(
  state: State.Api,
  type: string,
  id: string
): JSONApi.Resource<ModelType> | undefined => {
  if (state.resources[type]) {
    return state.resources[type][id] as JSONApi.Resource<ModelType> | undefined;
  }
};

export const findBySlug = <ModelType extends Models.Base>(
  state: State.Api,
  type: string,
  slug: string,
  group: JSONApi.GroupResource | null
): JSONApi.Resource<ModelType> | null => findResource<ModelType>(state, type, 'slug', slug, group);

export const getUser = (state: State.Api, id: string) => findById<Models.User>(state, 'user', id);

export const getGroup = (apiData: State.Api, slug: string) =>
  findResource<Models.Group>(apiData, 'group', 'slug', slug, null);

export const getPost = (apiData: State.Api, type: string, id: string) =>
  findById<Models.Post>(apiData, type, id);

export const getMemberGroupId = (resource: JSONApi.MemberResource): string | undefined => {
  const relationships = resource.relationships;
  if (relationships) {
    const groupData = forceArray(relationships.group.data)[0];
    if (groupData) {
      return groupData.id;
    }
  }
  return undefined;
};

export const getRelatedResourcesFromIncluded = <
  OwnerModel extends Models.Base,
  RelatedModel extends Models.Base
>(
  resource: JSONApi.Resource<OwnerModel>,
  included: JSONApi.BaseResource[],
  relationshipDescriptor: string
): JSONApi.Resource<RelatedModel>[] => {
  const parts = relationshipDescriptor.split('.');
  const relationshipName = parts.shift() as string;
  const relationships = resource.relationships;
  if (relationships) {
    const relationship = relationships[relationshipName];
    if (relationship) {
      const relatedResourceIdentifiers = relationship.data;
      if (relatedResourceIdentifiers) {
        const resources = forceArray(relatedResourceIdentifiers).map(
          relatedResourceIdenitifier =>
            included.filter(
              includedRecord =>
                includedRecord.type === relatedResourceIdenitifier.type &&
                includedRecord.id === relatedResourceIdenitifier.id
            )[0]
        );
        if (parts.length > 0) {
          const mapped = resources.map(subResource =>
            getRelatedResourcesFromIncluded<Models.Base, RelatedModel>(
              subResource,
              included,
              parts.join('.')
            )
          );
          return mapped.flat();
        }
        return resources.filter(obj => !!obj) as JSONApi.Resource<RelatedModel>[];
      }
    }
  }
  return [];
};

const _getRelatedResources = <ModelType extends Models.Base>(
  state: State.Api,
  owningResource: JSONApi.BaseResource,
  relationshipName: string
): JSONApi.Resource<ModelType>[] => {
  const relationships = owningResource.relationships;
  if (relationships) {
    const relationship = relationships[relationshipName];
    if (relationship) {
      const relatedResourceIdentifiers = relationship.data;
      if (relatedResourceIdentifiers) {
        const relatedResources = forceArray(relatedResourceIdentifiers).map(item => {
          if (item) {
            const resourcesOfItemType = state.resources[item.type];
            if (resourcesOfItemType) {
              return resourcesOfItemType[item.id] as JSONApi.Resource<ModelType>;
            }
          }
          return undefined;
        });
        return relatedResources.filter(obj => !!obj) as JSONApi.Resource<ModelType>[];
      }
    }
  }
  return [];
};

const _getRelatedResourcesInverse = <ModelType extends Models.Base>(
  state: State.Api,
  owningResource: JSONApi.BaseResource,
  ownerName: string,
  relationshipName: string
) => {
  const apiTargetResourceCollection = state.resources[
    relationshipName
  ] as JSONApi.ResourceTypeCollection<ModelType>;
  if (!apiTargetResourceCollection) {
    return [];
  }
  const allTargetResourcesInApi = Object.values(apiTargetResourceCollection);
  return allTargetResourcesInApi.filter(targetResource => {
    const targetResourceOwnerRelationship = targetResource.relationships[ownerName];
    if (targetResourceOwnerRelationship) {
      const targetResourceOwner = forceArray(targetResourceOwnerRelationship.data)[0];
      if (targetResourceOwner) {
        return (
          owningResource.type === targetResourceOwner.type &&
          owningResource.id === targetResourceOwner.id
        );
      }
      return false;
    }
    return false;
  });
};

export const getOwnerLinks = (
  state: State.Api,
  owner: JSONApi.GroupResource | JSONApi.MemberResource
) => sortByPosition(_getRelatedResources<Models.Link>(state, owner, 'links'));

export const getPostComments = (state: State.Api, post: JSONApi.PostResource) =>
  _getRelatedResourcesInverse<Models.Comment>(state, post, 'post', 'comment').sort((a, b) => {
    if (!a.attributes.createdAt || !b.attributes.createdAt) {
      return 0;
    }
    return a.attributes.createdAt > b.attributes.createdAt ? 1 : -1;
  });

export const getCommentCreator = (state: State.Api, comment: JSONApi.CommentResource) =>
  forceArray(_getRelatedResources<Models.Member>(state, comment, 'creator'))[0];

export const getCommentPost = (state: State.Api, comment: JSONApi.CommentResource) =>
  forceArray(_getRelatedResources<Models.Post>(state, comment, 'post'))[0];

export const getPostAuthor = (state: State.Api, post: JSONApi.PostResource) =>
  forceArray(_getRelatedResources<Models.Member>(state, post, 'poster'))[0];

export const getPinnedPost = (state: State.Api, group: JSONApi.GroupResource) =>
  forceArray(_getRelatedResources<Models.Post>(state, group, 'pinnedPost'))[0];

export const getPendingGroupFlags = (state: State.Api, group: JSONApi.GroupResource) =>
  findResources<Models.Flag>(state, 'flag', undefined, undefined, group).filter(
    flag => flag.attributes.status === ModelAttributes.FlagStatus.PENDING
  );

export const getPendingGroupFlaggedItems = (state: State.Api, group: JSONApi.GroupResource) =>
  findResources<Models.FlaggedItem>(state, 'flaggedItem', undefined, undefined, group).filter(
    flaggedItem => flaggedItem.attributes.status === ModelAttributes.FlagStatus.PENDING
  );

export const getFlagFlagger = (state: State.Api, flag: JSONApi.FlagResource) =>
  forceArray(_getRelatedResources<Models.Member>(state, flag, 'flagger'))[0];

export const getFlaggedItem = (state: State.Api, group: JSONApi.GroupResource, id: string) =>
  getPendingGroupFlaggedItems(state, group).find(flaggedItem => flaggedItem.id === id);

export const getFlaggedItemFlags = (state: State.Api, flaggedItem: JSONApi.FlaggedItemResource) =>
  sortByCreatedAt(_getRelatedResources<Models.Flag>(state, flaggedItem, 'flags'));

export const getFlaggedItemItem = (state: State.Api, flaggedItem: JSONApi.FlaggedItemResource) =>
  forceArray(
    _getRelatedResources<Models.Comment | Models.Member | Models.Post>(state, flaggedItem, 'item')
  )[0];

export const getGroupPriceTier = (state: State.Api, group: JSONApi.GroupResource) =>
  forceArray(_getRelatedResources<Models.PriceTier>(state, group, 'priceTier'))[0];

export const getGroupRules = (state: State.Api, group: JSONApi.GroupResource) =>
  sortByPosition(_getRelatedResources<Models.Rule>(state, group, 'rules'));

export const getGroupTraits = (state: State.Api, group: JSONApi.GroupResource) =>
  sortByPosition(_getRelatedResources<Models.Trait>(state, group, 'traits'));

export const getTraitOptions = (state: State.Api, trait: JSONApi.TraitResource) =>
  sortByPosition(_getRelatedResources<Models.TraitOption>(state, trait, 'traitOptions'));

export const getTraitOptionSelections = (state: State.Api, member: JSONApi.MemberResource) =>
  _getRelatedResources<Models.TraitOptionSelection>(state, member, 'traitOptionSelections');

export const getTrait = (state: State.Api, selection: JSONApi.TraitOptionSelectionResource) =>
  _getRelatedResources<Models.Trait>(state, selection, 'trait')[0];

export const getTraitOption = (state: State.Api, selection: JSONApi.TraitOptionSelectionResource) =>
  _getRelatedResources<Models.Trait>(state, selection, 'traitOption')[0];

export const getGroupWhitelistedDomains = (state: State.Api, group: JSONApi.GroupResource) =>
  _getRelatedResources<Models.WhitelistedDomain>(state, group, 'whitelistedDomains');

export const getSearchResults = <T extends Models.Base>(
  state: State.Api,
  searchData: JSONApi.SearchResource
) => _getRelatedResources<T>(state, searchData, 'results');

export const getBlocks = (state: State.Api, group: JSONApi.GroupResource) =>
  sortByCreatedAt(findResources<Models.Block>(state, 'block', undefined, undefined, group));

export const getBlockedResource = (state: State.Api, block: JSONApi.BlockResource) =>
  _getRelatedResources<Models.Base>(state, block, 'blocked')[0];

export const getConversationAttachments = (
  state: State.Api,
  conversation: JSONApi.ConversationResource
) => _getRelatedResources<Models.Attachment>(state, conversation, 'attachments');

export const getConversationCreator = (
  state: State.Api,
  conversation: JSONApi.ConversationResource
) => _getRelatedResources<Models.Member>(state, conversation, 'creator')[0];

export const getConversationJobPost = (
  state: State.Api,
  conversation: JSONApi.ConversationResource
) => _getRelatedResources<Models.JobPost>(state, conversation, 'jobPost')[0];

export const getConversationRecipient = (
  state: State.Api,
  conversation: JSONApi.ConversationResource
) => _getRelatedResources<Models.Member>(state, conversation, 'recipient')[0];

export const getConversationNotes = (
  state: State.Api,
  conversation: JSONApi.ConversationResource
) =>
  _getRelatedResources<Models.Note>(state, conversation, 'notes').sort((a, b) => {
    if (a.attributes.createdAt && b.attributes.createdAt) {
      return a.attributes.createdAt.valueOf() - b.attributes.createdAt.valueOf();
    }
    return 0;
  });

export const getGroupSearchResults = (state: State.Api, searchData: JSONApi.GroupSearchResource) =>
  _getRelatedResources<Models.Group>(state, searchData, 'results');

export const getAllImages = (state: State.Api) =>
  sortByCreatedAt(findResources<Models.Image>(state, 'image', undefined, undefined, null));

export const getImageAutoModerationResult = (state: State.Api, image: JSONApi.ImageResource) =>
  _getRelatedResources<Models.AutoModerationResult>(state, image, 'autoModerationResult')[0];

export const getAllAttachments = (state: State.Api) =>
  sortByCreatedAt(
    findResources<Models.Attachment>(state, 'attachment', undefined, undefined, null)
  );

export const getAttachmentAutoModerationResult = (
  state: State.Api,
  attachment: JSONApi.AttachmentResource
) =>
  _getRelatedResources<Models.AutoModerationResult>(state, attachment, 'autoModerationResult')[0];

export const getInviteGroup = (state: State.Api, invite: JSONApi.InviteResource) =>
  _getRelatedResources<Models.Group>(state, invite, 'group')[0];

export const getInviteLookupGroup = (
  state: State.Api,
  inviteLookup: JSONApi.InviteLookupResource
) => _getRelatedResources<Models.Group>(state, inviteLookup, 'group')[0];

export const getInviteLookupInvite = (
  state: State.Api,
  inviteLookup: JSONApi.InviteLookupResource
) => _getRelatedResources<Models.Invite>(state, inviteLookup, 'invite')[0];

export const getReceivedInvites = (
  state: State.Api,
  emailAddresses: JSONApi.EmailAddressResource[]
) =>
  findResources<Models.Invite>(
    state,
    'invite',
    'inviteeEmail',
    emailAddresses.map(emailAddress => emailAddress.attributes.email),
    null
  ).filter(invite => invite.attributes.status === ModelAttributes.InviteStatus.SENT);

export const getMemberGroup = (state: State.Api, member: JSONApi.MemberResource) =>
  _getRelatedResources<Models.Group>(state, member, 'group')[0];

export const getMemberSubscriptionLocationFilters = (
  state: State.Api,
  member: JSONApi.MemberResource
) =>
  _getRelatedResources<Models.SubscriptionLocationFilter>(
    state,
    member,
    'subscriptionLocationFilters'
  );

export const getJobPostApplication = (state: State.Api, jobPost: JSONApi.JobPostResource) =>
  _getRelatedResources<Models.Conversation>(state, jobPost, 'viewerApplication')[0];

export const getJobPostAttachments = (state: State.Api, jobPost: JSONApi.JobPostResource) =>
  _getRelatedResources<Models.Attachment>(state, jobPost, 'attachments');

export const getMemberSubscriptions = (state: State.Api, member: JSONApi.MemberResource) =>
  _getRelatedResourcesInverse<Models.Subscription>(state, member, 'member', 'subscription');

export const getMemberWelcome = (state: State.Api, member: JSONApi.MemberResource) =>
  _getRelatedResources<Models.Conversation>(state, member, 'welcomeReceivedFromViewer')[0];

export const getMentions = (state: State.Api, group: JSONApi.GroupResource) =>
  sortByCreatedAt(findResources<Models.Mention>(state, 'mention', undefined, undefined, group));

export const getMentionCreator = (state: State.Api, mention: JSONApi.MentionResource) =>
  _getRelatedResources<Models.Member>(state, mention, 'creator')[0];

export const getMentionedInResource = (state: State.Api, mention: JSONApi.MentionResource) =>
  _getRelatedResources<Models.Base>(state, mention, 'mentionedIn')[0];

export const getNoteAttachments = (state: State.Api, note: JSONApi.NoteResource) =>
  _getRelatedResources<Models.Attachment>(state, note, 'attachments');

export const getNoteCreator = (state: State.Api, note: JSONApi.NoteResource) =>
  _getRelatedResources<Models.Member>(state, note, 'creator')[0];

export const getPostImage = (state: State.Api, post: JSONApi.PostResource) =>
  _getRelatedResources<Models.Image>(state, post, 'image')[0];

export const getPostImages = (state: State.Api, post: JSONApi.PostResource) =>
  _getRelatedResources<Models.Image>(state, post, 'images');

export const getPostFollow = (state: State.Api, post: JSONApi.PostResource) =>
  _getRelatedResources<Models.Follow>(state, post, 'viewerFollow')[0];

export const getSaveForResource = (state: State.Api, resource: JSONApi.BaseResource) =>
  _getRelatedResources<Models.Save>(state, resource, 'viewerSave')[0];

export const getSaves = (state: State.Api, group: JSONApi.GroupResource) =>
  sortByCreatedAt(findResources<Models.Save>(state, 'save', undefined, undefined, group));

export const getSavedResource = (state: State.Api, save: JSONApi.SaveResource) =>
  _getRelatedResources<Models.Base>(state, save, 'saved')[0];

export const getPostViewerRsvp = (state: State.Api, post: JSONApi.PostResource) =>
  _getRelatedResources<Models.Rsvp>(state, post, 'viewerRsvp')[0];

export const getEventAttendees = (state: State.Api, event: JSONApi.EventResource) =>
  _getRelatedResources<Models.Rsvp>(state, event, 'rsvps').map(
    rsvp => _getRelatedResources<Models.Member>(state, rsvp, 'member')[0]
  );

export const getRecordBlock = (state: State.Api, record: JSONApi.BlockableResource) =>
  _getRelatedResources<Models.Block>(state, record, 'viewerBlock')[0];

export const getUserEmailAddresses = (state: State.Api, user: JSONApi.UserResource) =>
  _getRelatedResources<Models.EmailAddress>(state, user, 'emailAddresses').sort((a, b) => {
    if (a.attributes.primary) {
      return -1;
    }
    if (b.attributes.primary) {
      return 1;
    }
    return a.attributes.email > b.attributes.email ? 1 : -1;
  });

export const getUserMembers = (state: State.Api, user: JSONApi.UserResource) => {
  if (!user.relationships || !user.relationships.members) {
    return [];
  }
  const data = forceArray(user.relationships.members.data);
  return data
    .map(resource => findById<Models.Member>(state, resource.type, resource.id))
    .filter(m => !!m) as JSONApi.MemberResource[];
};

export const getUserGroups = (state: State.Api, user: JSONApi.UserResource) => {
  const members = getUserMembers(state, user);
  const groups = members.map(member => {
    const groupData = forceArray(member.relationships.group.data)[0];
    if (groupData) {
      return findById(state, 'group', groupData.id);
    }
    return null;
  }) as JSONApi.GroupResource[];
  return groups.filter(g => !!g);
};

export const getUserMemberForGroup = (
  state: State.Api,
  user: JSONApi.UserResource,
  group: JSONApi.GroupResource
) => {
  const members = getUserMembers(state, user);
  return (
    members.find(member => {
      const groupData = forceArray(member.relationships.group.data)[0];
      return groupData && groupData.id === group.id;
    }) ?? null
  );
};

export const getUserUnreadCounts = (
  state: State.Api,
  user: JSONApi.UserResource,
  group: JSONApi.GroupResource
) => {
  const member = getUserMemberForGroup(state, user, group);
  if (member) {
    return findById<Models.UnreadCounts>(state, 'unreadCounts', member.id);
  }
};

export const getReactionContent = (state: State.Api, reaction: JSONApi.ReactionResource) =>
  forceArray(_getRelatedResources<Models.Comment | Models.Post>(state, reaction, 'content'))[0];

export const getViewerReaction = (
  state: State.Api,
  content: JSONApi.CommentResource | JSONApi.PostResource
) => forceArray(_getRelatedResources<Models.Reaction>(state, content, 'viewerReaction'))[0];

export const getPlanChangePreviewPriceTier = (
  state: State.Api,
  planChangePreview: JSONApi.PlanChangePreviewResource
) => forceArray(_getRelatedResources<Models.PriceTier>(state, planChangePreview, 'priceTier'))[0];
