import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';

import AvatarRandom from '../components/AvatarRandom';

import './ChatBubble.scss';

const SimpleChatBubble = ({
  children,
  color
}: PropsWithChildren<{
  color: 'danger' | 'warning';
}>) => {
  const intl = useIntl();

  return (
    <div className="ion-padding">
      <div className={`chat-bubble color-${color}`}>
        <AvatarRandom alt={intl.formatMessage({ id: 'dictionary.randomAvatar' })} id="static2" />
        <div className="message">
          <div className="bubble">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default SimpleChatBubble;
