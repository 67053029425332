import { IonLabel, IonNote } from '@ionic/react';
import equal from 'deep-equal';
import { FormApi } from 'final-form';
import React, { useCallback } from 'react';
import { FormSpy } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

import AddAttachmentItem from '../../components/forms/AddAttachmentItem';
import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormSubmit from '../../components/forms/FormSubmit';
import FormTextarea from '../../components/forms/FormTextarea';
import RequiredFieldMarker from '../../components/forms/RequiredFieldMarker';
import SubmitError from '../../components/forms/SubmitError';
import { maxTextInputLength, minParagraphLength } from '../../globals';
import useAttachmentsFieldManager from '../../hooks/useAttachmentsFieldManager';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { forceArray } from '../../services/arrayUtils';
import { handleFormSubmission } from '../../services/formUtils';
import { createJobApplication } from '../../thunks/apiThunks';
import { JSONApi, Models } from '../../types';

import './CustomForm.scss';

type Props = {
  autofocus: boolean;
  group: JSONApi.GroupResource;
  jobPost: JSONApi.JobPostResource;
  onSuccess?: () => void;
};

const NewJobApplicationForm = ({ autofocus, group, jobPost, onSuccess }: Props) => {
  const attachmentsFieldManager = useAttachmentsFieldManager();
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const initialValues: Pick<Models.CreateJobApplication, 'coverLetter'> = {
    coverLetter: ''
  };

  const handleSubmit = useCallback(
    async (
      values: Pick<Models.CreateJobApplication, 'coverLetter'>,
      form: FormApi<Pick<Models.CreateJobApplication, 'coverLetter'>>
    ) =>
      handleFormSubmission({
        action: createJobApplication(
          group.attributes.slug,
          jobPost,
          values.coverLetter,
          attachmentsFieldManager.attachments
        ),
        dispatch,
        errorTranslationLocation: 'jobApplication',
        form,
        intl,
        isMounted,
        onSuccess: (response: JSONApi.Response<Models.Conversation>) => {
          if (onSuccess) {
            onSuccess();
          }
          const newJobApplication = forceArray(response.data)[0];
          history.push(
            `/g/${group.attributes.slug}/inbox/conversations/${newJobApplication.attributes.slug}`
          );
        },
        successTKey: 'forms.jobApplication.create.success',
        values
      }),
    [
      attachmentsFieldManager,
      dispatch,
      group.attributes.slug,
      history,
      intl,
      isMounted,
      jobPost,
      onSuccess
    ]
  );

  const validationSchema = Yup.object().shape({
    coverLetter: Yup.string()
      .required(intl.formatMessage({ id: 'errors.jobApplication.coverLetter.blank' }))
      .max(
        maxTextInputLength,
        intl.formatMessage(
          { id: 'errors.jobApplication.coverLetter.tooLong' },
          { count: maxTextInputLength }
        )
      )
      .min(
        minParagraphLength,
        intl.formatMessage(
          { id: 'errors.jobApplication.coverLetter.tooShort' },
          { count: minParagraphLength }
        )
      )
  });

  return (
    <FormContainer<Pick<Models.CreateJobApplication, 'coverLetter'>>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ form, handleSubmit }) => (
        <div className="custom-form-wrapper">
          <Form<Pick<Models.CreateJobApplication, 'coverLetter'>>
            className="job-application-form"
            onFormSubmit={handleSubmit}
            submit={form.submit}
          >
            <div className="ion-padding-end ion-padding-start">
              <SubmitError />
              <div className="ion-padding-bottom">
                <strong>
                  <IonNote color="medium">
                    <FormattedMessage id="models.jobApplication.suggestion" />
                  </IonNote>
                </strong>
              </div>
              <IonLabel>
                <FormattedMessage id="models.jobApplication.attributes.coverLetter.label" />
                <RequiredFieldMarker />
              </IonLabel>
              <FormError name="coverLetter" />
              <FormTextarea
                autoGrow={false}
                autofocus={autofocus}
                name="coverLetter"
                placeholder={intl.formatMessage({
                  id: 'models.jobApplication.attributes.coverLetter.placeholder'
                })}
                rows={8}
              />
            </div>
            <AddAttachmentItem
              attachmentsFieldManager={attachmentsFieldManager}
              labelKey="models.jobApplication.relationships.attachments.add"
              showLabel
            />
            <div className="ion-padding">
              <FormSpy subscription={{ pristine: true }}>
                {({ pristine }) => {
                  const isPristine =
                    pristine && equal(undefined, attachmentsFieldManager.attachments);
                  return (
                    <FormSubmit
                      allowPristineSubmit
                      disabled={attachmentsFieldManager.isUploading || isPristine}
                    >
                      <FormattedMessage id="dictionary.apply" />
                    </FormSubmit>
                  );
                }}
              </FormSpy>
            </div>
          </Form>
        </div>
      )}
    </FormContainer>
  );
};

export default NewJobApplicationForm;
