import { IonList, IonSegment, IonSegmentButton, SegmentChangeEventDetail } from '@ionic/react';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ModeratorPage from '../components/layout/ModeratorPage';
import ModerateMember from '../components/ModerateMember';
import SearchQuery from '../components/SearchQuery';
import SearchResults from '../components/SearchResults';
import { loadMemberSearch, loadMoreMemberSearchResults } from '../thunks/apiThunks';
import { ApiEndpoint, JSONApi, ModelAttributes, Models, Search } from '../types';

import '../components/forms/CustomForm.scss';

type Props = {
  group: JSONApi.GroupResource;
};

enum StatusFilters {
  ALL = 'all',
  APPROVED = 'approved',
  NEEDS_REVIEW = 'needsReview',
  REJECTED = 'rejected'
}

const ManageGroupMembersPage = ({ group }: Props) => {
  const [statusFilter, setStatusFilter] = useState<StatusFilters>(StatusFilters.ALL);
  const [searchParams, setSearchParams] = useState<Search.MemberParams>({
    country: null,
    industry: null,
    lat: null,
    lng: null,
    location: null,
    query: '',
    radius: null,
    status: Object.values(ModelAttributes.MemberStatus).join(','),
    traitOptionIds: ''
  });

  const handleMemberFilterChange = useCallback(
    (event: CustomEvent<SegmentChangeEventDetail>) => {
      const changeDetail = event.detail;
      if (changeDetail) {
        const newStatusFilter = changeDetail.value as StatusFilters;
        setStatusFilter(newStatusFilter);
        switch (newStatusFilter) {
          case StatusFilters.ALL:
            setSearchParams({
              ...searchParams,
              status: Object.values(ModelAttributes.MemberStatus).join(',')
            });
            break;
          case StatusFilters.APPROVED:
            setSearchParams({
              ...searchParams,
              status: ModelAttributes.MemberStatus.APPROVED
            });
            break;
          case StatusFilters.NEEDS_REVIEW:
            setSearchParams({
              ...searchParams,
              status: ModelAttributes.MemberStatus.AWAITING_MODERATION
            });
            break;
          case StatusFilters.REJECTED:
            setSearchParams({
              ...searchParams,
              status: [
                ModelAttributes.MemberStatus.ADMINISTRATOR_REJECTED,
                ModelAttributes.MemberStatus.MODERATOR_REJECTED
              ].join(',')
            });
            break;
          default:
            break;
        }
      }
    },
    [searchParams]
  );

  return (
    <ModeratorPage group={group} h2Key="pages.manageGroup.members.title">
      {contentRef => (
        <>
          <div className="custom-form-wrapper ion-padding">
            <SearchQuery<Search.MemberParams>
              autofocus={false}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </div>
          <IonSegment onIonChange={handleMemberFilterChange} value={statusFilter}>
            <IonSegmentButton value={StatusFilters.ALL}>
              <FormattedMessage id="dictionary.all" />
            </IonSegmentButton>
            <IonSegmentButton value={StatusFilters.NEEDS_REVIEW}>
              <FormattedMessage id="dictionary.needsReview" />
            </IonSegmentButton>
            <IonSegmentButton value={StatusFilters.APPROVED}>
              <FormattedMessage id="dictionary.approved" />
            </IonSegmentButton>
            <IonSegmentButton value={StatusFilters.REJECTED}>
              <FormattedMessage id="dictionary.rejected" />
            </IonSegmentButton>
          </IonSegment>
          <SearchResults<Models.MemberSearch, Models.Member, Search.MemberParams>
            contentRef={contentRef}
            group={group}
            loadDataActionCreator={loadMemberSearch}
            loadMoreActionCreator={loadMoreMemberSearchResults}
            searchEndpoint={ApiEndpoint.MEMBER_SEARCH}
            searchParams={searchParams}
            showCount={false}
          >
            {results => (
              <IonList>
                {results.map(member => (
                  <ModerateMember group={group} key={member.id} member={member} />
                ))}
              </IonList>
            )}
          </SearchResults>
        </>
      )}
    </ModeratorPage>
  );
};

export default ManageGroupMembersPage;
