import { IonCol, IonContent, IonGrid, IonRow } from '@ionic/react';
import React, { createRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ImgPane from '../components/ImgPane';
import Page from '../components/layout/Page';
import SearchResults from '../components/SearchResults';
import usePageVisitTracker from '../hooks/usePageVisitTracker';
import { loadImageSearch, loadMoreImageSearchResults } from '../thunks/apiThunks';
import { ApiEndpoint, JSONApi, Models, Search } from '../types';

import './GalleryPage.scss';

type Props = {
  group: JSONApi.GroupResource;
};

const GalleryPage = ({ group }: Props) => {
  const contentRef = createRef<HTMLIonContentElement>();
  // annoyingly this really needs to be "memoized" in the state so the object doesn't change
  const [searchParams] = useState<Search.ImageParams>({ query: null });

  usePageVisitTracker(group, Models.Readable.IMAGE);

  return (
    <Page className="gallery-page">
      <IonContent className="canvas" ref={contentRef} scrollEvents>
        <SearchResults<Models.ImageSearch, Models.Image, Search.ImageParams>
          contentRef={contentRef}
          group={group}
          loadDataActionCreator={loadImageSearch}
          loadMoreActionCreator={loadMoreImageSearchResults}
          searchEndpoint={ApiEndpoint.IMAGE_SEARCH}
          searchParams={searchParams}
          showCount={false}
        >
          {results => (
            <>
              {results.length === 0 && (
                <div className="ion-padding-end ion-padding-start">
                  <h2>
                    <FormattedMessage id="pages.gallery.noImages" />
                  </h2>
                </div>
              )}
              {results.length > 0 && (
                <IonGrid>
                  <IonRow>
                    {results.map(image => (
                      <IonCol key={image.id} size="4">
                        <ImgPane src={image.attributes.url} />
                      </IonCol>
                    ))}
                  </IonRow>
                </IonGrid>
              )}
            </>
          )}
        </SearchResults>
      </IonContent>
    </Page>
  );
};

export default GalleryPage;
