/*
 * this file is an overwrite of the react-final-form `Form` component. it:
 * 1) enforces the use of the childprops rendering strategy (instead of component or renderprops)
 * 2) extends the component with a `validationSchema` prop (and disables the existing `validate` prop)
 *    to offer a formik-like validation functionality using a yup schema
 */
import { FormSubscription, ValidationErrors } from 'final-form';
import React, { useCallback } from 'react';
import { Form, FormProps, FormRenderProps } from 'react-final-form';
import * as Yup from 'yup';

// you should be able to omit 'children' | 'component' | 'render' | 'validate'
// but for whatever reason typescript thinks that doing so omits all of the props
// so we pick only the props that we actually use instead
export default function FormContainer<FormValues>(
  props: Pick<
    FormProps<FormValues>,
    'initialValues' | 'keepDirtyOnReinitialize' | 'mutators' | 'onSubmit'
  > & {
    children: (props: FormRenderProps<FormValues>) => React.ReactNode;
    validationSchema?: Yup.AnyObjectSchema;
  }
) {
  const { children, validationSchema, ...formProps } = props;

  // in theory you don't _need_ to specify false for everything, but this makes it very clear
  const defaultSubscription: FormSubscription = {
    active: false,
    dirty: false,
    dirtyFields: false,
    dirtyFieldsSinceLastSubmit: false,
    dirtySinceLastSubmit: false,
    error: false,
    errors: false,
    hasSubmitErrors: false,
    hasValidationErrors: false,
    initialValues: false,
    invalid: false,
    modified: false,
    pristine: false,
    submitError: false,
    submitErrors: false,
    submitFailed: false,
    submitSucceeded: false,
    submitting: false,
    touched: false,
    valid: false,
    validating: false,
    values: false,
    visited: false
  };

  const applyValidationError = useCallback(
    (errors: ValidationErrors, path: string, message: string) => {
      const newErrors = { ...errors };
      // yup uses a dot notation syntax to represent the location of errors in the schema
      // example path: arrayName[index].fieldName
      const pathParts = path.split('.');
      const node = pathParts[0];
      if (pathParts.length === 1) {
        newErrors[node] = message;
      } else {
        const matches = /^([\w\d]+)(?:\[(\d+)\])$/i.exec(node);
        if (matches) {
          const nodeName = matches[1];
          const index = matches[2] as unknown as number;
          if (!newErrors[nodeName]) {
            newErrors[nodeName] = [];
          }
          // note that the `any` type seems to come from yup. we'll have to work on manually casting
          /* eslint-disable @typescript-eslint/no-unsafe-member-access */
          newErrors[nodeName][index] = applyValidationError(
            newErrors[nodeName][index],
            pathParts.slice(1).join('.'),
            message
          );
          /* eslint-enable @typescript-eslint/no-unsafe-member-access */
        }
      }
      return newErrors;
    },
    []
  );

  const destructureYupErrors = useCallback(
    (errors: ValidationErrors, error: Yup.ValidationError) => {
      let newErrors = { ...errors };
      if (error.inner.length > 0) {
        error.inner.forEach(subError => {
          newErrors = destructureYupErrors(newErrors, subError);
        });
      } else {
        // not a huge fan of the cast here, but we would only ever build schemas that work this way
        newErrors = applyValidationError(newErrors, error.path as string, error.message);
      }
      return newErrors;
    },
    [applyValidationError]
  );

  const validate = useCallback(
    async (values: FormValues) => {
      if (validationSchema) {
        try {
          await validationSchema.validate(values, { abortEarly: false });
        } catch (error) {
          const validationError = error as Yup.ValidationError;
          let errors: ValidationErrors = {};
          errors = destructureYupErrors(errors, validationError);
          return errors;
        }
      }
    },
    [destructureYupErrors, validationSchema]
  );

  return (
    <Form<FormValues> {...formProps} subscription={defaultSubscription} validate={validate}>
      {formRenderProps => children(formRenderProps)}
    </Form>
  );
}
