import { FORM_ERROR, FormApi, SubmissionErrors } from 'final-form';
import { IntlShape } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { alertError, alertSuccess } from '../actions/notificationActions';
import { AjaxError, AjaxResponse } from '../services/ajaxRequest';
import { translateApiError } from '../services/translateApiError';
import { Actions, JSONApi, Models, State } from '../types';

export const generateEmptyInvite: () => Models.InviteFormData = () => ({
  id: uuidv4(),
  inviteeEmail: ''
});

export const generateEmptyTraitOption: () => Models.TraitOptionFormData = () => ({
  id: uuidv4(),
  title: ''
});

export const generateEmptyTrait: () => Models.TraitFormData = () => ({
  id: uuidv4(),
  required: false,
  title: '',
  traitOptions: [generateEmptyTraitOption()]
});

// the annoying timeout is needed because you can't use `form.reset` directly within a `handleSubmit`
// function, so you have to make it async
export const resetForm = <FormValues>(form: FormApi<FormValues>) => {
  setTimeout(() => {
    form.reset();
    form.getRegisteredFields().forEach(field => {
      form.resetFieldState(field as unknown as keyof FormValues);
    });
  }, 0);
};

type HandleSubmissionError = {
  dispatch: Actions.ThunkDispatch;
  error: AjaxError;
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  handleInvalid: (errors: JSONApi.Error[]) => SubmissionErrors | void;
  isMounted: React.MutableRefObject<boolean>;
};

export const handleSubmissionError = ({
  dispatch,
  error,
  handleInvalid,
  isMounted
}: HandleSubmissionError) => {
  // This is a little bit funky, but with login forms we get a 403 back and
  // it will have the same types of errors as if we had submitted a form with
  // invalid data. We also now have a form that generates a lookup, which can
  // return a 404 with form errors.
  if (error.isUnprocessableEntity() || error.isForbidden() || error.isNotFound()) {
    if (isMounted.current) {
      return handleInvalid(error.errors());
    }
  } else if (error.isServerError()) {
    dispatch(alertError('errors.serverError'));
  } else if (error.isNetworkError()) {
    dispatch(alertError('errors.networkError'));
  } else {
    throw error;
  }
};

type HandleFormSubmitFailureArgs<FormValues extends {}> = {
  contentForwardRef?: React.RefObject<HTMLIonContentElement>;
  dispatch: Actions.ThunkDispatch;
  error: AjaxError;
  errorTranslationLocation: string;
  intl: IntlShape;
  isMounted: React.MutableRefObject<boolean>;
  values: FormValues;
};

export const handleFormSubmitFailure = <FormValues extends {}>({
  contentForwardRef,
  dispatch,
  error,
  errorTranslationLocation,
  intl,
  isMounted,
  values
}: HandleFormSubmitFailureArgs<FormValues>) => {
  if (isMounted.current) {
    if (contentForwardRef?.current) {
      contentForwardRef.current.scrollToTop(2000);
    }
  }
  return handleSubmissionError({
    dispatch,
    error,
    handleInvalid: errors => {
      const submissionErrors: SubmissionErrors = {};
      errors.forEach(error => {
        if (error.meta) {
          const errorMessage = translateApiError(intl, errorTranslationLocation, error);
          const fieldName = error.meta.pointer as string & keyof FormValues;
          if (Object.keys(values).includes(fieldName)) {
            submissionErrors[fieldName] = errorMessage;
          } else {
            const baseErrors = [errorMessage];
            if (submissionErrors[FORM_ERROR]) {
              baseErrors.unshift(submissionErrors[FORM_ERROR]);
            }
            submissionErrors[FORM_ERROR] = baseErrors.join(', ');
          }
        }
      });
      return submissionErrors;
    },
    isMounted
  });
};

type HandleFormSubmissionArgs<FormValues, ResponseModel extends Models.Base> = {
  action: Actions.ApiThunkAction<ResponseModel>;
  contentForwardRef?: React.RefObject<HTMLIonContentElement>;
  dispatch: Actions.ThunkDispatch;
  errorTranslationLocation: string;
  form?: FormApi<FormValues>;
  intl: IntlShape;
  isMounted: React.MutableRefObject<boolean>;
  onSuccess?: (response: AjaxResponse<ResponseModel>, newState: State.Root) => void;
  successTKey?: string;
  values: FormValues;
};

export const handleFormSubmission = async <
  FormValues extends {},
  ResponseModel extends Models.Base
>({
  action,
  contentForwardRef,
  dispatch,
  errorTranslationLocation,
  form,
  intl,
  isMounted,
  onSuccess,
  successTKey,
  values
}: HandleFormSubmissionArgs<FormValues, ResponseModel>) =>
  dispatch(action)
    .then(response => {
      if (successTKey) {
        dispatch(alertSuccess(successTKey));
      }
      // forms that appear in a modal will typically close the modal within this onSuccess
      // handler. for that reason, the onSuccess handler should be executed last as this
      // type of action will unmount the form. Also, we need to wait until the response is
      // fully resolved to call it because it may be changing the url.
      response.dataLoadedIntoStatePromise.then(newState => {
        // only reset the form if the client supplies the form to this handler
        // note that `reset` cannot be called within the handleSubmit function
        // and waiting for data to be loaded into state makes sure it happens async
        if (isMounted.current && form) {
          resetForm<FormValues>(form);
        }
        if (onSuccess) {
          // assume that the handler will check mounting on its own
          onSuccess(response, newState);
        }
      });
    })
    .catch((error: AjaxError) =>
      handleFormSubmitFailure({
        contentForwardRef,
        dispatch,
        error,
        errorTranslationLocation,
        intl,
        isMounted,
        values
      })
    );
