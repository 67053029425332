import { Keyboard } from '@capacitor/keyboard';
import { isPlatform } from '@ionic/react';
import { useCallback, useEffect, useRef } from 'react';

// This returns a callback that the client can call to kick off the scroll. But... the content object
// that the caller is inside of might not be set up properly when it decides it wants the scroll to start.
// So we need to hold onto the desire to scroll while it changes.
const useScrollToBottom = (contentRef?: React.RefObject<HTMLIonContentElement>) => {
  const shouldScroll = useRef<boolean>(false);

  const maybeStartScroll = useCallback(() => {
    if (contentRef?.current?.scrollToBottom && shouldScroll.current) {
      contentRef.current.scrollToBottom(1000).then(() => {
        shouldScroll.current = false;
      });
    }
  }, [contentRef]);

  const scrollToBottom = useCallback(() => {
    shouldScroll.current = true;
    maybeStartScroll();
  }, [maybeStartScroll]);

  // When the contentRef changes we might need to kick off a scroll
  useEffect(() => {
    maybeStartScroll();
    // eslint-disable-next-line
  }, [contentRef]);

  useEffect(() => {
    if (isPlatform('capacitor')) {
      const listener = Keyboard.addListener('keyboardDidShow', () => {
        setTimeout(() => {
          shouldScroll.current = true;
        }, 100);
      });
      return () => {
        listener.remove();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return scrollToBottom;
};

export default useScrollToBottom;
