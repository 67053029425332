import React from 'react';
import { useIntl } from 'react-intl';

import AvatarWithDefault from '../components/AvatarWithDefault';
import { maybeSlot } from '../services/componentUtils';
import { JSONApi } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  slot?: string;
};

const GroupAvatar = ({ group, slot }: Props) => {
  const intl = useIntl();

  const alt = intl.formatMessage(
    { id: 'models.group.attributes.navIcon.alt' },
    { name: group.attributes.name }
  );

  return (
    <AvatarWithDefault
      alt={alt}
      id={group.attributes.slug}
      src={group.attributes.navIconUrl ?? undefined}
      {...maybeSlot(slot)}
    />
  );
};

export default GroupAvatar;
