import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useParams } from 'react-router';

import { apiClearCachedResponsesForEndpoint } from '../../actions/apiActions';
import { alertWarning } from '../../actions/notificationActions';
import CheckForEmailConfirmationPage from '../../components/CheckForEmailConfirmationPage';
import ConfirmEmailPage from '../../components/ConfirmEmailPage';
import LocalLoadingIndicator from '../../components/LocalLoadingIndicator';
import RejectedGroupPage from '../../components/RejectedGroupPage';
import RequireCurrentUser from '../../components/RequireCurrentUser';
import GroupRoutes from '../../components/routing/GroupRoutes';
import SetupPasswordPage from '../../components/SetupPasswordPage';
import useActionCableChannel from '../../hooks/useActionCableChannel';
import useCurrentGroup from '../../hooks/useCurrentGroup';
import useCurrentMember from '../../hooks/useCurrentMember';
import useCurrentUser from '../../hooks/useCurrentUser';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { loadGroup, updateUser } from '../../thunks/apiThunks';
import { setGroupSlug } from '../../thunks/sessionThunks';
import { ApiEndpoint, ModelAttributes, SessionState, State } from '../../types';

const GroupRouter = () => {
  const currentGroup = useCurrentGroup();
  const currentMember = useCurrentMember();
  const currentUser = useCurrentUser();
  const dispatch = useThunkDispatch();
  const { groupSlug } = useParams<{ groupSlug: string }>();
  const groupState = useSelector((root: State.Root) => root.session.currentGroup.state);
  const userState = useSelector((root: State.Root) => root.session.currentUser.state);
  // guard against redirection if the effect that loads the slug hasn't had a chance to perform
  const [hasntTriedToLoadYet, setHasntTriedToLoadYet] = useState(true);

  const slugMismatch = !!currentGroup && currentGroup.attributes.slug !== groupSlug;

  useEffect(() => {
    if (
      currentUser &&
      currentUser.attributes.lastVisitedGroupSlug !== groupSlug &&
      currentUser.attributes.userComplete
    ) {
      dispatch(updateUser({ lastVisitedGroupSlug: groupSlug }, currentUser.id));
    }
  }, [currentUser, dispatch, groupSlug]);

  useEffect(() => {
    // only dispatch the action to set a slug if it differs from the existing currentGroup state
    if ((!currentGroup && groupSlug) || slugMismatch) {
      dispatch(setGroupSlug(groupSlug));
    }
    setHasntTriedToLoadYet(false);
  }, [currentGroup, dispatch, groupSlug, slugMismatch]);

  useActionCableChannel({
    channelName: { channel: 'GroupChannel', id: currentGroup?.attributes?.slug },
    onReceived: () => {
      if (currentGroup) {
        dispatch(apiClearCachedResponsesForEndpoint(ApiEndpoint.LOAD_GROUP));
        dispatch(loadGroup(currentGroup.attributes.slug));
      }
    }
  });

  // protect against a moment in time when changing groups when the current group and desired group
  // don't match
  if (hasntTriedToLoadYet || groupState === SessionState.LOADING || slugMismatch) {
    return null;
  }

  if (groupState === SessionState.NONE) {
    dispatch(alertWarning('errors.group.requiredForPage'));
    return <Redirect to="/" />;
  }

  if (
    groupState === SessionState.FAILED ||
    !currentGroup ||
    (currentGroup.attributes.status === ModelAttributes.GroupStatus.PENDING &&
      currentMember?.attributes.role !== ModelAttributes.MemberRole.OWNER)
  ) {
    dispatch(alertWarning('models.group.notFound'));
    return <Redirect to="/search" />;
  }

  if (currentGroup.attributes.status === ModelAttributes.GroupStatus.REJECTED) {
    return <RejectedGroupPage group={currentGroup} />;
  }

  if (userState === SessionState.LOADING || currentMember === undefined) {
    return <LocalLoadingIndicator />;
  }

  return (
    <Switch>
      <Route exact path="/g/:slug/register/check-for-email-confirmation">
        {() => <CheckForEmailConfirmationPage group={currentGroup} />}
      </Route>
      {/* this route is deprecated. prefer the register/confirm-email route */}
      <Redirect from="/g/:slug/confirm-email" to="/g/:slug/register/confirm-email" />
      <Route path="/g/:slug/register/confirm-email">
        {() => <ConfirmEmailPage group={currentGroup} />}
      </Route>
      <Route path="/g/:slug/register/setup-password">
        {() => (
          <RequireCurrentUser group={currentGroup}>
            {user => <SetupPasswordPage currentUser={user} group={currentGroup} />}
          </RequireCurrentUser>
        )}
      </Route>
      <Route path="/g/:slug">{() => <GroupRoutes currentGroup={currentGroup} />}</Route>
    </Switch>
  );
};

export default GroupRouter;
