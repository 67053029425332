import * as Sentry from '@sentry/browser';
import { DateTime } from 'luxon';
import ReactGA from 'react-ga';

import {
  apiClearCachedResponsesContainingResource,
  apiDeleteResource,
  apiError,
  apiSuccess
} from '../actions/apiActions';
import { alertError } from '../actions/notificationActions';
import { forceArray } from '../services/arrayUtils';
import { appendToFormData, convertTimestamps, GenericJSON } from '../services/jsonApiUtils';
import { responseCodeMap, toUrlParams } from '../services/urlUtils';
import { logout } from '../thunks/sessionThunks';
import { Actions, ApiEndpoint, HTML, JSONApi, Models, State } from '../types';

export type AfterRequestCallbackArgs = {
  dispatch: Actions.ThunkDispatch;
  getState: () => State.Root;
};

export interface AfterRequestSuccessCallbackArgs<T extends Models.Base>
  extends AfterRequestCallbackArgs {
  responseBody: JSONApi.Response<T>;
}

export interface AfterRequestErrorCallbackArgs extends AfterRequestCallbackArgs {
  error: AjaxError;
}

export type AfterRequestSuccessCallback<T extends Models.Base> = (
  args: AfterRequestSuccessCallbackArgs<T>
) => void;

export type AfterRequestErrorCallback = (args: AfterRequestErrorCallbackArgs) => void;

export type BaseRequestArgs<ModelType extends Models.Base, IncludedType = Models.Base> = {
  data?:
    | JSONApi.CreateData<Partial<ModelType>, Partial<IncludedType>>
    | JSONApi.CreateDataWithoutAttributes<Partial<IncludedType>>
    | JSONApi.CreateDataBulk<Partial<ModelType>, Partial<IncludedType>>
    | JSONApi.UpdateData<Partial<ModelType>, Partial<IncludedType>>
    | JSONApi.UpdateDataWithoutAttributes<Partial<IncludedType>>
    | JSONApi.DeleteData
    | GenericJSON;
  endpoint: ApiEndpoint;
  method: 'DELETE' | 'GET' | 'PATCH' | 'POST';
  params?: HTML.UrlParams | string;
  pathname: string;
  useFormData?: boolean;
};

export interface AjaxRequestArgs<ModelType extends Models.Base, IncludedType = Models.Base>
  extends BaseRequestArgs<ModelType, IncludedType> {
  authToken?: string;
  dispatch: Actions.ThunkDispatch;
  getState: () => State.Root;
}

export interface DispatchableRequestArgs<ModelType extends Models.Base, IncludedType = Models.Base>
  extends BaseRequestArgs<ModelType, IncludedType> {
  afterError?: AfterRequestErrorCallback;
  afterSuccess?: AfterRequestSuccessCallback<ModelType>;
  eventCategory: string;
  eventName: string;
}

export interface AjaxResponse<T extends Models.Base> extends JSONApi.Response<T> {
  dataLoadedIntoStatePromise: Promise<State.Root>;
}

/* eslint-disable no-restricted-syntax */
export class AjaxError extends Error {
  public constructor(
    public response: Response | undefined,
    public responseBody: GenericJSON | undefined,
    public url: URL,
    public requestArgs: RequestInit
  ) {
    super(`Ajax Error ${response?.status?.toString() ?? 'Network Error'} ${url.toString()}`);
  }

  public errors() {
    return (this.responseBody?.errors ?? []) as JSONApi.Error[];
  }

  public isForbidden() {
    return this.response && this.response.status === 403;
  }

  public isNetworkError() {
    return !this.response;
  }

  public isNotFound() {
    return this.response && this.response.status === 404;
  }

  public isServerError() {
    return this.response && this.response.status >= 500;
  }

  public isUnprocessableEntity() {
    return this.response && this.response.status === 422;
  }
}
/* eslint-enable no-restricted-syntax */

/* eslint-disable complexity */
export async function ajaxRequest<
  ModelType extends Models.Base,
  IncludedType extends Models.Base = Models.Base
>(ajaxRequestArgs: AjaxRequestArgs<ModelType, IncludedType>) {
  const { authToken, data, dispatch, endpoint, getState, method, params, pathname, useFormData } =
    ajaxRequestArgs;

  if (import.meta.env.MODE === 'test') {
    throw new Error(`Cannot make AJAX requests under test ${method} ${pathname}`);
  }

  const headers: HeadersInit = {
    Accept: 'application/json'
  };

  if (!useFormData) {
    headers['Content-Type'] = 'application/json';
  }

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  const ajaxArgs: RequestInit = {
    headers,
    method
  };

  if (useFormData) {
    const formData = new FormData();
    appendToFormData(data as GenericJSON, formData);
    ajaxArgs.body = formData;
  } else if (data) {
    ajaxArgs.body = JSON.stringify(data);
  }

  const url = new URL(import.meta.env.VITE_API_HOST ?? '');
  url.pathname = pathname;

  if (params) {
    url.search = typeof params === 'string' ? params : toUrlParams(params);
  }

  let cacheKey: string | undefined;

  if (method === 'GET') {
    cacheKey = JSON.stringify({ ajaxArgs, url: url.toString() });
    const cachedResponse = getState().api.cache[cacheKey];
    const twentyMinutesAgo = DateTime.utc().minus({ minutes: 20 });
    if (cachedResponse && cachedResponse.timestamp > twentyMinutesAgo) {
      const response = cachedResponse.response as AjaxResponse<ModelType>;
      const currentState = getState();
      const dataLoadedIntoStatePromise = Promise.resolve(currentState);
      const extendedResponse = { ...response, dataLoadedIntoStatePromise };
      return extendedResponse;
    }
  }

  try {
    const response = await fetch(url.toString(), ajaxArgs);

    if (response.status === responseCodeMap.unauthorized) {
      dispatch(logout());
      dispatch(alertError('errors.session.expired'));
      dispatch(apiError('expiredSession', endpoint));
    }

    // If the response is not ok, throw an error right away. If the body does not contain
    // valid JSON, create a blank JSON document and send that. This handles the case where
    // there is a timeout or other error where our server cannot send a valid JSON document.
    if (!response.ok) {
      let rawJSON: GenericJSON;
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        rawJSON = await response.json();
      } catch (error) {
        Sentry.captureException(error);
        rawJSON = {};
      }

      const responseBody = convertTimestamps(rawJSON);

      dispatch(apiError('apiError', endpoint));
      // Provide the errors to the catch resolution of this function's promise.
      const error = new AjaxError(response, responseBody, url, ajaxArgs);
      if (response.status >= 500) {
        Sentry.captureException(error);
      }
      throw error;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const rawJSON: GenericJSON = await response.json();
    const responseBody = convertTimestamps(rawJSON);

    // When creating something, remove anything from the api cache that contains any of its related
    // resources. When updating something, remove anything from the cache that contains the object
    // itself.
    if (data) {
      if (method === 'POST') {
        const response = responseBody as unknown as JSONApi.Response<ModelType>;
        forceArray(response.data).forEach(resource => {
          if (resource.relationships) {
            Object.keys(resource.relationships).forEach(name => {
              forceArray(resource.relationships[name].data).forEach(related => {
                dispatch(apiClearCachedResponsesContainingResource(related.id, related.type));
              });
            });
          }
        });
      } else if (method === 'PATCH') {
        const updateData = data as JSONApi.UpdateData<ModelType, IncludedType>;
        dispatch(
          apiClearCachedResponsesContainingResource(updateData.data.id, updateData.data.type)
        );
      } else if (method === 'DELETE') {
        const response = responseBody as unknown as JSONApi.DeleteResponse;
        dispatch(apiClearCachedResponsesContainingResource(response.meta.id, response.meta.type));
        dispatch(apiDeleteResource(response.meta.id, response.meta.type));
      }
    }

    const typed = responseBody as unknown as AjaxResponse<ModelType>;

    // We want the api success action to be dispatched asynchronously, but we may need to take action
    // after it is dispatched, so return a secondary promise that handlers can use for that purpose.
    typed.dataLoadedIntoStatePromise = new Promise(resolve => {
      setTimeout(() => {
        dispatch(apiSuccess(typed, endpoint, cacheKey, response.status));
        resolve(getState());
      }, 10);
    });

    return typed;
  } catch (error) {
    if (error instanceof AjaxError) {
      throw error;
    } else {
      throw new AjaxError(undefined, undefined, url, ajaxArgs);
    }
  }
}
/* eslint-enable complexity */

export function dispatchableRequest<
  ModelType extends Models.Base,
  IncludedType extends Models.Base = Models.Base
>({
  afterError,
  afterSuccess,
  eventCategory,
  eventName,
  ...remainingArgs
}: DispatchableRequestArgs<ModelType, IncludedType>) {
  return async (dispatch: Actions.ThunkDispatch, getState: () => State.Root) => {
    const ajaxRequestArgs: AjaxRequestArgs<ModelType, IncludedType> = {
      dispatch,
      getState,
      ...remainingArgs
    };
    const authToken = getState().session.authToken;
    if (authToken) {
      ajaxRequestArgs.authToken = authToken;
    }

    const promise = ajaxRequest<ModelType, IncludedType>(ajaxRequestArgs);

    promise
      .then(responseBody => {
        if (afterSuccess) {
          afterSuccess({ dispatch, getState, responseBody });
        }
        ReactGA.event({ action: eventName, category: eventCategory });
      })
      .catch((error: AjaxError) => {
        if (afterError) {
          afterError({ dispatch, error, getState });
        }
        ReactGA.event({ action: `${eventName} Failure`, category: eventCategory });
      });

    return promise;
  };
}
