import { IonButton, IonButtons, IonItem, IonProgressBar } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import { WizardSteps } from '../components/CreateGroupPage';
import GroupPlanForm from '../components/forms/GroupPlanForm';
import Heading from '../components/Heading';
import Paragraph from '../components/Paragraph';
import { JSONApi } from '../types';

type Props = {
  group: JSONApi.GroupResource;
};

const SetupGroupPayment = ({ group }: Props) => {
  const history = useHistory();

  const handleBackClick = useCallback(() => {
    history.push({ pathname: `/create/${group.attributes.slug}/${WizardSteps.SETUP_ACCOUNT}` });
  }, [group.attributes.slug, history]);

  const handleNextClick = useCallback(() => {
    history.push(`/create/${group.attributes.slug}/${WizardSteps.SHARE}`);
  }, [group.attributes.slug, history]);

  return (
    <div className="setup-group-payment-form">
      <IonProgressBar buffer={0.8} color="success" value={0} />
      <Heading level={2}>
        <FormattedMessage id="pages.groupWizard.payment.title" />
      </Heading>
      <Paragraph>
        <FormattedMessage id="pages.groupWizard.payment.description" />
      </Paragraph>
      <GroupPlanForm
        group={group}
        paymentCancelRedirect={`/create/${group.attributes.slug}/${WizardSteps.PAYMENT}?cancel=true`}
        paymentSuccessRedirect={`/create/${group.attributes.slug}/${WizardSteps.SHARE}?success=true`}
      />
      <IonItem color="transparent" lines="none">
        <IonButtons slot="end">
          <IonButton color="primary" fill="outline" onClick={handleBackClick} size="default">
            <FormattedMessage id="dictionary.previous" />
          </IonButton>
          <IonButton
            color="primary"
            disabled={!group.attributes.planActive}
            fill="solid"
            onClick={handleNextClick}
            size="default"
          >
            <FormattedMessage id="dictionary.next" />
          </IonButton>
        </IonButtons>
      </IonItem>
    </div>
  );
};

export default SetupGroupPayment;
