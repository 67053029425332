import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonText
} from '@ionic/react';
import deepEqual from 'deep-equal';
import { briefcase, ellipsisHorizontal, pin } from 'ionicons/icons';
import React, { createRef, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Heading from '../components/Heading';
import Page from '../components/layout/Page';
import PageHeader from '../components/layout/PageHeader';
import PageTitle from '../components/layout/PageTitle';
import LinksList from '../components/LinksList';
import MemberActions from '../components/MemberActions';
import MemberAvatar from '../components/MemberAvatar';
import Paragraph from '../components/Paragraph';
import PostFeed from '../components/PostFeed';
import SearchChip from '../components/SearchChip';
import SearchResults from '../components/SearchResults';
import TraitList from '../components/TraitList';
import useOpener from '../hooks/useOpener';
import { getOwnerLinks, getTrait, getTraitOption, getTraitOptionSelections } from '../selectors';
import { locationSearchUrl } from '../services/urlUtils';
import { loadMorePostSearchResults, loadPostSearch } from '../thunks/apiThunks';
import { ApiEndpoint, JSONApi, Models, Search, State } from '../types';

import './MemberProfilePage.scss';
import './SettingsList.scss';

type TraitData = {
  options: JSONApi.TraitOptionResource[];
  trait: JSONApi.TraitResource;
};

type Props = {
  group: JSONApi.GroupResource;
  member: JSONApi.MemberResource;
};

const MemberProfilePage = ({ group, member }: Props) => {
  const { bio, createdAt, fullName, headline, industry, location } = member.attributes;

  const actionSheetOpener = useOpener();
  const apiData = useSelector((root: State.Root) => root.api);
  const contentRef = createRef<HTMLIonContentElement>();
  const intl = useIntl();
  const [traitData, setTraitData] = useState<TraitData[]>([]);

  const [searchParams, setSearchParams] = useState<Search.PostParams>({
    companyName: null,
    eventType: null,
    industry: null,
    jobType: null,
    lat: null,
    lng: null,
    location: null,
    memberSlug: member.attributes.slug,
    query: null,
    radius: null,
    upcoming: null
  });

  // this prevents someone else's posts from appearing if you switch between member pages
  useEffect(() => {
    const newParams = { ...searchParams, memberSlug: member.attributes.slug };
    if (!deepEqual(newParams, searchParams)) {
      setSearchParams(newParams);
    }
  }, [member.attributes.slug, searchParams]);

  const links = getOwnerLinks(apiData, member);
  const traitOptionSelections = getTraitOptionSelections(apiData, member);

  useEffect(() => {
    let newTraitData: TraitData[] = [];
    traitOptionSelections.forEach(selection => {
      const trait = getTrait(apiData, selection);
      const option = getTraitOption(apiData, selection);
      let entry = newTraitData.find(item => item.trait.id === trait.id);
      if (!entry) {
        entry = { options: [], trait };
        newTraitData.push(entry);
      }
      entry.options.push(option);
    });
    newTraitData.forEach(item => {
      // eslint-disable-next-line no-confusing-arrow
      item.options = item.options.sort((a, b) =>
        a.attributes.title > b.attributes.title ? 1 : -1
      );
    });
    // eslint-disable-next-line no-confusing-arrow
    newTraitData = newTraitData.sort((a, b) =>
      a.trait.attributes.title > b.trait.attributes.title ? 1 : -1
    );
    if (!deepEqual(traitData, newTraitData)) {
      setTraitData(newTraitData);
    }
  }, [apiData, traitData, traitOptionSelections]);

  const actions = (
    <IonButton
      aria-label={intl.formatMessage({ id: 'dictionary.options' })}
      className="member-actions"
      onClick={actionSheetOpener.toggle}
    >
      <IonIcon icon={ellipsisHorizontal} />
    </IonButton>
  );

  const locationLink = locationSearchUrl(member.attributes, 'members', group);

  const industryLink =
    industry && `/g/${group.attributes.slug}/members?industry=${encodeURIComponent(industry)}`;

  return (
    <Page className="centered member-profile-page wide">
      <PageHeader buttonsRight={actions} defaultBackHref={`/g/${group.attributes.slug}/members`} />
      <PageTitle group={group} value={member.attributes.fullName} />
      <IonContent className="canvas" fullscreen ref={contentRef} scrollEvents>
        <MemberActions actionSheetOpener={actionSheetOpener} group={group} member={member} />
        <div className="subheader color-secondary">
          <div className="member-avatar">
            <MemberAvatar member={member} slot="start" />
            <div>
              <Heading level={1}>
                <FormattedMessage id="pages.memberProfile.title" values={{ name: fullName }} />
              </Heading>
              <div>
                <IonLabel color="primary">{headline}</IonLabel>
              </div>
              {bio && <Paragraph>{bio}</Paragraph>}
            </div>
          </div>
        </div>
        <IonGrid>
          <IonRow>
            <IonCol size="12" sizeLg="5">
              <IonLabel className="settings-list-label" color="primary">
                <FormattedMessage
                  id="models.member.info"
                  values={{ name: member.attributes.firstName }}
                />
              </IonLabel>
              <IonList className="ion-no-padding settings-list">
                <IonItem lines={location || industry ? 'inset' : 'none'}>
                  <IonLabel>
                    <FormattedMessage id="models.member.attributes.createdAt.label" />
                  </IonLabel>
                  <IonText color="primary" slot="end">
                    {createdAt.toLocaleString({ month: 'long', year: 'numeric' })}
                  </IonText>
                </IonItem>
                {location && (
                  <IonItem lines={industry ? 'inset' : 'none'}>
                    <IonLabel>
                      <FormattedMessage id="dictionary.location" />
                    </IonLabel>
                    <SearchChip icon={pin} label={location} link={locationLink} />
                  </IonItem>
                )}
                {industry && (
                  <IonItem lines="none">
                    <IonLabel>
                      <FormattedMessage id="dictionary.industry" />
                    </IonLabel>
                    <SearchChip icon={briefcase} label={industry} link={industryLink as string} />
                  </IonItem>
                )}
              </IonList>
              {traitData.length > 0 &&
                traitData.map(traitDatum => (
                  <TraitList
                    group={group}
                    key={traitDatum.trait.id}
                    options={traitDatum.options}
                    trait={traitDatum.trait}
                  />
                ))}
              <LinksList
                links={links}
                tKey="models.member.relationships.links.title"
                tValues={{ name: member.attributes.firstName }}
              />
            </IonCol>
            <IonCol size="12" sizeLg="7">
              <SearchResults<Models.PostSearch, Models.Post, Search.PostParams>
                contentRef={contentRef}
                group={group}
                loadDataActionCreator={loadPostSearch}
                loadMoreActionCreator={loadMorePostSearchResults}
                searchEndpoint={ApiEndpoint.POST_SEARCH}
                searchParams={searchParams}
                showCount={false}
              >
                {results => (
                  <>
                    <IonLabel className="settings-list-label">
                      <FormattedMessage
                        id="models.member.relationships.posts.title"
                        values={{ name: member.attributes.firstName }}
                      />
                    </IonLabel>
                    {results.length === 0 && (
                      <div className="post-feed">
                        <IonCard className="feed-item">
                          <IonCardContent>
                            <Paragraph>
                              <FormattedMessage
                                id="models.member.relationships.posts.none"
                                values={{ name: member.attributes.firstName }}
                              />
                            </Paragraph>
                          </IonCardContent>
                        </IonCard>
                      </div>
                    )}
                    <PostFeed group={group} posts={results} />
                  </>
                )}
              </SearchResults>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </Page>
  );
};

export default MemberProfilePage;
