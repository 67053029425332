import { IonButton, IonButtons, IonItem, IonLabel } from '@ionic/react';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import AddAttachment from '../../components/forms/AddAttachment';
import AttachmentFieldItems from '../../components/forms/AttachmentFieldItems';
import { AttachmentsFieldManager } from '../../hooks/useAttachmentsFieldManager';

type Props = {
  attachmentsFieldManager: AttachmentsFieldManager;
  labelKey: string;
  onChange?: () => void;
  showLabel?: boolean;
};

const AddAttachmentItem = ({
  attachmentsFieldManager,
  labelKey,
  onChange,
  showLabel = false
}: Props) => {
  const intl = useIntl();

  useEffect(() => {
    if (onChange) {
      onChange();
    }
  }, [attachmentsFieldManager.attachments, onChange]);

  return (
    <>
      <IonItem color="transparent" lines="none">
        {showLabel && (
          <IonLabel>
            <FormattedMessage id={labelKey} />
          </IonLabel>
        )}
        <IonButtons slot="end">
          <IonButton
            aria-label={intl.formatMessage({
              id: labelKey
            })}
            color="primary"
            fill="clear"
            size="small"
          >
            <AddAttachment attachmentsFieldManager={attachmentsFieldManager} />
          </IonButton>
        </IonButtons>
      </IonItem>
      <AttachmentFieldItems attachmentsFieldManager={attachmentsFieldManager} />
    </>
  );
};

export default AddAttachmentItem;
