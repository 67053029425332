import {
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  IonSegment,
  IonSegmentButton,
  SegmentChangeEventDetail
} from '@ionic/react';
import equal from 'deep-equal';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import DiscussionCard from '../components/DiscussionCard';
import EventCard from '../components/EventCard';
import JobPostCard from '../components/JobPostCard';
import Page from '../components/layout/Page';
import PageHeader from '../components/layout/PageHeader';
import LocalLoadingIndicator from '../components/LocalLoadingIndicator';
import MemberCard from '../components/MemberCard';
import NewsItemCard from '../components/NewsItemCard';
import useApiData from '../hooks/useApiData';
import { getSavedResource, getSaves } from '../selectors';
import { loadSaves } from '../thunks/apiThunks';
import { JSONApi, Models, State } from '../types';

type Props = {
  group: JSONApi.GroupResource;
};

type TypeCounts = {
  members: number;
  posts: number;
};

type TypeFilter = 'members' | 'posts';

const SavesPage = ({ group }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const [typeFilter, setTypeFilter] = useState<TypeFilter>('members');
  const [filteredSaves, setFilteredSaves] = useState<JSONApi.SaveResource[] | undefined>(undefined);

  const defaultTypeCounts = {
    members: 0,
    posts: 0
  };

  const [typeCounts, setTypeCounts] = useState<TypeCounts>(defaultTypeCounts);

  const findSaves = useCallback(() => getSaves(apiData, group), [apiData, group]);

  const { loading, responseData: saves } = useApiData<Models.Save>(
    loadSaves(group.attributes.slug),
    findSaves,
    'SavesPage'
  );

  useEffect(() => {
    const newCounts: TypeCounts = { members: 0, posts: 0 };
    saves.forEach(save => {
      const type = getSavedResource(apiData, save).type;
      if (type === 'member') {
        newCounts.members += 1;
      } else {
        newCounts.posts += 1;
      }
    });
    if (!equal(newCounts, typeCounts)) {
      setTypeCounts(newCounts);
    }
  }, [apiData, saves, typeCounts]);

  useEffect(() => {
    if (saves) {
      if (typeFilter === 'members') {
        setFilteredSaves(saves.filter(item => getSavedResource(apiData, item).type === 'member'));
      } else {
        setFilteredSaves(saves.filter(item => getSavedResource(apiData, item).type !== 'member'));
      }
    }
  }, [apiData, saves, typeFilter]);

  const renderSave = useCallback(
    (save: JSONApi.SaveResource) => {
      const saved = getSavedResource(apiData, save);
      if (saved.type === 'discussion') {
        const discussion = saved as JSONApi.DiscussionResource;
        return <DiscussionCard discussion={discussion} group={group} />;
      }
      if (saved.type === 'event') {
        const event = saved as JSONApi.EventResource;
        return <EventCard event={event} group={group} />;
      }
      if (saved.type === 'jobPost') {
        const jobPost = saved as JSONApi.JobPostResource;
        return <JobPostCard group={group} jobPost={jobPost} />;
      }
      if (saved.type === 'member') {
        const member = saved as JSONApi.MemberResource;
        return <MemberCard group={group} member={member} />;
      }
      if (saved.type === 'newsItem') {
        const newsItem = saved as JSONApi.NewsItemResource;
        return <NewsItemCard group={group} newsItem={newsItem} />;
      }
      return <div>{saved.type}</div>;
    },
    [apiData, group]
  );

  const handleSegmentChange = useCallback((event: CustomEvent<SegmentChangeEventDetail>) => {
    const changeDetail = event.detail;
    if (changeDetail) {
      const newTypeFilter = changeDetail.value as TypeFilter;
      setTypeFilter(newTypeFilter);
    }
  }, []);

  if (loading) {
    return <LocalLoadingIndicator />;
  }

  return (
    <Page className="centered wide">
      <PageHeader defaultBackHref={`/g/${group.attributes.slug}`} titleKey="pages.saves.title" />
      <IonContent className="canvas" scrollEvents>
        {saves.length === 0 ? (
          <div className="ion-padding-end ion-padding-start">
            <h2>
              <FormattedMessage id="pages.saves.noSaves" />
            </h2>
          </div>
        ) : (
          <>
            <div className="ion-padding-bottom ion-padding-top subheader">
              <IonSegment
                mode="ios"
                onIonChange={handleSegmentChange}
                scrollable
                value={typeFilter}
              >
                <IonSegmentButton value="members">
                  <FormattedMessage
                    id="pages.saves.membersFilter"
                    values={{ count: typeCounts.members }}
                  />
                </IonSegmentButton>
                <IonSegmentButton value="posts">
                  <FormattedMessage
                    id="pages.saves.postsFilter"
                    values={{ count: typeCounts.posts }}
                  />
                </IonSegmentButton>
              </IonSegment>
            </div>
            {filteredSaves && filteredSaves.length > 0 ? (
              <IonGrid className="cards">
                <IonRow className="cards-inner">
                  {filteredSaves.map(save => (
                    <IonCol className="card-wrapper" key={save.id} size="12" sizeLg="6" sizeXl="4">
                      {renderSave(save)}
                    </IonCol>
                  ))}
                </IonRow>
              </IonGrid>
            ) : (
              <div className="ion-padding-end ion-padding-start">
                <h2>
                  <FormattedMessage id={`pages.saves.${typeFilter}NoSaves`} />
                </h2>
              </div>
            )}
          </>
        )}
      </IonContent>
    </Page>
  );
};

export default SavesPage;
