import { ActionSheetButton, IonActionSheet } from '@ionic/react';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import BlockFormModal from '../components/BlockFormModal';
import useOpener, { Opener } from '../hooks/useOpener';
import { JSONApi } from '../types';

type Props = {
  actionSheetOpener: Opener;
  conversation: JSONApi.ConversationResource;
  group: JSONApi.GroupResource;
};

const ConversationActions = ({ actionSheetOpener, conversation, group }: Props) => {
  const blockModalOpener = useOpener();
  const intl = useIntl();

  const handleDidDismiss = useCallback(() => {
    actionSheetOpener.close();
    actionSheetOpener.handleDidDismiss();
  }, [actionSheetOpener]);

  const handleClickBlock = useCallback(() => {
    actionSheetOpener.close();
    blockModalOpener.open();
  }, [actionSheetOpener, blockModalOpener]);

  const actionSheetButtons: ActionSheetButton[] = [];

  if (conversation.attributes.permissions.block) {
    actionSheetButtons.push({
      handler: handleClickBlock,
      text: intl.formatMessage({ id: 'modals.newBlock.title.content' })
    });
  }

  actionSheetButtons.push({
    handler: actionSheetOpener.close,
    role: 'cancel',
    text: intl.formatMessage({ id: 'dictionary.close' })
  });

  if (!conversation.attributes.permissions.block) {
    return null;
  }

  return (
    <>
      <IonActionSheet
        buttons={actionSheetButtons}
        isOpen={actionSheetOpener.isOpen}
        onDidDismiss={handleDidDismiss}
        onDidPresent={actionSheetOpener.handleDidPresent}
      />
      <BlockFormModal group={group} opener={blockModalOpener} record={conversation} />
    </>
  );
};

export default ConversationActions;
