import { IonCard, IonCardContent, IonText } from '@ionic/react';
import React from 'react';

import Heading from '../components/Heading';
import MemberAvatar from '../components/MemberAvatar';
import { JSONApi } from '../types';

import './GriddedCard.scss';

type Props = {
  group: JSONApi.GroupResource;
  member: JSONApi.MemberResource;
};

const MemberCard = ({ group, member }: Props) => (
  <IonCard
    button
    className="ion-activatable gridded-card member-card"
    role="button"
    routerLink={`/g/${group.attributes.slug}/members/${member.attributes.slug}`}
  >
    <IonCardContent>
      <MemberAvatar member={member} slot="start" />
      <div className="details">
        <Heading level={2}>{member.attributes.fullName}</Heading>
        {member.attributes.location && (
          <IonText color="primary">{member.attributes.location}</IonText>
        )}
        {member.attributes.headline && (
          <IonText color="medium">{member.attributes.headline}</IonText>
        )}
      </div>
    </IonCardContent>
  </IonCard>
);

export default MemberCard;
