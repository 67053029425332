import { IonButton, IonButtons, IonIcon, IonItem } from '@ionic/react';
import { checkmarkCircle } from 'ionicons/icons';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Heading from '../../components/Heading';
import Paragraph from '../../components/Paragraph';
import { WizardSteps } from '../../components/VerificationWizard';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { getGroupRules } from '../../selectors';
import { AjaxError } from '../../services/ajaxRequest';
import { handleFormSubmitFailure } from '../../services/formUtils';
import { updateMember } from '../../thunks/apiThunks';
import { JSONApi, State } from '../../types';

type Props = {
  group: JSONApi.GroupResource;
  member: JSONApi.MemberResource;
  setWizardStep: (step: WizardSteps) => void;
};

const VerificationRulesForm = ({ group, member, setWizardStep }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const dispatch = useThunkDispatch();
  const isMounted = useMountedTracking();
  const intl = useIntl();

  const rules = getGroupRules(apiData, group);

  // move forward automatically if there are no rules to accept
  useEffect(() => {
    if (rules.length === 0) {
      setWizardStep(WizardSteps.EDIT_PROFILE);
    }
  }, [rules.length, setWizardStep]);

  const handleBackClick = useCallback(() => {
    setWizardStep(WizardSteps.QUESTION);
  }, [setWizardStep]);

  const handleAcceptClick = useCallback(() => {
    dispatch(updateMember(group.attributes.slug, { acceptedRulesAt: DateTime.local() }, member.id))
      .then(response => {
        response.dataLoadedIntoStatePromise.then(() => {
          if (isMounted.current) {
            setWizardStep(WizardSteps.EDIT_PROFILE);
          }
        });
      })
      .catch((error: AjaxError) => {
        handleFormSubmitFailure({
          dispatch,
          error,
          errorTranslationLocation: 'login',
          intl,
          isMounted,
          values: {}
        });
      });
  }, [dispatch, group.attributes.slug, intl, isMounted, member, setWizardStep]);

  return (
    <div className="verification-rules-form">
      <Heading level={2}>
        <FormattedMessage id="pages.verificationRules.h2" />
      </Heading>
      <Paragraph>
        <FormattedMessage
          id="pages.verificationRules.p"
          values={{ group: group.attributes.name }}
        />
      </Paragraph>
      {rules.map(rule => (
        <IonItem key={rule.id}>
          <IonIcon color="secondary" icon={checkmarkCircle} slot="start" />
          <Paragraph>{rule.attributes.rule}</Paragraph>
        </IonItem>
      ))}
      <IonItem color="transparent" lines="none">
        <IonButtons slot="end">
          {group.attributes.memberVerificationRequired &&
          group.attributes.memberVerificationQuestion.length > 0 ? (
            <IonButton color="primary" fill="outline" onClick={handleBackClick} size="default">
              <FormattedMessage id="dictionary.previous" />
            </IonButton>
          ) : (
            <IonButton
              color="primary"
              fill="outline"
              routerDirection="back"
              routerLink={`/g/${group.attributes.slug}/register/setup-account`}
              size="default"
            >
              <FormattedMessage id="dictionary.previous" />
            </IonButton>
          )}
          <IonButton color="primary" fill="solid" onClick={handleAcceptClick} size="default">
            <FormattedMessage id="dictionary.accept" />
          </IonButton>
        </IonButtons>
      </IonItem>
    </div>
  );
};

export default VerificationRulesForm;
