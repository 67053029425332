export enum BroadcastUpdateAction {
  CREATE = 'create',
  DELETE = 'delete',
  UPDATE = 'update'
}

export enum CommentStatus {
  APPROVED = 'approved',
  DELETED = 'deleted',
  REJECTED = 'rejected'
}

export enum ConversationType {
  CANDIDATE_INQUIRY = 'candidateInquiry',
  INTERNSHIP_RESUME_DROP = 'internshipResumeDrop',
  JOB_APPLICATION = 'jobApplication',
  MESSAGE = 'message',
  WELCOME = 'welcome'
}

export type EventType =
  | 'Appearance or Signing'
  | 'Attraction'
  | 'Camp, Trip, or Retreat'
  | 'Career/Job Fair'
  | 'Class, Training, or Workshop'
  | 'Concert or Performance'
  | 'Conference'
  | 'Convention'
  | 'Dinner or Gala'
  | 'Festival or Fair'
  | 'Game or Competition'
  | 'Meeting or Networking Event'
  | 'Other'
  | 'Party or Social Gathering'
  | 'Race or Endurance Event'
  | 'Rally'
  | 'Screening'
  | 'Seminar or Talk'
  | 'Tour'
  | 'Tournament'
  | 'Tradeshow, Consumer Show, or Expo'
  | 'Virtual Event'
  | 'Volunteer'
  | 'Webinar';

export type FlagCategory =
  | 'Nudity'
  | 'Harassment'
  | 'Hate Speech'
  | 'Violence'
  | 'Terrorism'
  | 'Spam'
  | 'Other';

export enum FlagStatus {
  ACTED_UPON = 'actedUpon',
  DISMISSED = 'dismissed',
  PENDING = 'pending'
}

export enum GroupStatus {
  APPROVED = 'approved',
  PENDING = 'pending',
  REJECTED = 'rejected'
}

export type Industry =
  | 'Accounting'
  | 'Airlines/Aviation'
  | 'Alternative Dispute Resolution'
  | 'Alternative Medicine'
  | 'Animation'
  | 'Apparel & Fashion'
  | 'Architecture & Planning'
  | 'Arts & Crafts'
  | 'Automotive'
  | 'Aviation & Aerospace'
  | 'Banking'
  | 'Biotechnology'
  | 'Broadcast Media'
  | 'Building Materials'
  | 'Business Supplies & Equipment'
  | 'Capital Markets'
  | 'Chemicals'
  | 'Civic & Social Organization'
  | 'Civil Engineering'
  | 'Commercial Real Estate'
  | 'Computer & Network Security'
  | 'Computer Games'
  | 'Computer Hardware'
  | 'Computer Networking'
  | 'Computer Software'
  | 'Construction'
  | 'Consumer Electronics'
  | 'Consumer Goods'
  | 'Consumer Services'
  | 'Cosmetics'
  | 'Dairy'
  | 'Defense & Space'
  | 'Design'
  | 'E-learning'
  | 'Education Management'
  | 'Electrical & Electronic Manufacturing'
  | 'Entertainment'
  | 'Environmental Services'
  | 'Events Services'
  | 'Executive Office'
  | 'Facilities Services'
  | 'Farming'
  | 'Financial Services'
  | 'Fine Art'
  | 'Fishery'
  | 'Food & Beverages'
  | 'Food Production'
  | 'Fundraising'
  | 'Furniture'
  | 'Gambling & Casinos'
  | 'Glass, Ceramics & Concrete'
  | 'Government Administration'
  | 'Government Relations'
  | 'Graphic Design'
  | 'Health, Wellness & Fitness'
  | 'Higher Education'
  | 'Hospital & Health Care'
  | 'Hospitality'
  | 'Human Resources'
  | 'Import & Export'
  | 'Individual & Family Services'
  | 'Industrial Automation'
  | 'Information Services'
  | 'Information Technology & Services'
  | 'Insurance'
  | 'International Affairs'
  | 'International Trade & Development'
  | 'Internet'
  | 'Investment Banking'
  | 'Investment Management'
  | 'Judiciary'
  | 'Law Enforcement'
  | 'Law Practice'
  | 'Legal Services'
  | 'Legislative Office'
  | 'Leisure, Travel & Tourism'
  | 'Libraries'
  | 'Logistics & Supply Chain'
  | 'Luxury Goods & Jewelry'
  | 'Machinery'
  | 'Management Consulting'
  | 'Maritime'
  | 'Market Research'
  | 'Marketing & Advertising'
  | 'Mechanical Or Industrial Engineering'
  | 'Media Production'
  | 'Medical Device'
  | 'Medical Practice'
  | 'Mental Health Care'
  | 'Military'
  | 'Mining & Metals'
  | 'Motion Pictures & Film'
  | 'Museums & Institutions'
  | 'Music'
  | 'Nanotechnology'
  | 'Newspapers'
  | 'Non-profit Organization Management'
  | 'Oil & Energy'
  | 'Online Media'
  | 'Outsourcing/Offshoring'
  | 'Package/Freight Delivery'
  | 'Packaging & Containers'
  | 'Paper & Forest Products'
  | 'Performing Arts'
  | 'Pharmaceuticals'
  | 'Philanthropy'
  | 'Photography'
  | 'Plastics'
  | 'Political Organization'
  | 'Primary/Secondary Education'
  | 'Printing'
  | 'Professional Training & Coaching'
  | 'Program Development'
  | 'Public Policy'
  | 'Public Relations & Communications'
  | 'Public Safety'
  | 'Publishing'
  | 'Railroad Manufacture'
  | 'Ranching'
  | 'Real Estate'
  | 'Recreational Facilities & Services'
  | 'Religious Institutions'
  | 'Renewables & Environment'
  | 'Research'
  | 'Restaurants'
  | 'Retail'
  | 'Security & Investigations'
  | 'Semiconductors'
  | 'Shipbuilding'
  | 'Social Work'
  | 'Sporting Goods'
  | 'Sports'
  | 'Staffing & Recruiting'
  | 'Student'
  | 'Supermarkets'
  | 'Telecommunications'
  | 'Textiles'
  | 'Think Tanks'
  | 'Tobacco'
  | 'Translation & Localization'
  | 'Transportation/Trucking/Railroad'
  | 'Utilities'
  | 'Venture Capital & Private Equity'
  | 'Veterinary'
  | 'Warehousing'
  | 'Wholesale'
  | 'Wine & Spirits'
  | 'Wireless'
  | 'Writing & Editing';

export enum InviteStatus {
  ACCEPTED = 'accepted',
  SENT = 'sent',
  REVOKED = 'revoked'
}

export type JobType = 'Full-time' | 'Part-time' | 'Internship';

export enum MemberRole {
  MEMBER = 'member',
  MODERATOR = 'moderator',
  OWNER = 'owner'
}

export enum MemberStatus {
  ADMINISTRATOR_REJECTED = 'administratorRejected',
  APPROVED = 'approved',
  AWAITING_MODERATION = 'awaitingModeration',
  DELETED = 'deleted',
  INCOMPLETE = 'incomplete',
  MODERATOR_REJECTED = 'moderatorRejected',
  SELF_CLOSED = 'selfClosed'
}

export enum PostStatus {
  APPROVED = 'approved',
  DELETED = 'deleted',
  PENDING = 'pending',
  PUBLISHED = 'published',
  REJECTED = 'rejected'
}

export enum PostType {
  DISCUSSION = 'discussion',
  EVENT = 'event',
  JOB_POST = 'jobPost',
  NEWS_ITEM = 'newsItem'
}

export enum ReactionContentType {
  COMMENT = 'Comment',
  POST = 'Post'
}

export type SubscriptionFrequency = 'immediate' | 'daily' | 'weekly' | 'monthly' | 'never';

export enum PlanPeriod {
  MONTHLY = 'monthly',
  YEARLY = 'yearly'
}

export enum PlanStatus {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  CREATED_BEFORE_PAYMENT = 'created_before_payment',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  PAST_DUE = 'past_due',
  TRIALING = 'trialing',
  UNPAID = 'unpaid'
}

export enum ReactionEmotion {
  CONFUSED = 'Confused',
  DISLIKE = 'Dislike',
  HAHA = 'Haha',
  LIKE = 'Like'
}
