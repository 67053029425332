import { ToggleChangeEventDetail } from '@ionic/react';
import React, { useCallback, useState } from 'react';

import { alertError, alertSuccess } from '../../actions/notificationActions';
import FormTip from '../../components/forms/FormTip';
import ToggleItem from '../../components/forms/ToggleItem';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { forceArray } from '../../services/arrayUtils';
import { updateGroup } from '../../thunks/apiThunks';
import { JSONApi, Models } from '../../types';

type Props = {
  group: JSONApi.GroupResource;
};

/*
 * managing state here gets a little crazy in order to prevent the toggle from flickering
 * on re-renders immediately after you click it. the checked status must be managed in local
 * state, and the order of events is:
 *   - clicking the toggle immediately animates it and throws the change event
 *   - it appears that multiple change events are fired after a click, so guard against change
 *     events that don't actually change the attribute value
 *   - you must immediately update the checked state, otherwise the component will likely
 *     re-render before completing the dispatch, causing the toggle to flip back
 *   - after completion of the dispatch, confirm the toggle state based on the data received,
 *     or change it back to the attribute value on failure
 */
const MembersCanSendInvitesToggle = ({ group }: Props) => {
  const dispatch = useThunkDispatch();
  const isMounted = useMountedTracking();
  const [checked, setChecked] = useState(group.attributes.membersCanSendInvites);

  const handleIonChange = useCallback(
    (event: CustomEvent<ToggleChangeEventDetail>) => {
      const target = event.target as HTMLIonToggleElement & typeof event.target;
      if (target) {
        const membersCanSendInvites = target.checked;
        setChecked(membersCanSendInvites);
        if (membersCanSendInvites !== group.attributes.membersCanSendInvites) {
          dispatch(updateGroup({ membersCanSendInvites }, group.id))
            .then((response: JSONApi.Response<Models.Group>) => {
              dispatch(alertSuccess('forms.group.update.success'));
              if (isMounted.current) {
                setChecked(forceArray(response.data)[0].attributes.membersCanSendInvites);
              }
            })
            .catch(() => {
              dispatch(alertError('forms.group.update.error'));
              setChecked(group.attributes.membersCanSendInvites);
            });
        }
      }
    },
    [dispatch, group.attributes.membersCanSendInvites, group.id, isMounted]
  );

  return (
    <>
      <ToggleItem
        checked={checked}
        id="models.group.attributes.membersCanSendInvites.label"
        name="membersCanSendInvites"
        onIonChange={handleIonChange}
      />
      <FormTip id="models.group.attributes.membersCanSendInvites.explanation" />
    </>
  );
};

export default MembersCanSendInvitesToggle;
