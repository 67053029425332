import { Browser } from '@capacitor/browser';
import React, { useCallback } from 'react';

import OnClickLink from '../components/OnClickLink';

const ExternalLink = ({
  children,
  href,
  ...onClickLinkProps
}: Omit<React.ComponentPropsWithoutRef<typeof OnClickLink>, 'onClick' | 'rel' | 'target'> & {
  href: string;
}) => {
  const handleClick = useCallback(() => {
    Browser.open({ url: href });
  }, [href]);

  return (
    <OnClickLink
      {...onClickLinkProps}
      onClick={handleClick}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
    </OnClickLink>
  );
};

export default ExternalLink;
