import { IonButton, IonButtons, IonItem, IonLabel, IonNote, IonSelectOption } from '@ionic/react';
import equal from 'deep-equal';
import { FormApi } from 'final-form';
import React, { useCallback } from 'react';
import { Field, FieldInputProps, FormSpy } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { apiClearCachedResponsesForEndpoint } from '../../actions/apiActions';
import DeletePostModal from '../../components/DeletePostModal';
import AddAttachmentItem from '../../components/forms/AddAttachmentItem';
import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormInput from '../../components/forms/FormInput';
import FormMentionsTextarea from '../../components/forms/FormMentionsTextarea';
import FormSearchableSelect from '../../components/forms/FormSearchableSelect';
import FormSelect from '../../components/forms/FormSelect';
import FormSubmit from '../../components/forms/FormSubmit';
import RequiredFieldMarker from '../../components/forms/RequiredFieldMarker';
import { SearchableSelectOptionData } from '../../components/forms/SearchableSelectModal';
import SubmitError from '../../components/forms/SubmitError';
import { maxTextInputLength, maxTitleLength, stringArrayDelimiter } from '../../globals';
import useAttachmentsFieldManager from '../../hooks/useAttachmentsFieldManager';
import useDismissModalAndGoTo from '../../hooks/useDismissModalAndGoTo';
import useMountedTracking from '../../hooks/useMountedTracking';
import useOpener, { Opener } from '../../hooks/useOpener';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { getJobPostAttachments } from '../../selectors';
import { forceArray } from '../../services/arrayUtils';
import { handleFormSubmission } from '../../services/formUtils';
import { placeQueryHandler } from '../../services/placeSearch';
import { generateTextQueryHandler } from '../../services/textSearch';
import { createJobPost, updateJobPost } from '../../thunks/apiThunks';
import { ApiEndpoint, JSONApi, ModelAttributes, Models, State } from '../../types';

import './CustomForm.scss';

export type JobPostFormValues = Models.JobPostFormData;

type Props = {
  autofocus: boolean;
  contentForwardRef?: React.RefObject<HTMLIonContentElement>;
  group: JSONApi.GroupResource;
  jobPost?: JSONApi.JobPostResource;
  onSuccess?: () => void;
  opener: Opener;
};

const JobPostForm = ({
  autofocus,
  contentForwardRef,
  group,
  jobPost,
  onSuccess,
  opener
}: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const deleteModalOpener = useOpener();
  const dismissModalAndGoTo = useDismissModalAndGoTo(opener);
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const seeAttachedJobDescription = intl.formatMessage({
    id: 'models.jobPost.seeAttachedJobDescription'
  });

  const industries = intl
    .formatMessage({ id: 'models.jobPost.attributes.industry.options' })
    .split(stringArrayDelimiter) as ModelAttributes.Industry[];
  const industryOptions: SearchableSelectOptionData[] = industries.map(industry => ({
    id: industry,
    label: industry,
    value: industry
  }));
  industryOptions.unshift({
    id: 'n/a',
    label: intl.formatMessage({ id: 'dictionary.notApplicable' }),
    value: ''
  });

  const initialAttachments = (jobPost && getJobPostAttachments(apiData, jobPost)) || [];
  const attachmentsFieldManager = useAttachmentsFieldManager(initialAttachments);

  const jobTypes = intl
    .formatMessage({ id: 'models.jobPost.attributes.jobType.options' })
    .split(stringArrayDelimiter) as ModelAttributes.JobType[];

  const initialValues: JobPostFormValues = {
    companyName: jobPost ? jobPost.attributes.companyName : '',
    companyUrl: jobPost ? jobPost.attributes.companyUrl : '',
    description: jobPost ? jobPost.attributes.description : '',
    industry: jobPost ? jobPost.attributes.industry : null,
    jobType: jobPost ? jobPost.attributes.jobType : undefined,
    location: jobPost ? jobPost.attributes.location : '',
    title: jobPost ? jobPost.attributes.title : ''
  };

  const generateHandleAttachmentsChange = useCallback(
    (
      form: FormApi<Models.JobPostFormData, Partial<Models.JobPostFormData>>,
      input: FieldInputProps<Models.JobPostFormData>
    ) =>
      () => {
        const currentDescription = input.value as unknown as string;
        if (
          (!currentDescription || currentDescription.length === 0) &&
          attachmentsFieldManager.attachments.length > 0
        ) {
          form.change('description', seeAttachedJobDescription);
        }
        if (
          attachmentsFieldManager.attachments.length === 0 &&
          currentDescription === seeAttachedJobDescription
        ) {
          form.change('description', '');
        }
      },
    [attachmentsFieldManager.attachments.length, seeAttachedJobDescription]
  );

  const handleSubmit = useCallback(
    async (values: JobPostFormValues, form: FormApi<JobPostFormValues>) => {
      const action = jobPost
        ? updateJobPost(
            group.attributes.slug,
            { ...jobPost, ...values, slug: jobPost.attributes.slug },
            attachmentsFieldManager.attachments
          )
        : createJobPost(group.attributes.slug, values, attachmentsFieldManager.attachments);
      return handleFormSubmission({
        action,
        contentForwardRef,
        dispatch,
        errorTranslationLocation: 'jobPost',
        form,
        intl,
        isMounted,
        onSuccess: (response: JSONApi.Response<Models.JobPost>) => {
          dispatch(apiClearCachedResponsesForEndpoint(ApiEndpoint.JOB_POST_SEARCH));
          const newJobPost = forceArray(response.data)[0];
          if (onSuccess) {
            onSuccess();
          }
          dismissModalAndGoTo(
            `/g/${group.attributes.slug}/job-board/${newJobPost.attributes.slug}`
          );
        },
        successTKey: `forms.jobPost.${jobPost ? 'update' : 'create'}.success`,
        values
      });
    },
    [
      attachmentsFieldManager.attachments,
      contentForwardRef,
      dismissModalAndGoTo,
      dispatch,
      group.attributes.slug,
      intl,
      isMounted,
      jobPost,
      onSuccess
    ]
  );

  const validationSchema = Yup.object().shape({
    companyName: Yup.string()
      .required(intl.formatMessage({ id: 'errors.jobPost.companyName.blank' }))
      .max(
        maxTextInputLength,
        intl.formatMessage(
          { id: 'errors.jobPost.companyName.tooLong' },
          { count: maxTextInputLength }
        )
      ),
    companyUrl: Yup.string()
      .nullable()
      .max(
        maxTextInputLength,
        intl.formatMessage(
          { id: 'errors.jobPost.companyUrl.tooLong' },
          { count: maxTextInputLength }
        )
      ),
    description: Yup.string()
      .required(intl.formatMessage({ id: 'errors.jobPost.description.blank' }))
      .max(
        maxTextInputLength,
        intl.formatMessage(
          { id: 'errors.jobPost.description.tooLong' },
          { count: maxTextInputLength }
        )
      ),
    location: Yup.string()
      .nullable()
      .max(
        maxTextInputLength,
        intl.formatMessage(
          { id: 'errors.jobPost.companyName.tooLong' },
          { count: maxTextInputLength }
        )
      ),
    title: Yup.string()
      .required(intl.formatMessage({ id: 'errors.jobPost.title.blank' }))
      .max(
        maxTitleLength,
        intl.formatMessage({ id: 'errors.jobPost.title.tooLong' }, { count: maxTitleLength })
      )
  });

  return (
    <FormContainer<JobPostFormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ form, handleSubmit }) => (
        <div className="custom-form-wrapper">
          <Form<JobPostFormValues>
            className="job-post-form"
            onFormSubmit={handleSubmit}
            submit={form.submit}
          >
            <SubmitError />
            <IonLabel>
              <FormattedMessage id="models.jobPost.attributes.title.label" />
              <RequiredFieldMarker />
            </IonLabel>
            <FormError name="title" />
            <FormInput
              autocapitalize="on"
              autocorrect="on"
              autofocus={autofocus}
              name="title"
              placeholder={intl.formatMessage({
                id: 'models.jobPost.attributes.title.placeholder'
              })}
            />
            <IonLabel>
              <FormattedMessage id="models.jobPost.attributes.companyName.label" />
              <RequiredFieldMarker />
            </IonLabel>
            <FormError name="companyName" />
            <FormInput
              autocapitalize="on"
              autocorrect="on"
              name="companyName"
              placeholder={intl.formatMessage({
                id: 'models.jobPost.attributes.companyName.placeholder'
              })}
            />
            <IonLabel>
              <FormattedMessage id="models.jobPost.attributes.companyUrl.label" />
            </IonLabel>
            <FormError name="companyUrl" />
            <FormInput
              autocapitalize="off"
              autocorrect="on"
              name="companyUrl"
              placeholder={intl.formatMessage({
                id: 'models.jobPost.attributes.companyUrl.placeholder'
              })}
            />
            <IonLabel>
              <FormattedMessage id="models.jobPost.attributes.jobType.label" />
            </IonLabel>
            <FormError name="jobType" />
            <FormSelect
              name="jobType"
              placeholder={intl.formatMessage({
                id: 'models.jobPost.attributes.jobType.placeholder'
              })}
            >
              {jobTypes.map(jobType => (
                <IonSelectOption key={jobType} value={jobType}>
                  {jobType}
                </IonSelectOption>
              ))}
            </FormSelect>
            <IonLabel>
              <FormattedMessage id="models.jobPost.attributes.industry.label" />
            </IonLabel>
            <FormError name="industry" />
            <div className="searchable-select-wrapper-outer">
              <div className="searchable-select-wrapper-inner">
                <FormSearchableSelect
                  defaultOptions={industryOptions}
                  name="industry"
                  placeholder={intl.formatMessage({
                    id: 'models.jobPost.attributes.industry.placeholder'
                  })}
                  queryHandler={generateTextQueryHandler(industryOptions)}
                  titleTKey="models.jobPost.attributes.industry.label"
                  type="text"
                />
              </div>
            </div>
            <IonLabel>
              <FormattedMessage id="models.jobPost.attributes.location.label" />
            </IonLabel>
            <div>
              <IonNote color="medium">
                <FormattedMessage id="models.jobPost.attributes.location.note" />
              </IonNote>
            </div>
            <FormError name="location" />
            <div className="searchable-select-wrapper-outer">
              <div className="searchable-select-wrapper-inner">
                <FormSearchableSelect
                  attributeNameTKey="models.jobPost.attributes.location.label"
                  name="location"
                  placeholder={intl.formatMessage({
                    id: 'models.jobPost.attributes.location.placeholder'
                  })}
                  queryHandler={placeQueryHandler}
                  titleTKey="models.jobPost.attributes.location.label"
                  type="text"
                />
              </div>
            </div>
            <IonLabel>
              <FormattedMessage id="models.jobPost.attributes.description.label" />
              <RequiredFieldMarker />
            </IonLabel>
            <div>
              <IonNote color="medium">
                <FormattedMessage id="models.jobPost.attributes.description.note" />
              </IonNote>
            </div>
            <FormError name="description" />
            <FormMentionsTextarea
              name="description"
              placeholder={intl.formatMessage({
                id: 'models.jobPost.attributes.description.placeholder'
              })}
              placement="top"
              rows={6}
            />
            <Field<JobPostFormValues> name="description" subscription={{ value: true }}>
              {({ input }) => (
                <AddAttachmentItem
                  attachmentsFieldManager={attachmentsFieldManager}
                  labelKey="models.jobPost.relationships.attachments.add"
                  onChange={generateHandleAttachmentsChange(form, input)}
                />
              )}
            </Field>
            <IonItem color="transparent" lines="none">
              <IonButtons slot="end">
                <FormSpy subscription={{ pristine: true }}>
                  {({ pristine }) => {
                    const isPristine =
                      pristine && equal(attachmentsFieldManager.attachments, initialAttachments);
                    return (
                      <FormSubmit
                        allowPristineSubmit
                        disabled={attachmentsFieldManager.isUploading || isPristine}
                        fill="solid"
                      >
                        <FormattedMessage id={jobPost ? 'dictionary.update' : 'dictionary.post'} />
                      </FormSubmit>
                    );
                  }}
                </FormSpy>
                {jobPost && (
                  <>
                    <IonButton color="danger" fill="clear" onClick={deleteModalOpener.toggle}>
                      <FormattedMessage id="dictionary.delete" />
                    </IonButton>
                    <DeletePostModal group={group} opener={deleteModalOpener} post={jobPost} />
                  </>
                )}
              </IonButtons>
            </IonItem>
          </Form>
        </div>
      )}
    </FormContainer>
  );
};

export default JobPostForm;
