const modals = {
  addLink: {
    title: 'Add a link'
  },
  addRule: {
    title: 'Add a group rule'
  },
  addTrait: {
    title: 'Add a category'
  },
  addTraitOption: {
    title: 'Add an option'
  },
  billingHistory: {
    title: 'Billing history'
  },
  createInvites: {
    h2: 'Invite friends to {group}',
    title: 'Send Invites'
  },
  deleteAccount: {
    explanation: 'Clicking the button below is permanent. You cannot undo this action.',
    h2: 'Are you sure?',
    title: 'Permanently Delete Your Account'
  },
  deleteComment: {
    explanation:
      `Are you sure you want to delete this comment? If you delete it, you won't be able ` +
      `to see or access it again.`
  },
  deletePost: {
    explanation:
      `Are you sure you want to delete this post? If you delete it, the post and all of its ` +
      `comments will be lost forever.`
  },
  editDiscussion: {
    title: 'Edit Discussion'
  },
  editEvent: {
    title: 'Edit Event'
  },
  editJobPost: {
    title: 'Edit Job Post'
  },
  editNewsItem: {
    title: 'Edit News Item'
  },
  leaveGroup: {
    delete: {
      explanation: {
        bullet1: 'Content within your member profile is permanently deleted from our servers.',
        bullet2: 'Your comment and post content is permanently deleted from our servers.',
        bullet3: "Private messages that you have sent to another user remain in that user's inbox.",
        bullet4:
          'Deleted content is subject to backup retention periods as described in the terms of service.',
        header: 'Permanently closing your account entails:'
      }
    },
    exposeLink: "What's the difference?",
    freeze: {
      explanation: {
        bullet1: 'Other members cannot see your profile.',
        bullet2: 'Your posts and comments will be hidden.',
        bullet3: 'Moderators cannot see your profile.',
        bullet4: 'Your data remains intact on our servers.',
        header: 'Freezing your account entails:'
      }
    },
    h2: 'Delete or freeze your membership to {group}',
    title: 'Leave Group'
  },
  newBlock: {
    button: {
      content: 'Hide',
      member: 'Block'
    },
    content: "If you hide this content you won't see it again. Are you sure you want to hide it?",
    member:
      `Blocking {name} will prevent them from seeing your profile or sending you ` +
      `messages. You also will no longer see their profile. Are you sure you want to ` +
      `block {name}?`,
    title: {
      content: 'Hide this Content',
      member: 'Block this Member'
    }
  },
  newDiscussion: {
    title: 'Start a new discussion'
  },
  newEvent: {
    title: 'Post an event'
  },
  newFlag: {
    heading:
      'Please select a problem with this content to flag it for group moderators to review. ' +
      'You can complete your report after selecting a problem.',
    title: 'Report this Content'
  },
  newJobApplication: {
    title: 'Apply to this job'
  },
  newJobPost: {
    title: 'Post a job'
  },
  newMessage: {
    title: 'Send a message to {name}'
  },
  newNewsItem: {
    title: 'Share the latest news'
  },
  planCancel: {
    cancel: 'Unsubscribe',
    title: 'Unsubscribe'
  },
  planChangePreview: {
    changePlan: 'Switch to this plan',
    on: 'on',
    selectPlan: 'Select plan',
    title: 'Plan Preview'
  },
  planReactivate: {
    reactivate: 'Reactivate',
    title: 'Reactivate your plan'
  },
  searchableSelect: {
    explanationEnd: 'and choose an option from the list of results.',
    explanationStart: 'Type to search for your'
  }
};

export default modals;
