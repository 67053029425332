/*
 * this component overwrites IonSelect with some default behavior we always need:
 * 1) set default values for certain props that make sense in most cases for selects
 * 2) replaces Ionic's default text values with our own internationalization-enabled ones
 * 3) imports some style overrides we need
 */

import { IonSelect } from '@ionic/react';
import React from 'react';
import { useIntl } from 'react-intl';

import './Select.scss';

const Select = (props: React.ComponentProps<typeof IonSelect>) => {
  const intl = useIntl();
  return (
    // eslint-disable-next-line react/forbid-elements
    <IonSelect
      cancelText={intl.formatMessage({ id: 'dictionary.cancel' })}
      color="primary"
      interface="popover"
      multiple={false}
      okText={intl.formatMessage({ id: 'dictionary.ok' })}
      {...props}
    />
  );
};

export default Select;
