import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { forceArray } from '../services/arrayUtils';
import { JSONApi, Models } from '../types';

type FormValuesWithTraitOptionSelections = {
  traitOptionSelections: Models.TraitOptionSelectionFormData[];
};

type RelationshipsType = {
  traitOptionSelections: JSONApi.HasManyRelationship;
};

export function convertApiTraitDataToFormSelections(
  groupTraits: JSONApi.TraitResource[],
  traitOptionSelections: JSONApi.TraitOptionSelectionResource[]
) {
  const formValues = groupTraits.map(trait => {
    const optionIds: string[] = [];
    const selections = traitOptionSelections.filter(selection => {
      if (selection.relationships.trait.data) {
        return forceArray(selection.relationships.trait.data)[0].id === trait.id;
      }
      return false;
    });

    selections.forEach(selection => {
      if (selection.relationships.traitOption.data) {
        optionIds.push(forceArray(selection.relationships.traitOption.data)[0].id);
      }
    });

    return {
      traitId: trait.id,
      traitOptionIds: optionIds
    };
  });
  return formValues;
}

export function convertFormSelectionsToApiTraitData(
  values: FormValuesWithTraitOptionSelections,
  traitOptionSelections: JSONApi.TraitOptionSelectionResource[]
) {
  const findExistingSelection = (traitOptionId: string) =>
    traitOptionSelections.find((selection: JSONApi.TraitOptionSelectionResource) => {
      if (selection.relationships.traitOption.data) {
        return forceArray(selection.relationships.traitOption.data)[0].id === traitOptionId;
      }
      return false;
    });

  const included: JSONApi.Resource<Models.UpdateTraitOptionSelection>[] = [];
  const relationships: RelationshipsType = { traitOptionSelections: { data: [] } };
  values.traitOptionSelections.forEach(traitOptionSelection => {
    traitOptionSelection.traitOptionIds.forEach(traitOptionId => {
      const existingSelection = findExistingSelection(traitOptionId);
      const selectionId = existingSelection ? existingSelection.id : uuidv4();
      if (relationships.traitOptionSelections.data) {
        relationships.traitOptionSelections.data.push({
          id: selectionId,
          type: 'trait_option_selection'
        });
      }
      const selection: JSONApi.Resource<Models.UpdateTraitOptionSelection> = {
        attributes: {
          id: selectionId,
          traitId: traitOptionSelection.traitId,
          traitOptionId
        },
        id: selectionId,
        relationships: {},
        type: 'trait_option_selection'
      };
      included.push(selection);
    });
  });
  return { included, relationships };
}

export function traitOptionSelectionsYupSchema(
  groupTraits: JSONApi.TraitResource[],
  errorMessage: string
) {
  return Yup.array().of(
    Yup.object().shape({
      traitId: Yup.string(),
      traitOptionIds: Yup.array()
        .of(Yup.string())
        .when('traitId', {
          is: (traitId: string) => {
            const trait = groupTraits.find(trait => trait.id === traitId);
            return trait?.attributes.required;
          },
          otherwise: schema => schema,
          then: schema =>
            schema.test('required', errorMessage, items => !!items && items.length > 0)
        })
    })
  );
}
