import React, { useCallback } from 'react';
import { Route, Switch } from 'react-router';

import { alertError } from '../../actions/notificationActions';
import CheckForPasswordRecoveryPage from '../../components/CheckForPasswordRecoveryPage';
import CreateGroupPage from '../../components/CreateGroupPage';
import EnterInviteCodePage from '../../components/EnterInviteCodePage';
import GroupSearchPage from '../../components/GroupSearchPage';
import LandingPage from '../../components/LandingPage';
import LoginPage from '../../components/LoginPage';
import RequireCurrentUser from '../../components/RequireCurrentUser';
import RequireNoCurrentUser from '../../components/RequireNoCurrentUser';
import ResetPasswordPage from '../../components/ResetPasswordPage';
import AdminRoutes from '../../components/routing/AdminRoutes';
import GuardAgainstIncompleteUser from '../../components/routing/GuardAgainstIncompleteUser';
import MeAndMyDataRoutes from '../../components/routing/MeAndMyDataRoutes';
import StartPage from '../../components/StartPage';
import UserAccountWizardPage from '../../components/UserAccountWizardPage';
import useThunkDispatch from '../../hooks/useThunkDispatch';

const NoGroupRoutes = () => {
  const dispatch = useThunkDispatch();

  const render404 = useCallback(() => {
    setTimeout(() => {
      dispatch(alertError('errors.pageNotFound'));
    }, 100);
    return <LandingPage />;
  }, [dispatch]);

  return (
    <GuardAgainstIncompleteUser>
      <Switch>
        <Route path="/join">
          <RequireNoCurrentUser>
            <UserAccountWizardPage />
          </RequireNoCurrentUser>
        </Route>
        <Route component={AdminRoutes} path="/admin" />
        <Route component={MeAndMyDataRoutes} path="/me-and-my-data" />
        <Route component={CheckForPasswordRecoveryPage} exact path="/check-for-password-recovery" />
        <Route exact path="/login">
          {() => (
            <RequireNoCurrentUser>
              <LoginPage />
            </RequireNoCurrentUser>
          )}
        </Route>
        <Route component={ResetPasswordPage} exact path="/reset-password/:token" />
        <Route component={CreateGroupPage} exact path="/create/:slug?/:step?" />
        <Route exact path="/enter-invite-code">
          {() => (
            <RequireCurrentUser>
              {user => <EnterInviteCodePage currentUser={user} />}
            </RequireCurrentUser>
          )}
        </Route>
        <Route component={GroupSearchPage} exact path="/search" />
        <Route exact path="/start">
          {() => (
            <RequireCurrentUser>{user => <StartPage currentUser={user} />}</RequireCurrentUser>
          )}
        </Route>
        <Route component={LandingPage} exact path="/" />
        <Route render={render404} />
      </Switch>
    </GuardAgainstIncompleteUser>
  );
};

export default NoGroupRoutes;
