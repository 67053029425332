import React from 'react';

import FormModal from '../components/FormModal';
import JobPostForm from '../components/forms/JobPostForm';
import { Opener } from '../hooks/useOpener';
import { JSONApi } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  jobPost?: JSONApi.JobPostResource;
  onSuccess?: () => void;
  opener: Opener;
  titleKey: string;
};

const JobPostFormModal = ({ group, jobPost, onSuccess, opener, titleKey }: Props) => (
  <FormModal keyboardClose={false} opener={opener} titleKey={titleKey}>
    {modalContentRef => (
      <JobPostForm
        autofocus={opener.isPresented}
        contentForwardRef={modalContentRef}
        group={group}
        jobPost={jobPost}
        onSuccess={onSuccess}
        opener={opener}
      />
    )}
  </FormModal>
);

export default JobPostFormModal;
