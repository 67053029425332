import { IonList, IonSegment, IonSegmentButton, SegmentChangeEventDetail } from '@ionic/react';
import { DateTime } from 'luxon';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import AdminManageGroup from '../components/AdminManageGroup';
import Heading from '../components/Heading';
import AdminPage from '../components/layout/AdminPage';
import NoGroupSearchResults from '../components/NoGroupSearchResults';
import SearchQuery from '../components/SearchQuery';
import { loadGroupSearch, loadMoreGroupSearchResults } from '../thunks/apiThunks';
import { ApiEndpoint, ModelAttributes, Models, Search } from '../types';

import './SettingsList.scss';

enum StatusFilters {
  ALL = 'all',
  APPROVED = 'approved',
  NEEDS_REVIEW = 'needsReview',
  REJECTED = 'rejected'
}

const AdminGroupsPage = () => {
  // annoyingly this really needs to be "memoized" in the state so the object doesn't change
  const [searchParams, setSearchParams] = useState<Search.GroupParams>({
    query: null,
    sort: Search.SortOptions.CREATED_DATE,
    status: Object.values(ModelAttributes.GroupStatus).join(',')
  });
  const [resultsUpdatedAt] = useState(DateTime.local());
  const [statusFilter, setStatusFilter] = useState<StatusFilters>(StatusFilters.ALL);

  const handleGroupFilterChange = useCallback(
    (event: CustomEvent<SegmentChangeEventDetail>) => {
      const changeDetail = event.detail;
      if (changeDetail) {
        const newStatusFilter = changeDetail.value as StatusFilters;
        setStatusFilter(newStatusFilter);
        switch (newStatusFilter) {
          case StatusFilters.ALL:
            setSearchParams({
              ...searchParams,
              status: Object.values(ModelAttributes.GroupStatus).join(',')
            });
            break;
          case StatusFilters.APPROVED:
            setSearchParams({
              ...searchParams,
              status: ModelAttributes.GroupStatus.APPROVED
            });
            break;
          case StatusFilters.NEEDS_REVIEW:
            setSearchParams({
              ...searchParams,
              status: ModelAttributes.GroupStatus.PENDING
            });
            break;
          case StatusFilters.REJECTED:
            setSearchParams({
              ...searchParams,
              status: ModelAttributes.GroupStatus.REJECTED
            });
            break;
          default:
            break;
        }
      }
    },
    [searchParams]
  );

  return (
    <AdminPage h2Key="pages.admin.groups.title">
      {contentRef => (
        <div className="ion-padding">
          <div className="custom-form-wrapper">
            <SearchQuery<Search.GroupParams>
              autofocus={false}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </div>
          <IonSegment onIonChange={handleGroupFilterChange} value={statusFilter}>
            <IonSegmentButton value={StatusFilters.ALL}>
              <FormattedMessage id="dictionary.all" />
            </IonSegmentButton>
            <IonSegmentButton value={StatusFilters.NEEDS_REVIEW}>
              <FormattedMessage id="dictionary.needsReview" />
            </IonSegmentButton>
            <IonSegmentButton value={StatusFilters.APPROVED}>
              <FormattedMessage id="dictionary.approved" />
            </IonSegmentButton>
            <IonSegmentButton value={StatusFilters.REJECTED}>
              <FormattedMessage id="dictionary.rejected" />
            </IonSegmentButton>
          </IonSegment>
          <NoGroupSearchResults<Models.GroupSearch, Models.Group, Search.GroupParams>
            contentRef={contentRef}
            loadDataActionCreator={loadGroupSearch}
            loadMoreActionCreator={loadMoreGroupSearchResults}
            resultsUpdatedAt={resultsUpdatedAt}
            searchEndpoint={ApiEndpoint.GROUP_SEARCH}
            searchParams={searchParams}
            showCount={false}
          >
            {groups => (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {groups.length > 0 ? (
                  <IonList className="ion-no-padding settings-list">
                    {groups.map((group, index) => (
                      <AdminManageGroup
                        group={group}
                        key={group.id}
                        lines={index === groups.length - 1 ? 'none' : 'full'}
                      />
                    ))}
                  </IonList>
                ) : (
                  <Heading level={2}>
                    <FormattedMessage id="models.search.noResults" />
                  </Heading>
                )}
              </>
            )}
          </NoGroupSearchResults>
        </div>
      )}
    </AdminPage>
  );
};

export default AdminGroupsPage;
