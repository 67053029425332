import { Action } from 'redux';

export enum LoadingAction {
  DECREMENT_GLOBAL = 'loading/DECREMENT_GLOBAL',
  INCREMENT_GLOBAL = 'loading/INCREMENT_GLOBAL'
}

export interface DecrementGlobalLoadingAction extends Action {
  payload: { name: string };
  type: LoadingAction.DECREMENT_GLOBAL;
}

export interface IncrementGlobalLoadingAction extends Action {
  payload: { name: string };
  type: LoadingAction.INCREMENT_GLOBAL;
}

export type LoadingActionTypes = DecrementGlobalLoadingAction | IncrementGlobalLoadingAction;

export const decrementGlobalLoading = (name: string) => ({
  payload: { name },
  type: LoadingAction.DECREMENT_GLOBAL
});

export const incrementGlobalLoading = (name: string) => ({
  payload: { name },
  type: LoadingAction.INCREMENT_GLOBAL
});
