import { IonIcon, IonItem, IonLabel, IonList, IonReorder, IonReorderGroup } from '@ionic/react';
import { checkmark, chevronForward, close, repeat } from 'ionicons/icons';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { alertError, alertSuccess } from '../../actions/notificationActions';
import ExternalLink from '../../components/ExternalLink';
import FormModal from '../../components/FormModal';
import LinkForm from '../../components/forms/LinkForm';
import OnClickLink from '../../components/OnClickLink';
import useOpener from '../../hooks/useOpener';
import useReorderer from '../../hooks/useReorderer';
import useStopEnterKeyPropagation from '../../hooks/useStopEnterKeyPropagation';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { getOwnerLinks } from '../../selectors';
import { AjaxResponse } from '../../services/ajaxRequest';
import { forceArray } from '../../services/arrayUtils';
import { dispatchAndAlert } from '../../services/dispatchUtils';
import { isGroup, isMember } from '../../services/typeDetection';
import { deleteLink, updateGroup, updateMember } from '../../thunks/apiThunks';
import { JSONApi, Models, State } from '../../types';

import '../SettingsList.scss';

type Props = {
  group: JSONApi.GroupResource;
  owner: JSONApi.GroupResource | JSONApi.MemberResource;
};

const LinksForm = ({ group, owner }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const modalOpener = useOpener();

  const ownerLinks = getOwnerLinks(apiData, owner);

  const onReorder = useCallback(
    (resources: JSONApi.LinkResource[]) => {
      const relationships = {
        links: { data: resources.map(({ id, type }) => ({ id, type })) }
      };
      const included = resources.map(({ attributes, id, type }) => ({
        attributes,
        id,
        relationships: {},
        type
      }));

      if (isGroup(owner)) {
        dispatchAndAlert(
          dispatch,
          updateGroup({}, owner.id, relationships, included),
          'forms.group.update.success',
          'forms.group.update.error'
        );
      }
      if (isMember(owner)) {
        dispatchAndAlert(
          dispatch,
          updateMember(group.attributes.slug, {}, owner.id, relationships, included),
          'forms.editMember.update.success',
          'forms.editMember.update.error'
        );
      }
    },
    [dispatch, group.attributes.slug, owner]
  );

  const reorderer = useReorderer({
    initialResources: ownerLinks,
    onReorder
  });

  const generateRemoveLinkClickHandler = useCallback(
    (link: JSONApi.LinkResource) => () => {
      dispatch(deleteLink(group.attributes.slug, link))
        .then(() => {
          dispatch(alertSuccess('forms.link.delete.success'));
          reorderer.setResources(reorderer.resources.filter(resource => resource.id !== link.id));
        })
        .catch(() => {
          dispatch(alertError('forms.link.delete.error'));
        });
    },
    [dispatch, group.attributes.slug, reorderer]
  );

  // prevent form submission when you're just adding links
  const handleAddLinkKeyDown = useStopEnterKeyPropagation<HTMLIonItemElement>();

  const handleLinkFormSuccess = useCallback(
    (response: AjaxResponse<Models.Link>) => {
      modalOpener.close();
      reorderer.setResources([...reorderer.resources, forceArray(response.data)[0]]);
    },
    [modalOpener, reorderer]
  );

  return (
    <div className="ion-padding-end ion-padding-start links-form">
      <IonItem className="compact" color="transparent" lines="none">
        <IonLabel className="ion-no-margin settings-list-label" color="primary">
          <FormattedMessage id={`models.${owner.type}.relationships.links.label`} />
        </IonLabel>
        {ownerLinks.length > 1 && (
          <OnClickLink
            aria-label={intl.formatMessage({ id: 'dictionary.reorder' })}
            className="settings-list-link"
            onClick={reorderer.handleToggleReorder}
            slot="end"
          >
            {reorderer.isReordering ? <IonIcon icon={checkmark} /> : <IonIcon icon={repeat} />}
          </OnClickLink>
        )}
      </IonItem>
      <IonList className="ion-no-padding settings-list">
        <IonReorderGroup
          disabled={!reorderer.isReordering}
          onIonItemReorder={reorderer.handleIonItemReorder}
        >
          {reorderer.resources.map(link => (
            <IonItem color="light" key={link.id}>
              <strong>
                <ExternalLink href={link.attributes.uri}>{link.attributes.label}</ExternalLink>
              </strong>
              {!reorderer.isReordering && (
                <OnClickLink
                  aria-label={intl.formatMessage({ id: 'dictionary.remove' })}
                  onClick={generateRemoveLinkClickHandler(link)}
                  slot="end"
                >
                  <IonIcon icon={close} />
                </OnClickLink>
              )}
              <IonReorder slot="end" />
            </IonItem>
          ))}
        </IonReorderGroup>
        <IonItem
          button
          color="light"
          detail={false}
          disabled={modalOpener.isOpen || modalOpener.isPresented}
          lines="none"
          onClick={modalOpener.open}
          onKeyDown={handleAddLinkKeyDown}
          role="button"
        >
          <FormattedMessage id={`models.${owner.type}.relationships.links.add`} />
          <IonIcon icon={chevronForward} slot="end" />
        </IonItem>
      </IonList>
      <FormModal keyboardClose={false} opener={modalOpener} titleKey="modals.addLink.title">
        {() => (
          <LinkForm
            autofocus={modalOpener.isPresented}
            group={group}
            onSuccess={handleLinkFormSuccess}
            owner={owner}
          />
        )}
      </FormModal>
    </div>
  );
};

export default LinksForm;
