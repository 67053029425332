import { apiClearCachedResponsesContainingResource } from '../actions/apiActions';
import { forceArray } from '../services/arrayUtils';
import { Actions, JSONApi, Models } from '../types';

export const clearRequestCacheOfResourceOnUpdate = (
  update: JSONApi.Response<Models.BroadcastUpdate> | undefined | null,
  dispatch: Actions.ThunkDispatch
) => {
  if (update) {
    const resource = forceArray(forceArray(update.data)[0].relationships.resource.data)[0];

    if (resource) {
      dispatch(apiClearCachedResponsesContainingResource(resource.id, resource.type));
    }

    const containingResource = forceArray(
      forceArray(update.data)[0].relationships.containingResource.data
    )[0];
    if (containingResource) {
      dispatch(
        apiClearCachedResponsesContainingResource(containingResource.id, containingResource.type)
      );
    }
  }
};
