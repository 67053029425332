/*
 * this component wraps Toggle with a very standardized item structure
 */

import { IonItem } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Toggle from '../../components/forms/Toggle';
import Paragraph from '../../components/Paragraph';

const ToggleItem = ({
  color = 'primary',
  id,
  lines = 'none',
  ...props
}: Pick<React.ComponentProps<typeof FormattedMessage>, 'id'> &
  Pick<React.ComponentProps<typeof IonItem>, 'lines'> &
  React.ComponentProps<typeof Toggle>) => (
  <IonItem className="compact" lines={lines}>
    <Toggle color={color} slot="start" {...props} />
    <Paragraph color={color}>
      <FormattedMessage id={id} />
    </Paragraph>
  </IonItem>
);

export default ToggleItem;
