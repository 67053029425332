import {
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs
} from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect, Route, Switch } from 'react-router';

import { alertError } from '../../actions/notificationActions';
import GroupDesktopMenu from '../../components/GroupDesktopMenu';
import TabDataProvider, { TabData } from '../../components/routing/TabDataProvider';
import TabBadge from '../../components/TabBadge';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { JSONApi } from '../../types';

import './GroupTabsContainer.scss';

type Props = {
  currentGroup: JSONApi.GroupResource;
  currentUser: JSONApi.UserResource;
};

const GroupTabsContainer = ({ currentGroup, currentUser }: Props) => {
  const numTabs = 4;

  const dispatch = useThunkDispatch();

  // eslint-disable-next-line react/display-name
  const generateRedirectToTab = useCallback((path: string) => () => <Redirect to={path} />, []);

  const renderTabButtons = useCallback(
    (tabs: TabData[]) =>
      tabs.map(({ icon, id, path, tKey, unreadCount }, i) => {
        let className = '';
        if (i >= numTabs) {
          className = 'desktop-only';
        }
        return (
          <IonTabButton className={className} href={path} key={id} layout="label-hide" tab={id}>
            <IonIcon className={icon} />
            <IonLabel>
              <FormattedMessage id={tKey} />
            </IonLabel>
            <TabBadge color="danger" count={unreadCount} />
          </IonTabButton>
        );
      }),
    []
  );

  const render404 = useCallback(
    // eslint-disable-next-line react/display-name
    (tabs: TabData[]) => () => {
      setTimeout(() => {
        dispatch(alertError('errors.pageNotFound'));
      }, 100);
      return <Redirect to={tabs[0].path} />;
    },
    [dispatch]
  );

  return (
    <TabDataProvider currentGroup={currentGroup} currentUser={currentUser}>
      {({ routes, tabs }) => (
        <IonSplitPane className="group-split-pane" contentId="in-group-main">
          <GroupDesktopMenu currentGroup={currentGroup} tabs={tabs} />
          <IonPage id="in-group-main">
            <IonTabs>
              <IonRouterOutlet>
                <Switch>
                  {routes.map(route => (
                    <Route exact {...route} key={route.path} />
                  ))}
                  <Route exact path="/g/:slug" render={generateRedirectToTab(tabs[0].path)} />
                  <Route path="/g/:slug" render={render404(tabs)} />
                </Switch>
              </IonRouterOutlet>
              <IonTabBar color="night" slot="bottom">
                {renderTabButtons(tabs)}
                {tabs.length >= numTabs && (
                  <IonTabButton
                    href={`/g/${currentGroup.attributes.slug}/more-sections`}
                    layout="label-hide"
                    tab="more"
                  >
                    <IonIcon className="custom-sections" />
                    <IonLabel>
                      <FormattedMessage id="dictionary.more" />
                    </IonLabel>
                  </IonTabButton>
                )}
              </IonTabBar>
            </IonTabs>
          </IonPage>
        </IonSplitPane>
      )}
    </TabDataProvider>
  );
};

export default GroupTabsContainer;
