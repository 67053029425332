// all this does is wrap a primary color IonText around headings so we don't forget
import { IonText } from '@ionic/react';
import React from 'react';

const Heading = ({
  level,
  ...props
}: React.ComponentPropsWithoutRef<typeof IonText> & {
  level: 1 | 2 | 3 | 4 | 5 | 6;
}) => React.createElement(`h${level}`, {}, <IonText color="primary" {...props} />);

export default Heading;
