import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { alertWarning } from '../actions/notificationActions';
import LocalLoadingIndicator from '../components/LocalLoadingIndicator';
import useApiData from '../hooks/useApiData';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { forceArray } from '../services/arrayUtils';
import { Actions, JSONApi, Models, State } from '../types';

type Props<T extends Models.Base> = {
  children: (record: JSONApi.Resource<T>) => React.ReactElement;
  identifier: string;
  loadAction: Actions.ApiThunkAction<T>;
  recordFinder: (apiData: State.Api) => JSONApi.Resource<T> | null;
  redirect: string;
  warningKey: string;
};

function RequireRecord<T extends Models.Base>({
  children,
  identifier,
  loadAction,
  recordFinder,
  redirect,
  warningKey
}: Props<T>) {
  const apiData = useSelector((root: State.Root) => root.api);
  const dispatch = useThunkDispatch();

  const selector = useCallback(() => {
    const record = recordFinder(apiData);
    if (record) {
      return forceArray(record);
    }
    return undefined;
  }, [apiData, recordFinder]);

  const { loading, responseData: records } = useApiData<T>(
    loadAction,
    selector,
    `RequireRecord via ${identifier}`
  );

  if (records.length > 0) {
    return children(records[0]);
  }

  if (!loading) {
    dispatch(alertWarning(warningKey));
    return <Redirect to={redirect} />;
  }

  return <LocalLoadingIndicator />;
}

export default RequireRecord;
