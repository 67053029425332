import React from 'react';
import { useField } from 'react-final-form';

const FormCondition = ({
  children,
  inverse = false,
  name
}: React.PropsWithChildren<{
  inverse?: boolean;
  name: string;
}>) => {
  const { input } = useField(name, { subscription: { value: true } });

  if (inverse ? !input.value : !!input.value) {
    // the "useless" fragment here helps typescript understand that `children` is valid jsx
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return null;
};

export default FormCondition;
