import { IonCol, IonGrid, IonLabel, IonRouterLink, IonRow } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Nbsp from '../components/Nbsp';
import RelativeTimestamp from '../components/RelativeTimestamp';
import useConversation from '../hooks/useConversation';
import { JSONApi } from '../types';

type Props = {
  conversation: JSONApi.ConversationResource;
  group: JSONApi.GroupResource;
};

const ConversationMetaNote = ({ conversation, group }: Props) => {
  const { counterparty, creator, jobPost, recipient } = useConversation(conversation);

  if (!creator || !recipient) {
    return null;
  }

  const incoming = creator === counterparty;
  const textKeyParts = ['conversations', 'metaNote', conversation.attributes.threadType];
  const textValues: { [key: string]: string | undefined } = {};

  textValues.name = incoming ? creator.attributes.fullName : recipient.attributes.fullName;
  textKeyParts.push(incoming ? 'sentToYou' : 'sentByYou');

  return (
    <IonGrid className="ion-padding">
      <IonRow>
        <IonCol className="ion-text-center">
          <IonLabel color="dark">
            <FormattedMessage id={textKeyParts.join('.')} values={textValues} />
            <Nbsp />
            <RelativeTimestamp date={conversation.attributes.createdAt.valueOf()} />
          </IonLabel>
        </IonCol>
      </IonRow>
      {jobPost && (
        <IonRow>
          <IonCol className="ion-text-center">
            <IonLabel color="dark">
              <IonRouterLink
                routerLink={`/g/${group.attributes.slug}/job-board/${jobPost.attributes.slug}`}
              >
                {jobPost.attributes.title}
                <Nbsp />
                <FormattedMessage id="dictionary.at" />
                <Nbsp />
                {jobPost.attributes.companyName}
              </IonRouterLink>
            </IonLabel>
          </IonCol>
        </IonRow>
      )}
    </IonGrid>
  );
};

export default ConversationMetaNote;
