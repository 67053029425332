import * as JSONApi from './JSONApi';
import * as Models from './Models';
import * as Searches from './Search';
import SessionState from './SessionState';

export enum NotificationType {
  ERROR = 'error',
  PUSH_NOTIFICATION = 'pushNotification',
  SUCCESS = 'success',
  WARNING = 'warning'
}

export interface Notification {
  body?: string;
  bodyTranslationKey?: string;
  link?: string;
  path?: string;
  title?: string;
  type: NotificationType;
  update?: JSONApi.Response<Models.BroadcastUpdate>;
}

export interface NotificationList {
  dismissedNotifications: string[];
  notifications: Notification[];
}

export interface Api {
  cache: JSONApi.ResponseCache;
  meta: JSONApi.ApiStateMetaInfo;
  resources: JSONApi.ResourceCollection<Models.Base>;
}

export interface Loading {
  globalLoading: boolean;
  names: string[];
}

export interface Search {
  conversationParams: Searches.ConversationParams;
  eventParams: Searches.PostParams;
  forumParams: Searches.PostParams;
  jobBoardParams: Searches.PostParams;
  memberParams: Searches.MemberParams;
  newsItemParams: Searches.PostParams;
}

export interface Session {
  authToken: string | null | undefined;
  currentGroup: {
    slug: string | null;
    state: SessionState;
  };
  currentUser: {
    id: string | null;
    state: SessionState;
  };
  pushNotificationToken: string | undefined;
}

export type Root = {
  api: Api;
  loading: Loading;
  notifications: NotificationList;
  search: Search;
  session: Session;
};
