import { getUserMemberForGroup } from '../selectors';
import { createMember, updateMember } from '../thunks/apiThunks';
import { Actions, JSONApi, State } from '../types';

type MaybeJoinGroupArgs = {
  apiData: State.Api;
  dispatch: Actions.ThunkDispatch;
  group: JSONApi.GroupResource;
  history: { push: (arg: string) => void };
  invite: JSONApi.InviteResource;
  inviteCode?: string;
  user: JSONApi.UserResource;
};

export const acceptInviteForGroup = (args: MaybeJoinGroupArgs) => {
  const { apiData, dispatch, group, history, invite, inviteCode, user } = args;
  const existingMember = getUserMemberForGroup(apiData, user, group);
  const relationships: JSONApi.Relationships = {
    receivedInvites: { data: [{ id: invite.id, type: invite.type }] }
  };
  const inviteAttributes = { ...invite.attributes };
  if (inviteCode) {
    inviteAttributes.inviteCode = inviteCode;
  }
  // we just want to include the invite and not other things it might include
  const included = [
    {
      attributes: inviteAttributes,
      id: invite.id,
      relationships: {},
      type: invite.type
    }
  ];
  if (existingMember) {
    dispatch(
      updateMember(group.attributes.slug, {}, existingMember.id, relationships, included)
    ).then(response => {
      response.dataLoadedIntoStatePromise.then(() => {
        history.push(`/g/${group.attributes.slug}`);
      });
    });
  } else {
    dispatch(createMember(group.attributes.slug, relationships, included)).then(response => {
      response.dataLoadedIntoStatePromise.then(() => {
        history.push(`/g/${group.attributes.slug}`);
      });
    });
  }
};
