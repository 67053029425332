import React from 'react';

import { getRandomHtmlId } from '../../services/htmlUtils';

import './FileInput.scss';

const FileInput = ({
  accept,
  children,
  disabled,
  multiple,
  name,
  onChange: handleChange
}: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  accept?: string | undefined;
  disabled?: boolean | undefined;
  multiple?: boolean | undefined;
  name?: string | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
}) => {
  const id = getRandomHtmlId();
  return (
    <>
      {/* eslint-disable-next-line react/forbid-elements */}
      <input
        accept={accept ? accept : '*'}
        className="file-input"
        disabled={disabled}
        id={id}
        multiple={multiple}
        name={name}
        onChange={handleChange}
        tabIndex={-1}
        type="file"
      />
      {/* eslint-disable-next-line react/forbid-elements */}
      <label className="file-input-label" htmlFor={id}>
        {children}
      </label>
    </>
  );
};

export default FileInput;
