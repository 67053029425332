export function capitalizeString(str: string) {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}

export function truncateString(str: string, limit: number) {
  if (str.length > limit) {
    const trimChars = [
      '\u0009',
      '\u000A',
      '\u000B',
      '\u000C',
      '\u000D',
      '\u0020',
      '\u00A0',
      '\u1680',
      '\u180E',
      '\u2000',
      '\u2001',
      '\u2002',
      '\u2003',
      '\u2004',
      '\u2005',
      '\u2006',
      '\u2007',
      '\u2008',
      '\u2009',
      '\u200A',
      '\u202F',
      '\u205F',
      '\u2028',
      '\u2029',
      '\u3000',
      '\uFEFF'
    ];
    const rgx = new RegExp(`(?=[${trimChars.join('')}])`);
    const words = str.split(rgx);
    let count = 0;
    const trimmedString = words
      .filter(word => {
        count += word.length;
        return count <= limit;
      })
      .join('');
    return `${trimmedString}${String.fromCharCode(8230)}`;
  }
  return str;
}
