import { uniq } from '../services/arrayUtils';

export const prefixInclusions = (prefix: string, inclusions: string[]) => {
  const prefixedInclusions = inclusions.map(inclusion => [prefix, inclusion].join('.'));
  prefixedInclusions.unshift(prefix);
  return prefixedInclusions;
};

export const attachmentInclusions = ['auto_moderation_result'];

export const commentInclusions = [
  'creator',
  'mentioned_members',
  'post',
  'viewer_block',
  'viewer_reaction'
];
export const conversationInclusions = [
  'attachments',
  'creator',
  'job_post',
  'recipient',
  'notes',
  'notes.attachments',
  'notes.creator',
  'notes.viewer_block'
];
export const flagInclusions = [
  'flagged',
  'flagged.creator',
  'flagged.post',
  'flagged.post.poster',
  'flagger'
];
export const flaggedItemInclusions = ['item'].concat(prefixInclusions('flags', flagInclusions));

export const groupInclusions = [
  'links',
  'pinned_post',
  'price_tier',
  'rules',
  'traits',
  'traits.trait_options'
];

export const imageInclusions = ['auto_moderation_result'];

export const memberInclusions = [
  'links',
  'trait_option_selections',
  'trait_option_selections.trait',
  'trait_option_selections.trait_option',
  'viewer_save',
  'welcome_received_from_viewer'
];

const commonPostIncludes = [
  'mentioned_members',
  'poster',
  'recent_comments',
  'recent_comments.creator',
  'recent_comments.mentioned_members',
  'recent_comments.viewer_block',
  'recent_comments.viewer_reaction',
  'viewer_follow',
  'viewer_reaction',
  'viewer_save'
];

// If we try to ask for or update an individual post with all of the inclusions, the API will 500
// because the type may not have all of the attributes requested. But if we request a search then we _can_
// ask for all of the attributes, presumably because they are on an included model to the serialized thing.
export const postInclusions = {
  discussion: commonPostIncludes.concat(['images']),
  event: commonPostIncludes.concat(['rsvps', 'rsvps.member', 'viewer_rsvp']),
  jobPost: commonPostIncludes.concat(['attachments', 'viewer_application']),
  newsItem: commonPostIncludes.concat(['images'])
};

export const allPostInclusions = uniq(Object.values(postInclusions).flat());

export const userInclusions = ['email_addresses']
  .concat(prefixInclusions('members', memberInclusions))
  .concat(prefixInclusions('members.group', groupInclusions));

export const sessionInclusions = ['user'].concat(prefixInclusions('user', userInclusions));
