import { ItemReorderEventDetail } from '@ionic/react';
import { useCallback, useState } from 'react';

import { JSONApi, Models } from '../types';

type Args<T extends Models.Base> = {
  initialResources: JSONApi.Resource<T>[];
  onReorder: (resources: JSONApi.Resource<T>[]) => void;
};

const useReorderer = <T extends Models.Base>({ initialResources, onReorder }: Args<T>) => {
  const [isReordering, setIsReordering] = useState(false);
  const [resources, setResources] = useState(initialResources);

  /*
   * Note that the resources value held in state must be immediately updated when the reorder
   * event occurs in order to prevent the drag & drop animation from flickering back to the old
   * order. The `onReorder` handler will perform api calls (and resulting api state updates).
   * The onReorder handler _should_ reinforce the resources value by setting resources with
   * whatever comes back from the api, in case the api does something unexpected with the order.
   */
  const handleIonItemReorder = useCallback(
    (event: CustomEvent<ItemReorderEventDetail>) => {
      const ordered = event.detail.complete(resources) as JSONApi.Resource<T>[];
      const resourcesToSubmit = ordered.map((resource, index) => {
        const attributes = { ...resource.attributes, position: index };
        return { ...resource, attributes };
      });
      setResources(resourcesToSubmit);
      onReorder(resourcesToSubmit);
    },
    [onReorder, resources]
  );

  const handleToggleReorder = useCallback(() => {
    setIsReordering(!isReordering);
  }, [isReordering]);

  return {
    handleIonItemReorder,
    handleToggleReorder,
    isReordering,
    resources,
    setIsReordering,
    setResources
  };
};

export default useReorderer;
