import React from 'react';
import { useSelector } from 'react-redux';

import { alertError } from '../actions/notificationActions';
import LandingPage from '../components/LandingPage';
import UserAccountWizardPage from '../components/UserAccountWizardPage';
import useCurrentUser from '../hooks/useCurrentUser';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { JSONApi, SessionState, State } from '../types';

type Props = {
  children: (user: JSONApi.UserResource) => React.ReactElement;
  group?: JSONApi.GroupResource;
};

const RequireCurrentUser = ({ children, group }: Props) => {
  const currentUser = useCurrentUser();
  const userState = useSelector((root: State.Root) => root.session.currentUser.state);
  const dispatch = useThunkDispatch();

  if (userState === SessionState.LOADING) {
    return null;
  }

  if (userState === SessionState.NONE || userState === SessionState.FAILED || !currentUser) {
    dispatch(alertError('errors.user.loginRequired'));
    if (group) {
      return <UserAccountWizardPage group={group} />;
    }
    return <LandingPage />;
  }

  return children(currentUser);
};

export default RequireCurrentUser;
