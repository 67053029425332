import { useHistory } from 'react-router';

import { Opener } from '../hooks/useOpener';

const useDismissModalAndGoTo = (opener: Opener) => {
  const history = useHistory();

  const dismissModalAndGoTo = (path: string) => {
    opener.close();
    history.push(path);
  };

  return dismissModalAndGoTo;
};

export default useDismissModalAndGoTo;
