import { IonButton } from '@ionic/react';
import React from 'react';
import { FormSpy } from 'react-final-form';

const FormSubmit = ({
  allowPristineSubmit = false,
  disabled,
  ...ionButtonProps
}: React.ComponentProps<typeof IonButton> & {
  allowPristineSubmit?: boolean;
  disabled?: boolean;
}) => (
  <FormSpy subscription={{ errors: true, pristine: true, submitting: true }}>
    {({ errors, pristine, submitting }) => (
      <IonButton
        color="secondary"
        disabled={
          disabled ||
          (pristine && !allowPristineSubmit) ||
          submitting ||
          (errors && Object.keys(errors).length > 0)
        }
        expand="block"
        type="submit"
        {...ionButtonProps}
      />
    )}
  </FormSpy>
);

export default FormSubmit;
