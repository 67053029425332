import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { State } from '../types';

export const useApiDatRef = () => {
  const apiData = useSelector((root: State.Root) => root.api);
  const apiDataRef = useRef<State.Api>(apiData);

  useEffect(() => {
    apiDataRef.current = apiData;
  }, [apiData]);

  return apiDataRef;
};
