/*
 * this component overwrites Mentions inputs with some default behavior we always need:
 * 1) provide a default onSearch handler that looks up members according to the query
 * 2) implement an autoGrow functionality that mimics what is available on IonTextarea
 * 3) handle keystrokes to ensure that mentions inputs do not cause forms to submit on enter
 * 4) set default values for certain props that make sense in most cases for mentions inputs
 */

import Mentions from 'rc-mentions';
import { MentionsRef } from 'rc-mentions/lib/Mentions';
import React, { createRef, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import useCurrentGroup from '../../hooks/useCurrentGroup';
import useStopEnterKeyPropagation from '../../hooks/useStopEnterKeyPropagation';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { findById } from '../../selectors';
import { forceArray } from '../../services/arrayUtils';
import { loadMemberSearch } from '../../thunks/apiThunks';
import { JSONApi, State } from '../../types';

import './Mentions.scss';

// The Mentions component allows all of the native textarea props, so this does too
const MentionsTextarea = ({
  autoGrow = 'on',
  forwardRef = createRef<MentionsRef>(),
  onChange,
  onKeyDown,
  ...props
}: Omit<React.ComponentProps<typeof Mentions>, 'onSearch' | 'prefix'> & {
  autoGrow?: 'on' | 'off';
  forwardRef?: React.RefObject<MentionsRef>;
}) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const currentGroup = useCurrentGroup();
  const dispatch = useThunkDispatch();
  const [autocompleteOptions, setAutoCompleteOptions] = useState<JSONApi.MemberResource[]>([]);

  const handleSearch = useCallback(
    (query: string) => {
      if (currentGroup) {
        dispatch(loadMemberSearch(currentGroup.attributes.slug, { query }))
          .then(response => {
            if (response) {
              const data = forceArray(response.data)[0];
              if (data) {
                const memberIdentifiers = forceArray(data.relationships.results.data);
                const members = memberIdentifiers
                  .map(identifier => findById(apiData, identifier.type, identifier.id))
                  .filter(record => !!record) as JSONApi.MemberResource[];
                setAutoCompleteOptions(members);
              }
            }
          })
          .catch(() => {
            // I'm not convinced we need to do anything here.
          });
      }
    },
    [apiData, currentGroup, dispatch]
  );

  const handleChange = useCallback(
    (text: string) => {
      if (onChange) {
        onChange(text);
      }
      if (autoGrow === 'on' && forwardRef.current && forwardRef.current.textarea) {
        const textarea = forwardRef.current.textarea;
        textarea.style.height = '';
        const newHeight = Math.min(textarea.scrollHeight, 300);
        textarea.style.height = `${newHeight.toString()}px`;
      }
    },
    [autoGrow, forwardRef, onChange]
  );

  const handleKeyDown = useStopEnterKeyPropagation<HTMLTextAreaElement>(onKeyDown);

  return (
    // eslint-disable-next-line react/forbid-elements
    <Mentions
      // defaults
      autoCapitalize="on"
      autoCorrect="on"
      placement="top"
      spellCheck
      // overwrite defaults with client-supplied props
      {...props}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onSearch={handleSearch}
      prefix="@"
      ref={forwardRef}
    >
      {autocompleteOptions.map(member => (
        <Mentions.Option key={member.id} value={member.attributes.slug}>
          <span>
            <strong>{`@${member.attributes.slug}`}</strong> {member.attributes.fullName}
          </span>
        </Mentions.Option>
      ))}
    </Mentions>
  );
};

export default MentionsTextarea;
