import flat from 'flat';

import dictionary from './dictionary';
import errors from './errors';
import flagCategories from './flagCategories';
import forms from './forms';
import industries from './industries';
import modals from './modals';
import models from './models';
import pages from './pages';
import timeZones from './timeZones';

const nestedTranslations = {
  conversations: {
    metaNote: {
      internshipResumeDrop: {
        sentByYou: 'You sent a resume drop to {name}',
        sentToYou: '{name} sent you a resume drop'
      },
      jobApplication: {
        sentByYou: 'You applied to a job posted by {name}',
        sentToYou: '{name} applied to a job you posted'
      },
      message: {
        sentByYou: 'You sent a message to {name}',
        sentToYou: '{name} sent you a message'
      },
      welcome: {
        sentByYou: 'You sent a welcome to {name}',
        sentToYou: '{name} sent you a welcome'
      }
    }
  },
  dictionary,
  errors,
  flagCategories,
  forms,
  industries,
  legal: {
    address: '134 Boutonville Road, Pound Ridge, NY 10576',
    privacy: 'Privacy Policy',
    readThe: 'Click here to read the',
    terms: 'Terms of Service'
  },
  modals,
  models,
  pages,
  photos: {
    takePhoto: 'Take a new photo',
    upload: 'Upload a photo'
  },
  punctuation: {
    period: '.'
  },
  search: {
    forQuery: 'for keyword',
    forSort: 'sorted by',
    hitCount: 'Showing {num, plural, one {# result} other {# results}}',
    noResults: 'No results'
  },
  timeZones
};

type Translations = {
  [key: string]: string;
};

const enTranslations: Translations = flat.flatten(nestedTranslations);

export default enTranslations;
