import { Browser } from '@capacitor/browser';
import { IonButton } from '@ionic/react';
import React, { useCallback } from 'react';

const ExternalButton = ({
  href,
  ...ionButtonProps
}: Omit<React.ComponentPropsWithoutRef<typeof IonButton>, 'onClick' | 'rel' | 'target'> & {
  href: string;
}) => {
  const handleClick = useCallback(() => {
    Browser.open({ url: href });
  }, [href]);

  return (
    <IonButton
      {...ionButtonProps}
      onClick={handleClick}
      rel="noopener noreferrer"
      target="_blank"
    />
  );
};

export default ExternalButton;
