import { IonButton, IonContent } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import UserAccountWizardBackButton from './UserAccountWizardBackButton';
import overlap from '../assets/images/design/overlap.png';
import Img from '../components/Img';
import Page from '../components/layout/Page';
import PageTitle from '../components/layout/PageTitle';
import Paragraph from '../components/Paragraph';
import useCurrentUser from '../hooks/useCurrentUser';
import useMountedTracking from '../hooks/useMountedTracking';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { createMember } from '../thunks/apiThunks';
import { JSONApi } from '../types';

import './GroupLandingPage.scss';

type Props = {
  group: JSONApi.GroupResource;
};

const GroupLandingPage = ({ group }: Props) => {
  const currentUser = useCurrentUser();
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const { bgImageUrl, description, name, slug } = group.attributes;

  const handleClickJoin = useCallback(() => {
    if (currentUser) {
      if (currentUser.attributes.userComplete) {
        dispatch(createMember(group.attributes.slug))
          .then(() => {
            // let the GroupRoutes root handler send them to the right spot
            history.push(`/g/${slug}`);
          })
          .catch(() => {
            if (isMounted.current) {
              // !!TODO!! handle member creation failure
            }
          });
      } else {
        history.push(`/g/${slug}/register/setup-account`);
      }
    } else {
      history.push(`/g/${slug}/join`);
    }
  }, [currentUser, dispatch, group, history, isMounted, slug]);

  return (
    <Page className="centered group-landing-page tight">
      <PageTitle id="pages.groupLanding.title" values={{ group: name }} />
      <IonContent className="ion-padding">
        <UserAccountWizardBackButton routerLink="/" />
        <h1 className="ion-text-center">
          <FormattedMessage id="pages.groupLanding.title" values={{ group: name }} />
        </h1>
        <div className="ion-padding ion-text-center">
          <IonButton color="secondary" expand="block" onClick={handleClickJoin} size="large">
            <FormattedMessage id="dictionary.signUpLogin" />
          </IonButton>
        </div>
        {description.length > 0 && (
          <div className="description ion-padding ion-text-center">
            <Paragraph color="medium">{description}</Paragraph>
          </div>
        )}
        <div className="ion-padding ion-text-center">
          <Img
            alt={intl.formatMessage({ id: 'models.group.attributes.bgImage.alt' })}
            src={bgImageUrl ?? overlap}
          />
        </div>
      </IonContent>
    </Page>
  );
};

export default GroupLandingPage;
