import {
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  ToggleChangeEventDetail
} from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { alertError } from '../../actions/notificationActions';
import EditTraitOptionsForm from '../../components/forms/EditTraitOptionsForm';
import Toggle from '../../components/forms/Toggle';
import TraitOptionWarning from '../../components/forms/TraitOptionWarning';
import OnClickLink from '../../components/OnClickLink';
import Paragraph from '../../components/Paragraph';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { forceArray } from '../../services/arrayUtils';
import { updateTrait } from '../../thunks/apiThunks';
import { JSONApi } from '../../types';

type Props = {
  group: JSONApi.GroupResource;
  onBackClick: () => void;
  onRemoveClick: () => void;
  onSuccess: (trait: JSONApi.TraitResource) => void;
  trait: JSONApi.TraitResource;
};

const EditGroupTraitForm = ({ group, onBackClick, onRemoveClick, onSuccess, trait }: Props) => {
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();
  const [checked, setChecked] = useState(trait.attributes.required);

  const handleToggleChange = useCallback(
    (event: CustomEvent<ToggleChangeEventDetail>) => {
      const toggle = event.target as HTMLIonToggleElement & typeof event.target;
      if (toggle) {
        const required = toggle.checked;
        setChecked(required);
        if (required !== trait.attributes.required) {
          dispatch(updateTrait(group.attributes.slug, { required }, trait.id))
            .then(response => {
              if (isMounted.current) {
                const updatedTrait = forceArray(response.data)[0];
                onSuccess(updatedTrait);
                setChecked(updatedTrait.attributes.required);
              }
            })
            .catch(() => {
              dispatch(alertError('forms.group.update.error'));
              if (isMounted.current) {
                setChecked(trait.attributes.required);
              }
            });
        }
      }
    },
    [dispatch, group.attributes.slug, isMounted, onSuccess, trait]
  );

  return (
    <>
      <TraitOptionWarning trait={trait} />
      <IonItem className="compact" color="transparent" lines="none">
        <IonLabel className="ion-no-margin settings-list-label" color="primary">
          <FormattedMessage id="models.trait.attributes.title.label" />
        </IonLabel>
        <OnClickLink
          aria-label={intl.formatMessage({ id: 'dictionary.back' })}
          className="settings-list-link"
          onClick={onBackClick}
          slot="end"
        >
          <IonIcon icon={arrowBack} />
        </OnClickLink>
      </IonItem>
      <IonList className="ion-no-padding settings-list">
        <IonItem color="light">
          <IonLabel className="ion-no-margin settings-list-label" color="primary">
            {trait.attributes.title}
          </IonLabel>
          <IonButtons slot="end">
            <IonButton color="danger" onClick={onRemoveClick} size="small">
              <FormattedMessage id="dictionary.remove" />
            </IonButton>
          </IonButtons>
        </IonItem>
        <IonItem color="light" lines="none">
          <Toggle checked={checked} color="primary" onIonChange={handleToggleChange} slot="start" />
          <Paragraph>
            <FormattedMessage id="models.trait.attributes.required.label" />
          </Paragraph>
        </IonItem>
      </IonList>
      <EditTraitOptionsForm group={group} trait={trait} />
    </>
  );
};

export default EditGroupTraitForm;
