import { IonButtons, IonItem } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import FormSubmit from '../components/forms/FormSubmit';
import SetupAccountForm from '../components/forms/SetupAccountForm';
import Heading from '../components/Heading';
import SetupPage from '../components/SetupPage';
import { JSONApi } from '../types';

type Props = {
  currentUser: JSONApi.UserResource;
  group: JSONApi.GroupResource;
};

const SetupAccountPage = ({ currentUser, group }: Props) => {
  const history = useHistory();

  const handleSuccess = useCallback(() => {
    // User information is now complete, so forward to member verification
    history.push(`/g/${group.attributes.slug}/register/verification`);
  }, [group.attributes.slug, history]);

  return (
    <SetupPage defaultBackHref={`/g/${group.attributes.slug}`} titleKey="pages.setupAccount.title">
      <Heading level={2}>
        <FormattedMessage id="pages.setupAccount.h2" />
      </Heading>
      <SetupAccountForm currentUser={currentUser} group={group} onSuccess={handleSuccess}>
        <IonItem color="transparent" lines="none">
          <IonButtons slot="end">
            <FormSubmit allowPristineSubmit color="primary" fill="solid" size="default">
              <FormattedMessage id="dictionary.next" />
            </FormSubmit>
          </IonButtons>
        </IonItem>
      </SetupAccountForm>
    </SetupPage>
  );
};

export default SetupAccountPage;
