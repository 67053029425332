import { DateTime } from 'luxon';

export const addTime = (time: string, milliseconds: number) => {
  const timeInUnix = new Date(time).valueOf();
  const newTimeInUnix = timeInUnix + milliseconds;
  return new Date(newTimeInUnix).toISOString();
};

// takes an ISO time string, adds an hour to it, and returns an ISO time string
export const addAnHour = (time: string) => addTime(time, 60 * 60 * 1000);

/*
 * accepts an ISO time string, applies a new zone while retaining the local time,
 * and convers the result to UTC
 */
export const applyZoneAndReturnUTC = (time: string, zone: string) => {
  const dt = DateTime.fromISO(time);
  const rezoned = dt.setZone(zone, { keepLocalTime: true });
  return rezoned.toUTC().toISO();
};

// returns number of milliseconds between two ISO time strings
export const getDuration = (start: string, end: string) =>
  new Date(end).valueOf() - new Date(start).valueOf();

// returns the next whole hour from now as an ISO string
export const beginningOfNextHour = () => {
  const now = new Date();
  now.setHours(now.getHours() + 1);
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);
  return now.toISOString();
};

export const systemLocalTime = (time: string) => {
  const dt = DateTime.fromISO(time);
  return dt.toLocal().toJSON();
};
