import React, { useCallback } from 'react';
import { useParams } from 'react-router';

import RequireRecord from '../components/RequireRecord';
import { findBySlug } from '../selectors';
import { loadConversation } from '../thunks/apiThunks';
import { JSONApi, Models, State } from '../types';

type Props = {
  children: (conversation: JSONApi.ConversationResource) => React.ReactElement;
  group: JSONApi.GroupResource;
};

const RequireConversation = ({ children, group }: Props) => {
  const { conversationSlug } = useParams<{ conversationSlug: string }>();

  const recordFinder = useCallback(
    (apiData: State.Api) =>
      findBySlug<Models.Conversation>(apiData, 'conversation', conversationSlug, group),
    [conversationSlug, group]
  );

  return (
    <RequireRecord<Models.Conversation>
      identifier="RequireConversation"
      loadAction={loadConversation(group.attributes.slug, conversationSlug)}
      recordFinder={recordFinder}
      redirect={`/g/${group.attributes.slug}/inbox`}
      warningKey="models.conversation.notFound"
    >
      {children}
    </RequireRecord>
  );
};

export default RequireConversation;
