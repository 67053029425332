import React from 'react';

import ImageThumbnail from '../../components/forms/ImageThumbnail';
import { RelationshipsFieldManager } from '../../hooks/useRelationshipsFieldManager';
import { Models } from '../../types';

import './ImagesFieldItems.scss';

type Props = {
  fieldManager: RelationshipsFieldManager<Models.Image>;
};

const ImagesFieldItems = ({ fieldManager }: Props) => {
  if (fieldManager.relationships.length === 0) {
    return null;
  }
  return (
    <div className="image-thumbnails">
      {fieldManager.relationships.map(image => (
        <ImageThumbnail fieldManager={fieldManager} image={image} key={image.id} />
      ))}
    </div>
  );
};

export default ImagesFieldItems;
