import React, { useState } from 'react';

import VerificationEditProfileForm from '../components/forms/VerificationEditProfileForm';
import VerificationQuestionForm from '../components/forms/VerificationQuestionForm';
import VerificationRulesForm from '../components/forms/VerificationRulesForm';
import VerificationSettingsForm from '../components/forms/VerificationSettingsForm';
import VerificationWaitingForm from '../components/forms/VerificationWaitingForm';
import { JSONApi } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  member: JSONApi.MemberResource;
};

export enum WizardSteps {
  QUESTION = 'QUESTION',
  RULES = 'RULES',
  EDIT_PROFILE = 'EDIT_PROFILE',
  SETTINGS = 'SETTINGS',
  WAITING = 'WAITING'
}

const VerificationWizard = ({ group, member }: Props) => {
  let firstStep = WizardSteps.RULES;
  if (
    group.attributes.memberVerificationRequired &&
    group.attributes.memberVerificationQuestion &&
    group.attributes.memberVerificationQuestion.length > 0
  ) {
    firstStep = WizardSteps.QUESTION;
  }
  const [wizardStep, setWizardStep] = useState<WizardSteps>(firstStep);

  return (
    <>
      {wizardStep === WizardSteps.QUESTION && (
        <VerificationQuestionForm group={group} member={member} setWizardStep={setWizardStep} />
      )}
      {wizardStep === WizardSteps.RULES && (
        <VerificationRulesForm group={group} member={member} setWizardStep={setWizardStep} />
      )}
      {wizardStep === WizardSteps.EDIT_PROFILE && (
        <VerificationEditProfileForm group={group} member={member} setWizardStep={setWizardStep} />
      )}
      {wizardStep === WizardSteps.SETTINGS && (
        <VerificationSettingsForm group={group} member={member} setWizardStep={setWizardStep} />
      )}
      {wizardStep === WizardSteps.WAITING && <VerificationWaitingForm group={group} />}
    </>
  );
};

export default VerificationWizard;
