import { IonButton, IonButtons, IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import FormModal from '../../components/FormModal';
import GroupAvatar from '../../components/GroupAvatar';
import { Opener } from '../../hooks/useOpener';
import { JSONApi } from '../../types';

import './ChangePreviewModal.scss';

const ChangePreviewModal = ({
  acceptButtonTextKey,
  children,
  group,
  handleAccept,
  handleCancel,
  opener,
  titleKey
}: React.PropsWithChildren<{
  acceptButtonTextKey: string;
  group: JSONApi.GroupResource;
  handleAccept: () => void;
  handleCancel: () => void;
  opener: Opener;
  titleKey: string;
}>) => (
  <FormModal keyboardClose opener={opener} titleKey={titleKey}>
    {() => (
      <IonGrid className="change-preview">
        <IonRow>
          <IonCol className="group-name-section ion-padding" sizeLg="5" sizeXs="12">
            <div className="group-icon-section ion-padding">
              <GroupAvatar group={group} />
            </div>
            <h2 className="ion-padding">{group.attributes.name}</h2>
          </IonCol>
          <IonCol className="info-section ion-padding" sizeLg="7" sizeXs="12">
            {children}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButtons>
              <IonButton color="primary" fill="outline" onClick={handleCancel} size="default">
                <FormattedMessage id="dictionary.cancel" />
              </IonButton>
              <IonButton
                className="accept-change"
                color="primary"
                fill="solid"
                onClick={handleAccept}
                size="default"
              >
                <FormattedMessage id={acceptButtonTextKey} />
              </IonButton>
            </IonButtons>
          </IonCol>
        </IonRow>
      </IonGrid>
    )}
  </FormModal>
);

export default ChangePreviewModal;
