import { IonLabel, IonSelect, IonSelectOption, SelectChangeEventDetail } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { ModelAttributes, Search } from '../types';

type ExtendedSearchParams<T extends Search.Params> = T & {
  eventType: ModelAttributes.EventType | null;
};

type Props<T extends Search.Params> = {
  facetValues?: Search.FacetValue[] | undefined;
  searchParams: ExtendedSearchParams<T>;
  setSearchParams: (value: ExtendedSearchParams<T>) => void;
};

function SearchEventType<T extends Search.Params>({
  facetValues,
  searchParams,
  setSearchParams
}: Props<T>) {
  const handleIonChange = useCallback(
    (event: CustomEvent<SelectChangeEventDetail>) => {
      const target = event.target as HTMLIonSelectElement & typeof event.target;
      if (target) {
        const value = target.value as ModelAttributes.EventType;
        setSearchParams({ ...searchParams, eventType: value });
      }
    },
    [searchParams, setSearchParams]
  );

  return (
    <div>
      <IonLabel>
        <FormattedMessage id="models.event.attributes.eventType.label" />
      </IonLabel>
      <IonSelect multiple onIonChange={handleIonChange} value={searchParams.eventType}>
        {facetValues?.map(facet => (
          <IonSelectOption key={facet.key} value={facet.key}>
            <FormattedMessage id="forms.facet.keyAndCount" values={{ ...facet }} />
          </IonSelectOption>
        ))}
      </IonSelect>
    </div>
  );
}

export default SearchEventType;
