import { IonBadge, IonContent, IonItem, IonLabel, IonList } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import BasicLink from '../components/BasicLink';
import NewEmailAddressForm from '../components/forms/NewEmailAddressForm';
import Page from '../components/layout/Page';
import PageHeader from '../components/layout/PageHeader';
import PageTitle from '../components/layout/PageTitle';
import Paragraph from '../components/Paragraph';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { getUserEmailAddresses } from '../selectors';
import { dispatchAndAlert } from '../services/dispatchUtils';
import {
  createEmailConfirmation,
  deleteEmailAddress,
  promoteEmailAddress
} from '../thunks/apiThunks';
import { JSONApi, State } from '../types';

import './SettingsList.scss';

type WithEmailAddress = {
  emailAddress: JSONApi.EmailAddressResource;
};

const BadgeSubcomponent = ({ emailAddress }: WithEmailAddress) => {
  if (emailAddress.attributes.primary) {
    return (
      <IonBadge color="primary">
        <FormattedMessage id="dictionary.primary" />
      </IonBadge>
    );
  }
  if (emailAddress.attributes.bounced) {
    return (
      <IonBadge color="danger">
        <FormattedMessage id="dictionary.bounced" />
      </IonBadge>
    );
  }
  if (emailAddress.attributes.confirmed) {
    return (
      <IonBadge color="success">
        <FormattedMessage id="dictionary.confirmed" />
      </IonBadge>
    );
  }
  return (
    <IonBadge color="warning">
      <FormattedMessage id="dictionary.unconfirmed" />
    </IonBadge>
  );
};

const SelectedItemSubcomponent = ({ emailAddress }: WithEmailAddress) => {
  const dispatch = useThunkDispatch();

  const handlePromoteClick = useCallback(() => {
    dispatchAndAlert(
      dispatch,
      promoteEmailAddress(emailAddress),
      'forms.emailAddress.promote.success',
      'forms.emailAddress.promote.failure'
    );
  }, [dispatch, emailAddress]);

  const handleResendConfirmationClick = useCallback(() => {
    dispatchAndAlert(
      dispatch,
      createEmailConfirmation(undefined, emailAddress.attributes.email),
      'forms.emailConfirmation.create.success',
      'forms.emailConfirmation.create.failure'
    );
  }, [dispatch, emailAddress.attributes.email]);

  const handleRemoveClick = useCallback(() => {
    dispatchAndAlert(
      dispatch,
      deleteEmailAddress(emailAddress),
      'forms.emailAddress.delete.success',
      'forms.emailAddress.delete.failure'
    );
  }, [dispatch, emailAddress]);

  if (emailAddress.attributes.primary) {
    return (
      <Paragraph>
        <FormattedMessage id="models.emailAddress.attributes.primary.details" />
      </Paragraph>
    );
  }
  return (
    <>
      {emailAddress.attributes.confirmed ? (
        <BasicLink onClick={handlePromoteClick} tKey="dictionary.makePrimary" />
      ) : (
        <BasicLink onClick={handleResendConfirmationClick} tKey="dictionary.resendConfirmation" />
      )}
      <BasicLink onClick={handleRemoveClick} slot="end" tKey="dictionary.remove" />
    </>
  );
};

type Props = {
  currentUser: JSONApi.UserResource;
};

const ChangeEmailPage = ({ currentUser }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const { email } = useParams<{ email: string }>();

  const emailAddresses = getUserEmailAddresses(apiData, currentUser);

  return (
    <Page className="centered change-email-page tight">
      <PageHeader defaultBackHref="/me-and-my-data" />
      <PageTitle id="pages.changeEmail.title" />
      <IonContent className="canvas ion-padding">
        <div>
          <h1 className="ion-no-margin ion-padding">
            <FormattedMessage id="pages.changeEmail.title" />
          </h1>
          <IonList className="ion-margin-top ion-no-padding settings-list">
            {emailAddresses.map(emailAddress => {
              const isSelected = decodeURIComponent(email) === emailAddress.attributes.email;
              return (
                <div key={emailAddress.id}>
                  <IonItem
                    color={isSelected ? 'day' : undefined}
                    detail={false}
                    lines={isSelected ? 'none' : 'inset'}
                    routerLink={
                      isSelected
                        ? '/me-and-my-data/emails'
                        : `/me-and-my-data/emails/${encodeURIComponent(
                            emailAddress.attributes.email
                          )}`
                    }
                  >
                    <IonLabel color="primary">{emailAddress.attributes.email}</IonLabel>
                    <BadgeSubcomponent emailAddress={emailAddress} />
                  </IonItem>
                  {isSelected && (
                    <IonItem color="day">
                      <SelectedItemSubcomponent emailAddress={emailAddress} />
                    </IonItem>
                  )}
                </div>
              );
            })}
            <NewEmailAddressForm />
          </IonList>
        </div>
      </IonContent>
    </Page>
  );
};

export default ChangeEmailPage;
