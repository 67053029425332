import actioncable from 'actioncable';
import React, { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { State } from '../types';

export const ActionCableContext = createContext<actioncable.Cable | undefined>(undefined);

type Props = {
  children: React.ReactNode;
};

const ActionCableProvider = ({ children }: Props) => {
  const authToken = useSelector((root: State.Root) => root.session.authToken);
  const [cable, setCable] = useState<actioncable.Cable | undefined>();

  useEffect(() => {
    const host = import.meta.env.VITE_ACTIONCABLE_HOST;
    if (host && authToken) {
      if (!cable) {
        const newCable = actioncable.createConsumer(`${host}/cable?token=${authToken}`);
        setCable(newCable);
      }
    } else {
      if (cable) {
        setCable(undefined);
      }
    }

    return () => {
      if (cable) {
        cable.disconnect();
      }
    };
  }, [authToken, cable]);

  return <ActionCableContext.Provider value={cable}>{children}</ActionCableContext.Provider>;
};

export default ActionCableProvider;
