import { useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react';
import { useState } from 'react';

export enum ViewStatus {
  CLOSED = 'closed',
  OPEN = 'open'
}

// It is very difficult to debug issues with this, so I added a thing to pass the name
// of the thing where it is originating from, so that we can see which views are opening
// and closing and why. It was a bit of a pain to add but doesn't need to be used until there
// is debugging to do, so this argument is currently unused.
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const useViewStatus = (name: string) => {
  const [status, setStatus] = useState<ViewStatus>(ViewStatus.CLOSED);
  const [everBeenOpen, setEverBeenOpen] = useState(false);

  // Prevent spurious changes to the state
  const updateState = (arg: { everBeenOpen?: boolean; status?: ViewStatus }) => {
    if (arg.status && arg.status !== status) {
      setStatus(arg.status);
    }
    if (arg.everBeenOpen && arg.everBeenOpen !== everBeenOpen) {
      setEverBeenOpen(arg.everBeenOpen);
    }
  };

  useIonViewDidEnter(() => {
    updateState({
      everBeenOpen: true,
      status: ViewStatus.OPEN
    });
  });

  useIonViewDidLeave(() => {
    updateState({ status: ViewStatus.CLOSED });
  });

  return { everBeenOpen, status };
};

export default useViewStatus;
