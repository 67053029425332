type WindowWithDevtools = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  __REACT_DEVTOOLS_GLOBAL_HOOK__: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    [key: string]: object | null;
  };
};

export function disableReactDevTools(): void {
  const noop = (): void => undefined;
  const typedWindow = window as Window & WindowWithDevtools & typeof globalThis;
  const devTools = typedWindow.__REACT_DEVTOOLS_GLOBAL_HOOK__;

  if (typeof devTools === 'object') {
    for (const [key, value] of Object.entries(devTools)) {
      devTools[key] = typeof value === 'function' ? noop : null;
    }
  }
}
