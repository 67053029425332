import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu
} from '@ionic/react';
import {
  addCircleOutline,
  atOutline,
  handLeftOutline,
  helpCircleOutline,
  logOutOutline,
  notificationsOutline,
  readerOutline,
  searchCircleOutline
} from 'ionicons/icons';
import React, { createRef, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import AvatarWithDefault from '../components/AvatarWithDefault';
import ExternalItemButton from '../components/ExternalItemButton';
import GroupMenuList from '../components/GroupMenuList';
import Nbsp from '../components/Nbsp';
import useCurrentGroup from '../hooks/useCurrentGroup';
import useCurrentMember from '../hooks/useCurrentMember';
import useCurrentUserGroups from '../hooks/useCurrentUserGroups';
import useOpener from '../hooks/useOpener';
import { JSONApi, ModelAttributes } from '../types';

import './MainMenu.scss';

type Props = {
  currentUser: JSONApi.UserResource;
};

const MainMenu = ({ currentUser }: Props) => {
  const currentUserGroups = useCurrentUserGroups();
  const currentGroup = useCurrentGroup();
  const currentMember = useCurrentMember();
  const groupSlug = currentGroup ? currentGroup.attributes.slug : null;
  const history = useHistory();
  const intl = useIntl();
  // we only use the opener to track the menu animation state for testing purposes
  const menuOpener = useOpener();
  const menuRef = createRef<HTMLIonMenuElement>();

  const alt = intl.formatMessage(
    { id: 'models.member.attributes.image.alt' },
    { name: currentUser.attributes.firstName }
  );

  const closeMenu = useCallback(() => {
    if (menuRef.current) {
      return menuRef.current.close();
    }
  }, [menuRef]);

  const generateClickHandler = useCallback(
    (route: string) => () => {
      if (menuRef.current) {
        menuRef.current.close().then(() => {
          history.push(route);
        });
      } else {
        history.push(route);
      }
    },
    [history, menuRef]
  );

  const shouldShowGroupSpecificItems =
    currentGroup &&
    currentGroup.attributes.status === ModelAttributes.GroupStatus.APPROVED &&
    currentMember &&
    currentMember.attributes.status === ModelAttributes.MemberStatus.APPROVED;

  return (
    <IonMenu
      className={`main-menu ${menuOpener.isPresented ? 'is-presented' : ''}`}
      contentId="mainMenuContent"
      disabled={false}
      menuId="main-menu"
      onIonDidClose={menuOpener.handleDidDismiss}
      onIonDidOpen={menuOpener.handleDidPresent}
      ref={menuRef}
      side="end"
      type="overlay"
    >
      <IonContent>
        <div className="hero" color="transparent">
          <div className="profile">
            <AvatarWithDefault
              alt={alt}
              id={currentUser.attributes.slug}
              src={currentUser.attributes.remoteImageThumbUrl}
            />
            <IonLabel>
              <h2>{currentUser.attributes.firstName}</h2>
              <IonButton
                className="flat"
                color="primary"
                disabled={!menuOpener.isPresented}
                onClick={generateClickHandler('/me-and-my-data')}
                shape="round"
                size="small"
              >
                <FormattedMessage id="pages.meAndMyData.title" />
              </IonButton>
            </IonLabel>
          </div>
        </div>
        {shouldShowGroupSpecificItems && currentGroup && (
          <IonList className="custom-list">
            <IonListHeader>
              <IonLabel>{currentGroup.attributes.name}</IonLabel>
            </IonListHeader>
            {currentMember?.attributes.moderator && (
              <IonItem
                button
                color="light"
                detail={false}
                disabled={!menuOpener.isPresented}
                onClick={generateClickHandler(`/g/${currentGroup.attributes.slug}/manage-group`)}
                role="button"
              >
                <IonIcon className="custom-moderator" slot="start" />
                <FormattedMessage id="pages.manageGroup.tools.title" />
              </IonItem>
            )}
            <IonItem
              button
              color="light"
              detail={false}
              disabled={!menuOpener.isPresented}
              onClick={generateClickHandler(`/g/${currentGroup.attributes.slug}/mentions`)}
              role="button"
            >
              <IonIcon icon={atOutline} slot="start" />
              <FormattedMessage id="pages.mentions.title" />
            </IonItem>
            <IonItem
              button
              color="light"
              detail={false}
              disabled={!menuOpener.isPresented}
              onClick={generateClickHandler(`/g/${currentGroup.attributes.slug}/blocked-items`)}
              role="button"
            >
              <IonIcon icon={handLeftOutline} slot="start" />
              <FormattedMessage id="pages.blocks.title" />
            </IonItem>
            <IonItem
              button
              color="light"
              detail={false}
              disabled={!menuOpener.isPresented}
              onClick={generateClickHandler(`/g/${currentGroup.attributes.slug}/saved-items`)}
              role="button"
            >
              <IonIcon className="custom-bookmarks" slot="start" />
              <FormattedMessage id="pages.saves.title" />
            </IonItem>
            <IonItem
              button
              color="light"
              detail={false}
              disabled={!menuOpener.isPresented}
              lines="none"
              onClick={generateClickHandler(
                `/me-and-my-data/notifications/${currentGroup.attributes.slug}`
              )}
              role="button"
            >
              <IonIcon icon={notificationsOutline} slot="start" />
              <FormattedMessage id="pages.notifications.title" />
            </IonItem>
          </IonList>
        )}
        {currentUserGroups.joined.length > 0 && (
          <GroupMenuList
            currentGroupSlug={groupSlug}
            groups={currentUserGroups.joined}
            headerTKey="dictionary.myGroups"
            menuOpener={menuOpener}
            onClick={closeMenu}
          />
        )}
        {currentUserGroups.joining.length > 0 && (
          <GroupMenuList
            currentGroupSlug={groupSlug}
            groups={currentUserGroups.joining}
            headerTKey="dictionary.groupsImJoining"
            menuOpener={menuOpener}
            onClick={closeMenu}
          />
        )}
        {currentUserGroups.creating.length > 0 && (
          <GroupMenuList
            creating
            currentGroupSlug={groupSlug}
            groups={currentUserGroups.creating}
            headerTKey="dictionary.groupsImCreating"
            menuOpener={menuOpener}
            onClick={closeMenu}
          />
        )}
        <IonList className="custom-list">
          <IonListHeader>
            <IonLabel>
              <FormattedMessage id="dictionary.more" />
            </IonLabel>
          </IonListHeader>
          <IonItem
            button
            color="light"
            detail={false}
            disabled={!menuOpener.isPresented}
            onClick={generateClickHandler('/create')}
            role="button"
          >
            <IonIcon icon={addCircleOutline} slot="start" />
            <FormattedMessage id="dictionary.startAGroup" />
          </IonItem>
          <IonItem
            button
            color="light"
            detail={false}
            disabled={!menuOpener.isPresented}
            onClick={generateClickHandler('/search')}
            role="button"
          >
            <IonIcon icon={searchCircleOutline} slot="start" />
            <FormattedMessage id="dictionary.findMoreGroups" />
          </IonItem>
          <ExternalItemButton color="light" detail={false} href="https://www.grouplicate.com/help">
            <IonIcon icon={helpCircleOutline} slot="start" />
            <FormattedMessage id="dictionary.help" />
          </ExternalItemButton>
          <ExternalItemButton color="light" detail={false} href="https://www.grouplicate.com/terms">
            <IonIcon icon={readerOutline} slot="start" />
            <FormattedMessage id="dictionary.legal" />
          </ExternalItemButton>
          <IonItem
            button
            color="light"
            detail={false}
            disabled={!menuOpener.isPresented}
            lines="none"
            onClick={generateClickHandler('/logout')}
            role="button"
          >
            <IonIcon icon={logOutOutline} slot="start" />
            <FormattedMessage id="dictionary.logout" />
          </IonItem>
        </IonList>
        <div className="ion-padding ion-text-center">
          <FormattedMessage id="dictionary.copyright" />
          <Nbsp />
          {new Date().getFullYear()}
          <Nbsp />
          <FormattedMessage id="dictionary.grouplicate" />
        </div>
      </IonContent>
    </IonMenu>
  );
};

export default MainMenu;
