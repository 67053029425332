import React, { useCallback } from 'react';
import { useParams } from 'react-router';

import RequireRecord from '../components/RequireRecord';
import { findBySlug } from '../selectors';
import { Actions, JSONApi, Models, State } from '../types';

type Props<T extends Models.Post> = {
  children: (post: JSONApi.Resource<T>) => React.ReactElement;
  group: JSONApi.GroupResource;
  loadPost: (groupSlug: string, slug: string) => Actions.ApiThunkAction<T>;
  redirect: string;
  resourceType: 'discussion' | 'event' | 'jobPost' | 'newsItem';
};

function RequirePost<T extends Models.Post>({
  children,
  group,
  loadPost,
  redirect,
  resourceType
}: Props<T>) {
  const { postSlug } = useParams<{ postSlug: string }>();

  const recordFinder = useCallback(
    (apiData: State.Api) => findBySlug<T>(apiData, resourceType, postSlug, group),
    [resourceType, postSlug, group]
  );

  return (
    <RequireRecord
      identifier="RequirePost"
      loadAction={loadPost(group.attributes.slug, postSlug)}
      recordFinder={recordFinder}
      redirect={redirect}
      warningKey={`models.${resourceType}.notFound`}
    >
      {children}
    </RequireRecord>
  );
}

export default RequirePost;
