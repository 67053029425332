import equal from 'deep-equal';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Action } from 'redux';

import useThunkDispatch from '../hooks/useThunkDispatch';
import { collectSearchParamsFromUrl, toUrlParams } from '../services/urlUtils';
import { Search } from '../types';

type SearchParamsSetter<T extends Search.Params> = (params: T) => void;

const useSearchParamsFromUrl = <T extends Search.Params>(
  searchableFields: (keyof T)[],
  setSearchParams: SearchParamsSetter<T>,
  searchParams: T,
  updateStoredSearchParamsActionCreator: (params: T) => Action
) => {
  const routeLocation = useLocation();
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const [searchParamsChangedTo, setSearchParamsChangedTo] = useState<T>();

  // Initialize the search from the url params
  useEffect(() => {
    const newParams = {
      ...searchParams,
      ...collectSearchParamsFromUrl(routeLocation, searchableFields)
    };
    if (!equal(searchParams, newParams)) {
      setSearchParams(newParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeLocation]);

  useEffect(() => {
    if (!equal(searchParams, searchParamsChangedTo)) {
      setSearchParamsChangedTo(searchParams);
      dispatch(updateStoredSearchParamsActionCreator(searchParams));
      history.replace({
        pathname: routeLocation.pathname,
        search: `?${toUrlParams(searchParams)}`
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, routeLocation.pathname, searchParams]);
};

export default useSearchParamsFromUrl;
