import {
  IonBadge,
  IonButton,
  IonButtons,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  SelectChangeEventDetail
} from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { alertError, alertSuccess } from '../actions/notificationActions';
import Nbsp from '../components/Nbsp';
import Paragraph from '../components/Paragraph';
import useCurrentMember from '../hooks/useCurrentMember';
import useMountedTracking from '../hooks/useMountedTracking';
import useOpener from '../hooks/useOpener';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { AjaxError } from '../services/ajaxRequest';
import { handleSubmissionError } from '../services/formUtils';
import { getMemberRoleColor, getMemberStatusColor } from '../services/htmlUtils';
import { approveMember, rejectMember, updateMember } from '../thunks/apiThunks';
import { Actions, JSONApi, ModelAttributes, Models } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  member: JSONApi.MemberResource;
};

const ModerateMember = ({ group, member }: Props) => {
  const actionsOpener = useOpener();
  const currentMember = useCurrentMember();
  const dispatch = useThunkDispatch();
  const isMounted = useMountedTracking();

  const { role, slug, status } = member.attributes;

  const canBeApproved =
    !group.attributes.isAtMaxCapacity &&
    [
      ModelAttributes.MemberStatus.ADMINISTRATOR_REJECTED,
      ModelAttributes.MemberStatus.AWAITING_MODERATION,
      ModelAttributes.MemberStatus.MODERATOR_REJECTED
    ].includes(status);

  const canBeRejected =
    [
      ModelAttributes.MemberStatus.APPROVED,
      ModelAttributes.MemberStatus.AWAITING_MODERATION
    ].includes(status) && role !== ModelAttributes.MemberRole.OWNER;

  const handleModerateMember = useCallback(
    (action: Actions.ApiThunkAction<Models.Member>, type: 'approve' | 'changeRole' | 'reject') => {
      dispatch(action)
        .then(() => {
          dispatch(alertSuccess(`forms.moderateMember.${type}.success`));
        })
        .catch((error: AjaxError) => {
          handleSubmissionError({
            dispatch,
            error,
            handleInvalid: () => {
              dispatch(alertError(`forms.moderateMember.${type}.error`));
            },
            isMounted
          });
        });
    },
    [dispatch, isMounted]
  );

  const handleChangeRole = useCallback(
    (event: CustomEvent<SelectChangeEventDetail>) => {
      const target = event.target as HTMLIonSelectElement | null;
      if (target && target.value !== role) {
        const role = target.value as ModelAttributes.MemberRole;
        handleModerateMember(
          updateMember(group.attributes.slug, { role }, member.id),
          'changeRole'
        );
      }
    },
    [group.attributes.slug, handleModerateMember, member.id, role]
  );

  const handleClickApprove = useCallback(() => {
    handleModerateMember(approveMember(group.attributes.slug, member), 'approve');
  }, [group.attributes.slug, handleModerateMember, member]);

  const handleClickReject = useCallback(() => {
    handleModerateMember(rejectMember(group.attributes.slug, member), 'reject');
  }, [group.attributes.slug, handleModerateMember, member]);

  return (
    <>
      <IonItem
        button
        className="ion-activatable"
        color={actionsOpener.isOpen ? 'light' : undefined}
        lines={actionsOpener.isOpen ? 'inset' : 'full'}
        onClick={actionsOpener.toggle}
        role="button"
      >
        <IonLabel>{member.attributes.fullName}</IonLabel>
        {!actionsOpener.isOpen && (
          <div slot="end">
            <IonBadge color={getMemberStatusColor(status)}>
              <FormattedMessage id={`models.member.attributes.status.${status}Badge`} />
            </IonBadge>
          </div>
        )}
      </IonItem>
      {actionsOpener.isOpen && (
        <>
          {currentMember &&
            currentMember.attributes.role === ModelAttributes.MemberRole.OWNER &&
            currentMember.id !== member.id && (
              <IonItem color="light" lines="none">
                <IonLabel>
                  <FormattedMessage id="models.member.attributes.role.label" />
                </IonLabel>
                <IonSelect onIonChange={handleChangeRole} value={role}>
                  {Object.values(ModelAttributes.MemberRole).map(role => (
                    <IonSelectOption key={role} value={role}>
                      <FormattedMessage id={`models.member.attributes.role.${role}Badge`} />
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            )}
          <IonItem color="light" lines="none">
            <IonLabel>
              <FormattedMessage id="dictionary.joined" />
              <Nbsp />
              {member.attributes.createdAt.toLocaleString({
                day: 'numeric',
                month: 'short',
                year: 'numeric'
              })}
            </IonLabel>
            <div slot="end">
              <IonBadge color={getMemberRoleColor(role)}>
                <FormattedMessage id={`models.member.attributes.role.${role}Badge`} />
              </IonBadge>
              <Nbsp />
              <IonBadge color={getMemberStatusColor(status)}>
                <FormattedMessage id={`models.member.attributes.status.${status}Badge`} />
              </IonBadge>
              <Nbsp />
            </div>
          </IonItem>
          {member.attributes.verificationAnswer && (
            <IonItem color="light" lines="none">
              <Paragraph className="formatted-text">
                <strong>{group.attributes.memberVerificationQuestion}</strong>
                <Nbsp />
                {member.attributes.verificationAnswer}
              </Paragraph>
            </IonItem>
          )}
          <IonItem color="light" lines="none">
            <IonButtons>
              <IonButton
                color="secondary"
                fill="solid"
                routerLink={`/g/${group.attributes.slug}/members/${slug}`}
                slot="start"
              >
                <FormattedMessage id="dictionary.viewProfile" />
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              {canBeApproved && (
                <IonButton color="success" fill="solid" onClick={handleClickApprove}>
                  <FormattedMessage id="dictionary.approve" />
                </IonButton>
              )}
              {canBeRejected && (
                <IonButton color="danger" fill="solid" onClick={handleClickReject}>
                  <FormattedMessage id="dictionary.reject" />
                </IonButton>
              )}
            </IonButtons>
          </IonItem>
        </>
      )}
    </>
  );
};

export default ModerateMember;
