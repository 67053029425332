import {
  IonCard,
  IonCardContent,
  IonList,
  IonSegment,
  IonSegmentButton,
  SegmentChangeEventDetail
} from '@ionic/react';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import MembersCanSendInvitesField from '../components/forms/MembersCanSendInvitesField';
import ModeratorPage from '../components/layout/ModeratorPage';
import ModerateInvite from '../components/ModerateInvite';
import SearchQuery from '../components/SearchQuery';
import SearchResults from '../components/SearchResults';
import { loadInviteSearch, loadMoreInviteSearchResults } from '../thunks/apiThunks';
import { ApiEndpoint, JSONApi, ModelAttributes, Models, Search } from '../types';

import '../components/forms/CustomForm.scss';

type Props = {
  group: JSONApi.GroupResource;
};

enum StatusFilters {
  ACCEPTED = 'accepted',
  ALL = 'all',
  SENT = 'sent',
  REVOKED = 'revoked'
}

const ManageGroupLinksPage = ({ group }: Props) => {
  const [searchParams, setSearchParams] = useState<Search.InviteParams>({
    query: '',
    status: Object.values(ModelAttributes.InviteStatus).join(',')
  });
  const [statusFilter, setStatusFilter] = useState<StatusFilters>(StatusFilters.ALL);

  const handleSegmentChange = useCallback(
    (event: CustomEvent<SegmentChangeEventDetail>) => {
      const changeDetail = event.detail;
      if (changeDetail) {
        const newStatusFilter = changeDetail.value as StatusFilters;
        setStatusFilter(newStatusFilter);
        switch (newStatusFilter) {
          case StatusFilters.ALL:
            setSearchParams({ ...searchParams, status: undefined });
            break;
          default:
            setSearchParams({ ...searchParams, status: newStatusFilter });
            break;
        }
      }
    },
    [searchParams]
  );

  return (
    <ModeratorPage group={group} h2Key="pages.manageGroup.invites.title">
      {contentRef => (
        <>
          <IonCard>
            <IonCardContent>
              <MembersCanSendInvitesField group={group} />
            </IonCardContent>
          </IonCard>
          <div className="custom-form-wrapper ion-padding">
            <SearchQuery<Search.InviteParams>
              autofocus={false}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </div>
          <IonSegment onIonChange={handleSegmentChange} value={statusFilter}>
            <IonSegmentButton value={StatusFilters.ALL}>
              <FormattedMessage id="dictionary.all" />
            </IonSegmentButton>
            <IonSegmentButton value={StatusFilters.SENT}>
              <FormattedMessage id="dictionary.sent" />
            </IonSegmentButton>
            <IonSegmentButton value={StatusFilters.ACCEPTED}>
              <FormattedMessage id="dictionary.accepted" />
            </IonSegmentButton>
            <IonSegmentButton value={StatusFilters.REVOKED}>
              <FormattedMessage id="dictionary.revoked" />
            </IonSegmentButton>
          </IonSegment>
          <SearchResults<Models.InviteSearch, Models.Invite, Search.InviteParams>
            contentRef={contentRef}
            group={group}
            loadDataActionCreator={loadInviteSearch}
            loadMoreActionCreator={loadMoreInviteSearchResults}
            searchEndpoint={ApiEndpoint.INVITE_SEARCH}
            searchParams={searchParams}
            showCount={false}
          >
            {results => (
              <IonList>
                {results.map(invite => (
                  <ModerateInvite group={group} invite={invite} key={invite.id} />
                ))}
              </IonList>
            )}
          </SearchResults>
        </>
      )}
    </ModeratorPage>
  );
};

export default ManageGroupLinksPage;
