import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRouterLink,
  IonText
} from '@ionic/react';
import deepEqual from 'deep-equal';
import { arrowForward, close } from 'ionicons/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Page from '../components/layout/Page';
import PageTitle from '../components/layout/PageTitle';
import Nbsp from '../components/Nbsp';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { getInviteGroup, getReceivedInvites, getUserEmailAddresses } from '../selectors';
import { acceptInviteForGroup } from '../services/thunkUtils';
import { loadInvites } from '../thunks/apiThunks';
import { JSONApi, State } from '../types';

import './SettingsList.scss';
import './StartPage.scss';

interface WithFormattedMessageProps
  extends Pick<React.ComponentProps<typeof FormattedMessage>, 'id' | 'values'> {
  text?: undefined;
}
type TextProps =
  | WithFormattedMessageProps
  | {
      id?: undefined;
      text: string;
    };

const Text = ({ text, ...formattedMessageProps }: TextProps) => (
  <IonText color="primary">
    {text && text}
    {formattedMessageProps.id && <FormattedMessage {...formattedMessageProps} />}
  </IonText>
);

const H2 = (props: TextProps) => (
  <h2 className="ion-text-center">
    <Text {...props} />
  </h2>
);

const P = (props: TextProps) => (
  <p className="ion-text-center">
    <Text {...props} />
  </p>
);

type Props = {
  currentUser: JSONApi.UserResource;
};

const StartPage = ({ currentUser }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const dispatch = useThunkDispatch();
  const emailAddresses = getUserEmailAddresses(apiData, currentUser);
  const history = useHistory();
  const intl = useIntl();
  const [invites, setInvites] = useState<JSONApi.InviteResource[]>([]);

  useEffect(() => {
    dispatch(loadInvites());
  }, [dispatch]);

  useEffect(() => {
    const newInvites = getReceivedInvites(apiData, emailAddresses);
    if (!deepEqual(invites, newInvites)) {
      setInvites(getReceivedInvites(apiData, emailAddresses));
    }
  }, [apiData, emailAddresses, invites]);

  const generateHandleClickAcceptInvite = useCallback(
    (group: JSONApi.GroupResource, invite: JSONApi.InviteResource) => () => {
      acceptInviteForGroup({
        apiData,
        dispatch,
        group,
        history,
        invite,
        user: currentUser
      });
    },
    [apiData, currentUser, dispatch, history]
  );

  return (
    <Page className="centered start-page tight">
      <IonContent className="canvas">
        <PageTitle id="pages.start.title" />
        <div className="ion-padding panel">
          <IonItem className="compact" color="transparent" lines="none">
            <IonButtons slot="start">
              <IonButton
                aria-label={intl.formatMessage({ id: 'pages.start.closeButton' })}
                color="primary"
                fill="clear"
                routerLink="/logout"
              >
                <IonIcon icon={close} slot="icon-only" />
              </IonButton>
            </IonButtons>
          </IonItem>
          <h1 className="ion-text-center">
            <FormattedMessage id="pages.start.title" />
          </h1>
          <P id="pages.start.subtitle" values={{ count: invites.length }} />
          {invites.length > 0 && (
            <>
              <hr />
              <H2 id="pages.start.inviteCount" values={{ count: invites.length }} />
              <IonList className="ion-no-padding settings-list">
                {invites.map((invite, index) => {
                  const group = getInviteGroup(apiData, invite);
                  return (
                    <IonItem
                      button
                      className={`join-${group.attributes.slug}`}
                      color="light"
                      key={invite.id}
                      lines={index === invites.length - 1 ? 'none' : 'full'}
                      onClick={generateHandleClickAcceptInvite(group, invite)}
                      role="button"
                    >
                      <FormattedMessage
                        id="pages.start.inviteLabel"
                        values={{
                          count: group.attributes.memberCount,
                          group: group.attributes.name
                        }}
                      />
                    </IonItem>
                  );
                })}
              </IonList>
            </>
          )}
          <hr />
          <H2 id="pages.start.findInvite.header" />
          <p className="ion-text-center">
            <IonRouterLink routerLink="/enter-invite-code">
              <FormattedMessage id="pages.start.findInvite.link" />
            </IonRouterLink>
          </p>
          <hr />
          <H2 id="pages.start.createGroup.header" />
          <p className="ion-text-center">
            <IonButton color="danger" fill="outline" routerLink="/create" size="default">
              <FormattedMessage id="pages.start.createGroup.button" />
            </IonButton>
          </p>
          <hr />
          <div className="ion-padding ion-text-center">
            <IonRouterLink routerLink="/search">
              <IonLabel>
                <FormattedMessage id="pages.start.justBrowsing" />
                <Nbsp />
                <IonIcon icon={arrowForward} />
              </IonLabel>
            </IonRouterLink>
          </div>
        </div>
      </IonContent>
    </Page>
  );
};

export default StartPage;
