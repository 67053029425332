import { IonButton, IonLabel } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { alertError, alertSuccess } from '../actions/notificationActions';
import FormModal from '../components/FormModal';
import useMountedTracking from '../hooks/useMountedTracking';
import { Opener } from '../hooks/useOpener';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { createBlock } from '../thunks/apiThunks';
import { JSONApi } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  opener: Opener;
  record: JSONApi.BaseResource;
};

const BlockFormModal = ({ group, opener, record }: Props) => {
  const dispatch = useThunkDispatch();
  const isMounted = useMountedTracking();

  const translationScope = record.type === 'member' ? 'member' : 'content';

  const handleBlockClick = useCallback(() => {
    dispatch(createBlock(group.attributes.slug, { blockedId: record.id, blockedType: record.type }))
      .then(() => {
        if (isMounted.current) {
          opener.close();
          dispatch(alertSuccess(`forms.block.${translationScope}.success`));
        }
      })
      .catch(() => {
        dispatch(alertError(`forms.block.${translationScope}.error`));
      });
  }, [
    dispatch,
    group.attributes.slug,
    isMounted,
    opener,
    record.id,
    record.type,
    translationScope
  ]);

  return (
    <FormModal opener={opener} titleKey={`modals.newBlock.title.${translationScope}`}>
      {() => (
        <>
          <IonLabel color="dark">
            <p>
              {record.type === 'member' ? (
                <FormattedMessage
                  id="modals.newBlock.member"
                  values={{ name: (record as JSONApi.MemberResource).attributes.firstName }}
                />
              ) : (
                <FormattedMessage id="modals.newBlock.content" />
              )}
            </p>
          </IonLabel>
          <div className="ion-padding">
            <IonButton color="tertiary" expand="full" onClick={handleBlockClick}>
              <FormattedMessage id={`modals.newBlock.button.${translationScope}`} />
            </IonButton>
          </div>
        </>
      )}
    </FormModal>
  );
};

export default BlockFormModal;
