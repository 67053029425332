import {
  IonBadge,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonReorder,
  IonReorderGroup
} from '@ionic/react';
import { checkmark, repeat } from 'ionicons/icons';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { alertError, alertSuccess } from '../../actions/notificationActions';
import FormModal from '../../components/FormModal';
import EditGroupTraitForm from '../../components/forms/EditGroupTraitForm';
import FormTip from '../../components/forms/FormTip';
import NewGroupTraitForm from '../../components/forms/NewGroupTraitForm';
import OnClickLink from '../../components/OnClickLink';
import useOpener from '../../hooks/useOpener';
import useReorderer from '../../hooks/useReorderer';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { findById, getGroupTraits } from '../../selectors';
import { AjaxResponse } from '../../services/ajaxRequest';
import { forceArray } from '../../services/arrayUtils';
import { dispatchAndAlert } from '../../services/dispatchUtils';
import { deleteTrait, updateGroup } from '../../thunks/apiThunks';
import { JSONApi, Models, State } from '../../types';

import './CustomForm.scss';

type Props = {
  group: JSONApi.GroupResource;
};

const GroupTraitsForm = ({ group }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const modalOpener = useOpener();

  const [focusedTraitId, setFocusedTraitId] = useState<string | null>(null);

  const focusedTrait = useMemo<JSONApi.TraitResource | undefined>(() => {
    if (focusedTraitId) {
      return findById(apiData, 'trait', focusedTraitId);
    }
  }, [apiData, focusedTraitId]);

  const allTraits = getGroupTraits(apiData, group);

  const onReorder = useCallback(
    (resources: JSONApi.TraitResource[]) => {
      const relationships = {
        traits: { data: resources.map(({ id, type }) => ({ id, type })) }
      };
      const included = resources.map(({ attributes, id, type }) => ({
        attributes,
        id,
        relationships: {},
        type
      }));
      dispatchAndAlert(
        dispatch,
        updateGroup({}, group.id, relationships, included),
        'forms.group.update.success',
        'forms.group.update.error'
      );
    },
    [dispatch, group.id]
  );

  const reorderer = useReorderer<Models.Trait>({
    initialResources: allTraits,
    onReorder
  });

  const generateFocusTraitClickHandler = useCallback(
    (trait: JSONApi.TraitResource) => () => {
      setFocusedTraitId(trait.id);
    },
    []
  );

  const generateRemoveTraitClickHandler = useCallback(
    (trait: JSONApi.TraitResource) => () => {
      dispatch(deleteTrait(group.attributes.slug, trait))
        .then(() => {
          dispatch(alertSuccess('forms.trait.delete.success'));
          reorderer.setResources(reorderer.resources.filter(resource => resource.id !== trait.id));
          setFocusedTraitId(null);
        })
        .catch(() => {
          dispatch(alertError('forms.trait.delete.error'));
        });
    },
    [dispatch, group.attributes.slug, reorderer]
  );

  const handleFocusedTraitBackClick = useCallback(() => {
    setFocusedTraitId(null);
  }, []);

  const handleTraitFormSuccess = useCallback(
    (response: AjaxResponse<Models.Trait>) => {
      modalOpener.close();
      const newTrait = forceArray(response.data)[0];
      reorderer.setResources([...reorderer.resources, newTrait]);
      setFocusedTraitId(newTrait.id);
    },
    [modalOpener, reorderer]
  );

  // this makes sure any updates to any of the traits are reflected in local state
  const handleUpdateTraitSuccess = useCallback(
    (trait: JSONApi.TraitResource) => {
      reorderer.setResources(
        reorderer.resources.map(resource => {
          if (resource.id === trait.id) {
            return trait;
          }
          return resource;
        })
      );
    },
    [reorderer]
  );

  return (
    <>
      <IonItem className="compact" color="transparent" lines="none">
        <FormTip id="models.trait.explanation" />
      </IonItem>
      {focusedTrait ? (
        <EditGroupTraitForm
          group={group}
          onBackClick={handleFocusedTraitBackClick}
          onRemoveClick={generateRemoveTraitClickHandler(focusedTrait)}
          onSuccess={handleUpdateTraitSuccess}
          trait={focusedTrait}
        />
      ) : (
        <>
          <IonItem className="compact" color="transparent" lines="none">
            <IonLabel className="ion-no-margin settings-list-label" color="primary">
              <FormattedMessage id="models.group.relationships.traits.label" />
            </IonLabel>
            {allTraits.length > 1 && (
              <OnClickLink
                aria-label={intl.formatMessage({ id: 'dictionary.reorder' })}
                className="settings-list-link"
                onClick={reorderer.handleToggleReorder}
                slot="end"
              >
                {reorderer.isReordering ? <IonIcon icon={checkmark} /> : <IonIcon icon={repeat} />}
              </OnClickLink>
            )}
          </IonItem>
          <IonList className="ion-no-padding settings-list">
            <IonReorderGroup
              disabled={!reorderer.isReordering}
              onIonItemReorder={reorderer.handleIonItemReorder}
            >
              {reorderer.resources.map(trait => {
                const optionCount = forceArray(trait.relationships.traitOptions?.data).length;
                return (
                  <div key={trait.id}>
                    <IonItem
                      button
                      color="light"
                      onClick={generateFocusTraitClickHandler(trait)}
                      role="button"
                    >
                      <p>{trait.attributes.title}</p>
                      <IonBadge color={optionCount === 0 ? 'danger' : 'primary'} slot="end">
                        <FormattedMessage
                          id="models.trait.relationships.traitOptions.count"
                          values={{ count: optionCount }}
                        />
                      </IonBadge>
                      <IonReorder slot="end" />
                    </IonItem>
                  </div>
                );
              })}
            </IonReorderGroup>
            <IonItem
              button
              color="light"
              disabled={modalOpener.isOpen || modalOpener.isPresented}
              lines="none"
              onClick={modalOpener.open}
              role="button"
            >
              <strong>
                <FormattedMessage id="models.group.relationships.traits.add" />
              </strong>
            </IonItem>
          </IonList>
          <IonItem className="compact" color="transparent" lines="none">
            <FormTip id="models.group.relationships.traits.tip" />
          </IonItem>
          <FormModal keyboardClose={false} opener={modalOpener} titleKey="modals.addTrait.title">
            {() => (
              <NewGroupTraitForm
                autofocus={modalOpener.isPresented}
                group={group}
                onSuccess={handleTraitFormSuccess}
              />
            )}
          </FormModal>
        </>
      )}
    </>
  );
};

export default GroupTraitsForm;
