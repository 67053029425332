import { IonChip, IonLabel } from '@ionic/react';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import FormModal from '../components/FormModal';
import CreateFlagForm from '../components/forms/CreateFlagForm';
import { stringArrayDelimiter } from '../globals';
import { Opener } from '../hooks/useOpener';
import { JSONApi, ModelAttributes } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  opener: Opener;
  record: JSONApi.BaseResource;
};

const FlagFormModal = ({ group, opener, record }: Props) => {
  const intl = useIntl();
  const [activeCategory, setActiveCategory] = useState<ModelAttributes.FlagCategory | undefined>();

  const categories = intl
    .formatMessage({ id: 'models.flag.attributes.categories.options' })
    .split(stringArrayDelimiter) as ModelAttributes.FlagCategory[];

  const generateCategoryClickHandler = useCallback(
    (category: ModelAttributes.FlagCategory) => () => {
      if (categories.includes(category)) {
        setActiveCategory(category);
      }
    },
    [categories]
  );

  const generateCategoryKeyDownHandler = useCallback(
    (category: ModelAttributes.FlagCategory) =>
      (event: React.KeyboardEvent<HTMLIonChipElement>) => {
        if (event.key === 'Enter' && categories.includes(category)) {
          setActiveCategory(category);
        }
      },
    [categories]
  );

  return (
    <FormModal opener={opener} titleKey="modals.newFlag.title">
      {() => (
        <div className="flag-form">
          <IonLabel color="dark">
            <p>
              <FormattedMessage id="modals.newFlag.heading" />
            </p>
          </IonLabel>
          <div className="ion-padding-top ion-padding-bottom">
            {categories.map(category => {
              const handleClick = generateCategoryClickHandler(category);
              const handleKeyDown = generateCategoryKeyDownHandler(category);
              const color = category === activeCategory ? 'primary' : 'secondary';
              return (
                <IonChip
                  color={color}
                  key={category}
                  onClick={handleClick}
                  onKeyDown={handleKeyDown}
                  role="button"
                  tabIndex={0}
                >
                  <IonLabel>{category}</IonLabel>
                </IonChip>
              );
            })}
          </div>
          {activeCategory && (
            <CreateFlagForm
              category={activeCategory}
              group={group}
              opener={opener}
              record={record}
            />
          )}
        </div>
      )}
    </FormModal>
  );
};

export default FlagFormModal;
