import { IonBadge, IonButton, IonButtons, IonItem, IonLabel } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { alertError, alertSuccess } from '../actions/notificationActions';
import Nbsp from '../components/Nbsp';
import useMountedTracking from '../hooks/useMountedTracking';
import useOpener from '../hooks/useOpener';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { AjaxError } from '../services/ajaxRequest';
import { handleSubmissionError } from '../services/formUtils';
import { revokeInvite } from '../thunks/apiThunks';
import { JSONApi, ModelAttributes } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  invite: JSONApi.InviteResource;
};

const ModerateInvite = ({ group, invite }: Props) => {
  const actionsOpener = useOpener();
  const dispatch = useThunkDispatch();
  const isMounted = useMountedTracking();

  const { status } = invite.attributes;

  const canBeRevoked = ModelAttributes.InviteStatus.SENT === status;

  // default status color handles these situations:
  // Models.PostStatus.SENT
  let statusColor: 'danger' | 'success' | 'warning' = 'warning';
  if (ModelAttributes.InviteStatus.REVOKED === status) {
    statusColor = 'danger';
  }
  if (ModelAttributes.InviteStatus.ACCEPTED === status) {
    statusColor = 'success';
  }

  const handleClickRevoke = useCallback(() => {
    dispatch(revokeInvite(group.attributes.slug, invite))
      .then(() => {
        dispatch(alertSuccess(`forms.moderateInvite.revoke.success`));
      })
      .catch((error: AjaxError) => {
        handleSubmissionError({
          dispatch,
          error,
          handleInvalid: () => {
            dispatch(alertError(`forms.moderateInvite.revoke.error`));
          },
          isMounted
        });
      });
  }, [dispatch, group.attributes.slug, invite, isMounted]);

  return (
    <>
      <IonItem
        button
        className="ion-activatable"
        color={actionsOpener.isOpen ? 'light' : undefined}
        lines={actionsOpener.isOpen ? 'inset' : 'full'}
        onClick={actionsOpener.toggle}
        role="button"
      >
        <IonLabel>{invite.attributes.inviteeEmail}</IonLabel>
        <div slot="end">
          <IonBadge color={statusColor}>
            <FormattedMessage id={`models.invite.attributes.status.${status}Badge`} />
          </IonBadge>
        </div>
      </IonItem>
      {actionsOpener.isOpen && (
        <IonItem color="light" lines="none">
          <IonLabel>
            <FormattedMessage id="dictionary.invitedOn" />
            <Nbsp />
            {invite.attributes.createdAt.toLocaleString({
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            })}
          </IonLabel>
          {canBeRevoked && (
            <IonButtons slot="end">
              <IonButton color="success" fill="solid" onClick={handleClickRevoke}>
                <FormattedMessage id="dictionary.revoke" />
              </IonButton>
            </IonButtons>
          )}
        </IonItem>
      )}
    </>
  );
};

export default ModerateInvite;
