import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { DateTime } from 'luxon';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import DiscussionCard from '../components/DiscussionCard';
import DiscussionFormModal from '../components/DiscussionFormModal';
import ForumSearchForm from '../components/ForumSearchForm';
import NoPostResults from '../components/NoPostResults';
import PostsPage from '../components/PostsPage';
import SearchResults from '../components/SearchResults';
import useOpener from '../hooks/useOpener';
import usePageVisitTracker from '../hooks/usePageVisitTracker';
import { loadDiscussionSearch, loadMoreDiscussionSearchResults } from '../thunks/apiThunks';
import { ApiEndpoint, JSONApi, Models, Search, State } from '../types';

type Props = {
  group: JSONApi.GroupResource;
};

const ForumPage = ({ group }: Props) => {
  const [forumUpdatedAt, setForumUpdatedAt] = useState(DateTime.local());
  const forumSearchParams = useSelector((root: State.Root) => root.search.forumParams);
  const modalOpener = useOpener();
  usePageVisitTracker(group, Models.Readable.DISCUSSION);

  const anySearchesApplied = Object.values(forumSearchParams).filter(val => !!val).length > 0;

  const handleDiscussionAdded = useCallback(() => {
    setTimeout(() => {
      setForumUpdatedAt(DateTime.local());
    }, 1000);
  }, []);

  const renderSearchForm = useCallback(
    (isOpen: boolean) => (
      <div className="subheader">
        <ForumSearchForm isOpen={isOpen} />
      </div>
    ),
    []
  );

  return (
    <PostsPage
      group={group}
      onCreateClick={modalOpener.toggle}
      renderSearchForm={renderSearchForm}
      titleKey="pages.forum.title"
    >
      {pageContentRef => (
        <>
          <DiscussionFormModal
            group={group}
            onSuccess={handleDiscussionAdded}
            opener={modalOpener}
            titleKey="modals.newDiscussion.title"
          />
          <SearchResults<Models.PostSearch, Models.Discussion, Search.PostParams>
            contentRef={pageContentRef}
            group={group}
            loadDataActionCreator={loadDiscussionSearch}
            loadMoreActionCreator={loadMoreDiscussionSearchResults}
            resultsUpdatedAt={forumUpdatedAt}
            searchEndpoint={ApiEndpoint.DISCUSSION_SEARCH}
            searchParams={forumSearchParams}
            showCount={anySearchesApplied}
          >
            {results => (
              <>
                {results.length === 0 && (
                  <NoPostResults onClick={modalOpener.toggle} tKey="pages.forum.noResults" />
                )}
                {results.length > 0 && (
                  <IonGrid className="cards">
                    <IonRow>
                      {results.map(discussion => (
                        <IonCol key={discussion.id} size="12" sizeLg="4" sizeXl="4">
                          <DiscussionCard discussion={discussion} group={group} />
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonGrid>
                )}
              </>
            )}
          </SearchResults>
        </>
      )}
    </PostsPage>
  );
};

export default ForumPage;
