import { IonChip, IonIcon, IonLabel } from '@ionic/react';
import { calendar, pin } from 'ionicons/icons';
import { DateTime } from 'luxon';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import PostCard from '../components/PostCard';
import { truncateString } from '../services/stringUtils';
import { JSONApi } from '../types';

type Props = {
  event: JSONApi.EventResource;
  group: JSONApi.GroupResource;
};

const EventCard = ({ event, group }: Props) => (
  <PostCard
    body={event.attributes.description}
    group={group}
    href={`/g/${group.attributes.slug}/events/${event.attributes.slug}`}
    image={event.attributes.ogImage}
    post={event}
  >
    <div className="ion-padding-bottom ion-padding-top">
      <div>
        <IonChip color="secondary">
          <IonIcon icon={calendar} />
          <IonLabel>{event.attributes.startTime.toLocaleString(DateTime.DATE_SHORT)}</IonLabel>
        </IonChip>
      </div>
      <div>
        <IonChip color="secondary">
          <IonIcon icon={pin} />
          <IonLabel>
            {event.attributes.online ? (
              <FormattedMessage id="models.event.attributes.online.name" />
            ) : (
              truncateString(event.attributes.location, 30)
            )}
          </IonLabel>
        </IonChip>
      </div>
    </div>
  </PostCard>
);

export default EventCard;
