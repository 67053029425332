import { IonLabel } from '@ionic/react';
import equal from 'deep-equal';
import { FORM_ERROR, FormApi } from 'final-form';
import arrayMutators from 'final-form-arrays';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import BasicLink from '../../components/BasicLink';
import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormSubmit from '../../components/forms/FormSubmit';
import FormTextarea from '../../components/forms/FormTextarea';
import InviteeEmailField from '../../components/forms/InviteeEmailField';
import InviteeEmailsListField from '../../components/forms/InviteeEmailsListField';
import RequiredFieldMarker from '../../components/forms/RequiredFieldMarker';
import SubmitError from '../../components/forms/SubmitError';
import Nbsp from '../../components/Nbsp';
import {
  maxEmailLength,
  maxTextInputLength,
  minParagraphLength,
  singleEmailRegexMatcher
} from '../../globals';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { generateEmptyInvite, handleFormSubmission } from '../../services/formUtils';
import { createInvites } from '../../thunks/apiThunks';
import { JSONApi, Models } from '../../types';

import './CustomForm.scss';
import './FieldArrayForm.scss';

type FormValues = {
  invites: Models.InviteFormData[];
  note: string;
};

type SubcomponentProps = {
  autofocus: boolean;
  form: FormApi<FormValues>;
  invitesFromValues: Models.InviteFormData[];
};

const Subcomponent = ({ autofocus, form, invitesFromValues }: SubcomponentProps) => {
  const [inviteeEmails, setInviteeEmails] = useState<string[]>([]);
  const [inviteeEmailsString, setInviteeEmailsString] = useState('');
  const [uploadInBulk, setUploadInBulk] = useState(false);

  useEffect(() => {
    const emailsFromInvites = invitesFromValues
      .map(invite => invite.inviteeEmail)
      .filter(email => !!singleEmailRegexMatcher.exec(email));
    if (!equal(inviteeEmails, emailsFromInvites)) {
      if (uploadInBulk) {
        const invites = inviteeEmails.map(inviteeEmail => ({
          id: uuidv4(),
          inviteeEmail
        }));
        if (invites.length === 0) {
          invites.push({
            id: uuidv4(),
            inviteeEmail: ''
          });
        }
        form.change('invites', invites);
      } else {
        setInviteeEmailsString(emailsFromInvites.join(','));
      }
    }
  }, [inviteeEmails, invitesFromValues, form, uploadInBulk]);

  const generateHandleAddClick = useCallback(
    (push: (value: Models.InviteFormData) => void) => () => {
      push(generateEmptyInvite());
    },
    []
  );

  const handleAddManyAtOnceClick = useCallback(() => {
    setUploadInBulk(true);
  }, []);

  const handleBackClick = useCallback(() => {
    setUploadInBulk(false);
  }, []);

  if (uploadInBulk) {
    return (
      <InviteeEmailsListField
        inviteeEmails={inviteeEmails}
        inviteeEmailsString={inviteeEmailsString}
        onBackClick={handleBackClick}
        setInviteeEmails={setInviteeEmails}
        setInviteeEmailsString={setInviteeEmailsString}
      />
    );
  }

  return (
    <FieldArray<Models.InviteFormData> name="invites">
      {({ fields }) => (
        <>
          {fields.map((name, index) => (
            <InviteeEmailField
              autofocus={autofocus && index === 0}
              index={index}
              key={name}
              remove={fields.remove}
              showRemoveLink={!!fields.length && fields.length > 1}
            />
          ))}
          <div className="ion-margin-bottom ion-margin-top">
            <BasicLink
              onClick={generateHandleAddClick(fields.push)}
              tKey="forms.invites.addAnother"
            />
            <Nbsp />
            <FormattedMessage id="dictionary.or" />
            <Nbsp />
            <BasicLink onClick={handleAddManyAtOnceClick} tKey="forms.invites.addManyAtOnce" />
          </div>
        </>
      )}
    </FieldArray>
  );
};

type Props = {
  autofocus?: boolean;
  contentForwardRef?: React.RefObject<HTMLIonContentElement>;
  group: JSONApi.GroupResource;
  onSuccess?: () => void;
};

const InvitesForm = ({ autofocus = true, contentForwardRef, group, onSuccess }: Props) => {
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const initialValues = useMemo<FormValues>(
    () => ({
      invites: [generateEmptyInvite()],
      note: ''
    }),
    []
  );

  const handleSubmit = useCallback(
    async (values: FormValues, form: FormApi<FormValues>) => {
      const invites = values.invites.filter(invite => !!invite.inviteeEmail);
      if (invites && invites.length > 0) {
        return handleFormSubmission({
          action: createInvites(group.attributes.slug, values.note, invites),
          contentForwardRef,
          dispatch,
          errorTranslationLocation: 'invite',
          form,
          intl,
          isMounted,
          onSuccess,
          successTKey: 'forms.invites.create.success',
          values
        });
      }
      return Promise.resolve({
        [FORM_ERROR]: intl.formatMessage({ id: 'errors.invite.inviteeEmail.blank' })
      });
    },
    [contentForwardRef, dispatch, group.attributes.slug, intl, isMounted, onSuccess]
  );

  const validationSchema = Yup.object().shape({
    invites: Yup.array().of(
      Yup.object().shape({
        inviteeEmail: Yup.string()
          .email(intl.formatMessage({ id: 'errors.invite.inviteeEmail.badFormat' }))
          .max(
            maxEmailLength,
            intl.formatMessage(
              { id: 'errors.invite.inviteeEmail.tooLong' },
              { count: maxEmailLength }
            )
          )
      })
    ),
    note: Yup.string()
      .required(intl.formatMessage({ id: 'errors.invite.note.blank' }))
      .max(
        maxTextInputLength,
        intl.formatMessage({ id: 'errors.invite.note.tooLong' }, { count: maxTextInputLength })
      )
      .min(
        minParagraphLength,
        intl.formatMessage({ id: 'errors.invite.note.tooShort' }, { count: minParagraphLength })
      )
  });

  return (
    <div className="custom-form-wrapper field-array-form">
      <FormContainer<FormValues>
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ form, handleSubmit }) => (
          <Form<FormValues>
            className="invites-form ion-padding-top"
            onFormSubmit={handleSubmit}
            submit={form.submit}
          >
            <SubmitError />
            <IonLabel color="primary">
              <FormattedMessage id="dictionary.to" />
            </IonLabel>
            <FormSpy<FormValues> subscription={{ values: true }}>
              {({ values }) => (
                <Subcomponent
                  autofocus={autofocus}
                  form={form}
                  invitesFromValues={values.invites}
                />
              )}
            </FormSpy>
            <IonLabel color="primary">
              <FormattedMessage id="dictionary.message" />
              <RequiredFieldMarker />
            </IonLabel>
            <FormError name="note" />
            <FormTextarea
              aria-label={intl.formatMessage({
                id: 'models.invite.attributes.note.label'
              })}
              autoGrow={false}
              color="primary"
              name="note"
              placeholder={intl.formatMessage({
                id: 'models.invite.attributes.note.placeholder'
              })}
              rows={4}
            />
            <div className="ion-padding">
              <FormSpy<FormValues> subscription={{ values: true }}>
                {({ values }) => {
                  const validInvites = values.invites.filter(
                    invite => invite.inviteeEmail && invite.inviteeEmail.length > 0
                  );
                  const noInvites = validInvites.length === 0;
                  return (
                    <FormSubmit disabled={noInvites}>
                      <FormattedMessage id="dictionary.send" />
                    </FormSubmit>
                  );
                }}
              </FormSpy>
            </div>
          </Form>
        )}
      </FormContainer>
    </div>
  );
};

export default InvitesForm;
