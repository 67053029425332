import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { DateTime } from 'luxon';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import JobBoardSearchForm, { JobBoardSearchFacets } from '../components/JobBoardSearchForm';
import JobPostCard from '../components/JobPostCard';
import JobPostFormModal from '../components/JobPostFormModal';
import NoPostResults from '../components/NoPostResults';
import PostsPage from '../components/PostsPage';
import SearchResults from '../components/SearchResults';
import useOpener from '../hooks/useOpener';
import usePageVisitTracker from '../hooks/usePageVisitTracker';
import { loadJobPostSearch, loadMoreJobPostSearchResults } from '../thunks/apiThunks';
import { ApiEndpoint, JSONApi, Models, Search, State } from '../types';

type Props = {
  group: JSONApi.GroupResource;
};

const JobBoardPage = ({ group }: Props) => {
  const modalOpener = useOpener();
  const jobBoardSearchParams = useSelector((root: State.Root) => root.search.jobBoardParams);
  const [facets, setFacets] = useState<JobBoardSearchFacets>({
    companyName: [],
    industry: [],
    jobType: []
  });
  const [jobPostsUpdatedAt, setJobPostsUpdatedAt] = useState(DateTime.local());
  usePageVisitTracker(group, Models.Readable.JOB_POST);

  const anySearchesApplied = Object.values(jobBoardSearchParams).filter(val => !!val).length > 0;

  const handleFacetsLoaded = useCallback((facets: Search.FacetCollection) => {
    setFacets({
      companyName: facets.companyName ?? [],
      industry: facets.industry ?? [],
      jobType: facets.jobType ?? []
    });
  }, []);

  const handleJobPostAdded = useCallback(() => {
    setTimeout(() => {
      setJobPostsUpdatedAt(DateTime.local());
    }, 1000);
  }, []);

  const renderSearchForm = useCallback(
    (isOpen: boolean) => (
      <div className="subheader">
        <JobBoardSearchForm facets={facets} group={group} isOpen={isOpen} />
      </div>
    ),
    [facets, group]
  );

  return (
    <PostsPage
      group={group}
      onCreateClick={modalOpener.toggle}
      renderSearchForm={renderSearchForm}
      titleKey="pages.jobBoard.title"
    >
      {pageContentRef => (
        <>
          <JobPostFormModal
            group={group}
            onSuccess={handleJobPostAdded}
            opener={modalOpener}
            titleKey="modals.newJobPost.title"
          />
          <SearchResults<Models.PostSearch, Models.JobPost, Search.PostParams>
            contentRef={pageContentRef}
            group={group}
            loadDataActionCreator={loadJobPostSearch}
            loadMoreActionCreator={loadMoreJobPostSearchResults}
            onFacetsLoaded={handleFacetsLoaded}
            resultsUpdatedAt={jobPostsUpdatedAt}
            searchEndpoint={ApiEndpoint.JOB_POST_SEARCH}
            searchParams={jobBoardSearchParams}
            showCount={anySearchesApplied}
          >
            {results => (
              <>
                {results.length === 0 && (
                  <NoPostResults onClick={modalOpener.toggle} tKey="pages.jobBoard.noResults" />
                )}
                {results.length > 0 && (
                  <IonGrid className="cards">
                    <IonRow>
                      {results.map(jobPost => (
                        <IonCol key={jobPost.id} size="12" sizeLg="6" sizeXl="4">
                          <JobPostCard group={group} jobPost={jobPost} />
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonGrid>
                )}
              </>
            )}
          </SearchResults>
        </>
      )}
    </PostsPage>
  );
};

export default JobBoardPage;
