import React from 'react';
import { Route, Switch } from 'react-router';

import GlobalLoadingIndicator from '../components/GlobalLoadingIndicator';
import Notifications from '../components/Notifications';
import GroupRouter from '../components/routing/GroupRouter';
import NoGroupRouter from '../components/routing/NoGroupRouter';

const AppCore = () => (
  <>
    <Notifications />
    <GlobalLoadingIndicator />
    <Switch>
      <Route component={GroupRouter} path="/g/:groupSlug" />
      <Route component={NoGroupRouter} />
    </Switch>
  </>
);

export default AppCore;
