import { IonButton, IonContent, IonIcon } from '@ionic/react';
import { search } from 'ionicons/icons';
import React, { createRef, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Page from '../components/layout/Page';
import PageHeader from '../components/layout/PageHeader';
import MemberSearchForm from '../components/MemberSearchForm';
import MembersList from '../components/MembersList';
import SearchResults from '../components/SearchResults';
import useOpener from '../hooks/useOpener';
import usePageVisitTracker from '../hooks/usePageVisitTracker';
import { loadMemberSearch, loadMoreMemberSearchResults } from '../thunks/apiThunks';
import { ApiEndpoint, JSONApi, Models, Search, State } from '../types';

import './MembersPage.scss';

type Props = {
  group: JSONApi.GroupResource;
};

const MembersPage = ({ group }: Props) => {
  const intl = useIntl();
  const [countryFacet, setCountryFacet] = useState<Search.FacetValue[]>([]);
  const [industryFacet, setIndustryFacet] = useState<Search.FacetValue[]>([]);
  const [traitOptionIdsFacet, setTraitOptionIdsFacet] = useState<Search.FacetValue[]>([]);
  const contentRef = createRef<HTMLIonContentElement>();
  const memberSearchParams = useSelector((root: State.Root) => root.search.memberParams);
  const searchFormOpener = useOpener();
  usePageVisitTracker(group, Models.Readable.MEMBER);

  const anySearchesApplied = Object.values(memberSearchParams).filter(val => !!val).length > 0;

  const handleFacetsLoaded = useCallback((facets: Search.FacetCollection) => {
    setCountryFacet(facets.country ?? []);
    setIndustryFacet(facets.industry ?? []);
    setTraitOptionIdsFacet(facets.traitOptionIds ?? []);
  }, []);

  const searchButton = (
    <IonButton
      aria-label={intl.formatMessage({ id: 'dictionary.search' })}
      onClick={searchFormOpener.toggle}
    >
      <IonIcon icon={search} slot="icon-only" />
    </IonButton>
  );

  return (
    <Page className="centered members-page wide">
      <PageHeader
        buttonsRight={searchButton}
        defaultBackHref={`/g/${group.attributes.slug}`}
        group={group}
        titleKey="pages.members.title"
      />
      <IonContent className="canvas" ref={contentRef} scrollEvents>
        <MemberSearchForm
          countryFacet={countryFacet}
          group={group}
          industryFacet={industryFacet}
          isOpen={searchFormOpener.isOpen}
          traitOptionIdsFacet={traitOptionIdsFacet}
        />
        <SearchResults<Models.MemberSearch, Models.Member, Search.MemberParams>
          contentRef={contentRef}
          group={group}
          loadDataActionCreator={loadMemberSearch}
          loadMoreActionCreator={loadMoreMemberSearchResults}
          onFacetsLoaded={handleFacetsLoaded}
          searchEndpoint={ApiEndpoint.MEMBER_SEARCH}
          searchParams={memberSearchParams}
          showCount={anySearchesApplied}
        >
          {results => <MembersList group={group} members={results} />}
        </SearchResults>
      </IonContent>
    </Page>
  );
};

export default MembersPage;
