import { FormApi } from 'final-form';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormItem from '../../components/forms/FormItem';
import FormSubmit from '../../components/forms/FormSubmit';
import FormTextarea from '../../components/forms/FormTextarea';
import SubmitError from '../../components/forms/SubmitError';
import useMountedTracking from '../../hooks/useMountedTracking';
import { Opener } from '../../hooks/useOpener';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { handleFormSubmission } from '../../services/formUtils';
import { createFlag } from '../../thunks/apiThunks';
import { JSONApi, ModelAttributes, Models } from '../../types';

type FormValues = Models.CreateFlag;

type Props = {
  autofocus?: boolean;
  category: ModelAttributes.FlagCategory;
  group: JSONApi.GroupResource;
  opener: Opener;
  record: JSONApi.BaseResource;
};

const CreateFlagForm = ({ autofocus = true, category, group, opener, record }: Props) => {
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const initialValues: FormValues = {
    category,
    flaggedId: record.id,
    flaggedType: record.type,
    report: ''
  };

  const handleSubmit = useCallback(
    async (values: FormValues, form: FormApi<FormValues>) =>
      handleFormSubmission({
        action: createFlag(group.attributes.slug, values),
        dispatch,
        errorTranslationLocation: 'flag',
        form,
        intl,
        isMounted,
        // you might think to pass the close function as an onSuccess prop for this
        // component and skip passing the opener around, but you would be oh so wrong
        onSuccess: () => {
          opener.close();
        },
        successTKey: 'forms.flag.create.success',
        values
      }),
    [dispatch, group.attributes.slug, intl, isMounted, opener]
  );

  return (
    <FormContainer<FormValues> initialValues={initialValues} onSubmit={handleSubmit}>
      {({ form, handleSubmit }) => (
        <Form<FormValues> onFormSubmit={handleSubmit} submit={form.submit}>
          <SubmitError />
          <FormError name="report" />
          <FormItem id="models.flag.attributes.report.label">
            <FormTextarea
              autofocus={autofocus}
              name="report"
              placeholder={intl.formatMessage({
                id: 'models.flag.attributes.report.placeholder'
              })}
              rows={3}
            />
          </FormItem>
          <div className="ion-padding">
            <FormSubmit allowPristineSubmit>
              <FormattedMessage id="dictionary.send" />
            </FormSubmit>
          </div>
        </Form>
      )}
    </FormContainer>
  );
};

export default CreateFlagForm;
