import { IonIcon, IonItem, IonLabel, IonList, IonReorder, IonReorderGroup } from '@ionic/react';
import { checkmark, close, repeat } from 'ionicons/icons';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { alertError, alertSuccess } from '../../actions/notificationActions';
import FormModal from '../../components/FormModal';
import FormTip from '../../components/forms/FormTip';
import TraitOptionForm from '../../components/forms/TraitOptionForm';
import OnClickLink from '../../components/OnClickLink';
import useOpener from '../../hooks/useOpener';
import useReorderer from '../../hooks/useReorderer';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { getTraitOptions } from '../../selectors';
import { AjaxResponse } from '../../services/ajaxRequest';
import { forceArray } from '../../services/arrayUtils';
import { dispatchAndAlert } from '../../services/dispatchUtils';
import { deleteTraitOption, updateTrait } from '../../thunks/apiThunks';
import { JSONApi, Models, State } from '../../types';

type Props = {
  group: JSONApi.GroupResource;
  trait: JSONApi.TraitResource;
};

const EditTraitOptionsForm = ({ group, trait }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const modalOpener = useOpener();

  const onReorder = useCallback(
    (resources: JSONApi.TraitOptionResource[]) => {
      const relationships = {
        traitOptions: { data: resources.map(({ id, type }) => ({ id, type })) }
      };
      const included = resources.map(({ attributes, id, type }) => ({
        attributes,
        id,
        relationships: {},
        type
      }));
      dispatchAndAlert(
        dispatch,
        updateTrait(group.attributes.slug, {}, trait.id, relationships, included),
        'forms.trait.update.success',
        'forms.trait.update.error'
      );
    },
    [dispatch, group.attributes.slug, trait.id]
  );

  const traitOptions = getTraitOptions(apiData, trait);

  const reorderer = useReorderer<Models.TraitOption>({
    initialResources: traitOptions,
    onReorder
  });

  const generateRemoveTraitOptionClickHandler = useCallback(
    (traitOption: JSONApi.TraitOptionResource) => () => {
      dispatch(deleteTraitOption(group.attributes.slug, trait.id, traitOption))
        .then(() => {
          dispatch(alertSuccess('forms.traitOption.delete.success'));
          reorderer.setResources(
            reorderer.resources.filter(resource => resource.id !== traitOption.id)
          );
        })
        .catch(() => {
          dispatch(alertError('forms.traitOption.delete.error'));
        });
    },
    [dispatch, group.attributes.slug, reorderer, trait.id]
  );

  const handleTraitOptionFormSuccess = useCallback(
    (response: AjaxResponse<Models.TraitOption>) => {
      modalOpener.close();
      reorderer.setResources([...reorderer.resources, forceArray(response.data)[0]]);
    },
    [modalOpener, reorderer]
  );

  return (
    <>
      <IonItem className="compact" color="transparent" lines="none">
        <IonLabel className="ion-no-margin settings-list-label" color="primary">
          <FormattedMessage id="models.trait.relationships.traitOptions.label" />
        </IonLabel>
        {traitOptions && traitOptions.length > 1 && (
          <OnClickLink
            aria-label={intl.formatMessage({ id: 'dictionary.reorder' })}
            className="settings-list-link"
            onClick={reorderer.handleToggleReorder}
            slot="end"
          >
            {reorderer.isReordering ? <IonIcon icon={checkmark} /> : <IonIcon icon={repeat} />}
          </OnClickLink>
        )}
      </IonItem>
      <IonList className="ion-no-padding settings-list">
        <IonReorderGroup
          disabled={!reorderer.isReordering}
          onIonItemReorder={reorderer.handleIonItemReorder}
        >
          {reorderer.resources.map(traitOption => (
            <div key={traitOption.id}>
              <IonItem color="light">
                <p>{traitOption.attributes.title}</p>
                {!reorderer.isReordering && (
                  <OnClickLink
                    aria-label={intl.formatMessage({ id: 'dictionary.remove' })}
                    onClick={generateRemoveTraitOptionClickHandler(traitOption)}
                    slot="end"
                  >
                    <IonIcon icon={close} />
                  </OnClickLink>
                )}
                <IonReorder slot="end" />
              </IonItem>
            </div>
          ))}
        </IonReorderGroup>
        <IonItem
          button
          color="light"
          disabled={modalOpener.isOpen || modalOpener.isPresented}
          lines="none"
          onClick={modalOpener.open}
          role="button"
        >
          <strong>
            <FormattedMessage id="models.trait.relationships.traitOptions.add" />
          </strong>
        </IonItem>
      </IonList>
      <IonItem className="compact" color="transparent" lines="none">
        <FormTip id="models.trait.relationships.traitOptions.tip" />
      </IonItem>
      <FormModal keyboardClose={false} opener={modalOpener} titleKey="modals.addTraitOption.title">
        {() => (
          <TraitOptionForm
            autofocus={modalOpener.isPresented}
            group={group}
            onSuccess={handleTraitOptionFormSuccess}
            trait={trait}
          />
        )}
      </FormModal>
    </>
  );
};

export default EditTraitOptionsForm;
