import { IonButton, IonCol, IonContent, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import InvitesModal from '../components/InvitesModal';
import Page from '../components/layout/Page';
import PageHeader from '../components/layout/PageHeader';
import TabDataProvider from '../components/routing/TabDataProvider';
import SelectSectionTile from '../components/SelectSectionTile';
import useCanSendInvites from '../hooks/useCanSendInvites';
import useOpener from '../hooks/useOpener';
import { JSONApi } from '../types';

import './SectionSelector.scss';

type Props = {
  currentGroup: JSONApi.GroupResource;
  currentUser: JSONApi.UserResource;
};

const MoreSectionsPage = ({ currentGroup, currentUser }: Props) => {
  const canSendInvites = useCanSendInvites();
  const invitesModalOpener = useOpener();

  return (
    <Page>
      <PageHeader group={currentGroup} titleKey="pages.moreSections.title" />
      <IonContent>
        <IonGrid className="section-selector">
          {canSendInvites && (
            <IonRow>
              <IonCol className="ion-padding" size="12">
                <IonButton color="danger" expand="block" onClick={invitesModalOpener.toggle}>
                  <FormattedMessage id="dictionary.inviteMembers" />
                </IonButton>
              </IonCol>
            </IonRow>
          )}
          <InvitesModal group={currentGroup} opener={invitesModalOpener} />
          <IonRow>
            <TabDataProvider currentGroup={currentGroup} currentUser={currentUser}>
              {({ tabs }) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {tabs.map(({ icon, id, path, tKey }) => (
                    <SelectSectionTile
                      iconClassName={icon}
                      key={id}
                      routerLink={path}
                      tKey={tKey}
                    />
                  ))}
                </>
              )}
            </TabDataProvider>
          </IonRow>
        </IonGrid>
      </IonContent>
    </Page>
  );
};

export default MoreSectionsPage;
