import { IonAvatar, IonButton, IonIcon, IonLabel, IonRouterLink } from '@ionic/react';
import { documentAttach, ellipsisHorizontal } from 'ionicons/icons';
import { DateTime } from 'luxon';
import React from 'react';
import { useIntl } from 'react-intl';

import Img from '../components/Img';
import MemberAvatar from '../components/MemberAvatar';
import RelativeTimestamp from '../components/RelativeTimestamp';
import { Opener } from '../hooks/useOpener';
import { JSONApi } from '../types';

import './ChatBubble.scss';

const ChatBubble = ({
  actionSheetOpener,
  attachments,
  children,
  color = 'medium',
  group,
  isActionable = false,
  member,
  timestamp
}: React.PropsWithChildren<{
  actionSheetOpener?: Opener;
  attachments?: JSONApi.AttachmentResource[];
  attributionKey?: string;
  color?: 'light' | 'medium' | 'dark';
  group: JSONApi.GroupResource;
  isActionable?: boolean;
  member: JSONApi.MemberResource;
  timestamp: DateTime;
}>) => {
  const intl = useIntl();

  const imageAttachments = attachments?.filter(attachment => attachment.attributes.isImage);
  const otherAttachments = attachments?.filter(attachment => !attachment.attributes.isImage);

  return (
    <div className="ion-padding">
      {imageAttachments?.map(attachment => (
        <div className="chat-bubble" key={attachment.id}>
          <IonAvatar className="empty-avatar" />
          <div>
            <Img
              alt={attachment.attributes.filename}
              className="image-attachment"
              src={attachment.attributes.url}
            />
          </div>
        </div>
      ))}
      {otherAttachments && otherAttachments.length > 0 && (
        <div className="chat-bubble">
          <IonAvatar className="empty-avatar" />
          <div>
            {otherAttachments.map(attachment => (
              <IonButton
                className="flat"
                color="secondary"
                download={attachment.attributes.filename}
                href={attachment.attributes.url}
                key={attachment.id}
                rel="noopener noreferrer"
                shape="round"
                size="small"
                target="_blank"
              >
                <IonIcon icon={documentAttach} slot="start" />
                <span className="truncate">{attachment.attributes.filename}</span>
              </IonButton>
            ))}
          </div>
        </div>
      )}
      <div className={`chat-bubble color-${color}`}>
        <div>
          <IonRouterLink
            routerLink={`/g/${group.attributes.slug}/members/${member.attributes.slug}`}
          >
            <div>
              <MemberAvatar member={member} />
            </div>
          </IonRouterLink>
        </div>
        <div className="message">
          <div className="bubble">{children}</div>
          <IonLabel className="smallcaps timestamp" color="primary">
            <RelativeTimestamp date={timestamp.valueOf()} />
          </IonLabel>
        </div>
        {actionSheetOpener && isActionable && (
          <IonButton
            aria-label={intl.formatMessage({ id: 'dictionary.options' })}
            color="primary"
            fill="clear"
            onClick={actionSheetOpener.open}
          >
            <IonIcon icon={ellipsisHorizontal} />
          </IonButton>
        )}
      </div>
    </div>
  );
};

export default ChatBubble;
