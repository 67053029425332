import equal from 'deep-equal';
import { produce } from 'immer';
import { DateTime } from 'luxon';

import { ApiAction, ApiActionTypes } from '../actions/apiActions';
import { findById } from '../selectors';
import { forceArray } from '../services/arrayUtils';
import { updateOrInsertResourcesIntoState } from '../services/jsonApiUtils';
import { JSONApi, Models, State } from '../types';

const defaultResources: JSONApi.ResourceCollection<Models.Base> = {};

const defaultState: State.Api = {
  cache: {},
  meta: {},
  resources: defaultResources
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const apiReducer = (state = defaultState, action: ApiActionTypes): State.Api => {
  switch (action.type) {
    case ApiAction.APPEND_NEW_RECORD_INTO_HAS_MANY_RELATIONSHIP: {
      let resultState = state;
      const belongsToResource = action.payload.resource;
      const belongsToRelationship =
        belongsToResource.relationships[action.payload.belongsToRelationshipName];
      const belongsToData = forceArray<JSONApi.RelationshipData>(belongsToRelationship.data);
      belongsToData.forEach(relationship => {
        const hasManyResource = findById(state, relationship.type, relationship.id);
        if (hasManyResource) {
          const hasManyRelationshipName = action.payload.hasManyRelationshipName;
          resultState = produce(resultState, draft => {
            const rels = draft.resources[hasManyResource.type][hasManyResource.id].relationships;
            const rel = rels[hasManyRelationshipName];
            rel.data = forceArray(rel.data);
            const existing = rel.data.find(
              resource =>
                resource.id === belongsToResource.id && resource.type === belongsToResource.type
            );
            if (!existing) {
              rel.data.unshift({ id: belongsToResource.id, type: belongsToResource.type });
            }
          });
        }
      });
      return resultState;
    }

    case ApiAction.CLEAR_CACHED_RESPONSES_CONTAINING_RESOURCE: {
      return produce(state, draft => {
        const removeKeys = new Set<string>();
        Object.keys(draft.cache).forEach(cacheKey => {
          const cachedResponse = draft.cache[cacheKey];
          forceArray(cachedResponse.response.data).forEach(resource => {
            if (resource.id === action.payload.id && resource.type === action.payload.type) {
              removeKeys.add(cacheKey);
            }
            if (resource.relationships) {
              Object.keys(resource.relationships).forEach(relationshipName => {
                const relatedResourceIdentifiers = resource.relationships[relationshipName].data;
                forceArray(relatedResourceIdentifiers).forEach(relatedResourceIdentifier => {
                  if (equal(relatedResourceIdentifier, action.payload)) {
                    removeKeys.add(cacheKey);
                  }
                });
              });
            }
          });
        });
        removeKeys.forEach(key => {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete draft.cache[key];
        });
      });
    }

    case ApiAction.CLEAR_CACHED_RESPONSES_FOR_ENDPOINT: {
      return produce(state, draft => {
        const removeKeys = new Set<string>();
        Object.keys(draft.cache).forEach(cacheKey => {
          const cachedResponse = draft.cache[cacheKey];
          if (cachedResponse.endpoint === action.payload.endpoint) {
            removeKeys.add(cacheKey);
          }
        });
        removeKeys.forEach(key => {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete draft.cache[key];
        });
      });
    }

    case ApiAction.DELETE_RESOURCE: {
      return produce(state, draft => {
        Object.keys(draft.resources).forEach(resourceType => {
          const resourceList = draft.resources[resourceType];
          Object.keys(resourceList).forEach(resourceId => {
            const resource = resourceList[resourceId];
            if (resource.id === action.payload.id && resource.type === action.payload.type) {
              // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
              delete draft.resources[resourceType][resourceId];
            } else {
              if (resource.relationships) {
                Object.keys(resource.relationships).forEach(relationshipName => {
                  const relatedResourceIdentifiers = resource.relationships[relationshipName].data;
                  const isHasManyRelationship = relatedResourceIdentifiers instanceof Array;
                  let survivingIdentifiers: JSONApi.RelationshipData[] = [];
                  forceArray(relatedResourceIdentifiers).forEach(relatedResourceIdentifier => {
                    if (!equal(relatedResourceIdentifier, action.payload)) {
                      survivingIdentifiers = [...survivingIdentifiers, relatedResourceIdentifier];
                    }
                  });
                  draft.resources[resourceType][resourceId].relationships[relationshipName].data =
                    isHasManyRelationship ? survivingIdentifiers : survivingIdentifiers[0] || null;
                });
              }
            }
          });
        });
      });
    }

    case ApiAction.SUCCESS: {
      const response = action.payload.response;
      let resources: JSONApi.BaseResource[] = forceArray(response.data);

      if (response.included) {
        resources = resources.concat(response.included);
      }

      const cache = {
        endpoint: action.payload.endpoint,
        response: action.payload.response,
        responseCode: action.payload.status,
        timestamp: DateTime.utc()
      };

      let newState = updateOrInsertResourcesIntoState(state, resources);
      newState = produce(newState, draft => {
        draft.meta[action.payload.endpoint] = {
          ...draft.meta[action.payload.endpoint],
          links: response.links
        };
        if (action.payload.cacheKey) {
          draft.cache[action.payload.cacheKey] = cache;
        }
      });

      return newState;
    }

    default:
      return state;
  }
};
