import { DateTime } from 'luxon';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router';

import PermanentAlert from '../components/PermanentAlert';
import useCurrentMember from '../hooks/useCurrentMember';
import { isPlanAwaitingCancellation } from '../services/planUtils';
import { JSONApi, ModelAttributes } from '../types';

type Props = {
  group: JSONApi.GroupResource | null | undefined;
};

const PlanAlerts = ({ group }: Props) => {
  const currentMember = useCurrentMember();
  const history = useHistory();
  const location = useLocation();

  const onPlanPage = /\/g\/[^/]*\/manage-group\/plan/.exec(location.pathname);

  const handleFixPlanClick = useCallback(() => {
    if (group) {
      history.push(`/g/${group.attributes.slug}/manage-group/plan`);
    }
  }, [group, history]);

  if (!group) {
    return null;
  }

  // If you are the owner, we warn you if the plan is about to be canceled,
  // if there is no plan, or if the group is at max capacity.
  if (currentMember?.attributes.role === ModelAttributes.MemberRole.OWNER) {
    if (isPlanAwaitingCancellation(group)) {
      const alertProps: React.ComponentPropsWithoutRef<typeof PermanentAlert> = {
        color: 'danger',
        id: 'models.plan.warnings.cancellationEffectiveSoon',
        values: {
          effectiveDate: group.attributes.planCancellationEffective?.toLocaleString(
            DateTime.DATE_FULL
          )
        }
      };
      if (!onPlanPage) {
        alertProps.buttonProps = {
          children: <FormattedMessage id="dictionary.reactivate" />,
          color: 'success',
          onClick: handleFixPlanClick
        };
      }
      return <PermanentAlert {...alertProps} />;
    }

    if (group.attributes.readOnly) {
      const alertProps: React.ComponentPropsWithoutRef<typeof PermanentAlert> = {
        color: 'danger',
        id: 'models.plan.warnings.readOnlyForOwner'
      };

      if (!onPlanPage) {
        alertProps.buttonProps = {
          children: <FormattedMessage id="models.plan.warnings.selectAPlan" />,
          color: 'success',
          onClick: handleFixPlanClick
        };
      }

      return <PermanentAlert {...alertProps} />;
    }

    if (group.attributes.isAtMaxCapacity) {
      const alertProps: React.ComponentPropsWithoutRef<typeof PermanentAlert> = {
        color: 'danger',
        id: 'models.group.attributes.isAtMaxCapacity.explanation'
      };

      if (!onPlanPage) {
        alertProps.buttonProps = {
          children: <FormattedMessage id="models.plan.warnings.upgradeYourPlan" />,
          color: 'success',
          onClick: handleFixPlanClick
        };
      }

      return <PermanentAlert {...alertProps} />;
    }
  }

  // If the group is read-only, let everyone know.
  if (group.attributes.readOnly) {
    return <PermanentAlert color="danger" id="models.plan.warnings.readOnly" />;
  }

  // If you are a moderator, we will warn you if the group is at its member cap. This is
  // separate from the warning the owner gets because the moderator cannot do anything about it.
  if (currentMember?.attributes.role === ModelAttributes.MemberRole.MODERATOR) {
    if (group.attributes.isAtMaxCapacity) {
      return (
        <PermanentAlert color="danger" id="models.group.attributes.isAtMaxCapacity.explanation" />
      );
    }
  }

  return null;
};

export default PlanAlerts;
