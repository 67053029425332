import * as Sentry from '@sentry/browser';
import loadImage from 'blueimp-load-image';
import { useEffect, useState } from 'react';

import useMountedTracking from '../hooks/useMountedTracking';
import { orientedImageToDataURL } from '../services/imageUtils';

const useImageOrienter = (originalSrc: string) => {
  const isMounted = useMountedTracking();
  const [isProcessing, setIsProcessing] = useState(true);
  const [src, setSrc] = useState(originalSrc);

  useEffect(() => {
    if (originalSrc) {
      fetch(originalSrc)
        .then(response => {
          if (isMounted.current) {
            response
              .blob()
              .then(imgBlob => {
                if (isMounted.current) {
                  loadImage(
                    imgBlob,
                    orientedImg => {
                      if (isMounted.current) {
                        const dataURL = orientedImageToDataURL(orientedImg);
                        if (dataURL) {
                          setSrc(dataURL);
                        }
                        setIsProcessing(false);
                      }
                    },
                    { noRevoke: true, orientation: true }
                  );
                }
              })
              .catch(error => {
                setIsProcessing(false);
                Sentry.captureException(error);
              });
          }
        })
        .catch(error => {
          setIsProcessing(false);
          Sentry.captureException(error);
        });
    } else {
      setIsProcessing(false);
    }
  }, [isMounted, originalSrc]);

  return { isProcessing, src };
};

export default useImageOrienter;
