import React, { useCallback } from 'react';
import { Route, Switch } from 'react-router';

import AdminAttachmentsPage from '../../components/AdminAttachmentsPage';
import AdminGroupPage from '../../components/AdminGroupPage';
import AdminGroupsPage from '../../components/AdminGroupsPage';
import AdminHomePage from '../../components/AdminHomePage';
import AdminImagesPage from '../../components/AdminImagesPage';
import RequireAdmin from '../../components/RequireAdmin';

const AdminRoutes = () => {
  const renderAdminAttachmentsPage = useCallback(
    () => <RequireAdmin>{() => <AdminAttachmentsPage />}</RequireAdmin>,
    []
  );

  const renderAdminGroupsPage = useCallback(
    () => <RequireAdmin>{() => <AdminGroupsPage />}</RequireAdmin>,
    []
  );

  const renderAdminGroupPage = useCallback(
    () => <RequireAdmin>{() => <AdminGroupPage />}</RequireAdmin>,
    []
  );

  const renderAdminHomePage = useCallback(
    () => <RequireAdmin>{() => <AdminHomePage />}</RequireAdmin>,
    []
  );

  const renderAdminImagesPage = useCallback(
    () => <RequireAdmin>{() => <AdminImagesPage />}</RequireAdmin>,
    []
  );

  return (
    <Switch>
      <Route exact path="/admin" render={renderAdminHomePage} />
      <Route path="/admin/attachments" render={renderAdminAttachmentsPage} />
      <Route path="/admin/groups/:slug" render={renderAdminGroupPage} />
      <Route path="/admin/groups" render={renderAdminGroupsPage} />
      <Route path="/admin/images" render={renderAdminImagesPage} />
    </Switch>
  );
};

export default AdminRoutes;
