import { FormApi } from 'final-form';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormInput from '../../components/forms/FormInput';
import FormItem from '../../components/forms/FormItem';
import FormSubmit from '../../components/forms/FormSubmit';
import SubmitError from '../../components/forms/SubmitError';
import { maxTextInputLength } from '../../globals';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { AjaxResponse } from '../../services/ajaxRequest';
import { handleFormSubmission } from '../../services/formUtils';
import { createLink } from '../../thunks/apiThunks';
import { JSONApi, Models } from '../../types';

type FormValues = Models.LinkFormData;

type Props = {
  autofocus: boolean;
  group: JSONApi.GroupResource;
  onSuccess?: (response: AjaxResponse<Models.Link>) => void;
  owner: JSONApi.BaseResource;
};

const LinkForm = ({ autofocus, group, onSuccess, owner }: Props) => {
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const initialValues: FormValues = {
    label: '',
    uri: ''
  };

  const handleSubmit = useCallback(
    async (values: FormValues, form: FormApi<FormValues>) =>
      handleFormSubmission({
        action: createLink(group.attributes.slug, values, owner),
        dispatch,
        errorTranslationLocation: 'link',
        form,
        intl,
        isMounted,
        onSuccess,
        successTKey: 'forms.link.create.success',
        values
      }),
    [dispatch, group.attributes.slug, intl, isMounted, onSuccess, owner]
  );

  const validationSchema = Yup.object().shape({
    label: Yup.string()
      .nullable()
      .max(
        maxTextInputLength,
        intl.formatMessage({ id: 'errors.link.label.tooLong' }, { count: maxTextInputLength })
      ),
    uri: Yup.string()
      .required(intl.formatMessage({ id: 'errors.link.uri.blank' }))
      .max(
        maxTextInputLength,
        intl.formatMessage({ id: 'errors.link.uri.tooLong' }, { count: maxTextInputLength })
      )
  });

  return (
    <FormContainer<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ form, handleSubmit }) => (
        <Form<FormValues> className="link-form" onFormSubmit={handleSubmit} submit={form.submit}>
          <SubmitError />
          <FormError name="label" />
          <FormItem id="models.link.attributes.label.label">
            <FormInput
              autocapitalize="on"
              autofocus={autofocus}
              name="label"
              placeholder={intl.formatMessage({
                id: 'models.link.attributes.label.placeholder'
              })}
            />
          </FormItem>
          <FormError name="uri" />
          <FormItem id="models.link.attributes.uri.label" required>
            <FormInput
              name="uri"
              placeholder={intl.formatMessage({ id: 'models.link.attributes.uri.placeholder' })}
            />
          </FormItem>
          <div className="ion-padding">
            <FormSubmit>
              <FormattedMessage id="dictionary.add" />
            </FormSubmit>
          </div>
        </Form>
      )}
    </FormContainer>
  );
};

export default LinkForm;
