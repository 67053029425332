import { IonButton, IonContent, IonIcon } from '@ionic/react';
import { search } from 'ionicons/icons';
import React, { createRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import ConversationList from '../components/ConversationList';
import InboxSearchForm from '../components/InboxSearchForm';
import Page from '../components/layout/Page';
import PageHeader from '../components/layout/PageHeader';
import SearchResults from '../components/SearchResults';
import useOpener from '../hooks/useOpener';
import { loadConversationSearch, loadMoreConversationSearchResults } from '../thunks/apiThunks';
import { ApiEndpoint, JSONApi, Models, Search, State } from '../types';

type Props = {
  group: JSONApi.GroupResource;
};

const InboxPage = ({ group }: Props) => {
  const intl = useIntl();
  const searchFormOpener = useOpener();
  const conversationSearchParams = useSelector(
    (root: State.Root) => root.search.conversationParams
  );
  const contentRef = createRef<HTMLIonContentElement>();

  const anySearchesApplied =
    Object.values(conversationSearchParams).filter(val => !!val).length > 0;

  const searchButton = (
    <IonButton
      aria-label={intl.formatMessage({ id: 'dictionary.search' })}
      onClick={searchFormOpener.toggle}
    >
      <IonIcon icon={search} slot="icon-only" />
    </IonButton>
  );

  return (
    <Page className="centered inbox-page wide">
      <PageHeader
        buttonsRight={searchButton}
        defaultBackHref={`/g/${group.attributes.slug}`}
        group={group}
        titleKey="pages.inbox.title"
      />
      <IonContent className="canvas" ref={contentRef} scrollEvents>
        <InboxSearchForm isOpen={searchFormOpener.isOpen} />
        <SearchResults<Models.ConversationSearch, Models.Conversation, Search.ConversationParams>
          contentRef={contentRef}
          group={group}
          loadDataActionCreator={loadConversationSearch}
          loadMoreActionCreator={loadMoreConversationSearchResults}
          searchEndpoint={ApiEndpoint.CONVERSATION_SEARCH}
          searchParams={conversationSearchParams}
          showCount={anySearchesApplied}
        >
          {results => <ConversationList conversations={results} group={group} />}
        </SearchResults>
      </IonContent>
    </Page>
  );
};

export default InboxPage;
