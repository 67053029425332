import React from 'react';
import { FormattedMessage } from 'react-intl';

import BlockedItem from '../components/BlockedItem';
import MemberAvatar from '../components/MemberAvatar';
import useConversation from '../hooks/useConversation';
import { JSONApi } from '../types';

type Props = {
  conversation: JSONApi.ConversationResource;
  isLast: boolean;
  onClick: () => void;
};

const BlockedConversationItem = ({ conversation, isLast, onClick }: Props) => {
  const { counterparty, creator, recipient } = useConversation(conversation);

  return (
    <BlockedItem isLast={isLast} onClick={onClick}>
      <MemberAvatar member={creator} slot="start" />
      <MemberAvatar member={recipient} slot="start" />
      {counterparty && (
        <FormattedMessage
          id={`models.conversation.attributes.threadType.${conversation.attributes.threadType}.title`}
          values={{ counterparty: counterparty.attributes.firstName }}
        />
      )}
    </BlockedItem>
  );
};

export default BlockedConversationItem;
