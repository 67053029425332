import { useCallback } from 'react';

type Args<T> = {
  onChange: (event: Event) => void;
  onIonChange?: (event: T) => void;
};

const useMergeOnChange = <T extends CustomEvent>({ onChange, onIonChange }: Args<T>) => {
  const handleIonChange = useCallback(
    (event: T) => {
      onChange(event);
      if (onIonChange) {
        onIonChange(event);
      }
    },
    [onChange, onIonChange]
  );

  return handleIonChange;
};

export default useMergeOnChange;
