import { decrementGlobalLoading, incrementGlobalLoading } from '../actions/loadingActions';
import { alertSuccess } from '../actions/notificationActions';
import {
  clearSession,
  setAuthToken,
  setCurrentGroupData,
  setCurrentUserData
} from '../actions/sessionActions';
import { findById, findResource } from '../selectors';
import {
  AfterRequestSuccessCallback,
  dispatchableRequest,
  DispatchableRequestArgs
} from '../services/ajaxRequest';
import { forceArray } from '../services/arrayUtils';
import { apiPath } from '../services/urlUtils';
import { createPushNotificationToken, loadGroup, loadUser } from '../thunks/apiThunks';
import { prefixInclusions, sessionInclusions, userInclusions } from '../thunks/inclusions';
import { Actions, ApiEndpoint, JSONApi, Models, SessionState, State } from '../types';

export const setGroupSlug =
  (slug: string | null) =>
  (dispatch: Actions.ThunkDispatch, getState: () => State.Root): void => {
    const oldSlug = getState().session.currentGroup.slug;
    if (!slug || oldSlug !== slug) {
      if (slug) {
        const preloadedGroup = findResource<Models.Group>(
          getState().api,
          'group',
          'slug',
          slug,
          null
        );
        if (preloadedGroup) {
          dispatch(setCurrentGroupData(slug, SessionState.SUCCESS));
        } else {
          dispatch(incrementGlobalLoading('setGroupSlug'));
          dispatch(setCurrentGroupData(slug, SessionState.LOADING));
          dispatch(loadGroup(slug))
            .then(response => {
              response.dataLoadedIntoStatePromise.then(() => {
                dispatch(decrementGlobalLoading('setGroupSlug'));
                dispatch(setCurrentGroupData(slug, SessionState.SUCCESS));
              });
            })
            .catch(() => {
              dispatch(decrementGlobalLoading('setGroupSlug'));
              dispatch(setCurrentGroupData(slug, SessionState.FAILED));
            });
        }
      } else {
        dispatch(setCurrentGroupData(null, SessionState.NONE));
      }
    }
  };

export const setUserId =
  (id: string | null) =>
  (dispatch: Actions.ThunkDispatch, getState: () => State.Root): void => {
    const oldId = getState().session.currentUser.id;
    if (!id || oldId !== id) {
      if (id) {
        const preloadedUser = findById<Models.User>(getState().api, 'user', id);
        if (preloadedUser) {
          dispatch(setCurrentUserData(id, SessionState.SUCCESS));
        } else {
          dispatch(incrementGlobalLoading('setUserId'));
          dispatch(setCurrentUserData(id, SessionState.LOADING));
          dispatch(loadUser(id))
            .then(response => {
              response.dataLoadedIntoStatePromise.then(() => {
                dispatch(decrementGlobalLoading('setUserId'));
                dispatch(setCurrentUserData(id, SessionState.SUCCESS));
              });
            })
            .catch(() => {
              dispatch(decrementGlobalLoading('setUserId'));
              dispatch(setCurrentUserData(id, SessionState.FAILED));
            });
        }
      } else {
        dispatch(setCurrentUserData(null, SessionState.NONE));
      }
    }
  };

const handleSessionResponse: AfterRequestSuccessCallback<Models.Session> = ({
  dispatch,
  getState,
  responseBody
}) => {
  const sessionResource = forceArray(responseBody.data)[0];
  const newAuthToken = sessionResource.id;

  // this function knows a bit too much about the return value,
  // but there wasn't an easy way around it
  const newUser = responseBody.included.filter(
    item => item.type === 'user'
  )[0] as JSONApi.UserResource;

  dispatch(setAuthToken(newAuthToken));
  dispatch(setCurrentUserData(newUser.id, SessionState.SUCCESS));
  dispatch(alertSuccess('forms.login.success'));

  const pushToken = getState().session.pushNotificationToken;
  if (pushToken) {
    dispatch(createPushNotificationToken(pushToken)).catch(() => {
      // An error here probably means that we already added the same token to the
      // user, but if it means something else then it's also ignorable
      // because we will try to send it again later since the token is set over and over.
    });
  }
};

export const login = (attributes: Models.Session) => {
  const data: JSONApi.CreateData<Models.Session> = {
    data: {
      attributes,
      type: 'session'
    },
    include: prefixInclusions('user', userInclusions).join(',')
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Base> = {
    afterSuccess: handleSessionResponse,
    data,
    endpoint: ApiEndpoint.LOGIN,
    eventCategory: 'Session',
    eventName: 'Login',
    method: 'POST',
    pathname: apiPath(undefined, 'sessions')
  };

  return dispatchableRequest(dispatchableRequestArgs);
};

export const extendSession = () => {
  const afterSuccess: AfterRequestSuccessCallback<Models.Session> = ({
    dispatch,
    responseBody
  }) => {
    const sessionResource = forceArray(responseBody.data)[0];
    const newAuthToken = sessionResource.id;
    dispatch(setAuthToken(newAuthToken));
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Base> = {
    afterSuccess,
    endpoint: ApiEndpoint.EXTEND_SESSION,
    eventCategory: 'Session',
    eventName: 'Extend',
    method: 'PATCH',
    pathname: apiPath(undefined, 'sessions/extend')
  };

  return dispatchableRequest(dispatchableRequestArgs);
};

export const loadSession = () => {
  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Base> = {
    afterSuccess: handleSessionResponse,
    endpoint: ApiEndpoint.LOAD_SESSION,
    eventCategory: 'Session',
    eventName: 'Show',
    method: 'GET',
    params: { include: sessionInclusions.join(',') },
    pathname: apiPath(undefined, 'session')
  };

  return dispatchableRequest(dispatchableRequestArgs);
};

export const logout =
  () =>
  (dispatch: Actions.ThunkDispatch): void => {
    dispatch(clearSession());
  };
