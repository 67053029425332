import { Capacitor } from '@capacitor/core';
import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { loggerMiddleware } from './loggerMiddleware';

const createMiddlewares = () => {
  if (import.meta.env.VITE_LOG_REDUX && Capacitor.getPlatform() === 'web') {
    return applyMiddleware(thunk, loggerMiddleware);
  }
  return applyMiddleware(thunk);
};

export default createMiddlewares;
