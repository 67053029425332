import React from 'react';
import { useSelector } from 'react-redux';

import PostCard from '../components/PostCard';
import { getPostImage } from '../selectors';
import { JSONApi, State } from '../types';

type Props = {
  discussion: JSONApi.DiscussionResource;
  group: JSONApi.GroupResource;
};

const DiscussionCard = ({ discussion, group }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const image = getPostImage(apiData, discussion);

  return (
    <PostCard
      body={discussion.attributes.description}
      group={group}
      href={`/g/${group.attributes.slug}/forum/${discussion.attributes.slug}`}
      image={image?.attributes?.url ?? discussion.attributes.ogImage}
      post={discussion}
    />
  );
};

export default DiscussionCard;
