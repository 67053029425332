// follows: https://capacitor.ionicframework.com/docs/guides/deep-links#react

import { App as CapApp, URLOpenListenerEvent } from '@capacitor/app';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const AppUrlListener = () => {
  const history = useHistory();

  useEffect(() => {
    CapApp.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      // Example url: https://app.grouplicate.com/path/to/content
      // slug: /path/to/content
      const slug = event.url.split('.com').pop();
      if (slug) {
        history.push(slug);
      }
      // If no match, do nothing - let regular routing logic take over
    });
  }, [history]);

  return null;
};

export default AppUrlListener;
