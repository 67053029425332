import { Cable, Channel, ChannelNameWithParams } from 'actioncable';
import equal from 'deep-equal';
import { useCallback, useContext, useEffect, useRef } from 'react';

import { ActionCableContext } from '../components/ActionCableProvider';
import { JSONApi, Models } from '../types';

type UseActionCableChannelArgs = {
  channelName: ChannelNameWithParams;
  onConnected?: () => void;
  onDisconnected?: () => void;
  onReceived?: (update: JSONApi.Response<Models.BroadcastUpdate>) => void;
};

const useActionCableChannel = (args: UseActionCableChannelArgs) => {
  const { channelName, onConnected, onDisconnected, onReceived } = args;
  const cable = useContext<Cable | undefined>(ActionCableContext);
  const channelRef = useRef<Channel | undefined>(undefined);
  const connectedChannelName = useRef<ChannelNameWithParams | undefined>();
  const localMounted = useRef<boolean>(true);

  const subscribe = useCallback(
    (cable: Cable, channelName: ChannelNameWithParams) => {
      const newChannel = cable.subscriptions.create(channelName, {
        connected: () => {
          if (onConnected) {
            onConnected();
          }
        },
        disconnected: () => {
          if (onDisconnected) {
            onDisconnected();
          }
        },
        received: (update: JSONApi.Response<Models.BroadcastUpdate>) => {
          if (onReceived) {
            onReceived(update);
          }
        }
      });

      channelRef.current = newChannel;
      connectedChannelName.current = { ...channelName };
    },
    [onConnected, onDisconnected, onReceived]
  );

  const unsubscribe = useCallback(() => {
    channelRef.current?.unsubscribe();
    channelRef.current = undefined;
    connectedChannelName.current = undefined;
  }, []);

  useEffect(() => {
    if (cable && channelName && localMounted.current && !channelRef.current) {
      subscribe(cable, channelName);
    } else if (channelRef.current && !equal(channelName, connectedChannelName.current)) {
      unsubscribe();
    }
  }, [cable, channelName, channelRef, connectedChannelName, localMounted, subscribe, unsubscribe]);

  useEffect(
    () => () => {
      unsubscribe();
      localMounted.current = false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};

export default useActionCableChannel;
