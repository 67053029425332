import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { alertError } from '../../actions/notificationActions';
import EditAvatar from '../../components/forms/EditAvatar';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { AjaxError } from '../../services/ajaxRequest';
import { forceArray } from '../../services/arrayUtils';
import { randomAvatarSrc } from '../../services/randomAvatarSrc';
import { updateGroup } from '../../thunks/apiThunks';
import { JSONApi, Models } from '../../types';

type Props = {
  group: JSONApi.GroupResource;
};

const EditGroupAvatar = ({ group }: Props) => {
  const intl = useIntl();
  const isMounted = useMountedTracking();
  const [isUpdating, setIsUpdating] = useState(false);
  const [src, setSrc] = useState(group.attributes.navIconUrl);
  const dispatch = useThunkDispatch();

  const alt = intl.formatMessage(
    { id: 'models.group.attributes.navIcon.alt' },
    { name: group.attributes.name }
  );

  const srcWithBackup = src ?? randomAvatarSrc(group.attributes.slug);

  const handleFail = useCallback(
    (errors?: JSONApi.Error[] | string) => {
      setIsUpdating(false);
      dispatch(alertError('errors.group.navIcon.default'));
      if (errors) {
        // eslint-disable-next-line no-console
        console.log(errors);
      }
    },
    [dispatch]
  );

  const updateAvatar = useCallback(
    (imageDataUrl: string) => {
      dispatch(updateGroup({ navIcon: imageDataUrl }, group.id))
        .then((response: JSONApi.Response<Models.Group>) => {
          if (isMounted.current) {
            const newAvatarImage = forceArray(response.data)[0].attributes.navIconUrl;
            setSrc(newAvatarImage);
            setIsUpdating(false);
          }
        })
        .catch((e: AjaxError) => {
          if (isMounted.current) {
            handleFail(e.errors());
          }
        });
    },
    [dispatch, isMounted, handleFail, group.id]
  );

  return (
    <EditAvatar
      alt={alt}
      isUpdating={isUpdating}
      onFail={handleFail}
      setIsUpdating={setIsUpdating}
      src={srcWithBackup}
      updateAvatar={updateAvatar}
    />
  );
};

export default EditGroupAvatar;
