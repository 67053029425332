import { IonContent, IonItem, IonLabel, IonList } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import GroupAvatar from '../components/GroupAvatar';
import Page from '../components/layout/Page';
import PageHeader from '../components/layout/PageHeader';
import PageTitle from '../components/layout/PageTitle';
import Paragraph from '../components/Paragraph';
import { getMemberGroup, getUserMembers } from '../selectors';
import { JSONApi, State } from '../types';

import './SettingsList.scss';

type Props = {
  currentUser: JSONApi.UserResource;
};

const NotificationsPage = ({ currentUser }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);

  const members = getUserMembers(apiData, currentUser).sort((a, b) => {
    const groupA = getMemberGroup(apiData, a);
    const groupB = getMemberGroup(apiData, b);
    return groupA.attributes.name.toLowerCase() > groupB.attributes.name.toLowerCase() ? 1 : -1;
  });

  return (
    <Page className="centered tight">
      <PageHeader defaultBackHref="/me-and-my-data" />
      <PageTitle id="pages.notifications.title" />
      <IonContent className="canvas ion-padding">
        <div>
          <h1 className="ion-no-margin ion-padding">
            <FormattedMessage id="pages.notifications.title" />
          </h1>
          <IonList className="ion-no-padding settings-list">
            <IonItem lines="none">
              <Paragraph>
                <FormattedMessage id="pages.notifications.note" />
              </Paragraph>
            </IonItem>
            {members.length > 0 ? (
              members.map((member, index) => {
                const group = getMemberGroup(apiData, member);
                const isLast = index === members.length - 1;
                return (
                  <IonItem
                    button
                    key={member.id}
                    lines={isLast ? 'none' : 'inset'}
                    role="button"
                    routerLink={`/me-and-my-data/notifications/${group.attributes.slug}`}
                  >
                    <GroupAvatar group={group} slot="start" />
                    <IonLabel>{group.attributes.name}</IonLabel>
                  </IonItem>
                );
              })
            ) : (
              <IonItem lines="none">
                <Paragraph color="primary">
                  <FormattedMessage id="pages.notifications.noGroups" />
                </Paragraph>
              </IonItem>
            )}
          </IonList>
        </div>
      </IonContent>
    </Page>
  );
};

export default NotificationsPage;
