import { IonButton, IonButtons, IonItem, IonProgressBar } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import { WizardSteps } from '../components/CreateGroupPage';
import FormSubmit from '../components/forms/FormSubmit';
import SetupAccountForm from '../components/forms/SetupAccountForm';
import Heading from '../components/Heading';
import Paragraph from '../components/Paragraph';
import { JSONApi } from '../types';

type Props = {
  currentUser: JSONApi.UserResource;
  group: JSONApi.GroupResource;
};

// the name of this component is a little weird. it means that you are still in the group
// creation wizard, but you are setting up your account, since you need to have a finished
// user account by the time you finish creating your group
const SetupGroupAccount = ({ currentUser, group }: Props) => {
  const history = useHistory();

  const handleBackClick = useCallback(() => {
    history.push({ pathname: `/create/${group.attributes.slug}/${WizardSteps.FEATURES}` });
  }, [group.attributes.slug, history]);

  const handleSuccess = useCallback(() => {
    history.push(`/create/${group.attributes.slug}/${WizardSteps.PAYMENT}`);
  }, [group.attributes.slug, history]);

  return (
    <>
      <IonProgressBar buffer={0.7} color="success" value={0} />
      <Heading level={2}>
        <FormattedMessage id="pages.groupWizard.account.title" />
      </Heading>
      <Paragraph>
        <FormattedMessage id="pages.groupWizard.account.description" />
      </Paragraph>
      <SetupAccountForm currentUser={currentUser} group={group} onSuccess={handleSuccess}>
        <IonItem color="transparent" lines="none">
          <IonButtons slot="end">
            <IonButton color="primary" fill="outline" onClick={handleBackClick} size="default">
              <FormattedMessage id="dictionary.previous" />
            </IonButton>
            <FormSubmit allowPristineSubmit color="primary" fill="solid" size="default">
              <FormattedMessage id="dictionary.next" />
            </FormSubmit>
          </IonButtons>
        </IonItem>
      </SetupAccountForm>
    </>
  );
};

export default SetupGroupAccount;
