import { IonCol, IonGrid, IonItem, IonRow, IonText } from '@ionic/react';
import React from 'react';

import EditUserAvatar from '../../components/forms/EditUserAvatar';
import { JSONApi } from '../../types';

import './AccountTopItem.scss';

type Props = {
  currentUser: JSONApi.UserResource;
};

const AccountTopItem = ({ currentUser }: Props) => (
  <IonItem className="account-top-item" color="transparent">
    <IonGrid>
      <IonRow>
        <IonCol size="auto">
          <EditUserAvatar user={currentUser} />
        </IonCol>
        <IonCol>
          <IonText className="name-section">
            <h2>{currentUser.attributes.fullName}</h2>
            <p>{`@${currentUser.attributes.slug}`}</p>
          </IonText>
        </IonCol>
      </IonRow>
    </IonGrid>
  </IonItem>
);

export default AccountTopItem;
