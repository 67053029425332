import * as Sentry from '@sentry/browser';
import React, { ErrorInfo } from 'react';
import { connect } from 'react-redux';

import { alertError } from '../actions/notificationActions';
import { Actions } from '../types';

const mapDispatchToProps = (dispatch: Actions.ThunkDispatch) => ({
  alertError: (tKey: string) => dispatch(alertError(tKey))
});

interface DispatchType {
  alertError: (tKey: string) => void;
}

/* eslint-disable no-restricted-syntax */
class ErrorBoundary extends React.Component<React.PropsWithChildren<DispatchType>> {
  public componentDidCatch(error: Error, _errorInfo: ErrorInfo) {
    const { alertError } = this.props;
    alertError('errors.genericFailure');
    Sentry.captureException(error);
  }

  public static getDerivedStateFromError(_error: Error) {
    return {};
  }

  public render() {
    const { children } = this.props;
    return children;
  }

  public shouldComponentUpdate() {
    // need to rerender whenever props change
    return true;
  }
}
/* eslint-enable */

export default connect(undefined, mapDispatchToProps)(ErrorBoundary);
