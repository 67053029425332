import { IonBadge, IonButton, IonButtons, IonItem, IonLabel, IonList } from '@ionic/react';
import { DateTime } from 'luxon';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { alertError, alertSuccess } from '../actions/notificationActions';
import MemberAvatar from '../components/MemberAvatar';
import Nbsp from '../components/Nbsp';
import OnClickLink from '../components/OnClickLink';
import useMountedTracking from '../hooks/useMountedTracking';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { getFlagFlagger, getFlaggedItemFlags } from '../selectors';
import { AjaxError } from '../services/ajaxRequest';
import { handleSubmissionError } from '../services/formUtils';
import { ignoreFlaggedItem, rejectFlaggedItem } from '../thunks/apiThunks';
import { JSONApi, State } from '../types';

import './ChatBubble.scss';

type Props = {
  flaggedItem: JSONApi.FlaggedItemResource;
  flaggedItemAuthor: JSONApi.MemberResource;
  group: JSONApi.GroupResource;
  rejectErrorKey: string;
  rejectSuccessKey: string;
  renderFlaggedContent: () => React.ReactNode;
  typeBadgeLabel: string;
  viewFlaggedLink: string;
};

const ModerateItemFlags = ({
  flaggedItem,
  flaggedItemAuthor,
  group,
  rejectErrorKey,
  rejectSuccessKey,
  renderFlaggedContent,
  typeBadgeLabel,
  viewFlaggedLink
}: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const flags = getFlaggedItemFlags(apiData, flaggedItem);

  const handleClickDismiss = useCallback(() => {
    dispatch(ignoreFlaggedItem(group.attributes.slug, flaggedItem.id))
      .then(() => {
        dispatch(alertSuccess('forms.moderateFlag.dismiss.success'));
      })
      .catch((error: AjaxError) => {
        handleSubmissionError({
          dispatch,
          error,
          handleInvalid: () => {
            dispatch(alertError('forms.moderateFlag.dismiss.error'));
          },
          isMounted
        });
      });
  }, [dispatch, flaggedItem.id, group.attributes.slug, isMounted]);

  const handleClickReject = useCallback(() => {
    dispatch(rejectFlaggedItem(group.attributes.slug, flaggedItem.id))
      .then(() => {
        dispatch(alertSuccess(rejectSuccessKey));
      })
      .catch((error: AjaxError) => {
        handleSubmissionError({
          dispatch,
          error,
          handleInvalid: () => {
            dispatch(alertError(rejectErrorKey));
          },
          isMounted
        });
      });
  }, [
    dispatch,
    flaggedItem.id,
    group.attributes.slug,
    isMounted,
    rejectErrorKey,
    rejectSuccessKey
  ]);

  const generateHandleClickLinkToFlagger = useCallback(
    (flagger: JSONApi.MemberResource) => () => {
      history.push(`/g/${group.attributes.slug}/members/${flagger.attributes.slug}`);
    },
    [group.attributes.slug, history]
  );

  return (
    <>
      <IonList className="ion-no-padding settings-list">
        <IonItem lines="none">
          <h2>{typeBadgeLabel}</h2>
          <IonBadge color="danger" slot="end">
            <FormattedMessage
              id="models.flaggedItem.attributes.flagCount"
              values={{ count: flags.length }}
            />
          </IonBadge>
        </IonItem>
        <div className="ion-padding">
          <div className="chat-bubble color-medium">
            {flaggedItemAuthor && <MemberAvatar member={flaggedItemAuthor} />}
            <div className="bubble">{renderFlaggedContent()}</div>
          </div>
        </div>
        <IonItem lines="none">
          <IonButtons slot="start">
            <IonButton color="secondary" fill="solid" routerLink={viewFlaggedLink}>
              <FormattedMessage id="dictionary.viewContent" />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton color="success" fill="solid" onClick={handleClickDismiss}>
              <FormattedMessage id="dictionary.ignore" />
            </IonButton>
            <IonButton color="danger" fill="solid" onClick={handleClickReject}>
              <FormattedMessage id="dictionary.reject" />
            </IonButton>
          </IonButtons>
        </IonItem>
      </IonList>
      <IonLabel className="settings-list-label">
        <FormattedMessage id="models.flaggedItem.attributes.flagger" />
      </IonLabel>
      {flags.map(flag => {
        const flagger = getFlagFlagger(apiData, flag);
        return (
          <IonList className="ion-margin-bottom ion-no-padding settings-list" key={flag.id}>
            <IonItem lines="none">
              <IonLabel color="primary">
                <OnClickLink onClick={generateHandleClickLinkToFlagger(flagger)}>
                  {flagger.attributes.fullName}
                </OnClickLink>
                <Nbsp />
                <FormattedMessage id="dictionary.on" />
                <Nbsp />
                {flag.attributes.createdAt.toLocaleString(DateTime.DATE_SHORT)}
              </IonLabel>
              <IonBadge color="danger" slot="end">
                {flag.attributes.category}
              </IonBadge>
            </IonItem>
            <div className="ion-padding-bottom ion-padding-end ion-padding-start">
              <div className="chat-bubble color-warning">
                <MemberAvatar member={flagger} />
                <div className="bubble">
                  {flag.attributes.report ||
                    intl.formatMessage({ id: 'models.flag.attributes.report.none' })}
                </div>
              </div>
            </div>
          </IonList>
        );
      })}
    </>
  );
};

export default ModerateItemFlags;
