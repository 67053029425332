import { IonBadge, IonCard, IonCardHeader, IonItem, IonLabel, IonList } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Heading from '../components/Heading';
import ModeratorPage from '../components/layout/ModeratorPage';
import LocalLoadingIndicator from '../components/LocalLoadingIndicator';
import useApiData from '../hooks/useApiData';
import useCurrentUser from '../hooks/useCurrentUser';
import { getFlaggedItemFlags, getFlaggedItemItem, getPendingGroupFlaggedItems } from '../selectors';
import { loadFlaggedItems } from '../thunks/apiThunks';
import { JSONApi, Models, State } from '../types';

import './SettingsList.scss';

type Props = {
  group: JSONApi.GroupResource;
};

const ManageGroupFlagsPage = ({ group }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const currentUser = useCurrentUser();

  const findFlaggedItems = useCallback(() => {
    const flaggedItems = getPendingGroupFlaggedItems(apiData, group);
    return flaggedItems.sort((a, b) => {
      if (!a.attributes.mostRecentFlagAt || !b.attributes.mostRecentFlagAt) {
        return 0;
      }
      return a.attributes.mostRecentFlagAt > b.attributes.mostRecentFlagAt ? -1 : 1;
    });
  }, [apiData, group]);

  const { loading, responseData: flaggedItems } = useApiData<Models.FlaggedItem>(
    loadFlaggedItems(group.attributes.slug),
    findFlaggedItems,
    'ManageGroupFlagsPage'
  );

  if (loading || !currentUser) {
    return <LocalLoadingIndicator />;
  }

  return (
    <ModeratorPage
      contentClass="canvas ion-padding"
      group={group}
      h2Key="pages.manageGroup.flags.title"
    >
      {() => {
        if (flaggedItems.length > 0) {
          return (
            <IonList className="ion-no-padding settings-list">
              {flaggedItems.map((flaggedItem, index) => {
                const flags = getFlaggedItemFlags(apiData, flaggedItem);
                const item = getFlaggedItemItem(apiData, flaggedItem);
                return (
                  <IonItem
                    key={flaggedItem.id}
                    lines={index === flaggedItems.length - 1 ? 'none' : 'full'}
                    routerLink={`/g/${group.attributes.slug}/manage-group/flags/${flaggedItem.id}`}
                  >
                    <IonLabel color="primary">
                      <h2>
                        <FormattedMessage id={`models.${item.type}.badge`} />
                      </h2>
                    </IonLabel>
                    <IonBadge color="danger">
                      <FormattedMessage
                        id="models.flaggedItem.attributes.flagCount"
                        values={{ count: flags.length }}
                      />
                    </IonBadge>
                  </IonItem>
                );
              })}
            </IonList>
          );
        }
        return (
          <IonCard>
            <IonCardHeader className="ion-text-center">
              <Heading color="secondary" level={2}>
                <FormattedMessage id="dictionary.allClear" />
              </Heading>
            </IonCardHeader>
          </IonCard>
        );
      }}
    </ModeratorPage>
  );
};

export default ManageGroupFlagsPage;
