import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  RadioGroupChangeEventDetail,
  SearchbarChangeEventDetail,
  useIonModal
} from '@ionic/react';
import debounce from 'debounce';
import equal from 'deep-equal';
import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Nbsp from '../../components/Nbsp';
import useAutofocus from '../../hooks/useAutofocus';
import { Opener } from '../../hooks/useOpener';
import useStopEnterKeyPropagation from '../../hooks/useStopEnterKeyPropagation';

export type SearchableSelectOptionData = {
  id: string;
  label: string;
  meta?: {
    geoloc: {
      lat: number;
      lng: number;
    };
  };
  value: string;
};

const SearchableSelectOption = ({
  forwardRef,
  option,
  ...ionRadioProps
}: Pick<React.ComponentProps<typeof IonRadio>, 'onIonBlur' | 'onIonFocus' | 'onKeyDown'> & {
  forwardRef: React.RefObject<HTMLIonItemElement>;
  option: SearchableSelectOptionData;
}) => (
  <IonItem ref={forwardRef} tabIndex={0}>
    <IonRadio slot="start" {...ionRadioProps} value={option.value} />
    <IonLabel>{option.label}</IonLabel>
  </IonItem>
);

export type QueryHandler = (query: string) => Promise<SearchableSelectOptionData[]>;

interface SearchableSelectModalBodyProps
  extends React.ComponentPropsWithoutRef<typeof IonSearchbar> {
  attributeNameTKey?: string;
  onSelectOption: (option: SearchableSelectOptionData | undefined) => void;
  opener: Opener;
  optionRefs: React.RefObject<HTMLIonItemElement>[];
  options: SearchableSelectOptionData[];
  selectedOption: SearchableSelectOptionData | undefined;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  titleTKey?: string;
}

const SearchableSelectModalBody = ({
  attributeNameTKey,
  onSelectOption,
  opener,
  optionRefs,
  options,
  selectedOption,
  setSearchQuery,
  titleTKey,
  ...ionSearchbarProps
}: SearchableSelectModalBodyProps) => {
  const contentRef = createRef<HTMLIonContentElement>();
  const focusedIndex = useRef<number>(-1);
  const searchbarRef = createRef<HTMLIonSearchbarElement>();

  const closeModal = useCallback(() => {
    if (Capacitor.getPlatform() === 'web') {
      opener.close();
    } else {
      const listener = Keyboard.addListener('keyboardDidHide', () => {
        opener.close();
        listener.remove();
      });
      Keyboard.hide().catch(() => {
        listener.remove();
        opener.close();
      });
    }
  }, [opener]);

  const handleArrowKeys = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        // prevent the default behavior of scrolling the content area
        event.preventDefault();
        // if the content ref is not currently focused, you have to focus it first
        // before focusing an option
        if (focusedIndex.current < 0 && contentRef.current) {
          contentRef.current.focus();
        }
        let nextIndex = 0;
        if (event.key === 'ArrowDown') {
          nextIndex = (focusedIndex.current + 1) % optionRefs.length;
        }
        if (event.key === 'ArrowUp') {
          if (focusedIndex.current > 0) {
            nextIndex = focusedIndex.current - 1;
          } else {
            nextIndex = optionRefs.length - 1;
          }
        }
        const refToFocus = optionRefs[nextIndex];
        if (refToFocus?.current) {
          refToFocus.current.focus();
        }
      }
    },
    [contentRef, optionRefs]
  );

  const handleBlur = useCallback(() => {
    focusedIndex.current = -1;
  }, []);

  const handleCancelClick = useCallback(() => {
    onSelectOption(undefined);
    closeModal();
  }, [closeModal, onSelectOption]);

  // stop propagation of enter key outside the searchable select to prevent form submission
  const handleKeyDown = useStopEnterKeyPropagation<HTMLIonContentElement | HTMLIonHeaderElement>(
    handleArrowKeys
  );

  const handleSearch = debounce((event: CustomEvent<SearchbarChangeEventDetail>) => {
    const searchbar = event.target as HTMLIonSearchbarElement & typeof event.target;
    setSearchQuery(searchbar.value ?? '');
  }, 200);

  const handleSelect = useCallback(
    (event: CustomEvent<RadioGroupChangeEventDetail>) => {
      const radioGroup = event.target as HTMLIonRadioGroupElement & typeof event.target;
      if (radioGroup) {
        const newSelectedOption = options.find(option => option.value === radioGroup.value);
        onSelectOption(newSelectedOption);
      }
      // if we ever offer a multiple option we will not want to automatically close the modal
      closeModal();
    },
    [closeModal, onSelectOption, options]
  );

  const generateHandleFocus = useCallback(
    (index: number) => () => {
      focusedIndex.current = index;
    },
    []
  );

  useAutofocus(opener.isPresented, searchbarRef);

  return (
    <>
      <IonHeader onKeyDown={handleKeyDown} translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={handleCancelClick}>
              <FormattedMessage id="dictionary.clear" />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage id={titleTKey ?? 'dictionary.search'} />
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={closeModal}>
              <FormattedMessage id="dictionary.done" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          {attributeNameTKey && (
            <p className="ion-no-margin ion-padding-bottom ion-padding-end ion-padding-start">
              <FormattedMessage id="modals.searchableSelect.explanationStart" />
              <Nbsp />
              <span className="ion-text-lowercase">
                <FormattedMessage id={attributeNameTKey} />
              </span>
              <Nbsp />
              <FormattedMessage id="modals.searchableSelect.explanationEnd" />
            </p>
          )}
          <IonSearchbar {...ionSearchbarProps} onIonChange={handleSearch} ref={searchbarRef} />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen onKeyDown={handleKeyDown} ref={contentRef}>
        {options.length > 0 && (
          <IonRadioGroup
            onIonChange={handleSelect}
            value={selectedOption ? selectedOption.value : undefined}
          >
            {options.map((option, index) => (
              <SearchableSelectOption
                forwardRef={optionRefs[index]}
                key={option.value}
                onIonBlur={handleBlur}
                onIonFocus={generateHandleFocus(index)}
                option={option}
              />
            ))}
          </IonRadioGroup>
        )}
      </IonContent>
    </>
  );
};

const SearchableSelectModal = ({
  attributeNameTKey,
  defaultOptions = [],
  onSelectOption,
  opener,
  queryHandler,
  selectedOption,
  titleTKey,
  ...ionSearchbarProps
}: React.ComponentPropsWithoutRef<typeof IonSearchbar> & {
  attributeNameTKey?: string;
  defaultOptions?: SearchableSelectOptionData[];
  onSelectOption: (option: SearchableSelectOptionData | undefined) => void;
  opener: Opener;
  queryHandler: QueryHandler;
  selectedOption: SearchableSelectOptionData | undefined;
  titleTKey?: string;
}) => {
  const [options, setOptions] = useState(defaultOptions);
  const [optionRefs, setOptionRefs] = useState<React.RefObject<HTMLIonItemElement>[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setOptionRefs(options.map(() => createRef<HTMLIonItemElement>()));
  }, [options]);

  useEffect(() => {
    if (searchQuery.length > 0) {
      queryHandler(searchQuery).then(newOptions => {
        setOptions(newOptions);
      });
    }
  }, [queryHandler, searchQuery]);

  useEffect(() => {
    if ((!searchQuery || searchQuery.length === 0) && !equal(options, defaultOptions)) {
      setOptions(defaultOptions);
    }
  }, [defaultOptions, options, searchQuery]);

  const handleDidDismiss = useCallback(() => {
    opener.close();
    opener.handleDidDismiss();
    setSearchQuery('');
  }, [opener]);

  const [present, dismiss] = useIonModal(SearchableSelectModalBody, {
    ...ionSearchbarProps,
    attributeNameTKey,
    onSelectOption,
    opener,
    optionRefs,
    options,
    selectedOption,
    setSearchQuery,
    titleTKey
  });

  useEffect(() => {
    if (opener.isOpen) {
      present({
        onDidDismiss: handleDidDismiss,
        onDidPresent: opener.handleDidPresent
      });
    } else {
      dismiss();
    }
  }, [dismiss, handleDidDismiss, opener.handleDidPresent, opener.isOpen, present]);

  return null;
};

export default SearchableSelectModal;
