import { IonAvatar } from '@ionic/react';
import React from 'react';

import AvatarRandom from '../components/AvatarRandom';
import Img from '../components/Img';
import { maybeSlot } from '../services/componentUtils';

type Props = {
  alt: string;
  id: string;
  slot?: string;
  src: string | undefined;
};

const AvatarWithDefault = ({ alt, id, slot, src }: Props) => {
  if (src && src.length > 0) {
    return (
      <IonAvatar {...maybeSlot(slot)}>
        <Img alt={alt} src={src} />
      </IonAvatar>
    );
  }

  return <AvatarRandom alt={alt} id={id} {...maybeSlot(slot)} />;
};

export default AvatarWithDefault;
