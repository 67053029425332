import { IonItem } from '@ionic/react';
import { FormApi } from 'final-form';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormInput from '../../components/forms/FormInput';
import FormSubmit from '../../components/forms/FormSubmit';
import SubmitError from '../../components/forms/SubmitError';
import Paragraph from '../../components/Paragraph';
import UserAccountWizardBackButton from '../../components/UserAccountWizardBackButton';
import { maxEmailLength } from '../../globals';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { AjaxError } from '../../services/ajaxRequest';
import { forceArray } from '../../services/arrayUtils';
import { handleFormSubmitFailure, resetForm } from '../../services/formUtils';
import { checkForEmailConfirmationPath, maybeGroupPath } from '../../services/urlUtils';
import { createEmailConfirmation, createUser, loadEmailLookup } from '../../thunks/apiThunks';
import { JSONApi } from '../../types';

type FormValues = {
  email: string;
};

type Props = {
  group: JSONApi.GroupResource | null | undefined;
  setCurrentEmail: (email: string | undefined) => void;
};

const EnterEmailForm = ({ group, setCurrentEmail }: Props) => {
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const initialValues: FormValues = {
    email: ''
  };

  // This avoids using the formUtils stuff because it chains multiple api calls together and we
  // need to manage the return values manually in order to make sure submission errors appear
  const handleSubmit = useCallback(
    async (values: FormValues, form: FormApi<FormValues>) =>
      dispatch(loadEmailLookup(values.email))
        .then(response => {
          const record = forceArray(response.data)[0].attributes;
          if (record.found && record.confirmed) {
            if (isMounted.current) {
              // The email matches a confirmed user, so let the user log in
              resetForm<FormValues>(form);
              setCurrentEmail(record.email);
            }
          } else if (!record.found) {
            // The email is new, so create a user for it and forward to email confirmation
            return dispatch(createUser(group, values.email))
              .then(() => {
                if (isMounted.current) {
                  resetForm<FormValues>(form);
                  history.push({
                    pathname: checkForEmailConfirmationPath(group),
                    state: { email: values.email }
                  });
                }
              })
              .catch((error: AjaxError) =>
                handleFormSubmitFailure({
                  dispatch,
                  error,
                  errorTranslationLocation: 'login',
                  intl,
                  isMounted,
                  values
                })
              );
          } else {
            if (isMounted.current) {
              resetForm<FormValues>(form);
              // The email matches an unconfirmed user, so resend a confirmation
              dispatch(createEmailConfirmation(group, values.email));
              // and forward to email confirmation
              history.push({
                pathname: checkForEmailConfirmationPath(group),
                state: { email: values.email }
              });
            }
          }
        })
        .catch((error: AjaxError) =>
          handleFormSubmitFailure({
            dispatch,
            error,
            errorTranslationLocation: 'login',
            intl,
            isMounted,
            values
          })
        ),
    [dispatch, group, history, intl, isMounted, setCurrentEmail]
  );

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'errors.login.email.invalid' }))
      .required(intl.formatMessage({ id: 'errors.login.email.blank' }))
      .max(
        maxEmailLength,
        intl.formatMessage({ id: 'errors.emailAddress.email.tooLong' }, { count: maxEmailLength })
      )
  });

  return (
    <>
      <UserAccountWizardBackButton routerLink={maybeGroupPath('/', group)} />
      <FormContainer<FormValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ form, handleSubmit }) => (
          <Form<FormValues>
            className="enter-email-form"
            onFormSubmit={handleSubmit}
            submit={form.submit}
          >
            <IonItem className="compact" color="transparent" lines="full">
              <FormInput
                aria-label={intl.formatMessage({
                  id: 'models.registration.attributes.email.label'
                })}
                autofocus
                color="primary"
                name="email"
                placeholder={intl.formatMessage({
                  id: 'models.registration.attributes.email.placeholder'
                })}
                type="email"
              />
            </IonItem>
            <SubmitError />
            <FormError allowBlank name="email" />
            <IonItem className="compact" lines="none">
              <Paragraph color="medium">
                <FormattedMessage id="models.registration.attributes.email.tip" />
              </Paragraph>
            </IonItem>
            <FormSubmit color="primary">
              <FormattedMessage id="dictionary.next" />
            </FormSubmit>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default EnterEmailForm;
