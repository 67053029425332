import React, { useCallback } from 'react';

import Lightbox from '../components/Lightbox';
import useOpener from '../hooks/useOpener';

import './ImgPane.scss';

type Props = {
  src: string;
};

const ImgPane = ({ src }: Props) => {
  const lightboxOpener = useOpener();

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        lightboxOpener.open();
      }
    },
    [lightboxOpener]
  );

  return (
    <>
      <div
        className="img-pane"
        onClick={lightboxOpener.open}
        onKeyDown={handleKeyDown}
        role="button"
        style={{ backgroundImage: `url("${src}")` }}
        tabIndex={0}
      />
      <Lightbox mainSrc={src} opener={lightboxOpener} />
    </>
  );
};

export default ImgPane;
