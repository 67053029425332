import { IonButton, IonButtons, IonItem, IonToggle } from '@ionic/react';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import { alertError, alertSuccess } from '../../actions/notificationActions';
import GroupNotificationsForm from '../../components/GroupNotificationsForm';
import Heading from '../../components/Heading';
import Paragraph from '../../components/Paragraph';
import { WizardSteps } from '../../components/VerificationWizard';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { AjaxError } from '../../services/ajaxRequest';
import { handleSubmissionError } from '../../services/formUtils';
import { updateMember } from '../../thunks/apiThunks';
import { JSONApi, ModelAttributes } from '../../types';

type Props = {
  group: JSONApi.GroupResource;
  member: JSONApi.MemberResource;
  setWizardStep: (step: WizardSteps) => void;
};

const VerificationSettingsForm = ({ group, member, setWizardStep }: Props) => {
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const isMounted = useMountedTracking();
  const [useDefaultSettings, setUseDefaultSettings] = useState(true);

  const handleBackClick = useCallback(() => {
    setWizardStep(WizardSteps.EDIT_PROFILE);
  }, [setWizardStep]);

  const handleNextClick = useCallback(() => {
    if (member.attributes.status === ModelAttributes.MemberStatus.APPROVED) {
      history.push(`/g/${group.attributes.slug}`);
    } else {
      setWizardStep(WizardSteps.WAITING);
    }
  }, [group.attributes.slug, history, member.attributes.status, setWizardStep]);

  const toggleUseDefaultSettings = useCallback(() => {
    setUseDefaultSettings(!useDefaultSettings);
  }, [useDefaultSettings]);

  const handleClickOkToShareInfo = useCallback(() => {
    dispatch(
      updateMember(
        group.attributes.slug,
        { okToShareInfo: !member.attributes.okToShareInfo },
        member.id
      )
    )
      .then(() => {
        if (isMounted.current) {
          dispatch(alertSuccess('forms.verificationSettings.changeOkToShareInfo.success'));
        }
      })
      .catch((error: AjaxError) => {
        handleSubmissionError({
          dispatch,
          error,
          handleInvalid: () => {
            dispatch(alertError('forms.verificationSettings.changeOkToShareInfo.error'));
          },
          isMounted
        });
      });
  }, [dispatch, group.attributes.slug, isMounted, member.attributes.okToShareInfo, member.id]);

  return (
    <div className="verification-settings-form">
      <Heading level={2}>
        <FormattedMessage id="pages.verificationSettings.h2" />
      </Heading>
      <IonItem lines="none">
        <IonToggle
          checked={useDefaultSettings}
          name="useDefaultSettings"
          onClick={toggleUseDefaultSettings}
          slot="start"
        />
        <Paragraph>
          <FormattedMessage id="models.member.attributes.defaultSubscriptionSettings.label" />
        </Paragraph>
      </IonItem>
      {!useDefaultSettings ? (
        <GroupNotificationsForm group={group} member={member} />
      ) : (
        <Paragraph>
          <FormattedMessage id="models.member.attributes.defaultSubscriptionSettings.subheader" />
        </Paragraph>
      )}
      <IonItem lines="none">
        <IonToggle
          checked={member.attributes.okToShareInfo}
          name="okToShareInfo"
          onClick={handleClickOkToShareInfo}
          slot="start"
        />
        <Paragraph>
          <FormattedMessage id="models.member.attributes.okToShareInfo.label" />
        </Paragraph>
      </IonItem>
      <Paragraph>
        <FormattedMessage id="models.member.attributes.okToShareInfo.subheader" />
      </Paragraph>
      <IonItem color="transparent" lines="none">
        <IonButtons slot="end">
          <IonButton color="primary" fill="outline" onClick={handleBackClick} size="default">
            <FormattedMessage id="dictionary.previous" />
          </IonButton>
          <IonButton color="primary" fill="solid" onClick={handleNextClick} size="default">
            <FormattedMessage id="dictionary.finish" />
          </IonButton>
        </IonButtons>
      </IonItem>
    </div>
  );
};

export default VerificationSettingsForm;
