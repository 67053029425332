import { IonLabel } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import SearchableSelect from '../components/forms/SearchableSelect';
import { SearchableSelectOptionData } from '../components/forms/SearchableSelectModal';
import { generateTextQueryHandler } from '../services/textSearch';
import { Search } from '../types';

type ExtendedSearchParams<T extends Search.Params> = T & { industry: string | null };

type Props<T extends Search.Params> = {
  facetValues: Search.FacetValue[] | undefined;
  searchParams: ExtendedSearchParams<T>;
  setSearchParams: (value: ExtendedSearchParams<T>) => void;
};

function SearchIndustry<T extends Search.Params>({
  facetValues,
  searchParams,
  setSearchParams
}: Props<T>) {
  const intl = useIntl();

  const handleSelectOption = useCallback(
    (option: SearchableSelectOptionData | undefined) => {
      setSearchParams({ ...searchParams, industry: option ? option.value : null });
    },
    [searchParams, setSearchParams]
  );

  let options: SearchableSelectOptionData[] = [];
  if (facetValues) {
    options = facetValues.map(value => {
      const label = intl.formatMessage(
        { id: 'forms.facet.keyAndCount' },
        { count: value.count, key: value.key }
      );
      return { id: value.key, label, value: value.key };
    });
  }

  return (
    <>
      <IonLabel>
        <FormattedMessage id="models.search.attributes.industry" />
      </IonLabel>
      <div className="searchable-select-wrapper-outer">
        <div className="searchable-select-wrapper-inner">
          <SearchableSelect
            defaultOptions={options}
            name="industry"
            onSelectOption={handleSelectOption}
            queryHandler={generateTextQueryHandler(options)}
            selectedOption={options.filter(option => option.value === searchParams.industry)[0]}
            type="text"
            value={searchParams.industry}
          />
        </div>
      </div>
    </>
  );
}

export default SearchIndustry;
