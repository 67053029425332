import { IonButtons, IonIcon, IonItem } from '@ionic/react';
import { FormApi } from 'final-form';
import { send } from 'ionicons/icons';
import { MentionsRef } from 'rc-mentions/lib/Mentions';
import React, { createRef, useCallback } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormMentionsTextarea from '../../components/forms/FormMentionsTextarea';
import FormSubmit from '../../components/forms/FormSubmit';
import SubmitError from '../../components/forms/SubmitError';
import MentionButton from '../../components/MentionButton';
import { maxTextInputLength } from '../../globals';
import useMountedTracking from '../../hooks/useMountedTracking';
import useScrollToBottom from '../../hooks/useScrollToBottom';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { handleFormSubmission } from '../../services/formUtils';
import { createComment } from '../../thunks/apiThunks';
import { JSONApi, Models } from '../../types';

import './AddToThreadForm.scss';

type FormValues = Models.CreateComment;

type Props = {
  contentRef?: React.RefObject<HTMLIonContentElement>;
  group: JSONApi.GroupResource;
  post: JSONApi.PostResource;
};

const CommentForm = ({ contentRef, group, post }: Props) => {
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();
  const scrollToBottom = useScrollToBottom(contentRef);
  const mentionsRef = createRef<MentionsRef>();

  const initialValues: FormValues = {
    content: ''
  };

  const generateSetContentValue = useCallback(
    (form: FormApi<FormValues>) => (content: string) => {
      form.change('content', content);
    },
    []
  );

  const handleSubmit = useCallback(
    async (values: FormValues, form: FormApi<FormValues>) =>
      handleFormSubmission({
        action: createComment(group.attributes.slug, post, values),
        dispatch,
        errorTranslationLocation: 'comment',
        form,
        intl,
        isMounted,
        onSuccess: () => {
          scrollToBottom();
        },
        successTKey: 'forms.comment.create.success',
        values
      }),
    [dispatch, group.attributes.slug, intl, isMounted, post, scrollToBottom]
  );

  const validationSchema = Yup.object().shape({
    content: Yup.string()
      .required(intl.formatMessage({ id: 'errors.comment.content.blank' }))
      .max(
        maxTextInputLength,
        intl.formatMessage({ id: 'errors.comment.content.tooLong' }, { count: maxTextInputLength })
      )
  });

  return (
    <FormContainer<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ form, handleSubmit }) => (
        <Form<FormValues>
          className="add-to-thread-form comment-form"
          onFormSubmit={handleSubmit}
          submit={form.submit}
        >
          <SubmitError />
          <div className="input-wrapper">
            <IonItem className="compact" color="transparent" lines="none">
              <FormMentionsTextarea
                aria-label={intl.formatMessage({
                  id: 'models.comment.attributes.content.label'
                })}
                forwardRef={mentionsRef}
                name="content"
                onFocus={scrollToBottom}
                placeholder={intl.formatMessage({
                  id: 'models.comment.attributes.content.placeholder'
                })}
                placement="top"
                rows={1}
              />
              <IonButtons className="ion-no-margin" slot="end">
                <MentionButton
                  fieldRef={mentionsRef}
                  setFieldValue={generateSetContentValue(form)}
                />
                <FormSubmit
                  aria-label={intl.formatMessage({ id: 'forms.comment.submitButton' })}
                  className="comment-submit"
                  color="primary"
                  fill="clear"
                  size="small"
                >
                  <IonIcon icon={send} slot="icon-only" />
                </FormSubmit>
              </IonButtons>
            </IonItem>
          </div>
        </Form>
      )}
    </FormContainer>
  );
};

export default CommentForm;
