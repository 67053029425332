import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import deepEqual from 'deep-equal';
import { chatbubblesOutline, thumbsDownSharp, thumbsUpSharp } from 'ionicons/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { alertError } from '../actions/notificationActions';
import ChipButton from '../components/ChipButton';
import CommentBubble from '../components/CommentBubble';
import CommentForm from '../components/forms/CommentForm';
import LocalLoadingIndicator from '../components/LocalLoadingIndicator';
import OnClickLink from '../components/OnClickLink';
import ReactionsModal from '../components/ReactionsModal';
import useOpener from '../hooks/useOpener';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { getPostComments, getViewerReaction } from '../selectors';
import { createReaction, deleteReaction, loadComments } from '../thunks/apiThunks';
import { JSONApi, ModelAttributes, State } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  post: JSONApi.PostResource;
  showForm?: boolean;
};

const CommentsSection = ({ group, post, showForm = false }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const [comments, setComments] = useState<JSONApi.CommentResource[]>([]);
  const [loadingMoreComments, setLoadingMoreComments] = useState(false);
  const [showComments, setShowComments] = useState(true);
  const dislikesModalOpener = useOpener();
  const likesModalOpener = useOpener();
  const [showReactionChips, setShowReactionChips] = useState(false);

  useEffect(() => {
    const apiDataComments = getPostComments(apiData, post);
    if (!deepEqual(apiDataComments, comments)) {
      setComments(apiDataComments);
    }
  }, [apiData, comments, post]);

  const viewerReaction = getViewerReaction(apiData, post);
  const isActive = useCallback(
    (emotion: ModelAttributes.ReactionEmotion) => viewerReaction?.attributes.emotion === emotion,
    [viewerReaction?.attributes.emotion]
  );

  const generateHandleClick = useCallback(
    (emotion: ModelAttributes.ReactionEmotion) => () => {
      const action = isActive(emotion)
        ? deleteReaction(group.attributes.slug, viewerReaction)
        : createReaction(group.attributes.slug, { emotion }, post);
      dispatch(action).then(response => {
        response.dataLoadedIntoStatePromise.then(() => {
          setShowReactionChips(false);
        });
      });
    },
    [dispatch, group.attributes.slug, isActive, post, viewerReaction]
  );

  const handleAddReactionClick = useCallback(() => {
    setShowReactionChips(!showReactionChips);
  }, [showReactionChips]);

  const handleLoadComments = useCallback(() => {
    setLoadingMoreComments(true);
    dispatch(loadComments(group.attributes.slug, post.id))
      .then(response => {
        response.dataLoadedIntoStatePromise
          .then(() => {
            setLoadingMoreComments(false);
          })
          .catch(() => {
            setLoadingMoreComments(false);
          });
      })
      .catch(() => {
        dispatch(alertError('errors.comments.load.failed'));
        setLoadingMoreComments(false);
      });
  }, [dispatch, group.attributes.slug, post.id]);

  const handleToggleComments = useCallback(() => {
    setShowComments(!showComments);
  }, [showComments]);

  return (
    <>
      <IonItem
        color="transparent"
        lines={showComments && post.attributes.numComments > 0 ? 'inset' : 'none'}
      >
        {post.attributes.numComments > 0 && (
          <ChipButton
            aria-label={intl.formatMessage(
              showComments ? { id: 'dictionary.hideComments' } : { id: 'dictionary.showComments' }
            )}
            color="medium"
            onClick={handleToggleComments}
            tabIndex={0}
          >
            <IonIcon icon={chatbubblesOutline} />
            <IonLabel>{post.attributes.numComments}</IonLabel>
          </ChipButton>
        )}
        <ChipButton
          aria-label={intl.formatMessage({ id: 'dictionary.like' })}
          color={showReactionChips ? 'rain' : 'medium'}
          onClick={handleAddReactionClick}
          tabIndex={0}
        >
          <IonIcon icon={thumbsUpSharp} />
        </ChipButton>
        {showReactionChips && (
          <>
            <ChipButton
              aria-label={intl.formatMessage({ id: 'dictionary.like' })}
              color={isActive(ModelAttributes.ReactionEmotion.LIKE) ? 'night' : 'secondary'}
              onClick={generateHandleClick(ModelAttributes.ReactionEmotion.LIKE)}
              tabIndex={0}
            >
              <IonIcon icon={thumbsUpSharp} />
            </ChipButton>
            <ChipButton
              aria-label={intl.formatMessage({ id: 'dictionary.dislike' })}
              color={isActive(ModelAttributes.ReactionEmotion.DISLIKE) ? 'night' : 'secondary'}
              onClick={generateHandleClick(ModelAttributes.ReactionEmotion.DISLIKE)}
            >
              <IonIcon icon={thumbsDownSharp} />
            </ChipButton>
          </>
        )}
        {!showReactionChips && (
          <div slot="end">
            {post.attributes.reactionCounts.Like > 0 && (
              <ChipButton
                color={isActive(ModelAttributes.ReactionEmotion.LIKE) ? 'night' : 'secondary'}
                onClick={likesModalOpener.toggle}
                tabIndex={0}
              >
                <IonIcon icon={thumbsUpSharp} />
                <IonLabel>{post.attributes.reactionCounts.Like}</IonLabel>
              </ChipButton>
            )}
            <ReactionsModal
              contentId={post.id}
              contentType={ModelAttributes.ReactionContentType.POST}
              emotion={ModelAttributes.ReactionEmotion.LIKE}
              group={group}
              opener={likesModalOpener}
              titleKey="dictionary.likes"
            />
            {post.attributes.reactionCounts.Dislike > 0 && (
              <ChipButton
                color={isActive(ModelAttributes.ReactionEmotion.DISLIKE) ? 'night' : 'secondary'}
                onClick={dislikesModalOpener.toggle}
              >
                <IonIcon icon={thumbsDownSharp} />
                <IonLabel>{post.attributes.reactionCounts.Dislike}</IonLabel>
              </ChipButton>
            )}
            <ReactionsModal
              contentId={post.id}
              contentType={ModelAttributes.ReactionContentType.POST}
              emotion={ModelAttributes.ReactionEmotion.DISLIKE}
              group={group}
              opener={dislikesModalOpener}
              titleKey="dictionary.dislikes"
            />
          </div>
        )}
      </IonItem>
      {showComments && (
        <>
          {loadingMoreComments && <LocalLoadingIndicator />}
          {!loadingMoreComments && post.attributes.numComments > comments.length && (
            <IonItem color="transparent" lines="none">
              <OnClickLink onClick={handleLoadComments}>
                <FormattedMessage
                  id="models.post.relationships.comments.more"
                  values={{ num: post.attributes.numComments - comments.length }}
                />
              </OnClickLink>
            </IonItem>
          )}
          {comments.map(comment => (
            <CommentBubble comment={comment} group={group} key={comment.id} />
          ))}
          {showForm && (
            <div className="add-to-thread">
              <CommentForm group={group} post={post} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CommentsSection;
