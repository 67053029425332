import {
  IonItem,
  IonLabel,
  IonList,
  IonSegment,
  IonSegmentButton,
  IonText,
  SegmentChangeEventDetail
} from '@ionic/react';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import PlanChangePreviewModal from '../../components/forms/PlanChangePreviewModal';
import PriceTierSelector from '../../components/forms/PriceTierSelector';
import LocalLoadingIndicator from '../../components/LocalLoadingIndicator';
import Nbsp from '../../components/Nbsp';
import useApiData from '../../hooks/useApiData';
import useOpener from '../../hooks/useOpener';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { findResources, getPlanChangePreviewPriceTier } from '../../selectors';
import { forceArray } from '../../services/arrayUtils';
import { handleCheckoutSessionResponse } from '../../services/checkoutUtils';
import {
  createInitialCheckoutSession,
  createPlanChange,
  createPlanChangePreview,
  loadPriceTiers
} from '../../thunks/apiThunks';
import { JSONApi, ModelAttributes, Models, State } from '../../types';

import '../SettingsList.scss';

type Props = {
  group: JSONApi.GroupResource;
  paymentCancelRedirect: string;
  paymentSuccessRedirect: string;
};

const GroupPlanForm = ({ group, paymentCancelRedirect, paymentSuccessRedirect }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const previewOpener = useOpener();
  const [planChangePreview, setPlanChangePreview] = useState<
    JSONApi.PlanChangePreviewResource | undefined
  >();
  const [term, setTerm] = useState<ModelAttributes.PlanPeriod>(
    group.attributes.planPeriod ?? ModelAttributes.PlanPeriod.MONTHLY
  );

  const handleTierSelected = useCallback(
    (tier: JSONApi.PriceTierResource, period: ModelAttributes.PlanPeriod) => {
      dispatch(createPlanChangePreview(group.attributes.slug, tier.id, period)).then(response => {
        setPlanChangePreview(forceArray(response.data)[0]);
        previewOpener.open();
      });
    },
    [dispatch, group.attributes.slug, previewOpener]
  );

  const handleIonChange = useCallback((event: CustomEvent<SegmentChangeEventDetail>) => {
    const newTerm = event.detail.value as ModelAttributes.PlanPeriod | undefined;
    if (newTerm) {
      setTerm(newTerm);
    }
  }, []);

  const handlePreviewAccept = useCallback(() => {
    if (planChangePreview) {
      const priceTier = getPlanChangePreviewPriceTier(apiData, planChangePreview);
      if (group.attributes.planActive) {
        dispatch(
          createPlanChange(
            group.attributes.slug,
            priceTier.id,
            planChangePreview.attributes.newPeriod,
            paymentSuccessRedirect,
            paymentCancelRedirect
          )
        )
          .then(response => {
            previewOpener.close();
            handleCheckoutSessionResponse(response, history, paymentSuccessRedirect);
          })
          .catch(() => {
            previewOpener.close();
          });
      } else {
        dispatch(
          createInitialCheckoutSession(
            group.attributes.slug,
            priceTier.id,
            planChangePreview?.attributes.newPeriod,
            paymentSuccessRedirect,
            paymentCancelRedirect
          )
        )
          .then(response => {
            previewOpener.close();
            handleCheckoutSessionResponse(response, history, paymentSuccessRedirect);
          })
          .catch(() => {
            previewOpener.close();
          });
      }
    } else {
      previewOpener.close();
    }
  }, [
    apiData,
    dispatch,
    group.attributes.planActive,
    group.attributes.slug,
    history,
    paymentCancelRedirect,
    paymentSuccessRedirect,
    planChangePreview,
    previewOpener
  ]);

  const handlePreviewCancel = useCallback(() => {
    previewOpener.close();
  }, [previewOpener]);

  const findPriceTiers = useCallback(
    () =>
      findResources<Models.PriceTier>(
        apiData,
        'priceTier',
        undefined,
        undefined,
        null
        /* eslint-disable-next-line no-confusing-arrow */
      ).sort((a, b) => (a.attributes.memberCap > b.attributes.memberCap ? 1 : -1)),
    [apiData]
  );

  const { loading: loadingPriceTiers, responseData: priceTiers } = useApiData<Models.PriceTier>(
    loadPriceTiers(),
    findPriceTiers,
    'ManageGroupSubscriptionPage'
  );

  if (loadingPriceTiers) {
    return <LocalLoadingIndicator />;
  }

  return (
    <div className="group-plan-form">
      <IonLabel className="settings-list-label" color="primary">
        <FormattedMessage id="forms.payment.title" />
      </IonLabel>
      <IonSegment onIonChange={handleIonChange} value={term}>
        <IonSegmentButton value={ModelAttributes.PlanPeriod.MONTHLY}>
          <IonLabel color="primary">
            <FormattedMessage id="dictionary.monthly" />
          </IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value={ModelAttributes.PlanPeriod.YEARLY}>
          <IonLabel>
            <IonText color="primary">
              <FormattedMessage id="dictionary.yearly" />
            </IonText>
            <Nbsp />
            <IonText color="danger">
              <FormattedMessage id="models.plan.attributes.planPeriod.tip" />
            </IonText>
          </IonLabel>
        </IonSegmentButton>
      </IonSegment>
      <IonList className="ion-no-padding price-tier-list settings-list">
        {priceTiers.map(tier => (
          <PriceTierSelector
            group={group}
            key={tier.id}
            onTierSelected={handleTierSelected}
            priceTier={tier}
            term={term}
          />
        ))}
        <IonItem color="light" href="mailto:team@grouplicate.com" lines="none">
          <strong>
            <FormattedMessage id="models.plan.attributes.memberCap.more" />
          </strong>
          <strong slot="end">
            <FormattedMessage id="dictionary.contactUs" />
          </strong>
        </IonItem>
      </IonList>
      <PlanChangePreviewModal
        group={group}
        handleAccept={handlePreviewAccept}
        handleCancel={handlePreviewCancel}
        opener={previewOpener}
        preview={planChangePreview}
      />
    </div>
  );
};

export default GroupPlanForm;
