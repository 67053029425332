import React from 'react';

import FeedItem from '../components/FeedItem';
import { JSONApi } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  posts: JSONApi.PostResource[];
};

const PostFeed = ({ group, posts }: Props) => {
  if (posts.length === 0) {
    return null;
  }

  return (
    <div className="post-feed">
      {posts.map(post => (
        <FeedItem group={group} key={post.id} post={post} />
      ))}
    </div>
  );
};

export default PostFeed;
