import React from 'react';

import './ErrorMessage.scss';

type Props = {
  children: string;
  className?: string;
};

const ErrorMessage = ({ children: message, className }: Props) => (
  <div className={`error-message ${className ?? ''}`}>{message}</div>
);

export default ErrorMessage;
