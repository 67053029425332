import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import { JSONApi } from '../../types';

type Props = {
  group?: JSONApi.GroupResource;
  id?: string;
  value?: string;
  values?: { [key: string]: string | undefined };
};

const PageTitle = ({ group, id, value, values }: Props) => {
  const intl = useIntl();
  const suffix = intl.formatMessage(
    { id: group ? 'pages.titleSuffixWithGroup' : 'pages.titleSuffixWithoutGroup' },
    { group: group?.attributes?.name }
  );
  const title = value ?? intl.formatMessage({ id }, values);

  return (
    <Helmet>
      <title>
        {title}
        {suffix}
      </title>
    </Helmet>
  );
};

export default PageTitle;
