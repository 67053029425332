import React, { useCallback } from 'react';

import SearchResultsCore, { SearchResultsCoreProps } from '../components/SearchResultsCore';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { AjaxResponse } from '../services/ajaxRequest';
import { Actions, JSONApi, Models, Search, State } from '../types';

export type SearchActionCreator<SearchType extends Models.Base, ParamsType> = (
  groupSlug: string,
  searchParams: ParamsType
) => (
  dispatch: Actions.ThunkDispatch,
  getState: () => State.Root
) => Promise<AjaxResponse<SearchType> | undefined>;

function SearchResults<
  SearchType extends Models.Search,
  ModelType extends Models.Base,
  ParamsType extends Search.Params
>(
  props: SearchResultsCoreProps<SearchType, ModelType, ParamsType> & {
    group: JSONApi.GroupResource;
    loadDataActionCreator: SearchActionCreator<SearchType, ParamsType>;
  }
) {
  const { group, loadDataActionCreator, searchParams, ...coreProps } = props;
  const dispatch = useThunkDispatch();

  const dispatchLoadAction = useCallback(
    async () => dispatch(loadDataActionCreator(group.attributes.slug, searchParams)),
    [dispatch, group.attributes.slug, loadDataActionCreator, searchParams]
  );

  return (
    <SearchResultsCore
      {...coreProps}
      dispatchLoadAction={dispatchLoadAction}
      searchParams={searchParams}
    />
  );
}

export default SearchResults;
