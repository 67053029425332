import { Action } from 'redux';

import { SessionState } from '../types';

export enum SessionAction {
  CLEAR_SESSION = 'session/CLEAR_SESSION',
  SET_AUTH_TOKEN = 'session/SET_AUTH_TOKEN',
  SET_CURRENT_GROUP_DATA = 'session/SET_CURRENT_GROUP_DATA',
  SET_CURRENT_USER_DATA = 'session/SET_CURRENT_USER_DATA',
  SET_PUSH_NOTIFICATION_TOKEN = 'session/SET_PUSH_NOTIFICATION_TOKEN'
}

export interface ClearSessionAction extends Action {
  payload: null;
  type: SessionAction.CLEAR_SESSION;
}

export interface SetAuthTokenAction extends Action {
  payload: {
    authToken: string | null;
  };
  type: SessionAction.SET_AUTH_TOKEN;
}

export interface SetCurrentGroupDataAction extends Action {
  payload: {
    slug: string;
    state: SessionState;
  };
  type: SessionAction.SET_CURRENT_GROUP_DATA;
}

export interface SetCurrentUserDataAction extends Action {
  payload: {
    id: string;
    state: SessionState;
  };
  type: SessionAction.SET_CURRENT_USER_DATA;
}

export interface SetPushNotificationTokenAction extends Action {
  payload: {
    pushNotificationToken: string;
  };
  type: SessionAction.SET_PUSH_NOTIFICATION_TOKEN;
}

export type SessionActionTypes =
  | ClearSessionAction
  | SetAuthTokenAction
  | SetCurrentGroupDataAction
  | SetCurrentUserDataAction
  | SetPushNotificationTokenAction;

export const clearSession = () => ({
  payload: {},
  type: SessionAction.CLEAR_SESSION
});

export const setAuthToken = (authToken: string | null) => ({
  payload: { authToken },
  type: SessionAction.SET_AUTH_TOKEN
});

export const setCurrentGroupData = (slug: string | null, state: SessionState) => ({
  payload: { slug, state },
  type: SessionAction.SET_CURRENT_GROUP_DATA
});

export const setCurrentUserData = (id: string | null, state: SessionState) => ({
  payload: { id, state },
  type: SessionAction.SET_CURRENT_USER_DATA
});

export const setPushNotificationToken = (pushNotificationToken: string) => ({
  payload: { pushNotificationToken },
  type: SessionAction.SET_PUSH_NOTIFICATION_TOKEN
});
