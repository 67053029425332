import { IonButton, IonButtons, IonIcon, IonItem, IonLabel } from '@ionic/react';
import { eye, eyeOff } from 'ionicons/icons';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormInput from '../../components/forms/FormInput';
import FormSubmit from '../../components/forms/FormSubmit';
import SubmitError from '../../components/forms/SubmitError';
import Paragraph from '../../components/Paragraph';
import UserAccountWizardBackButton from '../../components/UserAccountWizardBackButton';
import { minPasswordLength } from '../../globals';
import useMountedTracking from '../../hooks/useMountedTracking';
import useOpener from '../../hooks/useOpener';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { handleFormSubmission } from '../../services/formUtils';
import { updateUser } from '../../thunks/apiThunks';
import { JSONApi } from '../../types';

type FormValues = {
  email: string;
  password: string;
};

type Props = {
  currentUser: JSONApi.UserResource;
  nextHref: string;
};

const SetupPasswordForm = ({ currentUser, nextHref }: Props) => {
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const intl = useIntl();
  const isMounted = useMountedTracking();
  const [passwordIcon, setPasswordIcon] = useState(eye);
  const passwordVisibilityOpener = useOpener();

  const initialValues: FormValues = {
    email: currentUser.attributes.username,
    password: ''
  };

  const togglePasswordVisibility = useCallback(() => {
    passwordVisibilityOpener.toggle();
    if (passwordVisibilityOpener.isOpen) {
      setPasswordIcon(eye);
    } else {
      setPasswordIcon(eyeOff);
    }
  }, [passwordVisibilityOpener]);

  const handleSubmit = useCallback(
    async (values: FormValues) =>
      handleFormSubmission({
        action: updateUser({ password: values.password }, currentUser.id),
        dispatch,
        errorTranslationLocation: 'password',
        intl,
        isMounted,
        onSuccess: () => {
          history.push(nextHref);
        },
        values
      }),
    [currentUser.id, dispatch, history, intl, isMounted, nextHref]
  );

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(intl.formatMessage({ id: 'errors.password.password.blank' }))
      .min(
        minPasswordLength,
        intl.formatMessage(
          { id: 'errors.password.password.tooShort' },
          { count: minPasswordLength }
        )
      )
  });

  return (
    <>
      <UserAccountWizardBackButton routerLink="/logout" />
      <FormContainer<FormValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ form, handleSubmit }) => (
          <Form<FormValues>
            className="setup-password-form"
            onFormSubmit={handleSubmit}
            submit={form.submit}
          >
            <IonItem className="compact" color="transparent" lines="full">
              <FormInput
                aria-label={intl.formatMessage({
                  id: 'models.registration.attributes.password.label'
                })}
                name="password"
                placeholder={intl.formatMessage({
                  id: 'models.registration.attributes.password.placeholder'
                })}
                type={passwordVisibilityOpener.isOpen ? 'text' : 'password'}
              />
            </IonItem>
            <SubmitError />
            <FormError allowBlank name="password" />
            <IonItem className="compact" color="transparent" lines="none">
              <Paragraph>
                <FormattedMessage
                  id="models.registration.attributes.password.tip"
                  values={{ minLength: minPasswordLength }}
                />
              </Paragraph>
              <IonButtons slot="end">
                <IonButton
                  color="primary"
                  fill="clear"
                  onClick={togglePasswordVisibility}
                  size="default"
                >
                  <IonIcon icon={passwordIcon} slot="start" />
                  <IonLabel>
                    {passwordVisibilityOpener.isOpen
                      ? intl.formatMessage({ id: 'dictionary.hide' })
                      : intl.formatMessage({ id: 'dictionary.show' })}
                  </IonLabel>
                </IonButton>
              </IonButtons>
            </IonItem>
            <FormSubmit color="primary" fill="solid" size="default">
              <FormattedMessage id="dictionary.next" />
            </FormSubmit>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default SetupPasswordForm;
