import { IonButton } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  onClick?: () => void;
  tKey: string;
};

const NoPostResults = ({ onClick, tKey }: Props) => (
  <>
    <div className="ion-padding-end ion-padding-start">
      <h2>
        <FormattedMessage id={tKey} />
      </h2>
    </div>
    {onClick && (
      <div className="ion-padding">
        <IonButton color="secondary" onClick={onClick} size="large">
          <FormattedMessage id="dictionary.postNow" />
        </IonButton>
      </div>
    )}
  </>
);

export default NoPostResults;
