import { Location } from 'history';

import { HTML, JSONApi } from '../types';

export const cleanPath = (path: string): string => {
  if (path.endsWith('/')) {
    return path.substring(0, path.length - 1);
  }
  return path;
};

export const apiPath = (groupSlug?: string, resource?: string) => {
  let path = '/api/v1';
  if (groupSlug) {
    path += `/g/${groupSlug}`;
  }
  if (resource) {
    path += `/${resource}`;
  }
  return path;
};

export const responseCodeMap = {
  forbidden: 403,
  notFound: 404,
  preconditionFailed: 412,
  unauthorized: 401
};

export const maybeGroupPath = (path: string, group?: JSONApi.GroupResource | null | undefined) => {
  let finalPath = path;
  if (group) {
    finalPath = `/g/${group.attributes.slug}${finalPath}`;
  }
  return finalPath;
};

export const checkForEmailConfirmationPath = (group?: JSONApi.GroupResource | null | undefined) =>
  maybeGroupPath('/register/check-for-email-confirmation', group);

export const setupPasswordPath = (group?: JSONApi.GroupResource | null | undefined) =>
  maybeGroupPath('/register/setup-password', group);

export const toUrl = (link: string): URL | undefined => {
  let url: URL | undefined;
  try {
    if (link) {
      url = new URL(link);
    }
  } catch {
    url = undefined;
  }
  return url;
};

export const toUrlParams = (obj: HTML.UrlParams): string => {
  const parts = Object.keys(obj).map(key => {
    const val = obj[key];
    if (val) {
      return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
    }
    return undefined;
  });
  return parts.filter(i => !!i).join('&');
};

export const collectSearchParamsFromUrl = <T extends HTML.UrlParams>(
  routeLocation: Location,
  fields: (keyof T)[]
) => {
  const collection: { [key: string]: string } = {};
  const params = new URLSearchParams(routeLocation.search);
  fields.forEach(field => {
    const value = params.get(field as string);
    if (value) {
      collection[field as string] = value;
    }
  });
  return collection;
};

type RecordWithLocation = {
  latitude: number | null;
  location: string | null | undefined;
  longitude: number | null;
};

export const locationSearchUrl = (
  located: RecordWithLocation,
  searchType: string,
  group: JSONApi.GroupResource
) => {
  if (located.location && located.location.length > 0 && located.latitude && located.longitude) {
    const locationPart = encodeURIComponent(located.location);
    const latPart = encodeURIComponent(located.latitude);
    const lngPart = encodeURIComponent(located.longitude);
    return `/g/${group.attributes.slug}/${searchType}?location=${locationPart}&lat=${latPart}&lng=${lngPart}`;
  }
  // It's pretty inconvenient for users of this function if it can return undefined, so in the case that
  // we have incomplete location just link to the bare search page.
  return `/g/${group.attributes.slug}/${searchType}`;
};
