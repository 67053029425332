import { useCallback, useState } from 'react';

import useThunkDispatch from '../hooks/useThunkDispatch';
import { Actions, JSONApi, Models } from '../types';

export type RelationshipsFieldManager<T extends Models.Base> = {
  add: (attachment: JSONApi.Resource<T>) => void;
  didUpload: () => void;
  isUploading: boolean;
  relationships: JSONApi.Resource<T>[];
  remove: (attachment: JSONApi.Resource<T>) => void;
  reset: () => void;
  uploadingFile: File | undefined;
  willUpload: (file: File) => void;
};

const useRelationshipsFieldManager = <T extends Models.Base>(
  initialRelationships: JSONApi.Resource<T>[],
  deleteAction: (relationship: JSONApi.Resource<T>) => Actions.ApiThunkAction<T>
) => {
  const dispatch = useThunkDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingFile, setUploadingFile] = useState<File | undefined>(undefined);
  const [relationships, setRelationships] = useState<JSONApi.Resource<T>[]>(
    initialRelationships ?? []
  );

  const add = useCallback(
    (relationship: JSONApi.Resource<T>) => {
      if (!relationships.includes(relationship)) {
        const newRelationships = [...relationships, relationship];
        setRelationships(newRelationships);
      }
    },
    [relationships]
  );

  const didUpload = useCallback(() => {
    setIsUploading(false);
    setUploadingFile(undefined);
  }, []);

  const willUpload = useCallback((file: File) => {
    setIsUploading(true);
    setUploadingFile(file);
  }, []);

  const reset = useCallback(() => {
    setRelationships([]);
  }, []);

  const remove = useCallback(
    (toRemove: JSONApi.Resource<T>) => {
      const newRelationships = relationships.filter(
        relationship => relationship.id !== toRemove.id
      );
      setRelationships(newRelationships);
      dispatch(deleteAction(toRemove));
    },
    [deleteAction, dispatch, relationships]
  );

  const relationshipsFieldManager: RelationshipsFieldManager<T> = {
    add,
    didUpload,
    isUploading,
    relationships,
    remove,
    reset,
    uploadingFile,
    willUpload
  };

  return relationshipsFieldManager;
};

export default useRelationshipsFieldManager;
