/*
 * this component overwrites IonDatetime with some default behavior we always need:
 * 1) replaces Ionic's default text values with our own internationalization-enabled ones
 */

import { IonDatetime } from '@ionic/react';
import React from 'react';
import { useIntl } from 'react-intl';

const Datetime = (props: React.ComponentPropsWithoutRef<typeof IonDatetime>) => {
  const intl = useIntl();

  return (
    // eslint-disable-next-line react/forbid-elements
    <IonDatetime
      cancelText={intl.formatMessage({ id: 'dictionary.cancel' })}
      doneText={intl.formatMessage({ id: 'dictionary.done' })}
      {...props}
    />
  );
};

export default Datetime;
