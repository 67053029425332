import { compose, createStore, PreloadedState } from 'redux';

import createMiddlewares from '../middleware';
import createRootReducer from '../reducers';
import { State } from '../types';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?? compose;

const configureStore = (preloadedState?: PreloadedState<State.Root>) =>
  createStore(createRootReducer(), preloadedState, composeEnhancers(createMiddlewares()));

const store = configureStore();
export default store;
