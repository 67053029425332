import { IonItem, IonLabel, IonList } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import AddSubscriptionLocationFilter from '../components/AddSubscriptionLocationFilter';
import FormTip from '../components/forms/FormTip';
import LocalLoadingIndicator from '../components/LocalLoadingIndicator';
import SubscriptionCheckbox from '../components/SubscriptionCheckbox';
import SubscriptionFrequencySelect from '../components/SubscriptionFrequencySelect';
import SubscriptionLocationFilterItem from '../components/SubscriptionLocationFilterItem';
import ToggleMemberField from '../components/ToggleMemberField';
import useApiData from '../hooks/useApiData';
import useCurrentUser from '../hooks/useCurrentUser';
import { getMemberSubscriptionLocationFilters, getMemberSubscriptions } from '../selectors';
import { loadSubscriptionLocationFilters, loadSubscriptions } from '../thunks/apiThunks';
import { JSONApi, Models, State } from '../types';

import './GroupNotificationsForm.scss';
import './SettingsList.scss';

type Props = {
  group: JSONApi.GroupResource;
  member: JSONApi.MemberResource;
};

const GroupNotificationsForm = ({ group, member }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const currentUser = useCurrentUser();

  const findSubscriptionLocationFilters = useCallback(
    () => getMemberSubscriptionLocationFilters(apiData, member),
    [apiData, member]
  );

  const { loading: loadingFilters, responseData: subscriptionLocationFilters } =
    useApiData<Models.SubscriptionLocationFilter>(
      loadSubscriptionLocationFilters(group.attributes.slug),
      findSubscriptionLocationFilters,
      'GroupNotificationsForm location filters'
    );

  const findSubscriptions = useCallback(
    () => getMemberSubscriptions(apiData, member),
    [apiData, member]
  );

  const { loading: loadingSubscriptions, responseData: subscriptions } =
    useApiData<Models.Subscription>(
      loadSubscriptions(group.attributes.slug),
      findSubscriptions,
      'GroupNotificationsForm load subscriptions'
    );

  return (
    <div className="group-notifications-form">
      {(loadingFilters || loadingSubscriptions) && <LocalLoadingIndicator />}
      {member.attributes.moderator && (
        <>
          <IonLabel className="settings-list-label">
            <FormattedMessage id="models.member.attributes.moderatorEmailSettings.label" />
          </IonLabel>
          <IonList className="ion-margin-bottom ion-no-padding settings-list">
            <IonItem>
              <ToggleMemberField
                attributeName="wantsModeratorEmails"
                group={group}
                member={member}
                tErrorKey="forms.subscription.update.error"
                tSuccessKey="forms.subscription.update.success"
              />
            </IonItem>
            <IonItem lines="none">
              <ToggleMemberField
                attributeName="wantsModeratorSupportEmails"
                group={group}
                member={member}
                tErrorKey="forms.subscription.update.error"
                tSuccessKey="forms.subscription.update.success"
              />
            </IonItem>
          </IonList>
        </>
      )}
      {subscriptions.map(subscription => (
        <div key={subscription.id}>
          <IonLabel className="settings-list-label">
            <FormattedMessage id="models.subscription.attributes.emailFrequency.label" />
          </IonLabel>
          <IonList className="ion-margin-bottom ion-no-padding settings-list">
            <IonItem lines="none">
              <SubscriptionFrequencySelect group={group} subscription={subscription} />
            </IonItem>
          </IonList>
          <IonLabel className="settings-list-label">
            <FormattedMessage id="models.subscription.attributes.emailTypes.header" />
          </IonLabel>
          <IonList className="ion-margin-bottom ion-no-padding settings-list">
            {group.attributes.forumEnabled && (
              <SubscriptionCheckbox
                group={group}
                name="emailDiscussions"
                subscription={subscription}
              />
            )}
            {group.attributes.newsEnabled && (
              <SubscriptionCheckbox
                group={group}
                name="emailNewsItems"
                subscription={subscription}
              />
            )}
            {group.attributes.eventsEnabled && (
              <SubscriptionCheckbox group={group} name="emailEvents" subscription={subscription} />
            )}
            {group.attributes.careersEnabled && (
              <SubscriptionCheckbox
                group={group}
                name="emailJobPosts"
                subscription={subscription}
              />
            )}
            <SubscriptionCheckbox group={group} name="emailMentions" subscription={subscription} />
            <SubscriptionCheckbox
              group={group}
              lines="none"
              name="emailAcceptedInvites"
              subscription={subscription}
            />
          </IonList>
          {currentUser?.attributes?.hasAppInstalled && (
            <>
              <IonLabel className="settings-list-label">
                <FormattedMessage id="models.subscription.attributes.pushTypes.header" />
              </IonLabel>
              <IonList className="ion-margin-bottom ion-no-padding settings-list">
                {group.attributes.forumEnabled && (
                  <SubscriptionCheckbox
                    group={group}
                    name="pushDiscussions"
                    subscription={subscription}
                  />
                )}
                {group.attributes.newsEnabled && (
                  <SubscriptionCheckbox
                    group={group}
                    name="pushNewsItems"
                    subscription={subscription}
                  />
                )}
                {group.attributes.eventsEnabled && (
                  <SubscriptionCheckbox
                    group={group}
                    name="pushEvents"
                    subscription={subscription}
                  />
                )}
                {group.attributes.careersEnabled && (
                  <SubscriptionCheckbox
                    group={group}
                    name="pushJobPosts"
                    subscription={subscription}
                  />
                )}
                <SubscriptionCheckbox
                  group={group}
                  name="pushMentions"
                  subscription={subscription}
                />
                <SubscriptionCheckbox
                  group={group}
                  lines="none"
                  name="pushAcceptedInvites"
                  subscription={subscription}
                />
              </IonList>
            </>
          )}
        </div>
      ))}
      {group.attributes.locationFeaturesEnabled && (
        <>
          <IonLabel className="settings-list-label">
            <FormattedMessage id="pages.groupNotifications.locationFiltersHeader" />
          </IonLabel>
          <IonList className="ion-no-padding settings-list">
            {subscriptionLocationFilters.map(subscriptionLocationFilter => (
              <SubscriptionLocationFilterItem
                group={group}
                key={subscriptionLocationFilter.id}
                subscriptionLocationFilter={subscriptionLocationFilter}
              />
            ))}
            <AddSubscriptionLocationFilter group={group} />
          </IonList>
          <FormTip id="pages.groupNotifications.locationFiltersExplanation" />
        </>
      )}
    </div>
  );
};

export default GroupNotificationsForm;
