import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import ModerateItemFlags from '../components/ModerateItemFlags';
import { getPostAuthor } from '../selectors';
import { getPostBody, getPostPath } from '../services/postUtils';
import { JSONApi, State } from '../types';

type Props = {
  flaggedItem: JSONApi.FlaggedItemResource;
  group: JSONApi.GroupResource;
  post: JSONApi.PostResource;
};

const ModeratePostFlags = ({ flaggedItem, group, post }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const intl = useIntl();

  const author = getPostAuthor(apiData, post);

  const renderFlaggedContent = useCallback(
    () => (
      <>
        <strong>{post.attributes.title}</strong>
        <div>{getPostBody(post)}</div>
      </>
    ),
    [post]
  );

  return (
    <ModerateItemFlags
      flaggedItem={flaggedItem}
      flaggedItemAuthor={author}
      group={group}
      rejectErrorKey="forms.moderatePost.reject.error"
      rejectSuccessKey="forms.moderatePost.reject.success"
      renderFlaggedContent={renderFlaggedContent}
      typeBadgeLabel={intl.formatMessage({ id: `models.${post.type}.badge` })}
      viewFlaggedLink={getPostPath(post, group)}
    />
  );
};

export default ModeratePostFlags;
