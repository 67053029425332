import { IonButton, IonContent, IonRouterLink } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import EditClosedMemberProfilePage from '../components/EditClosedMemberProfilePage';
import EditMemberProfileForm from '../components/forms/EditMemberProfileForm';
import FormSubmit from '../components/forms/FormSubmit';
import FormTip from '../components/forms/FormTip';
import Page from '../components/layout/Page';
import PageHeader from '../components/layout/PageHeader';
import PageTitle from '../components/layout/PageTitle';
import LeaveGroupModal from '../components/LeaveGroupModal';
import Nbsp from '../components/Nbsp';
import useOpener from '../hooks/useOpener';
import { JSONApi, ModelAttributes } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  member: JSONApi.MemberResource;
};

const EditMemberProfilePage = ({ group, member }: Props) => {
  const leaveGroupModalOpener = useOpener();

  if (member.attributes.status === ModelAttributes.MemberStatus.SELF_CLOSED) {
    return <EditClosedMemberProfilePage group={group} member={member} />;
  }

  return (
    <Page className="centered tight">
      <PageHeader defaultBackHref="/me-and-my-data/account" />
      <PageTitle id="pages.editMemberProfile.title" values={{ group: group.attributes.name }} />
      <IonContent className="custom-form-wrapper ion-padding">
        <div>
          <h1 className="ion-no-margin ion-padding-end ion-padding-start ion-padding-top">
            <FormattedMessage
              id="pages.editMemberProfile.title"
              values={{ group: group.attributes.name }}
            />
          </h1>
          <p className="ion-padding-bottom ion-padding-end ion-padding-start">
            <FormattedMessage id="dictionary.or" />
            <Nbsp />
            <IonRouterLink
              routerLink={`/g/${group.attributes.slug}/members/${member.attributes.slug}`}
            >
              <FormattedMessage id="dictionary.viewYourProfile" />
            </IonRouterLink>
          </p>
          <EditMemberProfileForm group={group} member={member}>
            <FormSubmit className="ion-margin-bottom">
              <FormattedMessage id="dictionary.save" />
            </FormSubmit>
            <IonButton
              color="danger"
              disabled={member.attributes.role === ModelAttributes.MemberRole.OWNER}
              expand="block"
              onClick={leaveGroupModalOpener.toggle}
            >
              <FormattedMessage id="forms.editMember.leaveGroup" />
            </IonButton>
            {member.attributes.role === ModelAttributes.MemberRole.OWNER && (
              <FormTip color="danger" id="forms.editMember.cantLeaveGroup" />
            )}
          </EditMemberProfileForm>
        </div>
      </IonContent>
      <LeaveGroupModal group={group} member={member} opener={leaveGroupModalOpener} />
    </Page>
  );
};

export default EditMemberProfilePage;
