import { IonSelect, IonSelectOption, SelectChangeEventDetail } from '@ionic/react';
import React, { useCallback } from 'react';

import { alertError, alertSuccess } from '../actions/notificationActions';
import useThunkDispatch from '../hooks/useThunkDispatch';
import subscriptionFrequencies from '../i18n/subscriptionFrequencies';
import { capitalizeString } from '../services/stringUtils';
import { updateSubscription } from '../thunks/apiThunks';
import { JSONApi, ModelAttributes } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  subscription: JSONApi.SubscriptionResource;
};

const SubscriptionFrequencySelect = ({ group, subscription }: Props) => {
  const dispatch = useThunkDispatch();

  const handleIonChange = useCallback(
    (event: CustomEvent<SelectChangeEventDetail>) => {
      const target = event.target as HTMLIonSelectElement & typeof event.target;
      if (target) {
        const emailFrequency = target.value as ModelAttributes.SubscriptionFrequency;
        if (emailFrequency !== subscription.attributes.emailFrequency) {
          dispatch(updateSubscription(group.attributes.slug, { emailFrequency }, subscription.id))
            .then(() => {
              dispatch(alertSuccess('forms.subscription.update.success'));
            })
            .catch(() => {
              dispatch(alertError('forms.subscription.update.error'));
            });
        }
      }
    },
    [dispatch, group.attributes.slug, subscription]
  );

  return (
    <IonSelect onIonChange={handleIonChange} value={subscription.attributes.emailFrequency}>
      {subscriptionFrequencies.map(frequency => (
        <IonSelectOption key={frequency} value={frequency}>
          {capitalizeString(frequency)}
        </IonSelectOption>
      ))}
    </IonSelect>
  );
};

export default SubscriptionFrequencySelect;
