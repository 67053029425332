import { IonChip, IonIcon, IonLabel } from '@ionic/react';
import { briefcase, pin } from 'ionicons/icons';
import React from 'react';

import PostCard from '../components/PostCard';
import { truncateString } from '../services/stringUtils';
import { JSONApi } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  jobPost: JSONApi.JobPostResource;
};

const JobPostCard = ({ group, jobPost }: Props) => (
  <PostCard
    body={jobPost.attributes.description}
    group={group}
    href={`/g/${group.attributes.slug}/job-board/${jobPost.attributes.slug}`}
    image={jobPost.attributes.ogImage}
    post={jobPost}
  >
    <div className="ion-padding-bottom ion-padding-top">
      <div>
        <IonChip color="secondary">
          <IonIcon icon={briefcase} />
          <IonLabel>{jobPost.attributes.companyName}</IonLabel>
        </IonChip>
      </div>
      {jobPost.attributes.location && jobPost.attributes.location.length > 0 && (
        <div>
          <IonChip color="secondary">
            <IonIcon icon={pin} />
            <IonLabel>{truncateString(jobPost.attributes.location, 30)}</IonLabel>
          </IonChip>
        </div>
      )}
    </div>
  </PostCard>
);

export default JobPostCard;
