import React from 'react';
import ReactImageLightbox from 'react-image-lightbox';

import { Opener } from '../hooks/useOpener';

import 'react-image-lightbox/style.css';
import './Lightbox.scss';

const Lightbox = ({
  opener,
  ...props
}: Omit<React.ComponentPropsWithoutRef<typeof ReactImageLightbox>, 'onCloseRequest'> & {
  opener: Opener;
}) => {
  if (opener.isOpen) {
    return <ReactImageLightbox onCloseRequest={opener.close} {...props} />;
  }

  return null;
};

export default Lightbox;
