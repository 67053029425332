import { InputChangeEventDetail, IonItem, IonText, IonToggle } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Search } from '../types';

type ExtendedSearchParams<T extends Search.Params> = T & { upcoming: string | null };

type Props<T extends Search.Params> = {
  searchParams: ExtendedSearchParams<T>;
  setSearchParams: (value: ExtendedSearchParams<T>) => void;
};

function SearchUpcoming<T extends Search.Params>({ searchParams, setSearchParams }: Props<T>) {
  const handleIonChange = useCallback(
    (event: CustomEvent<InputChangeEventDetail>) => {
      const target = event.target as HTMLInputElement | null;
      if (target) {
        setSearchParams({ ...searchParams, upcoming: target.checked.toString() });
      }
    },
    [searchParams, setSearchParams]
  );

  return (
    <IonItem className="compact" lines="none">
      <IonToggle
        checked={searchParams.upcoming === 'true'}
        onIonChange={handleIonChange}
        value="true"
      />
      <IonText color="primary">
        <FormattedMessage id="models.search.attributes.upcoming" />
      </IonText>
    </IonItem>
  );
}

export default SearchUpcoming;
