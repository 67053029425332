import { Keyboard } from '@capacitor/keyboard';
import { isPlatform } from '@ionic/react';
import React, { createContext, useEffect, useState } from 'react';

export enum KeyboardStatus {
  CLOSED = 'closed',
  CLOSING = 'closing',
  OPEN = 'open',
  OPENING = 'opening'
}

export const KeyboardContext = createContext(KeyboardStatus.CLOSED);

type Props = {
  children: React.ReactNode;
};

const KeyboardStatusProvider = ({ children }: Props) => {
  const [status, setStatus] = useState<KeyboardStatus>(KeyboardStatus.CLOSED);

  useEffect(() => {
    if (isPlatform('capacitor')) {
      const keyboardWillShowListener = Keyboard.addListener('keyboardWillShow', () => {
        setStatus(KeyboardStatus.OPENING);
      });

      const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
        setStatus(KeyboardStatus.OPEN);
      });

      const keyboardWillHideListener = Keyboard.addListener('keyboardWillHide', () => {
        setStatus(KeyboardStatus.CLOSING);
      });

      const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
        setStatus(KeyboardStatus.CLOSED);
      });

      return () => {
        keyboardWillShowListener.remove();
        keyboardDidShowListener.remove();
        keyboardWillHideListener.remove();
        keyboardDidHideListener.remove();
      };
    }
  }, []);

  return <KeyboardContext.Provider value={status}>{children}</KeyboardContext.Provider>;
};

export default KeyboardStatusProvider;
