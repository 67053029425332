import { IonContent } from '@ionic/react';
import equal from 'deep-equal';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { alertError } from '../actions/notificationActions';
import BlockedConversationItem from '../components/BlockedConversationItem';
import BlockedItem from '../components/BlockedItem';
import BlockedItemList from '../components/BlockedItemList';
import Page from '../components/layout/Page';
import PageHeader from '../components/layout/PageHeader';
import LocalLoadingIndicator from '../components/LocalLoadingIndicator';
import MemberAvatar from '../components/MemberAvatar';
import useApiData from '../hooks/useApiData';
import useThunkDispatch from '../hooks/useThunkDispatch';
import {
  getBlockedResource,
  getBlocks,
  getCommentCreator,
  getNoteCreator,
  getPostAuthor
} from '../selectors';
import { dispatchAndAlert } from '../services/dispatchUtils';
import { deleteBlock, loadBlocks } from '../thunks/apiThunks';
import { JSONApi, Models, State } from '../types';

type Props = {
  group: JSONApi.GroupResource;
};

type BlockedResources = {
  comments: JSONApi.CommentResource[];
  conversations: JSONApi.ConversationResource[];
  members: JSONApi.MemberResource[];
  notes: JSONApi.NoteResource[];
  posts: JSONApi.PostResource[];
};

const BlocksPage = ({ group }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const dispatch = useThunkDispatch();

  const findBlocks = useCallback(() => getBlocks(apiData, group), [apiData, group]);
  const { loading, responseData: blocks } = useApiData<Models.Block>(
    loadBlocks(group.attributes.slug),
    findBlocks,
    'BlocksPage'
  );

  const blockedResources: BlockedResources = {
    comments: [],
    conversations: [],
    members: [],
    notes: [],
    posts: []
  };

  blocks.forEach(block => {
    const blockedResource = getBlockedResource(apiData, block);
    switch (blockedResource.type) {
      case 'comment':
        blockedResources.comments.push(blockedResource as JSONApi.CommentResource);
        break;
      case 'conversation':
        blockedResources.conversations.push(blockedResource as JSONApi.ConversationResource);
        break;
      case 'member':
        blockedResources.members.push(blockedResource as JSONApi.MemberResource);
        break;
      case 'note':
        blockedResources.notes.push(blockedResource as JSONApi.NoteResource);
        break;
      default:
        blockedResources.posts.push(blockedResource as JSONApi.PostResource);
        break;
    }
  });

  const generateHandleDeleteClick = useCallback(
    (blockedResource: JSONApi.BaseResource) => () => {
      const block = blocks.find(block =>
        equal(blockedResource, getBlockedResource(apiData, block))
      );
      if (block) {
        dispatchAndAlert(
          dispatch,
          deleteBlock(group.attributes.slug, block),
          'forms.block.delete.success',
          'forms.block.delete.error'
        );
      } else {
        dispatch(alertError('forms.block.delete.error'));
      }
    },
    [apiData, blocks, dispatch, group.attributes.slug]
  );

  if (loading) {
    return <LocalLoadingIndicator />;
  }

  return (
    <Page className="centered wide">
      <PageHeader
        defaultBackHref={`/g/${group.attributes.slug}`}
        titleKey="pages.blocks.titleShort"
      />
      <IonContent className="canvas" scrollEvents>
        {blocks.length === 0 ? (
          <div className="ion-padding-end ion-padding-start">
            <h2>
              <FormattedMessage id="pages.blocks.none" />
            </h2>
          </div>
        ) : (
          <>
            {blockedResources.members.length > 0 && (
              <BlockedItemList titleKey="models.member.label">
                {blockedResources.members.map((member, idx) => (
                  <BlockedItem
                    isLast={idx === blockedResources.members.length - 1}
                    key={member.id}
                    onClick={generateHandleDeleteClick(member)}
                  >
                    <MemberAvatar member={member} slot="start" />
                    {member.attributes.fullName}
                  </BlockedItem>
                ))}
              </BlockedItemList>
            )}
            {blockedResources.posts.length > 0 && (
              <BlockedItemList titleKey="models.post.label">
                {blockedResources.posts.map((post, idx) => (
                  <BlockedItem
                    isLast={idx === blockedResources.posts.length - 1}
                    key={post.id}
                    onClick={generateHandleDeleteClick(post)}
                  >
                    <MemberAvatar member={getPostAuthor(apiData, post)} slot="start" />
                    {post.attributes.title}
                  </BlockedItem>
                ))}
              </BlockedItemList>
            )}
            {blockedResources.comments.length > 0 && (
              <BlockedItemList titleKey="models.comment.label">
                {blockedResources.comments.map((comment, idx) => (
                  <BlockedItem
                    isLast={idx === blockedResources.comments.length - 1}
                    key={comment.id}
                    onClick={generateHandleDeleteClick(comment)}
                  >
                    <MemberAvatar member={getCommentCreator(apiData, comment)} slot="start" />
                    {comment.attributes.content}
                  </BlockedItem>
                ))}
              </BlockedItemList>
            )}
            {blockedResources.conversations.length > 0 && (
              <BlockedItemList titleKey="models.conversation.label">
                {blockedResources.conversations.map((conversation, idx) => (
                  <BlockedConversationItem
                    conversation={conversation}
                    isLast={idx === blockedResources.conversations.length - 1}
                    key={conversation.id}
                    onClick={generateHandleDeleteClick(conversation)}
                  />
                ))}
              </BlockedItemList>
            )}
            {blockedResources.notes.length > 0 && (
              <BlockedItemList titleKey="models.note.label">
                {blockedResources.notes.map((note, idx) => (
                  <BlockedItem
                    isLast={idx === blockedResources.notes.length - 1}
                    key={note.id}
                    onClick={generateHandleDeleteClick(note)}
                  >
                    <MemberAvatar member={getNoteCreator(apiData, note)} slot="start" />
                    {note.attributes.note}
                  </BlockedItem>
                ))}
              </BlockedItemList>
            )}
          </>
        )}
      </IonContent>
    </Page>
  );
};

export default BlocksPage;
