import React from 'react';

import FormModal from '../components/FormModal';
import NewsItemForm from '../components/forms/NewsItemForm';
import { Opener } from '../hooks/useOpener';
import { JSONApi } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  newsItem?: JSONApi.NewsItemResource;
  onSuccess?: () => void;
  opener: Opener;
  titleKey: string;
};

const NewsItemFormModal = ({ group, newsItem, onSuccess, opener, titleKey }: Props) => (
  <FormModal keyboardClose={false} opener={opener} titleKey={titleKey}>
    {modalContentRef => (
      <NewsItemForm
        autofocus={opener.isPresented}
        contentForwardRef={modalContentRef}
        group={group}
        newsItem={newsItem}
        onSuccess={onSuccess}
        opener={opener}
      />
    )}
  </FormModal>
);

export default NewsItemFormModal;
