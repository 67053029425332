import { useEffect } from 'react';

import useThunkDispatch from '../hooks/useThunkDispatch';
import { updateUnreadCount } from '../thunks/apiThunks';
import { JSONApi, Models } from '../types';

const usePageVisitTracker = (group: JSONApi.GroupResource, type: Models.Readable) => {
  const dispatch = useThunkDispatch();

  useEffect(() => {
    // the timeout is a hacky way to avoid a race condition with marking conversations as read
    setTimeout(() => {
      dispatch(updateUnreadCount(group.attributes.slug, type)).catch(() => {
        // We don't really need to handle this
      });
    }, 250);
  }, [dispatch, group.attributes.slug, type]);
};

export default usePageVisitTracker;
