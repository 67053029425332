import { FormApi } from 'final-form';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormInput from '../../components/forms/FormInput';
import FormItem from '../../components/forms/FormItem';
import FormSubmit from '../../components/forms/FormSubmit';
import FormTip from '../../components/forms/FormTip';
import SubmitError from '../../components/forms/SubmitError';
import { maxTitleLength } from '../../globals';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { AjaxResponse } from '../../services/ajaxRequest';
import { handleFormSubmission } from '../../services/formUtils';
import { createTraitOption } from '../../thunks/apiThunks';
import { JSONApi, Models } from '../../types';

type FormValues = Models.TraitOptionFormData;

type Props = {
  autofocus: boolean;
  group: JSONApi.GroupResource;
  onSuccess?: (response: AjaxResponse<Models.TraitOption>) => void;
  trait: JSONApi.TraitResource;
};

const TraitOptionForm = ({ autofocus, group, onSuccess, trait }: Props) => {
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const initialValues: FormValues = {
    title: ''
  };

  const handleSubmit = useCallback(
    async (values: FormValues, form: FormApi<FormValues>) =>
      handleFormSubmission({
        action: createTraitOption(group.attributes.slug, trait.id, values),
        dispatch,
        errorTranslationLocation: 'traitOption',
        form,
        intl,
        isMounted,
        onSuccess,
        successTKey: 'forms.traitOption.create.success',
        values
      }),
    [dispatch, group.attributes.slug, intl, isMounted, onSuccess, trait.id]
  );

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required(intl.formatMessage({ id: 'errors.traitOption.title.blank' }))
      .max(
        maxTitleLength,
        intl.formatMessage({ id: 'errors.traitOption.title.tooLong' }, { count: maxTitleLength })
      )
  });

  return (
    <FormContainer<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ form, handleSubmit }) => (
        <Form<FormValues> onFormSubmit={handleSubmit} submit={form.submit}>
          <SubmitError />
          <FormError name="title" />
          <FormItem id="models.traitOption.attributes.title.label" required>
            <FormInput
              autofocus={autofocus}
              name="title"
              placeholder={intl.formatMessage({
                id: 'models.traitOption.attributes.title.placeholder'
              })}
            />
          </FormItem>
          <div className="ion-padding-end ion-padding-start">
            <FormTip id="models.traitOption.attributes.title.tip" />
          </div>
          <div className="ion-padding">
            <FormSubmit>
              <FormattedMessage id="dictionary.add" />
            </FormSubmit>
          </div>
        </Form>
      )}
    </FormContainer>
  );
};

export default TraitOptionForm;
