import { IonCard } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { alertSuccess } from '../actions/notificationActions';
import FormSubmit from '../components/forms/FormSubmit';
import GroupFeaturesForm from '../components/forms/GroupFeaturesForm';
import ModeratorPage from '../components/layout/ModeratorPage';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { JSONApi } from '../types';

import '../components/forms/CustomForm.scss';

type Props = {
  group: JSONApi.GroupResource;
};

const ManageGroupFeaturesPage = ({ group }: Props) => {
  const dispatch = useThunkDispatch();

  const handleSubmitSuccess = useCallback(() => {
    dispatch(alertSuccess('forms.group.update.success'));
  }, [dispatch]);

  return (
    <ModeratorPage group={group} h2Key="pages.manageGroup.features.title">
      {() => (
        <IonCard className="custom-form-wrapper ion-padding">
          <GroupFeaturesForm group={group} onSuccess={handleSubmitSuccess}>
            <FormSubmit>
              <FormattedMessage id="dictionary.update" />
            </FormSubmit>
          </GroupFeaturesForm>
        </IonCard>
      )}
    </ModeratorPage>
  );
};

export default ManageGroupFeaturesPage;
