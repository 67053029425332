import { IonCard } from '@ionic/react';
import { FormApi } from 'final-form';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import Form from '../components/forms/Form';
import FormCondition from '../components/forms/FormCondition';
import FormContainer from '../components/forms/FormContainer';
import FormError from '../components/forms/FormError';
import FormItem from '../components/forms/FormItem';
import FormSubmit from '../components/forms/FormSubmit';
import FormTextarea from '../components/forms/FormTextarea';
import FormToggleItem from '../components/forms/FormToggleItem';
import MembersCanSendInvitesField from '../components/forms/MembersCanSendInvitesField';
import SubmitError from '../components/forms/SubmitError';
import ModeratorPage from '../components/layout/ModeratorPage';
import LocalLoadingIndicator from '../components/LocalLoadingIndicator';
import { maxTextInputLength } from '../globals';
import useCurrentUser from '../hooks/useCurrentUser';
import useMountedTracking from '../hooks/useMountedTracking';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { handleFormSubmission } from '../services/formUtils';
import { updateGroup } from '../thunks/apiThunks';
import { JSONApi } from '../types';

type FormValues = {
  memberVerificationQuestion: string;
  memberVerificationRequired: boolean;
};

type Props = {
  group: JSONApi.GroupResource;
};

const ManageGroupSecuritySettingsPage = ({ group }: Props) => {
  const currentUser = useCurrentUser();
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const initialValues: FormValues = {
    memberVerificationQuestion: group.attributes.memberVerificationQuestion,
    memberVerificationRequired: group.attributes.memberVerificationRequired
  };

  const handleSubmit = useCallback(
    async (values: FormValues, form: FormApi<FormValues>) =>
      handleFormSubmission({
        action: updateGroup(values, group.id),
        dispatch,
        errorTranslationLocation: 'group',
        form,
        intl,
        isMounted,
        successTKey: 'forms.group.update.success',
        values
      }),
    [dispatch, group.id, intl, isMounted]
  );

  const validationSchema = Yup.object().shape({
    memberVerificationQuestion: Yup.string()
      .nullable()
      .max(
        maxTextInputLength,
        intl.formatMessage(
          { id: 'errors.group.memberVerificationQuestion.tooLong' },
          { count: maxTextInputLength }
        )
      ),
    memberVerificationRequired: Yup.boolean()
  });

  if (!currentUser) {
    return <LocalLoadingIndicator />;
  }

  return (
    <ModeratorPage group={group} h2Key="pages.manageGroup.security.title">
      {() => (
        <>
          <IonCard>
            <div className="ion-padding">
              <MembersCanSendInvitesField group={group} />
            </div>
          </IonCard>
          <IonCard>
            <FormContainer<FormValues>
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ form, handleSubmit }) => (
                <Form<FormValues> onFormSubmit={handleSubmit} submit={form.submit}>
                  <div className="ion-padding">
                    <SubmitError />
                    <FormError name="memberVerificationRequired" />
                    <FormToggleItem<FormValues, 'memberVerificationRequired'>
                      change={form.change}
                      id="models.group.attributes.memberVerificationRequired.label"
                      name="memberVerificationRequired"
                    />
                  </div>
                  <FormCondition name="memberVerificationRequired">
                    <FormError name="memberVerificationQuestion" />
                    <FormItem
                      id="models.group.attributes.memberVerificationQuestion.label"
                      position="stacked"
                    >
                      <FormTextarea
                        name="memberVerificationQuestion"
                        placeholder={intl.formatMessage({
                          id: 'models.group.attributes.memberVerificationQuestion.placeholder'
                        })}
                        rows={4}
                      />
                    </FormItem>
                  </FormCondition>
                  <div className="ion-padding">
                    <FormSubmit>
                      <FormattedMessage id="dictionary.save" />
                    </FormSubmit>
                  </div>
                </Form>
              )}
            </FormContainer>
          </IonCard>
        </>
      )}
    </ModeratorPage>
  );
};

export default ManageGroupSecuritySettingsPage;
