import { InputChangeEventDetail, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Search } from '../types';

type ExtendedSearchParams<T extends Search.Params> = T & { radius: string | null };

type Props<T extends Search.Params> = {
  searchParams: ExtendedSearchParams<T>;
  setSearchParams: (value: ExtendedSearchParams<T>) => void;
};

function SearchRadius<T extends Search.Params>({ searchParams, setSearchParams }: Props<T>) {
  const handleIonChange = useCallback(
    (event: CustomEvent<InputChangeEventDetail>) => {
      const target = event.target as HTMLInputElement | null;
      if (target) {
        setSearchParams({ ...searchParams, radius: target.value });
      }
    },
    [searchParams, setSearchParams]
  );

  return (
    <>
      <IonLabel>
        <FormattedMessage id="models.search.attributes.radius" />
      </IonLabel>
      <IonSelect onIonChange={handleIonChange} value={searchParams.radius}>
        <IonSelectOption value="5">
          <FormattedMessage id="models.search.attributes.radiusOption" values={{ miles: 10 }} />
        </IonSelectOption>
        <IonSelectOption value="25">
          <FormattedMessage id="models.search.attributes.radiusOption" values={{ miles: 25 }} />
        </IonSelectOption>
        <IonSelectOption value="50">
          <FormattedMessage id="models.search.attributes.radiusOption" values={{ miles: 50 }} />
        </IonSelectOption>
        <IonSelectOption value="100">
          <FormattedMessage id="models.search.attributes.radiusOption" values={{ miles: 100 }} />
        </IonSelectOption>
      </IonSelect>
    </>
  );
}

export default SearchRadius;
