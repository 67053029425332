import React from 'react';
import { Redirect } from 'react-router';

import useCurrentMember from '../hooks/useCurrentMember';

type Props = {
  children: React.ReactElement;
  redirect: string;
};

const RequireNoCurrentMember = ({ children, redirect }: Props) => {
  const currentMember = useCurrentMember();

  if (currentMember === undefined) {
    return null;
  }
  if (currentMember) {
    return <Redirect to={redirect} />;
  }
  return children;
};

export default RequireNoCurrentMember;
