import { alertError, alertSuccess } from '../actions/notificationActions';
import { Actions, Models } from '../types';

export const dispatchAndAlert = (
  dispatch: Actions.ThunkDispatch,
  action: Actions.ApiThunkAction<Models.Base>,
  successAlertKey: string,
  errorAlertKey: string,
  onSuccess?: () => void
) => {
  dispatch(action)
    .then(response => {
      response.dataLoadedIntoStatePromise.then(() => {
        dispatch(alertSuccess(successAlertKey));
        if (onSuccess) {
          onSuccess();
        }
      });
    })
    .catch(() => {
      dispatch(alertError(errorAlertKey));
    });
};
