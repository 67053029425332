import { IonText } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { JSONApi } from '../types';

type Props = {
  autoModerationResult: JSONApi.AutoModerationResultResource;
};

const AdminAutoModerationResult = ({ autoModerationResult }: Props) => {
  const presentValue = useCallback((value: number, tKey: string) => {
    const roundedValue = Math.round(value * 10000.0) / 100.0;
    return (
      <IonText color="primary">
        <FormattedMessage
          id={`models.autoModerationResult.${tKey}`}
          values={{ value: roundedValue }}
        />
      </IonText>
    );
  }, []);

  if (!autoModerationResult) {
    return null;
  }

  return (
    <>
      {/* eslint-disable-next-line react/forbid-elements */}
      <ul>
        <li className="ion-no-padding">
          {presentValue(
            autoModerationResult.attributes.problematicLikelihood,
            'problematicLikelihood'
          )}
        </li>
        <li className="ion-no-padding">
          {presentValue(autoModerationResult.attributes.minorLikelihood, 'minorLikelihood')}
        </li>
        <li className="ion-no-padding">
          {presentValue(autoModerationResult.attributes.offensiveLikelihood, 'offensiveLikelihood')}
        </li>
        <li className="ion-no-padding">
          {presentValue(
            autoModerationResult.attributes.partialNudityLikelihood,
            'partialNudityLikelihood'
          )}
        </li>
        <li className="ion-no-padding">
          {presentValue(autoModerationResult.attributes.rawNudityLikelihood, 'rawNudityLikelihood')}
        </li>
        <li className="ion-no-padding">
          {presentValue(autoModerationResult.attributes.scamLikelihood, 'scamLikelihood')}
        </li>
        <li className="ion-no-padding">
          {presentValue(autoModerationResult.attributes.weaponLikelihood, 'weaponLikelihood')}
        </li>
      </ul>
    </>
  );
};

export default AdminAutoModerationResult;
