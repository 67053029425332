/*
 * capacitor 3 includes a schema migration for how storage is managed
 * to accommodate, we must include the `migrate()` call before performing
 * any storage operation until we can be sure all our local storage has
 * been migrated
 */

import { Storage } from '@capacitor/storage';

async function set(key: string, value: string): Promise<void> {
  await Storage.migrate();
  await Storage.set({ key, value });
}

async function get(key: string): Promise<string | null> {
  await Storage.migrate();
  return (await Storage.get({ key })).value;
}

async function remove(key: string): Promise<void> {
  await Storage.migrate();
  await Storage.remove({ key });
}

export const storage = { get, remove, set };
