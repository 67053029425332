import { IonContent, IonItem } from '@ionic/react';
import React, { createRef } from 'react';
import { FormattedMessage } from 'react-intl';

import Page from '../../components/layout/Page';
import PageHeader from '../../components/layout/PageHeader';

type Props = {
  children: (contentRef: React.RefObject<HTMLIonContentElement>) => React.ReactElement;
  h2Key: string;
};

const AdminPage = ({ children, h2Key }: Props) => {
  const contentRef = createRef<HTMLIonContentElement>();

  return (
    <Page className="centered wide">
      <PageHeader defaultBackHref="/admin" titleKey="pages.admin.headerTitle" />
      <IonContent ref={contentRef} scrollEvents>
        <IonItem color="transparent" lines="none">
          <h2>
            <FormattedMessage id={h2Key} />
          </h2>
        </IonItem>
        {children(contentRef)}
      </IonContent>
    </Page>
  );
};

export default AdminPage;
