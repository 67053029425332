import { IonButtons, IonItem, IonLabel, IonList, IonText } from '@ionic/react';
import { DateTime } from 'luxon';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Currency from '../components/Currency';
import ExternalButton from '../components/ExternalButton';
import LocalLoadingIndicator from '../components/LocalLoadingIndicator';
import { JSONApi } from '../types';

import './SettingsList.scss';

type Props = {
  invoices: JSONApi.InvoiceResource[] | undefined;
};

const InvoiceList = ({ invoices }: Props) => {
  if (!invoices) {
    return <LocalLoadingIndicator />;
  }

  return (
    <IonList className="ion-no-padding settings-list">
      {invoices.map((invoice, index) => (
        <IonItem
          color="light"
          key={invoice.id}
          lines={index === invoices.length - 1 ? 'none' : 'full'}
        >
          <IonText slot="start">
            {invoice.attributes.createdAt.toLocaleString(DateTime.DATE_FULL)}
          </IonText>
          <IonLabel>
            <Currency valueInCents={invoice.attributes.total} />
          </IonLabel>
          <IonButtons slot="end">
            <ExternalButton href={invoice.attributes.hostedInvoiceUrl}>
              <FormattedMessage id="dictionary.view" />
            </ExternalButton>
          </IonButtons>
        </IonItem>
      ))}
    </IonList>
  );
};

export default InvoiceList;
