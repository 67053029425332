import { Browser } from '@capacitor/browser';
import { IonItem } from '@ionic/react';
import React, { useCallback } from 'react';

const ExternalItemButton = ({
  href,
  ...ionItemProps
}: Omit<
  React.ComponentPropsWithoutRef<typeof IonItem>,
  'button' | 'onClick' | 'onKeyDown' | 'rel' | 'role' | 'target'
> & {
  href: string;
}) => {
  const handleClick = useCallback(() => {
    Browser.open({ url: href });
  }, [href]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLIonItemElement>) => {
      if (event.key === 'Enter') {
        Browser.open({ url: href });
      }
    },
    [href]
  );

  return (
    <IonItem
      {...ionItemProps}
      button
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      rel="noopener noreferrer"
      role="button"
      target="_blank"
    />
  );
};

export default ExternalItemButton;
