import { IonItem, IonLabel, IonText } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import ExternalLink from '../../components/ExternalLink';
import EditUserAvatar from '../../components/forms/EditUserAvatar';
import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormInput from '../../components/forms/FormInput';
import FormSearchableSelect from '../../components/forms/FormSearchableSelect';
import FormToggleItem from '../../components/forms/FormToggleItem';
import SubmitError from '../../components/forms/SubmitError';
import Nbsp from '../../components/Nbsp';
import { maxTextInputLength } from '../../globals';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { handleFormSubmission } from '../../services/formUtils';
import { cityQueryHandler } from '../../services/placeSearch';
import { updateUser } from '../../thunks/apiThunks';
import { JSONApi } from '../../types';

type FormValues = {
  acceptedTerms: boolean;
  acceptedTransactionalEmail: boolean;
  firstName: string;
  lastName: string;
  location: string | undefined;
};

const SetupAccountForm = ({
  children,
  currentUser,
  group,
  onSuccess
}: React.PropsWithChildren<{
  currentUser: JSONApi.UserResource;
  group: JSONApi.GroupResource;
  onSuccess: () => void;
}>) => {
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const initialValues: FormValues = {
    acceptedTerms: currentUser.attributes.acceptedTerms ?? false,
    acceptedTransactionalEmail: currentUser.attributes.acceptedTransactionalEmail ?? false,
    firstName: currentUser.attributes.firstName ?? '',
    lastName: currentUser.attributes.lastName ?? '',
    location: currentUser.attributes.location ?? undefined
  };

  const handleSubmit = useCallback(
    async (values: FormValues) =>
      handleFormSubmission({
        action: updateUser(values, currentUser.id),
        dispatch,
        errorTranslationLocation: 'user',
        intl,
        isMounted,
        onSuccess,
        values
      }),
    [currentUser.id, dispatch, intl, isMounted, onSuccess]
  );

  const validationSchema = Yup.object().shape({
    acceptedTerms: Yup.boolean()
      .required(intl.formatMessage({ id: 'errors.user.acceptedTerms.accepted' }))
      .oneOf([true], intl.formatMessage({ id: 'errors.user.acceptedTerms.accepted' })),
    acceptedTransactionalEmail: Yup.boolean()
      .required(intl.formatMessage({ id: 'errors.user.acceptedTransactionalEmail.accepted' }))
      .oneOf([true], intl.formatMessage({ id: 'errors.user.acceptedTransactionalEmail.accepted' })),
    firstName: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: 'errors.user.firstName.blank' }))
      .max(
        maxTextInputLength,
        intl.formatMessage({ id: 'errors.user.firstName.tooLong' }, { count: maxTextInputLength })
      ),
    lastName: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: 'errors.user.lastName.blank' }))
      .max(
        maxTextInputLength,
        intl.formatMessage({ id: 'errors.user.lastName.tooLong' }, { count: maxTextInputLength })
      ),
    location: Yup.string()
      .nullable()
      .max(
        maxTextInputLength,
        intl.formatMessage({ id: 'errors.user.location.tooLong' }, { count: maxTextInputLength })
      )
  });

  return (
    <FormContainer<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ form, handleSubmit }) => (
        <Form<FormValues>
          className="setup-account-form"
          onFormSubmit={handleSubmit}
          submit={form.submit}
        >
          <SubmitError />
          <FormError name="firstName" />
          <FormInput
            aria-label={intl.formatMessage({
              id: 'models.user.attributes.firstName.label'
            })}
            autocapitalize="on"
            autofocus
            name="firstName"
            placeholder={intl.formatMessage({
              id: 'models.user.attributes.firstName.placeholder'
            })}
            type="text"
          />
          <FormError name="lastName" />
          <FormInput
            aria-label={intl.formatMessage({
              id: 'models.user.attributes.lastName.label'
            })}
            autocapitalize="on"
            name="lastName"
            placeholder={intl.formatMessage({
              id: 'models.user.attributes.lastName.placeholder'
            })}
            type="text"
          />
          {group.attributes.locationFeaturesEnabled && (
            <>
              <FormError name="location" />
              <div className="searchable-select-wrapper-outer">
                <div className="searchable-select-wrapper-inner">
                  <FormSearchableSelect
                    aria-label={intl.formatMessage({
                      id: 'models.user.attributes.location.label'
                    })}
                    attributeNameTKey="models.user.attributes.location.label"
                    name="location"
                    placeholder={intl.formatMessage({
                      id: 'models.user.attributes.location.placeholder'
                    })}
                    queryHandler={cityQueryHandler}
                    type="text"
                  />
                </div>
              </div>
            </>
          )}
          <IonItem lines="none">
            <IonLabel color="primary" position="stacked">
              <FormattedMessage id="models.user.attributes.image.label" />
            </IonLabel>
            <EditUserAvatar user={currentUser} />
          </IonItem>
          <FormToggleItem<FormValues, 'acceptedTerms'>
            change={form.change}
            id="models.user.attributes.acceptedTerms.label"
            name="acceptedTerms"
          />
          <IonItem lines="none">
            <em>
              <IonText color="primary">
                <FormattedMessage id="legal.readThe" />
                <Nbsp />
                <ExternalLink href="https://www.grouplicate.com/terms">
                  <FormattedMessage id="legal.terms" />
                </ExternalLink>
                <Nbsp />
                <FormattedMessage id="dictionary.and" />
                <Nbsp />
                <ExternalLink href="https://www.grouplicate.com/privacy">
                  <FormattedMessage id="legal.privacy" />
                </ExternalLink>
              </IonText>
            </em>
          </IonItem>
          <FormToggleItem<FormValues, 'acceptedTransactionalEmail'>
            change={form.change}
            id="models.user.attributes.acceptedTransactionalEmail.label"
            name="acceptedTransactionalEmail"
          />
          {children}
        </Form>
      )}
    </FormContainer>
  );
};

export default SetupAccountForm;
