import { IonFab, IonFabButton, IonIcon, IonItem, IonLabel } from '@ionic/react';
import { attach, business, documentTextOutline, fileTrayFull, pin } from 'ionicons/icons';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import ExternalButton from '../components/ExternalButton';
import ExternalLink from '../components/ExternalLink';
import FormatText from '../components/FormatText';
import FormModal from '../components/FormModal';
import NewJobApplicationForm from '../components/forms/NewJobApplicationForm';
import JobPostFormModal from '../components/JobPostFormModal';
import Paragraph from '../components/Paragraph';
import PostImages from '../components/PostImages';
import PostPage, { PostPageProps } from '../components/PostPage';
import SearchChip from '../components/SearchChip';
import useOpener from '../hooks/useOpener';
import { getJobPostApplication, getJobPostAttachments } from '../selectors';
import { locationSearchUrl } from '../services/urlUtils';
import { JSONApi, Models, State } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  jobPost: JSONApi.JobPostResource;
};

const JobPostPage = ({ group, jobPost }: Props) => {
  const { companyName, companyUrl, description, location } = jobPost.attributes;

  const apiData = useSelector((root: State.Root) => root.api);
  const intl = useIntl();
  const applyFormModalOpener = useOpener();
  const editFormModalOpener = useOpener();

  const application = getJobPostApplication(apiData, jobPost);
  const attachments = getJobPostAttachments(apiData, jobPost);

  const postPageProps: PostPageProps<Models.JobPost> = {
    backHref: `/g/${group.attributes.slug}/job-board`,
    group,
    post: jobPost
  };

  if (jobPost.attributes.permissions.edit) {
    postPageProps.onEditClick = editFormModalOpener.toggle;
  }

  if (application) {
    postPageProps.fab = (
      <IonFab className="job-post-apply-fab" horizontal="end" slot="fixed" vertical="bottom">
        <IonFabButton
          aria-label={intl.formatMessage({ id: 'dictionary.apply' })}
          color="secondary"
          routerLink={`/g/${group.attributes.slug}/inbox/conversations/${application.attributes.slug}`}
        >
          <IonIcon icon={documentTextOutline} />
        </IonFabButton>
      </IonFab>
    );
  } else if (jobPost.attributes.permissions.apply) {
    postPageProps.fab = (
      <IonFab className="job-post-apply-fab" horizontal="end" slot="fixed" vertical="bottom">
        <IonFabButton
          aria-label={intl.formatMessage({ id: 'dictionary.apply' })}
          color="secondary"
          disabled={applyFormModalOpener.isOpen}
          onClick={applyFormModalOpener.toggle}
        >
          <IonIcon icon={documentTextOutline} />
        </IonFabButton>
      </IonFab>
    );
  }

  const locationLabel =
    location && location.length > 0 ? location : intl.formatMessage({ id: 'dictionary.remote' });
  const locationLink = locationSearchUrl(jobPost.attributes, 'job-board', group);

  const jobTypeLink =
    jobPost.attributes.jobType &&
    `/g/${group.attributes.slug}/job-board?jobType=${encodeURIComponent(
      jobPost.attributes.jobType
    )}`;

  const industryLink =
    jobPost.attributes.industry &&
    `/g/${group.attributes.slug}/job-board?industry=${encodeURIComponent(
      jobPost.attributes.industry
    )}`;

  return (
    <PostPage<Models.JobPost> {...postPageProps}>
      <FormModal
        keyboardClose={false}
        opener={applyFormModalOpener}
        titleKey="modals.newJobApplication.title"
      >
        {() => (
          <NewJobApplicationForm
            autofocus={applyFormModalOpener.isPresented}
            group={group}
            jobPost={jobPost}
            onSuccess={applyFormModalOpener.close}
          />
        )}
      </FormModal>
      <JobPostFormModal
        group={group}
        jobPost={jobPost}
        opener={editFormModalOpener}
        titleKey="modals.editJobPost.title"
      />
      {description && (
        <div className="ion-padding-end ion-padding-start">
          <Paragraph className="formatted-text">
            <FormatText autolink mentionable>
              {description}
            </FormatText>
          </Paragraph>
        </div>
      )}
      {attachments && attachments.length > 0 && (
        <div className="ion-padding-end ion-padding-start">
          {attachments.map(attachment => (
            <ExternalButton
              color="secondary"
              fill="outline"
              href={attachment.attributes.url}
              key={attachment.id}
              size="small"
            >
              <IonIcon icon={attach} />
              <span className="truncate">{attachment.attributes.filename}</span>
            </ExternalButton>
          ))}
        </div>
      )}
      <IonItem lines="inset">
        <IonLabel>
          <FormattedMessage id="models.jobPost.attributes.companyName.label" />
        </IonLabel>
        {companyUrl ? <ExternalLink href={companyUrl}>{companyName}</ExternalLink> : companyName}
      </IonItem>
      <IonItem lines="inset">
        <IonLabel>
          <FormattedMessage id="models.jobPost.attributes.location.label" />
        </IonLabel>
        <SearchChip icon={pin} label={locationLabel} link={locationLink} />
      </IonItem>
      {jobPost.attributes.jobType && (
        <IonItem lines="inset">
          <IonLabel>
            <FormattedMessage id="models.jobPost.attributes.jobType.label" />
          </IonLabel>
          <SearchChip
            icon={fileTrayFull}
            label={jobPost.attributes.jobType}
            link={jobTypeLink as string}
          />
        </IonItem>
      )}
      {jobPost.attributes.industry && (
        <IonItem color="transparent" lines="none">
          <IonLabel>
            <FormattedMessage id="models.jobPost.attributes.industry.label" />
          </IonLabel>
          <SearchChip
            icon={business}
            label={jobPost.attributes.industry}
            link={industryLink as string}
          />
        </IonItem>
      )}
      <PostImages post={jobPost} />
    </PostPage>
  );
};

export default JobPostPage;
