import { IonText } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import { alertError, alertSuccess } from '../actions/notificationActions';
import confirmEmailImage from '../assets/images/design/confirm-email.png';
import BasicLink from '../components/BasicLink';
import CheckInboxPage from '../components/CheckInboxPage';
import Heading from '../components/Heading';
import Img from '../components/Img';
import Paragraph from '../components/Paragraph';
import UserAccountWizardBackButton from '../components/UserAccountWizardBackButton';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { resetPassword } from '../thunks/apiThunks';
import { JSONApi } from '../types';

const CheckForPasswordRecoveryPage = () => {
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const intl = useIntl();

  const group = history.location.state
    ? (history.location.state as { group: JSONApi.GroupResource }).group
    : undefined;
  const passwordRecoveryEmail = history.location.state
    ? (history.location.state as { email: string }).email
    : undefined;

  const handleResendPasswordRecoveryEmail = useCallback(() => {
    if (passwordRecoveryEmail) {
      dispatch(resetPassword(group, passwordRecoveryEmail))
        .then(() => {
          dispatch(alertSuccess('forms.forgotPassword.create.success'));
        })
        .catch(() => {
          dispatch(alertError('forms.forgotPassword.create.failure'));
        });
    }
  }, [dispatch, group, passwordRecoveryEmail]);

  return (
    <CheckInboxPage titleId="dictionary.checkYourInbox">
      <UserAccountWizardBackButton routerLink={group ? `/g/${group.attributes.slug}/start` : '/'} />
      <div>
        <Img
          alt={intl.formatMessage({ id: 'pages.checkForPasswordRecovery.imageAlt' })}
          src={confirmEmailImage}
        />
        <div className="ion-text-center">
          <Heading level={2}>
            <FormattedMessage id="dictionary.checkYourInbox" />
          </Heading>
          <Paragraph>
            <FormattedMessage id="pages.checkForPasswordRecovery.pPrefix" />
            <strong>{passwordRecoveryEmail}</strong>
            <FormattedMessage id="pages.checkForPasswordRecovery.pSuffix" />
          </Paragraph>
        </div>
      </div>
      <IonText className="ion-padding-bottom ion-padding-top ion-text-center" color="primary">
        <BasicLink
          onClick={handleResendPasswordRecoveryEmail}
          tKey="pages.checkForPasswordRecovery.button"
        />
      </IonText>
    </CheckInboxPage>
  );
};

export default CheckForPasswordRecoveryPage;
