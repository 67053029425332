import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';

import MemberCard from '../components/MemberCard';
import { JSONApi } from '../types';

import './Cards.scss';

type Props = {
  group: JSONApi.GroupResource;
  members: JSONApi.MemberResource[];
};

const MembersList = ({ group, members }: Props) => {
  if (members.length === 0) {
    return null;
  }

  return (
    <IonGrid className="cards">
      <IonRow className="cards-inner">
        {members.map(member => (
          <IonCol className="card-wrapper" key={member.id} size="12" sizeLg="6" sizeXl="4">
            <MemberCard group={group} member={member} />
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
};

export default MembersList;
