import { IonText } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import './FormTip.scss';

const FormTip = ({
  color = 'medium',
  ...formattedMessageProps
}: Pick<React.ComponentProps<typeof IonText>, 'color'> &
  React.ComponentProps<typeof FormattedMessage>) => (
  <div className="form-tip">
    <IonText color={color}>
      <FormattedMessage {...formattedMessageProps} />
    </IonText>
  </div>
);

export default FormTip;
