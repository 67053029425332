import { IonButton, IonButtons, IonItem } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormSpy } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import EditMemberProfileForm from '../../components/forms/EditMemberProfileForm';
import FormSubmit from '../../components/forms/FormSubmit';
import Heading from '../../components/Heading';
import { WizardSteps } from '../../components/VerificationWizard';
import { getGroupRules } from '../../selectors';
import { JSONApi, State } from '../../types';

type Props = {
  group: JSONApi.GroupResource;
  member: JSONApi.MemberResource;
  setWizardStep: (step: WizardSteps) => void;
};

const VerificationEditProfileForm = ({ group, member, setWizardStep }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const rules = getGroupRules(apiData, group);

  const handleBackClick = useCallback(() => {
    setWizardStep(rules.length > 0 ? WizardSteps.RULES : WizardSteps.QUESTION);
  }, [rules.length, setWizardStep]);

  const handleSuccess = useCallback(() => {
    setWizardStep(WizardSteps.SETTINGS);
  }, [setWizardStep]);

  return (
    <div className="verification-edit-profile-form">
      <Heading level={2}>
        <FormattedMessage id="pages.verificationProfileSettings.h2" />
      </Heading>
      <EditMemberProfileForm group={group} member={member} onSuccess={handleSuccess}>
        <IonItem color="transparent" lines="none">
          <IonButtons slot="end">
            <IonButton color="primary" fill="outline" onClick={handleBackClick} size="default">
              <FormattedMessage id="dictionary.previous" />
            </IonButton>
            <FormSpy subscription={{ pristine: true }}>
              {form => {
                if (form.pristine) {
                  return (
                    <FormSubmit allowPristineSubmit color="secondary" fill="solid" size="default">
                      <FormattedMessage id="dictionary.skip" />
                    </FormSubmit>
                  );
                }
                return (
                  <FormSubmit allowPristineSubmit color="primary" fill="solid" size="default">
                    <FormattedMessage id="dictionary.next" />
                  </FormSubmit>
                );
              }}
            </FormSpy>
          </IonButtons>
        </IonItem>
      </EditMemberProfileForm>
    </div>
  );
};

export default VerificationEditProfileForm;
