import { useSelector } from 'react-redux';

import { findResource } from '../selectors';
import { Models, SessionState, State } from '../types';

const useCurrentGroup = () => {
  const currentGroupData = useSelector((root: State.Root) => root.session.currentGroup);
  const apiData = useSelector((root: State.Root) => root.api);

  if (currentGroupData.state === SessionState.LOADING) {
    return undefined;
  }

  // Second condition here shouldn't happen but makes TS happy
  if (currentGroupData.state !== SessionState.SUCCESS || !currentGroupData.slug) {
    return null;
  }

  return findResource<Models.Group>(apiData, 'group', 'slug', currentGroupData.slug, null);
};

export default useCurrentGroup;
