import { IonButton, IonCol, IonItem, IonList, IonRow } from '@ionic/react';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { alertSuccess } from '../../actions/notificationActions';
import Paragraph from '../../components/Paragraph';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { forceArray } from '../../services/arrayUtils';
import { createWelcome } from '../../thunks/apiThunks';
import { JSONApi } from '../../types';

type Props = {
  closeModal: () => void;
  group: JSONApi.GroupResource;
  member: JSONApi.MemberResource;
};

const WelcomeForm = ({ closeModal: handleCloseClick, group, member }: Props) => {
  const [conversation, setConversation] = useState<JSONApi.ConversationResource | undefined>(
    undefined
  );
  const dispatch = useThunkDispatch();

  const { slug } = group.attributes;

  const handleClick = useCallback(() => {
    dispatch(createWelcome(group.attributes.slug, member)).then(response => {
      dispatch(alertSuccess('forms.welcome.create.success'));
      setConversation(forceArray(response.data)[0]);
    });
  }, [dispatch, group.attributes.slug, member]);

  return (
    <IonList>
      {conversation ? (
        <>
          <IonItem color="transparent" lines="none">
            <Paragraph>
              <FormattedMessage
                id="forms.welcome.create.completed"
                values={{ name: member.attributes.firstName }}
              />
            </Paragraph>
          </IonItem>
          <IonRow>
            <IonCol>
              <IonButton
                color="secondary"
                expand="block"
                onClick={handleCloseClick}
                routerLink={`/g/${slug}/inbox/conversations/${conversation.attributes.slug}`}
              >
                <FormattedMessage id="forms.welcome.create.visit" />
              </IonButton>
            </IonCol>
          </IonRow>
        </>
      ) : (
        <>
          <IonItem color="transparent" lines="none">
            <Paragraph>
              <FormattedMessage
                id="forms.welcome.create.explainer"
                values={{ name: member.attributes.firstName }}
              />
            </Paragraph>
          </IonItem>
          <IonRow>
            <IonCol>
              <IonButton color="secondary" expand="block" onClick={handleClick}>
                <FormattedMessage id="forms.welcome.create.submitButton" />
              </IonButton>
            </IonCol>
          </IonRow>
        </>
      )}
    </IonList>
  );
};

export default WelcomeForm;
