import { IonLabel, IonList } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ExternalItemButton from '../components/ExternalItemButton';
import { JSONApi } from '../types';

import './SettingsList.scss';

type Props = {
  links: JSONApi.LinkResource[];
  tKey: string;
  tValues?: { [key: string]: string };
};

const LinksList = ({ links, tKey, tValues }: Props) => {
  if (links.length === 0) {
    return null;
  }

  return (
    <>
      <IonLabel className="settings-list-label" color="primary">
        <FormattedMessage id={tKey} values={tValues} />
      </IonLabel>
      <IonList className="ion-no-padding settings-list">
        {links.map((link, index) => (
          <ExternalItemButton
            href={link.attributes.uri}
            key={link.id}
            lines={index === links.length - 1 ? 'none' : 'full'}
          >
            {link.attributes.label}
          </ExternalItemButton>
        ))}
      </IonList>
    </>
  );
};

export default LinksList;
