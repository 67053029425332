import React, { useCallback } from 'react';
import { useField } from 'react-final-form';

import MentionsTextarea from '../../components/forms/MentionsTextarea';

// name is required for form fields
const FormMentionsTextarea = ({
  name,
  onChange,
  ...props
}: React.ComponentPropsWithoutRef<typeof MentionsTextarea> & {
  name: string;
  onChange?: ((text: string) => void) | undefined;
}) => {
  const field = useField(name, { subscription: { value: true } });
  // the checked, multiple, and type props from react-final-form do not pertain to mentions textareas
  // and we need to handle onChange manually during the onIonChange handler
  const { checked, multiple, onChange: onFieldChange, type, ...fieldTextareaProps } = field.input;

  const handleChange = useCallback(
    (text: string) => {
      onFieldChange({ target: { value: text } });
      if (onChange) {
        onChange(text);
      }
    },
    [onChange, onFieldChange]
  );

  return <MentionsTextarea {...fieldTextareaProps} {...props} onChange={handleChange} />;
};

export default FormMentionsTextarea;
