import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import BlocksPage from '../../components/BlocksPage';
import ConversationPage from '../../components/ConversationPage';
import DiscussionPage from '../../components/DiscussionPage';
import EventAttendeesPage from '../../components/EventAttendeesPage';
import EventPage from '../../components/EventPage';
import EventsPage from '../../components/EventsPage';
import ForumPage from '../../components/ForumPage';
import GalleryPage from '../../components/GalleryPage';
import GroupAboutPage from '../../components/GroupAboutPage';
import GroupHomePage from '../../components/GroupHomePage';
import InboxPage from '../../components/InboxPage';
import JobBoardPage from '../../components/JobBoardPage';
import JobPostPage from '../../components/JobPostPage';
import ManageGroupCustomizationPage from '../../components/ManageGroupCustomizationPage';
import ManageGroupFeaturesPage from '../../components/ManageGroupFeaturesPage';
import ManageGroupFlagPage from '../../components/ManageGroupFlagPage';
import ManageGroupFlagsPage from '../../components/ManageGroupFlagsPage';
import ManageGroupInvitesPage from '../../components/ManageGroupInvitesPage';
import ManageGroupLinksPage from '../../components/ManageGroupLinksPage';
import ManageGroupMembersPage from '../../components/ManageGroupMembersPage';
import ManageGroupPlanPage from '../../components/ManageGroupPlanPage';
import ManageGroupPostsPage from '../../components/ManageGroupPostsPage';
import ManageGroupRulesPage from '../../components/ManageGroupRulesPage';
import ManageGroupSecuritySettingsPage from '../../components/ManageGroupSecuritySettingsPage';
import ManageGroupTraitsPage from '../../components/ManageGroupTraitsPage';
import MemberProfilePage from '../../components/MemberProfilePage';
import MembersPage from '../../components/MembersPage';
import MentionsPage from '../../components/MentionsPage';
import ModeratorHomePage from '../../components/ModeratorHomePage';
import MoreSectionsPage from '../../components/MoreSectionsPage';
import NewsItemPage from '../../components/NewsItemPage';
import NewsPage from '../../components/NewsPage';
import RequireConversation from '../../components/RequireConversation';
import RequireMember from '../../components/RequireMember';
import RequireModerator from '../../components/RequireModerator';
import RequireOwner from '../../components/RequireOwner';
import RequirePost from '../../components/RequirePost';
import SavesPage from '../../components/SavesPage';
import useCurrentMember from '../../hooks/useCurrentMember';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { getUserUnreadCounts } from '../../selectors';
import {
  loadDiscussion,
  loadEvent,
  loadJobPost,
  loadNewsItem,
  loadUnreadCounts
} from '../../thunks/apiThunks';
import { JSONApi, Models, State } from '../../types';

export type RouteData = {
  path: string;
  render: () => React.ReactElement;
};

export type TabData = {
  icon: string;
  id: string;
  path: string;
  tKey: string;
  unreadCount?: number;
};

type Props = {
  children: (data: { routes: RouteData[]; tabs: TabData[] }) => React.ReactElement;
  currentGroup: JSONApi.GroupResource;
  currentUser: JSONApi.UserResource;
};

const TabDataProvider = ({ children, currentGroup, currentUser }: Props) => {
  const currentMember = useCurrentMember();
  const routeData: RouteData[] = [];
  const tabData: TabData[] = [];

  const apiData = useSelector((root: State.Root) => root.api);
  const dispatch = useThunkDispatch();
  type T = JSONApi.UnreadCountsResource | undefined;
  const [unreadCounts, setUnreadCounts] = useState<T>(undefined);

  useEffect(() => {
    dispatch(loadUnreadCounts(currentGroup.attributes.slug)).catch(() => {
      // We don't really need to handle this
    });
  }, [currentGroup.attributes.slug, dispatch]);

  useEffect(() => {
    setUnreadCounts(getUserUnreadCounts(apiData, currentUser, currentGroup));
  }, [apiData, currentGroup, currentUser]);

  const renderGroupHome = useCallback(
    () => <GroupHomePage currentUser={currentUser} group={currentGroup} />,
    [currentGroup, currentUser]
  );

  routeData.push({ path: `/g/:slug/home`, render: renderGroupHome });
  tabData.push({
    icon: 'custom-home',
    id: 'home',
    path: `/g/${currentGroup.attributes.slug}/home`,
    tKey: 'pages.groupHome.title'
  });

  const renderMembers = useCallback(() => <MembersPage group={currentGroup} />, [currentGroup]);

  const renderMemberProfile = useCallback(
    () => (
      <RequireMember group={currentGroup}>
        {member => <MemberProfilePage group={currentGroup} member={member} />}
      </RequireMember>
    ),
    [currentGroup]
  );

  routeData.push({ path: `/g/:slug/members`, render: renderMembers });
  routeData.push({ path: `/g/:slug/members/:memberSlug`, render: renderMemberProfile });
  tabData.push({
    icon: 'custom-members',
    id: 'members',
    path: `/g/${currentGroup.attributes.slug}/members`,
    tKey: 'pages.members.title',
    unreadCount: unreadCounts?.attributes?.memberCount
  });

  const renderInbox = useCallback(() => <InboxPage group={currentGroup} />, [currentGroup]);

  const renderConversation = useCallback(
    () => (
      <RequireConversation group={currentGroup}>
        {conversation => <ConversationPage conversation={conversation} group={currentGroup} />}
      </RequireConversation>
    ),
    [currentGroup]
  );

  routeData.push({ path: `/g/:slug/inbox`, render: renderInbox });
  routeData.push({
    path: `/g/:slug/inbox/conversations/:conversationSlug`,
    render: renderConversation
  });
  tabData.push({
    icon: 'custom-messages',
    id: 'inbox',
    path: `/g/${currentGroup.attributes.slug}/inbox`,
    tKey: 'pages.inbox.title',
    unreadCount: unreadCounts?.attributes?.conversationCount
  });

  const renderForum = useCallback(() => <ForumPage group={currentGroup} />, [currentGroup]);

  const renderForumPost = useCallback(
    () => (
      <RequirePost<Models.Discussion>
        group={currentGroup}
        loadPost={loadDiscussion}
        redirect={`/g/${currentGroup.attributes.slug}/forum`}
        resourceType="discussion"
      >
        {discussion => <DiscussionPage discussion={discussion} group={currentGroup} />}
      </RequirePost>
    ),
    [currentGroup]
  );

  if (currentGroup.attributes.forumEnabled) {
    routeData.push({ path: `/g/:slug/forum`, render: renderForum });
    routeData.push({ path: `/g/:slug/forum/:postSlug`, render: renderForumPost });
    tabData.push({
      icon: 'custom-feed',
      id: 'forum',
      path: `/g/${currentGroup.attributes.slug}/forum`,
      tKey: 'pages.forum.title',
      unreadCount: unreadCounts?.attributes?.discussionCount ?? 0
    });
  }

  const renderNewsItem = useCallback(
    () => (
      <RequirePost<Models.NewsItem>
        group={currentGroup}
        loadPost={loadNewsItem}
        redirect={`/g/${currentGroup.attributes.slug}/news`}
        resourceType="newsItem"
      >
        {newsItem => <NewsItemPage group={currentGroup} newsItem={newsItem} />}
      </RequirePost>
    ),
    [currentGroup]
  );

  const renderNews = useCallback(
    () => <NewsPage currentUser={currentUser} group={currentGroup} />,
    [currentGroup, currentUser]
  );

  if (currentGroup.attributes.newsEnabled) {
    routeData.push({ path: `/g/:slug/news`, render: renderNews });
    routeData.push({ path: `/g/:slug/news/:postSlug`, render: renderNewsItem });
    tabData.push({
      icon: 'custom-news',
      id: 'news',
      path: `/g/${currentGroup.attributes.slug}/news`,
      tKey: 'pages.news.title',
      unreadCount: unreadCounts?.attributes?.newsItemCount ?? 0
    });
  }

  const renderEvents = useCallback(() => <EventsPage group={currentGroup} />, [currentGroup]);

  const renderEvent = useCallback(
    () => (
      <RequirePost<Models.ReturnedEvent>
        group={currentGroup}
        loadPost={loadEvent}
        redirect={`/g/${currentGroup.attributes.slug}/events`}
        resourceType="event"
      >
        {event => <EventPage event={event} group={currentGroup} />}
      </RequirePost>
    ),
    [currentGroup]
  );

  const renderEventAttendees = useCallback(
    () => (
      <RequirePost<Models.ReturnedEvent>
        group={currentGroup}
        loadPost={loadEvent}
        redirect={`/g/${currentGroup.attributes.slug}/events`}
        resourceType="event"
      >
        {event => <EventAttendeesPage event={event} group={currentGroup} />}
      </RequirePost>
    ),
    [currentGroup]
  );

  if (currentGroup.attributes.eventsEnabled) {
    routeData.push({ path: `/g/:slug/events`, render: renderEvents });
    routeData.push({ path: `/g/:slug/events/:postSlug`, render: renderEvent });
    routeData.push({ path: `/g/:slug/events/:postSlug/rsvps`, render: renderEventAttendees });
    tabData.push({
      icon: 'custom-calendar',
      id: 'events',
      path: `/g/${currentGroup.attributes.slug}/events`,
      tKey: 'pages.events.title',
      unreadCount: unreadCounts?.attributes?.eventCount ?? 0
    });
  }

  const renderJobBoard = useCallback(() => <JobBoardPage group={currentGroup} />, [currentGroup]);

  const renderJobPost = useCallback(
    () => (
      <RequirePost<Models.JobPost>
        group={currentGroup}
        loadPost={loadJobPost}
        redirect={`/g/${currentGroup.attributes.slug}/job-board`}
        resourceType="jobPost"
      >
        {jobPost => <JobPostPage group={currentGroup} jobPost={jobPost} />}
      </RequirePost>
    ),
    [currentGroup]
  );

  if (currentGroup.attributes.careersEnabled) {
    routeData.push({ path: `/g/:slug/job-board`, render: renderJobBoard });
    routeData.push({ path: `/g/:slug/job-board/:postSlug`, render: renderJobPost });
    tabData.push({
      icon: 'custom-briefcase',
      id: 'jobPosts',
      path: `/g/${currentGroup.attributes.slug}/job-board`,
      tKey: 'pages.jobBoard.title',
      unreadCount: unreadCounts?.attributes?.jobPostCount ?? 0
    });
  }

  const renderGallery = useCallback(() => <GalleryPage group={currentGroup} />, [currentGroup]);

  if (currentGroup.attributes.galleryEnabled) {
    routeData.push({ path: `/g/:slug/gallery`, render: renderGallery });
    tabData.push({
      icon: 'custom-menu',
      id: 'gallery',
      path: `/g/${currentGroup.attributes.slug}/gallery`,
      tKey: 'pages.gallery.title',
      unreadCount: unreadCounts?.attributes?.imageCount ?? 0
    });
  }

  const renderAbout = useCallback(() => <GroupAboutPage group={currentGroup} />, [currentGroup]);

  routeData.push({ path: '/g/:slug/about', render: renderAbout });
  tabData.push({
    icon: 'custom-about',
    id: 'about',
    path: `/g/${currentGroup.attributes.slug}/about`,
    tKey: 'pages.groupAbout.title'
  });

  const renderManageGroupCustomization = useCallback(
    () => (
      <RequireModerator group={currentGroup}>
        {() => <ManageGroupCustomizationPage group={currentGroup} />}
      </RequireModerator>
    ),
    [currentGroup]
  );

  const renderManageGroupFeatures = useCallback(
    () => (
      <RequireModerator group={currentGroup}>
        {() => <ManageGroupFeaturesPage group={currentGroup} />}
      </RequireModerator>
    ),
    [currentGroup]
  );

  const renderManageGroupFlags = useCallback(
    () => (
      <RequireModerator group={currentGroup}>
        {() => <ManageGroupFlagsPage group={currentGroup} />}
      </RequireModerator>
    ),
    [currentGroup]
  );

  const renderManageGroupFlag = useCallback(
    () => (
      <RequireModerator group={currentGroup}>
        {() => <ManageGroupFlagPage group={currentGroup} />}
      </RequireModerator>
    ),
    [currentGroup]
  );

  const renderManageGroupInvites = useCallback(
    () => (
      <RequireModerator group={currentGroup}>
        {() => <ManageGroupInvitesPage group={currentGroup} />}
      </RequireModerator>
    ),
    [currentGroup]
  );

  const renderManageGroupLinks = useCallback(
    () => (
      <RequireModerator group={currentGroup}>
        {() => <ManageGroupLinksPage group={currentGroup} />}
      </RequireModerator>
    ),
    [currentGroup]
  );

  const renderManageGroupMembers = useCallback(
    () => (
      <RequireModerator group={currentGroup}>
        {() => <ManageGroupMembersPage group={currentGroup} />}
      </RequireModerator>
    ),
    [currentGroup]
  );

  const renderManageGroupPosts = useCallback(
    () => (
      <RequireModerator group={currentGroup}>
        {() => <ManageGroupPostsPage group={currentGroup} />}
      </RequireModerator>
    ),
    [currentGroup]
  );

  const renderManageGroupRules = useCallback(
    () => (
      <RequireModerator group={currentGroup}>
        {() => <ManageGroupRulesPage group={currentGroup} />}
      </RequireModerator>
    ),
    [currentGroup]
  );

  const renderManageGroupSecuritySettings = useCallback(
    () => (
      <RequireModerator group={currentGroup}>
        {() => <ManageGroupSecuritySettingsPage group={currentGroup} />}
      </RequireModerator>
    ),
    [currentGroup]
  );

  const renderManageGroupPlan = useCallback(
    () => (
      <RequireOwner group={currentGroup}>
        {() => <ManageGroupPlanPage group={currentGroup} />}
      </RequireOwner>
    ),
    [currentGroup]
  );

  const renderManageGroupTraits = useCallback(
    () => (
      <RequireModerator group={currentGroup}>
        {() => <ManageGroupTraitsPage group={currentGroup} />}
      </RequireModerator>
    ),
    [currentGroup]
  );

  const renderModeratorHome = useCallback(
    () => (
      <RequireModerator group={currentGroup}>
        {() => <ModeratorHomePage group={currentGroup} />}
      </RequireModerator>
    ),
    [currentGroup]
  );

  if (currentMember?.attributes?.moderator) {
    routeData.push({ path: '/g/:slug/manage-group', render: renderModeratorHome });
    routeData.push({
      path: '/g/:slug/manage-group/customize',
      render: renderManageGroupCustomization
    });
    routeData.push({ path: '/g/:slug/manage-group/features', render: renderManageGroupFeatures });
    routeData.push({ path: '/g/:slug/manage-group/flags', render: renderManageGroupFlags });
    routeData.push({ path: '/g/:slug/manage-group/flags/:id', render: renderManageGroupFlag });
    routeData.push({
      path: '/g/:slug/manage-group/group-specific-information',
      render: renderManageGroupTraits
    });
    routeData.push({ path: '/g/:slug/manage-group/invites', render: renderManageGroupInvites });
    routeData.push({ path: '/g/:slug/manage-group/links', render: renderManageGroupLinks });
    routeData.push({ path: '/g/:slug/manage-group/members', render: renderManageGroupMembers });
    routeData.push({ path: '/g/:slug/manage-group/posts', render: renderManageGroupPosts });
    routeData.push({ path: '/g/:slug/manage-group/rules', render: renderManageGroupRules });
    routeData.push({
      path: '/g/:slug/manage-group/security',
      render: renderManageGroupSecuritySettings
    });
    routeData.push({
      path: '/g/:slug/manage-group/plan',
      render: renderManageGroupPlan
    });
    tabData.push({
      icon: 'custom-moderator',
      id: 'moderator',
      path: `/g/${currentGroup.attributes.slug}/manage-group`,
      tKey: 'pages.manageGroup.tools.title'
    });
  }

  const renderBlocks = useCallback(() => <BlocksPage group={currentGroup} />, [currentGroup]);
  routeData.push({ path: `/g/:slug/blocked-items`, render: renderBlocks });

  const renderMentions = useCallback(() => <MentionsPage group={currentGroup} />, [currentGroup]);
  routeData.push({ path: `/g/:slug/mentions`, render: renderMentions });

  const renderMoreSections = useCallback(
    () => <MoreSectionsPage currentGroup={currentGroup} currentUser={currentUser} />,
    [currentGroup, currentUser]
  );
  routeData.push({ path: `/g/:slug/more-sections`, render: renderMoreSections });

  const renderSaves = useCallback(() => <SavesPage group={currentGroup} />, [currentGroup]);
  routeData.push({ path: `/g/:slug/saved-items`, render: renderSaves });

  return children({ routes: routeData, tabs: tabData });
};

export default TabDataProvider;
