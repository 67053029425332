import React from 'react';
import { useSelector } from 'react-redux';

import PermanentAlert from '../../components/PermanentAlert';
import { getTraitOptions } from '../../selectors';
import { JSONApi, State } from '../../types';

type Props = {
  trait: JSONApi.TraitResource;
};

const TraitOptionWarning = ({ trait }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);

  const optionCount = getTraitOptions(apiData, trait).length;

  if (optionCount > 0) {
    return null;
  }

  return <PermanentAlert color="warning" id="models.trait.relationships.traitOptions.none" />;
};

export default TraitOptionWarning;
