import { IonLabel, IonSelectOption } from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';

import FormError from './FormError';
import FormSelect from '../../components/forms/FormSelect';
import RequiredFieldMarker from '../../components/forms/RequiredFieldMarker';
import { getTraitOptions } from '../../selectors';
import { forceArray } from '../../services/arrayUtils';
import { JSONApi, State } from '../../types';

type Props = {
  groupTraits: JSONApi.TraitResource[];
};

const TraitOptionSelectField = ({ groupTraits }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {groupTraits
        .filter(trait => forceArray(trait.relationships.traitOptions.data).length > 0)
        .map((trait, index) => {
          const options = getTraitOptions(apiData, trait);
          return (
            <div key={trait.id}>
              <IonLabel color="primary">
                {trait.attributes.title}
                {trait.attributes.required && <RequiredFieldMarker />}
              </IonLabel>
              <FormError name={`traitOptionSelections.${index.toString()}.traitOptionIds`} />
              <FormSelect
                multiple
                name={`traitOptionSelections.${index.toString()}.traitOptionIds`}
              >
                {options.map(option => (
                  <IonSelectOption key={option.id} value={option.id}>
                    {option.attributes.title}
                  </IonSelectOption>
                ))}
              </FormSelect>
            </div>
          );
        })}
    </>
  );
};

export default TraitOptionSelectField;
