import { Action } from 'redux';

import { Search } from '../types';

export enum SearchAction {
  SET_CONVERSATION_SEARCH_PARAMS = 'search/SET_CONVERSATION_SEARCH_PARAMS',
  SET_EVENT_SEARCH_PARAMS = 'search/SET_EVENT_SEARCH_PARAMS',
  SET_FORUM_SEARCH_PARAMS = 'search/SET_FORUM_SEARCH_PARAMS',
  SET_JOB_BOARD_SEARCH_PARAMS = 'search/SET_JOB_BOARD_SEARCH_PARAMS',
  SET_MEMBER_SEARCH_PARAMS = 'search/SET_MEMBER_SEARCH_PARAMS',
  SET_NEWS_ITEM_SEARCH_PARAMS = 'search/SET_NEWS_ITEM_SEARCH_PARAMS'
}

export interface SetConversationSearchParamsAction extends Action {
  payload: {
    params: Search.ConversationParams;
  };
  type: SearchAction.SET_CONVERSATION_SEARCH_PARAMS;
}

export interface SetEventSearchParamsAction extends Action {
  payload: {
    params: Search.PostParams;
  };
  type: SearchAction.SET_EVENT_SEARCH_PARAMS;
}

export interface SetForumSearchParamsAction extends Action {
  payload: {
    params: Search.PostParams;
  };
  type: SearchAction.SET_FORUM_SEARCH_PARAMS;
}

export interface SetJobBoardSearchParamsAction extends Action {
  payload: {
    params: Search.PostParams;
  };
  type: SearchAction.SET_JOB_BOARD_SEARCH_PARAMS;
}

export interface SetMemberSearchParamsAction extends Action {
  payload: {
    params: Search.MemberParams;
  };
  type: SearchAction.SET_MEMBER_SEARCH_PARAMS;
}

export interface SetNewsItemSearchParamsAction extends Action {
  payload: {
    params: Search.PostParams;
  };
  type: SearchAction.SET_NEWS_ITEM_SEARCH_PARAMS;
}

export type SearchActionTypes =
  | SetConversationSearchParamsAction
  | SetEventSearchParamsAction
  | SetForumSearchParamsAction
  | SetJobBoardSearchParamsAction
  | SetMemberSearchParamsAction
  | SetNewsItemSearchParamsAction;

export const setConversationSearchParams = (params: Search.ConversationParams) => ({
  payload: { params },
  type: SearchAction.SET_CONVERSATION_SEARCH_PARAMS
});

export const setEventSearchParams = (params: Search.PostParams) => ({
  payload: { params },
  type: SearchAction.SET_EVENT_SEARCH_PARAMS
});

export const setForumSearchParams = (params: Search.PostParams) => ({
  payload: { params },
  type: SearchAction.SET_FORUM_SEARCH_PARAMS
});

export const setJobBoardSearchParams = (params: Search.PostParams) => ({
  payload: { params },
  type: SearchAction.SET_JOB_BOARD_SEARCH_PARAMS
});

export const setMemberSearchParams = (params: Search.MemberParams) => ({
  payload: { params },
  type: SearchAction.SET_MEMBER_SEARCH_PARAMS
});

export const setNewsItemSearchParams = (params: Search.PostParams) => ({
  payload: { params },
  type: SearchAction.SET_NEWS_ITEM_SEARCH_PARAMS
});
