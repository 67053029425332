import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import LocalLoadingIndicator from '../components/LocalLoadingIndicator';
import SetupPage from '../components/SetupPage';
import VerificationWizard from '../components/VerificationWizard';
import useMountedTracking from '../hooks/useMountedTracking';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { getUserMemberForGroup } from '../selectors';
import { forceArray } from '../services/arrayUtils';
import { createMember } from '../thunks/apiThunks';
import { JSONApi, State } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  user: JSONApi.UserResource;
};

const VerificationPage = ({ group, user }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const dispatch = useThunkDispatch();
  const currentMember = group && getUserMemberForGroup(apiData, user, group);
  const isMounted = useMountedTracking();

  const [member, setMember] = useState(currentMember);

  useEffect(() => {
    if (!member) {
      dispatch(createMember(group.attributes.slug))
        .then(response => {
          if (isMounted.current) {
            setMember(forceArray(response.data)[0]);
          }
        })
        .catch(() => {
          // !!TODO!! handle member creation failure
        });
    }
  }, [dispatch, group.attributes.slug, isMounted, member]);

  // getUserMemberForGroup is static and doesn't get reevaluated when the data it is based on
  // changes, so this forces it to update.
  useEffect(() => {
    setMember(group && getUserMemberForGroup(apiData, user, group));
  }, [apiData, group, user]);

  return (
    <SetupPage
      defaultBackHref={`/g/${group.attributes.slug}/register/setup-account`}
      titleKey="pages.verification.title"
    >
      {!member && <LocalLoadingIndicator />}
      {member && <VerificationWizard group={group} member={member} />}
    </SetupPage>
  );
};

export default VerificationPage;
