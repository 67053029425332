import React from 'react';

import GroupRulesForm from '../components/forms/GroupRulesForm';
import ModeratorPage from '../components/layout/ModeratorPage';
import LocalLoadingIndicator from '../components/LocalLoadingIndicator';
import useCurrentUser from '../hooks/useCurrentUser';
import { JSONApi } from '../types';

import '../components/forms/CustomForm.scss';

type Props = {
  group: JSONApi.GroupResource;
};

const ManageGroupRulesPage = ({ group }: Props) => {
  const currentUser = useCurrentUser();

  if (!currentUser) {
    return <LocalLoadingIndicator />;
  }

  return (
    <ModeratorPage group={group} h2Key="pages.manageGroup.groupRules.title">
      {() => (
        <div className="ion-padding-end ion-padding-start">
          <GroupRulesForm group={group} />
        </div>
      )}
    </ModeratorPage>
  );
};

export default ManageGroupRulesPage;
