import { IonToast, ToastButton } from '@ionic/react';
import { close } from 'ionicons/icons';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { clearNotifications } from '../actions/notificationActions';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { State } from '../types';

const Notifications = () => {
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const intl = useIntl();
  const notificationList = useSelector((root: State.Root) => root.notifications);
  const notification = notificationList.notifications[0];

  const handleDidDismiss = useCallback(() => {
    dispatch(clearNotifications());
  }, [dispatch]);

  if (notification) {
    let message = notification.body;
    if (notification.bodyTranslationKey) {
      message = intl.formatMessage({ id: notification.bodyTranslationKey });
    }

    let color = notification.type as string;
    if (color === State.NotificationType.PUSH_NOTIFICATION) {
      color = 'primary';
    }
    if (color === State.NotificationType.ERROR) {
      color = 'danger';
    }

    const buttons: ToastButton[] = [{ icon: close, role: 'cancel' }];
    if (notification.path && notification.link) {
      buttons.push({
        handler: () => {
          if (notification.path) {
            history.push(notification.path);
          }
        },
        text: notification.link
      });
    }

    return (
      <IonToast
        buttons={buttons}
        color={color}
        duration={4000}
        header={notification.title}
        isOpen
        message={message}
        onDidDismiss={handleDidDismiss}
        position="top"
      />
    );
  }
  return null;
};

export default Notifications;
