import { IonButton } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import { alertError, alertSuccess } from '../actions/notificationActions';
import { setCurrentUserData } from '../actions/sessionActions';
import FormModal from '../components/FormModal';
import Heading from '../components/Heading';
import Paragraph from '../components/Paragraph';
import useMountedTracking from '../hooks/useMountedTracking';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { AjaxError } from '../services/ajaxRequest';
import { handleSubmissionError } from '../services/formUtils';
import { deleteUser } from '../thunks/apiThunks';
import { setUserId } from '../thunks/sessionThunks';
import { JSONApi, SessionState } from '../types';

const DeleteAccountModal = ({
  currentUser,
  ...formModalProps
}: Omit<React.ComponentPropsWithoutRef<typeof FormModal>, 'children' | 'titleKey'> & {
  currentUser: JSONApi.UserResource;
}) => {
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const isMounted = useMountedTracking();

  const handleDeleteClick = useCallback(() => {
    dispatch(setCurrentUserData(null, SessionState.LOADING));
    dispatch(deleteUser(currentUser))
      .then(() => {
        if (isMounted.current) {
          history.push('/');
          dispatch(alertSuccess('forms.user.delete.success'));
        }
        dispatch(setUserId(null));
      })
      .catch((error: AjaxError) => {
        dispatch(setCurrentUserData(currentUser.id, SessionState.SUCCESS));
        handleSubmissionError({
          dispatch,
          error,
          handleInvalid: () => {
            dispatch(alertError('forms.user.delete.error'));
          },
          isMounted
        });
      });
  }, [currentUser, dispatch, history, isMounted]);

  return (
    <FormModal {...formModalProps} titleKey="modals.deleteAccount.title">
      {() => (
        <>
          <Heading level={2}>
            <FormattedMessage id="dictionary.areYouSure" />
          </Heading>
          <Paragraph>
            <FormattedMessage id="modals.deleteAccount.explanation" />
          </Paragraph>
          <IonButton color="danger" expand="block" onClick={handleDeleteClick}>
            <FormattedMessage id="forms.user.delete.closeButton" />
          </IonButton>
        </>
      )}
    </FormModal>
  );
};

export default DeleteAccountModal;
