import { IonRouterLink, IonText } from '@ionic/react';
import { MentionsRef } from 'rc-mentions/lib/Mentions';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import ChatBubble from '../components/ChatBubble';
import ChatBubbleActionSheet from '../components/ChatBubbleActionSheet';
import DeleteCommentModal from '../components/DeleteCommentModal';
import FormatText from '../components/FormatText';
import EditCommentForm from '../components/forms/EditCommentForm';
import MemberAvatar from '../components/MemberAvatar';
import Nbsp from '../components/Nbsp';
import SimpleChatBubble from '../components/SimpleChatBubble';
import useCurrentMember from '../hooks/useCurrentMember';
import useOpener from '../hooks/useOpener';
import { getCommentCreator, getRecordBlock } from '../selectors';
import { JSONApi, ModelAttributes, State } from '../types';

type Props = {
  comment: JSONApi.CommentResource;
  group: JSONApi.GroupResource;
};

const CommentBubble = ({ comment, group }: Props) => {
  const actionSheetOpener = useOpener();
  const apiData = useSelector((root: State.Root) => root.api);
  const currentMember = useCurrentMember();
  const deleteModalOpener = useOpener();
  const mentionsRef = createRef<MentionsRef>();
  const [isEditing, setIsEditing] = useState(false);

  const block = getRecordBlock(apiData, comment);
  const commenter = getCommentCreator(apiData, comment);
  const isCommenter = commenter.id === currentMember?.id;

  useEffect(() => {
    if (isEditing && mentionsRef.current) {
      mentionsRef.current.focus();
      const textarea = mentionsRef.current.textarea;
      if (textarea) {
        // set the cursor to the end of the text
        textarea.selectionStart = textarea.value.length;
        textarea.selectionEnd = textarea.value.length;
      }
      // mentionsRef.current.setState({ value: mentionsRef.current.state.value });
    }
  }, [isEditing, mentionsRef]);

  const handleEdit = useCallback(() => {
    if (isCommenter) {
      setIsEditing(true);
    }
  }, [isCommenter]);

  const handleEditCancel = useCallback(() => {
    setIsEditing(false);
  }, []);

  const handleEditSuccess = useCallback(() => {
    setIsEditing(false);
  }, []);

  if (block) {
    return (
      <SimpleChatBubble color="danger">
        <FormattedMessage id="models.comment.attributes.status.blockedMessage" />
      </SimpleChatBubble>
    );
  }

  if (comment.attributes.status === ModelAttributes.CommentStatus.DELETED) {
    return (
      <SimpleChatBubble color="danger">
        <FormattedMessage id="models.comment.attributes.status.deletedMessage" />
      </SimpleChatBubble>
    );
  }

  if (comment.attributes.status === ModelAttributes.CommentStatus.REJECTED) {
    return (
      <SimpleChatBubble color="danger">
        <FormattedMessage id="models.comment.attributes.status.rejectedMessage" />
      </SimpleChatBubble>
    );
  }

  const isActionable =
    isCommenter || comment.attributes.permissions.block || comment.attributes.permissions.flag;

  return (
    <>
      {isActionable && (
        <ChatBubbleActionSheet
          actionSheetOpener={actionSheetOpener}
          block={comment.attributes.permissions.block}
          flag={comment.attributes.permissions.flag}
          group={group}
          onDelete={isCommenter ? deleteModalOpener.toggle : undefined}
          onEdit={isCommenter ? handleEdit : undefined}
          record={comment}
        />
      )}
      <DeleteCommentModal comment={comment} group={group} opener={deleteModalOpener} />
      {isEditing ? (
        <div className="ion-padding">
          <div className="chat-bubble color-medium">
            <MemberAvatar member={commenter} />
            <div className="bubble">
              <EditCommentForm
                comment={comment}
                group={group}
                mentionsRef={mentionsRef}
                onCancel={handleEditCancel}
                onSuccess={handleEditSuccess}
              />
            </div>
          </div>
        </div>
      ) : (
        <ChatBubble
          actionSheetOpener={actionSheetOpener}
          group={group}
          isActionable={isActionable}
          member={commenter}
          timestamp={comment.attributes.createdAt}
        >
          <IonRouterLink
            className="commenter-name"
            routerLink={`/g/${group.attributes.slug}/members/${commenter.attributes.slug}`}
          >
            <strong>
              <IonText color="primary">{commenter.attributes.fullName}</IonText>
            </strong>
          </IonRouterLink>
          <Nbsp />
          <IonText className="formatted-text" color="dark">
            <FormatText autolink mentionable>
              {comment.attributes.content}
            </FormatText>
          </IonText>
        </ChatBubble>
      )}
    </>
  );
};

export default CommentBubble;
