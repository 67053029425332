import { IonButton, IonButtons, IonItem, IonLabel, IonSelectOption } from '@ionic/react';
import { FormApi } from 'final-form';
import jstz from 'jstimezonedetect';
import React, { useCallback } from 'react';
import { FormSpy } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import { apiClearCachedResponsesForEndpoint } from '../../actions/apiActions';
import DeletePostModal from '../../components/DeletePostModal';
import EventTimeField from '../../components/forms/EventTimeField';
import Form from '../../components/forms/Form';
import FormCondition from '../../components/forms/FormCondition';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormInput from '../../components/forms/FormInput';
import FormMentionsTextarea from '../../components/forms/FormMentionsTextarea';
import FormSearchableSelect from '../../components/forms/FormSearchableSelect';
import FormSelect from '../../components/forms/FormSelect';
import FormSubmit from '../../components/forms/FormSubmit';
import FormToggleItem from '../../components/forms/FormToggleItem';
import RequiredFieldMarker from '../../components/forms/RequiredFieldMarker';
import SubmitError from '../../components/forms/SubmitError';
import Heading from '../../components/Heading';
import { maxTextInputLength, maxTitleLength, stringArrayDelimiter } from '../../globals';
import useCurrentUser from '../../hooks/useCurrentUser';
import useDismissModalAndGoTo from '../../hooks/useDismissModalAndGoTo';
import useMountedTracking from '../../hooks/useMountedTracking';
import useOpener, { Opener } from '../../hooks/useOpener';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import timeZones, { suggestedTimeZones } from '../../i18n/timeZones';
import { forceArray } from '../../services/arrayUtils';
import {
  addAnHour,
  applyZoneAndReturnUTC,
  beginningOfNextHour,
  systemLocalTime
} from '../../services/dateUtils';
import { handleFormSubmission } from '../../services/formUtils';
import { placeQueryHandler } from '../../services/placeSearch';
import { createEvent, updateEvent } from '../../thunks/apiThunks';
import { ApiEndpoint, JSONApi, ModelAttributes, Models } from '../../types';

import './CustomForm.scss';

export type EventFormValues = Models.EventFormData;

type Props = {
  autofocus: boolean;
  contentForwardRef?: React.RefObject<HTMLIonContentElement>;
  event?: JSONApi.EventResource;
  group: JSONApi.GroupResource;
  onSuccess?: () => void;
  opener: Opener;
};

const EventForm = ({ autofocus, contentForwardRef, event, group, onSuccess, opener }: Props) => {
  const currentUser = useCurrentUser();
  const dismissModalAndGoTo = useDismissModalAndGoTo(opener);
  const deleteModalOpener = useOpener();
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const eventTypes = intl
    .formatMessage({ id: 'models.event.attributes.eventType.options' })
    .split(stringArrayDelimiter) as ModelAttributes.EventType[];
  const allTimeZones = Object.keys(timeZones);

  const initialStartTime = event?.attributes?.startTime?.toISO() ?? beginningOfNextHour();
  const initialEndTime = event?.attributes?.endTime?.toISO() ?? addAnHour(initialStartTime);

  const initialValues: EventFormValues = {
    description: event?.attributes?.description ?? '',
    enableRsvps: event?.attributes?.enableRsvps ?? false,
    endTime: systemLocalTime(initialEndTime) as string,
    eventType: event?.attributes?.eventType ?? null,
    link: event?.attributes?.link ?? '',
    location: event?.attributes?.location ?? '',
    locationName: event?.attributes?.locationName ?? '',
    online: event?.attributes?.online ?? false,
    startTime: systemLocalTime(initialStartTime) as string,
    timeZone:
      event?.attributes?.timeZone ?? currentUser?.attributes?.timezone ?? jstz.determine().name(),
    title: event?.attributes?.title ?? ''
  };

  const handleSubmit = useCallback(
    async (values: EventFormValues, form: FormApi<EventFormValues>) => {
      const { endTime, startTime, ...remainingValues } = values;
      const formattedEndTime = applyZoneAndReturnUTC(endTime, remainingValues.timeZone) as string;
      const formattedStartTime = applyZoneAndReturnUTC(
        startTime,
        remainingValues.timeZone
      ) as string;
      const formattedValues = {
        ...remainingValues,
        endTime: formattedEndTime,
        startTime: formattedStartTime
      };
      const action = event
        ? updateEvent(group.attributes.slug, {
            ...event,
            ...formattedValues,
            slug: event.attributes.slug
          })
        : createEvent(group.attributes.slug, formattedValues);
      return handleFormSubmission({
        action,
        contentForwardRef,
        dispatch,
        errorTranslationLocation: 'event',
        form,
        intl,
        isMounted,
        onSuccess: (response: JSONApi.Response<Models.Event>) => {
          dispatch(apiClearCachedResponsesForEndpoint(ApiEndpoint.EVENT_SEARCH));
          const newEvent = forceArray(response.data)[0];
          if (onSuccess) {
            onSuccess();
          }
          dismissModalAndGoTo(`/g/${group.attributes.slug}/events/${newEvent.attributes.slug}`);
        },
        successTKey: `forms.event.${event ? 'update' : 'create'}.success`,
        values
      });
    },
    [
      contentForwardRef,
      dismissModalAndGoTo,
      dispatch,
      event,
      group.attributes.slug,
      intl,
      isMounted,
      onSuccess
    ]
  );

  const validationSchema = Yup.object().shape({
    description: Yup.string()
      .nullable()
      .max(
        maxTextInputLength,
        intl.formatMessage(
          { id: 'errors.event.description.tooLong' },
          { count: maxTextInputLength }
        )
      ),
    endTime: Yup.date().required(intl.formatMessage({ id: 'errors.event.endTime.blank' })),
    eventType: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: 'errors.event.eventType.inclusion' })),
    location: Yup.string()
      .nullable()
      .max(
        maxTextInputLength,
        intl.formatMessage({ id: 'errors.event.location.tooLong' }, { count: maxTextInputLength })
      )
      .when('online', {
        is: (online: boolean) => !online,
        then: schema => schema.required(intl.formatMessage({ id: 'errors.event.location.blank' }))
      }),
    locationName: Yup.string()
      .nullable()
      .max(
        maxTextInputLength,
        intl.formatMessage(
          { id: 'errors.event.locationName.tooLong' },
          { count: maxTextInputLength }
        )
      ),
    startTime: Yup.date().required(intl.formatMessage({ id: 'errors.event.startTime.blank' })),
    title: Yup.string()
      .required(intl.formatMessage({ id: 'errors.event.title.blank' }))
      .max(
        maxTitleLength,
        intl.formatMessage({ id: 'errors.event.title.tooLong' }, { count: maxTitleLength })
      )
  });

  return (
    <FormContainer<EventFormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ form, handleSubmit }) => (
        <div className="custom-form-wrapper event-form">
          <Form<EventFormValues> onFormSubmit={handleSubmit} submit={form.submit}>
            <SubmitError />
            <Heading level={2}>
              <FormattedMessage id="models.event.attributes.detailsHeader.label" />
            </Heading>
            <IonLabel>
              <FormattedMessage id="models.event.attributes.title.label" />
              <RequiredFieldMarker />
            </IonLabel>
            <FormError name="title" />
            <FormInput
              autocapitalize="on"
              autocorrect="on"
              autofocus={autofocus}
              name="title"
              placeholder={intl.formatMessage({
                id: 'models.event.attributes.title.placeholder'
              })}
            />
            <IonLabel>
              <FormattedMessage id="models.event.attributes.eventType.label" />
              <RequiredFieldMarker />
            </IonLabel>
            <FormError name="eventType" />
            <FormSelect
              name="eventType"
              placeholder={intl.formatMessage({
                id: 'models.event.attributes.eventType.placeholder'
              })}
            >
              {eventTypes.map(eventType => (
                <IonSelectOption key={eventType} value={eventType}>
                  {eventType}
                </IonSelectOption>
              ))}
            </FormSelect>
            <IonLabel>
              <FormattedMessage id="models.event.attributes.description.label" />
            </IonLabel>
            <FormError name="description" />
            <FormMentionsTextarea
              name="description"
              placeholder={intl.formatMessage({
                id: 'models.event.attributes.description.placeholder'
              })}
              rows={6}
            />
            <hr />
            <Heading level={2}>
              <FormattedMessage id="models.event.attributes.locationHeader.label" />
            </Heading>
            <FormError name="online" />
            <FormToggleItem<EventFormValues, 'online'>
              change={form.change}
              id="models.event.attributes.online.label"
              name="online"
            />
            <FormCondition inverse name="online">
              <IonLabel>
                <FormattedMessage id="models.event.attributes.location.label" />
                <RequiredFieldMarker />
              </IonLabel>
              <FormError name="location" />
              <div className="searchable-select-wrapper-outer">
                <div className="searchable-select-wrapper-inner">
                  <FormSearchableSelect
                    attributeNameTKey="models.event.attributes.location.label"
                    name="location"
                    placeholder={intl.formatMessage({
                      id: 'models.event.attributes.location.placeholder'
                    })}
                    queryHandler={placeQueryHandler}
                    titleTKey="models.event.attributes.location.label"
                    type="text"
                  />
                </div>
              </div>
              <IonLabel>
                <FormattedMessage id="models.event.attributes.locationName.label" />
              </IonLabel>
              <FormError name="locationName" />
              <FormInput
                autocapitalize="on"
                name="locationName"
                placeholder={intl.formatMessage({
                  id: 'models.event.attributes.locationName.placeholder'
                })}
              />
            </FormCondition>
            <hr />
            <Heading level={2}>
              <FormattedMessage id="models.event.attributes.dateHeader.label" />
            </Heading>
            <FormSpy<EventFormValues> subscription={{ values: true }}>
              {({ values }) => (
                <>
                  <IonLabel>
                    <FormattedMessage id="models.event.attributes.startTime.label" />
                  </IonLabel>
                  <EventTimeField
                    change={form.change}
                    format="time"
                    name="startTime"
                    values={values}
                  />
                  <IonLabel>
                    <FormattedMessage id="models.event.attributes.endTime.label" />
                  </IonLabel>
                  <EventTimeField
                    change={form.change}
                    format="time"
                    name="endTime"
                    values={values}
                  />
                </>
              )}
            </FormSpy>
            <IonLabel>
              <FormattedMessage id="models.event.attributes.timeZone.label" />
            </IonLabel>
            <FormError name="timeZone" />
            <FormSelect
              name="timeZone"
              placeholder={intl.formatMessage({
                id: 'models.event.attributes.timeZone.placeholder'
              })}
            >
              {suggestedTimeZones.map(timeZone => (
                <IonSelectOption key={timeZone} value={timeZone}>
                  <FormattedMessage id={`timeZones.${timeZone}`} />
                </IonSelectOption>
              ))}
              {allTimeZones.map(timeZone => (
                <IonSelectOption key={timeZone} value={timeZone}>
                  <FormattedMessage id={`timeZones.${timeZone}`} />
                </IonSelectOption>
              ))}
            </FormSelect>
            <hr />
            <Heading level={2}>
              <FormattedMessage id="models.event.attributes.moreOptionsHeader.label" />
            </Heading>
            <IonLabel>
              <FormattedMessage id="models.event.attributes.link.label" />
            </IonLabel>
            <FormError name="link" />
            <FormInput
              name="link"
              placeholder={intl.formatMessage({ id: 'models.event.attributes.link.placeholder' })}
            />
            <FormToggleItem<EventFormValues, 'enableRsvps'>
              change={form.change}
              id="models.event.attributes.enableRsvps.label"
              name="enableRsvps"
            />
            <IonItem color="transparent" lines="none">
              <IonButtons slot="end">
                <FormSubmit fill="solid">
                  <FormattedMessage id={event ? 'dictionary.update' : 'dictionary.post'} />
                </FormSubmit>
                {event && (
                  <>
                    <IonButton color="danger" fill="clear" onClick={deleteModalOpener.toggle}>
                      <FormattedMessage id="dictionary.delete" />
                    </IonButton>
                    <DeletePostModal group={group} opener={deleteModalOpener} post={event} />
                  </>
                )}
              </IonButtons>
            </IonItem>
          </Form>
        </div>
      )}
    </FormContainer>
  );
};

export default EventForm;
