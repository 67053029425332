import { useSelector } from 'react-redux';

import useCurrentUser from '../hooks/useCurrentUser';
import { getUserGroups, getUserMemberForGroup } from '../selectors';
import { JSONApi, ModelAttributes, State } from '../types';

const useCurrentUserGroups = () => {
  const apiData = useSelector((root: State.Root) => root.api);
  const currentUser = useCurrentUser();

  const joined: JSONApi.GroupResource[] = [];
  const joining: JSONApi.GroupResource[] = [];
  const creating: JSONApi.GroupResource[] = [];

  if (currentUser) {
    getUserGroups(apiData, currentUser)
      .filter(group => group.attributes.status !== ModelAttributes.GroupStatus.REJECTED)
      // eslint-disable-next-line no-confusing-arrow
      .sort((a, b) => (a.attributes.name.toLowerCase() > b.attributes.name.toLowerCase() ? 1 : -1))
      .forEach(group => {
        const member = getUserMemberForGroup(apiData, currentUser, group);
        if (group.attributes.status === ModelAttributes.GroupStatus.APPROVED) {
          if (member && member.attributes.status === ModelAttributes.MemberStatus.APPROVED) {
            joined.push(group);
          } else {
            joining.push(group);
          }
        } else if (member?.attributes?.moderator) {
          creating.push(group);
        }
        // there is theoretically another condition here where you are not a moderator
        // and the group is pending...but we intend not to allow that to happen
      });
  }

  const any = creating.length + joined.length + joining.length > 0;
  return { any, creating, joined, joining };
};

export default useCurrentUserGroups;
