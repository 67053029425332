import { IonItem, IonLabel, IonRouterLink, IonText } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import GroupAvatar from '../components/GroupAvatar';
import Nbsp from '../components/Nbsp';
import Paragraph from '../components/Paragraph';
import { getGroupRules } from '../selectors';
import { JSONApi, State } from '../types';

import './CustomItem.scss';
import './GroupAboutHeader.scss';

type Props = {
  group: JSONApi.GroupResource;
  link?: boolean;
  showDescription?: boolean;
  showRules?: boolean;
};

const GroupAboutHeader = ({
  group,
  link = true,
  showDescription = false,
  showRules = false
}: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const { primaryColor } = group.attributes;
  const rules = getGroupRules(apiData, group);

  const aboutLink = `/g/${group.attributes.slug}/about`;

  const itemProps: React.ComponentProps<typeof IonItem> = {};
  if (primaryColor) {
    itemProps.color = 'transparent';
    itemProps.style = {};
    itemProps.style.backgroundColor = `${primaryColor}15`;
  }

  return (
    <div className="group-about-header">
      <div className="ion-padding panel">
        <div className="custom-item">
          {link ? (
            <IonRouterLink routerLink={aboutLink}>
              <GroupAvatar group={group} />
            </IonRouterLink>
          ) : (
            <GroupAvatar group={group} />
          )}
          <div className="item-content">
            <div className="item-content-1">
              <strong>
                {link ? (
                  <IonRouterLink routerLink={aboutLink}>{group.attributes.name}</IonRouterLink>
                ) : (
                  group.attributes.name
                )}
              </strong>
            </div>
            <div className="item-content-2">
              <IonRouterLink routerLink={`/g/${group.attributes.slug}/members`}>
                <FormattedMessage
                  id="pages.groupHome.memberCount"
                  values={{ count: group.attributes.memberCount }}
                />
              </IonRouterLink>
            </div>
          </div>
        </div>
        {showDescription && (
          <div className="ion-padding-top">
            <IonLabel color="primary">
              <FormattedMessage id="dictionary.aboutUs" />
            </IonLabel>
            <Paragraph>{group.attributes.description}</Paragraph>
          </div>
        )}
        {showRules && rules.length > 0 && (
          <div className="ion-padding-top">
            <IonLabel color="primary">
              <FormattedMessage id="models.group.relationships.rules.title" />
            </IonLabel>
            {rules.map((rule, index) => (
              <p key={rule.id}>
                <IonLabel color="primary">
                  {(index + 1).toString()}
                  <FormattedMessage id="dictionary.closeParen" />
                </IonLabel>
                <Nbsp />
                <IonText color="primary">{rule.attributes.rule}</IonText>
              </p>
            ))}
          </div>
        )}
      </div>
      {link && (
        <IonItem button lines="none" role="button" routerLink={aboutLink} {...itemProps}>
          <strong>
            <FormattedMessage id="dictionary.aboutUs" />
          </strong>
        </IonItem>
      )}
    </div>
  );
};

export default GroupAboutHeader;
