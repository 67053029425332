import { ModelAttributes } from '../types';

const subscriptionFrequencies: ModelAttributes.SubscriptionFrequency[] = [
  'immediate',
  'daily',
  'weekly',
  'monthly',
  'never'
];

export default subscriptionFrequencies;
