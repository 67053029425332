import { IonGrid, IonRow } from '@ionic/react';
import {
  bodyOutline,
  cardOutline,
  checkmarkDoneCircleOutline,
  colorPaletteOutline,
  flagOutline,
  linkOutline,
  personAddOutline,
  shapesOutline,
  shieldCheckmarkOutline
} from 'ionicons/icons';
import React from 'react';

import ModeratorPage from '../components/layout/ModeratorPage';
import SelectSectionTile from '../components/SelectSectionTile';
import useCurrentMember from '../hooks/useCurrentMember';
import { JSONApi } from '../types';

import './SectionSelector.scss';

type Props = {
  group: JSONApi.GroupResource;
};

const ModeratorHomePage = ({ group }: Props) => {
  const currentMember = useCurrentMember();

  return (
    <ModeratorPage group={group} h2Key="pages.manageGroup.selectSection.title">
      {() => (
        <IonGrid className="section-selector">
          <IonRow>
            <SelectSectionTile
              iconClassName="custom-members"
              routerLink={`/g/${group.attributes.slug}/manage-group/members`}
              tKey="pages.manageGroup.members.link"
            />
            <SelectSectionTile
              iconClassName="custom-feed"
              routerLink={`/g/${group.attributes.slug}/manage-group/posts`}
              tKey="pages.manageGroup.posts.link"
            />
            <SelectSectionTile
              icon={flagOutline}
              routerLink={`/g/${group.attributes.slug}/manage-group/flags`}
              tKey="pages.manageGroup.flags.link"
            />
            <SelectSectionTile
              icon={personAddOutline}
              routerLink={`/g/${group.attributes.slug}/manage-group/invites`}
              tKey="pages.manageGroup.invites.link"
            />
            <SelectSectionTile
              icon={shapesOutline}
              routerLink={`/g/${group.attributes.slug}/manage-group/features`}
              tKey="pages.manageGroup.features.link"
            />
            <SelectSectionTile
              icon={colorPaletteOutline}
              routerLink={`/g/${group.attributes.slug}/manage-group/customize`}
              tKey="pages.manageGroup.customize.link"
            />
            <SelectSectionTile
              icon={shieldCheckmarkOutline}
              routerLink={`/g/${group.attributes.slug}/manage-group/security`}
              tKey="pages.manageGroup.security.link"
            />
            <SelectSectionTile
              icon={checkmarkDoneCircleOutline}
              routerLink={`/g/${group.attributes.slug}/manage-group/rules`}
              tKey="pages.manageGroup.groupRules.link"
            />
            <SelectSectionTile
              icon={bodyOutline}
              routerLink={`/g/${group.attributes.slug}/manage-group/group-specific-information`}
              tKey="pages.manageGroup.traits.link"
            />
            <SelectSectionTile
              icon={linkOutline}
              routerLink={`/g/${group.attributes.slug}/manage-group/links`}
              tKey="pages.manageGroup.links.link"
            />
            {currentMember?.attributes.owner && (
              <SelectSectionTile
                icon={cardOutline}
                routerLink={`/g/${group.attributes.slug}/manage-group/plan`}
                tKey="pages.manageGroup.plan.link"
              />
            )}
          </IonRow>
        </IonGrid>
      )}
    </ModeratorPage>
  );
};

export default ModeratorHomePage;
