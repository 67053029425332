import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import UserAccountWizard from '../components/UserAccountWizard';
import UserAccountWizardPageWrapper from '../components/UserAccountWizardPageWrapper';
import useMountedTracking from '../hooks/useMountedTracking';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { findBySlug, getUserMemberForGroup } from '../selectors';
import { createMember } from '../thunks/apiThunks';
import { JSONApi, State } from '../types';

type Props = {
  group?: JSONApi.GroupResource;
};

const UserAccountWizardPage = ({ group }: Props) => {
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const isMounted = useMountedTracking();

  const handleLoginSuccess = useCallback(
    (user: JSONApi.UserResource, newApiData: State.Api) => {
      let groupToUse = group;
      if (!groupToUse && user.attributes.lastVisitedGroupSlug) {
        groupToUse =
          findBySlug(newApiData, 'group', user.attributes.lastVisitedGroupSlug, null) ?? undefined;
      }
      if (groupToUse) {
        const { slug } = groupToUse.attributes;
        const member = getUserMemberForGroup(newApiData, user, groupToUse);
        // When the profile isn't complete, forward to the setupAccount page to complete it.
        if (!user.attributes.userComplete) {
          if (isMounted.current) {
            history.push(`/g/${slug}/register/setup-account`);
          }
        } else if (!member) {
          // When there's no group member, create one and forward to verification
          dispatch(createMember(slug))
            .then(() => {
              if (isMounted.current) {
                history.push(`/g/${slug}/register/verification`);
              }
            })
            .catch(() => {
              if (isMounted.current) {
                // !!TODO!! handle member creation failure
              }
            });
        } else {
          // When there's a group member, let the GroupRoutes root handler send them to the right spot
          if (isMounted.current) {
            history.push(`/g/${slug}`);
          }
        }
      } else {
        history.push('/start');
      }
    },
    [dispatch, group, history, isMounted]
  );

  return (
    <UserAccountWizardPageWrapper titleId="pages.userAccountWizard.title">
      <UserAccountWizard group={group} handleLoginSuccess={handleLoginSuccess} />
    </UserAccountWizardPageWrapper>
  );
};

export default UserAccountWizardPage;
