import { useCallback } from 'react';

const useStopEnterKeyPropagation = <T extends HTMLElement>(
  onKeyDown?: (event: React.KeyboardEvent<T>) => void | Promise<void>
) => {
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<T>) => {
      if (onKeyDown) {
        onKeyDown(event);
      }
      if (event.key === 'Enter') {
        event.stopPropagation();
      }
    },
    [onKeyDown]
  );

  return handleKeyDown;
};

export default useStopEnterKeyPropagation;
