import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import ModerateItemFlags from '../components/ModerateItemFlags';
import { JSONApi } from '../types';

type Props = {
  flaggedItem: JSONApi.FlaggedItemResource;
  group: JSONApi.GroupResource;
  member: JSONApi.MemberResource;
};

const ModerateMemberFlags = ({ flaggedItem, group, member }: Props) => {
  const intl = useIntl();

  const renderFlaggedContent = useCallback(
    () => (
      <>
        <strong>{member.attributes.fullName}</strong>
        <div>{member.attributes.headline}</div>
      </>
    ),
    [member.attributes.fullName, member.attributes.headline]
  );

  return (
    <ModerateItemFlags
      flaggedItem={flaggedItem}
      flaggedItemAuthor={member}
      group={group}
      rejectErrorKey="forms.moderateMember.reject.error"
      rejectSuccessKey="forms.moderateMember.reject.success"
      renderFlaggedContent={renderFlaggedContent}
      typeBadgeLabel={intl.formatMessage({ id: 'models.member.badge' })}
      viewFlaggedLink={`/g/${group.attributes.slug}/members/${member.attributes.slug}`}
    />
  );
};

export default ModerateMemberFlags;
