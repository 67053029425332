import { IonButton, IonButtons, IonItem, IonProgressBar } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

import { WizardSteps } from '../../components/CreateGroupPage';
import Form from '../../components/forms/Form';
import FormCondition from '../../components/forms/FormCondition';
import FormContainer from '../../components/forms/FormContainer';
import FormError from '../../components/forms/FormError';
import FormSubmit from '../../components/forms/FormSubmit';
import FormTextarea from '../../components/forms/FormTextarea';
import FormTip from '../../components/forms/FormTip';
import FormToggleItem from '../../components/forms/FormToggleItem';
import SubmitError from '../../components/forms/SubmitError';
import Heading from '../../components/Heading';
import Paragraph from '../../components/Paragraph';
import { maxTextInputLength } from '../../globals';
import useMountedTracking from '../../hooks/useMountedTracking';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { handleFormSubmission } from '../../services/formUtils';
import { updateGroup } from '../../thunks/apiThunks';
import { JSONApi } from '../../types';

type FormValues = {
  memberVerificationQuestion: string;
  memberVerificationRequired: boolean;
};

type Props = {
  group: JSONApi.GroupResource;
};

const SetupGroupVerificationSettingsForm = ({ group }: Props) => {
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const initialValues: FormValues = {
    memberVerificationQuestion: group.attributes.memberVerificationQuestion,
    memberVerificationRequired: group.attributes.memberVerificationRequired
  };

  const handleSubmit = useCallback(
    async (values: FormValues) =>
      handleFormSubmission({
        action: updateGroup(values, group.id),
        dispatch,
        errorTranslationLocation: 'group',
        intl,
        isMounted,
        onSuccess: () => {
          history.push(`/create/${group.attributes.slug}/${WizardSteps.RULES}`);
        },
        values
      }),
    [dispatch, group, history, intl, isMounted]
  );

  const validationSchema = Yup.object().shape({
    memberVerificationQuestion: Yup.string()
      .nullable()
      .max(
        maxTextInputLength,
        intl.formatMessage(
          { id: 'errors.group.memberVerificationQuestion.tooLong' },
          { count: maxTextInputLength }
        )
      ),
    memberVerificationRequired: Yup.boolean()
  });

  const handleBackClick = useCallback(() => {
    history.push({ pathname: `/create/${group.attributes.slug}/${WizardSteps.PERSONALIZE}` });
  }, [group.attributes.slug, history]);

  return (
    <FormContainer<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ form, handleSubmit }) => (
        <Form<FormValues>
          className="setup-group-verification-settings-form"
          onFormSubmit={handleSubmit}
          submit={form.submit}
        >
          <IonProgressBar buffer={0.3} color="success" value={0} />
          <Heading level={2}>
            <FormattedMessage id="pages.groupWizard.verification.title" />
          </Heading>
          <Paragraph>
            <FormattedMessage id="pages.groupWizard.verification.description" />
          </Paragraph>
          <SubmitError />
          <FormError name="memberVerificationRequired" />
          <FormToggleItem<FormValues, 'memberVerificationRequired'>
            change={form.change}
            id="models.group.attributes.memberVerificationRequired.label"
            name="memberVerificationRequired"
          />
          <FormCondition name="memberVerificationRequired">
            <FormError name="memberVerificationQuestion" />
            <FormTextarea
              aria-label={intl.formatMessage({
                id: 'models.group.attributes.memberVerificationQuestion.label'
              })}
              name="memberVerificationQuestion"
              placeholder={intl.formatMessage({
                id: 'models.group.attributes.memberVerificationQuestion.placeholder'
              })}
              rows={5}
            />
            <FormTip id="models.group.attributes.memberVerificationQuestion.tip" />
          </FormCondition>
          <IonItem color="transparent" lines="none">
            <IonButtons slot="end">
              <IonButton color="primary" fill="outline" onClick={handleBackClick} size="default">
                <FormattedMessage id="dictionary.previous" />
              </IonButton>
              <FormSubmit allowPristineSubmit color="primary" fill="solid" size="default">
                <FormattedMessage id="dictionary.next" />
              </FormSubmit>
            </IonButtons>
          </IonItem>
        </Form>
      )}
    </FormContainer>
  );
};

export default SetupGroupVerificationSettingsForm;
