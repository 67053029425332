import { IonButton, IonContent } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import { alertError, alertSuccess } from '../actions/notificationActions';
import Page from '../components/layout/Page';
import PageHeader from '../components/layout/PageHeader';
import useMountedTracking from '../hooks/useMountedTracking';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { AjaxError } from '../services/ajaxRequest';
import { handleSubmissionError } from '../services/formUtils';
import { deleteMember, openMember } from '../thunks/apiThunks';
import { Actions, JSONApi, Models } from '../types';

type Props = {
  group: JSONApi.GroupResource;
  member: JSONApi.MemberResource;
};

const EditClosedMemberProfilePage = ({ group, member }: Props) => {
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const isMounted = useMountedTracking();

  const handleLeaveGroupDecision = useCallback(
    (action: Actions.ApiThunkAction<Models.Member>, type: 'delete' | 'open') => {
      dispatch(action)
        .then(() => {
          if (isMounted.current) {
            history.push('/me-and-my-data/account');
            dispatch(alertSuccess(`forms.leaveGroup.${type}.success`));
          }
        })
        .catch((error: AjaxError) => {
          handleSubmissionError({
            dispatch,
            error,
            handleInvalid: () => {
              dispatch(alertError(`forms.leaveGroup.${type}.error`));
            },
            isMounted
          });
        });
    },
    [dispatch, history, isMounted]
  );

  const handleOpenClick = useCallback(() => {
    handleLeaveGroupDecision(openMember(group.attributes.slug, member), 'open');
  }, [group.attributes.slug, handleLeaveGroupDecision, member]);

  const handleDeleteClick = useCallback(() => {
    handleLeaveGroupDecision(deleteMember(group.attributes.slug, member), 'delete');
  }, [group.attributes.slug, handleLeaveGroupDecision, member]);

  return (
    <Page className="centered wide">
      <PageHeader defaultBackHref="/me-and-my-data/account" />
      <IonContent className="ion-padding">
        <h1 className="ion-padding">
          <FormattedMessage id="pages.editClosedMemberProfile.title" />
        </h1>
        <p className="ion-padding-end ion-padding-start">
          <FormattedMessage id="pages.editClosedMemberProfile.explanation" />
        </p>
        <div className="ion-padding">
          <IonButton color="success" expand="block" onClick={handleOpenClick}>
            <FormattedMessage id="forms.leaveGroup.openButton" />
          </IonButton>
          <IonButton color="danger" expand="block" fill="outline" onClick={handleDeleteClick}>
            <FormattedMessage id="forms.leaveGroup.deleteButton" />
          </IonButton>
        </div>
      </IonContent>
    </Page>
  );
};

export default EditClosedMemberProfilePage;
