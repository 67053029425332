import { IonButton, IonButtons, IonItem, IonLabel } from '@ionic/react';
import equal from 'deep-equal';
import { FormApi } from 'final-form';
import React, { useCallback } from 'react';
import { FormSpy } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import AddAttachment from '../../components/forms/AddAttachment';
import AttachmentFieldItems from '../../components/forms/AttachmentFieldItems';
import Form from '../../components/forms/Form';
import FormContainer from '../../components/forms/FormContainer';
import FormSubmit from '../../components/forms/FormSubmit';
import FormTextarea from '../../components/forms/FormTextarea';
import RequiredFieldMarker from '../../components/forms/RequiredFieldMarker';
import SubmitError from '../../components/forms/SubmitError';
import { maxTextInputLength } from '../../globals';
import useAttachmentsFieldManager from '../../hooks/useAttachmentsFieldManager';
import useDismissModalAndGoTo from '../../hooks/useDismissModalAndGoTo';
import useMountedTracking from '../../hooks/useMountedTracking';
import { Opener } from '../../hooks/useOpener';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { forceArray } from '../../services/arrayUtils';
import { handleFormSubmission } from '../../services/formUtils';
import { createMessage } from '../../thunks/apiThunks';
import { JSONApi, Models } from '../../types';

import './CustomForm.scss';

type FormValues = Models.CreateNote;

type Props = {
  group: JSONApi.GroupResource;
  opener: Opener;
  recipient: JSONApi.MemberResource;
};

const NewMessageForm = ({ group, opener, recipient }: Props) => {
  const attachmentsFieldManager = useAttachmentsFieldManager();
  const dismissModalAndGoTo = useDismissModalAndGoTo(opener);
  const dispatch = useThunkDispatch();
  const intl = useIntl();
  const isMounted = useMountedTracking();

  const initialValues: FormValues = {
    note: ''
  };

  const handleSubmit = useCallback(
    async (values: FormValues, form: FormApi<FormValues>) =>
      handleFormSubmission({
        action: createMessage(
          group.attributes.slug,
          recipient,
          values.note,
          attachmentsFieldManager.attachments
        ),
        dispatch,
        errorTranslationLocation: 'message',
        form,
        intl,
        isMounted,
        onSuccess: response => {
          const newConversation = forceArray(response.data)[0];
          dismissModalAndGoTo(
            `/g/${group.attributes.slug}/inbox/conversations/${newConversation.attributes.slug}`
          );
        },
        successTKey: 'forms.newMessage.create.success',
        values
      }),
    [
      attachmentsFieldManager,
      dismissModalAndGoTo,
      dispatch,
      group.attributes.slug,
      intl,
      isMounted,
      recipient
    ]
  );

  const validationSchema = Yup.object().shape({
    note: Yup.string()
      .required(intl.formatMessage({ id: 'errors.message.thread.notes.note.blank' }))
      .max(
        maxTextInputLength,
        intl.formatMessage(
          { id: 'errors.message.thread.notes.note.tooLong' },
          { count: maxTextInputLength }
        )
      )
  });

  return (
    <FormContainer<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ form, handleSubmit }) => (
        <div className="custom-form-wrapper">
          <Form<FormValues>
            className="message-form"
            onFormSubmit={handleSubmit}
            submit={form.submit}
          >
            <SubmitError />
            <IonLabel>
              <FormattedMessage id="models.message.attributes.note.label" />
              <RequiredFieldMarker />
            </IonLabel>
            <FormTextarea
              autoGrow={false}
              autofocus={opener.isPresented}
              name="note"
              placeholder={intl.formatMessage(
                { id: 'models.message.attributes.note.placeholder' },
                { name: recipient.attributes.firstName }
              )}
              rows={8}
            />
            <IonItem color="transparent" lines="none">
              <IonButtons slot="end">
                <IonButton
                  aria-label={intl.formatMessage({ id: 'models.attachment.add' })}
                  color="primary"
                  size="small"
                >
                  <AddAttachment attachmentsFieldManager={attachmentsFieldManager} />
                </IonButton>
              </IonButtons>
            </IonItem>
            <AttachmentFieldItems attachmentsFieldManager={attachmentsFieldManager} />
            <div className="ion-padding">
              <FormSpy subscription={{ pristine: true }}>
                {({ pristine }) => {
                  const isPristine =
                    pristine && equal(undefined, attachmentsFieldManager.attachments);
                  return (
                    <FormSubmit
                      allowPristineSubmit
                      disabled={attachmentsFieldManager.isUploading || isPristine}
                    >
                      <FormattedMessage id="dictionary.send" />
                    </FormSubmit>
                  );
                }}
              </FormSpy>
            </div>
          </Form>
        </div>
      )}
    </FormContainer>
  );
};

export default NewMessageForm;
