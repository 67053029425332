import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { DateTime } from 'luxon';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import EventCard from '../components/EventCard';
import EventFormModal from '../components/EventFormModal';
import EventSearchForm, { EventSearchFacets } from '../components/EventSearchForm';
import NoPostResults from '../components/NoPostResults';
import PostsPage from '../components/PostsPage';
import SearchResults from '../components/SearchResults';
import useOpener from '../hooks/useOpener';
import usePageVisitTracker from '../hooks/usePageVisitTracker';
import { loadEventSearch, loadMoreEventSearchResults } from '../thunks/apiThunks';
import { ApiEndpoint, JSONApi, Models, Search, State } from '../types';

type Props = {
  group: JSONApi.GroupResource;
};

const EventsPage = ({ group }: Props) => {
  const [eventsUpdatedAt, setEventsUpdatedAt] = useState(DateTime.local());
  const eventSearchParams = useSelector((root: State.Root) => root.search.eventParams);
  const [facets, setFacets] = useState<EventSearchFacets>({
    eventType: []
  });

  const modalOpener = useOpener();
  usePageVisitTracker(group, Models.Readable.EVENT);

  const anySearchesApplied = Object.values(eventSearchParams).filter(val => !!val).length > 0;

  const handleFacetsLoaded = useCallback((facets: Search.FacetCollection) => {
    setFacets({
      eventType: facets.eventType ?? []
    });
  }, []);

  const handleEventAdded = useCallback(() => {
    setTimeout(() => {
      setEventsUpdatedAt(DateTime.local());
    }, 1000);
  }, []);

  const renderSearchForm = useCallback(
    (isOpen: boolean) => (
      <div className="subheader">
        <EventSearchForm facets={facets} group={group} isOpen={isOpen} />
      </div>
    ),
    [facets, group]
  );

  return (
    <PostsPage
      group={group}
      onCreateClick={modalOpener.toggle}
      renderSearchForm={renderSearchForm}
      titleKey="pages.events.title"
    >
      {pageContentRef => (
        <>
          <EventFormModal
            group={group}
            onSuccess={handleEventAdded}
            opener={modalOpener}
            titleKey="modals.newEvent.title"
          />
          <SearchResults<Models.PostSearch, Models.ReturnedEvent, Search.PostParams>
            contentRef={pageContentRef}
            group={group}
            loadDataActionCreator={loadEventSearch}
            loadMoreActionCreator={loadMoreEventSearchResults}
            onFacetsLoaded={handleFacetsLoaded}
            resultsUpdatedAt={eventsUpdatedAt}
            searchEndpoint={ApiEndpoint.EVENT_SEARCH}
            searchParams={eventSearchParams}
            showCount={anySearchesApplied}
          >
            {results => (
              <>
                {results.length === 0 && (
                  <NoPostResults onClick={modalOpener.toggle} tKey="pages.events.noResults" />
                )}
                {results.length > 0 && (
                  <IonGrid className="cards">
                    <IonRow>
                      {results.map(event => (
                        <IonCol key={event.id} size="12" sizeLg="6" sizeXl="4">
                          <EventCard event={event} group={group} />
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonGrid>
                )}
              </>
            )}
          </SearchResults>
        </>
      )}
    </PostsPage>
  );
};

export default EventsPage;
