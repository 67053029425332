import React from 'react';
import { FormSpy } from 'react-final-form';

import ErrorMessage from '../../components/forms/ErrorMessage';

const SubmitError = () => (
  <FormSpy subscription={{ submitError: true }}>
    {({ submitError }) => {
      if (submitError) {
        return <ErrorMessage>{submitError}</ErrorMessage>;
      }
      return null;
    }}
  </FormSpy>
);

export default SubmitError;
