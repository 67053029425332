import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { alertWarning } from '../../actions/notificationActions';
import useCurrentUser from '../../hooks/useCurrentUser';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { checkForEmailConfirmationPath } from '../../services/urlUtils';
import { JSONApi, SessionState, State } from '../../types';

type Props = {
  children: JSX.Element;
  group?: JSONApi.GroupResource;
};

const GuardAgainstUnconfirmedUser = ({ children, group }: Props) => {
  const currentUser = useCurrentUser();
  const dispatch = useThunkDispatch();
  const userState = useSelector((root: State.Root) => root.session.currentUser.state);

  if (userState === SessionState.LOADING) {
    return null;
  }

  if (currentUser && !currentUser.attributes.confirmed) {
    dispatch(alertWarning('pages.checkForEmailConfirmation.warning'));
    return <Redirect to={checkForEmailConfirmationPath(group)} />;
  }

  return children;
};

export default GuardAgainstUnconfirmedUser;
