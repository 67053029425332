import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getUserMemberForGroup } from '../selectors';
import { JSONApi, State } from '../types';

const useNewsPostingPermission = (user: JSONApi.UserResource, group: JSONApi.GroupResource) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const [isAllowed, setIsAllowed] = useState(false);

  const checkForMemberPermission = useCallback(() => {
    const member = getUserMemberForGroup(apiData, user, group);
    return !!member && (member.attributes.moderator || group.attributes.usersCanCreateNews);
  }, [apiData, group, user]);

  useEffect(() => {
    setIsAllowed(user.attributes.admin || checkForMemberPermission());
  }, [checkForMemberPermission, user]);

  return isAllowed;
};

export default useNewsPostingPermission;
