import { IonContent, IonItem } from '@ionic/react';
import React, { createRef } from 'react';
import { FormattedMessage } from 'react-intl';

import Page from '../../components/layout/Page';
import PageHeader from '../../components/layout/PageHeader';
import { JSONApi } from '../../types';

type Props = {
  children: (contentRef: React.RefObject<HTMLIonContentElement>) => React.ReactElement;
  contentClass?: string;
  group: JSONApi.GroupResource;
  h2Key: string;
};

const ModeratorPage = ({ children, contentClass, group, h2Key }: Props) => {
  const contentRef = createRef<HTMLIonContentElement>();

  return (
    <Page>
      <PageHeader
        defaultBackHref={`/g/${group.attributes.slug}/manage-group`}
        group={group}
        titleKey="pages.manageGroup.tools.title"
      />
      <IonContent className={contentClass ?? ''} ref={contentRef} scrollEvents>
        <IonItem color="transparent" lines="none">
          <h2>
            <FormattedMessage id={h2Key} />
          </h2>
        </IonItem>
        {children(contentRef)}
      </IonContent>
    </Page>
  );
};

export default ModeratorPage;
