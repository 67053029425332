import { combineReducers } from 'redux';

import { apiReducer } from './apiReducer';
import { loadingReducer } from './loadingReducer';
import { notificationsReducer } from './notificationsReducer';
import { searchReducer } from './searchReducer';
import { sessionReducer } from './sessionReducer';

const createRootReducer = () =>
  combineReducers({
    api: apiReducer,
    loading: loadingReducer,
    notifications: notificationsReducer,
    search: searchReducer,
    session: sessionReducer
  });

export default createRootReducer;
