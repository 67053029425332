import { IonLabel, IonSegment, IonSegmentButton, SegmentChangeEventDetail } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Search } from '../types';

import '../components/forms/CustomForm.scss';

type Props<T extends Search.Params> = {
  defaultOption: Search.SortOptions;
  options: Search.SortOptions[];
  searchParams: T;
  setSearchParams: (params: T) => void;
};

function SearchSort<T extends Search.Params>({
  defaultOption,
  options,
  searchParams,
  setSearchParams
}: Props<T>) {
  const handleIonChange = useCallback(
    (event: CustomEvent<SegmentChangeEventDetail>) => {
      const newSort = event.detail?.value as Search.SortOptions | undefined;
      if (newSort && newSort !== searchParams.sort) {
        setSearchParams({ ...searchParams, sort: newSort });
      }
    },
    [searchParams, setSearchParams]
  );

  return (
    <>
      <IonLabel>
        <FormattedMessage id="forms.search.sort.label" />
      </IonLabel>
      <IonSegment
        mode="ios"
        onIonChange={handleIonChange}
        scrollable
        value={searchParams.sort ?? defaultOption}
      >
        {options.map(option => (
          <IonSegmentButton key={option} value={option}>
            <FormattedMessage id={`forms.search.sortOptions.${option}`} />
          </IonSegmentButton>
        ))}
      </IonSegment>
    </>
  );
}

export default SearchSort;
