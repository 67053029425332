import { IonCheckbox, IonItem, IonLabel } from '@ionic/react';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { alertError, alertSuccess } from '../actions/notificationActions';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { updateSubscription } from '../thunks/apiThunks';
import { JSONApi } from '../types';

type FieldNames =
  | 'emailAcceptedInvites'
  | 'emailDiscussions'
  | 'emailEvents'
  | 'emailJobPosts'
  | 'emailMentions'
  | 'emailNewsItems'
  | 'pushAcceptedInvites'
  | 'pushDiscussions'
  | 'pushEvents'
  | 'pushJobPosts'
  | 'pushMentions'
  | 'pushNewsItems';

type Props = {
  group: JSONApi.GroupResource;
  lines?: 'full' | 'inset' | 'none';
  name: FieldNames;
  subscription: JSONApi.SubscriptionResource;
};

const SubscriptionCheckbox = ({ group, lines, name, subscription }: Props) => {
  const [checked, setChecked] = useState(subscription.attributes[name]);
  const dispatch = useThunkDispatch();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLIonCheckboxElement>) => {
      const checkbox = event.target as HTMLIonCheckboxElement & typeof event.target;
      setChecked(checkbox.checked);
      const attributes: Partial<Record<FieldNames, boolean>> = { [name]: checkbox.checked };
      dispatch(updateSubscription(group.attributes.slug, attributes, subscription.id))
        .then(() => {
          dispatch(alertSuccess('forms.subscription.update.success'));
        })
        .catch(() => {
          dispatch(alertError('forms.subscription.update.error'));
        });
    },
    [dispatch, group.attributes.slug, name, subscription.id]
  );

  return (
    <IonItem lines={lines}>
      <IonCheckbox checked={checked} onClick={handleClick} slot="start" />
      <IonLabel>
        <FormattedMessage id={`models.subscription.attributes.${name}.label`} />
      </IonLabel>
    </IonItem>
  );
};

export default SubscriptionCheckbox;
