import { IonTextareaCustomEvent, IonToggleCustomEvent, ToggleChangeEventDetail } from '@ionic/core';
import { TextareaChangeEventDetail } from '@ionic/react';
import React from 'react';
import { useField } from 'react-final-form';

import Textarea from '../../components/forms/Textarea';
import useMergeOnChange from '../../hooks/useMergeOnChange';

// name is required for form fields
const FormTextarea = ({
  name,
  onIonChange,
  ...props
}: React.ComponentProps<typeof Textarea> & {
  name: string;
  onIonChange?: ((event: IonToggleCustomEvent<ToggleChangeEventDetail<any>>) => void) | undefined;
}) => {
  const field = useField(name, { parse: (value: string) => value, subscription: { value: true } });
  // the checked, multiple, and type props from react-final-form do not pertain to textareas
  // and we need to handle onChange manually during the onIonChange handler
  const { checked, multiple, onChange, type, ...fieldTextareaProps } = field.input;
  const handleIonChange = useMergeOnChange<IonTextareaCustomEvent<TextareaChangeEventDetail>>({
    onChange,
    onIonChange
  });

  return <Textarea {...fieldTextareaProps} {...props} onIonChange={handleIonChange} />;
};

export default FormTextarea;
