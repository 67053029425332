import { IonRefresher, IonRefresherContent, RefresherEventDetail } from '@ionic/react';
import * as Sentry from '@sentry/browser';
import deepEqual from 'deep-equal';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { apiClearCachedResponsesForEndpoint } from '../actions/apiActions';
import InfiniteScroll from '../components/InfiniteScroll';
import LocalLoadingIndicator from '../components/LocalLoadingIndicator';
import Nbsp from '../components/Nbsp';
import useMountedTracking from '../hooks/useMountedTracking';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { findById } from '../selectors';
import { AjaxResponse } from '../services/ajaxRequest';
import { forceArray } from '../services/arrayUtils';
import { cancelablePromise } from '../services/promiseUtils';
import { Actions, ApiEndpoint, JSONApi, Models, Search, State } from '../types';

type FacetsLoadedHandler = (facets: Search.FacetCollection) => void;

type InjectResults<ModelType extends Models.Base> = (
  results: JSONApi.Resource<ModelType>[]
) => React.ReactElement;

type LoadAction<SearchType extends Models.Base> = (
  dispatch: Actions.ThunkDispatch,
  getState: () => State.Root
) => Promise<AjaxResponse<SearchType> | undefined>;

type LoadActionCreator<SearchType extends Models.Base> = () => LoadAction<SearchType>;

export type SearchResultsCoreProps<
  SearchType extends Models.Base,
  ModelType extends Models.Base,
  ParamsType
> = {
  children: InjectResults<ModelType>;
  contentRef: React.RefObject<HTMLIonContentElement>;
  loadMoreActionCreator: LoadActionCreator<SearchType>;
  onFacetsLoaded?: FacetsLoadedHandler;
  resultsUpdatedAt?: DateTime;
  searchEndpoint: ApiEndpoint;
  searchParams: ParamsType;
  showCount?: boolean;
};

// The SearchResource is the type of search result that is returned from the api,
// the ModelType is the type of resource for the results of the search, and
// the ParamsType is the type of the search params it expects.
function SearchResultsCore<
  SearchType extends Models.Search,
  ModelType extends Models.Base,
  ParamsType extends Search.Params
>({
  children,
  contentRef,
  dispatchLoadAction,
  loadMoreActionCreator,
  onFacetsLoaded,
  resultsUpdatedAt,
  searchEndpoint,
  searchParams,
  showCount = true
}: SearchResultsCoreProps<SearchType, ModelType, ParamsType> & {
  dispatchLoadAction: () => Promise<AjaxResponse<SearchType> | undefined>;
}) {
  const apiData = useSelector((root: State.Root) => root.api);
  const dispatch = useThunkDispatch();
  const isMounted = useMountedTracking();
  const [hitCount, setHitCount] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [searchFailed, setSearchFailed] = useState(false);
  const [searchResultIdentifiers, setSearchResultIdentifiers] = useState<
    JSONApi.RelationshipData[] | null
  >(null);
  const [searchResults, setSearchResults] = useState<JSONApi.Resource<ModelType>[] | null>(null);

  const loadData = useCallback(
    ({ skipLoading = false }: { skipLoading?: boolean }) => {
      if (!skipLoading) {
        setLoading(true);
      }
      setSearchFailed(false);
      const loadDataPromise = dispatchLoadAction();
      const cancelableLoadDataPromise = cancelablePromise<AjaxResponse<SearchType> | undefined>(
        loadDataPromise
      );
      cancelableLoadDataPromise.promise
        .then(response => {
          if (response) {
            response.dataLoadedIntoStatePromise.then(() => {
              if (isMounted.current) {
                const data = forceArray(response.data)[0];
                if (data) {
                  setSearchResultIdentifiers(forceArray(data.relationships.results.data));
                  if (onFacetsLoaded) {
                    onFacetsLoaded(data.attributes.facets);
                  }
                  setHitCount(data.attributes.hitCount);
                }
              }
            });
          }
          if (!skipLoading) {
            setLoading(false);
          }
        })
        .catch(error => {
          if (!deepEqual(error, { isCanceled: true })) {
            Sentry.captureException(error);
            if (isMounted.current) {
              if (!skipLoading) {
                setLoading(false);
              }
              setHitCount(null);
              setSearchResultIdentifiers([]);
              setSearchResults(null);
              setSearchFailed(true);
            }
          }
        });
      return cancelableLoadDataPromise;
    },
    [dispatchLoadAction, isMounted, onFacetsLoaded]
  );

  const handleIonRefresh = useCallback(
    (event: CustomEvent<RefresherEventDetail>) => {
      dispatch(apiClearCachedResponsesForEndpoint(searchEndpoint));
      loadData({ skipLoading: true }).promise.then(() => {
        if (isMounted.current) {
          event.detail.complete();
        }
      });
    },
    [dispatch, isMounted, loadData, searchEndpoint]
  );

  useEffect(() => {
    const promise = loadData({});
    return () => promise.cancel();
  }, [loadData, resultsUpdatedAt]);

  useEffect(() => {
    if (searchResultIdentifiers) {
      const searchResultsFromIdentifiers = searchResultIdentifiers
        .map(identifier => findById<ModelType>(apiData, identifier.type, identifier.id))
        .filter(item => !!item) as JSONApi.Resource<ModelType>[];
      if (!deepEqual(searchResults, searchResultsFromIdentifiers)) {
        setSearchResults(searchResultsFromIdentifiers);
      }
    }
  }, [apiData, searchResults, searchResultIdentifiers]);

  const handleIncrementalDataLoad = useCallback(
    (items: JSONApi.Resource<SearchType>[]) => {
      const data = forceArray(items[0].relationships.results.data);
      if (searchResultIdentifiers) {
        setSearchResultIdentifiers(searchResultIdentifiers.concat(data));
      } else {
        setSearchResultIdentifiers(data);
      }
    },
    [searchResultIdentifiers]
  );

  return (
    <>
      {showCount && hitCount !== null && (
        <div className="subheader">
          <div className="hitcount ion-padding">
            {hitCount !== null && hitCount === 0 && <FormattedMessage id="search.noResults" />}
            {hitCount !== null && hitCount > 0 && (
              <FormattedMessage id="search.hitCount" values={{ num: hitCount }} />
            )}
            <Nbsp />
            {searchParams.query && searchParams.query.length > 0 && (
              <>
                <FormattedMessage id="search.forQuery" />
                <Nbsp />
                <strong>{searchParams.query}</strong>
                <Nbsp />
              </>
            )}
            {searchParams.sort && (
              <>
                <FormattedMessage id="search.forSort" />
                <Nbsp />
                <strong>
                  <FormattedMessage id={`forms.search.sortOptions.${searchParams.sort}`} />
                </strong>
              </>
            )}
          </div>
        </div>
      )}
      <IonRefresher onIonRefresh={handleIonRefresh} slot="fixed">
        <IonRefresherContent />
      </IonRefresher>
      {loading && <LocalLoadingIndicator />}
      {searchFailed && (
        <div className="ion-padding">
          <FormattedMessage id="forms.search.update.failed" />
        </div>
      )}
      {!loading && searchResults && children(searchResults)}
      <InfiniteScroll<SearchType>
        contentRef={contentRef}
        loadMoreActionCreator={loadMoreActionCreator}
        onDataLoad={handleIncrementalDataLoad}
      />
    </>
  );
}

export default SearchResultsCore;
