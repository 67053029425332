import { IonAvatar, IonIcon } from '@ionic/react';
import loadImage from 'blueimp-load-image';
import { camera } from 'ionicons/icons';
import React, { useCallback } from 'react';

import FileInput from '../../components/forms/FileInput';
import Img from '../../components/Img';
import useMountedTracking from '../../hooks/useMountedTracking';
import { orientedImageToDataURL } from '../../services/imageUtils';
import { JSONApi } from '../../types';

import './EditAvatar.scss';

type Props = {
  alt: string;
  isUpdating: boolean;
  onFail: (errors?: JSONApi.Error[] | string) => void;
  setIsUpdating: (isUpdating: boolean) => void;
  src: string;
  updateAvatar: (imageDataUrl: string) => void;
};

const EditAvatar = (props: Props) => {
  const { alt, isUpdating, onFail, setIsUpdating, src, updateAvatar } = props;
  const isMounted = useMountedTracking();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsUpdating(true);
      const input = event.target;
      const newAvatarImage = input?.files?.[0];
      if (newAvatarImage) {
        loadImage(
          newAvatarImage,
          orientedImg => {
            const dataURL = orientedImageToDataURL(orientedImg);
            if (dataURL) {
              updateAvatar(dataURL);
            } else if (isMounted.current) {
              onFail();
            }
          },
          { canvas: true, contain: true, maxHeight: 1280, maxWidth: 1280, orientation: true }
        );
      } else {
        setIsUpdating(false);
      }
    },
    [isMounted, onFail, setIsUpdating, updateAvatar]
  );

  const handleKeyUp = useCallback((event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      const target = event.target as HTMLIonAvatarElement & typeof event.target;
      target.click();
    }
  }, []);

  return (
    <FileInput accept="image/*" disabled={isUpdating} name="avatarImage" onChange={handleChange}>
      <div className="edit-avatar ion-padding">
        <IonAvatar className="ion-activatable" onKeyUp={handleKeyUp} role="button" tabIndex={0}>
          <Img alt={alt} isProcessing={isUpdating} src={src} />
        </IonAvatar>
        <div
          className="camera-button ion-activatable"
          color="secondary"
          onKeyUp={handleKeyUp}
          role="button"
          tabIndex={0}
        >
          <IonIcon icon={camera} slot="icon-only" />
        </div>
      </div>
    </FileInput>
  );
};

export default EditAvatar;
