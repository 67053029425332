import { IonBadge, TextareaChangeEventDetail } from '@ionic/react';
import equal from 'deep-equal';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import BasicLink from '../../components/BasicLink';
import FormTip from '../../components/forms/FormTip';
import Textarea from '../../components/forms/Textarea';
import { singleEmailRegexMatcher } from '../../globals';

type Props = {
  inviteeEmails: string[];
  inviteeEmailsString: string;
  onBackClick: () => void;
  setInviteeEmails: (emails: string[]) => void;
  setInviteeEmailsString: (emails: string) => void;
};

const InviteeEmailsListField = ({
  inviteeEmails,
  inviteeEmailsString,
  onBackClick,
  setInviteeEmails,
  setInviteeEmailsString
}: Props) => {
  const intl = useIntl();

  useEffect(() => {
    const newInviteeEmails = inviteeEmailsString
      .split(/[,\n\r]/i)
      .map(potentialEmail => potentialEmail.trim())
      .filter(
        potentialEmail => potentialEmail.length > 0 && singleEmailRegexMatcher.exec(potentialEmail)
      );
    if (!equal(inviteeEmails, newInviteeEmails)) {
      setInviteeEmails(newInviteeEmails);
    }
  }, [inviteeEmails, inviteeEmailsString, setInviteeEmails]);

  const handleIonChange = useCallback(
    (event: CustomEvent<TextareaChangeEventDetail>) => {
      const target = event.target as HTMLIonTextareaElement & typeof event.target;
      if (target) {
        setInviteeEmailsString(target.value ?? '');
      }
    },
    [setInviteeEmailsString]
  );

  return (
    <>
      {inviteeEmails.length > 0 && (
        <div className="ion-float-right">
          <IonBadge color="medium">
            <FormattedMessage
              id="models.invite.attributes.bulkInvitesList.count"
              values={{ num: inviteeEmails.length }}
            />
          </IonBadge>
        </div>
      )}
      <Textarea
        aria-label={intl.formatMessage({
          id: 'models.invite.attributes.bulkInvitesList.label'
        })}
        autoCorrect="off"
        autocapitalize="off"
        autofocus
        color="primary"
        name="bulkInvitesList"
        onIonChange={handleIonChange}
        value={inviteeEmailsString}
      />
      <FormTip id="models.invite.attributes.bulkInvitesList.tip" />
      <div className="ion-margin-bottom">
        <BasicLink onClick={onBackClick} tKey="dictionary.back" />
      </div>
    </>
  );
};

export default InviteeEmailsListField;
