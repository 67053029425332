import * as Sentry from '@sentry/browser';
import { produce } from 'immer';

import { SessionAction, SessionActionTypes } from '../actions/sessionActions';
import { storage } from '../services/storage';
import { SessionState, State } from '../types';

const defaultState: State.Session = {
  authToken: undefined,
  currentGroup: {
    slug: null,
    state: SessionState.LOADING
  },
  currentUser: {
    id: null,
    state: SessionState.LOADING
  },
  pushNotificationToken: undefined
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const sessionReducer = (state = defaultState, action: SessionActionTypes): State.Session => {
  switch (action.type) {
    case SessionAction.CLEAR_SESSION:
      storage.remove('authToken');
      storage.remove('userId');
      Sentry.configureScope(scope => {
        scope.setUser({ id: undefined });
      });
      return {
        ...state,
        authToken: null,
        currentUser: {
          id: null,
          state: SessionState.NONE
        }
      };
    case SessionAction.SET_AUTH_TOKEN:
      if (action.payload.authToken) {
        storage.set('authToken', action.payload.authToken);
      } else {
        storage.remove('authToken');
      }
      return {
        ...state,
        authToken: action.payload.authToken
      };
    case SessionAction.SET_CURRENT_GROUP_DATA:
      Sentry.configureScope(scope => {
        scope.setContext('group', { slug: action.payload.slug });
      });

      return produce(state, draft => {
        draft.currentGroup.state = action.payload.state;
        draft.currentGroup.slug = action.payload.slug;
      });
    case SessionAction.SET_CURRENT_USER_DATA:
      Sentry.configureScope(scope => {
        scope.setUser({ id: action.payload.id });
      });

      if (action.payload.id) {
        storage.set('userId', action.payload.id);
      } else {
        storage.remove('userId');
      }
      return produce(state, draft => {
        draft.currentUser.id = action.payload.id;
        draft.currentUser.state = action.payload.state;
      });
    case SessionAction.SET_PUSH_NOTIFICATION_TOKEN:
      return {
        ...state,
        pushNotificationToken: action.payload.pushNotificationToken
      };
    default:
      return state;
  }
};
