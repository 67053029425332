import { IonItem, IonLabel, IonList } from '@ionic/react';
import React from 'react';

import FormModal from '../components/FormModal';
import MemberAvatar from '../components/MemberAvatar';
import SearchResults from '../components/SearchResults';
import { Opener } from '../hooks/useOpener';
import { loadMemberSearch, loadMoreMemberSearchResults } from '../thunks/apiThunks';
import { ApiEndpoint, JSONApi, ModelAttributes, Models, Search } from '../types';

type Props = {
  contentId: string;
  contentType: ModelAttributes.ReactionContentType;
  emotion: ModelAttributes.ReactionEmotion;
  group: JSONApi.GroupResource;
  opener: Opener;
  titleKey: string;
};

const ReactionsModal = ({ contentId, contentType, emotion, group, opener, titleKey }: Props) => {
  const memberSearchParams: Search.MemberParams = {
    country: null,
    industry: null,
    lat: null,
    lng: null,
    location: null,
    query: null,
    radius: null,
    reactionContentId: contentId,
    reactionContentType: contentType,
    reactionEmotion: emotion,
    traitOptionIds: ''
  };

  return (
    <FormModal opener={opener} titleKey={titleKey}>
      {contentRef => (
        <SearchResults<Models.MemberSearch, Models.Member, Search.MemberParams>
          contentRef={contentRef}
          group={group}
          loadDataActionCreator={loadMemberSearch}
          loadMoreActionCreator={loadMoreMemberSearchResults}
          searchEndpoint={ApiEndpoint.MEMBER_SEARCH}
          searchParams={memberSearchParams}
          showCount={false}
        >
          {members => (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {members.length > 0 && (
                <IonList className="ion-no-padding settings-list">
                  {members.map((member, index) => (
                    <IonItem
                      color="light"
                      key={member.id}
                      lines={index === members.length - 1 ? 'none' : 'full'}
                      routerLink={`/g/${group.attributes.slug}/members/${member.attributes.slug}`}
                    >
                      <MemberAvatar member={member} slot="start" />
                      <IonLabel>{member.attributes.fullName}</IonLabel>
                    </IonItem>
                  ))}
                </IonList>
              )}
            </>
          )}
        </SearchResults>
      )}
    </FormModal>
  );
};

export default ReactionsModal;
