import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ConversationCard from '../components/ConversationCard';
import { JSONApi } from '../types';

import './Cards.scss';

type Props = {
  conversations: JSONApi.ConversationResource[];
  group: JSONApi.GroupResource;
};

const ConversationList = ({ conversations, group }: Props) => {
  if (conversations.length === 0) {
    return (
      <h2 className="ion-padding">
        <FormattedMessage id="pages.inbox.noResults" />
      </h2>
    );
  }

  return (
    <IonGrid className="cards conversation-list">
      <IonRow className="cards-inner">
        {conversations.map(conversation => (
          <IonCol className="card-wrapper" key={conversation.id} size="12">
            <ConversationCard conversation={conversation} group={group} />
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
};

export default ConversationList;
