import { DateTime } from 'luxon';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ChangePreviewModal from '../../components/forms/ChangePreviewModal';
import Paragraph from '../../components/Paragraph';
import { Opener } from '../../hooks/useOpener';
import { JSONApi } from '../../types';

type Props = {
  group: JSONApi.GroupResource;
  handleAccept: () => void;
  handleCancel: () => void;
  opener: Opener;
};

const CancelPlanPreviewModal = ({ group, handleAccept, handleCancel, opener }: Props) => (
  <ChangePreviewModal
    acceptButtonTextKey="modals.planCancel.cancel"
    group={group}
    handleAccept={handleAccept}
    handleCancel={handleCancel}
    opener={opener}
    titleKey="modals.planCancel.title"
  >
    <Paragraph>
      <FormattedMessage
        id="models.plan.cancel.p1"
        values={{
          effectiveDate: group.attributes.planCurrentPeriodEnd.toLocaleString(DateTime.DATE_FULL)
        }}
      />
    </Paragraph>
    <Paragraph>
      <FormattedMessage id="models.plan.cancel.p2" />
    </Paragraph>
    <Paragraph>
      <FormattedMessage id="models.plan.cancel.p3" />
    </Paragraph>
  </ChangePreviewModal>
);

export default CancelPlanPreviewModal;
