import { IonSelectCustomEvent, IonToggleCustomEvent, ToggleChangeEventDetail } from '@ionic/core';
import { SelectChangeEventDetail } from '@ionic/react';
import React from 'react';
import { useField } from 'react-final-form';

import Select from '../../components/forms/Select';
import useMergeOnChange from '../../hooks/useMergeOnChange';

// name is required for form fields
const FormSelect = ({
  name,
  onIonChange,
  ...props
}: React.ComponentProps<typeof Select> & {
  name: string;
  onIonChange?: ((event: IonToggleCustomEvent<ToggleChangeEventDetail<any>>) => void) | undefined;
}) => {
  const field = useField(name, { subscription: { value: true } });
  // the checked and type props from react-final-form do not pertain to selects
  // and we need to handle onChange manually during the onIonChange handler
  const { checked, onChange, type, ...fieldSelectProps } = field.input;
  const handleIonChange = useMergeOnChange<IonSelectCustomEvent<SelectChangeEventDetail>>({
    onChange,
    onIonChange
  });

  return <Select {...fieldSelectProps} {...props} onIonChange={handleIonChange} />;
};

export default FormSelect;
