import { IonButton, IonButtons, IonItem, IonProgressBar } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { WizardSteps } from '../components/CreateGroupPage';
import FormSubmit from '../components/forms/FormSubmit';
import GroupFeaturesForm from '../components/forms/GroupFeaturesForm';
import Heading from '../components/Heading';
import Paragraph from '../components/Paragraph';
import ToggleMemberField from '../components/ToggleMemberField';
import { getUserMemberForGroup } from '../selectors';
import { JSONApi, State } from '../types';

type Props = {
  currentUser: JSONApi.UserResource;
  group: JSONApi.GroupResource;
};

export const SetupGroupFeatures = ({ currentUser, group }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const member = getUserMemberForGroup(apiData, currentUser, group);
  const history = useHistory();

  const handleBackClick = useCallback(() => {
    history.push({ pathname: `/create/${group.attributes.slug}/${WizardSteps.RULES}` });
  }, [group.attributes.slug, history]);

  const handleSubmitSuccess = useCallback(() => {
    if (currentUser.attributes.userComplete) {
      history.push(`/create/${group.attributes.slug}/${WizardSteps.PAYMENT}`);
    } else {
      history.push(`/create/${group.attributes.slug}/${WizardSteps.SETUP_ACCOUNT}`);
    }
  }, [currentUser.attributes.userComplete, group.attributes.slug, history]);

  return (
    <div className="setup-group-features-form">
      <IonProgressBar buffer={0.6} color="success" value={0} />
      <Heading level={2}>
        <FormattedMessage id="pages.groupWizard.features.title" />
      </Heading>
      <Paragraph>
        <FormattedMessage id="pages.groupWizard.features.description" />
      </Paragraph>
      <GroupFeaturesForm group={group} onSuccess={handleSubmitSuccess} showNews={false}>
        {member && (
          <>
            <hr />
            <Paragraph>
              <FormattedMessage id="models.member.attributes.moderatorEmailSettings.description" />
            </Paragraph>
            <IonItem className="compact" lines="none">
              <ToggleMemberField
                attributeName="wantsModeratorEmails"
                group={group}
                member={member}
                tErrorKey="forms.subscription.update.error"
                tSuccessKey="forms.subscription.update.success"
                textType="paragraph"
                uiType="toggle"
              />
            </IonItem>
            <IonItem className="compact" lines="none">
              <ToggleMemberField
                attributeName="wantsModeratorSupportEmails"
                group={group}
                member={member}
                tErrorKey="forms.subscription.update.error"
                tSuccessKey="forms.subscription.update.success"
                textType="paragraph"
                uiType="toggle"
              />
            </IonItem>
          </>
        )}
        <IonItem color="transparent" lines="none">
          <IonButtons slot="end">
            <IonButton color="primary" fill="outline" onClick={handleBackClick} size="default">
              <FormattedMessage id="dictionary.previous" />
            </IonButton>
            <FormSubmit allowPristineSubmit color="primary" fill="solid" size="default">
              <FormattedMessage id="dictionary.next" />
            </FormSubmit>
          </IonButtons>
        </IonItem>
      </GroupFeaturesForm>
    </div>
  );
};

export default SetupGroupFeatures;
