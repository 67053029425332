import { IonLabel } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import SearchableSelect from '../components/forms/SearchableSelect';
import { SearchableSelectOptionData } from '../components/forms/SearchableSelectModal';
import { cityQueryHandler, PlaceOptionMeta } from '../services/placeSearch';
import { Search } from '../types';

type ExtendedSearchParams<T extends Search.Params> = T & {
  lat: number | null;
  lng: number | null;
  location: string | null;
};

type Props<T extends Search.Params> = {
  searchParams: ExtendedSearchParams<T>;
  setSearchParams: (value: ExtendedSearchParams<T>) => void;
};

function SearchLocation<T extends Search.Params>({ searchParams, setSearchParams }: Props<T>) {
  const handleSelectLocationOption = useCallback(
    (option: SearchableSelectOptionData | undefined) => {
      if (option) {
        const meta = option.meta as PlaceOptionMeta;
        setSearchParams({
          ...searchParams,
          lat: meta.geoloc.lat,
          lng: meta.geoloc.lng,
          location: option.value
        });
      } else {
        setSearchParams({
          ...searchParams,
          lat: null,
          lng: null,
          location: null
        });
      }
    },
    [searchParams, setSearchParams]
  );

  /*
   * This is quite annoying, but because we apply a url path based on the value that is selected
   * we need a way to reflect the selected state for this search option, otherwise we will cause
   * an infinite loop of resetting the value to undefined and back. Because location search options
   * are generated from mapbox's location search, we have to create this ugly synthetic option
   * to reflect a selected state. I'm not proud of it, but it works.
   */
  const defaultOptions =
    searchParams.location && searchParams.lat && searchParams.lng
      ? [
          {
            id: searchParams.location,
            label: searchParams.location,
            meta: {
              geoloc: {
                lat: searchParams.lat,
                lng: searchParams.lng
              }
            },
            value: searchParams.location
          }
        ]
      : undefined;

  return (
    <>
      <IonLabel>
        <FormattedMessage id="models.search.attributes.location" />
      </IonLabel>
      <div className="searchable-select-wrapper-outer">
        <div className="searchable-select-wrapper-inner">
          <SearchableSelect
            defaultOptions={defaultOptions}
            name="location"
            onSelectOption={handleSelectLocationOption}
            queryHandler={cityQueryHandler}
            selectedOption={defaultOptions ? defaultOptions[0] : undefined}
            type="search"
            value={searchParams.location}
          />
        </div>
      </div>
    </>
  );
}

export default SearchLocation;
