import React from 'react';
import { useSelector } from 'react-redux';

import ModerateCommentFlags from '../components/ModerateCommentFlags';
import ModerateMemberFlags from '../components/ModerateMemberFlags';
import ModeratePostFlags from '../components/ModeratePostFlags';
import { getFlaggedItemItem } from '../selectors';
import { isComment, isMember, isPost } from '../services/typeDetection';
import { JSONApi, State } from '../types';

type Props = {
  flaggedItem: JSONApi.FlaggedItemResource;
  group: JSONApi.GroupResource;
};

const ModerateFlaggedItem = ({ flaggedItem, group }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);

  const item = getFlaggedItemItem(apiData, flaggedItem);

  return (
    <>
      {isComment(item) && (
        <ModerateCommentFlags comment={item} flaggedItem={flaggedItem} group={group} />
      )}
      {isPost(item) && <ModeratePostFlags flaggedItem={flaggedItem} group={group} post={item} />}
      {isMember(item) && (
        <ModerateMemberFlags flaggedItem={flaggedItem} group={group} member={item} />
      )}
    </>
  );
};

export default ModerateFlaggedItem;
