import { IonCol, IonContent, IonGrid, IonRow } from '@ionic/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Page from '../components/layout/Page';
import PageHeader from '../components/layout/PageHeader';
import LocalLoadingIndicator from '../components/LocalLoadingIndicator';
import MentionCard from '../components/MentionCard';
import useApiData from '../hooks/useApiData';
import useCurrentMember from '../hooks/useCurrentMember';
import { getMentions } from '../selectors';
import { forceArray } from '../services/arrayUtils';
import { loadMentions } from '../thunks/apiThunks';
import { JSONApi, Models, State } from '../types';

type Props = {
  group: JSONApi.GroupResource;
};

const MentionsPage = ({ group }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const currentMember = useCurrentMember();

  const findMentions = useCallback(
    () =>
      getMentions(apiData, group).filter(
        mention =>
          currentMember && forceArray(mention.relationships.member.data)[0].id === currentMember.id
      ),
    [apiData, currentMember, group]
  );

  const { loading, responseData: mentions } = useApiData<Models.Mention>(
    loadMentions(group.attributes.slug),
    findMentions,
    'MentionsPage'
  );

  if (loading) {
    return <LocalLoadingIndicator />;
  }

  return (
    <Page className="centered wide">
      <PageHeader defaultBackHref={`/g/${group.attributes.slug}`} titleKey="pages.mentions.title" />
      <IonContent className="canvas" scrollEvents>
        {mentions.length === 0 ? (
          <div className="ion-padding-end ion-padding-start">
            <h2>
              <FormattedMessage id="pages.mentions.none" />
            </h2>
          </div>
        ) : (
          <IonGrid className="cards">
            <IonRow className="cards-inner">
              {mentions.map(mention => (
                <IonCol className="card-wrapper" key={mention.id} size="12">
                  <MentionCard group={group} mention={mention} />
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </Page>
  );
};

export default MentionsPage;
