import React from 'react';
import { Route, Switch } from 'react-router';

import AccountPage from '../../components/AccountPage';
import ChangeEmailPage from '../../components/ChangeEmailPage';
import ChangePasswordPage from '../../components/ChangePasswordPage';
import EditMemberProfilePage from '../../components/EditMemberProfilePage';
import GroupNotificationsPage from '../../components/GroupNotificationsPage';
import MeAndMyDataPage from '../../components/MeAndMyDataPage';
import NotificationsPage from '../../components/NotificationsPage';
import RequireCurrentUser from '../../components/RequireCurrentUser';
import RequireGroupAndMember from '../../components/RequireGroupAndMember';

const MeAndMyDataRoutes = () => (
  // and more stuff
  <RequireCurrentUser>
    {currentUser => (
      <Switch>
        <Route exact path="/me-and-my-data/account">
          {() => <AccountPage currentUser={currentUser} />}
        </Route>
        <Route exact path="/me-and-my-data/emails/:email?">
          {() => <ChangeEmailPage currentUser={currentUser} />}
        </Route>
        <Route exact path="/me-and-my-data/memberships/:slug">
          {() => (
            <RequireGroupAndMember allowUnapproved redirect="/me-and-my-data/account">
              {(group, member) => <EditMemberProfilePage group={group} member={member} />}
            </RequireGroupAndMember>
          )}
        </Route>
        <Route exact path="/me-and-my-data/password">
          {() => <ChangePasswordPage user={currentUser} />}
        </Route>
        <Route exact path="/me-and-my-data/notifications">
          {() => <NotificationsPage currentUser={currentUser} />}
        </Route>
        <Route exact path="/me-and-my-data/notifications/:slug">
          {() => (
            <RequireGroupAndMember allowUnapproved redirect="/me-and-my-data/notifications">
              {(group, member) => <GroupNotificationsPage group={group} member={member} />}
            </RequireGroupAndMember>
          )}
        </Route>
        <Route path="/me-and-my-data">{() => <MeAndMyDataPage currentUser={currentUser} />}</Route>
      </Switch>
    )}
  </RequireCurrentUser>
);
export default MeAndMyDataRoutes;
