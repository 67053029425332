import React from 'react';
import { useIntl } from 'react-intl';

import AvatarWithDefault from '../components/AvatarWithDefault';
import { maybeSlot } from '../services/componentUtils';
import { JSONApi } from '../types';

type Props = {
  member: JSONApi.MemberResource;
  slot?: string;
};

const MemberAvatar = ({ member, slot }: Props) => {
  const intl = useIntl();

  const { firstName, profileImage200Url } = member.attributes;
  const alt = intl.formatMessage({ id: 'models.member.attributes.image.alt' }, { name: firstName });

  return (
    <AvatarWithDefault
      alt={alt}
      id={member.attributes.slug}
      src={profileImage200Url ?? undefined}
      {...maybeSlot(slot)}
    />
  );
};

export default MemberAvatar;
