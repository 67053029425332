import React from 'react';

import GuardAgainstPasswordlessUser from '../../components/routing/GuardAgainstPasswordlessUser';
import GuardAgainstUnconfirmedUser from '../../components/routing/GuardAgainstUnconfirmedUser';
import { JSONApi } from '../../types';

type Props = {
  children: JSX.Element;
  group?: JSONApi.GroupResource;
};

/*
 * Note that the order is significant here because you want to catch unconfirmed users, and
 * show them the confirmation page, before you catch passwordless users.
 */
const GuardAgainstIncompleteUser = ({ children, group }: Props) => (
  <GuardAgainstUnconfirmedUser group={group}>
    <GuardAgainstPasswordlessUser group={group}>{children}</GuardAgainstPasswordlessUser>
  </GuardAgainstUnconfirmedUser>
);

export default GuardAgainstIncompleteUser;
