import { IonIcon, IonLabel } from '@ionic/react';
import { pin } from 'ionicons/icons';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import FeedItem from '../components/FeedItem';
import { getPinnedPost } from '../selectors';
import { JSONApi, State } from '../types';

import './PinnedPost.scss';

type Props = {
  group: JSONApi.GroupResource;
};

const PinnedPost = ({ group }: Props) => {
  const apiData = useSelector((root: State.Root) => root.api);
  const pinnedPost = getPinnedPost(apiData, group);

  if (!pinnedPost) {
    return null;
  }

  return (
    <div className="pinned-post">
      <IonIcon color="primary" icon={pin} />
      <IonLabel>
        <FormattedMessage id="models.group.relationships.pinnedPost.title" />
      </IonLabel>
      <FeedItem group={group} post={pinnedPost} />
    </div>
  );
};

export default PinnedPost;
