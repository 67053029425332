import { IonButton, IonButtons, IonItem, IonProgressBar } from '@ionic/react';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import { alertError } from '../actions/notificationActions';
import { WizardSteps } from '../components/CreateGroupPage';
import Heading from '../components/Heading';
import Paragraph from '../components/Paragraph';
import useMountedTracking from '../hooks/useMountedTracking';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { createFreePlanCheckoutSession } from '../thunks/apiThunks';
import { JSONApi } from '../types';

type Props = {
  group: JSONApi.GroupResource;
};

const SetupGroupFreePlan = ({ group }: Props) => {
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const isMounted = useMountedTracking();
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const handleBackClick = useCallback(() => {
    history.push({ pathname: `/create/${group.attributes.slug}/${WizardSteps.SETUP_ACCOUNT}` });
  }, [group.attributes.slug, history]);

  const handleNextClick = useCallback(() => {
    const nextStep = `/create/${group.attributes.slug}/${WizardSteps.SHARE}`;
    if (group.attributes.planActive) {
      history.push(nextStep);
    } else {
      setSubmitDisabled(true);
      dispatch(createFreePlanCheckoutSession(group.attributes.slug))
        .then(() => {
          if (isMounted.current) {
            setSubmitDisabled(false);
            history.push(nextStep);
          }
        })
        .catch(() => {
          dispatch(alertError('pages.groupWizard.freePlan.error'));
          if (isMounted.current) {
            setSubmitDisabled(false);
          }
        });
    }
  }, [dispatch, group.attributes.planActive, group.attributes.slug, history, isMounted]);

  return (
    <div className="setup-group-payment-form">
      <IonProgressBar buffer={0.8} color="success" value={0} />
      <Heading level={2}>
        <FormattedMessage id="pages.groupWizard.freePlan.title" />
      </Heading>
      <Paragraph>
        <FormattedMessage id="pages.groupWizard.freePlan.description" />
      </Paragraph>
      <IonItem color="transparent" lines="none">
        <IonButtons slot="end">
          <IonButton color="primary" fill="outline" onClick={handleBackClick} size="default">
            <FormattedMessage id="dictionary.previous" />
          </IonButton>
          <IonButton
            color="primary"
            disabled={submitDisabled}
            fill="solid"
            onClick={handleNextClick}
            size="default"
          >
            <FormattedMessage id="dictionary.ok" />
          </IonButton>
        </IonButtons>
      </IonItem>
    </div>
  );
};

export default SetupGroupFreePlan;
