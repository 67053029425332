import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import EditGroupAvatar from '../../components/forms/EditGroupAvatar';
import EditGroupBackgroundImage from '../../components/forms/EditGroupBackgroundImage';
import GroupColorField from '../../components/forms/GroupColorField';
import { defaultPrimaryColor } from '../../globals';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import { forceArray } from '../../services/arrayUtils';
import { updateGroup } from '../../thunks/apiThunks';
import { JSONApi } from '../../types';

import './PersonalizeGroupForm.scss';

type Props = {
  group: JSONApi.GroupResource;
};

const PersonalizeGroupForm = ({ group }: Props) => {
  const intl = useIntl();
  const dispatch = useThunkDispatch();

  const [primaryColor, setPrimaryColor] = useState(
    // nullish coalescing will only happen if the current value is null or undefined.
    // we also want to use the default value if the current value is an empty string.
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    group.attributes.primaryColor || defaultPrimaryColor
  );
  const colors = [
    '#26d9bb', // cyan
    '#263373', // blue
    '#4b0082', // indigo
    '#800080', // purple
    '#f76e85', // pink
    '#DF5151', // red
    '#F0793B', // orange
    '#F0C63B', // yellow
    '#51DF99', // lime
    '#008000', // green
    '#c0c0c0' // grey
  ];

  const generateHandleColorClick = useCallback(
    (color: string) => () => {
      dispatch(updateGroup({ primaryColor: color }, group.id)).then(response => {
        const updatedGroup = forceArray(response.data)[0];
        if (updatedGroup?.attributes?.primaryColor) {
          setPrimaryColor(updatedGroup.attributes.primaryColor);
        }
      });
    },
    [dispatch, group.id]
  );

  const generateHandleColorKeyDown = useCallback(
    (color: string) => (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        generateHandleColorClick(color)();
      }
    },
    [generateHandleColorClick]
  );

  return (
    <div className="personalize-group-form">
      <div className="avatar">
        <EditGroupAvatar group={group} />
      </div>
      <div className="bg" style={{ backgroundColor: primaryColor }}>
        <div className="header" />
        <EditGroupBackgroundImage group={group} />
      </div>
      <div className="colorPicker">
        <div className="chips">
          {colors.map(color => (
            <div
              aria-label={intl.formatMessage(
                { id: 'models.group.attributes.primaryColor.colorLabel' },
                { color }
              )}
              key={color}
              onClick={generateHandleColorClick(color)}
              onKeyDown={generateHandleColorKeyDown(color)}
              role="button"
              style={{ backgroundColor: color }}
              tabIndex={0}
            />
          ))}
        </div>
      </div>
      <GroupColorField
        attribute="primaryColor"
        getColor={primaryColor}
        group={group}
        setColor={setPrimaryColor}
      />
    </div>
  );
};

export default PersonalizeGroupForm;
