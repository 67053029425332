/* eslint-disable max-lines */
import { Capacitor } from '@capacitor/core';

import {
  apiAppendNewRecordIntoHasManyRelationship,
  apiClearCachedResponsesContainingResource,
  apiClearCachedResponsesForEndpoint,
  apiDeleteResource
} from '../actions/apiActions';
import {
  AfterRequestSuccessCallback,
  ajaxRequest,
  AjaxRequestArgs,
  dispatchableRequest,
  DispatchableRequestArgs
} from '../services/ajaxRequest';
import { forceArray } from '../services/arrayUtils';
import { getPostApiPath } from '../services/postUtils';
import { apiPath } from '../services/urlUtils';
import {
  allPostInclusions,
  attachmentInclusions,
  commentInclusions,
  conversationInclusions,
  flaggedItemInclusions,
  flagInclusions,
  groupInclusions,
  imageInclusions,
  memberInclusions,
  postInclusions,
  prefixInclusions,
  userInclusions
} from '../thunks/inclusions';
import {
  Actions,
  ApiEndpoint,
  HTML,
  JSONApi,
  ModelAttributes,
  Models,
  Search,
  State
} from '../types';

type LoadResourceParameters = {
  endpoint: ApiEndpoint;
  params?: HTML.UrlParams;
  pathname: string;
};

const _loadResource = <T extends Models.Base>(loadResourceParameters: LoadResourceParameters) =>
  dispatchableRequest<T>({
    eventCategory: 'Load Data',
    eventName: loadResourceParameters.endpoint,
    method: 'GET',
    ...loadResourceParameters
  });

const _loadMoreResources =
  <T extends Models.Base>(endpoint: ApiEndpoint) =>
  async (dispatch: Actions.ThunkDispatch, getState: () => State.Root) => {
    const links = getState().api.meta[endpoint].links;
    const next = links.next;

    if (!next) {
      return (async () => Promise.resolve(undefined))();
    }

    const [pathname, params] = next.split('?');

    const ajaxRequestArgs: AjaxRequestArgs<T> = {
      dispatch,
      endpoint,
      getState,
      method: 'GET',
      params,
      pathname
    };
    const authToken = getState().session.authToken;
    if (authToken) {
      ajaxRequestArgs.authToken = authToken;
    }

    return ajaxRequest<T>(ajaxRequestArgs);
  };

export const loadComment = (groupSlug: string, id: string) =>
  _loadResource<Models.Comment>({
    endpoint: ApiEndpoint.LOAD_COMMENT,
    params: { include: commentInclusions.join(',') },
    pathname: apiPath(groupSlug, `comments/${id}`)
  });

export const loadComments = (groupSlug: string, postId: string) =>
  _loadResource<Models.Comment>({
    endpoint: ApiEndpoint.LOAD_COMMENTS,
    params: { include: commentInclusions.join(',') },
    pathname: apiPath(groupSlug, `posts/${postId}/comments`)
  });

export const loadConversation = (groupSlug: string, slug: string) =>
  _loadResource<Models.Conversation>({
    endpoint: ApiEndpoint.LOAD_CONVERSATION,
    params: { include: conversationInclusions.join(',') },
    pathname: apiPath(groupSlug, `conversations/${slug}`)
  });

export const loadConversationSearch = (
  groupSlug: string,
  params: Partial<Search.ConversationParams>
) => {
  const include = prefixInclusions('results', conversationInclusions).join(',');

  return _loadResource<Models.ConversationSearch>({
    endpoint: ApiEndpoint.CONVERSATION_SEARCH,
    params: { include, ...params },
    pathname: apiPath(groupSlug, 'search/conversations')
  });
};

export const loadMoreConversationSearchResults = () =>
  _loadMoreResources<Models.ConversationSearch>(ApiEndpoint.CONVERSATION_SEARCH);

export const loadConversations = (groupSlug: string) =>
  _loadResource<Models.Conversation>({
    endpoint: ApiEndpoint.LOAD_CONVERSATIONS,
    params: { include: conversationInclusions.join(',') },
    pathname: apiPath(groupSlug, 'conversations')
  });

export const loadMoreConversations = () =>
  _loadMoreResources<Models.Conversation>(ApiEndpoint.LOAD_CONVERSATIONS);

export const loadPost = (groupSlug: string, id: string, type: ModelAttributes.PostType) =>
  _loadResource<Models.Post>({
    endpoint: ApiEndpoint.LOAD_POST,
    params: { include: postInclusions[type].join(',') },
    pathname: getPostApiPath(groupSlug, id, type)
  });

export const loadDiscussion = (groupSlug: string, slug: string) =>
  _loadResource<Models.Discussion>({
    endpoint: ApiEndpoint.LOAD_DISCUSSION,
    params: { include: postInclusions.discussion.join(',') },
    pathname: apiPath(groupSlug, `discussions/${slug}`)
  });

export const loadEvent = (groupSlug: string, slug: string) =>
  _loadResource<Models.ReturnedEvent>({
    endpoint: ApiEndpoint.LOAD_EVENT,
    params: { include: postInclusions.event.join(',') },
    pathname: apiPath(groupSlug, `events/${slug}`)
  });

export const loadJobPost = (groupSlug: string, slug: string) =>
  _loadResource<Models.JobPost>({
    endpoint: ApiEndpoint.LOAD_JOB_POST,
    params: { include: postInclusions.jobPost.join(',') },
    pathname: apiPath(groupSlug, `jobs/${slug}`)
  });

export const loadNewsItem = (groupSlug: string, slug: string) =>
  _loadResource<Models.NewsItem>({
    endpoint: ApiEndpoint.LOAD_NEWS_ITEM,
    params: { include: postInclusions.newsItem.join(',') },
    pathname: apiPath(groupSlug, `news_items/${slug}`)
  });

export const loadGroup = (slug: string) =>
  _loadResource<Models.Group>({
    endpoint: ApiEndpoint.LOAD_GROUP,
    params: { include: groupInclusions.join(',') },
    pathname: apiPath(undefined, `g/${slug}`)
  });

export const loadFlags = (groupSlug: string) =>
  _loadResource<Models.Flag>({
    endpoint: ApiEndpoint.LOAD_FLAGS,
    params: { include: flagInclusions.join(',') },
    pathname: apiPath(groupSlug, 'flags')
  });

export const loadFlaggedItems = (groupSlug: string) =>
  _loadResource<Models.FlaggedItem>({
    endpoint: ApiEndpoint.LOAD_FLAGGED_ITEMS,
    params: { include: flaggedItemInclusions.join(',') },
    pathname: apiPath(groupSlug, 'flagged_items')
  });

export const loadWhitelistedDomains = (groupSlug: string) =>
  _loadResource<Models.WhitelistedDomain>({
    endpoint: ApiEndpoint.LOAD_WHITELISTED_DOMAINS,
    params: {},
    pathname: apiPath(groupSlug, 'whitelisted_domains')
  });

export const loadGroupSearch = (params: Partial<Search.GroupParams>) => {
  const include = prefixInclusions('results', groupInclusions).join(',');

  return _loadResource<Models.GroupSearch>({
    endpoint: ApiEndpoint.GROUP_SEARCH,
    params: { include, ...params },
    pathname: apiPath(undefined, 'search/groups')
  });
};

export const loadMoreGroupSearchResults = () =>
  _loadMoreResources<Models.GroupSearch>(ApiEndpoint.GROUP_SEARCH);

export const loadImageSearch = (groupSlug: string, params: Partial<Search.ImageParams>) =>
  _loadResource<Models.ImageSearch>({
    endpoint: ApiEndpoint.IMAGE_SEARCH,
    params: { include: 'results', ...params },
    pathname: apiPath(groupSlug, 'search/images')
  });

export const loadMoreImageSearchResults = () =>
  _loadMoreResources<Models.ImageSearch>(ApiEndpoint.IMAGE_SEARCH);

export const loadMember = (groupSlug: string, slug: string) =>
  _loadResource<Models.Member>({
    endpoint: ApiEndpoint.LOAD_MEMBER,
    params: {
      include: memberInclusions.join(',')
    },
    pathname: apiPath(groupSlug, `members/${slug}`)
  });

export const loadSubscriptionLocationFilters = (slug: string) =>
  _loadResource<Models.SubscriptionLocationFilter>({
    endpoint: ApiEndpoint.LOAD_NEWSLETTER_LOCATION_FILTERS,
    params: {},
    pathname: apiPath(undefined, `g/${slug}/subscription_location_filters`)
  });

export const loadSubscriptions = (slug: string) =>
  _loadResource<Models.Subscription>({
    endpoint: ApiEndpoint.LOAD_NEWSLETTER_SUBSCRIPTIONS,
    params: {},
    pathname: apiPath(undefined, `g/${slug}/subscriptions`)
  });

export const _loadPostSearch = (
  endpoint: ApiEndpoint,
  groupSlug: string,
  params: Partial<Search.PostParams>,
  postTypes?: Search.Searchable
) => {
  const include = prefixInclusions('results', allPostInclusions).join(',');

  return _loadResource<Models.PostSearch>({
    endpoint,
    params: { include, postTypes, ...params },
    pathname: apiPath(groupSlug, 'search/posts')
  });
};

export const loadDiscussionSearch = (groupSlug: string, params: Partial<Search.PostParams>) =>
  _loadPostSearch(ApiEndpoint.DISCUSSION_SEARCH, groupSlug, params, Search.Searchable.DISCUSSION);

export const loadMoreDiscussionSearchResults = () =>
  _loadMoreResources<Models.PostSearch>(ApiEndpoint.DISCUSSION_SEARCH);

export const loadEventSearch = (groupSlug: string, params: Partial<Search.PostParams>) =>
  _loadPostSearch(ApiEndpoint.EVENT_SEARCH, groupSlug, params, Search.Searchable.EVENT);

export const loadMoreEventSearchResults = () =>
  _loadMoreResources<Models.PostSearch>(ApiEndpoint.EVENT_SEARCH);

export const loadJobPostSearch = (groupSlug: string, params: Partial<Search.PostParams>) =>
  _loadPostSearch(ApiEndpoint.JOB_POST_SEARCH, groupSlug, params, Search.Searchable.JOB_POST);

export const loadMoreJobPostSearchResults = () =>
  _loadMoreResources<Models.PostSearch>(ApiEndpoint.JOB_POST_SEARCH);

export const loadNewsItemSearch = (groupSlug: string, params: Partial<Search.PostParams>) =>
  _loadPostSearch(ApiEndpoint.NEWS_ITEM_SEARCH, groupSlug, params, Search.Searchable.NEWS_ITEM);

export const loadMoreNewsItemSearchResults = () =>
  _loadMoreResources<Models.PostSearch>(ApiEndpoint.NEWS_ITEM_SEARCH);

export const loadPostSearch = (
  groupSlug: string,
  params: Partial<Search.PostParams>,
  postTypes?: Search.Searchable
) => _loadPostSearch(ApiEndpoint.POST_SEARCH, groupSlug, params, postTypes);

export const loadMorePostSearchResults = () =>
  _loadMoreResources<Models.PostSearch>(ApiEndpoint.POST_SEARCH);

export const loadInviteSearch = (groupSlug: string, params: Partial<Search.InviteParams>) => {
  const include = 'results';

  return _loadResource<Models.InviteSearch>({
    endpoint: ApiEndpoint.INVITE_SEARCH,
    params: { include, ...params },
    pathname: apiPath(groupSlug, 'search/invites')
  });
};

export const loadMoreInviteSearchResults = () =>
  _loadMoreResources<Models.InviteSearch>(ApiEndpoint.INVITE_SEARCH);

export const loadMemberSearch = (groupSlug: string, params: Partial<Search.MemberParams>) => {
  const include = prefixInclusions('results', memberInclusions).join(',');

  return _loadResource<Models.MemberSearch>({
    endpoint: ApiEndpoint.MEMBER_SEARCH,
    params: { include, ...params },
    pathname: apiPath(groupSlug, 'search/members')
  });
};

export const loadMoreMemberSearchResults = () =>
  _loadMoreResources<Models.MemberSearch>(ApiEndpoint.MEMBER_SEARCH);

export const loadUnreadCounts = (groupSlug: string) =>
  _loadResource<Models.UnreadCounts>({
    endpoint: ApiEndpoint.LOAD_UNREAD_COUNTS,
    pathname: apiPath(groupSlug, 'unread_counts')
  });

export const loadUser = (userId: string) =>
  _loadResource<Models.User>({
    endpoint: ApiEndpoint.LOAD_USER,
    params: { include: userInclusions.join(',') },
    pathname: apiPath(undefined, `users/${userId}`)
  });

export const loadEmailLookup = (email: string) =>
  _loadResource<Models.EmailLookup>({
    endpoint: ApiEndpoint.EMAIL_LOOKUP,
    params: { email },
    pathname: apiPath(undefined, 'email_lookup')
  });

export const createBlock = (groupSlug: string, attributes: Models.CreateBlock) => {
  const data: JSONApi.CreateData<Models.CreateBlock> = {
    data: {
      attributes,
      type: 'block'
    },
    include: 'blocked'
  };

  const afterSuccess: AfterRequestSuccessCallback<Models.Block> = ({ dispatch }) => {
    dispatch(
      apiClearCachedResponsesContainingResource(attributes.blockedId, attributes.blockedType)
    );
    dispatch(apiDeleteResource(attributes.blockedId, attributes.blockedType));
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Block> = {
    afterSuccess,
    data,
    endpoint: ApiEndpoint.CREATE_BLOCK,
    eventCategory: 'Content',
    eventName: 'Create Block',
    method: 'POST',
    pathname: apiPath(groupSlug, 'blocks')
  };

  return dispatchableRequest<Models.Block>(dispatchableRequestArgs);
};

export const deleteBlock = (groupSlug: string, block: JSONApi.BlockResource) => {
  const data: JSONApi.DeleteData = { data: null };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Block> = {
    data,
    endpoint: ApiEndpoint.DELETE_BLOCK,
    eventCategory: 'Content',
    eventName: 'Delete Block',
    method: 'DELETE',
    pathname: apiPath(groupSlug, `blocks/${block.id}`)
  };

  return dispatchableRequest<Models.Block>(dispatchableRequestArgs);
};

export const createComment = (
  groupSlug: string,
  post: JSONApi.PostResource,
  attributes: Models.CreateComment
) => {
  const data: JSONApi.CreateData<Models.CreateComment> = {
    data: { attributes, type: 'comment' },
    include: commentInclusions.join(',')
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Comment> = {
    data,
    endpoint: ApiEndpoint.CREATE_COMMENT,
    eventCategory: 'Content',
    eventName: 'Create Comment',
    method: 'POST',
    pathname: apiPath(groupSlug, `posts/${post.id}/comments`)
  };

  return dispatchableRequest<Models.Comment>(dispatchableRequestArgs);
};

export const updateComment = (groupSlug: string, comment: Models.UpdateComment, id: string) => {
  const data: JSONApi.UpdateData<Models.UpdateComment> = {
    data: {
      attributes: comment,
      id,
      type: 'comment'
    },
    include: commentInclusions.join(',')
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Comment> = {
    data,
    endpoint: ApiEndpoint.UPDATE_COMMENT,
    eventCategory: 'Content',
    eventName: 'Update Comment',
    method: 'PATCH',
    pathname: apiPath(groupSlug, `comments/${id}`)
  };

  return dispatchableRequest<Models.Comment>(dispatchableRequestArgs);
};

export const deleteComment = (groupSlug: string, comment: JSONApi.CommentResource) => {
  const data: JSONApi.DeleteData = { data: null };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Comment> = {
    data,
    endpoint: ApiEndpoint.DELETE_COMMENT,
    eventCategory: 'Content',
    eventName: 'Delete Comment',
    method: 'DELETE',
    pathname: apiPath(groupSlug, `comments/${comment.id}`)
  };

  return dispatchableRequest<Models.Comment>(dispatchableRequestArgs);
};

export const createNote = (
  groupSlug: string,
  conversation: JSONApi.ConversationResource,
  attributes: Models.CreateNote,
  attachments: JSONApi.AttachmentResource[]
) => {
  const relationships: { [key: string]: JSONApi.Relationship } = { attachments: { data: [] } };
  attachments.forEach(attachment => {
    forceArray(relationships.attachments.data).push({ id: attachment.id, type: attachment.type });
  });

  const data: JSONApi.CreateData<Models.CreateNote> = {
    data: {
      attributes,
      relationships,
      type: 'note'
    }
  };

  const afterSuccess: AfterRequestSuccessCallback<Models.Note> = ({ dispatch, responseBody }) => {
    const resource = forceArray(responseBody.data)[0];
    dispatch(apiAppendNewRecordIntoHasManyRelationship(resource, 'conversation', 'notes'));
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Note> = {
    afterSuccess,
    data,
    endpoint: ApiEndpoint.CREATE_NOTE,
    eventCategory: 'Messaging',
    eventName: 'Create Note',
    method: 'POST',
    pathname: apiPath(groupSlug, `conversations/${conversation.attributes.slug}/notes`)
  };

  return dispatchableRequest<Models.Note>(dispatchableRequestArgs);
};

export const updatePost = (
  groupSlug: string,
  post: Models.UpdatePost,
  id: string,
  type: ModelAttributes.PostType
) => {
  const data: JSONApi.UpdateData<Models.UpdatePost> = {
    data: {
      attributes: post,
      id,
      type
    },
    include: postInclusions[type].join(',')
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Post> = {
    data,
    endpoint: ApiEndpoint.UPDATE_POST,
    eventCategory: 'Content',
    eventName: 'Update Post',
    method: 'PATCH',
    pathname: getPostApiPath(groupSlug, id, type)
  };

  return dispatchableRequest<Models.Post>(dispatchableRequestArgs);
};

export const approvePost = <T extends Models.Post>(
  groupSlug: string,
  post: JSONApi.PostResource
) => {
  let endpoint: ApiEndpoint;
  let type: string;
  let basePath: string;

  switch (post.type) {
    case ModelAttributes.PostType.DISCUSSION:
      endpoint = ApiEndpoint.APPROVE_DISCUSSION;
      type = 'discussion';
      basePath = 'discussions';
      break;
    case ModelAttributes.PostType.EVENT:
      endpoint = ApiEndpoint.APPROVE_EVENT;
      type = 'event';
      basePath = 'events';
      break;
    case ModelAttributes.PostType.JOB_POST:
      endpoint = ApiEndpoint.APPROVE_JOB_POST;
      type = 'job_post';
      basePath = 'jobs';
      break;
    case ModelAttributes.PostType.NEWS_ITEM:
      endpoint = ApiEndpoint.APPROVE_NEWS_ITEM;
      type = 'news_item';
      basePath = 'news_items';
      break;
    default:
      // this basically won't happen since we handle all the possible cases
      throw new Error(`Post type is invalid ${post.type} ${post.attributes.slug}`);
  }

  const data: JSONApi.UpdateDataWithoutAttributes = {
    data: {
      id: post.id,
      type
    }
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<T> = {
    data,
    endpoint,
    eventCategory: 'Content',
    eventName: 'Approve Post',
    method: 'PATCH',
    pathname: apiPath(groupSlug, `${basePath}/${post.id}/approve`)
  };

  return dispatchableRequest<T>(dispatchableRequestArgs);
};

export const rejectPost = <T extends Models.Post>(
  groupSlug: string,
  post: JSONApi.PostResource
) => {
  let endpoint: ApiEndpoint;
  let type: string;
  let basePath: string;

  switch (post.type) {
    case ModelAttributes.PostType.DISCUSSION:
      endpoint = ApiEndpoint.REJECT_DISCUSSION;
      type = 'discussion';
      basePath = 'discussions';
      break;
    case ModelAttributes.PostType.EVENT:
      endpoint = ApiEndpoint.REJECT_EVENT;
      type = 'event';
      basePath = 'events';
      break;
    case ModelAttributes.PostType.JOB_POST:
      endpoint = ApiEndpoint.REJECT_JOB_POST;
      type = 'job_post';
      basePath = 'jobs';
      break;
    case ModelAttributes.PostType.NEWS_ITEM:
      endpoint = ApiEndpoint.REJECT_NEWS_ITEM;
      type = 'news_item';
      basePath = 'news_items';
      break;
    default:
      // this basically won't happen since we handle all the possible cases
      throw new Error(`Post type is invalid ${post.type} ${post.attributes.slug}`);
  }

  const data: JSONApi.UpdateDataWithoutAttributes = {
    data: {
      id: post.id,
      type
    }
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<T> = {
    data,
    endpoint,
    eventCategory: 'Content',
    eventName: 'Reject Post',
    method: 'PATCH',
    pathname: apiPath(groupSlug, `${basePath}/${post.id}/reject`)
  };

  return dispatchableRequest<T>(dispatchableRequestArgs);
};

export const createDiscussion = (
  groupSlug: string,
  attributes: Models.DiscussionFormData,
  images: JSONApi.ImageResource[]
) => {
  const relationships: { [key: string]: JSONApi.Relationship } = { images: { data: [] } };
  images.forEach(image => {
    forceArray(relationships.images.data).push({ id: image.id, type: image.type });
  });

  const data: JSONApi.CreateData<Models.DiscussionFormData> = {
    data: {
      attributes,
      relationships,
      type: 'discussion'
    },
    include: postInclusions.discussion.join(',')
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Discussion> = {
    data,
    endpoint: ApiEndpoint.CREATE_DISCUSSION,
    eventCategory: 'Content',
    eventName: 'Create Discussion',
    method: 'POST',
    pathname: apiPath(groupSlug, 'discussions')
  };

  return dispatchableRequest<Models.Discussion>(dispatchableRequestArgs);
};

interface DiscussionWithSlug extends Models.DiscussionFormData {
  slug: string;
}

export const updateDiscussion = (
  groupSlug: string,
  discussion: DiscussionWithSlug,
  images: JSONApi.ImageResource[]
) => {
  const relationships: { [key: string]: JSONApi.Relationship } = { images: { data: [] } };
  images.forEach(image => {
    forceArray(relationships.images.data).push({ id: image.id, type: image.type });
  });

  const data: JSONApi.UpdateData<Models.DiscussionFormData> = {
    data: {
      attributes: discussion,
      id: discussion.slug,
      relationships,
      type: 'discussion'
    },
    include: postInclusions.discussion.join(',')
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Discussion> = {
    data,
    endpoint: ApiEndpoint.UPDATE_DISCUSSION,
    eventCategory: 'Content',
    eventName: 'Update Discussion',
    method: 'PATCH',
    pathname: apiPath(groupSlug, `discussions/${discussion.slug}`)
  };

  return dispatchableRequest<Models.Discussion>(dispatchableRequestArgs);
};

export const createEvent = (groupSlug: string, attributes: Models.EventFormData) => {
  const data: JSONApi.CreateData<Models.EventFormData> = {
    data: {
      attributes,
      type: 'event'
    },
    include: postInclusions.event.join(',')
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Event> = {
    data,
    endpoint: ApiEndpoint.CREATE_EVENT,
    eventCategory: 'Content',
    eventName: 'Create Event',
    method: 'POST',
    pathname: apiPath(groupSlug, 'events')
  };

  return dispatchableRequest<Models.Event>(dispatchableRequestArgs);
};

interface EventWithSlug extends Models.EventFormData {
  slug: string;
}

export const updateEvent = (groupSlug: string, event: EventWithSlug) => {
  const data: JSONApi.UpdateData<Models.EventFormData> = {
    data: {
      attributes: event,
      id: event.slug,
      type: 'event'
    },
    include: postInclusions.event.join(',')
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Event> = {
    data,
    endpoint: ApiEndpoint.UPDATE_EVENT,
    eventCategory: 'Content',
    eventName: 'Update Event',
    method: 'PATCH',
    pathname: apiPath(groupSlug, `events/${event.slug}`)
  };

  return dispatchableRequest<Models.Event>(dispatchableRequestArgs);
};

export const createJobPost = (
  groupSlug: string,
  attributes: Models.JobPostFormData,
  attachments: JSONApi.AttachmentResource[]
) => {
  const relationships: { [key: string]: JSONApi.Relationship } = { attachments: { data: [] } };
  attachments.forEach(attachment => {
    forceArray(relationships.attachments.data).push({ id: attachment.id, type: attachment.type });
  });

  const data: JSONApi.CreateData<Models.JobPostFormData> = {
    data: {
      attributes,
      relationships,
      type: 'job_post'
    },
    include: postInclusions.jobPost.join(',')
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.JobPost> = {
    data,
    endpoint: ApiEndpoint.CREATE_JOB_POST,
    eventCategory: 'Content',
    eventName: 'Create Job Post',
    method: 'POST',
    pathname: apiPath(groupSlug, 'jobs')
  };

  return dispatchableRequest<Models.JobPost>(dispatchableRequestArgs);
};

interface JobPostWithSlug extends Models.JobPostFormData {
  slug: string;
}

export const updateJobPost = (
  groupSlug: string,
  jobPost: JobPostWithSlug,
  attachments: JSONApi.AttachmentResource[]
) => {
  const relationships: { [key: string]: JSONApi.Relationship } = { attachments: { data: [] } };
  attachments.forEach(attachment => {
    forceArray(relationships.attachments.data).push({ id: attachment.id, type: attachment.type });
  });

  const data: JSONApi.UpdateData<Models.JobPostFormData> = {
    data: {
      attributes: jobPost,
      id: jobPost.slug,
      relationships,
      type: 'job_post'
    },
    include: postInclusions.jobPost.join(',')
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.JobPost> = {
    data,
    endpoint: ApiEndpoint.UPDATE_JOB_POST,
    eventCategory: 'Content',
    eventName: 'Update Job Post',
    method: 'PATCH',
    pathname: apiPath(groupSlug, `jobs/${jobPost.slug}`)
  };

  return dispatchableRequest<Models.JobPost>(dispatchableRequestArgs);
};

export const createNewsItem = (
  groupSlug: string,
  attributes: Models.NewsItemFormData,
  images: JSONApi.ImageResource[]
) => {
  const relationships: { [key: string]: JSONApi.Relationship } = { images: { data: [] } };
  images.forEach(image => {
    forceArray(relationships.images.data).push({ id: image.id, type: image.type });
  });

  const data: JSONApi.CreateData<Models.NewsItemFormData> = {
    data: {
      attributes,
      relationships,
      type: 'news_item'
    },
    include: postInclusions.newsItem.join(',')
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.NewsItem> = {
    data,
    endpoint: ApiEndpoint.CREATE_NEWS_ITEM,
    eventCategory: 'Content',
    eventName: 'Create NewsItem',
    method: 'POST',
    pathname: apiPath(groupSlug, 'news_items')
  };

  return dispatchableRequest<Models.NewsItem>(dispatchableRequestArgs);
};

interface NewsItemWithSlug extends Models.NewsItemFormData {
  slug: string;
}

export const updateNewsItem = (
  groupSlug: string,
  newsItem: NewsItemWithSlug,
  images: JSONApi.ImageResource[]
) => {
  const relationships: { [key: string]: JSONApi.Relationship } = { images: { data: [] } };
  images.forEach(image => {
    forceArray(relationships.images.data).push({ id: image.id, type: image.type });
  });

  const data: JSONApi.UpdateData<Models.NewsItemFormData> = {
    data: {
      attributes: newsItem,
      id: newsItem.slug,
      relationships,
      type: 'news_item'
    },
    include: postInclusions.newsItem.join(',')
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.NewsItem> = {
    data,
    endpoint: ApiEndpoint.UPDATE_NEWS_ITEM,
    eventCategory: 'Content',
    eventName: 'Update NewsItem',
    method: 'PATCH',
    pathname: apiPath(groupSlug, `news_items/${newsItem.slug}`)
  };

  return dispatchableRequest<Models.NewsItem>(dispatchableRequestArgs);
};

export const deletePost = (groupSlug: string, post: JSONApi.PostResource) => {
  const data: JSONApi.DeleteData = { data: null };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Post> = {
    data,
    endpoint: ApiEndpoint.DELETE_POST,
    eventCategory: 'Content',
    eventName: 'Delete Post',
    method: 'DELETE',
    pathname: apiPath(groupSlug, `posts/${post.attributes.slug}`)
  };

  return dispatchableRequest<Models.Post>(dispatchableRequestArgs);
};

export const createFlag = (groupSlug: string, attributes: Models.CreateFlag) => {
  const data: JSONApi.CreateData<Models.CreateFlag> = {
    data: {
      attributes,
      type: 'flag'
    },
    include: 'flagged'
  };

  const afterSuccess: AfterRequestSuccessCallback<Models.Flag> = ({ dispatch }) => {
    dispatch(apiClearCachedResponsesForEndpoint(ApiEndpoint.LOAD_FLAGGED_ITEMS));
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Flag> = {
    afterSuccess,
    data,
    endpoint: ApiEndpoint.CREATE_FLAG,
    eventCategory: 'Content',
    eventName: 'Create Flag',
    method: 'POST',
    pathname: apiPath(groupSlug, 'flags')
  };

  return dispatchableRequest<Models.Flag>(dispatchableRequestArgs);
};

export const updateFlag = (groupSlug: string, flag: Models.UpdateFlag, id: string) => {
  const data: JSONApi.UpdateData<Models.UpdateFlag> = {
    data: {
      attributes: flag,
      id,
      type: 'flag'
    }
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Flag> = {
    data,
    endpoint: ApiEndpoint.UPDATE_FLAG,
    eventCategory: 'Content',
    eventName: 'Update Flag',
    method: 'PATCH',
    pathname: apiPath(groupSlug, `flags/${id}`)
  };

  return dispatchableRequest<Models.Flag>(dispatchableRequestArgs);
};

export const ignoreFlaggedItem = (groupSlug: string, id: string) => {
  const dispatchableRequestArgs: DispatchableRequestArgs<Models.FlaggedItem> = {
    endpoint: ApiEndpoint.IGNORE_FLAGGED_ITEM,
    eventCategory: 'Content',
    eventName: 'Ignore Flagged Item',
    method: 'PATCH',
    pathname: apiPath(groupSlug, `flagged_items/${id}/ignore`)
  };

  return dispatchableRequest<Models.FlaggedItem>(dispatchableRequestArgs);
};

export const rejectFlaggedItem = (groupSlug: string, id: string) => {
  const dispatchableRequestArgs: DispatchableRequestArgs<Models.FlaggedItem> = {
    endpoint: ApiEndpoint.REJECT_FLAGGED_ITEM,
    eventCategory: 'Content',
    eventName: 'Reject Flagged Item',
    method: 'PATCH',
    pathname: apiPath(groupSlug, `flagged_items/${id}/reject`)
  };

  return dispatchableRequest<Models.FlaggedItem>(dispatchableRequestArgs);
};

export const createFollow = (groupSlug: string, post: JSONApi.PostResource) => {
  const data: JSONApi.CreateData<Models.FollowFormData> = {
    data: {
      attributes: { postId: post.id },
      type: 'follow'
    },
    include: 'post'
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Follow> = {
    data,
    endpoint: ApiEndpoint.CREATE_FOLLOW,
    eventCategory: 'Content',
    eventName: 'Create Follow',
    method: 'POST',
    pathname: apiPath(groupSlug, 'follows')
  };

  return dispatchableRequest<Models.Follow>(dispatchableRequestArgs);
};

export const deleteFollow = (groupSlug: string, follow: JSONApi.FollowResource) => {
  const data: JSONApi.DeleteData = { data: null };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Follow> = {
    data,
    endpoint: ApiEndpoint.DELETE_FOLLOW,
    eventCategory: 'Content',
    eventName: 'Delete Follow',
    method: 'DELETE',
    pathname: apiPath(groupSlug, `follows/${follow.id}`)
  };

  return dispatchableRequest<Models.Follow>(dispatchableRequestArgs);
};

export const loadBlocks = (groupSlug: string) =>
  _loadResource<Models.Block>({
    endpoint: ApiEndpoint.LOAD_BLOCKS,
    params: { include: 'blocked,blocked.creator,blocked.post,blocked.poster,blocked.recipient' },
    pathname: apiPath(groupSlug, 'blocks')
  });

export const loadInvites = () =>
  _loadResource<Models.Invite>({
    endpoint: ApiEndpoint.LOAD_INVITES,
    params: { include: 'group' },
    pathname: apiPath(undefined, 'invites')
  });

export const loadInviteLookup = (inviteCode: string) =>
  _loadResource<Models.InviteLookup>({
    endpoint: ApiEndpoint.INVITE_LOOKUP,
    params: { include: 'group,invite' },
    pathname: apiPath(undefined, `invite_lookups/${inviteCode}`)
  });

export const loadMentions = (groupSlug: string) =>
  _loadResource<Models.Mention>({
    endpoint: ApiEndpoint.LOAD_MENTIONS,
    params: { include: 'creator,mentioned_in,mentioned_in.post' },
    pathname: apiPath(groupSlug, 'mentions')
  });

export const loadSaves = (groupSlug: string) =>
  _loadResource<Models.Save>({
    endpoint: ApiEndpoint.LOAD_SAVES,
    params: { include: 'saved' },
    pathname: apiPath(groupSlug, 'saves')
  });

export const loadPriceTiers = () =>
  _loadResource<Models.PriceTier>({
    endpoint: ApiEndpoint.LOAD_PRICE_TIERS,
    params: {},
    pathname: apiPath(undefined, 'price_tiers')
  });

export const createReaction = (
  groupSlug: string,
  reaction: Models.CreateReaction,
  resource: JSONApi.BaseResource
) => {
  const data: JSONApi.CreateData<Models.CreateReaction> = {
    data: {
      attributes: reaction,
      relationships: {
        content: {
          data: { id: resource.id, type: resource.type }
        }
      },
      type: 'reaction'
    },
    include: 'content'
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Reaction> = {
    data,
    endpoint: ApiEndpoint.CREATE_REACTION,
    eventCategory: 'Content',
    eventName: 'Create Reaction',
    method: 'POST',
    pathname: apiPath(groupSlug, 'reactions')
  };

  return dispatchableRequest<Models.Reaction>(dispatchableRequestArgs);
};

export const deleteReaction = (groupSlug: string, reaction: JSONApi.ReactionResource) => {
  const data: JSONApi.DeleteData = { data: null };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Reaction> = {
    data,
    endpoint: ApiEndpoint.DELETE_REACTION,
    eventCategory: 'Content',
    eventName: 'Delete Reaction',
    method: 'DELETE',
    pathname: apiPath(groupSlug, `reactions/${reaction.id}`)
  };

  return dispatchableRequest<Models.Reaction>(dispatchableRequestArgs);
};

export const createSave = (groupSlug: string, resource: JSONApi.BaseResource) => {
  const data: JSONApi.CreateData<Models.CreateSave> = {
    data: {
      attributes: {},
      relationships: {
        saved: {
          data: { id: resource.id, type: resource.type }
        }
      },
      type: 'save'
    },
    include: 'saved'
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Save> = {
    data,
    endpoint: ApiEndpoint.CREATE_SAVE,
    eventCategory: 'Content',
    eventName: 'Create Save',
    method: 'POST',
    pathname: apiPath(groupSlug, 'saves')
  };

  return dispatchableRequest<Models.Save>(dispatchableRequestArgs);
};

export const deleteSave = (groupSlug: string, save: JSONApi.SaveResource) => {
  const data: JSONApi.DeleteData = { data: null };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Save> = {
    data,
    endpoint: ApiEndpoint.DELETE_SAVE,
    eventCategory: 'Content',
    eventName: 'Delete Save',
    method: 'DELETE',
    pathname: apiPath(groupSlug, `saves/${save.id}`)
  };

  return dispatchableRequest<Models.Save>(dispatchableRequestArgs);
};

export const createRsvp = (groupSlug: string, event: JSONApi.EventResource) => {
  const data: JSONApi.CreateData<Models.RsvpFormData> = {
    data: {
      attributes: { eventId: event.id },
      type: 'rsvp'
    },
    include: 'event'
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Rsvp> = {
    data,
    endpoint: ApiEndpoint.CREATE_RSVP,
    eventCategory: 'Content',
    eventName: 'Create RSVP',
    method: 'POST',
    pathname: apiPath(groupSlug, 'rsvps')
  };

  return dispatchableRequest<Models.Rsvp>(dispatchableRequestArgs);
};

export const deleteRsvp = (groupSlug: string, rsvp: JSONApi.RsvpResource) => {
  const data: JSONApi.DeleteData = { data: null };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Rsvp> = {
    data,
    endpoint: ApiEndpoint.DELETE_RSVP,
    eventCategory: 'Content',
    eventName: 'Delete RSVP',
    method: 'DELETE',
    pathname: apiPath(groupSlug, `rsvps/${rsvp.id}`)
  };

  return dispatchableRequest<Models.Rsvp>(dispatchableRequestArgs);
};

export const createEmailAddress = (
  groupSlug: string | undefined,
  attributes: Models.CreateEmailAddress
) => {
  const data: JSONApi.CreateData<Models.CreateEmailAddress> = {
    data: {
      attributes,
      type: 'emailAddress'
    }
  };

  const afterSuccess: AfterRequestSuccessCallback<Models.EmailAddress> = ({
    dispatch,
    responseBody
  }) => {
    const resource = forceArray(responseBody.data)[0];
    dispatch(apiAppendNewRecordIntoHasManyRelationship(resource, 'user', 'emailAddresses'));
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.EmailAddress> = {
    afterSuccess,
    data,
    endpoint: ApiEndpoint.CREATE_EMAIL_ADDRESS,
    eventCategory: 'Registration',
    eventName: 'Create Email Address',
    method: 'POST',
    pathname: apiPath(groupSlug, 'email_addresses')
  };

  return dispatchableRequest<Models.EmailAddress>(dispatchableRequestArgs);
};

export const deleteEmailAddress = (emailAddress: JSONApi.EmailAddressResource) => {
  const data: JSONApi.DeleteData = { data: null };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.EmailAddress> = {
    data,
    endpoint: ApiEndpoint.DELETE_EMAIL_ADDRESS,
    eventCategory: 'Registration',
    eventName: 'Delete Email Address',
    method: 'DELETE',
    pathname: apiPath(undefined, `email_addresses/${emailAddress.id}`)
  };

  return dispatchableRequest<Models.EmailAddress>(dispatchableRequestArgs);
};

export const promoteEmailAddress = (emailAddress: JSONApi.EmailAddressResource) => {
  const data: JSONApi.UpdateData<Models.EmailAddress> = {
    data: emailAddress
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.EmailAddress> = {
    data,
    endpoint: ApiEndpoint.PROMOTE_EMAIL_ADDRESS,
    eventCategory: 'Registration',
    eventName: 'Promote Email Address',
    method: 'PATCH',
    pathname: apiPath(undefined, `email_addresses/${emailAddress.id}/promote`)
  };

  return dispatchableRequest<Models.EmailAddress>(dispatchableRequestArgs);
};

export const createInvites = (
  groupSlug: string,
  note: string,
  invites: Models.InviteFormData[]
) => {
  const data: JSONApi.CreateDataBulk<Models.CreateInvite> = { data: [] };
  invites.forEach(invite => {
    data.data.push({ attributes: { note, ...invite }, type: 'invite' });
  });

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Invite> = {
    data,
    endpoint: ApiEndpoint.CREATE_INVITES,
    eventCategory: 'Social',
    eventName: 'Create Invites',
    method: 'POST',
    pathname: apiPath(groupSlug, 'invites')
  };

  return dispatchableRequest<Models.Invite>(dispatchableRequestArgs);
};

export const revokeInvite = (groupSlug: string, invite: JSONApi.InviteResource) => {
  const data: JSONApi.UpdateDataWithoutAttributes = {
    data: {
      id: invite.id,
      type: 'invite'
    }
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Invite> = {
    data,
    endpoint: ApiEndpoint.REVOKE_INVITE,
    eventCategory: 'Social',
    eventName: 'Revoke Invite',
    method: 'PATCH',
    pathname: apiPath(groupSlug, `invites/${invite.id}/revoke`)
  };

  return dispatchableRequest<Models.Invite>(dispatchableRequestArgs);
};

export const createLink = (
  groupSlug: string,
  link: Models.LinkFormData,
  owner: JSONApi.BaseResource
) => {
  const data: JSONApi.CreateData<Models.LinkFormData> = {
    data: {
      attributes: link,
      relationships: { owner: { data: { id: owner.id, type: owner.type } } },
      type: 'link'
    }
  };

  const afterSuccess: AfterRequestSuccessCallback<Models.Link> = ({ dispatch, responseBody }) => {
    const resource = forceArray(responseBody.data)[0];
    dispatch(apiAppendNewRecordIntoHasManyRelationship(resource, 'owner', 'links'));
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Link> = {
    afterSuccess,
    data,
    endpoint: ApiEndpoint.CREATE_LINK,
    eventCategory: 'Content',
    eventName: 'Create Link',
    method: 'POST',
    pathname: apiPath(groupSlug, 'links')
  };

  return dispatchableRequest<Models.Link>(dispatchableRequestArgs);
};

export const deleteLink = (groupSlug: string, link: JSONApi.LinkResource) => {
  const data: JSONApi.DeleteData = { data: null };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Link> = {
    data,
    endpoint: ApiEndpoint.DELETE_LINK,
    eventCategory: 'Content',
    eventName: 'Delete Link',
    method: 'DELETE',
    pathname: apiPath(groupSlug, `links/${link.id}`)
  };

  return dispatchableRequest<Models.Link>(dispatchableRequestArgs);
};

export const createJobApplication = (
  groupSlug: string,
  jobPost: JSONApi.JobPostResource,
  coverLetter: string,
  attachments: JSONApi.AttachmentResource[]
) => {
  const relationships: { [key: string]: JSONApi.Relationship } = {
    attachments: { data: [] },
    jobPost: { data: jobPost }
  };
  attachments.forEach(attachment => {
    forceArray(relationships.attachments.data).push({ id: attachment.id, type: 'attachment' });
  });

  const data: JSONApi.CreateData<Models.CreateJobApplication> = {
    data: {
      attributes: { coverLetter },
      relationships,
      type: 'job_application'
    }
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Conversation> = {
    data,
    endpoint: ApiEndpoint.CREATE_JOB_APPLICATION,
    eventCategory: 'Messaging',
    eventName: 'Create Job Application',
    method: 'POST',
    pathname: apiPath(groupSlug, 'job_applications')
  };

  return dispatchableRequest<Models.Conversation>(dispatchableRequestArgs);
};

export const createMessage = (
  groupSlug: string,
  toMember: JSONApi.MemberResource,
  note: string,
  attachments: JSONApi.AttachmentResource[]
) => {
  const relationships: { [key: string]: JSONApi.Relationship } = { attachments: { data: [] } };
  attachments.forEach(attachment => {
    forceArray(relationships.attachments.data).push({ id: attachment.id, type: attachment.type });
  });

  const data: JSONApi.CreateData<Models.CreateConversation, Models.CreateNote> = {
    data: {
      attributes: { recipientId: toMember.id },
      relationships: { notes: { data: [{ id: '1', type: 'note' }] } },
      type: 'message'
    },
    included: [
      {
        attributes: { note },
        id: '1',
        relationships,
        type: 'note'
      }
    ]
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Conversation, Models.Note> = {
    data,
    endpoint: ApiEndpoint.CREATE_MESSAGE,
    eventCategory: 'Messaging',
    eventName: 'Create Message',
    method: 'POST',
    pathname: apiPath(groupSlug, 'messages')
  };

  return dispatchableRequest<Models.Conversation, Models.Note>(dispatchableRequestArgs);
};

export const createRule = (groupSlug: string, rule: Models.RuleFormData) => {
  const data: JSONApi.CreateData<Models.RuleFormData> = {
    data: {
      attributes: rule,
      type: 'rule'
    }
  };

  const afterSuccess: AfterRequestSuccessCallback<Models.Rule> = ({ dispatch, responseBody }) => {
    const resource = forceArray(responseBody.data)[0];
    dispatch(apiAppendNewRecordIntoHasManyRelationship(resource, 'group', 'rules'));
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Rule> = {
    afterSuccess,
    data,
    endpoint: ApiEndpoint.CREATE_RULE,
    eventCategory: 'Content',
    eventName: 'Create Rule',
    method: 'POST',
    pathname: apiPath(groupSlug, 'rules')
  };

  return dispatchableRequest<Models.Rule>(dispatchableRequestArgs);
};

export const deleteRule = (groupSlug: string, rule: JSONApi.RuleResource) => {
  const data: JSONApi.DeleteData = { data: null };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Rule> = {
    data,
    endpoint: ApiEndpoint.DELETE_RULE,
    eventCategory: 'Content',
    eventName: 'Delete Rule',
    method: 'DELETE',
    pathname: apiPath(groupSlug, `rules/${rule.id}`)
  };

  return dispatchableRequest<Models.Rule>(dispatchableRequestArgs);
};

export const createTrait = (groupSlug: string, trait: Models.TraitFormData) => {
  const data: JSONApi.CreateData<Models.TraitFormData> = {
    data: {
      attributes: trait,
      type: 'trait'
    }
  };

  const afterSuccess: AfterRequestSuccessCallback<Models.Trait> = ({ dispatch, responseBody }) => {
    const resource = forceArray(responseBody.data)[0];
    dispatch(apiAppendNewRecordIntoHasManyRelationship(resource, 'group', 'traits'));
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Trait> = {
    afterSuccess,
    data,
    endpoint: ApiEndpoint.CREATE_TRAIT,
    eventCategory: 'Content',
    eventName: 'Create Trait',
    method: 'POST',
    pathname: apiPath(groupSlug, 'traits')
  };

  return dispatchableRequest<Models.Trait>(dispatchableRequestArgs);
};

export const updateTrait = (
  groupSlug: string,
  attributes: Partial<Models.TraitFormData>,
  id: string,
  relationships?: { [key: string]: JSONApi.Relationship },
  included?: JSONApi.TraitOptionResource[]
) => {
  const data: JSONApi.UpdateData<Partial<Models.TraitFormData>> = {
    data: {
      attributes,
      id,
      relationships,
      type: 'trait'
    },
    include: 'trait_options',
    included
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Trait> = {
    data,
    endpoint: ApiEndpoint.UPDATE_TRAIT,
    eventCategory: 'Content',
    eventName: 'Update Trait',
    method: 'PATCH',
    pathname: apiPath(groupSlug, `traits/${id}`)
  };

  return dispatchableRequest<Models.Trait>(dispatchableRequestArgs);
};

export const deleteTrait = (groupSlug: string, trait: JSONApi.TraitResource) => {
  const data: JSONApi.DeleteData = { data: null };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Trait> = {
    data,
    endpoint: ApiEndpoint.DELETE_TRAIT,
    eventCategory: 'Content',
    eventName: 'Delete Trait',
    method: 'DELETE',
    pathname: apiPath(groupSlug, `traits/${trait.id}`)
  };

  return dispatchableRequest<Models.Trait>(dispatchableRequestArgs);
};

export const createTraitOption = (
  groupSlug: string,
  traitId: string,
  attributes: Models.TraitOptionFormData
) => {
  const data: JSONApi.CreateData<Models.TraitOptionFormData> = {
    data: { attributes, type: 'trait_option' }
  };

  const afterSuccess: AfterRequestSuccessCallback<Models.TraitOption> = ({
    dispatch,
    responseBody
  }) => {
    const resource = forceArray(responseBody.data)[0];
    dispatch(apiAppendNewRecordIntoHasManyRelationship(resource, 'trait', 'traitOptions'));
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.TraitOption> = {
    afterSuccess,
    data,
    endpoint: ApiEndpoint.CREATE_TRAIT_OPTION,
    eventCategory: 'Content',
    eventName: 'Create Trait Option',
    method: 'POST',
    pathname: apiPath(groupSlug, `traits/${traitId}/trait_options`)
  };

  return dispatchableRequest<Models.TraitOption>(dispatchableRequestArgs);
};

export const deleteTraitOption = (
  groupSlug: string,
  traitId: string,
  traitOption: JSONApi.TraitOptionResource
) => {
  const data: JSONApi.DeleteData = { data: null };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.TraitOption> = {
    data,
    endpoint: ApiEndpoint.DELETE_TRAIT_OPTION,
    eventCategory: 'Content',
    eventName: 'Delete Trait Option',
    method: 'DELETE',
    pathname: apiPath(groupSlug, `traits/${traitId}/trait_options/${traitOption.id}`)
  };

  return dispatchableRequest<Models.TraitOption>(dispatchableRequestArgs);
};

export const createWelcome = (groupSlug: string, toMember: JSONApi.MemberResource) => {
  const data: JSONApi.CreateData<Models.CreateConversation> = {
    data: {
      attributes: { recipientId: toMember.id },
      type: 'welcome'
    },
    include: 'recipient'
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Conversation> = {
    data,
    endpoint: ApiEndpoint.CREATE_WELCOME,
    eventCategory: 'Social',
    eventName: 'Create Welcome',
    method: 'POST',
    pathname: apiPath(groupSlug, 'welcomes')
  };

  return dispatchableRequest<Models.Conversation>(dispatchableRequestArgs);
};

export const updateGroup = <IncludedType extends Models.Base = Models.Base>(
  group: Models.UpdateGroup,
  id: string,
  relationships?: { [key: string]: JSONApi.Relationship },
  included?: JSONApi.Resource<IncludedType>[]
) => {
  const data: JSONApi.UpdateData<Models.UpdateGroup> = {
    data: {
      attributes: group,
      id,
      relationships,
      type: 'group'
    },
    include: groupInclusions.join(','),
    included
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Group> = {
    data,
    endpoint: ApiEndpoint.UPDATE_GROUP,
    eventCategory: 'Group',
    eventName: 'Update Group',
    method: 'PATCH',
    pathname: apiPath(undefined, `groups/${id}`)
  };

  return dispatchableRequest<Models.Group>(dispatchableRequestArgs);
};

export const approveGroup = (group: JSONApi.GroupResource) => {
  const data: JSONApi.UpdateDataWithoutAttributes = {
    data: {
      id: group.id,
      type: 'group'
    }
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Group> = {
    data,
    endpoint: ApiEndpoint.APPROVE_GROUP,
    eventCategory: 'Registration',
    eventName: 'Approve Group',
    method: 'PATCH',
    pathname: apiPath(undefined, `groups/${group.id}/approve`)
  };

  return dispatchableRequest<Models.Group>(dispatchableRequestArgs);
};

export const rejectGroup = (group: JSONApi.GroupResource) => {
  const data: JSONApi.UpdateDataWithoutAttributes = {
    data: {
      id: group.id,
      type: 'group'
    }
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Group> = {
    data,
    endpoint: ApiEndpoint.REJECT_GROUP,
    eventCategory: 'Registration',
    eventName: 'Reject Group',
    method: 'PATCH',
    pathname: apiPath(undefined, `groups/${group.id}/reject`)
  };

  return dispatchableRequest<Models.Group>(dispatchableRequestArgs);
};

export const updateUser = (user: Models.UpdateUser, id: string) => {
  const data: JSONApi.UpdateData<Models.UpdateUser> = {
    data: {
      attributes: user,
      id,
      type: 'user'
    }
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.User> = {
    data,
    endpoint: ApiEndpoint.UPDATE_USER,
    eventCategory: 'User',
    eventName: 'Update User',
    method: 'PATCH',
    params: { include: 'members' },
    pathname: apiPath(undefined, `users/${id}`)
  };

  return dispatchableRequest<Models.User>(dispatchableRequestArgs);
};

export const deleteUser = (user: JSONApi.UserResource) => {
  const data: JSONApi.DeleteData = { data: null };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.User> = {
    data,
    endpoint: ApiEndpoint.DELETE_USER,
    eventCategory: 'Registration',
    eventName: 'Delete User',
    method: 'DELETE',
    pathname: apiPath(undefined, `users/${user.id}`)
  };

  return dispatchableRequest<Models.User>(dispatchableRequestArgs);
};

export const updateUnreadCount = (groupSlug: string, type: Models.Readable) => {
  const dispatchableRequestArgs: DispatchableRequestArgs<Models.UnreadCounts> = {
    endpoint: ApiEndpoint.UPDATE_UNREAD_COUNT,
    eventCategory: 'Content',
    eventName: 'Update Unread Count',
    method: 'PATCH',
    pathname: apiPath(groupSlug, `unread_counts/${type}`)
  };

  return dispatchableRequest<Models.UnreadCounts>(dispatchableRequestArgs);
};

export const createGroup = (group: Models.CreateGroup) => {
  const data: JSONApi.CreateData<Models.CreateGroup> = {
    data: {
      attributes: group,
      type: 'group'
    },
    // there will only be one moderator when a group is created
    include: 'moderators'
  };

  const afterSuccess: AfterRequestSuccessCallback<Models.Group> = ({ dispatch, responseBody }) => {
    const groupResource = forceArray(responseBody.data)[0];
    const identifier = forceArray(groupResource.relationships.moderators.data)[0];
    const resource = responseBody.included.find(
      item => item.id === identifier.id && item.type === identifier.type
    );
    if (resource) {
      dispatch(apiAppendNewRecordIntoHasManyRelationship(resource, 'user', 'members'));
    }
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Group> = {
    afterSuccess,
    data,
    endpoint: ApiEndpoint.CREATE_GROUP,
    eventCategory: 'Group',
    eventName: 'Create Group',
    method: 'POST',
    pathname: apiPath(undefined, 'groups')
  };

  return dispatchableRequest<Models.Group>(dispatchableRequestArgs);
};

export const createFreePlanCheckoutSession = (groupSlug: string) => {
  const dispatchableRequestArgs: DispatchableRequestArgs<Models.CheckoutSession> = {
    endpoint: ApiEndpoint.CREATE_FREE_PLAN_CHECKOUT_SESSION,
    eventCategory: 'Group',
    eventName: 'Create Free Plan Checkout Session',
    method: 'POST',
    pathname: apiPath(groupSlug, 'plan/create_free_plan_checkout_session')
  };

  return dispatchableRequest<Models.CheckoutSession>(dispatchableRequestArgs);
};

export const createInitialCheckoutSession = (
  groupSlug: string,
  priceTierId: string,
  period: ModelAttributes.PlanPeriod,
  successRedirectPath: string,
  cancelRedirectPath: string
) => {
  const dispatchableRequestArgs: DispatchableRequestArgs<Models.CheckoutSession> = {
    data: {
      data: { attributes: { cancelRedirectPath, period, priceTierId, successRedirectPath } }
    },
    endpoint: ApiEndpoint.CREATE_INITIAL_CHECKOUT_SESSION,
    eventCategory: 'Group',
    eventName: 'Create Initial Checkout Session',
    method: 'POST',
    pathname: apiPath(groupSlug, 'plan/create_initial_checkout_session')
  };

  return dispatchableRequest<Models.CheckoutSession>(dispatchableRequestArgs);
};

export const createPaymentInfoUpdateCheckoutSession = (
  groupSlug: string,
  successRedirectPath: string,
  cancelRedirectPath: string
) => {
  const dispatchableRequestArgs: DispatchableRequestArgs<Models.CheckoutSession> = {
    data: {
      data: { attributes: { cancelRedirectPath, successRedirectPath } }
    },
    endpoint: ApiEndpoint.CREATE_PAYMENT_INFO_UPDATE_CHECKOUT_SESSION,
    eventCategory: 'Group',
    eventName: 'Update Payment Info',
    method: 'POST',
    pathname: apiPath(groupSlug, 'plan/create_payment_info_update_checkout_session')
  };

  return dispatchableRequest<Models.CheckoutSession>(dispatchableRequestArgs);
};

export const cancelPlan = (groupSlug: string) => {
  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Group> = {
    endpoint: ApiEndpoint.CANCEL_PLAN,
    eventCategory: 'Group',
    eventName: 'Cancel Plan',
    method: 'PATCH',
    pathname: apiPath(groupSlug, 'plan/cancel')
  };

  return dispatchableRequest<Models.Group>(dispatchableRequestArgs);
};

export const reactivatePlan = (groupSlug: string) => {
  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Group> = {
    endpoint: ApiEndpoint.REACTIVATE_PLAN,
    eventCategory: 'Group',
    eventName: 'Reactivate Plan',
    method: 'PATCH',
    pathname: apiPath(groupSlug, 'plan/reactivate')
  };

  return dispatchableRequest<Models.Group>(dispatchableRequestArgs);
};

export const createPlanChangePreview = (
  groupSlug: string,
  priceTierId: string,
  period: ModelAttributes.PlanPeriod
) => {
  const dispatchableRequestArgs: DispatchableRequestArgs<Models.PlanChangePreview> = {
    data: { data: { attributes: { period, priceTierId } } },
    endpoint: ApiEndpoint.CREATE_PLAN_CHANGE_PREVIEW,
    eventCategory: 'Group',
    eventName: 'Preview Plan Change',
    method: 'POST',
    pathname: apiPath(groupSlug, 'plan/create_plan_change_preview')
  };

  return dispatchableRequest<Models.PlanChangePreview>(dispatchableRequestArgs);
};

export const createPlanChange = (
  groupSlug: string,
  priceTierId: string,
  period: ModelAttributes.PlanPeriod,
  successRedirectPath: string,
  cancelRedirectPath: string
) => {
  const dispatchableRequestArgs: DispatchableRequestArgs<Models.CheckoutSession> = {
    data: {
      data: { attributes: { cancelRedirectPath, period, priceTierId, successRedirectPath } }
    },
    endpoint: ApiEndpoint.CREATE_PLAN_CHANGE,
    eventCategory: 'Group',
    eventName: 'Create Plan Change',
    method: 'POST',
    pathname: apiPath(groupSlug, 'plan/create_plan_change')
  };

  return dispatchableRequest<Models.CheckoutSession>(dispatchableRequestArgs);
};

export const loadInvoices = (groupSlug: string) => {
  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Invoice> = {
    endpoint: ApiEndpoint.LOAD_INVOICES,
    eventCategory: 'Plan',
    eventName: 'Load Invoices',
    method: 'GET',
    pathname: apiPath(groupSlug, 'plan/invoices')
  };

  return dispatchableRequest<Models.Invoice>(dispatchableRequestArgs);
};

export const createMember = <IncludedType extends Models.Base = Models.Base>(
  groupSlug: string,
  relationships?: { [key: string]: JSONApi.Relationship },
  included?: JSONApi.Resource<IncludedType>[]
) => {
  const data: JSONApi.CreateDataWithoutAttributes = {
    data: { relationships, type: 'member' },
    include: memberInclusions.join(','),
    included
  };

  const afterSuccess: AfterRequestSuccessCallback<Models.Member> = ({ dispatch, responseBody }) => {
    const resource = forceArray(responseBody.data)[0];
    dispatch(apiAppendNewRecordIntoHasManyRelationship(resource, 'user', 'members'));
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Member> = {
    afterSuccess,
    data,
    endpoint: ApiEndpoint.CREATE_MEMBER,
    eventCategory: 'Registration',
    eventName: 'Create Member',
    method: 'POST',
    pathname: apiPath(groupSlug, 'members')
  };

  return dispatchableRequest<Models.Member>(dispatchableRequestArgs);
};

export const createUser = (group: JSONApi.GroupResource | null | undefined, username: string) => {
  const data: JSONApi.CreateData<Models.CreateUser> = {
    data: {
      attributes: { username },
      type: 'user'
    }
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.User> = {
    data,
    endpoint: ApiEndpoint.CREATE_USER,
    eventCategory: 'Registration',
    eventName: 'Create Account',
    method: 'POST',
    params: (group && { groupId: group.attributes.slug }) || undefined,
    pathname: apiPath(undefined, 'users')
  };

  return dispatchableRequest<Models.User>(dispatchableRequestArgs);
};

export const updateMember = <IncludedType extends Models.Base = Models.Base>(
  groupSlug: string,
  member: Models.UpdateMember,
  id: string,
  relationships?: { [key: string]: JSONApi.Relationship },
  included?: JSONApi.Resource<IncludedType>[]
) => {
  const data: JSONApi.UpdateData<Models.UpdateMember> = {
    data: {
      attributes: member,
      id,
      relationships,
      type: 'member'
    },
    include: memberInclusions.join(','),
    included
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Member> = {
    data,
    endpoint: ApiEndpoint.UPDATE_MEMBER,
    eventCategory: 'Registration',
    eventName: 'Update Member',
    method: 'PATCH',
    pathname: apiPath(groupSlug, `members/${id}`)
  };

  return dispatchableRequest<Models.Member>(dispatchableRequestArgs);
};

export const deleteMember = (groupSlug: string, member: JSONApi.MemberResource) => {
  const data: JSONApi.DeleteData = { data: null };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Member> = {
    data,
    endpoint: ApiEndpoint.DELETE_MEMBER,
    eventCategory: 'Registration',
    eventName: 'Delete Member',
    method: 'DELETE',
    pathname: apiPath(groupSlug, `members/${member.id}`)
  };

  return dispatchableRequest<Models.Member>(dispatchableRequestArgs);
};

export const approveMember = (groupSlug: string, member: JSONApi.MemberResource) => {
  const data: JSONApi.UpdateDataWithoutAttributes = {
    data: {
      id: member.id,
      type: 'member'
    }
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Member> = {
    data,
    endpoint: ApiEndpoint.APPROVE_MEMBER,
    eventCategory: 'Registration',
    eventName: 'Approve Member',
    method: 'PATCH',
    pathname: apiPath(groupSlug, `members/${member.id}/approve`)
  };

  return dispatchableRequest<Models.Member>(dispatchableRequestArgs);
};

export const closeMember = (groupSlug: string, member: JSONApi.MemberResource) => {
  const data: JSONApi.UpdateDataWithoutAttributes = {
    data: {
      id: member.id,
      type: 'member'
    }
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Member> = {
    data,
    endpoint: ApiEndpoint.CLOSE_MEMBER,
    eventCategory: 'Registration',
    eventName: 'Close Member',
    method: 'PATCH',
    pathname: apiPath(groupSlug, `members/${member.id}/close`)
  };

  return dispatchableRequest<Models.Member>(dispatchableRequestArgs);
};

export const openMember = (groupSlug: string, member: JSONApi.MemberResource) => {
  const data: JSONApi.UpdateDataWithoutAttributes = {
    data: {
      id: member.id,
      type: 'member'
    }
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Member> = {
    data,
    endpoint: ApiEndpoint.OPEN_MEMBER,
    eventCategory: 'Registration',
    eventName: 'Open Member',
    method: 'PATCH',
    pathname: apiPath(groupSlug, `members/${member.id}/open`)
  };

  return dispatchableRequest<Models.Member>(dispatchableRequestArgs);
};

export const rejectMember = (groupSlug: string, member: JSONApi.MemberResource) => {
  const data: JSONApi.UpdateDataWithoutAttributes = {
    data: {
      id: member.id,
      type: 'member'
    }
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Member> = {
    data,
    endpoint: ApiEndpoint.REJECT_MEMBER,
    eventCategory: 'Registration',
    eventName: 'Reject Member',
    method: 'PATCH',
    pathname: apiPath(groupSlug, `members/${member.id}/reject`)
  };

  return dispatchableRequest<Models.Member>(dispatchableRequestArgs);
};

export const createAttachment = (file: File) => {
  const data: JSONApi.CreateData<Models.CreateAttachment> = {
    data: {
      attributes: { file },
      type: 'attachment'
    }
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Attachment> = {
    data,
    endpoint: ApiEndpoint.CREATE_ATTACHMENT,
    eventCategory: 'Messaging',
    eventName: 'Create Attachment',
    method: 'POST',
    pathname: apiPath(undefined, 'attachments'),
    useFormData: true
  };

  return dispatchableRequest<Models.Attachment>(dispatchableRequestArgs);
};

export const deleteAttachment = (attachment: JSONApi.AttachmentResource) => {
  const data: JSONApi.DeleteData = { data: null };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Attachment> = {
    data,
    endpoint: ApiEndpoint.DELETE_ATTACHMENT,
    eventCategory: 'Content',
    eventName: 'Delete Attachment',
    method: 'DELETE',
    pathname: apiPath(undefined, `attachments/${attachment.id}`)
  };

  return dispatchableRequest<Models.Attachment>(dispatchableRequestArgs);
};

export const adminLoadAttachments = () =>
  _loadResource<Models.Attachment>({
    endpoint: ApiEndpoint.ADMIN_LOAD_ATTACHMENTS,
    params: { include: attachmentInclusions.join(',') },
    pathname: apiPath(undefined, 'admin/attachments')
  });

export const adminLoadMoreAttachments = () =>
  _loadMoreResources<Models.Attachment>(ApiEndpoint.ADMIN_LOAD_ATTACHMENTS);

export const adminApproveAttachment = (attachment: JSONApi.AttachmentResource) => {
  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Attachment> = {
    endpoint: ApiEndpoint.ADMIN_APPROVE_ATTACHMENT,
    eventCategory: 'Content',
    eventName: 'Approve Attachment',
    method: 'PATCH',
    pathname: apiPath(undefined, `admin/attachments/${attachment.id}/approve`)
  };

  return dispatchableRequest<Models.Attachment>(dispatchableRequestArgs);
};

export const createImage = (file: string) => {
  const data: JSONApi.CreateData<Models.CreateImage> = {
    data: {
      attributes: { file },
      type: 'image'
    }
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Image> = {
    data,
    endpoint: ApiEndpoint.CREATE_IMAGE,
    eventCategory: 'Content',
    eventName: 'Create Image',
    method: 'POST',
    pathname: apiPath(undefined, 'images'),
    useFormData: true
  };

  return dispatchableRequest<Models.Image>(dispatchableRequestArgs);
};

export const deleteImage = (image: JSONApi.ImageResource) => {
  const data: JSONApi.DeleteData = { data: null };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Image> = {
    data,
    endpoint: ApiEndpoint.DELETE_IMAGE,
    eventCategory: 'Content',
    eventName: 'Delete Image',
    method: 'DELETE',
    pathname: apiPath(undefined, `images/${image.id}`)
  };

  return dispatchableRequest<Models.Image>(dispatchableRequestArgs);
};

export const adminLoadImages = () =>
  _loadResource<Models.Image>({
    endpoint: ApiEndpoint.ADMIN_LOAD_IMAGES,
    params: { include: imageInclusions.join(',') },
    pathname: apiPath(undefined, 'admin/images')
  });

export const adminLoadMoreImages = () =>
  _loadMoreResources<Models.Image>(ApiEndpoint.ADMIN_LOAD_IMAGES);

export const adminApproveImage = (image: JSONApi.ImageResource) => {
  const dispatchableRequestArgs: DispatchableRequestArgs<Models.Image> = {
    endpoint: ApiEndpoint.ADMIN_APPROVE_IMAGE,
    eventCategory: 'Content',
    eventName: 'Approve Image',
    method: 'PATCH',
    pathname: apiPath(undefined, `admin/images/${image.id}/approve`)
  };

  return dispatchableRequest<Models.Image>(dispatchableRequestArgs);
};

export const createEmailConfirmation = (
  group: JSONApi.GroupResource | null | undefined,
  email: string
) => {
  const data: JSONApi.CreateData<Models.EmailConfirmation> = {
    data: {
      attributes: { email },
      type: 'email_confirmation'
    }
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.EmailConfirmation> = {
    data,
    endpoint: ApiEndpoint.CREATE_EMAIL_CONFIRMATION,
    eventCategory: 'Registration',
    eventName: 'Create Email Confirmation',
    method: 'POST',
    params: (group && { groupId: group.attributes.slug }) || undefined,
    pathname: apiPath(undefined, 'email_confirmations')
  };

  return dispatchableRequest<Models.EmailConfirmation>(dispatchableRequestArgs);
};

export const resetPassword = (group: JSONApi.GroupResource | null | undefined, email: string) => {
  const data: JSONApi.CreateData<Models.PasswordReset> = {
    data: {
      attributes: { email },
      type: 'password_reset'
    }
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.PasswordReset> = {
    data,
    endpoint: ApiEndpoint.CREATE_PASSWORD_RESET,
    eventCategory: 'Authentication',
    eventName: 'Create Password Reset',
    method: 'POST',
    params: (group && { groupId: group.attributes.slug }) || undefined,
    pathname: apiPath(undefined, 'password_resets/')
  };

  return dispatchableRequest<Models.PasswordReset>(dispatchableRequestArgs);
};

export const updateSubscription = (
  groupSlug: string,
  subscription: Models.UpdateSubscription,
  id: string
) => {
  const data: JSONApi.UpdateData<Models.UpdateSubscription> = {
    data: {
      attributes: subscription,
      id,
      type: 'subscription'
    }
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.NewsItem> = {
    data,
    endpoint: ApiEndpoint.UPDATE_NEWSLETTER_SUBSCRIPTION,
    eventCategory: 'Content',
    eventName: 'Update Subscription',
    method: 'PATCH',
    pathname: apiPath(groupSlug, `subscriptions/${id}`)
  };

  return dispatchableRequest<Models.NewsItem>(dispatchableRequestArgs);
};

export const createSubscriptionLocationFilter = (groupSlug: string, location: string) => {
  const data: JSONApi.CreateData<Models.CreateSubscriptionLocationFilter> = {
    data: {
      attributes: {
        location
      },
      type: 'subscription_location_filter'
    }
  };

  const afterSuccess: AfterRequestSuccessCallback<Models.SubscriptionLocationFilter> = ({
    dispatch,
    responseBody
  }) => {
    const resource = forceArray(responseBody.data)[0];
    dispatch(
      apiAppendNewRecordIntoHasManyRelationship(resource, 'member', 'subscriptionLocationFilters')
    );
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.SubscriptionLocationFilter> = {
    afterSuccess,
    data,
    endpoint: ApiEndpoint.CREATE_NEWSLETTER_LOCATION_FILTER,
    eventCategory: 'Content',
    eventName: 'Create SubscriptionLocationFilter',
    method: 'POST',
    pathname: apiPath(groupSlug, 'subscription_location_filters')
  };

  return dispatchableRequest<Models.SubscriptionLocationFilter>(dispatchableRequestArgs);
};

export const deleteSubscriptionLocationFilter = (
  groupSlug: string,
  subscriptionLocationFilter: JSONApi.SubscriptionLocationFilterResource
) => {
  const data: JSONApi.DeleteData = { data: null };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.SubscriptionLocationFilter> = {
    data,
    endpoint: ApiEndpoint.DELETE_NEWSLETTER_LOCATION_FILTER,
    eventCategory: 'Content',
    eventName: 'Delete SubscriptionLocationFilter',
    method: 'DELETE',
    pathname: apiPath(groupSlug, `subscription_location_filters/${subscriptionLocationFilter.id}`)
  };

  return dispatchableRequest<Models.SubscriptionLocationFilter>(dispatchableRequestArgs);
};

export const createPushNotificationToken = (pushNotificationToken: string) => {
  const data: JSONApi.CreateData<Models.PushNotificationToken> = {
    data: {
      attributes: {
        platform: Capacitor.getPlatform(),
        token: pushNotificationToken
      },
      type: 'push_notification_token'
    },
    include: 'user'
  };

  const dispatchableRequestArgs: DispatchableRequestArgs<Models.PushNotificationToken> = {
    data,
    endpoint: ApiEndpoint.CREATE_PUSH_NOTIFICATION_TOKEN,
    eventCategory: 'User',
    eventName: 'Create Push Notification Token',
    method: 'POST',
    pathname: apiPath(undefined, 'push_notification_tokens')
  };

  return dispatchableRequest<Models.PushNotificationToken>(dispatchableRequestArgs);
};
/* eslint-enable max-lines */
