import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token
} from '@capacitor/push-notifications';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { addNotification } from '../actions/notificationActions';
import { setPushNotificationToken } from '../actions/sessionActions';
import useThunkDispatch from '../hooks/useThunkDispatch';
import { clearRequestCacheOfResourceOnUpdate } from '../services/broadcastUtils';
import { JSONApi, Models, State } from '../types';

interface PushNotificationCustom extends Omit<PushNotificationSchema, 'data'> {
  data: {
    path?: string;
    update?: string;
  };
}

interface PushNotificationCustomActionPerformed extends Omit<ActionPerformed, 'notification'> {
  notification: PushNotificationCustom;
}

const PushNotificationSetup = () => {
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const platform = Capacitor.getPlatform();
  const [registered, setRegistered] = useState(false);

  useEffect(() => {
    if (!registered && platform !== 'web') {
      setRegistered(true);

      PushNotifications.addListener('registration', (token: Token) => {
        dispatch(setPushNotificationToken(token.value));
      });

      PushNotifications.addListener('registrationError', error => {
        // eslint-disable-next-line
        console.log('+++++ Error on registration: ' + JSON.stringify(error));
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification: PushNotificationCustom) => {
          const { data, ...pushNotificationBody } = notification;
          const { update, ...pushNotificationData } = data;
          let pushNotificationUpdate: JSONApi.Response<Models.BroadcastUpdate> | undefined;

          if (notification.data.update) {
            const pushNotificationUpdate = JSON.parse(
              notification.data.update
            ) as JSONApi.Response<Models.BroadcastUpdate>;
            clearRequestCacheOfResourceOnUpdate(pushNotificationUpdate, dispatch);
          }

          dispatch(
            addNotification({
              ...pushNotificationBody,
              ...pushNotificationData,
              type: State.NotificationType.PUSH_NOTIFICATION,
              update: pushNotificationUpdate
            })
          );
        }
      );

      // Method called when tapping on a notification when the app is not open
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification: PushNotificationCustomActionPerformed) => {
          if (notification.notification.data.update) {
            const update = JSON.parse(
              notification.notification.data.update
            ) as JSONApi.Response<Models.BroadcastUpdate>;
            clearRequestCacheOfResourceOnUpdate(update, dispatch);
          }

          const path = notification.notification.data.path;
          if (path) {
            history.push(path);
          }
        }
      );

      PushNotifications.requestPermissions().then(response => {
        if (response.receive === 'granted') {
          PushNotifications.register();
        }
      });
    }
  }, [dispatch, history, platform, registered]);

  return null;
};

export default PushNotificationSetup;
